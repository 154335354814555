import { styled } from '@taraai/design-system';
import Icon from 'components/core/controllers/views/Icon';
import React, { ChangeEventHandler, useCallback } from 'react';
import { inputTestIds } from 'resources/cypress/testAttributesValues';

interface Props {
  dataCyCancel?: string;
  dataCyConfirm?: string;
  handleOnCancel: (event: React.KeyboardEvent | React.MouseEvent) => void;
  handleOnChange: ChangeEventHandler<HTMLInputElement>;
  handleOnSave: () => void;
  value: string;
  placeholder?: string;
  id?: string;
  isCreating?: boolean;
  addTeamNameRef?: React.RefObject<HTMLInputElement>;
}

/**
 * InputWithButtons
 * Input field with buttons
 */
export function InputWithButtons({
  value,
  handleOnChange,
  handleOnCancel,
  handleOnSave,
  dataCyCancel,
  dataCyConfirm,
  placeholder,
  isCreating,
  id,
  addTeamNameRef,
}: Props): JSX.Element {
  const KEYS = {
    ESCAPE: 27,
    ENTER: 13,
  };
  const handleOnKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      switch (event.keyCode) {
        case KEYS.ESCAPE:
          event.preventDefault();
          handleOnCancel(event);
          break;
        case KEYS.ENTER:
          event.preventDefault();
          handleOnSave();
          break;
        default:
          break;
      }
    },
    [KEYS.ENTER, KEYS.ESCAPE, handleOnCancel, handleOnSave],
  );

  return (
    <Form id={id} isCreating={isCreating}>
      <InputField
        ref={addTeamNameRef}
        autoFocus
        data-cy={inputTestIds.INPUT_FIELD}
        isCreating={isCreating}
        onChange={handleOnChange}
        onKeyDown={handleOnKeyDown}
        placeholder={placeholder}
        value={value}
      />
      <CancelButton data-cy={dataCyCancel} name='cancelEdit' onClick={(event) => handleOnCancel(event)} />
      <SaveButton data-cy={dataCyConfirm} name='save' onClick={handleOnSave} />
    </Form>
  );
}

const Form = styled(
  'form',
  {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '0.5rem',
    borderRadius: '0rem',
  },
  { isCreating: { true: { paddingLeft: '1.5rem', paddingTop: '0.5rem', paddingBottom: '0.5rem' } } },
);

const InputField = styled(
  'input',
  {
    'fontSize': '0.875rem',
    'fontWeight': '400',
    'color': '$focus',
    'alignItems': 'center',
    'flexGrow': '1',
    'width': '12.5rem',
    'border': 'none',
    '::placeholder': {
      color: '$grey6',
    },
    ':focus-within': {
      outline: 'none',
      border: 'none',
      borderBottom: '1px dashed $grey6',
      paddingLeft: '0rem',
    },
  },
  {
    isCreating: {
      true: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        maxWidth: '12.5rem',
      },
    },
  },
);

const CancelButton = styled(Icon, {
  padding: '0',
  position: 'absolute',
  right: '2rem',
  color: 'white',
  width: '0.75rem',
  height: '0.75rem',
});

const SaveButton = styled(Icon, {
  padding: '0',
  position: 'absolute',
  right: '1rem',
  width: '0.75rem',
  height: '0.75rem',
});
