"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.payloadDecoder = void 0;
var ts_data_json_1 = require("ts.data.json");
var team_1 = require("../decoders/team");
exports.payloadDecoder = ts_data_json_1.JsonDecoder.object({
    organizationId: ts_data_json_1.JsonDecoder.string,
    teamId: ts_data_json_1.JsonDecoder.string,
    sprintSettings: team_1.SprintSettingsDecoder,
}, 'Payload');
