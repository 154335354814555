import Icon from 'components/core/controllers/views/Icon';
import { FastSmallSpinner } from 'components/core/controllers/views/Spinners';
import { css } from 'emotion';
import React from 'react';
import { strings } from 'resources/i18n';
import { atomic } from 'resources/theme';

export type AddCommentInputStatus =
  | 'disabled' // inital state, empty comment
  | 'ready' // ready to send
  | 'loading'; // sending in progress

type AddCommentInputViewProps = {
  onSendButtonClick: () => void;
  status: AddCommentInputStatus;

  /** Component to render in the input place */
  inputSlot: (props: { inputRootStyle: string; disabled: boolean }) => JSX.Element;
};

/**
 * This component wraps any _Input component_ and renders
 * it with applied styling as well as send button.
 */
export default function AddCommentInputView({
  onSendButtonClick,
  status,
  inputSlot,
}: AddCommentInputViewProps): JSX.Element {
  const inputRootStyle = css`
    height: 100%;
    overflow-y: auto;
    font-size: 0.9rem;
    padding: 1rem;
    padding-right: 2.2rem;

    .public-DraftEditorPlaceholder-root {
      color: ${atomic.get(atomic.colors.grey6)};
      user-select: none;
    }

    &:focus-within .public-DraftEditorPlaceholder-root {
      display: none;
    }
  `;

  const media = window.matchMedia('(max-width:500px)');

  return (
    <div
      className={css`
        background-color: ${atomic.get(atomic.colors.grey1)};
        width: 100%;
        position: relative;
        ${media.matches ? 'height: 6.625rem; padding-bottom: 4rem;' : 'height: 4.625rem;'}
      `}
    >
      {media.matches && (
        <button
          className={css`
            background-color: ${status === 'ready'
              ? atomic.get(atomic.colors.primary)
              : atomic.get(atomic.colors.grey5)};
            color: white;
            padding: 0.25rem 1.5rem;
            position: absolute;
            margin-left: auto;
            margin-right: auto;
            width: 50%;
            left: 0;
            right: 0;
            bottom: 1rem;
            text-align: center;
            border: none;
            border-radius: 4px;
            z-index: 2; /* z-index from Draft.css + 1 */
          `}
          name='send'
          onClick={onSendButtonClick}
          type='submit'
        >
          {strings.comments.submitComment}
        </button>
      )}
      {inputSlot({ inputRootStyle, disabled: status === 'disabled' })}
      {status === 'loading' ? (
        <FastSmallSpinner
          className={css`
            bottom: 1rem;
            padding: 0;
            position: absolute;
            right: 1rem;
          `}
        />
      ) : (
        <Icon
          className={css`
            bottom: 1rem;
            cursor: ${status === 'ready' ? 'pointer' : 'default'} !important;
            fill: ${status === 'ready' ? atomic.get(atomic.colors.primary) : atomic.get(atomic.colors.grey5)};
            height: 1rem;
            padding: 0;
            position: absolute;
            right: 1rem;
            width: 1rem;
            z-index: 2; /* z-index from Draft.css + 1 */
            ${media.matches && 'display: none;'}
          `}
          name='send'
          onClick={onSendButtonClick}
        />
      )}
    </div>
  );
}
