import React from 'react';
import { ValidationResult, Validator } from 'tools/helpers/validators/inviteUserValidators';

import { Tag } from './Tag';

type Props = {
  tags: string[];
  validators: Validator[];
  onRemove: (tag: string) => void;
};

/**
 * Validates a tag given a list of validators.
 * It reduces a list of validators taking the first invalid result, last result if all are valid.
 */
const validateTag = (validators: Validator[], tag: string): ValidationResult =>
  validators.reduce((result, validator) => (!result.valid ? result : validator(tag)), {
    valid: true,
  } as ValidationResult);

export const TagList: React.FC<Props> = ({ tags, validators, onRemove }: Props) => {
  const validatedTags = tags.map((tag) => {
    const validationResult = validateTag(validators, tag);
    return {
      label: tag,
      invalid: !validationResult.valid,
      invalidMessage: !validationResult.valid ? validationResult.message : '',
    };
  });

  return (
    <>
      {validatedTags.map(({ label, invalid, invalidMessage }) => (
        <Tag
          key={label}
          invalid={invalid}
          invalidMessage={invalidMessage}
          label={label}
          onRemove={(): void => onRemove(label)}
        />
      ))}
    </>
  );
};
