import { Fluid, getCustomSize, HStack, styled, Text } from '@taraai/design-system';
import Icon from 'components/core/controllers/views/Icon';
import React from 'react';
import { strings } from 'resources/i18n';

import { SprintSelectorOption } from './types';

type SelectButtonProps = {
  selectedOption: SprintSelectorOption;
  onClick: () => void;
  dataCy?: string;
};

export default function SelectButton({ selectedOption, dataCy, onClick }: SelectButtonProps): JSX.Element {
  return (
    <Container data-cy={dataCy} onClick={onClick}>
      <HStack align='right'>
        {selectedOption?.sprintName === '' ? (
          <Text color='$focus' size='$12px'>
            {strings.taskSidebar.modules.sprint.select}
          </Text>
        ) : (
          <Fluid>
            <Text singleLine size='$12px' textAlign='right'>
              {selectedOption.sprintName}
            </Text>
          </Fluid>
        )}
        <ArrowDownIcon name='arrowdown' noPadding />
      </HStack>
    </Container>
  );
}

const Container = styled('div', {
  cursor: 'pointer',
  alignItems: 'center',
  display: 'flex',
  outline: '0',
});

const ArrowDownIcon = styled(Icon, { height: getCustomSize(12), width: getCustomSize(16), color: '$focus' });
