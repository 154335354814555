import { DeleteTeamModal } from 'components/app/GlobalNav/TeamSwitcher/DeleteTeamModal';
import { getRouteName } from 'components/Router/paths';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { selectActiveTeam, selectActiveWorkspace } from 'reduxStore';

export default function DeleteTeam(): JSX.Element {
  const orgId = useSelector(selectActiveWorkspace);
  const teamId = useSelector(selectActiveTeam);
  const routeName = getRouteName();
  const isOnNestedDeleteRoute = routeName === 'deleteTeam';

  const history = useHistory();

  const onClose = useCallback(() => {
    isOnNestedDeleteRoute ? history.go(-2) : history.goBack();
  }, [history, isOnNestedDeleteRoute]);

  return <DeleteTeamModal onClose={onClose} orgId={orgId} teamId={teamId} />;
}
