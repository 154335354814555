import { css } from 'emotion';
import React, { HTMLProps } from 'react';
import { strings } from 'resources/i18n';
import { atomic } from 'resources/theme';

const ResendButton = (props: HTMLProps<HTMLButtonElement>): JSX.Element => (
  <button
    className={css`
      background: none !important;
      border: none;
      padding: 0 !important;
      cursor: pointer;
      width: 3.8125rem;
      height: 1rem;
      padding: 0.25rem;
      font-size: 0.875rem;
      font-weight: 500;
      text-align: center;
      color: ${atomic.get(atomic.colors.secondary)};
    `}
    {...props}
    type='button'
  >
    {strings.users.invitedUsers.resendBtn}
  </button>
);

export default ResendButton;
