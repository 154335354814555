import { Data } from '@taraai/types';
import Avatar from 'components/core/controllers/views/Avatar';
import { css, cx } from 'emotion';
import moment from 'moment';
import React from 'react';
import { atomic } from 'resources/theme';
import { toDate } from 'tools';

import parseMessage from './parse-message';

export interface CommitListItemProps extends React.HTMLProps<HTMLDivElement> {
  commit: Data.Commit;
}

/**
 * A representation of a commit that can be used
 * in a ListView.
 *
 *  - [Designs](https://zpl.io/VqkNWRY)
 */
export default function CommitListItem({ className, commit, ...props }: CommitListItemProps): JSX.Element {
  const lastCommitted = toDate(commit.createdAt);
  const { title } = parseMessage(commit.message);

  return (
    <div
      className={cx(
        css`
          display: flex;
          flex-direction: row;
          border: 0.0625rem solid ${atomic.get(atomic.colors.grey3)};
          border-radius: 0.125rem;
          font-size: 0.75rem;
          align-items: center;
        `,
        className,
      )}
      {...props}
    >
      <Avatar
        className={css`
          margin: 0.8rem;
          flex-shrink: 0;
        `}
        size='2rem'
        user={commit.user}
      />
      <div
        className={css`
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-right: 0.8rem;
        `}
      >
        <a
          className={css`
            text-decoration: none;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          `}
          href={commit.url}
          rel='noopener noreferrer'
          target='_blank'
        >
          {title}
        </a>
      </div>
      <div
        className={css`
          color: ${atomic.get(atomic.colors.grey6)};
          padding-right: 0.8rem;
          text-align: right;
          flex-shrink: 0;
        `}
      >
        <span title={lastCommitted.toLocaleDateString()}>{moment(lastCommitted).fromNow()}</span>
      </div>
    </div>
  );
}
