import styled from '@emotion/styled';
import { StandardSpinner } from 'components/core/controllers/views/Spinners';
import React from 'react';
import { atomic, strings } from 'resources';

import { InstallableServiceWithRedirects } from './interfaces';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const LoadingMessage = styled.div`
  margin-top: 2rem;
  font-family: ${atomic.get(atomic.fonts.family)},
  font-size: 1.5rem;
  font-weight: 500;
  color: ${atomic.get(atomic.colors.grey6)}
`;

type SetupIntegrationLayoutProps = {
  service: InstallableServiceWithRedirects;
};

export function SetupIntegrationLayout({ service }: SetupIntegrationLayoutProps): JSX.Element {
  return (
    <Container>
      <StandardSpinner size='medium' />
      <LoadingMessage>{strings.setupIntegration[service].setup}</LoadingMessage>
    </Container>
  );
}
