import { css, cx } from 'emotion';
import React, { forwardRef } from 'react';
import { getIcon, IconName } from 'resources/assets';
import { atomic } from 'resources/theme/atomic';

export interface IconProps extends React.SVGProps<SVGSVGElement> {
  name: IconName;
  color?: string;
  dataCy?: string;
  noPadding?: boolean;
  autoSize?: boolean;
}

export function getStyles({
  autoSize,
  className,
  color,
  onClick,
  noPadding,
}: {
  autoSize?: boolean;
  className?: string;
  color?: string;
  onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  noPadding?: boolean;
}): string[] {
  const styles: string[] = [
    css`
      box-sizing: content-box;
      overflow: visible !important;
      ${!noPadding && 'padding: 0.5rem;'}
      ${atomic.color(color ?? 'black')};
      ${!autoSize && 'height: 1rem; width: 1rem;'}
    `,
    className ?? '',
  ];
  if (typeof onClick === 'function') {
    styles.push(
      css`
        cursor: pointer;
      `,
    );
  }
  return styles;
}

export default forwardRef<SVGSVGElement, IconProps>(function Icon(
  { name, autoSize = false, color = atomic.colors.grey6, className, dataCy, onClick, noPadding = false, ...props },
  ref,
) {
  const IconSVG = getIcon(name);
  return (
    <IconSVG
      ref={ref}
      className={cx(...getStyles({ autoSize, className, color, noPadding, onClick }))}
      data-cy={dataCy}
      data-name={name}
      fill='currentColor'
      onClick={onClick}
      role={onClick ? 'button' : 'img'}
      {...props}
    />
  );
});
