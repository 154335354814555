import { Box, Fluid, HStack, Switch, Text, VStack } from '@taraai/design-system';
import { SprintDetailsSidebarModule } from 'components/app/controllers/views/SprintDetailsSidebarModule';
import React from 'react';
import { strings } from 'resources/i18n';

import { PlannedEffortEntry } from './PlannedEffortEntry';
import { TaskSummaries } from './types';

interface PlannedEffortModuleProps {
  onShowSprintOverloadAlertsChange: (value: boolean) => void;
  recommendedEffort: number | undefined;
  showSprintOverloadAlerts: boolean | undefined;
  taskSummaries: TaskSummaries;
}

export function PlannedEffortModule({
  onShowSprintOverloadAlertsChange,
  recommendedEffort,
  showSprintOverloadAlerts,
  taskSummaries,
}: PlannedEffortModuleProps): JSX.Element {
  const totalPlannedEffort = taskSummaries.reduce((sum, { plannedEffort }) => sum + plannedEffort, 0);

  return (
    <SprintDetailsSidebarModule>
      <VStack space='$12px'>
        {recommendedEffort !== undefined && (
          <PlannedEffortEntry recommendedEffort={recommendedEffort} totalPlannedEffort={totalPlannedEffort} />
        )}
        <Box spaceHorz='$4px'>
          <HStack>
            <Text color='$grey6' size='$10px' weight='bold'>
              {strings.plannedEffort.showSprintOverloadAlerts}
            </Text>
            <Fluid />
            {showSprintOverloadAlerts !== undefined && (
              <Switch checked={showSprintOverloadAlerts} onChange={onShowSprintOverloadAlertsChange} />
            )}
          </HStack>
        </Box>
      </VStack>
    </SprintDetailsSidebarModule>
  );
}
