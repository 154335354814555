import { tokens } from '@taraai/design-system/dist/core/tokens';
import Icon from 'components/core/controllers/views/Icon';
import Input from 'components/core/controllers/views/Input';
import { css, cx } from 'emotion';
import React, { useCallback } from 'react';
import { dropDownTestIds } from 'resources/cypress/testAttributesValues';

interface Props {
  headerPlaceholder: string;
  headerTitle: string | string[];
  inputRef: React.RefObject<HTMLInputElement>;
  onClose: () => void;
  onKeyDown: (event: KeyboardEvent) => void;
  onSearchChange: (query: string) => void;
}

/**
 * DropdownHeader
 * optional header for dropdown component
 *
 */
export default function DropdownHeader({
  headerPlaceholder,
  headerTitle,
  inputRef,
  onClose,
  onKeyDown,
  onSearchChange,
}: Props): JSX.Element {
  return (
    <div
      className={css`
        padding: 1rem;
        box-shadow: 0 0.0625rem 0 0 #dee3ec;
        :hover {
          cursor: initial;
        }
        :focus {
          outline: none;
        }
      `}
      onClick={(event): void => event.stopPropagation()}
      onKeyDown={(event): void => event.stopPropagation()}
      role='button'
      tabIndex={0}
    >
      <div
        className={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 0.6875rem;
        `}
      >
        <span
          className={css`
            font-size: 0.875rem;
            color: #303f4b;
            font-weight: normal;
          `}
        >
          {headerTitle}
        </span>
        <Icon
          className={css`
            padding: 0rem;
            width: 0.5625rem;
            height: 0.5625rem;
          `}
          color={tokens.colors.$grey6}
          data-cy={dropDownTestIds.CLOSE_POPUP_BUTTON}
          name='exit'
          onClick={(event) => {
            event.preventDefault();
            onClose();
          }}
        />
      </div>
      <Input
        Ref={inputRef}
        className={cx(
          css`
            border: solid 0.0625rem #dee3ec;
          `,
        )}
        data-cy={dropDownTestIds.TEXT_INPUT}
        onChange={useCallback((event) => onSearchChange(event.target.value), [onSearchChange])}
        onKeyDown={onKeyDown}
        placeholder={headerPlaceholder}
        style={{
          main: css`
            padding-left: 0.5rem;
            margin-top: auto;
            margin-bottom: auto;
          `,
        }}
        type='text'
      />
    </div>
  );
}
