import { Fluid, HStack, styled, Text } from '@taraai/design-system';
import { UI } from '@taraai/types';
import { RequirementSelector } from 'components/app/controllers/Selectors/RequirementSelector';
import { UserSelector } from 'components/app/controllers/Selectors/UserSelector';
import { SectionType } from 'components/core/controllers/Selector';
import Avatar from 'components/core/controllers/views/Avatar';
import React, { useEffect, useState } from 'react';
import { strings } from 'resources';

import { SelectButton } from './SelectButton';

type RequirementFragment = Pick<UI.UIRequirement, 'id' | 'title'>;
type UserFragment = Pick<UI.UIUser, 'id' | 'name' | 'avatarURL'>;

type Props = {
  assignees: UserFragment[];
  requirements: RequirementFragment[];
  handleFiltering: (selectedAssignees: UserFragment[], selectedRequirements: RequirementFragment[]) => void;
};

export const Filters = ({ assignees, requirements, handleFiltering }: Props): JSX.Element => {
  const [selectedAssignees, setSelectedAssignees] = useState<UserFragment[]>([]);
  const [selectedRequirements, setSelectedRequirements] = useState<RequirementFragment[]>([]);

  const clearAssignees = (): void => setSelectedAssignees([]);
  const clearRequirements = (): void => setSelectedRequirements([]);

  useSelectedFiltersGuard(
    requirements,
    selectedRequirements,
    setSelectedRequirements,
    assignees,
    selectedAssignees,
    setSelectedAssignees,
  );

  useEffect(() => {
    handleFiltering(selectedAssignees, selectedRequirements);
  }, [selectedAssignees, selectedRequirements, handleFiltering]);

  const requirementsSection: SectionType<RequirementFragment> = {
    id: 'requirements',
    options: requirements,
  };
  const assigneesSection: SectionType<UserFragment> = {
    id: 'assignees',
    options: assignees,
  };

  return (
    <>
      <SelectorWrapper fractions={3}>
        <RequirementSelector
          closePopupOnSelection
          onDeselectOption={clearRequirements}
          onSelectOption={(requirement): void => setSelectedRequirements([requirement])}
          optionSize='small'
          renderSelectButton={(props) => (
            <SelectButton
              active={!!selectedRequirements.length}
              clearSelection={clearRequirements}
              name='requirementSelector'
              {...props}
            >
              <Text singleLine size='$14px' textAlign='left'>
                {selectedRequirements[0]
                  ? selectedRequirements[0].title
                  : strings.sprints.sprintTimeline.allRequirements}
              </Text>
            </SelectButton>
          )}
          searchPlaceholder={strings.sprints.sprintTimeline.searchRequirements}
          sections={[requirementsSection]}
          selection={selectedRequirements}
        />
      </SelectorWrapper>
      <SelectorWrapper fractions={2}>
        <UserSelector
          closePopupOnSelection
          onDeselectOption={clearAssignees}
          onSelectOption={(assignee): void => setSelectedAssignees([assignee])}
          optionSize='small'
          renderSelectButton={(props) => (
            <SelectButton
              active={!!selectedAssignees.length}
              clearSelection={clearAssignees}
              name='userSelector'
              {...props}
            >
              <HStack alignY='center' space='$8px'>
                {selectedAssignees[0] && <Avatar size='small' user={selectedAssignees[0]} />}
                <Fluid>
                  <Text singleLine size='$14px' textAlign='left'>
                    {selectedAssignees[0] ? selectedAssignees[0].name : strings.sprints.sprintTimeline.allAssignees}
                  </Text>
                </Fluid>
              </HStack>
            </SelectButton>
          )}
          searchPlaceholder={strings.sprints.sprintTimeline.searchUsers}
          sections={[assigneesSection]}
          selection={selectedAssignees}
        />
      </SelectorWrapper>
    </>
  );
};

const SelectorWrapper = styled(Fluid, { height: '2rem' });

/** Reset filters if selected filter is not a part of filtering data */
export function useSelectedFiltersGuard(
  requirements: RequirementFragment[],
  selectedRequirements: RequirementFragment[],
  setSelectedRequirements: (requirements: RequirementFragment[]) => void,
  assignees: UserFragment[],
  selectedAssignees: UserFragment[],
  setSelectedAssignees: (assignees: UserFragment[]) => void,
): void {
  useEffect(() => {
    const invalidSelectedRequirement =
      selectedRequirements[0] && !requirements.some(({ id }) => id === selectedRequirements[0].id);
    if (invalidSelectedRequirement) setSelectedRequirements([]);
  }, [requirements, selectedRequirements, setSelectedRequirements]);

  useEffect(() => {
    const invalidSelectedAssignee = selectedAssignees[0] && !assignees.some(({ id }) => id === selectedAssignees[0].id);
    if (invalidSelectedAssignee) setSelectedAssignees([]);
  }, [assignees, selectedAssignees, setSelectedAssignees]);
}
