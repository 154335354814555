import styled from '@emotion/styled';
import { Data } from '@taraai/types';
import { EditTeamDetailsController } from 'components/app/controllers/EditTeamDetailsController';
import { MemberListController } from 'components/app/controllers/MemberListController';
import { TeamDetailsHeader } from 'components/app/controllers/views/TeamDetailsHeader';
import Modal from 'components/core/controllers/views/Modal';
import { css } from 'emotion';
import React from 'react';

interface Props {
  canLeave: boolean;
  isMember: boolean;
  isPreferred: boolean;
  hasAlternativeTeams: boolean;
  isSprintCompleteOrActive?: boolean;
  noModal: boolean;
  orgId: Data.Id.OrganizationId;
  profileId: string;
  teamId: string;
  teamName: string;
  closeModal?: () => void;
  username: string;
}

/**
 * TeamDetailsModal
 * Team details view
 */
export function TeamDetailsModal({
  canLeave,
  isMember,
  isPreferred,
  hasAlternativeTeams,
  isSprintCompleteOrActive,
  noModal = false,
  closeModal,
  orgId,
  profileId,
  teamId,
  teamName,
  username,
}: Props): JSX.Element {
  return (
    <Modal
      className={css`
        width: 60rem;
        border: solid 0.0625rem #dee3ec;
      `}
      closeModal={closeModal}
      header={
        <TeamDetailsHeader
          hasAlternativeTeams={hasAlternativeTeams}
          isMember={isMember}
          isSprintCompleteOrActive={isSprintCompleteOrActive}
          name={teamName}
          orgId={orgId}
          teamId={teamId}
        />
      }
      headerStyle={css`
        padding: 0.75rem 0.9375rem 0.75rem 1.5rem;
        border-top-left-radius: 0.375rem;
        border-top-right-radius: 0.375rem;
      `}
      noModal={noModal}
    >
      <EditTeamDetailsController
        isMember={isMember}
        isPreferred={isPreferred}
        profileId={profileId}
        teamId={teamId}
        teamName={teamName}
        username={username}
      />
      <Separator />
      <MemberListController
        canLeave={canLeave}
        orgId={orgId}
        profileId={profileId}
        teamId={teamId}
        teamName={teamName}
      />
    </Modal>
  );
}

const Separator = styled.hr`
  background-color: #eaeef5;
  height: 1px;
  border: none;
  margin: 1.5rem 0;
`;
