"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.maxTooltipWidth = exports.Tooltip = void 0;
var react_1 = __importStar(require("react"));
var Box_1 = require("./Box");
var RelativePositioning_1 = require("./common/RelativePositioning");
var useHover_1 = require("./common/useHover");
var core_1 = require("./core");
var typography_1 = require("./typography");
function Tooltip(_a) {
    var children = _a.children, content = _a.content, _b = _a.disabled, disabled = _b === void 0 ? false : _b, _c = _a.placement, placement = _c === void 0 ? 'top' : _c, title = _a.title;
    var childRef = (0, react_1.useRef)(null);
    var show = (0, useHover_1.useHover)(childRef);
    return (react_1.default.createElement(RelativePositioning_1.RelativePositioning, { content: react_1.default.createElement(Box_1.Box, { background: '$grey7', borderRadius: '$2px', maxWidth: exports.maxTooltipWidth, role: 'tooltip', spaceHorz: '$12px', spaceVert: '$4px' },
            react_1.default.createElement(typography_1.Text, { color: '$white', size: '$10px', weight: 'medium' }, title),
            content), offset: 8, placement: placement, show: !disabled && show }, (0, react_1.cloneElement)(children, { ref: childRef })));
}
exports.Tooltip = Tooltip;
exports.maxTooltipWidth = (0, core_1.getCustomSize)(250);
