import Text from 'components/core/controllers/views/Text';
import { css, cx } from 'emotion';
import React from 'react';
import { atomic } from 'resources';

export interface InputGroupProps extends React.HTMLProps<HTMLInputElement> {
  /**
   * If present, prepend this string to the input
   */
  prepend?: string;
  /**
   * If present, append this string to the input
   */
  append?: string;
  /**
   * Only string input types are supported as other's don't look right
   */
  type?:
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'month'
    | 'number'
    | 'password'
    | 'search'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'week';
  labelStyle?: string;
  prependStyle?: string;
  appendStyle?: string;
  bottomLabel?: string;
  bottomLabelStyle?: string;
  containerStyle?: string;
}

/**
 * Input groups support a prepended or appended string or icon
 *
 * - [Designs](https://zpl.io/a7e3qGv)
 */
export default function InputGroup({
  prepend = '',
  append = '',
  type = 'text',
  min,
  max,
  onChange = (): void => undefined,
  value,
  className,
  prependStyle,
  appendStyle,
  label,
  labelStyle,
  id,
  bottomLabel,
  bottomLabelStyle,
  containerStyle,
  ...props
}: InputGroupProps): JSX.Element {
  const endStyles = css`
    padding: 0.7rem;
    user-select: none;
  `;
  return (
    <div>
      {label?.trim().length !== 0 && (
        <Text
          className={cx(
            css`
              margin-bottom: 0.5rem;
              ${atomic.color(atomic.colors.dark)}
              ${atomic.fontWeight('400')}
            `,
            labelStyle,
          )}
        >
          {label}
        </Text>
      )}
      <div
        className={cx(
          css`
            background-color: #eef1f7;
            border: 0.0625rem solid #c8d0df;
            border-radius: var(--atomic-input-border-radius-regular);
            font-size: 1rem;
            margin: 0.25rem 0;
            color: #929cb1;
            position: relative;
            display: flex;
            align-items: stretch;
          `,
          containerStyle,
        )}
      >
        <span
          className={cx(
            css`
              border-right: 0.0625rem solid #c8d0df;
              border-top-left-radius: 0.125rem;
              border-bottom-left-radius: 0.125rem;
              display: ${prepend.length > 0 ? 'flex' : 'none'};
            `,
            endStyles,
            prependStyle,
          )}
        >
          {prepend}
        </span>
        <input
          className={cx(
            css`
              border-radius: 0.125rem;
              font-size: 1rem;
              border: none;
              padding: 0.7rem;
              position: relative;
              background-color: #fff;
              flex: 1 1 auto;
              &::placeholder {
                color: #949caf;
              }
            `,
            className,
          )}
          max={max}
          min={min}
          onChange={onChange}
          type={type}
          value={value}
          {...props}
        />
        <span
          className={cx(
            css`
              border-left: 0.0625rem solid #c8d0df;
              border-top-right-radius: 0.125rem;
              border-bottom-right-radius: 0.125rem;
              display: ${append.length > 0 ? 'inline-block' : 'none'};
            `,
            endStyles,
            appendStyle,
          )}
        >
          {append}
        </span>
      </div>
      {bottomLabel?.trim().length !== 0 && (
        <Text
          className={cx(
            css`
              display: block;
              ${atomic.fontWeight('400')}
            `,
            bottomLabelStyle,
          )}
        >
          {bottomLabel}
        </Text>
      )}
    </div>
  );
}
