import { Data, UI } from '@taraai/types';
import { isNonEmptyString } from '@taraai/utility';
import { createStandardSelector, inertQuery, Query } from 'reduxStore/utils/selectors';
import { ReduxFirestoreQuery } from 'reduxStore/utils/types';

export const getTaskPullRequestsSummaries = (
  orgId: Data.Id.OrganizationId,
  taskId: Data.Id.TaskId,
): Query<UI.UIPullRequestSummary> => {
  if (![orgId, taskId].every(isNonEmptyString)) {
    return inertQuery();
  }

  const query: ReduxFirestoreQuery = {
    collection: `orgs/${orgId}/tasks-pull-requests-summaries`,
    where: [['taskId', '==', taskId]],
    storeAs: `summaries/${orgId}/${taskId}/pull-requests`,
  };

  return {
    query: [query],
    selector: createStandardSelector<UI.UIPullRequestSummary>(query),
  };
};
