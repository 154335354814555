import { Data, UI } from '@taraai/types';

export const formatIdWithRepositoryName = (
  pullRequest: UI.UIPullRequest | Data.PullRequest<Data.ExternalRepo.Github> | UI.UIPullRequestSummary['data'],
): string => {
  if (pullRequest && pullRequest.externalService && pullRequest.externalService.repository) {
    const [, repo] = pullRequest.externalService.repository.split('/');
    return `${repo}#${pullRequest.pullRequestId}`;
  }
  return '';
};
