"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setDifference = exports.unique = exports.areEqual = exports.chunk = exports.notEmpty = void 0;
function notEmpty(value) {
    return value !== null && value !== undefined;
}
exports.notEmpty = notEmpty;
function chunk(arr, size) {
    return Array.from({ length: Math.ceil(arr.length / size) }, function (_value, index) {
        return arr.slice(index * size, index * size + size);
    });
}
exports.chunk = chunk;
function areEqual(array1, array2) {
    var index = array1.length;
    if (index !== array2.length)
        return false;
    // eslint-disable-next-line no-loops/no-loops
    while (index--) {
        if (array1[index] !== array2[index])
            return false;
    }
    return true;
}
exports.areEqual = areEqual;
function unique(array) {
    return Array.from(new Set(array));
}
exports.unique = unique;
/**
 * Returns a set difference of two arrays: the set of all elements in A that are not in B.
 * @param array1 A
 * @param array2 B
 */
function setDifference(arrayA, arrayB) {
    var setB = new Set(arrayB);
    return arrayA.filter(function (element) { return !setB.has(element); });
}
exports.setDifference = setDifference;
