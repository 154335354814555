function isObject(obj: unknown): obj is Record<string, unknown> {
  return Object.prototype.toString.call(obj) === '[object Object]';
}

function flattenNestedMap(obj: Record<string, unknown>, path: string): Record<string, unknown> {
  return Object.keys(obj).reduce((result, key) => {
    const val = obj[key];
    if (isObject(val)) {
      const nestedPath = `${path}${key}.`;
      return { ...result, ...flattenNestedMap(val, nestedPath) };
    }
    return { ...result, [`${path}${key}`]: val };
  }, {});
}

export function flattenNestedAttribute<T extends Record<string, unknown>>(
  obj: T,
  key: keyof T,
): Record<string, unknown> {
  const { [key]: value, ...rest } = obj;

  if (value && isObject(value)) {
    return { ...rest, ...flattenNestedMap(value, `${key}.`) };
  }

  return obj;
}
