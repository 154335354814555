"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimezoneDecoder = void 0;
var ts_data_json_1 = require("ts.data.json");
var timezone_1 = require("../data/timezone");
exports.TimezoneDecoder = new ts_data_json_1.JsonDecoder.Decoder(function (value) {
    var match = timezone_1.TIMEZONES_SHORTLIST.find(function (timezone) { return timezone.value === value; });
    if (match) {
        return (0, ts_data_json_1.ok)(match.value);
    }
    return (0, ts_data_json_1.err)("".concat(value, " is not a timezone"));
});
