import { createSlice } from '@reduxjs/toolkit';
import { Data } from '@taraai/types';

const team = createSlice({
  name: 'team',
  initialState: '' as Data.Id.TeamId,
  reducers: {
    setCurrentTeamId: (state, action): void => action.payload,
  },
});

export const teamReducer = team.reducer;

export const setTeam = team.actions.setCurrentTeamId;

export const selectActiveTeam = (state: { team: string }): string => state.team;
