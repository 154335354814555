"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.keys = exports.filterObjectByKey = void 0;
/**
 * Filter values from object
 */
function filterObjectByKey(obj, predicate) {
    return Object.entries(obj).reduce(function (acc, _a) {
        var _b;
        var key = _a[0], value = _a[1];
        if (predicate(key)) {
            return __assign(__assign({}, acc), (_b = {}, _b[key] = value, _b));
        }
        return acc;
    }, {});
}
exports.filterObjectByKey = filterObjectByKey;
/**
 * Object.keys but with generics
 */
function keys(object) {
    return Object.keys(object);
}
exports.keys = keys;
