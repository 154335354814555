import { Data, UI } from '@taraai/types';
import { getTeamId } from 'route-utils';

/**
 * Since GlobalNav is not a part of the route components, we can't rely on useParams hook.
 * This function parses current URL and returns the selected teamId if it is present and valid.
 */
export const getSelectedTeamId = (orgTeams: UI.UITeam[]): Data.Id.TeamId | null => {
  // This may be either real team id, or nothing, or part of another path
  // (e.g. /tara-ai/requirements will match)
  const potentialTeamId = getTeamId();

  // That's why we need to check if such team exists in current organization
  if (potentialTeamId && orgTeams.some(({ id }) => id === potentialTeamId)) return potentialTeamId;

  return null;
};
