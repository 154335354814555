import { createAsyncThunk } from '@reduxjs/toolkit';
import { ExtraAPI } from 'reduxStore/utils/types';

export const removeOrganizationLogo = createAsyncThunk(
  'RemoveOrganizationLogo',
  async (actionPayload: void, { extra }) => {
    const { getOrgId, getFirestore } = extra as ExtraAPI;
    const orgId = getOrgId();

    return getFirestore().update(`orgs/${orgId}`, { logoURL: null });
  },
);
