import { UI } from '@taraai/types';
import { css } from 'emotion';
import React from 'react';
import { strings } from 'resources/i18n';

type SprintFragment = Pick<UI.UISprint, 'id' | 'sprintName' | 'computedOnCompletion'>;
export interface SprintInsightsFooterProps {
  lastNCompletedSprints: SprintFragment[] | undefined;
  estimatedEffort: number;
}

/**
 * SprintInsightsFooter does… renders footer for sprint insights modal
 *
 */

export default function SprintInsightsFooter({
  lastNCompletedSprints = [],
  estimatedEffort = 0,
}: SprintInsightsFooterProps): JSX.Element {
  return (
    <div
      className={css`
        padding-top: 0.75rem;
      `}
    >
      <div
        className={css`
          color: #303f4b;
          font-size: 0.875rem;
          font-weight: 500;
          padding-bottom: 0.875rem;
        `}
      >
        {strings.sprints.insights.pastSprintCompletedEffort}
      </div>
      <div
        className={css`
          display: flex;
          border: solid 0.0625rem #eaeef5;
        `}
      >
        {lastNCompletedSprints &&
          lastNCompletedSprints.map((sprint) => (
            <div
              key={sprint.id}
              className={css`
                :nth-child(odd) {
                  background: #fbfbfd;
                }
                :nth-child(even) {
                  background: #ffffff;
                }
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 0.9375rem 1.25rem;
                flex-basis: 100%;
              `}
            >
              <div
                className={css`
                  color: #575f65;
                  font-size: 0.75rem;
                  font-weight: 500;
                `}
              >
                {sprint && sprint.sprintName}
              </div>
              <div
                className={css`
                  color: #575f65;
                  font-size: 1rem;
                  font-weight: 500;
                  padding-top: 0.25rem;
                `}
              >
                {sprint.computedOnCompletion.totalEffortCompleted}
              </div>
              <div
                className={css`
                  color: #949caf;
                  opacity: 0.6;
                  font-style: italic;
                  font-size: 0.625rem;
                  font-weight: 500;
                `}
              >
                {strings.formatString(strings.sprints.insights.outOf, {
                  number: sprint.computedOnCompletion.totalEffortEstimated as number,
                })}
              </div>
            </div>
          ))}
        <div
          className={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0.9375rem 1.25rem;
            background-color: #ddf4d9;
            flex-basis: 100%;
          `}
        >
          <div
            className={css`
              color: #389e0d;
              font-size: 0.75rem;
              font-weight: 500;
            `}
          >
            {strings.sprints.insights.average}
          </div>
          <div
            className={css`
              color: #389e0d;
              font-size: 1rem;
              font-weight: 500;
              padding-top: 0.25rem;
            `}
          >
            {estimatedEffort}
          </div>
          <div
            className={css`
              opacity: 0.6;
              font-style: italic;
              font-size: 0.625rem;
              color: #389e0d;
              font-weight: 500;
            `}
          >
            {strings.sprints.insights.recommended}
          </div>
        </div>
      </div>
    </div>
  );
}
