import { Box, HStack, Radio, Spacer, Text, VStack } from '@taraai/design-system';
import { Data } from '@taraai/types';
import React, { useMemo } from 'react';
import { strings } from 'resources';

import { SprintSettingsViewProps } from './types';

export const SprintDuration = ({ onChange, sprintSettings, testMode }: SprintSettingsViewProps): JSX.Element => {
  const durationValues = useMemo(() => {
    return [
      '1 week',
      '2 weeks',
      // only show 1 hour option in test mode (or if it's already selected)
      ...(testMode || sprintSettings.duration === '1 hour' ? ['1 hour'] : []),
    ] as Data.SprintDuration[];
  }, [sprintSettings, testMode]);

  return (
    <Box background='$grey1' spaceHorz='$16px' spaceVert='$12px'>
      <VStack>
        <Text color='$dark' size='$14px'>
          {strings.sprintSettings.sprintDuration}
        </Text>
        <Spacer space='$4px' />
        <Text color='$grey6' size='$12px'>
          {strings.sprintSettings.selectAWeeklyTimePeriod}
        </Text>
        <Spacer space='$16px' />
        <Radio.Group
          onChange={(duration: Data.SprintDuration) => onChange({ ...sprintSettings, duration })}
          value={sprintSettings.duration}
        >
          <HStack space='$16px'>
            {durationValues.map((option) => (
              <Radio key={option} value={option}>
                <Text size='$12px' weight='medium'>
                  {strings.sprintSettings.durations[option]}
                </Text>
              </Radio>
            ))}
          </HStack>
        </Radio.Group>
      </VStack>
    </Box>
  );
};
