import 'react-datepicker/dist/react-datepicker.css';

import { Data, Timestamp, UI } from '@taraai/types';
import { addDuration } from '@taraai/utility';
import SprintPlanning from 'components/app/controllers/views/SprintPlanning';
import Icon from 'components/core/controllers/views/Icon';
import Input from 'components/core/controllers/views/Input';
import Text from 'components/core/controllers/views/Text';
import { css, cx } from 'emotion';
import React, { useEffect, useState } from 'react';
import { sprintsTabTestIds } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';
import { firestore } from 'tools/libraries/firebaseValues';
import { formatDMMMYYYY } from 'tools/libraries/helpers/dates';

export interface EditSprintBodyProps extends React.HTMLProps<HTMLDivElement> {
  /**
   * The duration of the sprint in weeks
   */
  currentDuration: Data.SprintDuration;
  currentSprint: Data.Sprint;
  setStartAndEndTimestamps: (startDate: Timestamp, endDate: Timestamp) => void;
  setSprintName: (name: string) => void;
  estimatedEffort: number;
  totalEffort: number;
  allSprintTasks: UI.UITask[] | undefined;
  unassignedSprintTasks: UI.UITask[] | undefined;
  renderNewSprintPreview: (sprintName?: string) => string;
}

/**
 * Body for the Sprint Start/Edit Modal component. The body takes in a start date and spits out an end date based
 * on the sprint duration setting
 */
export default function EditSprintBody({
  currentDuration,
  currentSprint,
  setStartAndEndTimestamps,
  setSprintName,
  estimatedEffort,
  totalEffort,
  allSprintTasks,
  unassignedSprintTasks,
  renderNewSprintPreview,
  className,
  ...props
}: EditSprintBodyProps): JSX.Element {
  const { startDate } = currentSprint;
  const [bodySprintName, setBodySprintName] = useState(currentSprint.sprintName);
  const endDate = formatDMMMYYYY(firestore.Timestamp.fromDate(addDuration(startDate.toDate(), currentDuration)));

  const handleNameChange = (event: React.BaseSyntheticEvent): void => {
    setBodySprintName(event.target.value);
  };

  useEffect(() => {
    setStartAndEndTimestamps(startDate, firestore.Timestamp.fromDate(addDuration(startDate.toDate(), currentDuration)));
    setSprintName(bodySprintName);
  }, [currentDuration, endDate, setSprintName, setStartAndEndTimestamps, bodySprintName, startDate]);

  return (
    <div
      className={cx(
        css`
          display: flex;
          flex-direction: column;
          padding-bottom: 1.5rem;
        `,
        className,
      )}
      {...props}
    >
      {estimatedEffort > 0 && totalEffort > estimatedEffort && (
        <div
          className={css`
            display: flex;
            padding-bottom: 1.25rem;
          `}
        >
          <Icon
            className={css`
              padding: 0rem;
              padding-top: 0.0625rem;
              padding-right: 0.25rem;
              display: flex;
              align-self: auto;
              height: 0.875rem;
              width: 0.875rem;
            `}
            name='overload'
          />
          <div>
            <span
              className={css`
                color: #d99b59;
                font-size: 0.875rem;
              `}
            >
              {strings.sprints.edit.youMightHave}
            </span>
            <span
              className={css`
                color: #d99b59;
                font-size: 0.875rem;
                font-weight: 600;
              `}
            >
              {strings.sprints.edit.overloadedYourSprint}
            </span>
            <span
              className={css`
                color: #d99b59;
                font-size: 0.875rem;
              `}
            >
              {strings.sprints.edit.takeALook}
            </span>
          </div>
        </div>
      )}
      <SprintPlanning
        allSprintTasks={allSprintTasks}
        currentSprint={currentSprint}
        estimatedEffort={estimatedEffort}
        totalEffort={totalEffort}
        unassignedSprintTasks={unassignedSprintTasks}
      />
      <Text
        className={css`
          color: #303f4b;
          font-size: 0.875rem;
          font-weight: normal;
          padding-top: 1.25rem;
          padding-bottom: 0.5rem;
        `}
      >
        {strings.sprints.edit.sprintName}
      </Text>
      <Input
        className={css`
          color: #303f4b;
          font-size: 0.875rem;
          width: 10.5rem;
        `}
        data-cy={sprintsTabTestIds.SPRINT_EDIT_MODAL.EDIT_NAME_FIELD}
        onChange={handleNameChange}
        style={{
          main: css`
            ::placeholder {
              opacity: 0.7;
            }
            padding-left: 0.75rem;
          `,
        }}
        value={bodySprintName}
      />
      <Text
        className={css`
          color: #67728b;
          font-size: 0.75rem;
          font-weight: normal;
          margin-top: 0.5rem;
        `}
      >
        {renderNewSprintPreview(bodySprintName)}
      </Text>
      <Text
        className={css`
          margin-top: 1rem;
          color: #303f4b;
          font-size: 0.875rem;
          font-weight: normal;
        `}
      >
        {strings.sprints.edit.duration}
      </Text>
      <Text
        className={css`
          margin-top: 1rem;
          color: #67728b;
          font-size: 0.875rem;
          font-weight: normal;
        `}
      >
        {strings.sprints.durationLength[currentDuration]}
      </Text>
      <div
        className={css`
          margin-top: 1rem;
          display: flex;
          flex-direction: row;
        `}
      >
        <div
          className={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <Text
            className={css`
              font-size: 0.875rem;
              color: #303f4b;
              font-weight: normal;
              margin-bottom: 0.25rem;
            `}
          >
            {strings.sprints.edit.startDate}
          </Text>
          <div
            className={css`
              padding: 0.8125rem 0rem;
            `}
          >
            <Text
              className={css`
                font-size: 0.875rem;
                font-weight: normal;
                align-self: center;
              `}
            >
              {formatDMMMYYYY(startDate)}
            </Text>
          </div>
        </div>
        <div
          className={css`
            margin: 0rem 0.5rem;
            margin-top: 1.125rem;
            background-color: #c8d0df;
            width: 1rem;
            height: 0.0625rem;
            align-self: center;
          `}
        />
        <div
          className={css`
            display: flex;
            flex-direction: column;
            align-self: center;
          `}
        >
          <Text
            className={css`
              font-size: 0.875rem;
              color: #303f4b;
              font-weight: normal;
              margin-bottom: 0.25rem;
            `}
          >
            {strings.sprints.edit.endDate}
          </Text>
          <div
            className={css`
              padding: 0.8125rem 0rem;
            `}
          >
            <Text
              className={css`
                font-size: 0.875rem;
                font-weight: normal;
                align-self: center;
              `}
            >
              {endDate}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
}
