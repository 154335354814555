import { RootState } from 'reduxStore/store';
import { auth } from 'tools/libraries';

export function selectAuth(state: RootState): RootState['firebase']['auth'] {
  return state.firebase.auth;
}

export async function getCurrentUserIdToken(): Promise<string | undefined> {
  return auth().currentUser?.getIdToken(true);
}
