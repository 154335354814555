import { Data } from '@taraai/types';
import GitSnippet from 'components/app/controllers/views/GitSnippet';
import Icon from 'components/core/controllers/views/Icon';
import { css } from 'emotion';
import React, { useRef } from 'react';
import { strings } from 'resources/i18n';
import { useClickOutside } from 'tools';

export interface GithubInfoPopupProps extends React.HTMLProps<HTMLDivElement> {
  onClose: () => void;
  taskId: Data.Id.TaskId;
  isGithubInstalled: boolean;
}

/**
 * GithubInfoPopup
 * info for github popup
 *
 */
export default function GithubInfoPopup({ onClose, taskId, isGithubInstalled }: GithubInfoPopupProps): JSX.Element {
  const popupRef = useRef<HTMLDivElement>(null);
  useClickOutside(popupRef, onClose);
  const media = window.matchMedia('(max-width:500px)');

  return (
    <div
      ref={popupRef}
      className={css`
        ${media.matches ? 'width: 90%;' : 'width: 23.375rem; '}
        border-radius: 0.1875rem;
        box-shadow: 0 0 0.625rem 0 rgba(0, 0, 0, 0.14);
        background-color: white;
      `}
    >
      <div
        className={css`
          border-bottom: solid 1px #eaeef5;
          display: flex;
          padding: 1rem;
        `}
      >
        <Icon
          className={css`
            padding: 0rem;
            padding-right: 0.6875rem;
          `}
          name='miniInfoCircle'
        />
        <div
          className={css`
            font-size: 0.875rem;
            font-weight: normal;
            color: #303f4b;
            padding-right: 1.25rem;
          `}
        >
          {strings.gitTaskLifecycle.linkYourTask}
          <span
            className={css`
              color: #1d98ff;
            `}
          >
            {strings.formatString(strings.gitTaskLifecycle.taskId, {
              taskId,
            })}
          </span>
          {strings.gitTaskLifecycle.inYourBranch}
        </div>
      </div>
      <div
        className={css`
          padding: 1rem;
        `}
      >
        <div
          className={css`
            color: #67728b;
            font-size: 0.875rem;
            font-weight: normal;
            margin-bottom: 0.75rem;
          `}
        >
          {isGithubInstalled ? strings.gitTaskLifecycle.prExample : strings.gitTaskLifecycle.branchExample}
        </div>
        {isGithubInstalled ? <GitSnippet pullRequest taskId={taskId} /> : <GitSnippet branch taskId={taskId} />}
        <div
          className={css`
            color: #67728b;
            font-size: 0.875rem;
            font-weight: normal;
            margin-top: 1rem;
            margin-bottom: 0.75rem;
          `}
        >
          {strings.gitTaskLifecycle.commitExample}
        </div>
        <GitSnippet taskId={taskId} />
      </div>
    </div>
  );
}
