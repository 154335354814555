import Button from 'components/core/controllers/views/Button';
import Modal from 'components/core/controllers/views/Modal';
import Text from 'components/core/controllers/views/Text';
import { css } from 'emotion';
import React from 'react';
import { strings } from 'resources/i18n';

export interface DeleteCommentModalProps {
  onClose: () => void;
  onSubmit: () => void;
}

export default function DeleteCommentModal({ onClose, onSubmit }: DeleteCommentModalProps): JSX.Element {
  return (
    <Modal
      bodyStyle={css`
        width: 30rem;
      `}
      closeModal={onClose}
      footer={
        <div
          className={css`
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
          `}
        >
          <Button
            className={css`
              margin-right: 0.8125rem;
            `}
            color='ghost'
            name='cancel'
            onClick={onClose}
          >
            {strings.comments.deleteModal.cancel}
          </Button>
          <Button color='red' name='delete' onClick={onSubmit}>
            {strings.comments.deleteModal.delete}
          </Button>
        </div>
      }
      header={
        <Text
          className={css`
            color: #303f4b;
            font-size: 1rem;
            font-weight: 500;
          `}
        >
          {strings.comments.deleteModal.title}
        </Text>
      }
      zIndexPlus={1}
    >
      <div
        className={css`
          width: 100%;
        `}
      >
        {strings.comments.deleteModal.areYouSure}
      </div>
    </Modal>
  );
}
