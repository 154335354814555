import { createSelector, unwrapResult } from '@reduxjs/toolkit';
import { Box, Fluid, HStack, styled } from '@taraai/design-system';
import { isLoaded, useFirestoreConnect } from '@taraai/read-write';
import { UI } from '@taraai/types';
import { SprintSummaryOption } from '@taraai/types/dist/ui';
import { EmptySectionHeader, SectionFooter, SectionHeader } from 'components/app/controllers/Selectors/common/Section';
import {
  OptionRenderProps,
  PopupHandle,
  SectionRenderProps,
  Selector as SelectorComponent,
} from 'components/core/controllers/Selector';
import React, { forwardRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  getOrgTeams,
  getUpcomingSprints,
  selectActiveTeam,
  selectActiveWorkspace,
  selectPreferredTeamId,
  selectProfile,
  selectTeam,
  useAppDispatch,
} from 'reduxStore';
import { updateTask } from 'reduxStore/tasks/actions/update';
import { strings } from 'resources/i18n';
import { useToast } from 'tools';

import { SprintHeader } from './Header';
import SelectButton from './SelectButton';
import SprintOptionView from './SprintOption';
import {
  BacklogOption,
  BacklogSection,
  SprintSelectorOption,
  SprintSelectorSection,
  SprintsSummarySection,
} from './types';

export interface SprintSelectorProps {
  task: Pick<UI.UITask, 'id' | 'sprint'>;
  dataCy?: string;
}

/**
 * SprintSelector allows to select single sprint
 *
 * user can choose from all organization sprints grouped by teams
 */
export const SprintSelector = forwardRef<PopupHandle, SprintSelectorProps>(
  // eslint-disable-next-line sonarjs/cognitive-complexity
  function SprintSelector({ task, dataCy }, ref) {
    const dispatch = useAppDispatch();
    const orgId = useSelector(selectActiveWorkspace);
    const teamId = useSelector(selectActiveTeam);

    const preferredTeamId = useSelector(selectPreferredTeamId(orgId));
    const preferredTeam = useSelector(selectTeam(orgId, preferredTeamId));

    if (!isLoaded(preferredTeam) || !preferredTeam) {
      throw new Error(`Team ${orgId}/${preferredTeamId} document was not loaded at the time of selector access`);
    }

    const orgTeamsSlice = getOrgTeams(orgId);

    const userTeams = useSelector(
      createSelector(orgTeamsSlice.selector, selectProfile, (allTeams, profile) =>
        allTeams?.filter((team) => profile.teamIds[orgId].includes(team.id) && team.id !== preferredTeamId),
      ),
    );

    const otherOrgTeams = useSelector(
      createSelector(orgTeamsSlice.selector, selectProfile, (allTeams, profile) =>
        allTeams?.filter((team) => !profile.teamIds[orgId].includes(team.id)),
      ),
    );

    const upcomingSprintsSlice = getUpcomingSprints(orgId, teamId);
    const upcomingSprintOptions = useSelector(upcomingSprintsSlice.selector) || [];

    useFirestoreConnect(upcomingSprintsSlice.query);

    function buildOptionsForAGivenTeam(team: UI.UITeam): UI.SprintSummaryOption[] {
      return upcomingSprintOptions.filter((sprint: SprintSummaryOption) => sprint.teamId === team.id);
    }

    function buildSprintsForTeams(teams: UI.UITeam[]): SprintsSummarySection[] {
      return teams?.map((team) => {
        const options: SprintSummaryOption[] = buildOptionsForAGivenTeam(team);
        return {
          ...team,
          options,
        };
      });
    }

    const sprintOptionsForPreferredTeam: UI.SprintSummaryOption[] = upcomingSprintOptions.filter(
      (sprint) => sprint.teamId === preferredTeamId,
    );
    const sprintOptionsForTeams: SprintsSummarySection[] = buildSprintsForTeams(userTeams || []);
    const sprintOptionsForOtherTeams: SprintsSummarySection[] = buildSprintsForTeams(otherOrgTeams || []);

    const sections: SprintSelectorSection[] = [
      backlogSection,
      {
        ...preferredTeam,
        name: `${preferredTeam.name} ${strings.sprintDropdown.preferred}`,
        options: sprintOptionsForPreferredTeam ?? [],
      },
      ...(sprintOptionsForTeams ?? []),
      ...(sprintOptionsForOtherTeams ?? []),
    ];

    const selectedOption: SprintSelectorOption =
      sections
        .flatMap((section: SprintSelectorSection): SprintSelectorOption[] => section.options)
        .find((option) => option?.id === task.sprint) ?? backlog;

    const { whenError } = useToast();

    const handleSelectedSprint = useCallback(
      (option: SprintSelectorOption): void => {
        dispatch(
          updateTask({
            id: task.id,
            sprint: option.id === 'backlog' ? null : option.id,
          }),
        )
          .then(unwrapResult)
          .catch(whenError(strings.task.failedToUpdateTask));
      },
      [dispatch, whenError, task],
    );

    return (
      <Box full spaceVert='$8px'>
        <Container>
          <Fluid>
            <HStack align='right'>
              <SelectorComponent
                ref={ref}
                closePopupOnSelection
                dataCy={dataCy}
                onSelectOption={handleSelectedSprint}
                orgId={orgId}
                renderHeader={SprintHeader}
                renderOption={({
                  option: sprint,
                  isActive: isActiveOption,
                  isSelected: isSelectedOption,
                }: OptionRenderProps<SprintSelectorOption>): JSX.Element => (
                  <SprintOptionView
                    dataCy={dataCy}
                    isActiveOption={isActiveOption}
                    isSelectedOption={isSelectedOption}
                    sprint={sprint}
                  />
                )}
                renderSectionFooter={({ section }: SectionRenderProps<SprintSelectorSection>): JSX.Element | null =>
                  section.options?.length > 0 ? <SectionFooter /> : null
                }
                renderSectionHeader={({ section }: SectionRenderProps<SprintSelectorSection>): JSX.Element | null => {
                  if (section.options?.length === 0) return null;
                  if (section.id === 'backlog') return <EmptySectionHeader />;
                  return <SectionHeader>{section.name}</SectionHeader>;
                }}
                renderSelectButton={({ openPopup }): JSX.Element => (
                  <SelectButton dataCy={dataCy} onClick={openPopup} selectedOption={selectedOption} />
                )}
                sections={sections}
                selection={selectedOption}
                taskId={task.id}
              />
            </HStack>
          </Fluid>
        </Container>
      </Box>
    );
  },
);

const backlog: BacklogOption = {
  id: 'backlog',
  isActive: false,
  sprintName: strings.sprintDropdown.backlog,
};

const backlogSection: BacklogSection = {
  id: 'backlog',
  name: strings.sprintDropdown.backlog,
  options: [backlog],
};

const Container = styled('div', {
  cursor: 'pointer',
  alignItems: 'center',
  display: 'flex',
  outline: '0',
});
