import { styled } from '@taraai/design-system';
import Avatar from 'components/core/controllers/views/Avatar';
import Icon from 'components/core/controllers/views/Icon';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { compose } from 'redux';
import { selectAuth, selectProfile } from 'reduxStore';
import { useClickOutside } from 'tools/utils/hooks/useClickOutside';

import { Popup } from './Popup';

export function ProfileSection(): JSX.Element {
  const profile = useSelector(selectProfile);
  const email = useSelector(compose((auth) => auth.email, selectAuth));

  const [showPopup, setShowPopup] = useState(false);

  const openPopup = useCallback(() => {
    setShowPopup(true);
  }, []);

  const closePopup = useCallback(() => {
    setShowPopup(false);
  }, []);

  const history = useHistory();

  useEffect(() => closePopup(), [history.location.pathname, closePopup]);

  const popupRef = useRef<HTMLDivElement>(null);
  useClickOutside(popupRef, closePopup);

  return (
    <Container interactive={!showPopup} onClick={openPopup}>
      <Avatar size='1.5rem' user={profile} />
      <ArrowIndicator name='arrowDown' />
      {showPopup && <Popup containerRef={popupRef} email={email} profile={profile} />}
    </Container>
  );
}

const Container = styled(
  'button',
  {
    height: '$full',
    padding: '0rem 1.5rem',
    outline: 'inherit',
    background: 'none',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    boxShadow: '$dividerLeft',
  },
  {
    interactive: {
      true: {
        pointerEvents: 'auto',
      },
      false: {
        pointerEvents: 'none',
      },
    },
  },
);

const ArrowIndicator = styled(Icon, { padding: '0rem' });
