import styled from '@emotion/styled';
import Button from 'components/core/controllers/views/Button';
import Modal from 'components/core/controllers/views/Modal';
import SpinnerButton from 'components/core/controllers/views/SpinnerButton';
import Text from 'components/core/controllers/views/Text';
import { css } from 'emotion';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { teamDetailsTestIds } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';

export interface LeaveTeamModalProps {
  onSubmit: () => Promise<void>;
  teamName: string;
  canLeave: boolean;
}

/**
 * LeaveTeamModal
 * A confirmation modal for leaving a team. Similar to LeaveWorkspace.
 * Temporarily using a confirmation modal until we have a proper confirmation component.
 */
export default function LeaveTeamModal({ onSubmit, canLeave, teamName }: LeaveTeamModalProps): JSX.Element {
  const history = useHistory();
  return (
    <Modal
      bodyStyle={css`
        min-width: 30rem;
        max-width: 30rem;
      `}
      footer={
        <FooterContainer>
          <CancelButton
            onClick={(): void => {
              history.go(-1);
            }}
          >
            {strings.confirmDialog.cancel}
          </CancelButton>
          {canLeave ? <ConfirmButton onClick={onSubmit}>{strings.confirmDialog.yes}</ConfirmButton> : null}
        </FooterContainer>
      }
      header={<HeaderText>{strings.teamDetails.leaveTitle}</HeaderText>}
    >
      <LeaveTeamPrompt>
        {canLeave
          ? strings
              .formatString(strings.teamDetails.leaveTeamPromptWithName, {
                name: teamName,
              })
              .toString()
          : strings.teamDetails.unableToLeaveTeam}
      </LeaveTeamPrompt>
    </Modal>
  );
}

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const CancelButton = styled((props) => (
  <Button color='ghost' data-cy={teamDetailsTestIds.LEAVE_TEAM_MODAL_CANCEL_BUTTON} name='cancel' {...props} />
))`
  margin-right: 0.8125rem;
`;

const ConfirmButton = styled((props) => (
  <SpinnerButton color='red' data-cy={teamDetailsTestIds.LEAVE_TEAM_MODAL_CONFIRM_BUTTON} name='leave' {...props} />
))`
  padding: 0.25rem 0.8rem;
`;

const HeaderText = styled((props) => <Text {...props} />)`
  color: #303f4b;
  font-size: 1rem;
  font-weight: 500;
`;

const LeaveTeamPrompt = styled.div`
  width: 100%;
`;
