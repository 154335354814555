import { createAsyncThunk } from '@reduxjs/toolkit';
import { UI } from '@taraai/types';
import { RootState } from 'reduxStore/store';
import { decode } from 'reduxStore/utils/decoders';
import { Batch, ExtraAPI } from 'reduxStore/utils/types';

export const archiveTask = createAsyncThunk(
  'ArchiveTask',
  async (taskIds: Pick<UI.UITask, 'id'> | Pick<UI.UITask, 'id'>[], { extra, getState }) => {
    const state = getState() as RootState;
    const { getOrgId, getFirestore, getUserId } = extra as ExtraAPI;
    const orgId = getOrgId();
    const userId = getUserId(state);
    const { mutate } = getFirestore();

    const ids = Array.isArray(taskIds) ? taskIds.map((taskId) => taskId.id) : [taskIds.id];

    const writes = ids.map((taskId) =>
      decode<UI.UITaskArchiveChangeset>(
        {
          archived: true,
          updatedAt: ['::serverTimestamp'],
          updatedBy: userId,
          lastUpdateVia: 'tara',
          path: `orgs/${orgId}/tasks`,
          id: taskId,
        },
        'UITaskArchiveChangeset',
      ),
    ) as unknown as Batch;

    await mutate(writes);

    return writes.map((write) => write.id);
  },
);
