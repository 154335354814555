"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScrollToTop = void 0;
var react_1 = __importStar(require("react"));
var core_1 = require("./core");
function ScrollToTop(_a) {
    var scrollRef = _a.scrollRef;
    var handleClick = (0, react_1.useCallback)(function () {
        var _a;
        (_a = scrollRef.current) === null || _a === void 0 ? void 0 : _a.scroll({ top: 0, behavior: 'smooth' });
    }, [scrollRef]);
    return (react_1.default.createElement(Circle, { onClick: handleClick, onMouseDown: preventFocus }, scrolltoTopArrow));
}
exports.ScrollToTop = ScrollToTop;
var Circle = (0, core_1.styled)('button', {
    alignItems: 'center',
    backgroundColor: '$grey7',
    border: 'none',
    borderRadius: '$circle',
    display: 'inline-flex',
    height: '1.5rem',
    justifyContent: 'center',
    width: '1.5rem',
});
var scrolltoTopArrow = (react_1.default.createElement("svg", { fill: 'none', height: '12', stroke: core_1.tokens.colors.$white, width: '12', xmlns: 'http://www.w3.org/2000/svg' },
    react_1.default.createElement("path", { d: 'M6 5v7M8.5 7.5L6 5 3.5 7.5M0 2.5h12' })));
function preventFocus(event) {
    var _a;
    event.preventDefault();
    (_a = document.activeElement) === null || _a === void 0 ? void 0 : _a.blur();
}
