/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-loops/no-loops */

import { createEntity, hasEntityOfType } from 'components/editor/entities';
import { PluginFunctions } from 'components/editor/types';
import { ContentState, DraftBlockType, DraftInlineStyleType, EditorState, Modifier, RichUtils } from 'draft-js';
import { OrderedSet } from 'immutable';
import { v4 as uuidv4 } from 'uuid';

import { EditorStateTransform } from './types';
import { preserveVolatileState } from './utils';

export const transforms = {
  // Inline style
  toggleBold: getInlineToggler('BOLD'),
  toggleItalics: getInlineToggler('ITALIC'),
  toggleUnderline: getInlineToggler('UNDERLINE'),
  toggleStrikethrough: getInlineToggler('STRIKETHROUGH'),
  toggleCode: getInlineToggler('CODE'),
  // Block styles
  toggleParagraph: getBlockToggler('paragraph'),
  toggleHeaderOne: getBlockToggler('header-one'),
  toggleHeaderTwo: getBlockToggler('header-two'),
  toggleBlockquote: getBlockToggler('blockquote'),
  toggleCodeBlock: getBlockToggler('code-block'),
  // Block styles (each block separately)
  toggleOrderedList: getBlockToggler('ordered-list-item'),
  toggleUnorderedList: getBlockToggler('unordered-list-item'),
  // Other
  toggleLink,
  tryAppendSpace,
  createThread,
} as const;

function getInlineToggler(inlineStyle: DraftInlineStyleType): EditorStateTransform {
  return preserveVolatileState(
    (state) => RichUtils.toggleInlineStyle(state, inlineStyle),
    (state) => {
      if (!state.getSelection().isCollapsed()) return state;
      const currentInlineStyle = state.getCurrentInlineStyle() ?? OrderedSet();
      return EditorState.setInlineStyleOverride(
        state,
        currentInlineStyle.has(inlineStyle)
          ? currentInlineStyle.delete(inlineStyle)
          : currentInlineStyle.add(inlineStyle),
      );
    },
  );
}

function getBlockToggler(blockType: DraftBlockType): EditorStateTransform {
  return preserveVolatileState((state) => RichUtils.toggleBlockType(state, blockType));
}

const emptySelectionNewLinkText = 'link';
const defaultNewLink = 'https://';

function toggleLink(state: EditorState, { setForceSelectedEntity }: PluginFunctions): EditorState {
  if (getLinkDisabledMessage(state)) return state;
  const selection = state.getSelection();
  const content = state.getCurrentContent();
  if (selection.isCollapsed()) {
    const offset = selection.getAnchorOffset();
    const block = content.getBlockForKey(selection.getAnchorKey());
    const characters = block.getCharacterList();
    if (offset > 0 && hasEntityOfType('LINK', content, characters.get(offset - 1))) {
      setForceSelectedEntity(block.getEntityAt(offset - 1));
      return state;
    }
    if (offset < characters.size && hasEntityOfType('LINK', content, characters.get(offset))) {
      setForceSelectedEntity(block.getEntityAt(offset));
      return state;
    }
    const { contentWithEntity, key } = getNewLink(content);
    setForceSelectedEntity(key);
    return EditorState.push(
      state,
      Modifier.insertText(contentWithEntity, selection, emptySelectionNewLinkText, state.getCurrentInlineStyle(), key),
      'apply-entity',
    );
  }
  const { contentWithEntity, key } = getNewLink(content);
  setForceSelectedEntity(key);
  return EditorState.push(state, Modifier.applyEntity(contentWithEntity, selection, key), 'apply-entity');
}

function getNewLink(content: ContentState): ReturnType<typeof createEntity> {
  return createEntity('LINK', { url: defaultNewLink }, content);
}

export function isLinkSelected(state: EditorState): boolean {
  const content = state.getCurrentContent();
  const selection = state.getSelection();
  const offset = selection.getStartOffset();
  const characters = content.getBlockForKey(selection.getStartKey()).getCharacterList();
  return selection.isCollapsed()
    ? (offset > 0 && hasEntityOfType('LINK', content, characters.get(offset - 1))) ||
        (offset < characters.size && hasEntityOfType('LINK', content, characters.get(offset)))
    : false;
}

export function getLinkDisabledMessage(state: EditorState): string | undefined {
  const selection = state.getSelection();
  if (selection.isCollapsed()) return;
  if (selection.getAnchorKey() !== selection.getFocusKey()) return 'Links spanning multiple paragraps not allowed';
  const content = state.getCurrentContent();
  const characters = content.getBlockForKey(selection.getAnchorKey()).getCharacterList();
  for (let offset = selection.getStartOffset(); offset < selection.getEndOffset(); offset += 1) {
    const char = characters.get(offset);
    if (hasEntityOfType('IMAGE', content, char)) return 'Links with images are not allowed';
    if (hasEntityOfType('LINK', content, char)) return 'Links with images are not allowed';
    if (hasEntityOfType('label', content, char)) return 'Links with labels are not allowed';
    if (hasEntityOfType('mention', content, char)) return 'Links with mentions are not allowed';
  }
  return undefined;
}

function tryAppendSpace(state: EditorState): EditorState | null {
  const selection = state.getSelection();
  const content = state.getCurrentContent();
  const lastBlock = content.getLastBlock();
  const lastBlockKey = lastBlock.getKey();
  const lastBlockCharacters = lastBlock.getCharacterList();
  if (
    selection.isCollapsed() &&
    selection.getAnchorKey() === lastBlockKey &&
    selection.getAnchorOffset() === lastBlockCharacters.size &&
    lastBlockCharacters.get(-1).hasStyle('CODE')
  ) {
    return EditorState.push(state, Modifier.insertText(content, selection, ' '), 'insert-characters');
  }
  return null;
}

function createThread(state: EditorState, { setThreadIds }: PluginFunctions): EditorState {
  if (!setThreadIds) return state;

  const threadID = `thread${uuidv4()}`;

  setThreadIds((threadIds) => [...threadIds, threadID]);

  state.getCurrentContent().getSelectionAfter();

  return EditorState.push(
    state,
    Modifier.applyInlineStyle(state.getCurrentContent(), state.getSelection(), threadID),
    'change-inline-style',
  );
}
