/* eslint-disable react/jsx-sort-props */
/* eslint-disable react/no-children-prop */
import { useFirebase } from '@taraai/read-write';
import GitInsightsPage from 'components/app/GitInsights';
import { GitInsightsIntegrationSuccess, Insights } from 'components/app/InsightsPage';
import { WorkDrawerController } from 'components/app/WorkDrawer';
import {
  Deactivated,
  FirebaseEmailActionHandler,
  GithubErrorPage,
  Home,
  Integrations,
  JoinWorkspace,
  Login,
  NotFound,
  Onboarding,
  OnboardingMobileNote,
  Profile,
  SprintDetails,
  SprintPage,
  TeamDetailsPage,
  Workspace,
} from 'components/pages';
import { DefinePage } from 'components/pages/Define';
import TaskPage from 'components/pages/TaskPage';
import {
  CheckoutSuccess,
  CompleteSprint,
  DeleteWorkspace,
  GithubLearnMore,
  LeaveTeam,
  LeaveWorkspace,
  NewRequirement,
  SetupIntegration,
  SprintInsights,
  SprintsSettings,
  TeamCreate,
} from 'components/routes';
import DeleteTeam from 'components/routes/DeleteTeam';
import EditSprint from 'components/routes/EditSprint';
import Upgrade from 'components/routes/Upgrade/Upgrade';
import UpgradeB from 'components/routes/UpgradeB';
import Firebase from 'firebase/compat/app';
import React, { ReactElement, useEffect } from 'react';
import { Redirect, Route, Router as ReactRouter, Switch, useHistory } from 'react-router-dom';
import { PROD } from 'tools';
import { FeatureFlagCondition } from 'tools/helpers/FeatureFlagCondition';
import { segment } from 'tools/libraries/analytics';

import { browserHistory } from './history';
import InvitationContext from './InvitationContext';
import OrganizationTeamContext from './OrganizationTeamContext';
import { getPath, linkTo } from './paths';
import AuthenticatedRoute from './routes/AuthenticatedRoute';
import NonAuthenticatedRoute from './routes/NonAuthenticatedRoute';
import { OnboardedRoute } from './routes/OnboardedRoute';

// Pages to track on segment
const SIGNUP_PATH = '/login';
const ONBOARDING_PATH = '/onboarding';
const DEFINE_PATH = '/define';
const SPRINT_PATH = '/sprint';
const isSegmentPath = (pathname: string): boolean => {
  if (
    pathname === SIGNUP_PATH ||
    pathname === ONBOARDING_PATH ||
    pathname.includes(DEFINE_PATH) ||
    pathname.includes(SPRINT_PATH)
  ) {
    return true;
  }
  return false;
};

const tagPage = (path: string): void => {
  if (PROD) {
    Firebase.analytics().setCurrentScreen(path);
  }
};
const Logout: React.FC = () => {
  const firebase = useFirebase();
  const history = useHistory();

  useEffect(() => {
    firebase
      .logout()
      .then(() => history.replace('/'))
      // eslint-disable-next-line no-console
      .catch((err) => console.error('Logout failed', err));
  });

  return <div>Logout in progress...</div>;
};

export type OrgIdParam = { orgId: string };

export default function Router(): ReactElement {
  // track the first load
  const { location } = browserHistory;
  tagPage(location.pathname + location.search);

  useEffect(() => {
    segment.load();
    // track page transitions
    browserHistory.listen((loc) => {
      if (isSegmentPath(loc.pathname)) {
        segment.page();
      }

      tagPage(loc.pathname + loc.search);
    });
  });

  return (
    <ReactRouter history={browserHistory}>
      <Switch>
        <NonAuthenticatedRoute path={getPath('login')} children={<Login />} />
        <Route path={getPath('logout')} children={<Logout />} />
        <AuthenticatedRoute path={getPath('deactivated')} children={<Deactivated />} />
        <Route path={getPath('emailActionHandler')} children={<FirebaseEmailActionHandler />} />
        <Route path={getPath('notFound')} children={<NotFound />} />
        <Route path={getPath('invite')} children={<JoinWorkspace />} />
        <AuthenticatedRoute path={getPath('setupIntegration')} children={<SetupIntegration />} />
        <AuthenticatedRoute exact path={getPath('onboarding')}>
          <Onboarding />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path={getPath('onboardingMobileNote')}>
          <OnboardingMobileNote />
        </AuthenticatedRoute>
        <InvitationContext>
          <OrganizationTeamContext>
            <OnboardedRoute
              path={[getPath('requirements'), getPath('requirement'), getPath('sprints'), getPath('task')]}
            >
              <WorkDrawerController />
            </OnboardedRoute>
            <OnboardedRoute exact path={getPath('home')} children={<Home />} />
            <OnboardedRoute exact path={getPath('homeTask')}>
              <Home />
            </OnboardedRoute>
            <OnboardedRoute exact path={getPath('githubLearnMore')}>
              <Home />
              <GithubLearnMore />
            </OnboardedRoute>
            <OnboardedRoute path={getPath('requirements')}>
              <DefinePage />
            </OnboardedRoute>
            <OnboardedRoute path={getPath('newRequirement')} children={<NewRequirement />} />
            <OnboardedRoute exact path={getPath('createRequirement')}>
              <SprintPage />
              <NewRequirement createRequirementFromSprintsPage />
            </OnboardedRoute>
            <OnboardedRoute exact path={getPath('deleteTeam')}>
              <TeamDetailsPage />
              <DeleteTeam />
            </OnboardedRoute>
            <OnboardedRoute path={getPath('requirement')}>
              <DefinePage />
            </OnboardedRoute>
            <OnboardedRoute path={getPath('requirementCreateTeam')} children={<TeamCreate />} />
            <OnboardedRoute path={getPath('sprints')} children={<SprintPage />} />
            {/* Default pricing to yearly */}
            <FeatureFlagCondition
              flag='alphaMonetization'
              enabled={<OnboardedRoute path={getPath('upgrade')} children={<Upgrade />} />}
            />
            {/* Default pricing to monthly */}
            <FeatureFlagCondition
              flag='alphaMonetization'
              enabled={<OnboardedRoute path={getPath('premium')} children={<UpgradeB />} />}
            />
            <FeatureFlagCondition
              flag='alphaMonetization'
              enabled={<OnboardedRoute path={getPath('checkoutSuccess')} children={<CheckoutSuccess />} />}
            />
            <OnboardedRoute path={getPath('sprintsSettings')} children={<SprintsSettings />} />
            <OnboardedRoute path={getPath('sprintComplete')} children={<CompleteSprint />} />
            <OnboardedRoute path={getPath('sprintInsights')} children={<SprintInsights />} />
            <OnboardedRoute path={getPath('sprintDetails')} children={<SprintDetails />} />
            <OnboardedRoute path={getPath('sprintDetailsComplete')} children={<CompleteSprint />} />
            <OnboardedRoute path={getPath('sprintDetailsEdit')} children={<EditSprint />} />
            <OnboardedRoute path={getPath('sprintDetailsInsights')} children={<SprintInsights />} />
            <FeatureFlagCondition
              flag='insightsPage'
              enabled={<OnboardedRoute path={getPath('insights')} children={<Insights />} />}
            />

            <FeatureFlagCondition
              flag='gitlabInsights'
              enabled={<OnboardedRoute path={getPath('gitlabInsights')} children={<GitInsightsPage />} />}
            />

            <FeatureFlagCondition
              flag='gitlabInsights'
              enabled={
                <OnboardedRoute
                  path={getPath('gitlabInsightsIntegrationComplete')}
                  children={<GitInsightsIntegrationSuccess />}
                />
              }
            />
            <OnboardedRoute path={getPath('taskDetails')}>
              <TaskPage />
            </OnboardedRoute>
            <OnboardedRoute path={getPath('task')}>
              <FeatureFlagCondition flag='newSprintsPage' enabled={<DefinePage />} />
            </OnboardedRoute>
            <OnboardedRoute exact path={getPath('profile')} children={<Profile />} />
            <OnboardedRoute path={getPath('workspace')} children={<Workspace />} />
            <OnboardedRoute exact path={getPath('leaveWorkspace')}>
              <Workspace />
              <LeaveWorkspace />
            </OnboardedRoute>
            <OnboardedRoute exact path={getPath('deleteWorkspace')}>
              <Workspace />
              <DeleteWorkspace />
            </OnboardedRoute>
            <OnboardedRoute exact path={getPath('integrations')} children={<Integrations />} />
            <OnboardedRoute path={getPath('teamCreate')} children={<TeamCreate />} />
            <OnboardedRoute path={getPath('teamCreateSprintPlanning')} children={<TeamCreate />} />
            <OnboardedRoute path={getPath('teamCreateSprintDetails')} children={<TeamCreate />} />
            <OnboardedRoute path={getPath('teamDetails')} children={<TeamDetailsPage />} />
            <OnboardedRoute exact path={getPath('leaveTeam')}>
              <TeamDetailsPage />
              <LeaveTeam />
            </OnboardedRoute>
            <OnboardedRoute path={getPath('teamDetailsSprintPlanning')} children={<TeamDetailsPage />} />
            <OnboardedRoute path={getPath('teamDetailsSprintDetails')} children={<TeamDetailsPage />} />
            <OnboardedRoute path={getPath('deleteTeamWithConfirmation')} children={<DeleteTeam />} />
            <OnboardedRoute exact path={getPath('errorGithubRepoNotSelected')} children={<GithubErrorPage />} />
          </OrganizationTeamContext>
        </InvitationContext>
        <Route path='*' children={<Redirect to={linkTo('notFound')} />} />
      </Switch>
    </ReactRouter>
  );
}
