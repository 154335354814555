import { Box, Fluid, HStack } from '@taraai/design-system';
import { Data, UI } from '@taraai/types';
import AvatarListController from 'components/app/controllers/AvatarListController';
import Icon from 'components/core/controllers/views/Icon';
import Text from 'components/core/controllers/views/Text';
import { linkTo } from 'components/Router/paths';
import { css } from 'emotion';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CompleteSprintData } from 'reduxStore/sprints/queries/complete-sprint-data';
import { selectActiveTeam } from 'reduxStore/teams';
import { strings } from 'resources/i18n';
import { atomic } from 'resources/theme';

import { SprintDetailsNavigation } from './SprintDetailsNavigation';

export interface SprintDetailsHeaderProps {
  selectedSprint?: UI.UISprint;
  organizationId: Data.Id.OrganizationId;
  nextSprintId: Data.Id.SprintId | null;
  previousSprintId: Data.Id.SprintId | null;
  completeSprintData: CompleteSprintData | undefined;
}

/**
 * Render the sprint number, complete status, and date filter which will update
 * the top level state and render the filtered data for SprintDetailsTimeline
 */
export default function SprintDetailsHeader({
  selectedSprint,
  organizationId,
  nextSprintId,
  previousSprintId,
  completeSprintData,
}: SprintDetailsHeaderProps): JSX.Element {
  const teamId = useSelector(selectActiveTeam);

  const { sprintTasks } = completeSprintData ?? {};

  return (
    <div>
      <Box spaceVert='$8px'>
        <HStack alignY='center'>
          <div>
            <SprintDetailsNavigation
              nextSprintId={nextSprintId}
              organizationId={organizationId}
              previousSprintId={previousSprintId}
              selectedSprint={selectedSprint}
            >
              {selectedSprint ? selectedSprint?.sprintName : strings.sprints.noActiveSprints.header}
            </SprintDetailsNavigation>
          </div>
          <Fluid />
          {selectedSprint && (
            <div>
              {selectedSprint.isComplete && (
                <Link
                  className={css`
                    font-weight: 500;
                    margin-left: 1rem;
                    align-self: center;
                    font-size: 0.875rem;
                    text-decoration: none;
                    ${atomic.color(atomic.theme.color.success)};
                    :hover {
                      ${atomic.color(atomic.theme.color.success)};
                    }
                  `}
                  to={linkTo('sprintDetailsEdit', {
                    orgId: organizationId,
                    teamId,
                    sprintId: selectedSprint.id,
                  })}
                >
                  <Icon
                    className={css`
                      width: 0.875rem;
                      height: 0.75rem;
                      padding: 0rem;
                      margin-right: 0.3125rem;
                      margin-bottom: -0.1406rem;
                    `}
                    color='white'
                    name='tick'
                  />
                  {strings.sprints.completed}
                </Link>
              )}
            </div>
          )}
        </HStack>
      </Box>
      {selectedSprint?.isComplete && (
        <div
          className={css`
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
          `}
        >
          <div
            className={css`
              flex-grow: 1;
              margin: 2.25rem 0;
            `}
          >
            <br />
            <span aria-label={strings.sprints.completedHeader.rocket.label} role='img'>
              <Text h1>{strings.sprints.completedHeader.rocket.emoji}</Text>
            </span>
            <Text
              className={css`
                margin-top: 0.5rem;
                margin-bottom: 1rem;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                ${atomic.color(atomic.colors.greySlate)};
              `}
              h3
            >
              {strings.sprints.completedHeader.header}
            </Text>
            <AvatarListController userIds={sprintTasks?.map((task) => task.assignee)} />
          </div>
        </div>
      )}
    </div>
  );
}
