import styled from '@emotion/styled';
import { Identifiable } from '@taraai/types';
import { OptionRenderProps } from 'components/core/controllers/Selector/types';
import React, { useCallback, useEffect, useRef, useState } from 'react';

type SelectorOptionProps<Option extends Identifiable> = {
  option: Option;
  isActive: boolean;
  isSelected: boolean;
  renderOption: (props: OptionRenderProps<Option>) => JSX.Element;
  onSelect: (event: React.SyntheticEvent) => void;
};

export function SelectorOption<Option extends Identifiable>({
  option,
  renderOption,
  isActive,
  isSelected,
  onSelect,
}: SelectorOptionProps<Option>): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);

  const [waiting, setWaiting] = useState(false);

  const onClick = useCallback(
    (event: React.SyntheticEvent): void => {
      setWaiting(true);
      onSelect(event);
      setTimeout(() => {
        return ref.current?.blur();
      }, 500);
    },
    [onSelect],
  );

  useEffect(() => {
    if (isActive) {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [isActive]);

  useEffect(() => {
    setWaiting(false);
  }, [isSelected]);

  return (
    <Container ref={ref} onClick={onClick}>
      {renderOption({
        option,
        isActive,
        isSelected,
        waiting,
      })}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;
