"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.statusDecoder = exports.serviceDecoder = void 0;
var ts_data_json_1 = require("ts.data.json");
exports.serviceDecoder = new ts_data_json_1.JsonDecoder.Decoder(function (value) {
    switch (value) {
        case 'github-api':
        case 'asana-csv':
        case 'trello-csv':
            return (0, ts_data_json_1.ok)(value);
        default:
            return (0, ts_data_json_1.err)("Failed to decode ".concat(value, " as ImportItem.ImportableService"));
    }
});
exports.statusDecoder = new ts_data_json_1.JsonDecoder.Decoder(function (value) {
    switch (value) {
        case 'pending':
        case 'ok':
        case 'error':
            return (0, ts_data_json_1.ok)(value);
        default:
            return (0, ts_data_json_1.err)("Failed to decode ".concat(value, " as ImportItem.Status"));
    }
});
exports.default = ts_data_json_1.JsonDecoder.object({
    data: ts_data_json_1.JsonDecoder.dictionary(ts_data_json_1.JsonDecoder.succeed, 'ImportItem.Data'),
    service: exports.serviceDecoder,
    author: ts_data_json_1.JsonDecoder.string,
    status: exports.statusDecoder,
    errorMessage: ts_data_json_1.JsonDecoder.nullable(ts_data_json_1.JsonDecoder.string),
}, 'ImportItem');
