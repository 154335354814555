import Text from 'components/core/controllers/views/Text';
import { css } from 'emotion';
import React from 'react';
import { atomic } from 'resources/theme';

const Label: React.FC = ({ children }) => (
  <Text
    className={css`
      margin-bottom: 0.8rem;
      color: ${atomic.get(atomic.colors.grey7)};
      font-weight: ${atomic.get(atomic.fonts.weight.regular)};
    `}
  >
    {children}
  </Text>
);

export default Label;
