import { styled } from '@taraai/design-system';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { EmptyData } from 'components/app/GitInsights/Shared/EmptyData';
import { GraphLegend } from 'components/app/GitInsights/Shared/PrimitiveComponents/GraphLegend';
import { GraphSection } from 'components/app/GitInsights/Shared/PrimitiveComponents/GraphSection';
import { VerticalStacked } from 'components/app/GitInsights/Shared/PrimitiveComponents/VerticalStacked';
import { GenerateComparisonLegendScale, GetKeysForRenderingInGraph } from 'components/app/GitInsights/Shared/utils';
import React from 'react';
import { useSelector } from 'react-redux';
import { getChartData, gitInsightsSelectionState } from 'reduxStore';

// Pull request/Merge Request Health
export function CustomerValueByFeature(): JSX.Element | null {
  const getInsightState = useSelector(gitInsightsSelectionState);

  const { timeSegment } = getInsightState;

  const rawData = useSelector(getChartData)?.CustomerValueFeature;

  const getBottomInfo = (info: Record<string, string | number>): string => String(info.label);

  return (
    <GraphSection
      colorsLegend={
        <GraphLegend
          colorShapeFormat='circle'
          scale={
            rawData
              ? GenerateComparisonLegendScale(Object.keys(rawData[0].data[0]).filter((key) => key !== 'label'))
              : undefined
          }
        />
      }
      comparisonPeriodLegend={timeSegment}
      headerText='Customer Value By Feature'
    >
      {!!rawData && rawData.length > 0 ? (
        <GraphsBox>
          {rawData?.map((graph) => {
            if (!graph.data) return;

            return (
              <ParentSize key={graph.label}>
                {({ width }) => (
                  <VerticalStacked
                    bottomTitle={graph.label as string}
                    data={graph.data}
                    dataKeys={GetKeysForRenderingInGraph(graph.data[0], 'label')}
                    getAxisBottomInfo={getBottomInfo}
                    height={300}
                    showAxisLeftInfo={false}
                    showGridLines={false}
                    showValues
                    width={width}
                  />
                )}
              </ParentSize>
            );
          })}

          {/* {renderType === 'singleUser' &&
          (!singleData ? null : (
            <ParentSize>
              {({ width }) => (
                <GroupVerticalStacked
                  data={singleData}
                  dataKeys={GetKeysForRenderingInGraph(singleData[0], 'label')}
                  getAxisBottomInfo={getBottomInfo}
                  height={300}
                  showAxisLeftInfo={false}
                  showGridLines={false}
                  showValues
                  width={width}
                />
              )}
            </ParentSize>
          ))} */}
        </GraphsBox>
      ) : (
        <EmptyData dataToWatch={rawData} />
      )}
    </GraphSection>
  );
}

const GraphsBox = styled('div', {
  display: 'flex',
  justifyContent: 'center',
});
