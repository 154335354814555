"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ts_data_json_1 = require("ts.data.json");
function isTimestamp(value) {
    return (typeof value === 'object' &&
        value !== null &&
        typeof value.seconds === 'number' &&
        typeof value.nanoseconds === 'number');
}
exports.default = new ts_data_json_1.JsonDecoder.Decoder(function (value) {
    if (isTimestamp(value)) {
        return (0, ts_data_json_1.ok)(value);
    }
    return (0, ts_data_json_1.err)("Failed to decode ".concat(value, " as a Timestamp"));
});
