import { createAsyncThunk } from '@reduxjs/toolkit';
import { Data, UI } from '@taraai/types';
import { updateOrganization } from 'reduxStore/organization';
import { updateTask } from 'reduxStore/tasks';
import { ExtraAPI } from 'reduxStore/utils';
import { decode } from 'reduxStore/utils/decoders';

type FinishOnboardingPayload = {
  orgId: Data.Id.OrganizationId;
  taskChangesets?: {
    id: Data.Id.TaskId;
    sprint: Data.Id.SprintId;
  }[];
};

export const finishOnboarding = createAsyncThunk(
  'FinishOnboarding',
  async ({ orgId, taskChangesets }: FinishOnboardingPayload, { dispatch, extra }) => {
    const { getFirestore } = extra as ExtraAPI;
    const firestore = getFirestore();

    return firestore.runTransaction(async (transaction) => {
      const orgChangeset = decode<UI.UIOrganizationPartial>(
        {
          id: orgId,
          onboardingStatus: 'finished',
        },
        'UIOrganizationPartial',
        {
          removeAdditional: 'all',
        },
      );

      taskChangesets &&
        (await Promise.all(
          taskChangesets.map((taskUpdate) => dispatch(updateTask({ ...taskUpdate, orgId, meta: { transaction } }))),
        ));
      await dispatch(updateOrganization({ ...orgChangeset, meta: { transaction } }));
    });
  },
);
