import { unwrapResult } from '@reduxjs/toolkit';
import { Data, UI } from '@taraai/types';
import { MemberListView } from 'components/app/controllers/views/MemberListView';
import React, { useCallback } from 'react';
import { updateTeamMembership, useAppDispatch } from 'reduxStore';
import { strings } from 'resources/i18n';
import { useToast } from 'tools';

type UserFragment = Pick<UI.UIUser, 'id' | 'name'>;

interface Props {
  canLeave: boolean;
  orgId: Data.Id.OrganizationId;
  profileId: string;
  teamId: string;
  teamName: string;
}

/**
 * MemberListController
 * Manage the members of a team
 *
 */
export function MemberListController({ canLeave, orgId, profileId, teamId, teamName }: Props): JSX.Element | null {
  const dispatch = useAppDispatch();
  const { whenSuccess, whenError } = useToast();

  const handleJoinTeam = useCallback(
    (user: UserFragment): void => {
      dispatch(updateTeamMembership({ action: 'add', teamId, userIds: [user.id] }))
        .then(unwrapResult)
        .then(
          whenSuccess(
            strings.formatString(strings.memberManager.addMember.success, {
              username: user.name,
              teamName,
            }) as string,
          ),
        )
        .catch(whenError(({ message }) => message));
    },
    [dispatch, teamId, teamName, whenError, whenSuccess],
  );

  const removeUserFromTeam = useCallback(
    (user: UserFragment): void => {
      dispatch(updateTeamMembership({ action: 'remove', teamId, userIds: [user.id] }))
        .then(unwrapResult)
        .then(() =>
          whenSuccess(
            strings.formatString(strings.memberManager.removeMember.success, {
              username: user.name,
              teamName,
            }) as string,
          ),
        )
        .catch(whenError(({ message }) => message));
    },
    [dispatch, teamId, teamName, whenError, whenSuccess],
  );

  return (
    <MemberListView
      canProfileLeave={canLeave}
      joinTeam={handleJoinTeam}
      leaveTeam={removeUserFromTeam}
      orgId={orgId}
      profileId={profileId}
      teamId={teamId}
      teamName={teamName}
    />
  );
}
