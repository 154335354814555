import { compose } from '@reduxjs/toolkit';
import { useFirestoreConnect } from '@taraai/read-write';
import { Data, UI } from '@taraai/types';
import { useGetWorkDrawerRequirementIds } from 'firestore';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getCompletedSprints,
  getRequirements,
  getSprint,
  getSprintTasks,
  getUpcomingSprints,
  QuerySelector,
  selectActiveTeam,
  selectActiveWorkspace,
  selectTeam,
} from 'reduxStore';
import { CompleteSprintData, getCompleteSprintData } from 'reduxStore/sprints/queries/complete-sprint-data';

type RequirementFragment = Pick<UI.UIRequirement, 'id' | 'title' | 'assignedTeamIds'>;

const mapToRequirementFragment = (data: UI.UIRequirement[] = []): RequirementFragment[] =>
  data.map(({ id, title, assignedTeamIds }) => ({ id, title, assignedTeamIds }));

const filterActiveRequirements =
  (tasks: UI.UITask[]) =>
  (requirements: RequirementFragment[]): RequirementFragment[] =>
    requirements.filter(({ id }) => tasks.some(({ _relationships }) => _relationships.requirement === id));

interface DataLoader {
  completedSprints: UI.UISprint[];
  upcomingSprint: UI.UISprint[];
  selectedSprint: UI.UISprint | undefined;
  selectedSprintData: CompleteSprintData | undefined;
  activeSprint: UI.UISprint | undefined;
  sprintTasks: UI.UITask[] | undefined;
  requirements: RequirementFragment[];
  sprintTasksSelector: QuerySelector<UI.UITask>;
}

/** Separate hook for decreasing controller complexity */
export function useDataLoader(): DataLoader {
  const { sprintId: sprintIdParam } = useParams<{
    sprintId: Data.Id.SprintId;
  }>();
  const orgId = useSelector(selectActiveWorkspace);
  const teamId = useSelector(selectActiveTeam);

  const currentSprintId = useSelector(compose((data) => data?.currentSprintId, selectTeam(orgId, teamId)));
  const sprintId = sprintIdParam === 'current' ? currentSprintId : sprintIdParam;

  const tasksSlice = getSprintTasks(orgId, sprintId ?? '');
  const selectedSlice = getSprint(orgId, sprintId ?? '');
  const activeSlice = getSprint(orgId, currentSprintId ?? '');
  const upcomingSlice = getUpcomingSprints(orgId, teamId);
  const completedSlice = getCompletedSprints(orgId, teamId, {
    orderBy: 'sprintNumber',
  });
  const requirementsSlice = getRequirements(orgId, null, {
    orderBy: 'title',
  });

  useFirestoreConnect([
    ...tasksSlice.query,
    ...selectedSlice.query,
    ...activeSlice.query,
    ...upcomingSlice.query,
    ...completedSlice.query,
    ...requirementsSlice.query,
  ]);

  // preload workdrawer requirements
  // this keeps the listener for workdrawer requirements alive always to improve
  // performance when switching between view
  useGetWorkDrawerRequirementIds();

  const sprintTasksSelector = tasksSlice.selector;

  const completedSprints = useSelector(completedSlice.selector) ?? [];
  const upcomingSprint = useSelector(upcomingSlice.selector) ?? [];
  const selectedSprint = useSelector(selectedSlice.selector);
  const sprintTasks = useSelector(sprintTasksSelector);
  const activeSprint = useSelector(activeSlice.selector);
  const tasks = useSelector(tasksSlice.selector) ?? [];
  const requirements = useSelector(
    compose(filterActiveRequirements(tasks), mapToRequirementFragment, requirementsSlice.selector),
  );

  // Separate selector based on selected sprint data
  const completeSprintSlice = getCompleteSprintData(
    orgId,
    selectedSprint?.id ?? '',
    selectedSprint?.startDate ?? null,
    selectedSprint?.endDate ?? null,
  );
  useFirestoreConnect(completeSprintSlice.query);
  const selectedSprintData = useSelector(completeSprintSlice.selector);

  return {
    completedSprints,
    upcomingSprint,
    selectedSprint,
    selectedSprintData,
    sprintTasks,
    activeSprint,
    requirements,
    sprintTasksSelector,
  };
}
