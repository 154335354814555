import AuthLayout from 'components/app/layouts/AuthLayout';
import Button from 'components/core/controllers/views/Button';
import Input from 'components/core/controllers/views/Input';
import { css } from 'emotion';
import React, { useEffect, useState } from 'react';
import { TaraLogoIconWhite } from 'resources/assets';
import { strings } from 'resources/i18n';

/**
 * The API will reject if the password is less than six characters,
 * it has no other restrictions.
 */
const FIREBASE_AUTH_MIN_PASSWORD_LENGTH = 6;

export interface PasswordResetOptions {
  newPasswordWasGiven: (password: string) => void;
  email: string;

  successfullyChanged: boolean;
}

export default function PasswordReset({
  newPasswordWasGiven,
  email,
  successfullyChanged = false,
}: PasswordResetOptions): JSX.Element {
  const [password, setPassword] = useState('');
  const [isPasswordLongEnough, setIsPasswordLongEnough] = useState(true);

  useEffect(() => {
    setIsPasswordLongEnough(password.length >= FIREBASE_AUTH_MIN_PASSWORD_LENGTH);
  }, [password.length]);

  function onChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setPassword(event.target.value);
  }

  const isError = !isPasswordLongEnough && password.length !== 0;
  if (successfullyChanged) {
    return (
      <AuthLayout title={strings.passwordReset.passwordSuccessfullyChanged}>
        <a href='/login'>
          <Button
            className={css`
              height: 3.5rem;
              display: flex;
              margin: 0;
              padding: 1rem;
              font-size: 1.125rem;
              vertical-align: middle;
              font-weight: var(--atomic-font-weight-medium);
            `}
            name={strings.firebaseEmailActionHandlers.continueToLogin}
          >
            <img
              alt={strings.logo.tara}
              className={css`
                width: 1.375rem;
                height: 1.375rem;
                text-align: left;
                margin-right: 1.25rem;
                vertical-align: middle;
              `}
              src={TaraLogoIconWhite}
            />
            {strings.firebaseEmailActionHandlers.continueToLogin}
          </Button>
        </a>
      </AuthLayout>
    );
  }
  return (
    <AuthLayout title={strings.passwordReset.reset}>
      <Input
        className={css`
          margin-bottom: 3rem;
        `}
        label={strings.passwordReset.emailAddress}
        readOnly
        type='email'
        value={email}
      />
      <Input
        bottomLabel={isError ? strings.passwordReset.passwordMustBeSixChar : ''}
        className={css`
          margin-bottom: 3rem;
        `}
        error={isError}
        label={strings.passwordReset.newPassword}
        name='password'
        onChange={onChange}
        required
        type='password'
        value={password}
      />
      <Button
        className={css`
          width: 100%;
        `}
        disabled={!isPasswordLongEnough}
        onClick={(): void => newPasswordWasGiven(password)}
        type='submit'
      >
        {strings.passwordReset.updatePassword}
      </Button>
    </AuthLayout>
  );
}
