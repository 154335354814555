import { useFirestoreConnect } from '@taraai/read-write';
import { UI } from '@taraai/types';
import uniqBy from 'lodash.uniqby';
import { useSelector } from 'react-redux';
import { getBlocked, selectActiveWorkspace } from 'reduxStore';

interface BlockedPullsData {
  blocked: UI.UIPullRequest[] | undefined;
  blockedByRepo: Record<string, UI.UIPullRequest[]> | undefined;
}

export function useBlockedPulls(): BlockedPullsData {
  const orgId = useSelector(selectActiveWorkspace);
  const blockedSlice = getBlocked(orgId);
  const blocked = useSelector(blockedSlice.selector);
  useFirestoreConnect(Array.from(blockedSlice.query));
  const uniqByBranchName = uniqBy(blocked, 'branchName');
  const blockedByRepo = blocked?.reduce((accumulator, target) => {
    const repos = blocked?.filter((repo) => repo.externalService.repository === target.externalService.repository);
    return { ...accumulator, [target.externalService.repository]: [repos] };
  }, {});

  return {
    blocked: uniqByBranchName,
    blockedByRepo,
  };
}
