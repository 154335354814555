import { unwrapResult } from '@reduxjs/toolkit';
import { Service } from '@taraai/types';
import { GitlabInsightsModalController } from 'components/app/controllers/GitlabModalController/GitlabInsightsModalController';
import ConnectExternalAccount from 'components/app/controllers/views/ConnectExternalAccount';
import GitlabIntegration from 'components/app/controllers/views/GitlabIntegration';
import IntegrationBoxView from 'components/app/controllers/views/IntegrationBoxView';
import IntegrationsApp, { IntegrationsAppProps } from 'components/app/controllers/views/IntegrationsApp';
import { ManageRepositoriesBtn } from 'components/app/controllers/views/IntegrationsApp/manageRepositoriesBtn';
import IntegrationsAppsList, { AppListEntry } from 'components/app/controllers/views/IntegrationsAppsList';
import { css } from 'emotion';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { isProfileAdmin, selectActiveWorkspace, useAppDispatch } from 'reduxStore';
import { disconnectProfile } from 'reduxStore/integrations/actions/disconnectProfile';
import { GitLabInsightsProject } from 'resources/assets';
import { IconName } from 'resources/assets/icons';
import { integrationTestIds } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';
import { useInstallGitHub, useInstallGitlab, useToast } from 'tools';
import { formatI18n } from 'tools/libraries/helpers/formatI18n';
import { useInstallGitlabAPI } from 'tools/utils/hooks/useInstallIntegration';
import { ConnectAccountGenericData } from 'types/connect-account';
import useImage from 'use-image';

type IsDisconnectPendingMap = {
  [serviceName in Service]: boolean;
};

interface IntegrationsAppWithProfile extends IntegrationsAppProps {
  withProfileConnect?: {
    service: 'github' | 'slack' | 'gitlab';
    title: string;
    description: string;
    connectData: ConnectAccountGenericData;
    label: string;
    icon?: IconName;
    connectedLabel: string;
  };
}

export function InsightsIntegrationController(): JSX.Element {
  const dispatch = useAppDispatch();
  const orgId = useSelector(selectActiveWorkspace);
  const { whenSuccess, whenError } = useToast();
  const isAdmin = useSelector(isProfileAdmin(orgId));

  const gitlabInstallationAPI = useInstallGitlabAPI();
  const gitHubInstallation = useInstallGitHub();
  const gitlabInstallation = useInstallGitlab(orgId);
  const [isDisconnectPendingMap, setIsDisconnectPendingMap] = useState({} as IsDisconnectPendingMap);

  const [modalImg] = useImage(GitLabInsightsProject);

  const withPendingState = useCallback(
    (
      service: Service,
      disconnectHandler: () => Promise<void>,
    ): {
      onDisconnect: IntegrationsAppProps['onDisconnect'];
      isLoading: IntegrationsAppProps['isLoading'];
    } => ({
      onDisconnect: async (): Promise<void> => {
        setIsDisconnectPendingMap({
          ...isDisconnectPendingMap,
          [service]: true,
        });

        await disconnectHandler();

        setIsDisconnectPendingMap({
          ...isDisconnectPendingMap,
          [service]: false,
        });
      },
      isLoading: !!isDisconnectPendingMap[service],
    }),
    [isDisconnectPendingMap, setIsDisconnectPendingMap],
  );

  const onDisconnectProfile = useCallback(
    async (service: 'github' | 'slack' | 'gitlab'): Promise<void> =>
      dispatch(disconnectProfile({ service }))
        .then(unwrapResult)
        .then(
          whenSuccess(
            formatI18n(strings.integrations.disconnectedProfileSuccess)({
              service,
            }),
          ),
        )
        .catch(
          whenError(
            formatI18n(strings.integrations.disconnectedProfileError)({
              service,
            }),
          ),
        ),
    [dispatch, whenSuccess, whenError],
  );

  const insightsApps = useMemo((): IntegrationsAppWithProfile[] => {
    return [
      {
        connectBtnDataCy: integrationTestIds.GITLAB_CONNECT,
        description: strings.integrations.gitlabApp.description,
        installationData: gitlabInstallationAPI,
        icon: 'gitlab',
        title: strings.integrations.gitlabApp.title,
        elements: [], // @TODO
        elementsTitle: strings.integrations.gitlab.repositoriesConnected,
        manageIntegrationBtn: <ManageRepositoriesBtn installationData={gitHubInstallation} orgId={orgId} />,
        ...withPendingState('gitlab', async (): Promise<void> => {
          // if (gitLabInstallation.id) {
          // }
        }),
      },
    ];
  }, [gitHubInstallation, gitlabInstallationAPI, orgId, withPendingState]);

  const entriesFromApps = insightsApps.map(
    (app): AppListEntry => ({
      key: app.title,
      installed: !!app.installationData.id,
      component: (): JSX.Element => <IntegrationsApp {...app} />,
      profileComponent: (): JSX.Element | null =>
        app.withProfileConnect ? (
          <IntegrationBoxView
            actionButton={
              <ConnectExternalAccount
                key={app.withProfileConnect.label}
                className={css`
                  margin: 0.5rem 0;
                `}
                onProfileDisconnect={onDisconnectProfile}
                {...app.withProfileConnect}
              />
            }
            description={app.withProfileConnect.description}
            icon={app.icon}
            isLoading={false}
            service={`${app.installationData.service}-profile`}
            title={app.withProfileConnect.title}
          />
        ) : null,
    }),
  );

  const gitlabEntry: AppListEntry = {
    key: 'gitlab',
    installed: gitlabInstallation.isInstalled,
    component: (): JSX.Element => (
      // TODO: Update the cloud function and url
      <GitlabIntegration
        isAdmin={isAdmin}
        isInstalled={gitlabInstallation.isInstalled}
        openModal={gitlabInstallation.openModal}
      />
    ),
  };

  const entries: AppListEntry[] = [...entriesFromApps, gitlabEntry];

  return (
    <IntegrationsAppsList entries={entries}>
      {gitlabInstallation.isModalOpen && (
        <GitlabInsightsModalController
          closeModal={gitlabInstallation.closeModal}
          instructionImgSrc={modalImg?.src}
          secretToken={gitlabInstallation.secretToken}
        />
      )}
    </IntegrationsAppsList>
  );
}
