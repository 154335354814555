import { Data } from '@taraai/types';

/**
 * Integration flow redirects user to an outside website (e.g. github.com).
 * When this service redirects back, we lose information about which org was selected before.
 * So we don't know with which org should the service be integrated.
 * That's why we need to store this information in the user's client session.
 */

const PENDING_INTEGRATION_ORG_KEY = 'pending-integration-org';

export const setPendingIntegrationOrg = (pendingIntegrationOrg: Data.Id.OrganizationId): void => {
  localStorage.setItem(PENDING_INTEGRATION_ORG_KEY, pendingIntegrationOrg);
};

export const getPendingIntegrationOrg = (): Data.Id.OrganizationId | undefined => {
  return localStorage.getItem(PENDING_INTEGRATION_ORG_KEY) ?? undefined;
};

export const clearPendingIntegrationOrg = (): void => {
  localStorage.removeItem(PENDING_INTEGRATION_ORG_KEY);
};
