import { collection, getFirestore, onSnapshot, orderBy, query, QueryConstraint, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import {
  selectActiveTeam,
  selectActiveWorkspace,
  useFilteredSummaryRequirementIds,
  useIsSearchActive,
} from 'reduxStore';
import { useWorkDrawerSettingsState } from 'reduxStore/workDrawer';

/**
 * @returns Ids of requirements specifically for the workdrawer component based on redux state
 */
export function useGetWorkDrawerRequirementIds(): string[] | null {
  const firestore = getFirestore();
  const orgId = useSelector(selectActiveWorkspace);
  const teamId = useSelector(selectActiveTeam);
  const showArchivedRequirements = useWorkDrawerSettingsState('requirementsSection:show-archived');
  const isSearchActive = useIsSearchActive();
  const requirementSearchIds = useFilteredSummaryRequirementIds();

  // State where requirement Ids are set. Null is the default so it will function as the loading state
  const [requirementIds, setRequirementIds] = useState<string[] | null>(null);

  // setup query constraints as an object to simplify modification
  const queryConstraints: Record<string, QueryConstraint> = {
    removeDeleted: where('deleted', '==', false),
    filterSelectedTeam: where('assignedTeamIds', 'array-contains', teamId),
    filterArchived: where('archived', '==', false),
    ordering: orderBy('createdAt', 'desc'),
  };

  // if search is active remove query for team and archived
  if (isSearchActive) {
    delete queryConstraints.filterSelectedTeam;
    delete queryConstraints.filterArchived;
  }

  // if show archived flag is true then remove query for archived
  if (showArchivedRequirements) {
    delete queryConstraints?.filterArchived;
  }

  useEffect(
    () => {
      const collectionPath = collection(firestore, `orgs/${orgId}/requirements`);
      // setup collection query
      const collectionQuery = query(collectionPath, ...Object.values(queryConstraints));
      onSnapshot(collectionQuery, (querySnapshot) => {
        const docIds = [] as string[];
        querySnapshot.forEach((doc) => {
          // filter for search. Do not push new ids to state if not in search
          if (isSearchActive && !requirementSearchIds.includes(doc.id)) return;
          docIds.push(doc.id);
        });
        if (!deepEquals(docIds, requirementIds)) {
          setRequirementIds(docIds);
        }
      });
    },
    // Include only dependencies necessary to the UI to rerender to prevent looping
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryConstraints],
  );

  return requirementIds;
}
