import { unwrapResult } from '@reduxjs/toolkit';
import { Box, Tree } from '@taraai/design-system';
import { useFirestoreConnect } from '@taraai/read-write';
import { Data, TaskStatus } from '@taraai/types';
import { DroppableArea, DroppableType } from 'components/app/DragAndDrop';
import { DNDContext } from 'components/app/DragAndDrop/DNDProvider';
import { EmptyDrawerTasks } from 'components/app/EmptyWorkDrawer/EmptyWorkDrawer';
import { TaskCreation } from 'components/app/TaskCreation';
import { selectTaskFragment } from 'components/app/WorkDrawer/WorkDrawerController';
import { CreateTaskEditor, TaskCards } from 'components/app/WorkDrawer/WorkDrawerSections';
import { PrimaryHeader } from 'components/app/WorkDrawerHeaders';
import { extractTaskData } from 'components/editor/plugins/extractTaskData';
import { RichEditorHandle } from 'components/editor/RichEditor';
import { AppCuesWrapper } from 'components/services/AppCues';
import React, { useCallback, useMemo, useRef } from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import {
  createTask,
  getTasksWithoutRequirementAndNotImported,
  selectActiveWorkspace,
  useAppDispatch,
  useFilteredOrphanTaskIds,
  useIsSearchActive,
  useSetNextTaskId,
} from 'reduxStore';
import { useWorkDrawerSettingsState, useWorkDrawerUnfoldedState } from 'reduxStore/workDrawer';
import { strings } from 'resources';
import { useToast } from 'tools';
import { segment } from 'tools/libraries/analytics';

export function OrphanTasks({
  onTaskSelect,
  selectedTaskId,
}: {
  onTaskSelect: (taskId: Data.Id.TaskId) => void;
  selectedTaskId: Data.Id.TaskId | undefined;
}): JSX.Element {
  const dispatch = useAppDispatch();
  const [unfolded, setUnfolded] = useWorkDrawerUnfoldedState('tasks');
  const editorRef = useRef<RichEditorHandle>(null);
  const { addToast } = useToast();
  const submitting = useRef(false);
  const orgId = useSelector(selectActiveWorkspace);

  const tasksWithoutRequirementSlice = getTasksWithoutRequirementAndNotImported(orgId, { orderBy: 'createdAt' });

  const showAssignedToSprintTasks = useWorkDrawerSettingsState('tasks:show-assigned-to-sprint', {
    checkSearchIsActive: true,
  });
  const showDoneTasks = useWorkDrawerSettingsState('tasks:show-done', {
    checkSearchIsActive: true,
  });

  const searchTaskIds = useFilteredOrphanTaskIds();
  const isSearchActive = useIsSearchActive();

  const taskIds = useSelector(
    (state) =>
      selectTaskFragment(state, tasksWithoutRequirementSlice.query[0]?.storeAs)
        ?.filter((task) => task.id && (!task?.externalIssue || task?.externalIssue.service === 'slack'))
        ?.filter(
          (task) =>
            (showDoneTasks ? true : task.status !== TaskStatus.Done) &&
            (showAssignedToSprintTasks ? true : task.sprint === null) &&
            (isSearchActive ? searchTaskIds.includes(task.id) : true),
        )
        .map((task) => task.id) || [],
    deepEquals,
  );

  useFirestoreConnect(Array.from(tasksWithoutRequirementSlice.query));

  useSetNextTaskId(taskIds, selectedTaskId);
  const tasksDroppableAreaDescription = useMemo(() => {
    return {
      id: 'workDrawer-tasks',
      type: DroppableType.workdrawerTasks,
      visibleList: taskIds,
      list: taskIds,
    };
  }, [taskIds]);

  const handleTaskCreation = useCallback(
    async (title: string, insertAtIndex?: number, ids?: Data.Id.TaskId[]): Promise<boolean> => {
      if (submitting.current) return false;
      submitting.current = true;
      const taskToCreate = { ...extractTaskData(title) };
      let success = false;
      await dispatch(createTask({ ...taskToCreate, insertAtIndex, taskIds: ids }))
        .then(unwrapResult)
        .then((newTaskId) => {
          segment.track('TaskCreated', { orgID: orgId, taskID: newTaskId, location: 'WorkDrawer' });
          success = true;
        })
        .catch(() => addToast({ type: 'error', message: strings.task.failedToCreateTask }));

      submitting.current = false;

      return success;
    },
    [addToast, dispatch, orgId],
  );

  const asyncHandleTaskCreation = useCallback(
    async (title: string) => {
      if (await handleTaskCreation(title)) editorRef.current?.clear();
    },
    [handleTaskCreation],
  );

  return (
    <DroppableArea description={tasksDroppableAreaDescription} styles={{ minHeight: '0' }}>
      {() => {
        return (
          <Tree unfolded={unfolded}>
            <PrimaryHeader onClick={() => setUnfolded(!unfolded)} title={strings.tasks.tasks} />
            <Tree.Content strategy='lazy'>
              <Box spaceBottom='$2px' spaceLeft='$12px' spaceTop='$2px'>
                <AppCuesWrapper targetName='orphan-task-creation'>
                  <Box background='$white' border='$grey4' borderRadius='$2px' space='$8px' spaceRight='$4px'>
                    <TaskCreation onEnter={asyncHandleTaskCreation}>
                      <CreateTaskEditor
                        ref={editorRef}
                        placeholder={strings.workDrawer.createTask}
                        saveOnBlur={false}
                      />
                    </TaskCreation>
                  </Box>
                </AppCuesWrapper>
              </Box>

              {taskIds.length ? (
                <DNDContext.Consumer>
                  {() => <TaskCards onTaskSelect={onTaskSelect} taskIds={taskIds} />}
                </DNDContext.Consumer>
              ) : (
                <EmptyDrawerTasks />
              )}
            </Tree.Content>
          </Tree>
        );
      }}
    </DroppableArea>
  );
}
