import { css, cx } from 'emotion';
import React, { useEffect, useState } from 'react';

export interface RadioProps extends React.HTMLProps<HTMLInputElement> {
  /**
   * The <label> for this radio button
   */
  label: string;
}

/**
 * A wrapper around <input type="radio"/>
 *
 * - [Design](https://zpl.io/aXXRQKx)
 */
export default function Radio({ checked, className, disabled, label, ...props }: RadioProps): JSX.Element {
  const [isChecked, setIsChecked] = useState(checked);
  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);
  return (
    <label
      className={cx(
        css`
          display: block;
          position: relative;
          padding-left: 1.5rem;
          margin-bottom: 0.5rem;
          cursor: pointer;
          font-size: 1rem;
          user-select: none;
          color: ${disabled ? '#c8d0df' : '#303f4b'};
        `,
        className,
      )}
    >
      <input
        type='radio'
        {...props}
        className={css`
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        `}
        onChange={(event): void => setIsChecked(event.target.checked)}
      />
      <span
        className={css`
          position: absolute;
          top: 0;
          left: 0;
          height: 1rem;
          width: 1rem;
          border: 0.0625rem solid ${isChecked && !disabled ? '#50af28' : '#c8d0df'};
          background-color: #ffffff;
          border-radius: 50%;
          &:after {
            content: '';
            position: absolute;
            display: ${isChecked ? 'block' : 'none'};
            top: 0.125rem;
            left: 0.125rem;
            width: 0.625rem;
            height: 0.625rem;
            border-radius: 50%;
            background: ${disabled ? '#c8d0df' : '#50af28'};
          }
        `}
      />
      {label}
    </label>
  );
}
