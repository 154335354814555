import { Box, HStack, Text, VStack } from '@taraai/design-system';
import { getStyledRichEditor, RichEditorHandle } from 'components/editor/RichEditor';
import React, { Ref } from 'react';
import { strings } from 'resources/i18n';

interface SprintDetailsSidebarDescriptionProps {
  counter?: {
    currentLength: number;
    maxLength: number;
  };
  isEditing?: boolean;
  descriptionRef: Ref<RichEditorHandle>;
}

export function SprintDetailsSidebarDescription({
  counter,
  isEditing = false,
  descriptionRef,
}: SprintDetailsSidebarDescriptionProps): JSX.Element {
  return (
    <Box
      background={isEditing ? '$grey1' : undefined}
      borderRadius='$2px'
      outline={isEditing ? '$focus' : undefined}
      spaceVert='$2px'
    >
      <VStack space='$12px'>
        <DescriptionEditor ref={descriptionRef} placeholder={strings.sprintDetailsSidebar.description.placeholder} />
        {counter ? (
          <HStack align='right'>
            <Box spaceRight='$4px'>
              <Text color='$grey6' size='$10px' weight='medium'>
                {counter.currentLength} / {counter.maxLength}
              </Text>
            </Box>
          </HStack>
        ) : (
          // Placeholder
          <Text size='$10px'>&nbsp;</Text>
        )}
      </VStack>
    </Box>
  );
}

const DescriptionEditor = getStyledRichEditor({
  'fontSize': '$12px',
  'lineHeight': '$12px',

  '&[contenteditable="false"]': {
    color: '$grey7',
  },
});
