import { createAsyncThunk } from '@reduxjs/toolkit';
import { Data, Functions } from '@taraai/types';
import { decode } from 'reduxStore/utils/decoders';
import { ExtraAPI } from 'reduxStore/utils/types';

type Payload = {
  invitationId: Data.Id.IntegrationId;
};

export const deactivateInviteLink = createAsyncThunk(
  'DeactivateInviteLink',
  async ({ invitationId }: Payload, { extra }) => {
    const { getFirebase, getOrgId } = extra as ExtraAPI;
    const organizationId = getOrgId();

    const payloadDraft: Functions.DeactivateInviteLink.Payload = {
      invitationId,
      organizationId,
    };

    const payload = decode<Functions.DeactivateInviteLink.Payload>(payloadDraft, 'DeactivateInviteLinkPayload');

    await getFirebase().functions().httpsCallable('deactivateInviteLink')(payload);
  },
);
