import { getCustomSize, styled, Text, tokens } from '@taraai/design-system';
import { Data } from '@taraai/types';
import SprintColumnController from 'components/app/controllers/SprintColumnController/SprintColumnController';
import { ScrollPane } from 'components/app/layouts/ScrollPane/ScrollPane';
import { SearchBar } from 'components/app/SearchBar';
import { ZoomManager } from 'components/app/ZoomManager';
import React, { useCallback } from 'react';
import { strings } from 'resources/i18n';
import { SprintPathId } from 'tools/utils/hooks/usePagination';

export default function SprintColumnsLayout({
  currentSprintId,
  onSelectCurrentSprint,
  onTaskSelect,
  selectedSprintId,
  sprints,
  onScrollBackEdge,
  onScrollForwardEdge,
}: {
  currentSprintId: string;
  onSelectCurrentSprint: () => void;
  onTaskSelect: (taskId: Data.Id.TaskId) => void;
  selectedSprintId: Data.Id.SprintId;
  sprints: SprintPathId[] | undefined;
  onScrollBackEdge: () => void;
  onScrollForwardEdge: () => void;
}): JSX.Element {
  const renderSprintColumn = useCallback(
    (
      [, id, isPhantom]: SprintPathId,
      ref: React.RefObject<HTMLDivElement>,
      offsetFromSelected: number,
      offsetFromCurrent: number,
      idx: number,
    ): JSX.Element => {
      return (
        <SprintColumnWrapper key={isPhantom ? 'phantom' : id} ref={ref} data-click='sprint-column'>
          <SprintColumnController
            // If the sprint is behind the accepted one or is complete, we don't want to enable to drop tasks in it
            acceptDrops={offsetFromCurrent >= -1}
            currentSprintId={currentSprintId}
            idx={idx}
            isPhantom={isPhantom === true}
            offsetFromCurrent={offsetFromCurrent}
            onTaskSelect={onTaskSelect}
            sprintId={id}
          />
        </SprintColumnWrapper>
      );
    },
    [currentSprintId, onTaskSelect],
  );

  return (
    <Wrapper>
      <ControlsRow>
        <SearchWrapper>
          <SearchBar />
        </SearchWrapper>
        <ZoomWrapper>
          <ZoomManager />
        </ZoomWrapper>
        <ControlButtonWrapper>
          <ControlButton onClick={onSelectCurrentSprint} type='button'>
            <Text color='current' size='$10px'>
              {strings.sprints.sprintColumn.jumpToCurrentSprint}
            </Text>
          </ControlButton>
        </ControlButtonWrapper>
      </ControlsRow>
      <ScrollPane
        anchorLeft={columnWidthInPx / offset}
        currentId={currentSprintId}
        items={sprints ?? []}
        onScrollBackEdge={onScrollBackEdge}
        onScrollForwardEdge={onScrollForwardEdge}
        renderItem={renderSprintColumn}
        selectedId={selectedSprintId}
      />
    </Wrapper>
  );
}

const offset = 2.25;
const columnHorzPaddingInPx = 12;
const columnWidthInPx = 280 + 2 * columnHorzPaddingInPx;
const controlRowHeight = getCustomSize(40);
const controlButtonSize = getCustomSize(16);
const columnHorzPadding = getCustomSize(columnHorzPaddingInPx);
const columnWidth = getCustomSize(columnWidthInPx);
const searchWidth = getCustomSize(340);

const Wrapper = styled('div', {
  background: tokens.colors.$white,
  height: '100%',
});

const ControlsRow = styled('div', {
  height: controlRowHeight,
  left: 0,
  position: 'absolute',
  right: 0,
  // TODO: replace `zIndex: 2` with an upcoming LevelUp component
  zIndex: '2',
});
const ControlButtonWrapper = styled('div', {
  position: 'absolute',
  right: '12px',
  top: '8px',
});
const ControlButton = styled(
  'button',
  {
    'alignItems': 'center',
    'backgroundColor': '$white',
    'border': 'none',
    'borderRadius': '$2px',
    'color': '$grey7',
    'cursor': 'pointer',
    'display': 'inline-flex',
    'height': controlButtonSize,
    'justifyContent': 'center',
    'outline': 'none',
    'padding': '$2px 8px',
    ':hover': { color: '$grey6' },
  },
  {
    square: { true: { width: controlButtonSize } },
  },
);

const SearchWrapper = styled('div', {
  '--right': '200px',
  'maxWidth': `calc(100% - 1.25 * ${columnWidth} - var(--right))`,
  'overflow': 'hidden',
  'position': 'absolute',
  'right': 'var(--right)',
  'top': '50%',
  'transform': 'translateY(-50%)',
  'width': searchWidth,
});

export const SprintColumnWrapper = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: '1',
  height: '100%',
  padding: `${controlRowHeight} ${columnHorzPadding} 0`,
  width: columnWidth,
});

const ZoomWrapper = styled('div', {
  '--right': '130px',
  // '--right': 'space.$24px',
  'overflow': 'hidden',
  'position': 'absolute',
  'right': 'var(--right)',
  'top': '50%',
  'transform': 'translateY(-50%)',
});
