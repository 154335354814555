import { styled, Text } from '@taraai/design-system';
import Avatar from 'components/core/controllers/views/Avatar';
import { linkTo } from 'components/Router/paths';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectActiveTeam, selectActiveWorkspace } from 'reduxStore';
import { Profile } from 'reduxStore/utils';
import { strings } from 'resources';
import { JOIN_COMMUNITY_URL, PRODUCT_GUIDE } from 'tools/libraries/helpers';

type ProfileSummaryProps = {
  containerRef?: React.RefObject<HTMLDivElement>;
  profile: Profile;
  email: string | null;
};

/**
 * @FIXME fix text selection
 */
export function Popup({ profile, email, containerRef }: ProfileSummaryProps): JSX.Element {
  const orgId = useSelector(selectActiveWorkspace);
  const teamId = useSelector(selectActiveTeam);

  return (
    <PopupContainer ref={containerRef}>
      <PersonalDataContainer>
        <AvatarStyled size='2rem' user={profile} />
        <TextContainer>
          <Text color='$dark' singleLine size='$12px' textAlign='left'>
            {profile.name}
          </Text>
          <Text color='$grey6' singleLine size='$12px' textAlign='left'>
            {email}
          </Text>
        </TextContainer>
      </PersonalDataContainer>
      <OptionsContainer>
        <Option to={linkTo('profile', { orgId, teamId })}>
          <Text color='$dark' size='$12px' textAlign='left'>
            {strings.profileLabels.settings}
          </Text>
        </Option>
        <OptionLink href={JOIN_COMMUNITY_URL} rel='noreferrer' target='_blank'>
          <Text color='$dark' size='$12px' textAlign='left'>
            {strings.profileLabels.community}
          </Text>
        </OptionLink>
        <OptionLink href={PRODUCT_GUIDE} rel='noreferrer' target='_blank'>
          <Text color='$dark' size='$12px' textAlign='left'>
            {strings.profileLabels.guide}
          </Text>
        </OptionLink>
        <Option to={linkTo('logout')}>
          <Text color='$dark' size='$12px' textAlign='left'>
            {strings.profileLabels.logout}
          </Text>
        </Option>
      </OptionsContainer>
    </PopupContainer>
  );
}

const PopupContainer = styled('div', {
  position: 'absolute',
  right: '1.25rem',
  top: '2.5rem',
  boxShadow: '$popup',
  border: 'borderWidths.$1px solid colors.$grey5',
  borderRadius: '0.1875rem',
  width: '15.625rem',
  backgroundColor: '$white',
  cursor: 'default',
  pointerEvents: 'auto',
  outline: 'none',
});

const PersonalDataContainer = styled('div', {
  height: '4rem',
  display: 'flex',
  alignItems: 'center',
});

const AvatarStyled = styled(Avatar, {
  marginLeft: '1rem',
});

const TextContainer = styled('div', {
  width: '11rem',
  marginLeft: '0.75rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const OptionsContainer = styled('div', {
  border: 'borderWidths.$1px solid colors.$grey5',
  borderWidth: '0.0625rem 0 0 0',
  padding: '0.3125rem 0',
  display: 'flex',
  flexDirection: 'column',
});

const Option = styled(Link, {
  'display': 'flex',
  'alignItems': 'center',
  'height': '2rem',
  'paddingLeft': '1rem',
  'cursor': 'pointer',
  ':hover': {
    backgroundColor: '$grey1',
  },
});

const OptionLink = styled('a', {
  'display': 'flex',
  'alignItems': 'center',
  'height': '2rem',
  'paddingLeft': '1rem',
  'cursor': 'pointer',
  ':hover': {
    backgroundColor: '$grey1',
  },
});
