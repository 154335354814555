import { styled, Tooltip } from '@taraai/design-system';
import Icon from 'components/core/controllers/views/Icon';
import React from 'react';
import { IconName } from 'resources';

interface Props {
  iconName: IconName;
  onClick: () => void;
  title: string;
}

export function PopupButton({ iconName, onClick, title }: Props): JSX.Element {
  return (
    <Tooltip title={title}>
      <PopupIcon name={iconName} onClick={onClick} />
    </Tooltip>
  );
}

export const PopupIcon = styled(Icon, {
  'color': '$grey6',
  'cursor': 'pointer',
  'height': 'auto',
  'padding': '0',
  'verticalAlign': 'top',
  'width': 'auto',

  ':hover': {
    opacity: 0.7,
  },
});
