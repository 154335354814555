import styled from '@emotion/styled';
import { StandardSpinner } from 'components/core/controllers/views/Spinners';
import { css } from 'emotion';
import React from 'react';
import { EmptyTara } from 'resources/assets';
import { strings } from 'resources/i18n';

import AnimatedTextCollection from './AnimatedTextCollection';

const Container = styled.div`
  padding: 5rem 6.25rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 500px) {
    padding: 5rem 2rem 0;
  }
`;

const Header = styled.div`
  font-size: 1.25rem;
  font-weight: 600;
  text-align: center;
  color: #4550ce;
  max-width: 25rem;
`;

type UserAccountSetupProgressProps = {
  message: string;
};

export default function UserAccountSetupProgress({ message }: UserAccountSetupProgressProps): JSX.Element {
  const texts = strings.userAccountSetupProgress.animatedTexts;

  return (
    <Container>
      <Header>{message}</Header>
      <AnimatedTextCollection texts={texts} />
      <StandardSpinner
        className={css`
          margin-top: 1rem;
          margin-bottom: 2.75rem;
        `}
        size='medium'
      />
      <img
        alt={message}
        className={css`
          height: 100%;
          width: 100%;
        `}
        src={EmptyTara}
      />
    </Container>
  );
}
