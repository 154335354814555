import { createAsyncThunk } from '@reduxjs/toolkit';
import { Data } from '@taraai/types';
import { isNonEmptyString } from '@taraai/utility';
import { ExtraAPI } from 'reduxStore/utils/types';

interface DeleteTaskComment {
  commentId: Data.Id.CommentId;
  taskId: Data.Id.TaskId;
}

export const deleteTaskComment = createAsyncThunk(
  'DeleteTaskComment',
  async ({ commentId, taskId }: DeleteTaskComment, { extra }) => {
    const { getOrgId, getFirestore } = extra as ExtraAPI;
    const orgId = getOrgId();
    const firestore = getFirestore();

    if (!isNonEmptyString(taskId)) {
      throw new Error('Missing task id');
    }
    if (!isNonEmptyString(commentId)) {
      throw new Error('Missing comment id');
    }

    await getFirestore().update<Data.Comment>(`orgs/${orgId}/tasks/${taskId}/comments/${commentId}`, {
      deletedAt: firestore.Timestamp.now(),
    });

    return { id: commentId };
  },
);
