import { useFirestoreConnect } from '@taraai/read-write';
import { Data, TaskStatus, UI } from '@taraai/types';
import TotalEffort from 'components/app/controllers/views/TotalEffort';
import { linkTo } from 'components/Router/paths';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { compose } from 'redux';
import { computeTotalEffort, estimatedEffortSelector, getCompletedSprints, selectActiveTeam } from 'reduxStore';

type SprintFragment = Pick<UI.UISprint, 'id' | 'computedOnCompletion' | 'isComplete' | 'completedAt'>;

export interface SprintEffortProps {
  tasks: UI.UITask[];
  orgId: Data.Id.OrganizationId;
  isTextHidden?: boolean;
  currentSprint: SprintFragment;
}

/**
 * SprintEffort calculates total effort level and estimated effort
 * based on sprint and task data
 */
export default function SprintEffort({
  tasks,
  orgId,
  isTextHidden = false,
  currentSprint,
}: SprintEffortProps): JSX.Element {
  const history = useHistory();
  const teamId = useSelector(selectActiveTeam);

  const completedSprintSlice = getCompletedSprints(orgId, teamId);
  useFirestoreConnect(completedSprintSlice.query);

  const completedSprintCount = useSelector(compose((arr) => arr?.length || 0, completedSprintSlice.selector));
  const estimatedEffort = useSelector(estimatedEffortSelector(completedSprintSlice.selector, () => currentSprint)) ?? 0;

  const totalEffort = computeTotalEffort(currentSprint, tasks);

  const completedEffort = tasks
    ?.filter(({ status }) => status === TaskStatus.Done)
    ?.reduce((tasksDone, task) => tasksDone + Number(task.effortLevel) || 1, 0);

  const navigateToSprintInsights = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    if (completedSprintCount <= 0) {
      return;
    }
    history.push(
      linkTo('sprintInsights', {
        orgId,
        teamId,
        sprintId: currentSprint.id,
      }),
    );
  };

  const navigateToSprintDetailsInsights = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    if (completedSprintCount <= 0) {
      return;
    }
    history.push(
      linkTo('sprintDetailsInsights', {
        orgId,
        teamId,
        sprintId: currentSprint.id,
      }),
    );
  };

  return (
    <TotalEffort
      completedEffort={completedEffort}
      completedSprintsWithEffortEstimation={completedSprintCount}
      estimatedEffort={estimatedEffort}
      isTextHidden={isTextHidden}
      navigateToSprintDetailsInsights={navigateToSprintDetailsInsights}
      navigateToSprintInsights={navigateToSprintInsights}
      totalEffort={totalEffort}
    />
  );
}
