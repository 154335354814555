import { unwrapResult } from '@reduxjs/toolkit';
import { styled, Tooltip } from '@taraai/design-system';
import { Data, UI } from '@taraai/types';
import Icon from 'components/core/controllers/views/Icon';
import { InputWithButtons } from 'components/core/controllers/views/InputWithButtons';
import { getRouteName, linkTo } from 'components/Router/paths';
import React, { ChangeEventHandler, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { selectActiveTeam, selectActiveWorkspace, updateTeam, useAppDispatch } from 'reduxStore';
import { decodeChain } from 'reduxStore/utils/decoders';
import { strings } from 'resources';
import { teamDetailsTestIds, teamSwitcherTestIds } from 'resources/cypress/testAttributesValues';
import { useToast } from 'tools';

export interface TeamItemProps extends React.HTMLProps<HTMLDivElement> {
  isDisabled: boolean;
  isPreferred: boolean;
  isSelected: boolean;
  teamId: Data.Id.TeamId;
  teamName: string;
}

export function TeamItem({ isDisabled, isPreferred, isSelected, teamId, teamName }: TeamItemProps): JSX.Element {
  const dispatch = useAppDispatch();
  const orgId = useSelector(selectActiveWorkspace);
  const currentTeamId = useSelector(selectActiveTeam);
  const { whenSuccess, whenError } = useToast();
  const updateName = useCallback(
    (newName: string): void => {
      decodeChain<UI.UITeamChangeset>({ id: teamId, name: newName }, 'UITeamPartial')
        .then((data) => dispatch(updateTeam(data)))
        .then(unwrapResult)
        .then(whenSuccess(strings.teamDetails.editNameSuccess))
        .catch(whenError((err) => `${strings.teamDetails.editError}: ${err.message}`));
    },
    [dispatch, teamId, whenError, whenSuccess],
  );
  const [isEditing, setIsEditing] = useState(false);
  const handleClick = (): void => {
    setIsEditing(true);
  };
  const routeName = getRouteName();

  const switchTeam = (): string => {
    if (isDisabled) return '';
    // If on 'sprintDetails' route redirect to current sprint on team change. Otherwise sprint page will not update.
    if (routeName === 'sprintDetails') {
      return linkTo('sprintDetails', {
        orgId,
        teamId,
        sprintId: 'current',
      });
    }
    // don't persist selection if switched in define with selected task or requirement selected
    if (routeName === 'requirement' || routeName === 'task') {
      return linkTo('requirements', {
        orgId,
        teamId,
      });
    }
    return history.location.pathname.replace(`/${currentTeamId}`, `/${teamId}`);
  };

  const [newTeamName, setNewTeamName] = useState(teamName);

  const handleNameChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setNewTeamName(event.target.value);
  }, []);

  const handleOnCancel = useCallback(() => {
    setNewTeamName(teamName);
    setIsEditing(false);
  }, [teamName]);

  const handleOnSave = useCallback(() => {
    if (teamName !== newTeamName) {
      updateName(newTeamName);
    }
    setIsEditing(false);
  }, [newTeamName, teamName, updateName]);
  const history = useHistory();
  const navigateToDelete = useCallback((): void => {
    history.push(linkTo('deleteTeamWithConfirmation', { orgId, teamId }));
  }, [history, orgId, teamId]);

  return (
    <Option data-cy={teamSwitcherTestIds.TEAM_SELECT_ITEM} isDisabled={isDisabled}>
      {isEditing ? (
        <InputWithButtons
          dataCyCancel={teamDetailsTestIds.EDIT_TEAM_NAME_CANCEL_BUTTON}
          dataCyConfirm={teamDetailsTestIds.EDIT_TEAM_NAME_CONFIRM_BUTTON}
          handleOnCancel={handleOnCancel}
          handleOnChange={handleNameChange}
          handleOnSave={handleOnSave}
          value={newTeamName}
        />
      ) : (
        <OptionName to={switchTeam}>{teamName}</OptionName>
      )}
      {!isEditing && (
        <Tooltip title={strings.teamSwitcher.edit.toUpperCase()}>
          <EditIcon id='edit' isSelected={isSelected} name='edit' onClick={handleClick} />
        </Tooltip>
      )}
      {!isPreferred && !isSelected && !isEditing && (
        <Tooltip title={strings.teamSwitcher.delete.toUpperCase()}>
          <TrashIcon id='trash' name='trash' onClick={navigateToDelete} />
        </Tooltip>
      )}
      {isSelected && !isEditing && <IndicatorStyled name='check' />}
    </Option>
  );
}

const OptionName = styled(Link, {
  display: 'block',
  fontSize: '0.875rem',
  color: '$dark',
  textAlign: 'left',
  marginLeft: '0.5rem',
  width: '$full',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  maxHeight: '20px',
  whiteSpace: 'nowrap',
});

const IndicatorStyled = styled(Icon, {
  position: 'absolute',
  top: '50%',
  right: '0.5rem',
  transform: 'translateY(-50%)',
  color: 'white',
  width: '0.875rem',
  height: '0.625rem',
});

const Option = styled(
  'div',
  {
    'position': 'relative',
    'padding': '1rem',
    'height': '2.25rem',
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'flex-start',
    '&:hover': {
      backgroundColor: '$grey1',
    },
    ':hover #edit': { display: 'block' },
    ':hover #trash': { display: 'block' },
  },
  {
    isDisabled: {
      true: { opacity: '50%', pointerEvents: 'none', cursor: 'none' },
    },
  },
);

const EditIcon = styled(
  Icon,
  {
    display: 'none',
    color: 'white',
    position: 'absolute',
    right: '0.5rem',
    width: '0.75rem',
    height: '0.75rem',
  },
  { isSelected: { true: { right: '1.5rem' } } },
);
const TrashIcon = styled(Icon, {
  display: 'none',
  color: 'white',
  position: 'absolute',
  right: '1.5rem',
  width: '0.75rem',
  height: '0.75rem',
});
