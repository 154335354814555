import React from 'react';

import LeafMenuItem from './LeafMenuItem';
import { LeafMenuOption, MenuOption } from './menuTypes';
import SubMenuItem from './SubMenuItem';

export interface MenuItemProps {
  option: MenuOption;

  isDisabled?: boolean;

  /**
   * shows left padding to show selected check mark on left of item
   */
  showSelection?: boolean;
  showTooltip?: boolean;

  selectedItem?: string;
  title?: string;
}
export default function MenuItem({
  option,
  isDisabled = false,
  showSelection = false,
  showTooltip = false,
  title,
  selectedItem,
}: MenuItemProps): JSX.Element {
  if (typeof (option as LeafMenuOption).onSelect === 'function') {
    const tooltipProps = showTooltip ? { title, showTooltip } : {};
    return (
      <LeafMenuItem
        data-cy={option.dataCy}
        isDisabled={isDisabled}
        option={option}
        selectedItem={selectedItem}
        showSelection={showSelection}
        {...tooltipProps}
      />
    );
  }
  return <SubMenuItem data-cy={option.dataCy} isDisabled={isDisabled} option={option} />;
}
