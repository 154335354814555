import styled from '@emotion/styled';
import { HeaderRenderProps } from 'components/core/controllers/Selector';
import Button from 'components/core/controllers/views/Button';
import Icon from 'components/core/controllers/views/Icon';
import React from 'react';
import { dropDownTestIds } from 'resources/cypress/testAttributesValues';

import Search from './Search';

export type SearchHeaderProps = HeaderRenderProps & {
  title?: string;
  searchPlaceholder?: string;
  showSearch?: boolean;
  dataCySearch?: string;
  hideCloseIcon?: boolean;
};

export default function SearchHeader({
  title,
  searchPlaceholder,
  showSearch = false,
  hideCloseIcon = false,
  searchInputRef,
  searchInputValue,
  onClose,
  onSearchInputChange,
  onSearchInputKeyDown,
  dataCySearch,
}: SearchHeaderProps): JSX.Element {
  return (
    <Container>
      {title && (
        <Header>
          {title}
          {!hideCloseIcon && <ExitButton onClick={onClose} />}
        </Header>
      )}
      {showSearch && (
        <Search
          data-cy={dataCySearch}
          inputRef={searchInputRef}
          onChange={onSearchInputChange}
          onKeyDown={onSearchInputKeyDown}
          placeholder={searchPlaceholder}
          value={searchInputValue}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  box-shadow: 0 1px 0 0 #dee3ec;
  position: relative;
`;

const Header = styled.div`
  width: 100%;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  color: #303f4b;
  text-align: left;
`;

const ExitButton = styled((props) => (
  <Button color='ghost' data-cy={dropDownTestIds.CLOSE_POPUP_BUTTON} name='exit button' size='compact' {...props}>
    <ExitIcon />
  </Button>
))`
  padding: 0rem;
  width: 0.5625rem;
  height: 0.5625rem;
  padding: 0;
  margin-bottom: 0.5rem;
  outline: none;
  border: none;
  z-index: 2;
  transition: all 0.2s ease-in-out;
  transform-origin: center;

  &:hover {
    transform: scale(1.2);
    cursor: pointer;
    border: none;
  }

  &:focus {
    transform: scale(1.1);
    cursor: pointer;
  }
`;

const ExitIcon = styled((props) => <Icon {...props} name='exit' />)`
  width: 100%;
  height: 100%;
  padding: 0;
`;
