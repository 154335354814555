import { createAction, createSlice } from '@reduxjs/toolkit';
import { Data } from '@taraai/types';

export const openTaskModal = createAction<Data.Id.TaskId>('openTaskModal');
export const closeTaskModal = createAction('closeTaskModal');

const slice = createSlice({
  name: 'taskModal',
  initialState: {
    showModalForTask: null as Data.Id.TaskId | null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(closeTaskModal, (state) => ({
      ...state,
      showModalForTask: null,
    }));
    builder.addCase(openTaskModal, (state, action) => ({
      ...state,
      showModalForTask: action.payload,
    }));
  },
});

export const taskModalReducer = slice.reducer;
