import { unwrapResult } from '@reduxjs/toolkit';
import { styled, Tooltip } from '@taraai/design-system';
import { isLoaded } from '@taraai/read-write';
import { Data } from '@taraai/types';
import Button from 'components/core/controllers/views/Button';
import Icon from 'components/core/controllers/views/Icon';
import Modal from 'components/core/controllers/views/Modal';
import Radio from 'components/core/controllers/views/Radio';
import Select from 'components/core/controllers/views/Select';
import { FastSmallSpinner } from 'components/core/controllers/views/Spinners';
import { linkTo, usePathParams } from 'components/Router/paths';
import { css, cx } from 'emotion';
import pick from 'lodash.pick';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectOrganization, selectTeam, updateSprintSettings, useAppDispatch } from 'reduxStore';
import { atomic } from 'resources';
import { sprintSettingsTestIds } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';
import { useToast } from 'tools';
import { guessTimezone } from 'tools/utils/timezones';

export default function SprintSettingsController(): JSX.Element | null {
  const { orgId, teamId } = usePathParams('sprintsSettings');
  const org = useSelector(selectOrganization(orgId));
  const team = useSelector(selectTeam(orgId, teamId));

  return org && team ? <SprintSettings featureFlags={org.featureFlags} team={team} /> : null;
}

type SprintSettingsProps = {
  featureFlags: Pick<Data.FeatureFlags, 'autoSprints' | 'testMode'>;
  team: Data.Team;
};

function SprintSettings({ featureFlags, team }: SprintSettingsProps): JSX.Element | null {
  const dispatch = useAppDispatch();
  const { orgId, teamId } = usePathParams('sprintsSettings');
  const history = useHistory();
  const { addToast } = useToast();

  const [duration, setDuration] = useState(team.sprintSettings.duration);
  const [isLoading, setIsLoading] = useState(false);
  const [autoPilotOn, setAutoPilotOn] = useState(team.sprintSettings.autoSprints);
  const [time, setTime] = useState(pick(team.sprintSettings, 'endHours', 'endMinutes'));
  const [timezone, setTimezone] = useState(team.sprintSettings.timezone);
  const [endDay, setEndDay] = useState(team.sprintSettings.endDay);
  const [moveRemainingTasksTo, setMoveRemainingTasksTo] = useState(team.sprintSettings.moveRemainingTasksTo);

  useEffect(() => {
    if (!timezone) {
      setTimezone(guessTimezone());
    }
  }, [timezone]);

  const sprintsPath = linkTo('sprints', { orgId, teamId });

  const editSettings = (): Promise<void> => {
    setIsLoading(true);
    return dispatch(
      updateSprintSettings({
        teamId,
        organizationId: orgId,
        sprintSettings: {
          autoSprints: autoPilotOn,
          duration,
          timezone,
          endDay,
          moveRemainingTasksTo,
          ...time,
        },
      }),
    )
      .then(unwrapResult)
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        addToast({
          message: strings.sprintsSettings.updateError,
          type: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
        history.push(sprintsPath);
      });
  };

  if (!isLoaded(team)) {
    return null;
  }

  return (
    <Modal
      bodyStyle={css`
        min-width: 46.875rem;
        max-width: 46.875rem;
      `}
      footer={
        <div
          className={css`
            display: flex;
            width: 100%;
            justify-content: flex-end;
          `}
        >
          <Button
            className={cx(css`
              margin: 0rem;
              padding: 0rem;
              margin-right: 1rem;
            `)}
            color='ghost'
            data-cy={sprintSettingsTestIds.CANCEL_BUTTON}
            onClick={(): void => history.push(sprintsPath)}
          >
            {strings.sprintsSettings.cancel}
          </Button>
          <Button
            className={cx(css`
              margin: 0rem;
              padding: 0rem;
            `)}
            data-cy={sprintSettingsTestIds.SAVE_BUTTON}
            disabled={isLoading}
            name={strings.sprintsSettings.save}
            onClick={editSettings}
          >
            {isLoading && (
              <FastSmallSpinner
                color='white'
                spinnerStyles={css`
                  padding: 0;
                  vertical-align: text-bottom;
                  margin-right: 0.3125rem;
                `}
              />
            )}
            {strings.sprintsSettings.save}
          </Button>
        </div>
      }
      header={strings.sprintsSettings.title}
    >
      {(featureFlags.autoSprints || autoPilotOn) && (
        <AutoPilotSwitcher featureFlags={featureFlags} onChange={setAutoPilotOn} value={autoPilotOn} />
      )}
      {autoPilotOn && (
        <>
          <RemainingTasksDestinationSelector onChange={setMoveRemainingTasksTo} value={moveRemainingTasksTo} />
          <EndDaySelect onChange={setEndDay} value={endDay} />
          <TimeSelect onChange={setTime} onTzChange={setTimezone} tzValue={timezone} value={time} />
        </>
      )}
      <DurationSelect featureFlags={featureFlags} onChange={setDuration} value={duration} />
      {autoPilotOn && <AutoPilotBanner endDay={endDay} endTime={time} />}
    </Modal>
  );
}

type RemainingTasksDestinationSelectorProps = {
  value: Data.MoveRemainingTasksTo;
  onChange: (value: Data.MoveRemainingTasksTo) => void;
};

const REMAINING_TASKS_DESTINATIONS = [
  { label: strings.sprintsSettings.nextSprint, value: 'NextSprint' },
  { label: strings.sprintsSettings.backlog, value: 'Backlog' },
] as const;

function RemainingTasksDestinationSelector({
  value: selectedValue,
  onChange,
}: RemainingTasksDestinationSelectorProps): JSX.Element {
  return (
    <InputSection>
      <InputLabel>{strings.sprintsSettings.remainingTasksDestination}</InputLabel>
      <InputDescription>{strings.sprintsSettings.remainingTasksDestinationDescription}</InputDescription>
      <RadiosContainer>
        {REMAINING_TASKS_DESTINATIONS.map(({ label, value }) => (
          <Radio
            key={value}
            checked={selectedValue === value}
            data-cy={sprintSettingsTestIds.RADIO_OPTION}
            label={label}
            onClick={(): void => onChange(value)}
          />
        ))}
      </RadiosContainer>
    </InputSection>
  );
}

type DurationSelectProps = {
  featureFlags: Pick<Data.FeatureFlags, 'testMode'>;
  value: Data.SprintDuration;
  onChange: (value: Data.SprintDuration) => void;
};

function DurationSelect({ featureFlags, onChange, value: selectedValue }: DurationSelectProps): JSX.Element {
  const durations = useMemo(() => {
    return [
      { label: strings.sprints.durationLength['1 week'], value: '1 week' },
      { label: strings.sprints.durationLength['2 weeks'], value: '2 weeks' },
      // only show 1 hour option in test mode (or if it's already selected)
      ...(featureFlags.testMode || selectedValue === '1 hour'
        ? [{ label: strings.sprints.durationLength['1 hour'], value: '1 hour' }]
        : []),
    ] as { label: string; value: Data.SprintDuration }[];
  }, [featureFlags, selectedValue]);

  return (
    <InputSection>
      <InputLabel>{strings.sprintsSettings.duration}</InputLabel>
      <InputDescription>{strings.sprintsSettings.durationDescription}</InputDescription>
      <RadiosContainer>
        {/* eslint-disable-next-line sonarjs/no-identical-functions */}
        {durations.map(({ label, value }) => (
          <Radio
            key={value}
            checked={selectedValue === value}
            data-cy={sprintSettingsTestIds.RADIO_OPTION}
            label={label}
            onClick={(): void => onChange(value)}
          />
        ))}
      </RadiosContainer>
    </InputSection>
  );
}

type EndDaySelectProps = {
  value: number;
  onChange: (value: number) => void;
};

const DAYS_ABBREV = [
  { label: strings.sprintsSettings.sat, value: Data.WeekDay.Saturday },
  { label: strings.sprintsSettings.sun, value: Data.WeekDay.Sunday },
  { label: strings.sprintsSettings.mon, value: Data.WeekDay.Monday },
  { label: strings.sprintsSettings.tue, value: Data.WeekDay.Tuesday },
  { label: strings.sprintsSettings.wed, value: Data.WeekDay.Wednesday },
  { label: strings.sprintsSettings.thu, value: Data.WeekDay.Thursday },
  { label: strings.sprintsSettings.fri, value: Data.WeekDay.Friday },
];

function EndDaySelect({ value: selectedValue, onChange }: EndDaySelectProps): JSX.Element {
  return (
    <InputSection>
      <InputLabel>{strings.sprintsSettings.endDay}</InputLabel>
      <InputDescription>{strings.sprintsSettings.endDayDescription}</InputDescription>
      <DaysContainer>
        {DAYS_ABBREV.map(({ label, value }) => (
          <Day key={value} onClick={() => onChange(value)} selected={value === selectedValue}>
            {label}
          </Day>
        ))}
      </DaysContainer>
    </InputSection>
  );
}

type TimeSelectProps = {
  tzValue: Data.Timezone | null;
  onTzChange: (value: Data.Timezone) => void;
  value: Pick<Data.SprintSettings, 'endHours' | 'endMinutes'>;
  onChange: (value: Pick<Data.SprintSettings, 'endHours' | 'endMinutes'>) => void;
};

const timeToStr = (time: Pick<Data.SprintSettings, 'endHours' | 'endMinutes'>): string =>
  moment().set({ hours: time.endHours, minutes: time.endMinutes }).format('HH:mm');

function TimeSelect({ tzValue, onTzChange, onChange, value }: TimeSelectProps): JSX.Element {
  const onTimeChange = useCallback(
    async ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      const [endHoursRaw, endMinutesRaw] = target.value.split(':').map((str) => parseInt(str, 10));
      const endHours = (endHoursRaw || 0) as unknown as Data.SprintSettings['endHours'];
      const endMinutes = (endMinutesRaw || 0) as unknown as Data.SprintSettings['endMinutes'];

      onChange({ endHours, endMinutes });
    },
    [onChange],
  );

  return (
    <InputSection>
      <InputLabel>{strings.sprintsSettings.endTime}</InputLabel>
      <InputDescription>{strings.sprintsSettings.timezoneDescription}</InputDescription>
      <TimeSelectInputsContainer>
        <TimeInput
          onChange={onTimeChange}
          step={60 * 30} // 30min
          type='time'
          value={timeToStr(value)}
        />
        <TimeInputsSeparator />
        <TimeZoneSelect
          onChange={(entry) => onTzChange(entry.value as Data.Timezone)}
          options={Array.from(Data.TIMEZONES_SHORTLIST)}
          placeholder={strings.sprintsSettings.selectTimezone}
          value={Data.TIMEZONES_SHORTLIST.find((entry) => entry.value === tzValue)}
        />
      </TimeSelectInputsContainer>
    </InputSection>
  );
}

type AutoPilotSwitcherProps = {
  featureFlags: Pick<Data.FeatureFlags, 'testMode'>;
  value: boolean;
  onChange: (newValue: boolean) => void;
};

function AutoPilotSwitcher({ featureFlags, value, onChange }: AutoPilotSwitcherProps): JSX.Element {
  // disable possibility to turn off auto pilot if already on (or always allow in test mode)
  const disableOffOption = value && !featureFlags.testMode;

  const OffBtnParent = ({ children }: { children: JSX.Element }): JSX.Element =>
    disableOffOption ? (
      <Tooltip placement='right' title={strings.sprintsSettings.cantDisableAutoPilot}>
        <div>{children}</div>
      </Tooltip>
    ) : (
      children
    );

  return (
    <InputSection>
      <InputLabel>{strings.sprintsSettings.autoPilot}</InputLabel>
      <InputDescription>{strings.sprintsSettings.autoPilotDescription}</InputDescription>
      <RadiosContainer>
        <RadioStyled checked={value} label={strings.sprintsSettings.on} onClick={() => onChange(true)} />
        <OffBtnParent>
          <RadioWithDisable
            checked={!value}
            disable={disableOffOption}
            disabled={disableOffOption}
            label={strings.sprintsSettings.off}
            onClick={() => onChange(false)}
          />
        </OffBtnParent>
      </RadiosContainer>
    </InputSection>
  );
}

type AutoPilotBannerProps = {
  endDay: number;
  endTime: Pick<Data.SprintSettings, 'endHours' | 'endMinutes'>;
};

const formatBannerDate = (date: moment.Moment): string => {
  const isUsing12HourFormat = !!new Date().toLocaleString(navigator.language).match(/am|pm/i);
  const formatHour24 = 'HH:mm';
  const formatHour12 = 'hh:mm A';
  return date.format(`dddd ${isUsing12HourFormat ? formatHour12 : formatHour24}`);
};

function AutoPilotBanner({ endDay, endTime }: AutoPilotBannerProps): JSX.Element {
  const endDateMoment = moment().set({ hours: endTime.endHours, minutes: endTime.endMinutes, day: endDay });
  const endDate = formatBannerDate(endDateMoment);
  const startDate = formatBannerDate(endDateMoment.set({ minutes: endTime.endMinutes + 1 }));
  return (
    <AutoPilotBannerContainer>
      <AutoPilotBannerIcon color={atomic.colors.white} name='infoCircle' />
      {strings.formatString(strings.sprintsSettings.bannerMessage, { endDate, startDate })}
    </AutoPilotBannerContainer>
  );
}

const TimeInput = styled('input', {
  borderWidth: 0,
  borderBottomWidth: '1px',
  borderColor: '$grey',
  padding: '0.4rem',
});

const RadioStyled = styled(Radio, {
  marginBottom: 0,
});

const RadioWithDisable = styled(
  RadioStyled,
  {
    margin: 0,
  },
  {
    disable: {
      true: {
        cursor: 'normal',
        pointerEvents: 'none',
      },
    },
  },
);

const AutoPilotBannerIcon = styled(Icon, {
  width: '1.5rem',
  height: '1.5rem',
});

const AutoPilotBannerContainer = styled('div', {
  backgroundColor: '$success',
  height: '4rem',
  borderRadius: '0.3rem',
  color: '$white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '1rem',
  fontWeight: 500,
});

const DaysContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
});

const Day = styled(
  'div',
  {
    width: '2.2rem',
    height: '2.2rem',
    marginRight: '1rem',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  {
    selected: {
      false: {
        background: 'transparent',
        color: '$dark',
      },
      true: {
        backgroundColor: '$indigo',
        color: '$white',
      },
    },
  },
);

const TimeSelectInputsContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
});

const TimeInputsSeparator = styled('div', {
  width: '1px',
  height: '1.5rem',
  backgroundColor: '$grey7',
  margin: '0 2rem',
});

const TimeZoneSelect = styled(Select, {
  width: '20rem',
});

const RadiosContainer = styled('div', {
  'display': 'flex',
  '& *': {
    marginRight: '3rem',
  },
});

const InputLabel = styled('h4', {
  fontSize: '1.2rem',
  marginBottom: '0.25rem',
});

const InputDescription = styled('p', {
  marginBottom: '0.75rem',
});

const InputSection = styled('div', {
  marginBottom: '1.5rem',
});
