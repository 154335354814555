import { Timestamp, UI } from '@taraai/types';
import { isNonEmptyString } from '@taraai/utility';
import { createStandardSelector, inertQuery, Query } from 'reduxStore/utils/selectors';
import { ReduxFirestoreQuery } from 'reduxStore/utils/types';
import { firestore } from 'tools';

export function getSprintCommits(
  orgId: string,
  sprintId: string,
  sprintStart: Timestamp | Date | null | undefined,
  sprintEnd: Timestamp | Date | null | undefined,
): Query<UI.UICommit> {
  if (!isNonEmptyString(orgId) || !isNonEmptyString(sprintId) || !sprintStart || !sprintEnd) {
    return inertQuery();
  }

  const sprintStartTimestamp = sprintStart instanceof Date ? firestore.Timestamp.fromDate(sprintStart) : sprintStart;

  const sprintEndTimestamp = sprintEnd instanceof Date ? firestore.Timestamp.fromDate(sprintEnd) : sprintEnd;

  const query: ReduxFirestoreQuery = {
    collection: `orgs/${orgId}/commits`,
    where: [
      ['createdAt', '>', sprintStartTimestamp],
      ['createdAt', '<', sprintEndTimestamp],
    ],
    orderBy: [['createdAt', 'asc']],
    storeAs: `commits/${orgId}/${sprintId}/sprints`,
  };

  return {
    query: [query],
    selector: createStandardSelector<UI.UICommit>(query),
  };
}
