import { useCallback, useMemo } from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import { getUsers } from 'reduxStore';

/**
 * Gets user mention tag `"@Bob Ross"` for user using allUsers list.
 *
 * __NOTE__: allUsers list will always be populated here because of check
 * in `OrgAndTeamIsLoaded.tsx`.
 */
export function useUserTagForId(orgId: string): (id: string) => string | undefined {
  const allUsersSelector = useMemo(
    () => compose((users) => users?.map(({ id, name }) => ({ id, name })), getUsers(orgId).selector),
    [orgId],
  );
  const allUsers = useSelector(allUsersSelector, deepEquals);

  return useCallback(
    (id: string): string | undefined => {
      const foundUser = allUsers?.find((user) => user.id === id);
      return foundUser ? `@${foundUser.name}` : undefined;
    },
    [allUsers],
  );
}
