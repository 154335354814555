import { styled } from '@taraai/design-system';
import Button from 'components/core/controllers/views/Button';
import Input from 'components/core/controllers/views/Input';
import Modal from 'components/core/controllers/views/Modal';
import Text from 'components/core/controllers/views/Text';
import { css } from 'emotion';
import React from 'react';
import { atomic } from 'resources';
import { newRequirementTestIds } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';

type Props = {
  inputRef: React.MutableRefObject<HTMLInputElement>;
  inputError: string;
  onCancel: () => void;
  onCreate: () => void;
  createButtonText: string;
  titleText: string;
};

export function NewRequirement({
  inputRef,
  inputError,
  onCancel,
  onCreate,
  createButtonText,
  titleText,
}: Props): JSX.Element {
  return (
    <Modal
      bodyStyle={ModalBodyStyles}
      footer={
        <FooterWrapper>
          <CancelButton color='ghost' data-cy={newRequirementTestIds.REQUIREMENT_CANCEL_BUTTON} onClick={onCancel}>
            {strings.requirements.cancel}
          </CancelButton>
          <CreateButton data-cy={newRequirementTestIds.REQUIREMENT_CONFIRM_BUTTON} onClick={onCreate}>
            {createButtonText}
          </CreateButton>
        </FooterWrapper>
      }
      header={<HeaderWrapper>{titleText}</HeaderWrapper>}
    >
      <InputWrapper>
        {inputError && (
          <InputWrapper>
            <Text color={atomic.colors.error}>{inputError}</Text>
          </InputWrapper>
        )}
        <Input
          Ref={inputRef}
          autoFocus
          data-cy={newRequirementTestIds.REQUIREMENT_NAME_INPUT}
          onKeyPress={(event: KeyboardEvent): void => {
            const code = event.keyCode || event.which;
            if (code === 13) onCreate();
          }}
          placeholder={strings.requirements.enterRequirementName}
        />
      </InputWrapper>
    </Modal>
  );
}

const ModalBodyStyles = css`
  min-width: 46.875rem;
  max-width: 46.875rem;
`;

const CancelButton = styled(Button, {
  marginRight: '$16px',
  minWidth: '6.25rem',
  minHeight: '2.375rem',
  padding: 0,
});

const CreateButton = styled(Button, {
  minWidth: '6.25rem',
  minHeight: '2.375rem',
  padding: '0rem 1rem',
});

const HeaderWrapper = styled(Text, {
  color: '#303f4b',
  fontSize: '1rem',
  fontWeight: '500',
});

const InputWrapper = styled('div', {
  marginBottom: '1rem',
});

const FooterWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
});
