import { UI } from '@taraai/types';
import PullRequestDetails from 'components/app/controllers/views/PullRequestDetails';
import Avatar from 'components/core/controllers/views/Avatar';
import Icon from 'components/core/controllers/views/Icon';
import { css } from 'emotion';
import moment from 'moment';
import React, { useState } from 'react';
import { strings } from 'resources/i18n';
import { atomic } from 'resources/theme';
import { formatIdWithRepositoryName, toDate } from 'tools/libraries/helpers';

type PullRequestListItemProps = {
  pullRequest: UI.UIPullRequest;
  showDetailsButton?: boolean;
  showLinesAdded?: boolean;
  showLinesRemoved?: boolean;
  showCreatedAt?: boolean;
  showBranch?: boolean;
  showStatus?: boolean;
  showCommentsCount?: boolean;
};

const createdAtTitle = (
  pullRequest: UI.UIPullRequest,
  ...props: React.HTMLProps<HTMLDivElement>[]
): JSX.Element | null => {
  if (!pullRequest.createdAt) return null;
  const createdAt = toDate(pullRequest.createdAt);

  return (
    <div
      className={css`
        color: ${atomic.get(atomic.colors.grey6)};
        padding-right: 0.8rem;
        text-align: right;
        flex-shrink: 0;
      `}
      {...props}
    >
      <span
        className={css`
          position: relative;
        `}
        title={createdAt.toLocaleDateString()}
      >
        {moment(createdAt).fromNow()}
      </span>
    </div>
  );
};
const prStatusLabels: Record<string, string> = {
  'pending': strings.pullRequests.prStatus.statusLabels.pending,
  'merge-ready': strings.pullRequests.prStatus.statusLabels.mergeReady,
  'blocked': strings.pullRequests.prStatus.statusLabels.blocked,
  'conflicts': strings.pullRequests.prStatus.statusLabels.conflicts,
  'checks-failed': strings.pullRequests.prStatus.statusLabels.checksFailed,
};

const statusColors: Record<string, string> = {
  'pending': '#303f4b',
  'merge-ready': '#389e0d',
  'blocked': '#e86262',
  'conflicts': '#e86262',
  'checks-failed': '#e86262',
};
/**
 * A representation of a pull request that can be used
 * in a ListView.
 */
const PullRequestListItem: React.FC<PullRequestListItemProps> = ({
  pullRequest,
  showDetailsButton = true,
  showLinesAdded = true,
  showLinesRemoved = true,
  showCreatedAt = false,
  showBranch = false,
  showStatus = false,
  showCommentsCount = false,
}: PullRequestListItemProps) => {
  const [showDetails, setShowDetails] = useState(false);

  // Currently the gitlab integration does not provide the data about PR lines addded/removed
  const skipLinesAddedRemoved = pullRequest.externalService.service === 'gitlab';

  return (
    <div
      className={css`
        display: flex;
        flex-direction: row;
        border-bottom: 0.0625rem solid ${atomic.get(atomic.colors.grey3)};
        border-radius: ${showDetailsButton ? '0.125rem' : '0rem'};
        font-size: 0.75rem;
        align-items: center;
        :after {
          content: '';
          display: table;
          clear: both;
        }
      `}
    >
      <Avatar
        className={css`
          margin: 0.8rem;
          flex-shrink: 0;
        `}
        size='2rem'
        user={pullRequest.user}
      />
      <div
        className={css`
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-right: 0.8rem;
          float: left;
          width: 40%;
        `}
      >
        <div
          className={css`
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          `}
        >
          <a
            className={css`
              :hover {
                text-decoration: underline;
              }
            `}
            href={pullRequest.url}
            rel='noopener noreferrer'
            target='_blank'
          >
            {pullRequest.title}
          </a>
        </div>
        <div
          className={css`
            padding-bottom: 0.25rem;
          `}
        >
          <a
            className={css`
              color: ${atomic.get(atomic.colors.grey7)};
              text-decoration: none;
              padding-right: 0.5rem;
              :hover {
                text-decoration: underline;
              }
            `}
            href={pullRequest.url}
            rel='noopener noreferrer'
            target='_blank'
          >
            {strings.formatString(strings.pullRequestListItem.issueId, {
              id: formatIdWithRepositoryName(pullRequest),
            })}
          </a>
          {!skipLinesAddedRemoved && (
            <>
              {showLinesAdded && (
                <span
                  className={css`
                    color: ${atomic.get(atomic.colors.success)};
                    padding-right: 0.5rem;
                  `}
                >
                  {strings.formatString(strings.pullRequestListItem.linesAdded, {
                    linesAdded: pullRequest.additionsCount.toString(),
                  })}
                </span>
              )}
              {showLinesRemoved && (
                <span
                  className={css`
                    color: ${atomic.get(atomic.colors.error)};
                  `}
                >
                  {strings.formatString(strings.pullRequestListItem.linesRemoved, {
                    linesRemoved: pullRequest.deletionsCount.toString(),
                  })}
                </span>
              )}
            </>
          )}
          {showCreatedAt && (
            <span
              className={css`
                color: ${atomic.get(atomic.colors.grey7)};
              `}
            >
              {strings.formatString(strings.pullRequestListItem.createdAt, {
                time: moment(toDate(pullRequest.createdAt)).fromNow(),
                user: pullRequest.user?.name,
              })}
            </span>
          )}
        </div>
      </div>
      {!showCreatedAt && createdAtTitle(pullRequest)}

      {showBranch && pullRequest.branchName && (
        <span
          className={css`
            float: left;
            border-radius: 3px;
            width: 30%;
            text-align: center;
            padding-right: 0.8rem;
          `}
        >
          <span
            className={css`
              font-size: 0.875rem;
              color: #67728b;
              background-color: #eef1f7;
              padding: 0.22rem;
            `}
          >
            {pullRequest.branchName}
          </span>
        </span>
      )}
      {showStatus && (
        <span
          className={css`
            float: left;
            width: 20%;
            text-align: center;
            padding-right: 10.5rem;
            font-size: 0.875rem;
            font-weight: 500;
            color: ${statusColors[pullRequest.mergeableState]};
          `}
        >
          {prStatusLabels[pullRequest.mergeableState]}
        </span>
      )}
      {showCommentsCount && (
        <>
          <Icon
            className={css`
              fill: none;
            `}
            name='comment'
          />
          <span
            className={css`
              text-align: center;
              padding-right: 2rem;
            `}
          >
            <a
              className={css`
                text-decoration: none;
                padding-right: 0.5rem;
                :hover {
                  text-decoration: underline;
                }
              `}
              href={pullRequest.url}
              rel='noopener noreferrer'
              target='_blank'
            >
              {pullRequest.commentsCount}
            </a>
          </span>
        </>
      )}
      <button
        className={css`
          outline: none;
          background: none;
          border: none;
          text-align: left;
          position: relative;
          display: ${showDetailsButton ? 'inherit' : 'none'};
        `}
        onClick={(): void => setShowDetails(true)}
        type='button'
      >
        <Icon
          className={css`
            width: 16px;
            height: 16px;
            cursor: pointer;
            :hover {
              opacity: 0.6;
            }
          `}
          name='kebab'
        />
        {showDetails && (
          <PullRequestDetails
            className={css`
              position: absolute;
              top: 50%;
              right: 50%;
            `}
            onClose={(): void => setShowDetails(false)}
            pullRequest={pullRequest}
          />
        )}
      </button>
    </div>
  );
};

export default PullRequestListItem;
