import { useCallback } from 'react';
import { useHistory } from 'react-router';

type ParamsIn = Record<string, string>;

/**
 * Passing a `null` means that the param will be stripped from the URL; otherwise if a param is
 * not passed, it will stay as-is
 */
type ParamsOut = Record<string, string | null>;

export function useQuerySetState(): (
  queryUpdater: ParamsOut | ((params: ParamsIn) => ParamsOut),
  replaceUpdater?: boolean | ((params: ParamsIn) => boolean),
) => void {
  const routerHistory = useHistory();
  return useCallback(
    (queryUpdater, replaceUpdater) => {
      // We have to use the global location since react-router's API doesn't accept updater functions
      const params = new URLSearchParams(window.location.search);
      const paramsMap = Object.fromEntries(params);
      const query = typeof queryUpdater === 'function' ? queryUpdater(paramsMap) : queryUpdater;
      const replace = typeof replaceUpdater === 'function' ? replaceUpdater(paramsMap) : replaceUpdater;
      Object.entries(query).forEach(([name, value]) => {
        if (value === null) params.delete(name);
        else params.set(name, value);
      });
      const nextUrl = `${routerHistory.location.pathname}?${params.toString()}`;
      const currentUrl = `${window.location.pathname}${window.location.search}${window.location.hash}`;
      if (nextUrl === currentUrl) return;
      if (replace) routerHistory.replace(nextUrl);
      else routerHistory.push(nextUrl);
    },
    [routerHistory],
  );
}
