/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * I'm disabling the 'any' type here because header/tbody types inside the table are from value unknown.
 * Also, column defs and callbacks served by the library returns data with any annotation
 */

import {
  AccessorFn,
  ColumnDef,
  ColumnHelper,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Box, styled } from '@taraai/design-system';
import groupBy from 'lodash.groupby';
import React, { useMemo, useState } from 'react';

import { ColumnDefinition, TableProps } from './_types';
import { resolvePath } from './utils/resolvePath';

const generateColumnAccessors = (
  columnHelper: ColumnHelper<any>,
  columnsDef: ColumnDefinition[],
): ColumnDef<unknown, unknown>[] => {
  const columnsAccessors: ColumnDef<Record<string, unknown>, any>[] = [];

  columnsDef.forEach((def) => {
    const columnName = def.accessor as unknown as AccessorFn<unknown, unknown>;

    /**
     * Mount the config
     * In case we have header config missing for some column, the name of the column
     * will be the property name in data object
     */

    columnsAccessors.push(
      columnHelper.accessor(columnName, {
        // Here, we pass first our deafult columns definition, because we can override it with custom defs for each column
        ...defaultColumnsDefinition,
        ...def,
      }) as ColumnDef<Record<string, unknown>, any>,
    );
  });
  // Then return it to the table instance
  return columnsAccessors as unknown as ColumnDef<unknown, unknown>[];
};

/**
 * Here, we can group the table data by some property in the object, then we create separated
 * Sections that we can fold or unfold clicking on the section
 */
const generateTableSections = (data: any, groupByField: string): any[] => {
  return groupBy(data, groupByField) as unknown as any[];
};

const defaultColumnsDefinition: Partial<ColumnDefinition> = {
  alignX: 'center',
  alignY: 'middle',
};

const TableSection = ({
  children,
  sectionName,
  cols,
}: {
  children?: React.ReactNode;
  sectionName: string;
  cols: number;
}): JSX.Element => {
  const [showSectionItems, setShowSectionsItems] = React.useState(true);

  const toggleShow = React.useCallback((event: React.SyntheticEvent) => {
    event.stopPropagation();
    setShowSectionsItems((val) => !val);
  }, []);

  const TinyCaret = ({ isOpen }: { isOpen: boolean }): JSX.Element => (
    <svg
      fill='none'
      height='4'
      style={{
        WebkitTransition: '-webkit-transform .8s ease-in-out;',
        marginRight: '6px',
        marginTop: '-3px',
        transform: `rotate(${isOpen ? '180deg' : '360deg'})`,
      }}
      viewBox='0 0 6 4'
      width='6'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.99851 3.5L5.57173 0.926777C5.72922 0.769286 5.61768 0.5 5.39496 0.5H0.602064C0.379337 0.5 0.267796 0.769285 0.425287 0.926777L2.99851 3.5Z'
        fill='#303F4B'
      />
    </svg>
  );

  return (
    <>
      <TableRow>
        <TableSectionSeparator onClick={toggleShow}>
          <Box spaceLeft='$16px'>
            <TinyCaret isOpen={showSectionItems} /> {`${sectionName}`}
          </Box>
        </TableSectionSeparator>

        {Array.from(Array(cols - 1).keys(), () => (
          <TableData style={{ border: 'none' }} />
        ))}
      </TableRow>

      <div
        style={{
          opacity: !showSectionItems ? '0' : '1',
          transition: 'all .2s',
          visibility: !showSectionItems ? 'hidden' : 'visible',
          height: !showSectionItems ? '0' : 'auto',
          display: !showSectionItems ? 'block' : 'contents',
        }}
      >
        {children}
      </div>
    </>
  );
};

export function ReactTable({
  tableData,
  columnsConfig,
  showFooter = false,
  groupByField,
  customizeGroupName,
}: TableProps): JSX.Element {
  // Presentational Data, which we display.
  const [data, setData] = React.useState<any[]>(tableData);

  const [columnsDef, setColumnsDef] = useState(columnsConfig);

  const [shouldGroupByField] = useState(typeof groupByField === 'string' && groupByField !== undefined);
  const [showTableFooter, setShowTableFooter] = useState(showFooter);

  useMemo(() => {
    setData(tableData);
  }, [tableData]);

  useMemo(() => {
    setShowTableFooter(showFooter);
  }, [showFooter]);

  useMemo(() => {
    setColumnsDef(columnsConfig);
  }, [columnsConfig]);

  useMemo(() => {
    setColumnsDef(columnsConfig);
  }, [columnsConfig]);

  const columnHelper = createColumnHelper<typeof data>();

  const columns = generateColumnAccessors(columnHelper, columnsDef);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <TableContainer>
        <colgroup>
          {Array.from(Array(columnsDef.length).keys(), () => (
            <col />
          ))}
        </colgroup>
        <thead>
          {table.getHeaderGroups().map((headerGroup: { id: React.Key | null | undefined; headers: unknown[] }) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header: any) => {
                return (
                  <TableHeader
                    key={header.id}
                    style={{
                      verticalAlign: header.column.columnDef.alignY,
                      marginLeft: header.column.columnDef.marginLeft,
                    }}
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHeader>
                );
              })}
            </TableRow>
          ))}
        </thead>
        <tbody>
          {groupByField && shouldGroupByField ? (
            Object.keys(generateTableSections(tableData, groupByField)).map((group: string) => {
              return (
                <TableSection
                  key={group}
                  cols={columnsDef.length}
                  sectionName={customizeGroupName ? customizeGroupName(group) : group}
                >
                  <>
                    {table
                      .getRowModel()
                      .rows.map(
                        (row: {
                          id: React.Key | null | undefined;
                          getVisibleCells: () => unknown[];
                          original: any;
                        }) => (
                          <TableRow key={row.id}>
                            {row.getVisibleCells().map((cell: any) => {
                              return (
                                resolvePath(groupByField, cell.row.original) === group && (
                                  <TableData
                                    key={cell.id}
                                    style={{
                                      verticalAlign: cell.column.columnDef.alignY,
                                      textAlign: cell.column.columnDef.alignX,
                                      padding: cell.column.columnDef.cellPadding,
                                    }}
                                  >
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                  </TableData>
                                )
                              );
                            })}
                          </TableRow>
                        ),
                      )}
                  </>
                </TableSection>
              );
            })
          ) : (
            <>
              {table
                .getRowModel()
                .rows.map((row: { id: React.Key | null | undefined; getVisibleCells: () => unknown[] }) => (
                  <TableRow key={row.id}>
                    {row.getVisibleCells().map((cell: any) => (
                      <TableData
                        key={cell.id}
                        style={{
                          verticalAlign: cell.column.columnDef.alignY,
                          textAlign: cell.column.columnDef.alignX,
                        }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableData>
                    ))}
                  </TableRow>
                ))}
            </>
          )}
        </tbody>
        {showTableFooter && (
          <tfoot>
            {table.getFooterGroups().map((footerGroup: { id: React.Key | null | undefined; headers: unknown[] }) => (
              <TableRow key={footerGroup.id}>
                {footerGroup.headers.map((header: any) => (
                  <TableHeader key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
                  </TableHeader>
                ))}
              </TableRow>
            ))}
          </tfoot>
        )}
      </TableContainer>
    </>
  );
}

const defaultTableBorder = '1px solid rgba(255, 255, 255, 0.15)';

const TableContainer = styled('table', {
  borderSpacing: '0',
  width: '100%',
  color: '$dark',
  fontStyle: 'normal',
  tableLayout: 'fixed',
});

const TableRow = styled('tr', {
  'margin': '0',
  'fontSize': '$12px',
  '& :last-child': {
    '& td': {
      borderBottom: defaultTableBorder,
    },
  },
});

const TableHeader = styled('th', {
  margin: '0',
  padding: '0.5rem',
  borderLeft: 'none',
  borderRight: 'none',
  fontSize: '$12px',
  fontWeight: '600',
  height: '48px',
  textAlign: 'left',
  color: '$background',
});

const TableData = styled('td', {
  'margin': '0',
  'padding': '0.5rem',
  'border': defaultTableBorder,
  'borderTop': 'none',
  'borderBottom': 'none',
  'height': '48px',
  'borderLeft': 'none',
  'overflow': 'hidden',
  'textOverflow': 'ellipsis',
  'whiteSpace': 'nowrap',
  'color': '$secondaryText',
  '& :last-child': {
    border: 'none',
  },
});

const TableSectionSeparator = styled(TableData, {
  border: 'none',
  fontWeight: '600',
});
