import Box from 'components/core/controllers/views/Box';
import Checkbox from 'components/core/controllers/views/Checkbox';
import Icon from 'components/core/controllers/views/Icon';
import Menu from 'components/core/controllers/views/Menu';
import { useTaskTitleConfig } from 'components/editor/config';
import { RichEditor } from 'components/editor/RichEditor';
import { RichEditorProvider } from 'components/editor/RichEditorProvider';
import { css, cx } from 'emotion';
import React, { ChangeEventHandler } from 'react';
import { strings } from 'resources/i18n';

type SubtaskViewProps = {
  completeProps: {
    checked: boolean;
    onChange: ChangeEventHandler<HTMLInputElement>;
  };
  subtaskTitle: string;
  handleUpdate: (content: string) => void;
  deleteCurrentTask: () => void;
  dragRef: React.MutableRefObject<HTMLDivElement | null>;
};

/**
 * This component renders a single subtask.
 */
export default function SubtaskView({
  completeProps,
  subtaskTitle,
  handleUpdate,
  deleteCurrentTask,
  dragRef,
}: SubtaskViewProps): JSX.Element {
  return (
    <div
      ref={dragRef}
      className={cx(
        css`
          margin-bottom: 0.5rem;
          border-radius: 0.125rem;
          border: solid 0.0625rem #eaeef5;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0 0.5em;
          &:focus-within {
            border-radius: 0.1875rem;
            border: solid 0.125rem #1d98ff;
          }
        `,
      )}
      onClick={(event): void => event.stopPropagation()}
      onKeyDown={(event): void => {
        if (event.keyCode === 13) event.stopPropagation();
      }}
      role='button'
      tabIndex={0}
    >
      <Checkbox {...completeProps} />
      <div
        className={css`
          flex: 1;
        `}
      >
        <RichEditorProvider config={useTaskTitleConfig()} initialValue={subtaskTitle} onSave={handleUpdate}>
          <RichEditor placeholder={strings.task.subtaskPlaceholder} />
        </RichEditorProvider>
      </div>
      {/* subtask meatball menu */}
      <Box
        className={css`
          position: relative;
          justify-self: flex-end;
          fill: #949caf;
          width: 1.875rem;
        `}
      >
        <Menu
          options={[
            {
              title: strings.tasks.delete,
              onSelect: deleteCurrentTask,
            },
          ]}
        >
          <Icon
            className={css`
              fill: #949caf;
              width: 0.875rem;
            `}
            name='meatballs'
          />
        </Menu>
      </Box>
    </div>
  );
}
