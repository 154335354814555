import { Box, getCustomSize, styled, VStack } from '@taraai/design-system';
import React from 'react';

import { ErrorOnSave } from './ErrorOnSave';
import { Footer } from './Footer';
import { Header } from './Header';
import { Save } from './Save';
import { SprintDuration } from './SprintDuration';
import { SprintEndTime } from './SprintEndTime';
import { SprintSettingsViewProps } from './types';
import { UncompletedTasks } from './UncompletedTasks';

const sprintSettingsWidth = getCustomSize(400);

export function SprintSettingsView(props: SprintSettingsViewProps): JSX.Element {
  return (
    <Wrapper background='$grey3' width={sprintSettingsWidth}>
      <VStack space='$1px'>
        <Header />
        <SprintEndTime {...props} />
        <SprintDuration {...props} />
        <UncompletedTasks {...props} />
        <Footer {...props} />
        {props.state.status === 'dirty' && <Save {...props} />}
        {props.state.errorMessage && <ErrorOnSave message={props.state.errorMessage} />}
      </VStack>
    </Wrapper>
  );
}

const Wrapper = styled(Box, {
  overflow: 'hidden',
});
