/* eslint-disable no-loops/no-loops */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-continue */

/** ************************************
 *
 *  This file is copied from here:
 *      https://github.com/remirror/remirror/blob/main/packages/remirror__extension-markdown/src/html-to-markdown.ts
 *
 *  If there is any issue with the mapping / translation between
 *  HTML and MARKDOWN, first update this document with the latest code in that file.
 *
 *  The reasons for this file ot exists within the Tara code are:
 *      * We need customisation of the mapping to enable backward compatibility.
 *      * The current extension architecture does not allow for method extension.
 *
 *  @TODO:  Implement the super() pattern in the remirror markdown extension
 *          and propose a change in the official repository
 *
 ************************************ */

/**
 * @module
 *
 * Use `turndown` to provide a github flavoured markdown converter and the
 * default common mark converter.
 */
import { ErrorConstant, invariant, isElementDomNode } from '@remirror/core';
import TurndownService from 'turndown';

/**
 * Converts the provide HTML to markdown.
 */
export function htmlToMarkdown(html: string): string {
  return turndownService.turndown(html);
}

/**
 * A tableRow is a heading row if:
 * - the parent is a THEAD
 * - or if its the first child of the TABLE or the first TBODY (possibly
 *   following a blank THEAD)
 * - and every cell is a TH
 */
function isHeadingRow(tableRow: Node): tableRow is HTMLTableRowElement {
  const { parentNode } = tableRow;

  if (!isElementDomNode(parentNode)) {
    return false;
  }

  return (
    parentNode.nodeName === 'THEAD' ||
    (parentNode.firstChild === tableRow &&
      (parentNode.nodeName === 'TABLE' || isFirstTbody(parentNode)) &&
      Array.from(tableRow.childNodes).every((node) => {
        return node.nodeName === 'TH';
      }) &&
      Array.from(tableRow.childNodes).some((node) => !!node.textContent))
  );
}

/**
 * Check whether this is the first `tbody` in the table.
 */
function isFirstTbody(element: Node): element is HTMLTableSectionElement {
  const { previousSibling } = element;
  return (
    element.nodeName === 'TBODY' &&
    (!previousSibling ||
      (isElementDomNode(previousSibling) &&
        previousSibling.nodeName === 'THEAD' &&
        !previousSibling.textContent?.trim()))
  );
}

/**
 * Create a cell from the table.
 */
function cell(content: string, node: Node) {
  const index = Array.from(node.parentNode?.childNodes ?? []).indexOf(node as ChildNode);
  let prefix = ' ';

  if (index === 0) {
    prefix = '| ';
  }

  return `${prefix + content} |`;
}

/**
 * Create the turndown service which will be used to convert html to markdown.
 *
 * This supports html by default.
 */
const turndownService = new TurndownService({
  codeBlockStyle: 'fenced',
  headingStyle: 'atx',
})
  .addRule('taskListItems', {
    filter: (node) => {
      return (node as HTMLInputElement).type === 'checkbox' && node.parentNode?.nodeName === 'LI';
    },
    replacement: (_, node) => {
      return `${(node as HTMLInputElement).checked ? '[x]' : '[ ]'} `;
    },
  })
  .addRule('tableCell', {
    filter: ['th', 'td'],
    replacement: (content, node) => {
      return cell(content, node as ChildNode);
    },
  })
  .addRule('tableRow', {
    filter: 'tr',
    replacement: (content, node) => {
      let borderCells = '';
      const alignMap = { left: ':--', right: '--:', center: ':-:' };

      if (isHeadingRow(node)) {
        for (const childNode of node.childNodes) {
          if (!isElementDomNode(childNode)) {
            // This should never happen.
            continue;
          }

          let border = '---';
          const align = (childNode.getAttribute('align') ?? '').toLowerCase() as keyof typeof alignMap;

          if (align) {
            border = alignMap[align] || border;
          }

          borderCells += cell(border, childNode);
        }
      }

      return `\n${content}${borderCells ? `\n${borderCells}` : ''}`;
    },
  })
  .addRule('table', {
    // Only convert tables with a heading row. Tables with no heading row are kept
    // using `keep` (see below).
    filter: (node) => {
      return node.nodeName === 'TABLE' && isHeadingRow((node as HTMLTableElement).rows[0] as Node);
    },

    replacement: (content) => {
      // Ensure there are no blank lines
      return `\n\n${content.replace('\n\n', '\n')}\n\n`;
    },
  })
  .addRule('tableSection', {
    filter: ['thead', 'tbody', 'tfoot'],
    replacement(content) {
      return content;
    },
  })
  .keep((node) => {
    return node.nodeName === 'TABLE' && !isHeadingRow((node as HTMLTableElement).rows[0] as Node);
  })
  .addRule('strikethrough', {
    filter: ['del', 's', 'strike' as 'del'],
    replacement(content) {
      return `~${content}~`;
    },
  })

  // Add improved code block support from html.
  .addRule('fencedCodeBlock', {
    filter: (node, options) => {
      return !!(
        options.codeBlockStyle === 'fenced' &&
        node.nodeName === 'PRE' &&
        node.firstChild &&
        node.firstChild.nodeName === 'CODE'
      );
    },

    replacement: (_, node, options) => {
      invariant(isElementDomNode(node.firstChild), {
        code: ErrorConstant.EXTENSION,
        message: `Invalid node \`${node.firstChild?.nodeName}\` encountered for codeblock when converting html to markdown.`,
      });

      const className = node.firstChild.getAttribute('class') ?? '';
      const language =
        className.match(/(?:lang|language)-(\S+)/)?.[1] ??
        node.firstChild.getAttribute('data-code-block-language') ??
        '';

      return `\n\n${options.fence}${language}\n${node.firstChild.textContent}\n${options.fence}\n\n`;
    },
  })
  .addRule('taraMentions', {
    filter: (node) =>
      !!(
        node.nodeName === 'SPAN' &&
        node.classList.contains('remirror-mention-atom-at') &&
        (node as HTMLInputElement).dataset.mentionAtomName &&
        (node as HTMLInputElement).dataset.mentionAtomName === 'at'
      ),
    replacement: (content, node: TurndownService.Node) =>
      `@tara-user-${(node as HTMLInputElement).dataset.mentionAtomId}`,
  })
  .addRule('taraLabels', {
    filter: (node) =>
      !!(
        node.nodeName === 'SPAN' &&
        node.classList.contains('remirror-mention-atom-hash') &&
        (node as HTMLInputElement).dataset.mentionAtomName &&
        (node as HTMLInputElement).dataset.mentionAtomName === 'hash'
      ),
    replacement: (content, node: TurndownService.Node) =>
      `[#tara-label-${(node as HTMLInputElement).dataset.mentionAtomId}]`,
  })
  .addRule('image', {
    filter: ['img'],
    replacement: (content, node: HTMLElement | Document | DocumentFragment | HTMLImageElement) => {
      return `\n![image](${(node as HTMLImageElement).src})`;
    },
  });
