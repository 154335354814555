/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Find the property name of a value in object passing the value and the object itself
 * E.g: I want to know the path of 'test' in {title: 'test', user: {id: '208424', name: 'John'}}
 * The result will be: title
 */

export function resolvePath(path: string, obj: Record<string, any>): string {
  return path.split('.').reduce((prev: any, curr: any) => {
    return prev ? prev[curr] : null;
    // eslint-disable-next-line no-restricted-globals
  }, obj || self);
}
