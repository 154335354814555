import { createAsyncThunk } from '@reduxjs/toolkit';
import { Data, UI } from '@taraai/types';
import { RootState } from 'reduxStore/store';
import { CREATION_DEFAULTS, decode } from 'reduxStore/utils/decoders';
import { ExtraAPI } from 'reduxStore/utils/types';

type Props = Required<Pick<UI.UIRequirementPartial, 'assignedTeamIds' | 'title'>> &
  Pick<UI.UIRequirementPartial, 'description' | 'attachments'>;

export const createRequirement = createAsyncThunk(
  'CreateRequirement',
  async ({ assignedTeamIds, title, description, attachments }: Props, { extra, getState }) => {
    const { getOrgId, getUserId, getFirestore } = extra as ExtraAPI;
    const state = getState() as RootState;
    const userId = getUserId(state);
    const orgId = getOrgId();

    const firestore = getFirestore();
    const requirement = decode<Data.Requirement>(
      {
        author: userId,
        updatedAt: firestore.Timestamp.now(),
        createdAt: firestore.Timestamp.now(),
        title,
        description: description || '',
        attachments: attachments || [],
        assignedTeamIds,
      },
      'Requirement',
      CREATION_DEFAULTS,
    );

    return firestore
      .add<Data.Requirement>(`orgs/${orgId}/requirements/`, requirement)
      .then(({ id }) => ({ ...requirement, id } as UI.UIRequirement));
  },
);
