import { Box, Fluid, HStack, Popup, styled, Text, VStack } from '@taraai/design-system';
import { Data, UI } from '@taraai/types';
import WorkspaceItem from 'components/app/GlobalNav/WorkspaceSwitcher/WorkspaceItem';
import { linkTo } from 'components/Router/paths';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { strings } from 'resources';
import { workspaceTestIds } from 'resources/cypress/testAttributesValues';

type Props = {
  selectedOrgId: Data.Id.OrganizationId;
  selectedTeamId: Data.Id.TeamId;
  orgs: UI.UIOrganization[];
};

export function WorkspaceSection({ selectedOrgId, selectedTeamId, orgs }: Props): JSX.Element {
  const [showList, setShowList] = useState(false);

  const workspaceItems = (
    <ListContainer>
      <WorkspaceList background='$white'>
        <WorkspaceListHeadline size='$14px'>{strings.workspaceSwitcher.switchWorkspace}</WorkspaceListHeadline>
        {orgs.map((org) => (
          <WorkspaceItem key={org.id} org={org} selectedOrgId={selectedOrgId} />
        ))}
      </WorkspaceList>
    </ListContainer>
  );

  return (
    <Box space='$16px'>
      <VStack space='$12px'>
        <Popup content={workspaceItems} onHide={() => setShowList(false)} placement='right-start' show={showList}>
          <Box.Button onClick={() => setShowList((prev) => !prev)} width='$full'>
            <HStack alignY='center'>
              <Text color='$dark' size='$14px'>
                {strings.workspacePulldown.switchWorkspaces}
              </Text>
              <Fluid />
              <RightCaret />
            </HStack>
          </Box.Button>
        </Popup>
        <Text color='$dark' size='$14px' textAlign='left'>
          <StyledLink
            data-cy={workspaceTestIds.OPEN_SETTINGS}
            to={linkTo('workspace', { orgId: selectedOrgId, teamId: selectedTeamId })}
          >
            {strings.workspaceSwitcher.workspaceSettings}
          </StyledLink>
        </Text>
      </VStack>
    </Box>
  );
}

const ListContainer = styled(Box, {
  position: 'absolute',
  boxShadow: '$popup',
  border: 'borderWidths.$1px solid colors.$grey5',
  borderRadius: '0.1875rem',
  width: '17.875rem',
  backgroundColor: '$white',
  cursor: 'default',
  pointerEvents: 'auto',
  outline: 'none',
});

const WorkspaceList = styled(Box, {
  maxHeight: '18rem',
  overflowY: 'auto',
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
});

const WorkspaceListHeadline = styled(Text, {
  display: 'flex',
  fontSize: '0.75rem',
  fontWeight: '$regular',
  color: '$grey6',
  padding: '$8px $16px',
});

const StyledLink = styled(Link, {
  width: 'fit-content',
  cursor: 'pointer',
});

const RightCaret = (): JSX.Element => (
  <svg fill='none' height='10' viewBox='0 0 6 10' width='6' xmlns='http://www.w3.org/2000/svg'>
    <path d='M1 9L5 5L1 1' stroke='#949CAF' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.33333' />
  </svg>
);
