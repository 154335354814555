import { Data } from '@taraai/types';
import { createStandardSelector, inertQuery, Query } from 'reduxStore/utils/selectors';
import { ReduxFirestoreQuery } from 'reduxStore/utils/types';

export type WorkspaceCustomClaim = {
  id: string;
  [key: string]: unknown;
};

export function getWorkspaceCustomClaimsQuery(orgId: Data.Id.OrganizationId): Query<WorkspaceCustomClaim> {
  if (!orgId) {
    return inertQuery();
  }

  const query: ReduxFirestoreQuery = {
    collection: `orgs/${orgId}/custom-claims`,
    storeAs: `orgs/${orgId}/custom-claims`,
  };

  return {
    query: [query],
    selector: createStandardSelector<WorkspaceCustomClaim>(query),
  };
}
