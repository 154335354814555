import { unwrapResult } from '@reduxjs/toolkit';
import { styled, VStack } from '@taraai/design-system';
import { TaskStatus, UI } from '@taraai/types';
import { isNonEmptyString } from '@taraai/utility';
import EffortStatus from 'components/app/controllers/EffortStatus';
import { LabelList } from 'components/app/controllers/views/LabelList';
import { SmartText } from 'components/app/controllers/views/SmartText';
import AvatarPicker from 'components/core/controllers/views/AvatarPicker';
import { Card } from 'components/core/controllers/views/Card';
import Text from 'components/core/controllers/views/Text';
import { useTaskTitleConfig } from 'components/editor/config';
import React, { ComponentProps, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectActiveUsers, selectActiveWorkspace, useAppDispatch } from 'reduxStore';
import { updateTask } from 'reduxStore/tasks/actions/update';
import { decodeChain } from 'reduxStore/utils/decoders';
import { strings } from 'resources/i18n';
import { useToast } from 'tools';

type TaskFragment = Pick<
  UI.UITask,
  'id' | 'title' | 'description' | 'assignee' | 'effortLevel' | 'status' | 'labels' | '_relationships'
>;
type Props = {
  background?: ComponentProps<typeof Card>['background'];
  filterLabelsOnClick?: boolean;
  onClick: () => void;
  task: TaskFragment;
  title?: string;
  withBorder?: boolean;
};

/**
 * TASK Small card shown in the Progress page
 */
export default function SummaryTaskCard({
  background,
  filterLabelsOnClick,
  onClick,
  task,
  title,
  withBorder,
}: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const orgId = useSelector(selectActiveWorkspace);
  const { whenError } = useToast();

  // show only active users plus assignee, or all users if there's no access level info available
  const teamUsers = useSelector(selectActiveUsers(orgId));

  const assignee =
    teamUsers && isNonEmptyString(task.assignee) ? teamUsers.find((user) => user.id === task.assignee) : undefined;

  const addAssignee = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (assignee) => {
      decodeChain<UI.UITaskChangeset>({ id: task.id, assignee }, 'UITaskChangeset')
        .then((changeSet) => dispatch(updateTask(changeSet)))
        .then(unwrapResult)
        .catch(whenError(strings.task.failedToUpdateTask));
    },
    [dispatch, task, whenError],
  );

  const removeAssignee = useCallback(() => {
    decodeChain<UI.UITaskChangeset>({ id: task.id, assignee: null }, 'UITaskChangeset')
      .then((data) => dispatch(updateTask(data)))
      .then(unwrapResult)
      .catch(whenError(strings.task.failedToUpdateTask));
  }, [dispatch, task, whenError]);

  return (
    <Card background={background} withBorder={withBorder}>
      <Container onClick={onClick}>
        <EffortButton>
          <EffortStatus effortLevel={task.effortLevel} status={task.status} />
        </EffortButton>
        <StyledVStack space='$1px'>
          <TaskId h5>{strings.formatString(strings.task.taskId, { taskId: task.id })}</TaskId>
          <TaskTitleText className={title} done={task.status === TaskStatus.Done} h5>
            <SmartText config={useTaskTitleConfig()} filterLabelsOnClick={filterLabelsOnClick} text={task.title} />
          </TaskTitleText>
        </StyledVStack>
        <AssigneeSection>
          <StyledAvatarPicker
            maxAllowed={1}
            onAddUser={addAssignee}
            onRemoveUser={removeAssignee}
            size='medium'
            suggestions={teamUsers}
            users={assignee && [assignee]}
          />
        </AssigneeSection>
        <LabelList description={task.description} title={task.title} />
      </Container>
    </Card>
  );
}

const StyledVStack = styled(VStack, { padding: '0px 5px' });

const Container = styled('div', {
  display: 'grid',
  gridTemplateColumns: '2.75rem 1fr auto',
  padding: '8px 0px',
  alignItems: 'flex-start',
});

const EffortButton = styled('button', {
  outline: 0,
  display: 'flex',
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  color: '$dark',
  margin: '0 0.75rem 0 0.75rem',
});

const TaskId = styled(Text, {
  color: '$grey6',
  padding: '$none $8px $none $none',
});

const TaskTitleText = styled(
  Text,
  {
    wordBreak: 'break-word',
  },
  { done: { true: { textDecoration: 'line-through', color: '$grey7' } } },
);

const AssigneeSection = styled(
  'div',
  {
    position: 'relative',
    display: 'flex',
    alignContent: 'center',
    fontSize: '0.875rem',
    color: '#303f4b',
    marginRight: '0.5rem',
    justifyContent: 'space-between',
  },
  {
    sprintView: {
      true: { justifyContent: 'center' },
      false: { justifyContent: 'flex-end' },
    },
  },
);

const StyledAvatarPicker = styled(AvatarPicker, {
  alignSelf: 'center',
});
