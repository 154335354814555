import Text from 'components/core/controllers/views/Text';
import { css } from 'emotion';
import React from 'react';
import { ExpiredInvitation as ExpiredInvitationIcon } from 'resources/assets';
import { strings } from 'resources/i18n';

export default function ExpiredInvitation(): JSX.Element {
  return (
    <div
      className={css`
        margin: auto;
        padding-top: 7rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;
        overflow: auto;
        @media (max-width: 500px) {
          width: 90%;
          padding-top: 18%;
        }
      `}
    >
      <img
        alt={strings.logo.tara}
        className={css`
          margin: 1rem auto;
          height: 7.5rem;
        `}
        src={ExpiredInvitationIcon}
      />
      <Text
        className={css`
          font-size: 1.5rem;
          padding: 1rem;
          font-weight: 600;
          color: #4550ce;
          text-align: center;
        `}
      >
        {strings.joinWorkspace.expiredInvitation.message}
      </Text>
      <Text
        className={css`
          font-size: 1.125rem;
          padding: 0.25rem;
          color: #67728b;
          text-align: center;
        `}
      >
        {strings.joinWorkspace.expiredInvitation.contactInfo}
      </Text>
    </div>
  );
}
