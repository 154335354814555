import InstallIntegrationForm from 'components/app/controllers/views/InstallIntegrationForm';
import Button from 'components/core/controllers/views/Button';
import Icon from 'components/core/controllers/views/Icon';
import { FastSmallSpinner } from 'components/core/controllers/views/Spinners';
import { css } from 'emotion';
import React, { useState } from 'react';
import { atomic } from 'resources/theme';
import { ConnectGitHubAccountData } from 'types/connect-account';
import { InstallGitHubData } from 'types/install-integration';

/**
 * ConnectButtonState
 * enabled - user is able to perform click action
 * disabled - action is blocked
 * completed - action was already performed
 */
export type ConnectButtonState = 'enabled' | 'disabled' | 'completed';

export type ConnectButtonProps = React.HTMLProps<HTMLDivElement> & {
  installData: InstallGitHubData | ConnectGitHubAccountData;
  labels: { [key in ConnectButtonState]: string };
  state: ConnectButtonState;
  method: 'get' | 'post';
};

export default function ConnectButton({
  className,
  installData,
  labels,
  state,
  method,
}: ConnectButtonProps): JSX.Element {
  const [isLoading, setLoading] = useState(false);
  const disableForm = state !== 'enabled';
  const onClick = (): void => {
    setLoading(true);
  };
  return (
    <InstallIntegrationForm
      className={className}
      disabled={disableForm}
      installationData={installData}
      isLoading={isLoading}
      method={method}
      onClick={onClick}
    >
      <Button
        className={css`
          padding: 0.4rem 0.75rem;
          font-size: 0.875rem;
          font-weight: 500;
          outline: none;
        `}
        color={disableForm ? 'grey' : 'green'}
        disabled={disableForm}
        id='installIntegrationFormChild'
        name='githubConnectButton'
        size='compact'
      >
        {isLoading ? (
          <FastSmallSpinner
            color={atomic.get(atomic.colors.white)}
            spinnerStyles={css`
              padding: 0;
              vertical-align: text-bottom;
              margin-right: 0.3125rem;
            `}
          />
        ) : (
          state === 'completed' && (
            <Icon
              className={css`
                width: 14px;
                height: 14px;
                margin-right: 5px;
                vertical-align: text-bottom;
                padding: 0;
              `}
              name='tick'
            />
          )
        )}
        {labels[state]}
      </Button>
    </InstallIntegrationForm>
  );
}
