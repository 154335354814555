import styled from '@emotion/styled';
import { Data, UI } from '@taraai/types';
import { PreferredTeamButton } from 'components/app/controllers/views/PreferredTeamButton';
import Table from 'components/core/controllers/Table';
import Logo from 'components/core/controllers/views/Logo';
import { linkTo } from 'components/Router/paths';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { selectActiveWorkspace } from 'reduxStore';
import { userProfileTestIds } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';

export type UserTeamsProps = {
  teams: UI.UITeam[];
  preferredTeamId: Data.Id.TeamId;
  onSetPreferredTeamId: (teamId: Data.Id.TeamId) => Promise<void>;
};

export function UserTeams({ teams, preferredTeamId, onSetPreferredTeamId }: UserTeamsProps): JSX.Element {
  const orgId = useSelector(selectActiveWorkspace);
  const history = useHistory();

  const tableData = teams.map((team: UI.UITeam) => ({
    id: team.id,
    name: (
      <NameRow>
        <Logo name={team.name} shape='circle' size='medium' />
        <NameLabel>{team.name}</NameLabel>
      </NameRow>
    ),
    preferred: (
      <PreferredTeamButton
        data-cy={userProfileTestIds.SET_PREFERRED_TEAM_BUTTON}
        isPreferred={team.id === preferredTeamId}
        onClick={(event: React.SyntheticEvent): Promise<void> => {
          event.stopPropagation();
          return onSetPreferredTeamId(team.id);
        }}
      />
    ),
  }));

  return (
    <>
      <Title>{strings.profile.teams.title}</Title>
      <Description>{strings.profile.teams.description}</Description>
      <TableContainer>
        <Table
          data={tableData}
          headers={{
            name: { name: strings.profile.teams.nameColumnLabel, width: '70%' },
            preferred: { name: '', width: '30%' },
          }}
          tableRowOnClick={(row) => (): void => {
            history.push(linkTo('teamDetails', { orgId, teamId: row.id }));
          }}
        />
      </TableContainer>
    </>
  );
}

const Title = styled.div`
  color: #303f4b;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

const Description = styled.div`
  font-size: 0.875rem;
  font-weight: normal;
  color: #67728b;
  margin-bottom: 1rem;
`;

const TableContainer = styled.div`
  max-width: 31.25rem;
`;

const NameRow = styled.div`
  font-size: 0.875rem;
  padding: 0.25rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  &:hover {
    background-color: #fbfbfd;
  }
`;

const NameLabel = styled.div`
  color: #303f4b;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1.5rem;
`;
