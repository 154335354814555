import { useCallback, useMemo, useRef, useState } from 'react';

export enum SaveState {
  clean = 'clean', // local unedited from remote
  dirty = 'dirty', // locally edited
  dirtyConflict = 'dirtyConflict', // edited, remote different - https://app.zeplin.io/project/6179e1537eca2c04910456f4/screen/6179e9863f2726ba5dfbb62b
  cleanConflict = 'cleanConflict', // unedited, remote different - https://app.zeplin.io/project/6179e1537eca2c04910456f4/screen/617ae40a983aa9bf1ca32645
  saving = 'saving', // edited queuing for save to remote - https://app.zeplin.io/project/6179e1537eca2c04910456f4/screen/6179e98660ae19ba902e0a44
  saved = 'saved', // edited saved to remote - https://app.zeplin.io/project/6179e1537eca2c04910456f4/screen/6179e9860ea8a903224794fb
  blindSaved = 'blindSaved',
  error = 'error', // edited could NOT be saved - https://app.zeplin.io/project/6179e1537eca2c04910456f4/screen/6179e9863f2726ba5dfbb62b
}
/**
 * "Force save" content reconciliation scheme.
 *
 * Basic premise of this scheme is to do 3 things:
 *
 * 1) Detect that other user is simultaneously editing the same content,
 * 2) Prevent the user from saving (because that would overwrite changes that are on the server),
 * 3) Allow the user to force the save and overwrite the changes on the server.
 *
 * It is accomplished by creating a proxy function `trySave` that wraps original `save` function.
 *
 * It tracks two different versions of the content: _local_ and _remote_.
 *
 * It stores two sync points: _the latest remote version_ and _the last remote version that was the same
 * as local one_.
 *
 * Each time we try to save we compare these two sync points and if they differ we block the save
 * and indicate that by setting `isConflict` to true.
 * We also store parameters to that `trySave` call, so that we can repeat that call if the user decides to
 * force save their changes.
 *
 * @param currentRemoteValue Current value for remote key
 * @param save Save handler
 * @param getKey A getter that selects from arguments to `save` function a key
 * that will be used later in reconciliation process.
 */
export function useForceSave(
  currentRemoteValue: string,
  save: (value: string) => Promise<string | Error | null> | void,
): {
  trySave: (value: string) => Promise<string | Error | null>;
  saveState: SaveState;
  forceSave: (override?: string) => Promise<string | Error | null>;
  getLatestOutOfSyncValue: () => string;
} {
  const [state, setState] = useState<SaveState>(SaveState.clean);

  // used to store last trySave call args, so that we can
  // repeat that call if user decides they want to force save
  const lastSavedValue = useRef<string>();

  // current state of the remote
  const lastRemoteValue = useRef(currentRemoteValue);
  lastRemoteValue.current = currentRemoteValue;

  // last state when local and remote were synced
  const lastSyncedValue = useRef(currentRemoteValue);

  // saves and synchronizes local with remote
  const doSave = useCallback(
    async (value: string): Promise<string | Error | null> => {
      // sync local and remote
      lastSyncedValue.current = value;
      lastRemoteValue.current = value;

      setState(SaveState.saving);
      const result = (await save(value)) as unknown;

      if (typeof result === 'string') {
        setState(SaveState.saved);
        return result;
      }

      if (result instanceof Error) {
        setState(SaveState.error);
        return result;
      }

      if ((result as { messge: string }).messge !== null) {
        setState(SaveState.error);
        return new Error((result as { messge: string }).messge);
      }

      setState(SaveState.blindSaved);
      return null;
    },
    [save],
  );

  return {
    trySave: useCallback(
      (value): Promise<string | Error | null> => {
        setState(SaveState.saving);
        // check if remote is in sync with local
        const remoteInSync = lastSyncedValue.current === lastRemoteValue.current;
        if (!remoteInSync) {
          // defer args for possible force-save
          lastSavedValue.current = value;

          setState(SaveState.dirtyConflict);
          return Promise.resolve(new Error(SaveState.dirtyConflict));
        }

        return doSave(value);
      },
      [doSave],
    ),
    forceSave: useCallback(
      async (override): Promise<string | Error | null> => {
        lastSavedValue.current = override?.length ? override : lastSavedValue.current;

        if (typeof lastSavedValue.current !== 'undefined') {
          setState(SaveState.saving);

          return doSave(lastSavedValue.current as string);
        }

        return Promise.resolve(null);
      },
      [doSave],
    ),
    saveState: useMemo(() => state, [state]),
    getLatestOutOfSyncValue: useCallback(() => lastSavedValue.current || '', [lastSavedValue]),
  };
}
