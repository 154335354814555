import { unwrapResult } from '@reduxjs/toolkit';
import { Data } from '@taraai/types';
import { parseMentionsFromPlainText } from '@taraai/utility';
import AddCommentInputView, { AddCommentInputStatus } from 'components/app/controllers/views/AddCommentInputView';
import { useCommentConfig } from 'components/editor/config';
import { RichEditor, RichEditorHandle } from 'components/editor/RichEditor';
import { RichEditorProvider } from 'components/editor/RichEditorProvider';
import React, { useCallback, useRef, useState } from 'react';
import { createTaskComment, useAppDispatch } from 'reduxStore';
import { strings } from 'resources';
import { useToast } from 'tools';

type AddCommentInputProps = {
  onCommentAdded: () => void;
  taskId: Data.Id.TaskId;
};

export function AddCommentInput({ onCommentAdded, taskId }: AddCommentInputProps): JSX.Element {
  const dispatch = useAppDispatch();
  const { addToast } = useToast();
  const [status, setStatus] = useState<AddCommentInputStatus>('ready');

  const editorRef = useRef<RichEditorHandle>(null);

  const handleSave = useCallback(
    (text: string) => {
      // Check for ZWSP explicitly because the markdown plugin adds it to an empty string for some reason -.-
      const hasNonWhiteSpace = /[^\s\u200b]/.test(text);
      if (status !== 'ready' || !hasNonWhiteSpace) return;

      setStatus('loading');
      // eslint-disable-next-line promise/catch-or-return
      dispatch(createTaskComment({ description: text, taskId, mentionedUserIds: parseMentionsFromPlainText(text) }))
        .then(unwrapResult)
        .then(onCommentAdded)
        .then(() => editorRef.current?.clear())
        .catch(() => addToast({ type: 'error', message: strings.comments.couldntAdd, timeoutMs: 3000 }))
        .finally(() => setStatus('ready'));
    },
    [addToast, dispatch, onCommentAdded, status, taskId],
  );

  return (
    <RichEditorProvider config={useCommentConfig()} initialValue='' onSave={handleSave} saveStrategy='saveOnReturn'>
      <AddCommentInputView
        inputSlot={({ inputRootStyle }) => (
          <RichEditor ref={editorRef} className={inputRootStyle} placeholder={strings.comments.leaveComment} />
        )}
        onSendButtonClick={() => editorRef.current?.save()}
        status={status}
      />
    </RichEditorProvider>
  );
}
