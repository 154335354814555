import { styled } from '@taraai/design-system';
import { WorkloadBreakdownComparison, WorkloadBreakdownNonComparison } from '@taraai/types/src/data';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { EmptyData } from 'components/app/GitInsights/Shared/EmptyData';
import { GraphLegend } from 'components/app/GitInsights/Shared/PrimitiveComponents/GraphLegend';
import { GraphSection } from 'components/app/GitInsights/Shared/PrimitiveComponents/GraphSection';
import { VerticalStacked } from 'components/app/GitInsights/Shared/PrimitiveComponents/VerticalStacked';
import {
  GenerateComparisonLegendScale,
  GetKeysForRenderingInGraph,
  GetStateSelectionType,
} from 'components/app/GitInsights/Shared/utils';
import React from 'react';
import { useSelector } from 'react-redux';
import { getChartData, gitInsightsSelectionState } from 'reduxStore';

// Pull request/Merge Request Health
export function TeamWorkloadBreakdown(): JSX.Element | null {
  const getInsightState = useSelector(gitInsightsSelectionState);

  const { timeSegment } = getInsightState;

  const renderType = GetStateSelectionType();

  const rawData = useSelector(getChartData)?.WorkloadBreakdown;

  const comparisonData = rawData as WorkloadBreakdownComparison;

  const singleData = rawData as WorkloadBreakdownNonComparison;

  const getBottomInfo = (info: Record<string, string | number>): string => String(info.label);

  return (
    <GraphSection
      colorsLegend={
        <GraphLegend
          colorShapeFormat='circle'
          scale={GenerateComparisonLegendScale(['feature', 'bugs', 'incomplete'])}
        />
      }
      comparisonPeriodLegend={timeSegment}
      headerText='Team Workload Breakdown'
    >
      {!!rawData && rawData.length > 0 ? (
        <GraphsBox>
          {renderType === 'multiUser' &&
            comparisonData?.map((graph) => {
              if (!graph.data) return;

              return (
                <ParentSize key={graph.label}>
                  {({ width }) => (
                    <VerticalStacked
                      bottomTitle={graph.label as string}
                      data={graph.data}
                      dataKeys={GetKeysForRenderingInGraph(graph.data[0], 'label')}
                      getAxisBottomInfo={getBottomInfo}
                      height={300}
                      showAxisLeftInfo={false}
                      showGridLines={false}
                      showValues
                      width={width}
                    />
                  )}
                </ParentSize>
              );
            })}

          {renderType === 'singleUser' &&
            (!singleData ? null : (
              <ParentSize>
                {({ width }) => (
                  <VerticalStacked
                    data={singleData}
                    dataKeys={GetKeysForRenderingInGraph(singleData[0], 'label')}
                    getAxisBottomInfo={getBottomInfo}
                    height={300}
                    showAxisLeftInfo
                    showGridLines
                    showValues
                    useBackgroundPatterns
                    width={width}
                  />
                )}
              </ParentSize>
            ))}
        </GraphsBox>
      ) : (
        <EmptyData dataToWatch={rawData} />
      )}
    </GraphSection>
  );
}

const GraphsBox = styled('div', {
  display: 'flex',
  justifyContent: 'center',
});
