/* eslint-disable id-length */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { faker } from '@faker-js/faker';

// generate x number of fake people
export function generateFakeMembers(num: number): { id: string; name: string }[] {
  return Array.from({ length: num }, (_, _i) => ({
    id: faker.datatype.uuid(),
    name: faker.name.fullName(),
  }));
}

// generate x number of teams
export function generateFakeTeams(
  num: number,
): { id: string; name: string; members: { id: string; name: string }[] }[] {
  return Array.from({ length: num }, (_, _i) => ({
    id: faker.datatype.uuid(),
    // name uppercase first letter
    name: `Team ${faker.color.human().charAt(0).toUpperCase()}${faker.color.human().slice(1)}`,
    members: generateFakeMembers(10),
  }));
}

export const placeholderHook = {
  defaultValue: 'All teams & members',
  allTeams: generateFakeTeams(3),
};
