import { Avatar, Box, Pill } from '@taraai/design-system';
import { Data, UI } from '@taraai/types';
import { UserSelector } from 'components/app/controllers/Selectors/UserSelector';
import { Section } from 'components/app/TaskDetails/common/Section';
import { SectionType } from 'components/core/controllers/Selector';
import { SEGMENT_ASSIGNEE_LOCATION } from 'components/core/controllers/Selector/types';
import React from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { selectTaskValue, selectUserDocument, updateTask, useAppDispatch } from 'reduxStore';
import { strings } from 'resources';

type UserFragment = Pick<UI.UIUser, 'id' | 'name'>;

type Props = {
  taskId: UI.UITask['id'];
  users: UserFragment[];
  orgId: Data.Id.OrganizationId;
  location?: SEGMENT_ASSIGNEE_LOCATION;
};

export function Assignee({ taskId, users, orgId, location }: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const assignee = useSelector((state) => selectTaskValue(state, taskId, 'assignee') ?? '', deepEquals);

  const {
    name,
    avatarURL,
    id: assigneeId,
  } = useSelector(
    (state) => selectUserDocument(state, assignee, ['name', 'avatarURL', 'id']) ?? ({} as UI.UIUser),
    deepEquals,
  );

  const sections: SectionType<UserFragment>[] = [{ id: 'users', options: users }];
  const selection = assigneeId && name ? [{ id: assigneeId, name, avatarURL }] : [];

  const setTaskAssignee = async ({ id }: UserFragment): Promise<void> => {
    await dispatch(updateTask({ id: taskId, assignee: id }));
  };

  const removeTaskAssignee = async (): Promise<void> => {
    await dispatch(updateTask({ id: taskId, assignee: null }));
  };

  return (
    <Section
      right={
        <UserSelector
          closePopupOnSelection
          headerTitle={strings.taskSidebar.modules.assignee.dropdown}
          location={location}
          onDeselectOption={removeTaskAssignee}
          onSelectOption={setTaskAssignee}
          optionSize='small'
          orgId={orgId}
          renderSelectButton={({ openPopup }) =>
            name && assigneeId ? (
              <Pill maxWidth='$full' name={name} onClick={openPopup} url={avatarURL} />
            ) : (
              <Box.Button onClick={openPopup}>
                <Avatar />
              </Box.Button>
            )
          }
          searchPlaceholder={strings.taskSidebar.modules.header.searchUsers}
          sections={sections}
          selection={selection}
          selectorPosition='left'
          taskId={taskId}
        />
      }
      title={strings.taskSidebar.modules.assignee.title}
    />
  );
}
