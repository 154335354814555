import { Box, styled, Text } from '@taraai/design-system';
import { Data } from '@taraai/types';
import { linkTo } from 'components/Router/paths';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { strings } from 'resources/i18n';

export type EmptyDrawerProps = { orgId: Data.Id.OrganizationId; teamId: Data.Id.TeamId };

export function EmptyDrawerTasks(): JSX.Element {
  return <EmptyStateWrapper>{strings.drawer.emptyStates.tasks}</EmptyStateWrapper>;
}

export function EmptyDrawerRequirements(): JSX.Element {
  return <EmptyStateWrapper>{strings.formatString(strings.drawer.emptyStates.requirements)}</EmptyStateWrapper>;
}

export function EmptyDrawerRepos({ orgId, teamId }: EmptyDrawerProps): JSX.Element {
  return (
    <EmptyStateWrapper>
      {strings.formatString(
        strings.drawer.emptyStates.repos,
        <InlineLink to={linkTo('integrations', { orgId, teamId })}>Github</InlineLink>,
        <InlineLink to={linkTo('integrations', { orgId, teamId })}>Gitlab</InlineLink>,
      )}
    </EmptyStateWrapper>
  );
}

export function EmptyDrawerImports({ orgId, teamId }: EmptyDrawerProps): JSX.Element {
  return (
    <EmptyStateWrapper>
      {strings.formatString(
        strings.drawer.emptyStates.imports,
        <InlineLink to={linkTo('integrations', { orgId, teamId })}>Trello</InlineLink>,
        <InlineLink to={linkTo('integrations', { orgId, teamId })}>Asana</InlineLink>,
      )}
    </EmptyStateWrapper>
  );
}

function EmptyStateWrapper({ children }: { children: ReactNode }): JSX.Element {
  return (
    <StyledBox spaceVert='$4px'>
      <Text size='$12px'>{children}</Text>
    </StyledBox>
  );
}

function InlineLink({ children, to }: { children: ReactNode; to: string }): JSX.Element {
  return (
    <Link to={to}>
      <Text color='$sapphire' size='$12px' weight='medium'>
        {children}
      </Text>
    </Link>
  );
}
const StyledBox = styled(Box, { paddingLeft: '40px' });
