import { compose, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Data } from '@taraai/types';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useIsSearchActive } from 'reduxStore/search';
import { RootStateWithProfile } from 'reduxStore/store';

type Unfolded = Record<string, Record<string, boolean>>;

type State = {
  rendered: boolean;
  scroll?: number;
  scrollBlock?: ScrollBlock;
  settings: {
    pages: {
      sprints: Settings;
      define: Settings;
    };
  };
  currentSettings: WorkdrawerPageMode;
  unfolded: Unfolded;
};

export enum WorkdrawerPageMode {
  sprints = 'sprints',
  define = 'define',
}

export type SettingType = 'requirementsSection:show-archived' | 'tasks:show-assigned-to-sprint' | 'tasks:show-done';

type Settings = Record<SettingType, boolean>;

export type ScrollBlock = 'nearest' | 'center';

type WorkDrawerType = 'tasks' | 'requirements' | 'requirementsSection' | 'reposSection' | 'repos' | 'imports';

const defaultId = 'all';

const initialState: State = {
  rendered: false,
  scrollBlock: undefined,
  settings: {
    pages: {
      sprints: {
        'requirementsSection:show-archived': false,
        'tasks:show-done': false,
        'tasks:show-assigned-to-sprint': false,
      },
      define: {
        'requirementsSection:show-archived': false,
        'tasks:show-done': false,
        'tasks:show-assigned-to-sprint': false,
      },
    },
  },
  unfolded: { tasks: { all: true }, requirementsSection: { all: true } },
  currentSettings: WorkdrawerPageMode.sprints,
};

const workDrawerSlice = createSlice({
  name: 'workDrawer',
  initialState,
  reducers: {
    markRendered: (state) => {
      state.rendered = true;
    },
    toggle: (
      state,
      { payload: { type, id, value } }: PayloadAction<{ type: WorkDrawerType; id: string; value: boolean }>,
    ) => {
      state.unfolded[type] = { [id]: value };
    },
    multiToggle: (state, { payload }: PayloadAction<Unfolded>) => {
      state.unfolded = payload;
    },
    scrollBlock: (state) => {
      state.scrollBlock = state.rendered ? 'nearest' : 'center';
    },
    scroll: (state, { payload: { scroll } }: PayloadAction<{ scroll?: number }>) => {
      state.scroll = scroll;
    },
    setSetting: (state, { payload: { setting, value } }: PayloadAction<{ setting: SettingType; value: boolean }>) => {
      state.settings.pages[state.currentSettings][setting] = value;
    },
    setCurrentSettingsMode: (state, { payload: { mode } }: PayloadAction<{ mode: WorkdrawerPageMode }>) => {
      state.currentSettings = mode;
    },
  },
});

const workDrawerActions = workDrawerSlice.actions;
export const workDrawerReducer = workDrawerSlice.reducer;

export function useWorkDrawerMarkRendered(): () => void {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(workDrawerActions.markRendered());
  }, [dispatch]);
}

export function useWorkDrawerUnfoldedState(
  type: WorkDrawerType,
  id = defaultId,
): [boolean | undefined, (value: boolean) => void] {
  return [useWorkDrawerUnfoldedValue(type, id), useWorkDrawerUnfoldedSetState(type, id)];
}

export function useWorkDrawerUnfoldedValue(type: WorkDrawerType, id = defaultId): boolean | undefined {
  const select = useMemo(() => compose((state) => state.unfolded?.[type]?.[id], selectWorkDrawerState), [id, type]);
  return useSelector(select);
}

export function useWorkDrawerSettingsSetState(
  setting?: SettingType | undefined,
  mode?: WorkdrawerPageMode,
): (value?: boolean) => void {
  const dispatch = useDispatch();
  return useCallback(
    (value) => {
      mode && dispatch(workDrawerActions.setCurrentSettingsMode({ mode }));
      setting && value && dispatch(workDrawerActions.setSetting({ setting, value }));
    },
    [dispatch, setting, mode],
  );
}

export function useWorkDrawerSettingsToggleState(setting: SettingType): () => void {
  const select = useMemo(
    () => compose((state) => state.settings.pages[state.currentSettings][setting], selectWorkDrawerState),
    [setting],
  );
  const currentValue = useSelector(select);
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(workDrawerActions.setSetting({ setting, value: !currentValue }));
  }, [currentValue, dispatch, setting]);
}

export function useWorkDrawerSettingsState(setting: SettingType, options?: { checkSearchIsActive?: boolean }): boolean {
  const isSearchActive = useIsSearchActive();
  const select = useMemo(
    () => compose((state) => state.settings.pages[state.currentSettings][setting], selectWorkDrawerState),
    [setting],
  );
  const selection = useSelector(select);
  if (options?.checkSearchIsActive && isSearchActive) return true;
  return selection;
}

export function useWorkDrawerMultipleUnfoldedSetState(): (ids: Data.Id.RequirementId[]) => void {
  const dispatch = useDispatch();
  return useCallback(
    (ids: Data.Id.RequirementId[]) => {
      const unfoldedRequirementIds = ids.reduce((obj, id) => Object.assign(obj, { [id]: true }), {});
      dispatch(workDrawerActions.multiToggle({ ...initialState.unfolded, requirements: unfoldedRequirementIds }));
    },
    [dispatch],
  );
}

export function useWorkDrawerResetFoldedState(): () => void {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(workDrawerActions.multiToggle(initialState.unfolded));
  }, [dispatch]);
}

export function useWorkDrawerUnfoldedSetState(type: WorkDrawerType, id = defaultId): (value: boolean) => void {
  const dispatch = useDispatch();
  return useCallback(
    (value) => {
      dispatch(workDrawerActions.toggle({ type, id, value }));
    },
    [dispatch, id, type],
  );
}

export function useWorkDrawerScrollBlock(): 'nearest' | 'center' {
  const select = useMemo(() => compose((state) => state.scrollBlock ?? 'nearest', selectWorkDrawerState), []);
  return useSelector(select);
}

export function useWorkDrawerScrollState(): {
  getScroll: () => number | undefined;
  setScroll: (scroll?: number) => void;
} {
  const store = useStore();
  return useMemo(
    () => ({
      /**
       * In order to get the current value from the store we have to avoid useSelector, as it can
       * return an old value since the new one needs to go through the whole React cycle to propagate.
       */
      getScroll: compose((state) => state.scroll, selectWorkDrawerState, store.getState),
      setScroll: (scroll) => {
        store.dispatch(workDrawerActions.scroll({ scroll }));
      },
    }),
    [store],
  );
}

function selectWorkDrawerState(state: RootStateWithProfile): State {
  return state.workDrawer;
}
