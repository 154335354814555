"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ts_data_json_1 = require("ts.data.json");
exports.default = new ts_data_json_1.JsonDecoder.Decoder(function (value) {
    if (typeof value !== 'string') {
        return (0, ts_data_json_1.err)(errorMessage(value));
    }
    var arr = value.trim().split(/\s+/g);
    return (0, ts_data_json_1.ok)(arr);
});
function errorMessage(value) {
    return "Failed to decode ".concat(value, " as an array");
}
