/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable sonarjs/cognitive-complexity */
import { UI } from '@taraai/types';
import DropdownController from 'components/core/controllers/DropdownController';
import Avatar, { avatarSizes, TAvatarSize } from 'components/core/controllers/views/Avatar';
import Icon from 'components/core/controllers/views/Icon';
import { css, cx } from 'emotion';
import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import deepEquals from 'react-fast-compare';
import { strings } from 'resources/i18n';

interface AvatarPickerProps {
  className?: string;

  /**
   * The maximum number of Avatars to show, any additional will
   * wrap up into a `+n` string with a tooltip on hover showing the
   * names of those people.
   *
   * Defaults to 1.
   */
  maxShown?: number;

  /**
   * Maximum allowed users to add. Defaults to 1.
   */
  maxAllowed?: number;

  /**
   * Array of users who's avatars we want to show.
   */
  users?: UI.UIUser[];

  /**
   * Array of all possible users.
   *
   * TODO: this should be some sort of sparse array function like `getUsers(match:string, max:number)`
   */
  suggestions?: UI.UIUser[];

  /**
   * The size to display this view at, defaults to TAvatarSizes<`medium`>.
   */
  size?: TAvatarSize;

  onAddUser?: (userId: string) => void;

  /**
   * Will be called when a user is removed from the
   * picker display.
   */
  onRemoveUser?: (userId: string) => void;

  avatarStyle?: string;

  pickerStyle?: string;

  Ref?: React.RefObject<HTMLInputElement>;

  isCollaborators?: boolean;
  dataCy?: string;
}

/**
 * AvatarPicker allows the user to assign a person.
 *
 */
export default function AvatarPicker({
  className = css``,
  maxAllowed = Number.MAX_SAFE_INTEGER,
  maxShown = 1,
  onAddUser,
  onRemoveUser,
  size = 'medium',
  suggestions = [],
  users = [],
  avatarStyle,
  pickerStyle,
  isCollaborators,
  dataCy,
  Ref,
}: AvatarPickerProps = {}): JSX.Element {
  const [currentUsers, setCurrentUsers] = useState(users);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setCurrentUsers((current) => (deepEquals(users, current) ? current : users));
  }, [users]);

  // convert specified size to CSS Length for styling.
  const calculatedSize = avatarSizes[size as TAvatarSize] ?? size;

  const toggleShow = useCallback((event: SyntheticEvent) => {
    event.stopPropagation();
    setShow((show) => !show);
  }, []);

  /**
   * Add the user associated with this id to the view.
   * Calls `onAddUser()` handler for the parent component.
   */
  function addAvatar(userId: string): void {
    if (!isCollaborators) setShow(!show);
    if (typeof onAddUser === 'function') onAddUser(userId); // callback
    const addedUser = suggestions.find((user): boolean => user.id === userId);
    setCurrentUsers((current): UI.UIUser[] => {
      const updatedUsers = current;
      if (updatedUsers.length === maxAllowed) {
        updatedUsers[updatedUsers.length - 1] = addedUser as UI.UIUser;
        return updatedUsers;
      }
      return updatedUsers.concat(addedUser as UI.UIUser);
    });
  }

  /**
   * Remove the user associated with this id from the view.
   * Calls `onRemoveUser()` handler for the parent component.
   */
  function removeAvatar(userId: string): void {
    if (!isCollaborators) setShow(!show);
    if (typeof onRemoveUser === 'function') onRemoveUser(userId); // callback
    setCurrentUsers((current) => current.filter((user): boolean => user.id !== userId));
  }

  let picker = (
    <Icon
      className={cx(
        css`
          padding: 0rem;
          height: ${calculatedSize};
          width: ${calculatedSize};
        `,
        pickerStyle,
      )}
      color='#eaeef5'
      data-cy={dataCy}
      name='circleplus'
    />
  );
  if (currentUsers.length > 0) {
    if (maxShown === 1) {
      picker = <Avatar className={avatarStyle} data-cy={dataCy} size={calculatedSize} user={currentUsers[0]} />;
    } else {
      picker = (
        <>
          {currentUsers.slice(0, maxShown).map((user) => (
            <Avatar key={user.id} className={avatarStyle} data-cy={dataCy} size={calculatedSize} user={user} />
          ))}
          {picker}
          {currentUsers.length > maxShown && (
            <span
              className={css`
                color: #949caf;
                margin-top: calc(${calculatedSize} / 4);
                margin-left: calc(${calculatedSize} / 5);
                font-size: calc(${calculatedSize} / 2);
              `}
              title={currentUsers
                .slice(maxShown)
                .map((user): string => user.name)
                .join(strings.avatarPicker.arrayJoiner)}
            >
              {strings.formatString(strings.avatarPicker.plus, {
                count: currentUsers.length - maxShown,
              })}
            </span>
          )}
        </>
      );
    }
  }

  return (
    <DropdownController
      data-cy={dataCy}
      header
      headerPlaceholder={strings.dropdown.placeholder.searchUsers}
      headerTitle={strings.dropdown.header.addTeamMembers}
      onAdd={addAvatar}
      onClose={useCallback(() => setShow(false), [])}
      onRemove={removeAvatar}
      options={suggestions}
      selectedOptions={currentUsers}
      show={show}
    >
      <div
        className={cx(
          css`
            min-height: ${calculatedSize};
          `,
          className,
        )}
      >
        <div
          ref={Ref}
          className={css`
            cursor: pointer;
            display: flex;
            outline: 0;
          `}
          data-cy={dataCy}
          onClick={toggleShow}
          onKeyDown={toggleShow}
          role='button'
          tabIndex={-1}
        >
          {picker}
        </div>
      </div>
    </DropdownController>
  );
}
