import { useFirestoreConnect } from '@taraai/read-write';
import { Data, TaskStatus } from '@taraai/types';
import { PlannedEffortModule, SprintFiltersModule } from 'components/core/controllers/views/SprintFiltersModule';
import { TaskSummaries } from 'components/core/controllers/views/SprintFiltersModule/types';
import React, { useCallback } from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import {
  estimatedEffortSelector,
  getCompletedSprints,
  getSprint,
  getSprintTasks,
  searchActions,
  selectAuth,
  selectProfile,
  selectStatusFilter,
  updateUser,
  useAppDispatch,
} from 'reduxStore';

interface Props {
  sprintId: Data.Id.SprintId;
  orgId: Data.Id.OrganizationId;
  teamId: Data.Id.TeamId;
  hideFilters?: boolean;
}

export function SprintFiltersModuleController({ sprintId, orgId, teamId, hideFilters = false }: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const sprintSlice = getSprint(orgId, sprintId);
  const filterValue = useSelector(
    compose((value) => (value?.sprint === sprintId ? value?.status : undefined), selectStatusFilter),
  );

  const sprintTasksSlice = getSprintTasks(orgId, sprintId);
  const completedSlice = getCompletedSprints(orgId, teamId);
  const { showSprintOverloadAlerts = true } = useSelector(selectProfile);
  const { uid } = useSelector(selectAuth);

  useFirestoreConnect([...sprintSlice.query, ...completedSlice.query, ...sprintTasksSlice.query]);

  const estimatedEffort = useSelector(estimatedEffortSelector(completedSlice.selector, sprintSlice.selector));
  const sprintTasksFragment =
    useSelector(
      compose(
        (tasks) =>
          tasks &&
          tasks.map((task) => ({
            status: task.status,
            effortLevel: task.effortLevel,
          })),
        sprintTasksSlice.selector,
      ),
      deepEquals,
    ) || [];

  // Since we are willing to always show all the filters, the default is
  // to have 0 for everything. Then we go through the tasks and set the actual values

  /**
   * TS JOKE:
   * Object.values({ key: 2 }).length is equal to 2
   * But Object.values({ key: "1" }).length is equal to 1
   * We are using numbers in TaskStatus values, so the compiler see
   * the values of number type as a new record
   * To get the correct length for TaskStatus Enum, we need to do (value / 2)
   */
  const taskSummaries: TaskSummaries = Array(Object.values(TaskStatus).length / 2).fill({
    taskCount: 0,
    plannedEffort: 0,
  });
  sprintTasksFragment?.forEach((taskFragment) => {
    taskSummaries[taskFragment.status] = {
      taskCount: taskSummaries[taskFragment.status].taskCount + 1,
      plannedEffort: taskSummaries[taskFragment.status].plannedEffort + taskFragment.effortLevel,
    };
    return taskSummaries;
  });

  const handleFilterReset = useCallback(() => dispatch(searchActions.clearStatus()), [dispatch]);

  const handleFilterSelect = useCallback(
    (selectedFilter: TaskStatus | undefined) => {
      const status = selectedFilter === undefined ? undefined : { status: selectedFilter, sprint: sprintId };
      dispatch(searchActions.search(status && { status }));
    },
    [dispatch, sprintId],
  );

  const handleShowSprintOverloadAlertsChange = useCallback(
    (value: boolean) => {
      dispatch(updateUser({ id: uid, showSprintOverloadAlerts: value }));
    },
    [dispatch, uid],
  );

  return (
    <>
      {!hideFilters && (
        <SprintFiltersModule
          filterValue={filterValue}
          onFilterReset={handleFilterReset}
          onFilterSelect={handleFilterSelect}
          taskSummaries={taskSummaries}
        />
      )}
      <PlannedEffortModule
        onShowSprintOverloadAlertsChange={handleShowSprintOverloadAlertsChange}
        recommendedEffort={estimatedEffort}
        showSprintOverloadAlerts={showSprintOverloadAlerts}
        taskSummaries={taskSummaries}
      />
    </>
  );
}
