import { styled } from '@taraai/design-system';
import { CustomerValueComparison, CustomerValueNonComparison } from '@taraai/types/src/data';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import {
  GenerateComparisonLegendScale,
  GetKeysForRenderingInGraph,
  GetStateSelectionType,
  isValidData,
} from 'components/app/GitInsights/Shared//utils';
import { EmptyData } from 'components/app/GitInsights/Shared/EmptyData';
import { GraphLegend } from 'components/app/GitInsights/Shared/PrimitiveComponents/GraphLegend';
import { GraphSection } from 'components/app/GitInsights/Shared/PrimitiveComponents/GraphSection';
import { GroupVerticalStacked } from 'components/app/GitInsights/Shared/PrimitiveComponents/GroupVerticalStacked';
import { ColumnDefinition } from 'components/app/Table/_types';
import { ReactTable } from 'components/app/Table/Table';
import React from 'react';
import { useSelector } from 'react-redux';
import { getChartData } from 'reduxStore';

// Pull request/Merge Request Health
export function CustomerValueBreakdown(): JSX.Element | null {
  const rawData = useSelector(getChartData)?.CustomerValue;

  const renderType = GetStateSelectionType();

  const comparisonData = rawData as CustomerValueComparison;

  const singleData = rawData as CustomerValueNonComparison;

  const getBottomInfo = (info: Record<string, string | number>): string => String(info.label);

  const getAxisBottomInfo = (info: Record<string, string | number>): string => String(info.label).slice(20);

  const generateTableGraphsMarkup = (values: Record<string, string | number>[]): JSX.Element => {
    return (
      <ParentSize>
        {({ width }) => (
          <GroupVerticalStacked
            data={values}
            dataKeys={GetKeysForRenderingInGraph(values[0], 'label')}
            getAxisBottomInfo={getAxisBottomInfo}
            height={100}
            margin={{ top: -20, left: -5, right: 0, bottom: 20 }}
            showAxisLeftInfo={false}
            showBarLabels
            showGridLines={false}
            showValues
            width={width}
          />
        )}
      </ParentSize>
    );
  };

  const generateTableComparisonData = (data: CustomerValueComparison): unknown[] => {
    if ('data' in data[0])
      // eslint-disable-next-line id-length
      return data?.map((el) => ({
        user: el.label,
        plannedOnProd: [el.data.find((_el) => _el.label === 'plannedOnProd')],
        plannedOnDev: [el.data.find((_el) => _el.label === 'plannedOnDev')],
        plannedOnStaging: [el.data.find((_el) => _el.label === 'plannedOnStaging')],
        unplanned: [el.data.find((_el) => _el.label === 'unplanned')],
        bugs: [el.data.find((_el) => _el.label === 'bugs')],
      })) as unknown[];

    return [] as unknown[];
  };

  const tableColumnsConfig: ColumnDefinition[] = [
    {
      id: 'user',
      accessor: 'user',
      header: () => <span>&nbsp;</span>,
      cell: (info) => {
        return <span>{info.getValue()}</span>;
      },
      alignX: 'left',
      alignY: 'top',
    },
    {
      id: 'prod',
      accessor: 'plannedOnProd',
      header: () => <span>Planned on Production</span>,
      cell: (info) => generateTableGraphsMarkup(info.getValue()),
      alignX: 'left',
      alignY: 'top',
    },
    {
      id: 'dev',
      accessor: 'plannedOnDev',
      header: () => <span>Planned on Dev</span>,
      cell: (info) => generateTableGraphsMarkup(info.getValue()),
      alignX: 'left',
      alignY: 'top',
    },
    {
      id: 'staging',
      accessor: 'plannedOnStaging',
      header: () => <span>Planned on Staging</span>,
      cell: (info) => generateTableGraphsMarkup(info.getValue()),
      alignX: 'left',
      alignY: 'top',
    },
    {
      id: 'unplanned',
      accessor: 'unplanned',
      header: () => <span>Unplanned</span>,
      cell: (info) => generateTableGraphsMarkup(info.getValue()),
      alignX: 'left',
      alignY: 'top',
    },
    {
      id: 'bugs',
      accessor: 'bugs',
      header: () => <span>Bugs</span>,
      cell: (info) => generateTableGraphsMarkup(info.getValue()),
      alignX: 'left',
      alignY: 'top',
    },
  ];

  return (
    <GraphSection
      colorsLegend={
        <GraphLegend
          colorShapeFormat='circle'
          scale={GenerateComparisonLegendScale([
            'plannedOnProd',
            'plannedOnDev',
            'plannedOnStaging',
            'unplanned',
            'bugs',
          ])}
        />
      }
      headerSubText='Number of commits merged to the production branch that deliver value to our customers'
      headerText='Customer Value Breakdown'
    >
      {!!rawData && rawData.length > 0 ? (
        <GraphsBox>
          {renderType === 'multiUser' && isValidData(comparisonData) && comparisonData.length > 0 && (
            <ReactTable
              columnsConfig={tableColumnsConfig}
              tableData={generateTableComparisonData(comparisonData) as unknown[]}
            />
          )}

          {renderType === 'singleUser' && isValidData(singleData) && singleData.length > 0 && (
            <ParentSize>
              {({ width }) => (
                <GroupVerticalStacked
                  data={singleData}
                  dataKeys={GetKeysForRenderingInGraph(singleData[0], 'label')}
                  getAxisBottomInfo={getBottomInfo}
                  height={300}
                  showValues
                  width={width}
                />
              )}
            </ParentSize>
          )}
        </GraphsBox>
      ) : (
        <EmptyData dataToWatch={rawData} />
      )}
    </GraphSection>
  );
}

const GraphsBox = styled('div', {
  display: 'flex',
  justifyContent: 'center',
});
