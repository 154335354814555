import { isLoaded, useFirestoreConnect } from '@taraai/read-write';
import { Data } from '@taraai/types';
import React, { useEffect } from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { Redirect, useLocation, useParams } from 'react-router';
import { compose } from 'redux';
import {
  getCustomLabels,
  getOrg,
  getOrgTeams,
  getUsers,
  getWorkspaceCustomClaimsQuery,
  listOrganizationUsers,
  RootState,
  selectActiveOrgIds,
  selectPreferredTeamId,
  setTeam,
  setWorkspace,
  updateOrganization,
  updateUser,
  useAppDispatch,
} from 'reduxStore';
import { getSprintSummary } from 'reduxStore/sprints/queries/getSprintsSummary';
import { getRequirementSummary } from 'reduxStore/summaries/requirement';
import { getTaskSummary } from 'reduxStore/summaries/tasks';
import { getTeamId } from 'route-utils';
import { toTimestamp } from 'tools/libraries/helpers';

import { getRouteName, linkTo } from './paths';

/**
 * Shows spinner until organization document is loaded from firestore
 */
export const OrgAndTeamIsLoaded: React.FC = ({ children }): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { orgId, ...params } = useParams<{ orgId: Data.Id.OrganizationId }>();
  const orgSlice = getOrg(orgId);
  const userSlice = getUsers(orgId);
  const teamSlice = getOrgTeams(orgId);
  const labelsSlice = getCustomLabels(orgId);
  const workspaceCustomClaimsSlice = getWorkspaceCustomClaimsQuery(orgId);

  const orgName = useSelector(
    compose((data) => data?.name, orgSlice.selector),
    deepEquals,
  );

  const teamNames = useSelector(
    compose((data) => data?.map(({ name }) => name), teamSlice.selector),
    deepEquals,
  );
  const profileHasAccessToOrg = useSelector(
    compose((organizationIds) => organizationIds.includes(orgId), selectActiveOrgIds),
  );
  const workspaceCustomClaims = useSelector(workspaceCustomClaimsSlice.selector, deepEquals);
  const uid = useSelector((state: RootState) => state.firebase.auth.uid);

  const requirementSprintSlice = getSprintSummary(orgId, false);
  const requirementSummariesSlice = getRequirementSummary(orgId, false);
  const tasksSummariesSlice = getTaskSummary(orgId, false);

  useFirestoreConnect([
    ...orgSlice.query,
    ...userSlice.query,
    ...teamSlice.query,
    ...labelsSlice.query,
    ...workspaceCustomClaimsSlice.query,
    ...requirementSprintSlice.query,
    ...requirementSummariesSlice.query,
    ...tasksSummariesSlice.query,
  ]);

  // When the org, user custom-claims or users in org changes get the new access levels
  useEffect(() => {
    // theoretically user without custom claims should never get here
    // to be 100% sure that user has rights to request organization data
    // there should be another layer of abstraction checking that
    // this is not part of a hotfix so ticket for that: https://app.tara.ai/tara-ai/tasks/2273
    if (profileHasAccessToOrg) {
      dispatch(listOrganizationUsers());
    }
  }, [orgId, profileHasAccessToOrg, dispatch]);

  const preferredTeamId = useSelector(selectPreferredTeamId(orgId));
  const routeTeamId = getTeamId();
  const orgTeams = useSelector(getOrgTeams(orgId).selector, deepEquals);
  const orgTeamsCount = (orgTeams ?? []).length;
  const teamId = routeTeamId && orgTeams?.some(({ id }) => id === routeTeamId) ? routeTeamId : undefined;

  // Each time user switches workspace
  // TODO: Consider re-initializing Fullstory and Firebase Analytics.
  useEffect(() => {
    if (orgId && orgId !== '404') {
      dispatch(setWorkspace(orgId));
    }
    if (teamId) {
      dispatch(setTeam(teamId));
    }
  }, [orgId, teamId, orgTeamsCount, dispatch]);

  useEffect(() => {
    dispatch(updateUser({ id: uid, lastViewed: toTimestamp(new Date()) }));
    dispatch(updateOrganization({ id: orgId, lastViewed: toTimestamp(new Date()) }));
  }, [dispatch, orgId, uid]);

  if (!isLoaded(orgName) || !isLoaded(teamNames) || !isLoaded(workspaceCustomClaims)) {
    return null;
  }

  /**
   * We need support for legacy links without team id in the URL
   * for tasks and requirements.
   */
  const isLegacyRoute = getRouteName().startsWith('LEGACY_');
  if (isLegacyRoute && !teamId) {
    const routeWithPreferredTeam = location.pathname.replace(orgId, `${orgId}/${preferredTeamId}`);

    return <Redirect to={routeWithPreferredTeam} />;
  }

  if (!teamId) {
    return (
      <Redirect
        to={linkTo(getRouteName(), {
          ...params,
          orgId,
          teamId: preferredTeamId,
          // TODO: linkTo requires static params but in order to have global
          //       navigation the route must be a variable
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any)}
      />
    );
  }

  return <>{children}</>;
};
