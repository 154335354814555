import { Dropdown, HStack, Text } from '@taraai/design-system';
import { UI } from '@taraai/types';
import React, { ReactElement, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useClickOutside } from 'tools/utils/hooks';

type Props = {
  children: ReactElement;
  tasks: UI.TaskSummary[] | undefined;
};

export function TaskSelectorDropdown({ children, tasks }: Props): JSX.Element {
  const history = useHistory();

  const DropdownRef = useRef<HTMLDivElement>(null);

  const [shouldShowDropdown, setShowDropdown] = useState(true);

  const dropdownItems = useMemo(() => {
    const onSelect = (taskId: string): void => {
      history.push({
        search: `?type=task&active=${taskId}`,
      });
    };

    const handleSelect = (taskId: string): void => {
      onSelect(taskId);
      setShowDropdown(false);
    };

    return tasks?.map((task: UI.TaskSummary) => (
      <Dropdown.DefaultItem key={task.id} onClick={() => handleSelect(task.id)}>
        <HStack alignY='center' space='$4px' style={{ display: 'block' }}>
          <Text color='$focus' size='$14px' style={{ fontWeight: 'bold' }}>
            TASK-{task.id}
          </Text>

          <Text color='$focus' size='$14px'>
            {task.labels
              .map((label) => `#${label}`)
              .toString()
              .replaceAll(',', ' ')}
          </Text>

          <Text color='$dark' size='$14px'>
            {`${task.title.substring(0, 20)}${task.title.length > 20 ? '...' : ''}`}
          </Text>
        </HStack>
      </Dropdown.DefaultItem>
    ));
  }, [tasks, history]);

  useClickOutside(DropdownRef, () => {
    return shouldShowDropdown ? setShowDropdown(false) : null;
  });

  const isEmptyTaskList = !!(tasks && tasks?.length === 0);

  return (
    <div ref={DropdownRef}>
      <Dropdown
        items={dropdownItems || []}
        placement='bottom-start'
        preventBlurOnSelection
        selectOnTab
        show={shouldShowDropdown === true && !isEmptyTaskList}
      >
        {children}
      </Dropdown>
    </div>
  );
}
