/* eslint-disable no-loops/no-loops */

import { Data } from '@taraai/types';
import { ContentBlock, ContentState, EditorState, SelectionState } from 'draft-js';
import { MAX_TASK_TITLE_LENGTH } from 'tools';

export const isInlineStyleSelected = (styleName: string) => (state: EditorState) =>
  state.getCurrentInlineStyle().includes(styleName);

export const isBlockTypeSelected = (blockType: string) => (state: EditorState) =>
  getSelectedBlocks(
    state.getCurrentContent(),
    state.getSelection().getStartKey(),
    state.getSelection().getEndKey(),
  ).some((block) => block.getType() === blockType);

function getSelectedBlocks(content: ContentState, startKey: string, endKey: string): ContentBlock[] {
  const startingBlock = content.getBlockForKey(startKey);
  const selectedBlocks = [startingBlock];
  let blockKey = startKey;
  while (blockKey !== endKey) {
    const nextBlock = content.getBlockAfter(blockKey);
    if (!nextBlock) throw new Error('Block is undefined');
    selectedBlocks.push(nextBlock);
    blockKey = nextBlock.getKey();
  }
  return selectedBlocks;
}

export type Selection = {
  anchorKey: string;
  anchorOffset: number;
  hasFocus?: boolean;
} & ({ focusKey?: undefined; focusOffset?: undefined } | { focusKey: string; focusOffset: number });

export function withSelection(
  editorState: EditorState,
  { anchorKey, anchorOffset, focusKey = anchorKey, focusOffset = anchorOffset, hasFocus = false }: Selection,
): EditorState {
  return EditorState.forceSelection(
    editorState,
    SelectionState.createEmpty(anchorKey).merge({
      anchorKey,
      anchorOffset,
      focusKey,
      focusOffset,
      hasFocus,
    }) as SelectionState,
  );
}

export const makeCopyDocumentTitle = (
  docTitle: string,
  documentId: Data.Id.TaskId | Data.Id.RequirementId,
  type: 'requirement' | 'task',
): string => {
  const titleString = `Copy of ${type}-${documentId}: ${docTitle}`;
  return titleString.substring(0, MAX_TASK_TITLE_LENGTH);
};
