import { Box, Text } from '@taraai/design-system';
import React from 'react';

type Props = {
  message: string;
};

export const ErrorOnSave = ({ message }: Props): JSX.Element => (
  <Box background='$grey1' spaceHorz='$16px' spaceVert='$12px'>
    <Text color='$coreRed' size='$12px'>
      Error: {message}
    </Text>
  </Box>
);
