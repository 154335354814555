/* eslint-disable id-length */ // We use standard ISO language codes, which are two letters.

import LocalizedStrings from 'react-localization';

import en from './en';

/**
 * Full documentation: [react-localization](https://github.com/stefalda/react-localization#readme)
 *
 * Localization of strings, any string checked in should be in the appropriate language file and run through this package
 *
 * ## Usage
 *
 * ```js
 * // i18n/en.js
 * export default {
 *   section: {
 *     foo: `foo`,
 *     hello: `Hello, {name}!`
 *   }
 * };
 * ```
 *
 * ```js
 * // your-file.js
 * import { strings } from 'resources/i18n';
 *
 * strings.section.foo; // "foo"
 * strings.formatString(strings.section.hello, { name: `Syed` }); // "Hello, Syed!"
 * ```
 * Example of adding a localized string with a link / any other component found under: https://github.com/stefalda/react-localization#api
 */
export const strings = new LocalizedStrings({
  en,
});

export default strings;
