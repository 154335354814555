import { createSelector, Selector } from '@reduxjs/toolkit';
import { Data, UI } from '@taraai/types';
import { notUndefined } from '@taraai/utility';
import { RootState } from 'reduxStore/store';
import { ReduxFirestoreQuery } from 'reduxStore/utils/types';

import { getTaskBranchSummaries } from './tasks-branches-summaries';
import { getTaskCommitsSummaries } from './tasks-commits-summaries';
import { getTaskPullRequestsSummaries } from './tasks-pull-requests-summaries';

export type TaskGitData = {
  branches: UI.UIBranchSummary[];
  pullRequests: UI.UIPullRequestSummary[];
  commits: UI.UICommitSummary[];
};

type GetTaskGitDataRes = {
  query: ReduxFirestoreQuery[];
  selector: Selector<RootState, TaskGitData | undefined>;
};

export const getTaskGitData = (orgId: Data.Id.OrganizationId, taskId: Data.Id.TaskId): GetTaskGitDataRes => {
  const changes = {
    taskBranchesSummaries: getTaskBranchSummaries(orgId, taskId),
    taskPullRequestsSummaries: getTaskPullRequestsSummaries(orgId, taskId),
    // For now we only want to load one commit
    taskCommitsSummaries: getTaskCommitsSummaries(orgId, taskId, {
      limit: 1,
    }),
  };

  const query = [
    ...changes.taskBranchesSummaries.query,
    ...changes.taskPullRequestsSummaries.query,
    ...changes.taskCommitsSummaries.query,
  ];

  const allTaskGitDataSelector = createSelector(
    [
      changes.taskBranchesSummaries.selector,
      changes.taskPullRequestsSummaries.selector,
      changes.taskCommitsSummaries.selector,
    ],
    (tasksBranchesSummaries, tasksPullRequestsSummaries, tasksCommitsSummaries): TaskGitData | undefined =>
      tasksBranchesSummaries && tasksPullRequestsSummaries && tasksCommitsSummaries
        ? {
            branches: tasksBranchesSummaries.filter(notUndefined),

            pullRequests: tasksPullRequestsSummaries.filter(notUndefined),

            commits: tasksCommitsSummaries.filter(notUndefined),
          }
        : undefined,
  );

  return {
    query,
    selector: allTaskGitDataSelector,
  };
};
