import { Box, styled, Tree, VStack } from '@taraai/design-system';
import { useFirestoreConnect } from '@taraai/read-write';
import { Data, TaskStatus, UI } from '@taraai/types';
import { noop } from '@taraai/utility';
import { DroppableArea, DroppableDescription, DroppableType } from 'components/app/DragAndDrop';
import { EmptyDrawerProps, EmptyDrawerRepos } from 'components/app/EmptyWorkDrawer/EmptyWorkDrawer';
import { TaskCardsItem } from 'components/app/WorkDrawer/WorkDrawerSections';
import { PrimaryHeader, SecondaryHeader } from 'components/app/WorkDrawerHeaders';
import { linkTo } from 'components/Router/paths';
import { AppCuesWrapper } from 'components/services/AppCues';
import React, { useEffect } from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { repositoryTasks, useFilteredRepos, useFilteredRepoTasks, useIsSearchActive } from 'reduxStore';
import { getOrgGithubRepositories } from 'reduxStore/github-import/queries';
import { useWorkDrawerSettingsState, useWorkDrawerUnfoldedState } from 'reduxStore/workDrawer';

export function WorkDrawerRepos({
  onTaskSelect,
  orgId,
  teamId,
}: EmptyDrawerProps & {
  onTaskSelect: (taskId: Data.Id.TaskId) => void;
}): JSX.Element {
  const [unfolded, setUnfolded] = useWorkDrawerUnfoldedState('reposSection');
  const githubSlice = getOrgGithubRepositories(orgId);
  const isSearchActive = useIsSearchActive();
  const searchRepoIds = useFilteredRepos();
  const repos = useSelector(githubSlice.selector, deepEquals) || [];
  const filteredRepos = isSearchActive ? repos.filter((repository) => searchRepoIds.includes(repository.id)) : repos;

  useEffect(() => {
    if (isSearchActive) setUnfolded(true);
  }, [isSearchActive, setUnfolded]);

  useFirestoreConnect(Array.from(githubSlice.query));

  return (
    <Tree unfolded={unfolded}>
      <AppCuesWrapper targetName='repos-header'>
        <PrimaryHeader
          onClick={() => setUnfolded(!unfolded)}
          title='Repositories'
          widget={<TopLevelCreationLink to={linkTo('integrations', { orgId, teamId })} />}
        />
      </AppCuesWrapper>
      <Tree.Content strategy='lazy'>
        <VStack space='$2px'>
          {filteredRepos.length ? (
            filteredRepos.map((repo) => (
              <WorkDrawerReposItem
                key={repo.id}
                onTaskSelect={onTaskSelect}
                orgId={orgId}
                repoId={repo.id}
                title={repo.title}
              />
            ))
          ) : (
            <EmptyDrawerRepos orgId={orgId} teamId={teamId} />
          )}
        </VStack>
      </Tree.Content>
    </Tree>
  );
}

function WorkDrawerReposItem({
  onTaskSelect,
  title,
  repoId,
  orgId,
}: {
  title: string;
  repoId: Data.Id.ProductId;
  orgId: Data.Id.OrganizationId;
  onTaskSelect: (taskId: Data.Id.TaskId) => void;
}): JSX.Element {
  const [unfolded, setUnfolded] = useWorkDrawerUnfoldedState('repos', title);
  return (
    <Tree onChange={noop} unfolded={unfolded}>
      <SecondaryHeader onClick={() => setUnfolded(!unfolded)} title={title} />
      <Tree.Content strategy='lazy'>
        <RepoTaskCards onTaskSelect={onTaskSelect} orgId={orgId} repoId={repoId} />
      </Tree.Content>
    </Tree>
  );
}

function RepoTaskCards({
  onTaskSelect,
  repoId,
  orgId,
  sourceType = DroppableType.workdrawerRepo,
  isDraggable = true,
}: {
  onTaskSelect: (taskId: Data.Id.TaskId) => void;
  repoId: Data.Id.ProductId;
  orgId: Data.Id.OrganizationId;
  sourceType?: DroppableType;
  isDraggable?: boolean;
}): JSX.Element {
  const tasksSlice = repositoryTasks(orgId, repoId);
  const isSearchActive = useIsSearchActive();
  const showDoneTasks = useWorkDrawerSettingsState('tasks:show-done', { checkSearchIsActive: true });
  const searchRepoTaskIds = useFilteredRepoTasks();

  const showAssignedToSprintTasks = useWorkDrawerSettingsState('tasks:show-assigned-to-sprint', {
    checkSearchIsActive: true,
  });

  const taskIds: Data.Id.TaskId[] = useSelector(
    compose(
      (tasks: UI.UITask[]) =>
        tasks?.sort((task1, task2) => (Number.parseInt(task1.id, 10) > Number.parseInt(task2.id, 10) ? 1 : -1)),
      (tasks) =>
        tasks &&
        tasks
          .filter(
            (task: UI.UITask): boolean =>
              (showDoneTasks ? true : task.status !== TaskStatus.Done) &&
              (showAssignedToSprintTasks ? true : task.sprint === null) &&
              (isSearchActive ? searchRepoTaskIds.includes(task.id) : true),
          )
          .map(({ id }) => id),
      tasksSlice.selector,
    ),
    deepEquals,
  );

  const droppableAreaDescription: DroppableDescription = {
    id: repoId,
    type: DroppableType.workdrawerRepo,
    visibleList: taskIds,
    list: taskIds,
  };

  useFirestoreConnect(tasksSlice.query);

  return (
    <DroppableArea description={droppableAreaDescription} styles={{ minHeight: '0' }}>
      {() => {
        return (
          <Box spaceLeft='$12px'>
            <VStack space='$1px'>
              {taskIds?.map((taskId, index) => (
                <TaskCardsItem
                  key={taskId}
                  index={index}
                  isDraggable={isDraggable}
                  onTaskSelect={onTaskSelect}
                  sourceType={sourceType}
                  taskId={taskId}
                />
              ))}
            </VStack>
          </Box>
        );
      }}
    </DroppableArea>
  );
}

function TopLevelCreationLink({ to }: { to: string }): JSX.Element {
  return (
    <CreateLink onClick={(event) => event.stopPropagation()} to={to}>
      Sync
    </CreateLink>
  );
}

const CreateLink = styled(Link, {
  color: '$focus',
  fontSize: '14px',
});
