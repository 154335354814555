import { splitArrayOn } from '@taraai/utility';
import { css, cx } from 'emotion';
import React, { useState } from 'react';
import { useDrop } from 'react-dnd';

import { DragElement } from './DragElement';

type DraggableListProps<T extends { id: string }> = {
  /** Drag type (element can be dropped on other list if it has the same drag type) */
  type: string;
  items: T[];
  listElement: (props: { value: T; innerRef: React.MutableRefObject<HTMLDivElement | null> }) => JSX.Element;
  /** Shown on hover before dragged element is dropped */
  placeholder: (props: { show: boolean }) => JSX.Element;
  onChange: (newList: T[]) => void;
  className?: string;
};

/**
 * Generic list component that allows for reordering of elements using drag & drop.
 */
export function DraggableList<T extends { id: string }>({
  items,
  listElement,
  placeholder,
  onChange,
  type,
  className,
}: DraggableListProps<T>): JSX.Element {
  const [anyDragged, setAnyDragged] = useState(false);

  /** Reorder elements and call onChange with new order */
  const handleDrop = (elementId: string, dropzoneId?: string): void => {
    const withoutDraggedElement = items.filter((item) => item.id !== elementId);
    const [beforeDropPoint, afterDropPoint] = splitArrayOn(
      withoutDraggedElement,
      (element) => element.id === dropzoneId,
    );
    const elementToDrop = items.find((element) => element.id === elementId);
    if (elementToDrop) {
      onChange([...beforeDropPoint, elementToDrop, ...afterDropPoint]);
    }
  };

  // used to enable dropping at the bottom of the list
  // (to move to the end of the order list)
  const [{ isHovered }, lastDropzoneRef] = useDrop<{ id: string; type: string }, unknown, { isHovered: boolean }>({
    accept: type,
    collect: (monitor) => ({
      isHovered: monitor.isOver({ shallow: true }),
    }),
    drop: (element) => {
      handleDrop(element.id);
    },
  });

  return (
    <div
      className={cx(
        css`
          position: relative;
          display: flex;
          flex-direction: column;
        `,
        className,
      )}
      data-dragging={anyDragged}
      data-testid='draggableList'
    >
      {items.map((value) => {
        return (
          <DragElement
            key={value.id}
            dropzonePlaceholder={(show): JSX.Element => placeholder({ show })}
            id={value.id}
            listElement={(innerRef): JSX.Element => listElement({ value, innerRef })}
            onDragEnd={(): void => setAnyDragged(false)}
            onDragStart={(): void => setAnyDragged(true)}
            onDrop={(elementId): void => handleDrop(elementId, value.id)}
            showDropzone={anyDragged}
            type={type}
          />
        );
      })}
      {anyDragged && (
        <div
          ref={lastDropzoneRef}
          className={css`
            flex: 1;
            min-height: 32px;
          `}
        >
          {placeholder({ show: isHovered })}
        </div>
      )}
    </div>
  );
}
