import styled from '@emotion/styled';
import { PreferredTeamButton } from 'components/app/controllers/views/PreferredTeamButton';
import Icon from 'components/core/controllers/views/Icon';
import { InputWithButtons } from 'components/core/controllers/views/InputWithButtons';
import Logo from 'components/core/controllers/views/Logo';
import SpinnerButton from 'components/core/controllers/views/SpinnerButton';
import React, { ChangeEventHandler, useCallback, useState } from 'react';
import { teamDetailsTestIds } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';
import { atomic } from 'resources/theme';

import { NameContainer, NameField, NameLabel, NameSection, SectionContainer } from './styles';

interface Props {
  isMember: boolean;
  isPreferred: boolean;
  joinTeam: () => void;
  setAsPreferred: () => void;
  teamName: string;
  updateName: (teamName: string) => void;
}

/**
 * EditTeamDetailsView
 * The section of team details where you can edit the name
 */
export function EditTeamDetailsView({
  isMember,
  isPreferred,
  joinTeam,
  setAsPreferred,
  teamName,
  updateName,
}: Props): JSX.Element {
  const [isEditing, setIsEditing] = useState(false);
  const [newTeamName, setNewTeamName] = useState(teamName);

  const handleOnPencilClick = useCallback(() => {
    setIsEditing(true);
  }, []);

  const handleNameChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setNewTeamName(event.target.value);
  }, []);

  const handleOnCancel = useCallback(() => {
    setNewTeamName(teamName);
    setIsEditing(false);
  }, [teamName]);

  const handleOnSave = useCallback(() => {
    if (teamName !== newTeamName) {
      updateName(newTeamName);
    }
    setIsEditing(false);
  }, [newTeamName, teamName, updateName]);

  return (
    <SectionContainer>
      <NameContainer>
        <Logo name={newTeamName} shape='circle' size='extra-large' />
        <NameSection>
          <NameLabel>{strings.teamDetails.nameLabel}</NameLabel>
          {isEditing ? (
            <NameField>
              <InputWithButtons
                dataCyCancel={teamDetailsTestIds.EDIT_TEAM_NAME_CANCEL_BUTTON}
                dataCyConfirm={teamDetailsTestIds.EDIT_TEAM_NAME_CONFIRM_BUTTON}
                handleOnCancel={handleOnCancel}
                handleOnChange={handleNameChange}
                handleOnSave={handleOnSave}
                value={newTeamName}
              />
            </NameField>
          ) : (
            <NameField>
              {newTeamName}
              {isMember && (
                <EditIcon
                  data-cy={teamDetailsTestIds.EDIT_TEAM_NAME_BUTTON}
                  name='pencil'
                  onClick={handleOnPencilClick}
                />
              )}
            </NameField>
          )}
        </NameSection>
      </NameContainer>
      {isMember ? (
        <PreferredTeamButton
          dataCy={teamDetailsTestIds.SET_PREFERRED_BUTTON}
          isPreferred={isPreferred}
          onClick={(event: React.SyntheticEvent): void => {
            event.stopPropagation();
            setAsPreferred();
          }}
        />
      ) : (
        <JoinTeamButton data-cy={teamDetailsTestIds.JOIN_TEAM} onClick={joinTeam}>
          {strings.teamDetails.joinTeam}
        </JoinTeamButton>
      )}
    </SectionContainer>
  );
}

const EditIcon = styled((props) => <Icon {...props} />)`
  color: ${atomic.get(atomic.colors.grey5)};
  width: 0.75rem;
  height: 0.75rem;
  padding: 0 0 0 0.625rem;
`;

const JoinTeamButton = styled((props) => <SpinnerButton size='compact' type='button' {...props} />)`
  padding: 0.25;
  height: 1.75rem;
  font-size: 0.875rem;
  min-width: 5.5rem;
  display: block;
`;
