import Icon from 'components/core/controllers/views/Icon';
import { css } from 'emotion';
import React from 'react';
import { strings } from 'resources/i18n';
import { atomic } from 'resources/theme';

export default function NoComments(): JSX.Element {
  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
      `}
    >
      <Icon
        className={css`
          fill: #f1f5fd;
          width: 6rem;
          height: 6rem;
        `}
        name='speech'
      />
      <span
        className={css`
          color: ${atomic.get(atomic.colors.grey6)};
          font-size: 0.9rem;
          font-weight: 500;
          padding-top: 1.3rem;
        `}
      >
        {strings.comments.empty}
      </span>
    </div>
  );
}
