import { Tooltip } from '@taraai/design-system';
import Avatar from 'components/core/controllers/views/Avatar';
import { css } from 'emotion';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectProfile } from 'reduxStore';
import { homePageTestIds } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';
import { atomic } from 'resources/theme';

export interface YourStatsProps {
  done: number;
  effort: number;
  effortCompleted: number;
}

export default function YourStats({ done, effort, effortCompleted }: YourStatsProps): JSX.Element {
  const profile = useSelector(selectProfile);

  return (
    <div
      className={css`
        margin: 1rem;
        border-radius: 0.1875rem;
        background-color: #fbfbfd;
      `}
    >
      <div
        className={css`
          padding: 0.625rem;
          font-size: 0.75rem;
          font-weight: 600;
          letter-spacing: 0.025rem;
          text-align: center;
          color: ${atomic.get(atomic.colors.primary)};
          padding-bottom: 1.5625rem;
        `}
      >
        {strings.keyStatistics.yourStats.toLocaleUpperCase()}
      </div>
      <div
        className={css`
          display: flex;
        `}
      >
        <div
          className={css`
            float: left;
            flex: 1;
          `}
        >
          <Tooltip
            placement='bottom'
            title={
              effort === 0
                ? strings.keyStatistics.emptyStateTooltip
                : (strings.formatString(strings.keyStatistics.tooltip, {
                    number: done,
                  }) as string)
            }
          >
            {effort === 0 ? (
              <div
                className={css`
                  font-size: 1.125rem;
                  font-weight: 600;
                  color: #c8d0df;
                  text-align: center;
                `}
              >
                {strings.keyStatistics.emptyState}
              </div>
            ) : (
              <div
                className={css`
                  font-size: 1.125rem;
                  font-weight: 600;
                  color: #4550ce;
                  text-align: center;
                `}
                data-cy={homePageTestIds.USER_PERCENT_DONE_IN_SPRINT}
              >
                {strings.formatString(strings.keyStatistics.totalTasksDone, {
                  number: done,
                })}
              </div>
            )}
          </Tooltip>
          <p
            className={css`
              font-size: 0.75rem;
              text-align: center;
              padding: 1.3125rem;
              padding-top: 0.3125rem;
              color: ${atomic.get(atomic.colors.primary)};
            `}
          >
            {strings.keyStatistics.done}
          </p>
        </div>
        <div>
          <Avatar size='medium' user={profile} />
        </div>
        <div
          className={css`
            float: right;
            flex: 1;
          `}
        >
          <Tooltip
            placement='bottom'
            title={
              effort === 0
                ? strings.keyStatistics.emptyStateTooltip
                : (strings.formatString(strings.keyStatistics.tooltipEffort, {
                    effortCompleted,
                  }) as string)
            }
          >
            {effort === 0 ? (
              <div
                className={css`
                  font-size: 1.125rem;
                  font-weight: 600;
                  color: #c8d0df;
                  text-align: center;
                `}
              >
                {strings.keyStatistics.emptyState}
              </div>
            ) : (
              <div
                className={css`
                  font-size: 1.125rem;
                  font-weight: 600;
                  text-align: center;
                  color: #4550ce;
                `}
                data-cy={homePageTestIds.USER_EFFORT_IN_SPRINT}
              >
                {strings.formatString(strings.keyStatistics.totalEffortCompleted, {
                  effortCompleted,
                  effort,
                })}
              </div>
            )}
          </Tooltip>
          <p
            className={css`
              font-size: 0.75rem;
              text-align: center;
              padding: 1.3125rem;
              padding-top: 0.3125rem;
              color: ${atomic.get(atomic.colors.primary)};
            `}
          >
            {strings.keyStatistics.effort}
          </p>
        </div>
      </div>
    </div>
  );
}
