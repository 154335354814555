import { Box, HStack, styled, Text, VStack } from '@taraai/design-system';
import { Timestamp } from '@taraai/types';
import { SprintDetailsSidebarModule } from 'components/app/controllers/views/SprintDetailsSidebarModule';
import moment from 'moment';
import React from 'react';
import { compareDates, firestore, toDate } from 'tools';

type Props = {
  startDate: Timestamp;
  endDate: Timestamp;
};

export function SprintDateModule({ startDate, endDate }: Props): JSX.Element {
  return (
    <SprintDetailsSidebarModule>
      <Box>
        <DottedLine />
        <VStack space='$8px'>
          <DateItem date={startDate} />
          <DateItem date={endDate} />
        </VStack>
      </Box>
    </SprintDetailsSidebarModule>
  );
}

function DateItem({ date }: { date: Timestamp }): JSX.Element {
  const currentDate = firestore.Timestamp.fromDate(new Date());
  const isDateInPast = compareDates(date, currentDate) < 0;
  return (
    <Box>
      <HStack alignY='center'>
        <Bullet past={isDateInPast} />
        <DateText color={isDateInPast ? '$grey6' : '$grey7'} size='$12px'>
          {moment(toDate(date)).format('LLL')}
        </DateText>
      </HStack>
    </Box>
  );
}

const DateText = styled(Text, {
  transition: '0.2s all ease',
});

const DottedLine = styled('div', {
  position: 'absolute',
  top: '8px',
  left: '2px',
  height: 'calc(100% - 16px)',
  borderStyle: 'none none none dashed',
  borderWidth: '1px',
  borderColor: '$grey5',
  transform: 'translateX(50%)',
});

const Bullet = styled(
  'div',
  {
    borderRadius: '$circle',
    margin: '4px 8px 4px 0px',
    borderStyle: 'solid',
    transition: '0.2s all ease',
    backgroundColor: '$white',
    borderColor: '$grey6',
  },
  {
    past: {
      true: {
        backgroundColor: '$grey5',
        borderColor: '$grey5',
      },
    },
  },
);
