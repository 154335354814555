import styled from '@emotion/styled';
import Button, { ButtonSize } from 'components/core/controllers/views/Button';
import FastSmallSpinner from 'components/core/controllers/views/Spinners/FastSmallSpinner';
import React, { ComponentProps, useCallback, useState } from 'react';

export type SpinnerButtonProps = ComponentProps<typeof Button> & {
  showSpinnerInitially?: boolean;
  hideSpinnerAutomatically?: boolean;
  onClick?: (event: React.SyntheticEvent) => Promise<void>;
  dataCy?: string;
};

/**
 * A wrapper around `<Button>` with spinner inside
 */
export default function SpinnerButton({
  children,
  size,
  onClick,
  dataCy,
  showSpinnerInitially = false,
  hideSpinnerAutomatically = false,
  disabled,
  ...buttonProps
}: SpinnerButtonProps): JSX.Element {
  const [showSpinner, setShowSpinner] = useState(showSpinnerInitially);

  const handleClick = useCallback(
    async (event: React.SyntheticEvent): Promise<void> => {
      if (!onClick) {
        return;
      }
      setShowSpinner(true);
      await onClick(event);
      if (hideSpinnerAutomatically) {
        setShowSpinner(false);
      }
    },
    [hideSpinnerAutomatically, onClick],
  );

  return (
    <Button disabled={disabled || showSpinner} size={size} {...buttonProps} data-cy={dataCy} onClick={handleClick}>
      <CenteringContainer>
        {showSpinner && <Spinner />}
        <ChildrenContainer data-cy={dataCy} size={size} spinnerVisible={showSpinner}>
          {children}
        </ChildrenContainer>
      </CenteringContainer>
    </Button>
  );
}

const CenteringContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Spinner = styled((props) => <FastSmallSpinner {...props} />)`
  padding: 0;
`;

type ChildrenContainerProps = {
  spinnerVisible: boolean;
  size?: ButtonSize;
};

const ChildrenContainer = styled.div`
  padding-left: ${({ spinnerVisible, size }: ChildrenContainerProps): string =>
    spinnerVisible ? getPadding(size) : '0'};
  line-height: 1;
`;

const getPadding = (size?: ButtonSize): string => {
  return size === 'compact' ? '0.25rem' : '0.5rem';
};
