import Text from 'components/core/controllers/views/Text';
import { css } from 'emotion';
import React from 'react';
import { UsersEmptyState } from 'resources/assets';
import { strings } from 'resources/i18n';
import { atomic } from 'resources/theme';

export default function EmptyState(): JSX.Element {
  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
        align-items: center;
      `}
    >
      <img
        alt={strings.logo.tara}
        className={css`
          height: 6.25rem;
          margin-top: 3rem;
        `}
        src={UsersEmptyState}
      />
      <Text
        className={css`
          font-size: 1rem;
          font-weight: 500;
          color: #708090;
          margin-top: 2.3rem;
        `}
      >
        {strings.users.noInvitesTitle}
      </Text>
      <Text
        className={css`
          font-size: 0.875rem;
          ${atomic.color(atomic.colors.grey7)}
          margin-top: 1rem;
        `}
      >
        {strings.users.noInvitesBody}
      </Text>
    </div>
  );
}
