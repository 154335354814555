import { css, cx } from 'emotion';
import React from 'react';
import { atomic } from 'resources/theme';
/**
 * TableRow does…
 *
 */
export default function TableRow({ className, children, ...props }: React.HTMLProps<HTMLTableRowElement>): JSX.Element {
  return (
    <tr
      className={cx(
        css`
          :hover {
            background-color: #fbfbfd;
            cursor: pointer;
          }
          border: solid 0.0625rem #dee3ec;
          ${atomic.responsive.query({
            nanoTiny: true,
            tinySmall: true,
            smallMedium: true,
          })} {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0.625rem 0rem;
            border: solid 0.0625rem #dee3ec;
          }
        `,
        className,
      )}
      {...props}
    >
      {children}
    </tr>
  );
}
