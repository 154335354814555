/* istanbul ignore file */
import * as firebaseui from 'firebaseui';
import { useEffect } from 'react';
import { firebase } from 'tools/libraries';

export type FirebaseCallback = (data?: unknown) => unknown;

/**
 * Handles user login/logout
 * @deprecated
 */
export const useFirebaseAuth = (
  auth: firebase.auth.Auth,
  widgetInstance: firebaseui.auth.AuthUI,
  callbacks: {
    onLoggedIn: FirebaseCallback;
    onLoggedOut: FirebaseCallback;
  },
): void => {
  useEffect(() => {
    let userSignedIn = false;

    const unregisterAuthObserver = auth.onAuthStateChanged((user: unknown) => {
      if (!user && userSignedIn) widgetInstance.reset();
      userSignedIn = !!user;

      if (userSignedIn) {
        callbacks.onLoggedIn({ uiWidget: widgetInstance, user });
      } else if (!userSignedIn) {
        callbacks.onLoggedOut({ uiWidget: widgetInstance, user });
      }
    });

    return (): void => {
      unregisterAuthObserver();
    };
  });
};

/**
 * Handles mounting/unmounting of FirebaseUI component
 * @deprecated
 */
export const useFirebaseUI = (
  instance: firebaseui.auth.AuthUI,
  id: string,
  config: firebaseui.auth.Config,
  callbacks: {
    onMounted: FirebaseCallback;
    onUnmounted: FirebaseCallback;
  },
): void => {
  useEffect(() => {
    if (config.signInFlow === 'popup') instance.reset();

    instance.start(`#${id}`, config);
    callbacks.onMounted({ uiWidget: instance });

    return (): void => {
      callbacks.onUnmounted();
      instance.reset();
    };
  });
};
