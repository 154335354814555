import { createSlice } from '@reduxjs/toolkit';
import { Data } from '@taraai/types';
// import { useDispatch, useSelector } from 'react-redux';
// import { getNextIdFromTaskList } from 'tools/utils/getNextIdFromTaskList';

const slice = createSlice({
  name: 'tasks',
  initialState: {
    nextTaskId: null,
  },
  reducers: {
    setNextTaskId: (state, action): void => {
      state.nextTaskId = action.payload;
    },
  },
});

export const tasksReducer = slice.reducer;

export const { setNextTaskId } = slice.actions;

export const selectNextTaskId = (state: { tasks: { nextTaskId: string | null } }): string | null =>
  state.tasks.nextTaskId;

export const useSetNextTaskId = (taskIds: Data.Id.TaskId[], selectedTaskId: Data.Id.TaskId | undefined): void => {
  // const dispatch = useDispatch();
  // const nextTaskId = useSelector(selectNextTaskId);

  if (!taskIds) return;
  const selectedTask = taskIds.includes(selectedTaskId || '');
  // eslint-disable-next-line no-useless-return
  if (!selectedTask) return;

  // const newNextTaskId = getNextIdFromTaskList(tasks, selectedTask.id);

  /**
   * BUG(Task-4517)
   *
   * This crashes the app when clicking on a task in the sprint.
   * Will disable the select next on delete feature until we have time
   * for a better fix. - @Bobby
   * */
  // if (newNextTaskId !== nextTaskId) dispatch(setNextTaskId(newNextTaskId));
};
