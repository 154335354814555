"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBranchNameFromCommitRef = void 0;
function getBranchNameFromCommitRef(ref) {
    var branchPrefix = 'refs/heads/';
    if (!ref.startsWith(branchPrefix)) {
        return null;
    }
    return ref.substring(branchPrefix.length);
}
exports.getBranchNameFromCommitRef = getBranchNameFromCommitRef;
