import { createSelector } from '@reduxjs/toolkit';
import { Timestamp } from '@taraai/types';
import { UISprint } from '@taraai/types/dist/ui';
import { isNonEmptyString } from '@taraai/utility';
import { createStandardSelector, inertQuery, Query } from 'reduxStore/utils/selectors';
import { ReduxFirestoreQuery } from 'reduxStore/utils/types';

/**
 * Returns a timed-window of sprints around a timestamp
 * if no window is defined, no results are returned
 *
 * @param orgId
 * @param teamId
 * @param pivotEndDate
 * @param options
 */
function queryBuilder(
  orgId?: string,
  teamId?: string,
  pivotEndDate?: Timestamp,
  options?: { limitBefore?: number; limitAfter?: number },
): Query<UISprint> {
  if (!isNonEmptyString(orgId) || !isNonEmptyString(teamId) || pivotEndDate === undefined) {
    return inertQuery();
  }

  const baseQuery = {
    collection: `orgs/${orgId}/sprints`,
    where: [
      ['teamId', '==', teamId],
      ['archived', '==', false],
      ['deleted', '==', false],
    ],
  };

  // We are compositing the queries we have to perform based on the limits requested.
  // Only in the case we want before and after we are going to perform 2 queries.
  // Otherwise we perform one, or none.
  let firstLimit;
  let secondLimit; // Only used if a second query is required
  if (
    options?.limitBefore !== undefined &&
    options?.limitBefore !== 0 &&
    options?.limitAfter !== undefined &&
    options?.limitAfter !== 0
  ) {
    // Return a window with elements before and after the pivot:
    //
    // []-[]-[ pivot ]-[]-[]
    // [-------------]+[---]
    firstLimit = { startAt: pivotEndDate, limit: options.limitBefore + 1, orderBy: ['endDate', 'desc'] };
    secondLimit = { startAfter: pivotEndDate, limit: options.limitAfter, orderBy: ['endDate', 'asc'] };
  } else if (options?.limitBefore !== undefined && options?.limitBefore !== 0) {
    // Return elements up to the pivot:
    //
    // []-[]-[ pivot ]-[]-[]
    // [-------------]
    firstLimit = { endAt: pivotEndDate, limit: options.limitBefore + 1, orderBy: ['endDate', 'desc'] };
  } else if (options?.limitAfter !== undefined && options?.limitAfter !== 0) {
    // Return elements from to the pivot:
    //
    // []-[]-[ pivot ]-[]-[]
    //       [-------------]
    firstLimit = { startAt: pivotEndDate, limit: options.limitAfter + 1, orderBy: ['endDate', 'asc'] };
  } else {
    // No limits, no query.
    return inertQuery();
  }

  const query = [
    {
      ...baseQuery,
      ...firstLimit,
      storeAs: `sprint/window/${baseQuery.where}&${firstLimit.startAt}&${firstLimit.limit}-first`,
    } as ReduxFirestoreQuery,
  ];

  if (secondLimit !== undefined) {
    query.push({
      ...baseQuery,
      ...secondLimit,
      storeAs: `sprint/window/${baseQuery.where}&${secondLimit.startAfter}&${secondLimit.limit}-second`,
    } as ReduxFirestoreQuery);
  }
  return {
    query,
    selector: createSelector(
      [createStandardSelector(query[0])].concat(query[1] !== undefined ? createStandardSelector(query[1]) : []),
      (before, after) =>
        mergeBeforeAndAfterQueryResults(
          before as UISprint[] | undefined,
          after as UISprint[] | undefined,
          Boolean(options?.limitBefore),
        ),
    ),
  };
}

function mergeBeforeAndAfterQueryResults(
  firstQueryResults: UISprint[] = [],
  secondQueryResults: UISprint[] = [],
  limitBeforeExists: boolean,
): UISprint[] | undefined {
  if (!limitBeforeExists) {
    // there is no before limit, so the first query is all elements after pivot
    return firstQueryResults as UISprint[];
  }

  return [...Array.from(firstQueryResults).reverse(), ...secondQueryResults];
}

export default function getSprintsWindow(
  orgId?: string,
  teamId?: string,
  pivotEndDate?: Timestamp,
  options?: { limitBefore?: number; limitAfter?: number },
): Query<UISprint> {
  if (!isNonEmptyString(orgId) || !isNonEmptyString(teamId) || options === undefined) {
    return inertQuery();
  }

  return queryBuilder(orgId, teamId, pivotEndDate, options);
}
