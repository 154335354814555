import { unwrapResult } from '@reduxjs/toolkit';
import { TaskStatus, UI } from '@taraai/types';
import SubtaskView from 'components/app/controllers/views/SubtaskView';
import React, { ChangeEventHandler, useCallback } from 'react';
import { useAppDispatch } from 'reduxStore';
import { archiveTask } from 'reduxStore/tasks/actions';
import { updateTask } from 'reduxStore/tasks/actions/update';
import { strings } from 'resources';
import { useToast } from 'tools';

export type TaskFragment = Pick<UI.UITaskCreateChangeset, 'id' | 'title' | 'status'>;
export interface SubtaskControllerProps {
  task: TaskFragment;
  dragRef: React.MutableRefObject<HTMLDivElement | null>;
}

/**
 * SubtaskController
 * controller
 */
export default function SubtaskController({ task, dragRef }: SubtaskControllerProps): JSX.Element {
  const dispatch = useAppDispatch();
  const { addToast } = useToast();

  const handleUpdate = (title: string): void => {
    dispatch(updateTask({ id: task.id, title }))
      .then(unwrapResult)
      .catch(() => addToast({ type: 'error', message: strings.task.failedToUpdateTask }));
  };

  const completeProps = {
    checked: task.status === TaskStatus.Done,
    onChange: useCallback<ChangeEventHandler<HTMLInputElement>>(
      (event) => {
        dispatch(updateTask({ id: task.id, status: event.target.checked ? TaskStatus.Done : TaskStatus.Doing }))
          .then(unwrapResult)
          .catch(() => addToast({ type: 'error', message: strings.task.failedToUpdateTask }));
      },
      [addToast, dispatch, task.id],
    ),
  };

  const deleteCurrentTask = useCallback(() => {
    dispatch(archiveTask({ id: task?.id }))
      .then(unwrapResult)
      .catch(() => addToast({ type: 'error', message: strings.task.failedToArchiveTask }));
  }, [addToast, dispatch, task]);

  return (
    <SubtaskView
      completeProps={completeProps}
      deleteCurrentTask={deleteCurrentTask}
      dragRef={dragRef}
      handleUpdate={handleUpdate}
      subtaskTitle={task.title}
    />
  );
}
