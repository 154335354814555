import { AsyncThunkAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Functions, Schema } from '@taraai/types';
import { Options } from 'ajv';

import { decode } from './decoders';
import { ExtraAPI } from './types';

type CloudFunctionActionParams = {
  typePrefix: string;
  cloudFunctionName: string;
  responseDecoder: keyof Schema.TaraValidation;
  decodingOptions?: Options;
};

type CloudFunctionActionResult<R> = AsyncThunkAction<R, unknown, Record<string, unknown>>;

type CloudFunctionActionType<P, R> = (payload: P) => CloudFunctionActionResult<R>;

/**
 *
 * Creates AsyncThunk for cloud function call with response decoding
 */
export function createCloudFunctionAction<P, R>({
  typePrefix,
  cloudFunctionName,
  responseDecoder,
  decodingOptions,
}: CloudFunctionActionParams): CloudFunctionActionType<P, R> {
  return createAsyncThunk(typePrefix, async (payload: P, { extra }): Promise<R> => {
    const { getFirebase } = extra as ExtraAPI;

    const response = await getFirebase().functions().httpsCallable(cloudFunctionName)(payload);

    return Functions.unwrap(decode<Functions.Result<R>>(response.data, responseDecoder, decodingOptions));
  });
}
