import { Box, Fluid, HStack, Text } from '@taraai/design-system';
import { useFirestoreConnect } from '@taraai/read-write';
import { Data } from '@taraai/types';
import { UIImport } from '@taraai/types/dist/ui';
import { hasImportFailed, hasImportFinished } from 'components/app/controllers/WorkDrawerImportToastController/guards';
import { PendingActionToast } from 'components/app/PendingActionToast';
import { linkTo } from 'components/Router/paths';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectActiveTeam, selectActiveWorkspace, selectAuth, selectPreferredTeamId } from 'reduxStore';
import { getImports } from 'reduxStore/github-import/queries';
import { strings } from 'resources';

export function GithubImportToastController(): JSX.Element | null {
  const orgId = useSelector(selectActiveWorkspace);
  const teamId = useSelector(selectActiveTeam);
  const history = useHistory();
  const { uid } = useSelector(selectAuth);
  const preferredTeamId = useSelector(selectPreferredTeamId(orgId));
  const githubImportsSlice = getImports(orgId, 'github-api', uid);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const userImports = useSelector(githubImportsSlice.selector) ?? [];
  useFirestoreConnect(githubImportsSlice.query);

  const [importId, setImportId] = useState<Data.Id.ImportId | null>(null);

  useEffect(() => {
    // Save a pending import in state to have a reference to it even when it's finished
    const latestPendingImport: UIImport | undefined = userImports.filter(
      (userImport) => !hasImportFinished(userImport),
    )?.[0];
    if (latestPendingImport && latestPendingImport.id !== importId) setImportId(latestPendingImport.id);
  }, [userImports, importId]);

  const importToShow = userImports.find((userImport) => userImport.id === importId);
  if (!importToShow) return null;

  const onTryAgainRequest = (): void => {
    setImportId(null);
    history.push(linkTo('workspace', { orgId, teamId }, '?tab=Integrations'));
  };

  const onViewImportedTasksRequest = (): void => {
    history.push(linkTo('sprints', { orgId, teamId: teamId ?? preferredTeamId }));
  };

  if (hasImportFailed(importToShow)) {
    return (
      <PendingActionToast state='failure'>
        <HStack alignY='center'>
          {strings.importNotificationBar.github.errorCopy}
          <Fluid />
          <Box.Button onClick={onTryAgainRequest}>{strings.importNotificationBar.defaultTryAgainCopy}</Box.Button>
        </HStack>
      </PendingActionToast>
    );
  }

  if (!hasImportFinished(importToShow)) {
    return (
      <PendingActionToast state='loading'>
        <HStack alignY='center'>
          <Fluid>{strings.importNotificationBar.github.importInProgressCopy}</Fluid>
        </HStack>
      </PendingActionToast>
    );
  }

  return (
    <PendingActionToast state='success'>
      <HStack alignY='center'>
        {strings.formatString(
          strings.importNotificationBar.github.issuesImportedCopy,
          <Text size='$10px' weight='medium'>
            {importToShow.totalItemsCount}
          </Text>,
        )}
        <Fluid />
        <Box.Button onClick={onViewImportedTasksRequest}>
          {strings.importNotificationBar.defaultImportedTasksLinkLabel}
        </Box.Button>
      </HStack>
    </PendingActionToast>
  );
}
