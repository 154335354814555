import { css } from 'emotion';
import React, { useEffect, useRef } from 'react';
import { useDrag } from 'react-dnd';
import { useElementBoundingRect } from 'tools/utils/hooks/useElementBoundingRect';

import { Dropzone } from './Dropzone';

type DragElementProps = {
  id: string;
  type: string;
  listElement: (ref: React.MutableRefObject<HTMLDivElement | null>) => JSX.Element;
  dropzonePlaceholder: (show: boolean) => JSX.Element;
  onDragStart: () => void;
  onDragEnd: () => void;
  onDrop: (elementId: string) => void;
  showDropzone: boolean;
};

export const DragElement: React.FC<DragElementProps> = ({
  id,
  type,
  listElement,
  dropzonePlaceholder,
  showDropzone,
  onDragStart,
  onDragEnd,
  onDrop,
}) => {
  const innerRef = useRef<HTMLDivElement>(null);
  const [{ isDragging }, dragRef] = useDrag({
    item: { id, type },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // This is used instead of `begin` and `end` callbacks in `useDrag` hook,
  // to prevent lag from breaking drag&drop (monitor.isDragging() returning
  // true one render after `begin()` runs, preventing drag action)
  useEffect(() => {
    if (isDragging) {
      onDragStart();
    } else {
      onDragEnd();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging]);

  const innerDimensions = useElementBoundingRect(innerRef);

  return (
    <div
      className={css`
        position: relative;
      `}
    >
      {showDropzone && !isDragging && (
        <Dropzone
          acceptType={type}
          height={innerDimensions.height}
          onElementDropped={onDrop}
          placeholder={dropzonePlaceholder}
          width={innerDimensions.width}
        />
      )}
      <div ref={dragRef}>{!isDragging && listElement(innerRef)}</div>
    </div>
  );
};
