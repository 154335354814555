import { css, cx } from 'emotion';
import React, { ReactNode } from 'react';

export interface DropdownProps {
  children: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
}

/**
 * Dropdown
 * dropdown that supports search and buttons
 *
 */
export default function Dropdown({ children, header, footer }: DropdownProps): JSX.Element {
  return (
    <div
      className={cx(
        css`
          background-color: #ffffff;
          width: 18.75rem;
          border-radius: 0.1875rem;
          border: solid 0.0625rem #c8d0df;
          box-shadow: 0 0 0.625rem 0 rgba(0, 0, 0, 0.14);
        `,
      )}
    >
      <div>{header}</div>
      <div
        className={cx(
          css`
            max-height: 15.625rem;
            overflow: auto;
          `,
        )}
      >
        {children}
      </div>
      <div>{footer}</div>
    </div>
  );
}
