import { useFirestoreConnect } from '@taraai/read-write';
import { Data, UI } from '@taraai/types';
import React, { useCallback, useMemo } from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import {
  getLastTaskComments,
  getTask,
  getTaskGitData,
  getTaskRevision,
  getUserFragment,
  selectActiveUsers,
  selectOrganization,
  selectTaskList,
  taskSubTasks,
  useAppDispatch,
  UserFragment,
} from 'reduxStore';
import { openTaskModal } from 'reduxStore/modals/actions';

import { TaskDetailsView } from './TaskDetailsView';

type Props = {
  orgId: Data.Id.OrganizationId;
  showSummary?: boolean;
  taskDeleteCallback: () => void;
  taskId: Data.Id.TaskId;
  teamId: Data.Id.TeamId;
};

export const TaskDetails = React.memo(function TaskDetails({
  orgId,
  showSummary,
  taskDeleteCallback,
  taskId,
  teamId,
}: Props): JSX.Element {
  const taskSlice = getTask(orgId, taskId);
  const taskGitDataSlice = getTaskGitData(orgId, taskId);
  const subTasksSlice = taskSubTasks(orgId, taskId, { orderBy: 'createdAt', reverseOrder: true });
  const taskCommentsSlice = getLastTaskComments(orgId, taskId);
  const taskRevisionsSlice = getTaskRevision(orgId, taskId);
  useFirestoreConnect([
    ...taskSlice.query,
    ...taskGitDataSlice.query,
    ...subTasksSlice.query,
    ...taskCommentsSlice.query,
    ...taskRevisionsSlice.query,
  ]);

  const org = useSelector(selectOrganization(orgId));
  const gitData = useSelector(taskGitDataSlice.selector);

  const subtasksData = useSelector((state) =>
    selectTaskList(state, subTasksSlice.query[0]?.storeAs, ['id', 'status', 'title', 'assignee']),
  );

  const comments = useSelector(taskCommentsSlice.selector);
  const users: UserFragment[] = useSelector(compose(getUserFragment, selectActiveUsers(orgId)), deepEquals);
  const subtasksIds = useMemo(
    () => (subtasksData ?? []).map((subtask: SubtaskFragment) => ({ id: subtask.id })),
    [subtasksData],
  );
  const dispatch = useAppDispatch();
  const handleViewModal = useCallback(
    (): { payload: string; type: string } => dispatch(openTaskModal(taskId)),
    [dispatch, taskId],
  );

  return (
    <TaskDetailsView
      comments={comments}
      effortUnit={org.effortUnit}
      gitData={gitData}
      handleViewModal={handleViewModal}
      orgId={orgId}
      showSummary={showSummary}
      subtasksData={subtasksData}
      subtasksIds={subtasksIds}
      taskDeleteCallback={taskDeleteCallback}
      taskId={taskId}
      teamId={teamId}
      users={users}
    />
  );
});

export type SubtaskFragment = Pick<UI.UITask, 'id' | 'status' | 'title' | 'assignee'>;
