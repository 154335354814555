import styled from '@emotion/styled';
import { Data, UI } from '@taraai/types';
import { AddTeamMemberRow } from 'components/app/controllers/views/AddTeamMemberRow';
import Table from 'components/core/controllers/Table';
import Avatar, { AvatarProps } from 'components/core/controllers/views/Avatar';
import SpinnerButton from 'components/core/controllers/views/SpinnerButton';
import { linkTo } from 'components/Router/paths';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { selectActiveUsers, selectTeamMembers } from 'reduxStore';
import { teamDetailsTestIds } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';

type UserFragment = Pick<UI.UIUser, 'id' | 'name' | 'avatarURL'>;

interface Props {
  canProfileLeave: boolean;
  joinTeam: (user: UserFragment) => void;
  leaveTeam: (user: UserFragment) => void;
  /** memberIds override team members; this is for creating a team */
  memberIds?: string[];
  orgId: Data.Id.OrganizationId;
  profileId: Data.Id.UserId;
  /** If teamId is undefined, a team is being created */
  teamId?: string;
  teamName: string;
}

/**
 * MemberListView
 * The view for MemberListController
 *
 */
export function MemberListView({
  canProfileLeave,
  joinTeam,
  leaveTeam,
  memberIds,
  orgId,
  profileId,
  teamId,
  teamName,
}: Props): JSX.Element {
  const users = useSelector(
    compose((data) => data.map(({ id, name, avatarURL }) => ({ id, name, avatarURL })), selectActiveUsers(orgId)),
  ) as UserFragment[];
  const members = useSelector(
    teamId ? selectTeamMembers(orgId, teamId) : () => users.filter((user) => memberIds?.includes(user.id)),
  );
  const isProfileATeamMember = members.some((user) => user.id === profileId);
  const tableData = members.map((user) => ({
    id: user.id,
    name: (
      <TableCell>
        <MemberAvatar size='medium' user={user} />
        {user.name}
      </TableCell>
    ),
    button: (
      <RemoveButton
        color='ghost'
        dataCy={teamDetailsTestIds.REMOVE_MEMBER_BUTTON}
        disabled={user.id === profileId && (!canProfileLeave || !teamId)}
        name={`leaveTeam-${user.id}`}
        onClick={() => leaveTeam(user)}
        size='small'
        type='button'
      >
        {strings.memberManager.remove}
      </RemoveButton>
    ),
  }));

  return (
    <div>
      <Header>
        <Text>
          {strings.formatString(strings.memberManager.count, {
            count: members.length,
          })}
        </Text>
        {canProfileLeave && teamId && isProfileATeamMember && (
          <LeaveLink data-cy={teamDetailsTestIds.LEAVE_TEAM_MODAL_OPEN} to={linkTo('leaveTeam', { orgId, teamId })}>
            {strings.teamDetails.leaveTeam}
          </LeaveLink>
        )}
      </Header>
      <Table
        data={tableData}
        headers={{
          name: {
            name: strings.memberManager.name,
            width: '70%',
          },
          // TODO: [Multi-team] - display user role in column
          // role: { name: strings.memberManager.role, width: '25%' },
          button: { name: '', width: '5%' },
        }}
        topRow={
          <AddTeamMemberRow
            joinTeam={joinTeam}
            leaveTeam={leaveTeam}
            options={users}
            selection={members}
            teamName={teamName}
          />
        }
      />
    </div>
  );
}

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const Text = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #67728b;
`;

const TableCell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const MemberAvatar = styled((props: AvatarProps) => <Avatar {...props} />)`
  margin-right: 0.7rem;
`;

const LeaveLink = styled((props) => <Link {...props}>{strings.teamDetails.leaveTeam}</Link>)`
  color: #e86262;
  font-size: 0.875rem;
  text-decoration: none;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background-color: #e86262;
    transform: scaleX(0);
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }

  &:hover::before,
  &:focus::before {
    opacity: 1;
    transform: scaleX(1);
  }

  &:focus::before {
    transform: scaleX(1.1);
  }
`;

const RemoveButton = styled((props) => <SpinnerButton dataCy={teamDetailsTestIds.REMOVE_MEMBER_BUTTON} {...props} />)`
  padding: 0.25rem 0.5rem;
`;
