import { Box, Text } from '@taraai/design-system';
import { UI } from '@taraai/types';
import React from 'react';
import { strings } from 'resources';
import { taskDetailsTestIds } from 'resources/cypress/testAttributesValues';

import { RevisionEntry } from './RevisionEntry';

type Props = {
  revisions: UI.UITaskRevision[];
};

export const Activity = ({ revisions }: Props): JSX.Element => (
  <Box spaceTop='$12px'>
    {revisions
      .slice(-5)
      .reverse()
      .map((revision: UI.UITaskRevision, idx) => (
        <Box key={revision.id ?? idx} spaceBottom='$12px'>
          <RevisionEntry data-cy={taskDetailsTestIds.REVISION_ENTRY} revision={revision} />
        </Box>
      ))}
    {!revisions.length && (
      <Box background='$grey1' border='$grey2' borderRadius='$4px' center spaceVert='$12px'>
        <Text color='$grey6' data-cy={taskDetailsTestIds.NO_ACTIVITY} size='$12px'>
          {strings.revisions.noActivity}
        </Text>
      </Box>
    )}
  </Box>
);
