import { VStack } from '@taraai/design-system';
import { SubtaskFragment } from 'components/app/TaskDetails/TaskDetails';
import React from 'react';

import { SubtaskItem } from './SubtaskItem';

export function SubtasksList({ subtasks }: { subtasks: SubtaskFragment[] }): JSX.Element {
  return (
    <VStack space='$4px'>
      {subtasks.map((subtask) => (
        <SubtaskItem key={subtask.id} subtask={subtask} />
      ))}
    </VStack>
  );
}
