import { TaskStatus } from '@taraai/types';
import { css, cx } from 'emotion';
import React, { useMemo } from 'react';

export interface EffortStatusProps extends React.HTMLProps<HTMLDivElement> {
  effortLevel: number;

  status: TaskStatus;
}

/**
 * A short description for EffortStatus.
 *
 */
export default function EffortStatus({ effortLevel, status, className, ...props }: EffortStatusProps): JSX.Element {
  /**
   * Colors need to be set in a better way. Currently using an array
   * To get status colors based on this specific order.
   */

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const statusBackgroundColors = ['#eef1f7', '#ddf0ff', '#ddf4d9'];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const statusFontColors = ['#67728b', '#1d98ff', '#389e0d'];

  return useMemo(
    () => (
      <div
        className={cx(
          css`
            background-color: ${statusBackgroundColors[status]};
            color: ${statusFontColors[status]};
            padding: 0.125rem 0.375rem;
            text-align: center;
            border-radius: 0.1875rem;
            display: inline-block;
            text-transform: uppercase;
            font-size: 0.875rem;
          `,
          className,
        )}
        {...props}
      >
        {effortLevel}
      </div>
    ),
    [className, effortLevel, props, status, statusBackgroundColors, statusFontColors],
  );
}
