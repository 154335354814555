import { UI } from '@taraai/types';
import { getFilterByNameFn } from 'components/app/controllers/Selectors/common/filterFn';
import IconGroup from 'components/app/controllers/Selectors/common/IconGroup';
import SearchHeader from 'components/app/controllers/Selectors/common/SearchHeader';
import { CreateTeamButton } from 'components/app/controllers/Selectors/TeamSwitcher/CreateTeamButton';
import { TeamOption } from 'components/app/controllers/Selectors/TeamSwitcher/TeamOption';
import { OptionRenderProps, SectionType, Selector } from 'components/core/controllers/Selector';
import { SelectorPosition } from 'components/core/controllers/Selector/types';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getOrgTeams, selectActiveWorkspace } from 'reduxStore';
import { strings } from 'resources';
import { teamSwitcherTestIds } from 'resources/cypress/testAttributesValues';

type Section = SectionType<UI.UITeam>;
type TeamFragment = Pick<UI.UITeam, 'id' | 'name'>;

type Props = {
  assignedTeams: TeamFragment[];
  linkToCreateTeam?: string;
  onSelectOption?: (teamId: TeamFragment) => void;
  onDeselectOption?: (teamId: TeamFragment) => void;
  selectorPosition?: SelectorPosition;
  disablePopup?: boolean;
};

const filterFn = getFilterByNameFn<Section, UI.UITeam>();

/**
 * TeamSelector is an UI component build on top of the Selector to handle selecting teams.
 *
 * It supports:
 * - multiple and single selection of team by mouse clicking and keyboard navigation
 * - searching teams by name
 * - displaying team names and avatars in dropdown
 */
export function TeamSelector({ assignedTeams, linkToCreateTeam, disablePopup = false, ...props }: Props): JSX.Element {
  const history = useHistory();
  const orgId = useSelector(selectActiveWorkspace);

  const teams = useSelector(getOrgTeams(orgId).selector) || [];

  const goToCreateTeam = useCallback(
    (onClose: (event: React.SyntheticEvent) => void) =>
      (event: React.SyntheticEvent): void => {
        onClose(event);
        linkToCreateTeam && history.push(linkToCreateTeam);
      },
    [history, linkToCreateTeam],
  );

  const assignedTeamElements = assignedTeams.map((team) => ({
    id: team.id,
    name: team.name,
    tooltip: team.name,
  }));

  const section: SectionType<TeamFragment> = {
    id: 'teams',
    options: teams,
  };

  return (
    <Selector
      disablePopup={disablePopup}
      filterFn={filterFn}
      renderFooter={({ onClose }): JSX.Element => (
        <CreateTeamButton data-cy={teamSwitcherTestIds.CREATE_TEAM_BUTTON} onClick={goToCreateTeam(onClose)} />
      )}
      renderHeader={(headerProps): JSX.Element => (
        <SearchHeader
          searchPlaceholder={strings.teams.selector.searchPlaceholder}
          showSearch
          title={strings.teams.selector.header}
          {...headerProps}
        />
      )}
      renderOption={({ option, ...optionProps }: OptionRenderProps<TeamFragment>): JSX.Element => (
        <TeamOption team={option} {...optionProps} />
      )}
      renderSelectButton={({ openPopup }) => (
        <IconGroup elements={assignedTeamElements} openPopup={disablePopup ? null : openPopup} />
      )}
      sections={[section] as Section[]}
      selection={assignedTeams}
      {...props}
    />
  );
}
