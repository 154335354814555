import styled from '@emotion/styled';
import { HeaderRenderProps } from 'components/core/controllers/Selector/types';
import React, { useEffect, useRef } from 'react';

type SelectorPopupHeaderProps = {
  searchInputValue: string;
  render?: (props: HeaderRenderProps) => JSX.Element;
  onClose: (event: React.SyntheticEvent) => void;
  onSearchInputChange: (pattern: string) => void;
  onSearchInputKeyDown: (event: React.KeyboardEvent) => void;
};

export function SelectorPopupHeader({
  searchInputValue,
  render,
  onClose,
  onSearchInputChange,
  onSearchInputKeyDown,
}: SelectorPopupHeaderProps): JSX.Element | null {
  const searchInputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    } else {
      containerRef.current?.focus();
    }
  }, []);

  return (
    <Container>
      {/* InvisibleInput handle keyboard events when search is disabled */}
      <InvisibleInput ref={containerRef} onKeyDown={onSearchInputKeyDown} />
      {render?.({
        searchInputRef,
        onClose,
        onSearchInputChange,
        onSearchInputKeyDown,
        searchInputValue,
      })}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  position: relative;
`;

const InvisibleInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  padding: 0;
  margin: 0;
  border: none;
`;
