import { Box, Fluid, HStack, styled, Text } from '@taraai/design-system';
import React from 'react';

import { CheckButton, CrossButton, PencilButton } from './buttons';

const TITLE_LENGTH_MAX = 16;

interface SprintDetailsSidebarNameProps {
  isEditing?: boolean;
  onCancel?: () => void;
  onEdit?: () => void;
  onSubmit?: () => void;
  sprintName: string;
  sprintState?: 'active' | 'completed';
  submitDisabled?: boolean;
}

export function SprintDetailsSidebarName({
  isEditing,
  onCancel,
  onEdit,
  onSubmit,
  sprintName,
  sprintState,
  submitDisabled,
}: SprintDetailsSidebarNameProps): JSX.Element {
  const sprintNameColor = sprintStateToColor[sprintState ?? 'default'];

  return (
    <HStack>
      <Box borderLeft={[sprintNameColor, '$2px']} width='$12px' />
      <Fluid>
        <Box spaceRight='$12px'>
          <HStack alignY='center'>
            <Fluid>
              <StyledText color={sprintNameColor} singleLine size='$12px' weight='medium'>
                {sprintName}
              </StyledText>
            </Fluid>
            <HStack alignY='center' space='$8px'>
              {isEditing ? (
                <HStack alignY='center' space='$4px'>
                  <CrossButton onClick={onCancel} />
                  <CheckButton disabled={submitDisabled} onClick={onSubmit} />
                </HStack>
              ) : (
                <PencilButton onClick={onEdit} />
              )}
            </HStack>
          </HStack>
        </Box>
      </Fluid>
    </HStack>
  );
}

const sprintStateToColor = {
  active: '$indigo',
  completed: '$success',
  default: '$grey7',
} as const;

const StyledText = styled(Text, {
  maxWidth: `${TITLE_LENGTH_MAX}ch`,
});
