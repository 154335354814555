/* eslint-disable sonarjs/no-duplicate-string */

import { styled } from '@taraai/design-system';
import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  title?: string;
}

/**
 * A text that renders without any styles, but with an accent color.
 * Used eg. for labels and mentions.
 */
export function AccentedText({ children, title: description }: Props): JSX.Element {
  return <Wrapper title={description}>{children}</Wrapper>;
}

const Wrapper = styled('span', {
  'color': '$sapphire',
  'fontStyle': 'normal',
  'fontWeight': '$regular',
  'textDecoration': 'none',
  '& *': {
    color: 'inherit !important',
    fontSize: 'inherit !important',
    fontStyle: 'inherit !important',
    fontWeight: 'inherit !important',
    textDecoration: 'inherit !important',
  },
});
