import { Schema } from '@taraai/types';
import { noop } from '@taraai/utility';
import { Toast } from 'components/core/controllers/views/Toast';
import constant from 'lodash.constant';
import identity from 'lodash.identity';
import React, { ComponentProps } from 'react';

export type AddToastFn = (options: {
  addOnTop?: boolean;
  id?: string;
  message?: string | JSX.Element;
  timeoutMs?: number;
  type?: ComponentProps<typeof Toast>['color'];
  data?: unknown;
  taraType?: keyof Schema.TaraValidation;
}) => void;
export interface ToastContextType {
  whenError: (str: ((error: Error) => string) | string) => (error: Error) => void;
  whenSuccess: <Data>(str: ((data: Data) => string) | string) => (data: Data) => Data;
  addToast: AddToastFn;
  removeToast: (id: string) => void;
  maybeToast: (
    data: unknown,
    type: keyof Schema.TaraValidation,
    onValid?: <T extends unknown>(data: T) => void,
  ) => void;
}

const ToastContext = React.createContext<ToastContextType>({
  whenError: () => constant(undefined),
  whenSuccess: () => identity,
  addToast: noop,
  removeToast: noop,
  maybeToast: noop,
});

export default ToastContext;
