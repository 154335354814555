import { isLoaded } from '@taraai/read-write';
import { Data } from '@taraai/types';
import HomeLayout from 'components/app/layouts/HomeLayout';
import { usePathParams } from 'components/Router/paths';
import React from 'react';
import { useSelector } from 'react-redux';
import { hasFeature, selectPreferredTeamId, selectProfile, selectTeam, selectUserTeams } from 'reduxStore';
import { selectOrganization } from 'reduxStore/organization/selectors';

/**
 * HomeController contains high level data for that is used throughout the home page
 *
 */
export default function HomeController(): JSX.Element | null {
  const { orgId } = usePathParams('home');
  const currentOrg = useSelector(selectOrganization(orgId));
  const preferredTeamId = useSelector(selectPreferredTeamId(orgId));
  const preferredTeam = useSelector(selectTeam(orgId, preferredTeamId));
  const isGitlabInsightsEnabled = useSelector(hasFeature('gitlabInsights', orgId));

  const userTeams = useSelector(selectUserTeams(orgId));
  const { name } = useSelector(selectProfile);

  if (!userTeams) {
    return null;
  }

  if (!isLoaded(preferredTeam)) {
    return null;
  }

  const allTeamsWithActiveSprints = userTeams.filter((team) => team.currentSprintId !== null);

  const otherTeamsWithActiveSprints = allTeamsWithActiveSprints.filter((team) => team.id !== preferredTeamId);

  const allTeamsWithActiveSprintsOrdered = [preferredTeam, ...otherTeamsWithActiveSprints];

  const activeSprintIds = allTeamsWithActiveSprints.map((team) => team.currentSprintId) as Data.Id.SprintId[];

  return (
    <HomeLayout
      activeSprintIds={activeSprintIds}
      allTeamsWithActiveSprintsOrdered={allTeamsWithActiveSprintsOrdered}
      isGitlabInsightsEnabled={isGitlabInsightsEnabled}
      name={name}
      org={{ id: orgId, ...currentOrg }}
    />
  );
}
