import { compose, unwrapResult } from '@reduxjs/toolkit';
import { isLoaded } from '@taraai/read-write';
import { Data, UI } from '@taraai/types';
import IconGroup from 'components/app/controllers/Selectors/common/IconGroup';
import { UserSelector } from 'components/app/controllers/Selectors/UserSelector';
import { SectionType } from 'components/core/controllers/Selector';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectActiveUsers, selectTeam, selectTeamMembers, updateTeamMembership, useAppDispatch } from 'reduxStore';
import { strings } from 'resources/i18n';
import { useToast } from 'tools';

type UserFragment = Pick<UI.UIUser, 'id' | 'name' | 'avatarURL'>;

type Props = {
  orgId: Data.Id.OrganizationId;
  teamId: Data.Id.TeamId;
};

/**
 * TeamMemberSelector allows to select members of given team
 */
export function TeamMemberSelector({ orgId, teamId }: Props): JSX.Element | null {
  const dispatch = useAppDispatch();
  const { whenSuccess, whenError } = useToast();

  const team = useSelector(selectTeam(orgId, teamId));

  const orgUsers = useSelector(
    compose((data) => data.map(({ id, name, avatarURL }) => ({ id, name, avatarURL })), selectActiveUsers(orgId)),
  );

  const teamMembers = useSelector(selectTeamMembers(orgId, teamId));

  if (!isLoaded(teamMembers) || !isLoaded(team)) {
    return null;
  }

  const userElements = teamMembers.map((user) => ({
    id: user.id,
    name: user.name,
    tooltip: user.name,
    url: user.avatarURL,
  }));

  const section: SectionType<UserFragment> = {
    id: teamId,
    options: orgUsers,
  };

  const addUserToTeam = (user: UserFragment): void => {
    dispatch(
      updateTeamMembership({
        action: 'add',
        teamId,
        userIds: [user.id],
      }),
    )
      .then(unwrapResult)
      .then(
        whenSuccess(
          strings.formatString(strings.memberManager.addMember.success, {
            username: user.name,
            teamName: team.name,
          }) as string,
        ),
      )
      .catch(whenError(({ message }) => message));
  };

  const removeUserFromTeam = (user: UserFragment): void => {
    dispatch(
      updateTeamMembership({
        action: 'remove',
        teamId,
        userIds: [user.id],
      }),
    )
      .then(unwrapResult)
      .then(() =>
        whenSuccess(
          strings.formatString(strings.memberManager.removeMember.success, {
            username: user.name,
            teamName: team.name,
          }) as string,
        ),
      )
      .catch(whenError(({ message }) => message));
  };

  return (
    <UserSelector
      closePopupOnSelection
      headerTitle={
        strings.formatString(strings.teamMemberSelector.addMembers, {
          teamName: team.name,
        }) as string
      }
      onDeselectOption={removeUserFromTeam}
      onSelectOption={addUserToTeam}
      renderSelectButton={(props): JSX.Element => <IconGroup elements={userElements} {...props} />}
      searchPlaceholder={strings.teamMemberSelector.searchUser}
      sections={[section]}
      selection={teamMembers}
    />
  );
}
