import { UI } from '@taraai/types';
import { isNonEmptyString } from '@taraai/utility';
import { createStandardSelector, inertQuery, Query } from 'reduxStore/utils/selectors';
import { ReduxFirestoreQuery } from 'reduxStore/utils/types';

export function getOrgInvitations(orgId: string): Query<UI.UIInvitation> {
  if (!isNonEmptyString(orgId)) {
    return inertQuery();
  }
  const query: ReduxFirestoreQuery = {
    collection: `orgs/${orgId}/invitations`,
    where: [['status', '==', 'sent']],
    storeAs: `invitations/${orgId}/open`,
  };

  return {
    query: [query],
    selector: createStandardSelector<UI.UIInvitation>(query),
  };
}

export function getReusableInvitation(orgId: string, userId: string): Query<UI.UIInvitation> {
  if (!isNonEmptyString(orgId) || !isNonEmptyString(userId)) {
    return inertQuery();
  }

  const query: ReduxFirestoreQuery = {
    collection: `orgs/${orgId}/invitations`,
    where: [
      ['status', '==', 'reusable'],
      ['inviterId', '==', userId],
    ],
    storeAs: `invitations/${orgId}`,
    orderBy: [['expiredAt', 'desc']],
    limit: 1,
  };

  return {
    query: [query],
    selector: createStandardSelector(query),
  };
}
