import { Box, Text } from '@taraai/design-system';
import { Data } from '@taraai/types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectActiveTeam, selectActiveWorkspace } from 'reduxStore';
import { strings } from 'resources/i18n';

interface Props {
  id: Data.Id.RequirementId;
  type: 'requirements' | 'tasks';
  style?: 'focus' | 'white';
}

/**
 * Share Button to copy link of current page
 */
export function CopyShare({ id, type, style }: Props): JSX.Element {
  const [copiedText, setCopiedText] = useState(false);
  const orgId = useSelector(selectActiveWorkspace);
  const teamId = useSelector(selectActiveTeam);

  const copyId = (): void => {
    navigator.clipboard.writeText(`${window.location.origin}/${orgId}/${teamId}/define/${type}/${id}`);
    setCopiedText(true);
  };

  return (
    <Box.Button
      background={style === 'white' ? '$white' : '$focus'}
      border={style === 'white' ? '$grey7' : '$white'}
      borderRadius='$4px'
      center
      height='$24px'
      onClick={copyId}
      onMouseEnter={(): void => setCopiedText(false)}
      spaceHorz='$8px'
      spaceVert='$2px'
    >
      <Text color={style === 'white' ? '$grey7' : '$white'} size='$10px' weight='medium'>
        {copiedText ? strings.task.copied : strings.task.copyLink}
      </Text>
    </Box.Button>
  );
}
