"use strict";
/* eslint-disable no-bitwise */
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeStringToValidID = void 0;
var encodeStringToValidID = function (input) {
    var bufferEncoding = input.split('').flatMap(function (character) {
        var code = character.charCodeAt(0);
        return [code & 0xff, (code / 256) >>> 0];
    });
    return Buffer.from(bufferEncoding).toString('base64');
};
exports.encodeStringToValidID = encodeStringToValidID;
