import { HStack, styled, Text } from '@taraai/design-system';
import { Data, UI } from '@taraai/types';
import Button from 'components/core/controllers/views/Button';
import Icon from 'components/core/controllers/views/Icon';
import { linkTo } from 'components/Router/paths';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectActiveTeam } from 'reduxStore';

type Props = {
  children: React.ReactNode;
  organizationId: Data.Id.OrganizationId;
  nextSprintId: Data.Id.SprintId | null;
  previousSprintId: Data.Id.SprintId | null;
  selectedSprint?: UI.UISprint;
};

export const SprintDetailsNavigation = ({
  organizationId,
  nextSprintId,
  previousSprintId,
  selectedSprint,
  children,
}: Props): JSX.Element => {
  const teamId = useSelector(selectActiveTeam);
  const history = useHistory();

  const goToSprint = (sprintId: string): void =>
    history.push(
      linkTo('sprintDetails', {
        orgId: organizationId,
        teamId,
        sprintId,
      }),
    );

  return (
    <HStack alignY='center' space='$16px'>
      <ArrowButton
        color='wireframe'
        disabled={!previousSprintId}
        hide={!previousSprintId}
        name='left'
        onClick={() => previousSprintId && goToSprint(previousSprintId)}
        size='compact'
      >
        <ArrowIcon name='left' noPadding />
      </ArrowButton>
      <Text size='$12px' weight='medium'>
        {children}
      </Text>
      <ArrowButton
        color='wireframe'
        disabled={!nextSprintId}
        hide={!nextSprintId && !selectedSprint?.isComplete}
        name='right'
        onClick={() => goToSprint(nextSprintId || 'current')}
        size='compact'
      >
        <ArrowIcon name='right' noPadding />
      </ArrowButton>
    </HStack>
  );
};

const ArrowButton = styled(Button, {}, { hide: { true: { cursor: 'default', opacity: 0 } } });

const ArrowIcon = styled(Icon, {
  width: '0.75rem',
  height: '0.75rem',
  color: '$grey7',
});
