import { Data } from '@taraai/types';
import React from 'react';

import { SettingsModal } from './SettingsModal';

export type SprintSettingsId = Data.Id.TeamId | Data.Id.SprintId | null;
export type SprintSettingsType =
  | 'individualSprintSettings'
  | 'globalSprintSettings'
  | 'completeSprint'
  | 'deleteSprint'
  | 'individualSprintDates'
  | null;
export interface SprintSettingsContextType {
  isSprintSettingsOpen: boolean;
  handleOpenSprintSettings: () => void;
  handleSprintSettingsType: (type: SprintSettingsType) => void;
  handleSprintSettingId: (id: SprintSettingsId) => void;
}

const SprintSettingsContext = React.createContext<SprintSettingsContextType>({
  isSprintSettingsOpen: false,
  handleOpenSprintSettings: () => undefined,
  handleSprintSettingsType: () => undefined,
  handleSprintSettingId: () => undefined,
});

export function useSprintSettingsModal(): SprintSettingsContextType {
  return React.useContext(SprintSettingsContext);
}

export function SprintSettingsProvider({ children }: { children: React.ReactNode }): null | JSX.Element {
  const [id, setId] = React.useState<SprintSettingsId>(null);
  const [isSprintSettingsOpen, setIsSprintSettingsOpen] = React.useState(false);
  const [sprintSettingsType, setSprintSettingsType] = React.useState<SprintSettingsType>(null);

  const handleOpenSprintSettings = React.useCallback(() => {
    isSprintSettingsOpen && setSprintSettingsType(null);
    setIsSprintSettingsOpen(!isSprintSettingsOpen);
  }, [isSprintSettingsOpen]);

  const handleSprintSettingsType = React.useCallback((type) => {
    setSprintSettingsType(type);
  }, []);

  const handleSprintSettingId = React.useCallback((sprintSettingId) => setId(sprintSettingId), []);

  return (
    <SprintSettingsContext.Provider
      value={{ handleOpenSprintSettings, isSprintSettingsOpen, handleSprintSettingsType, handleSprintSettingId }}
    >
      {isSprintSettingsOpen && (
        <SettingsModal
          handleOpenSprintSettings={handleOpenSprintSettings}
          id={id}
          sprintSettingsType={sprintSettingsType}
        />
      )}
      {children}
    </SprintSettingsContext.Provider>
  );
}
