import { RichEditorPlugin } from 'components/editor/types';

export function createBackspaceDeletePlugin(onDelete?: () => void): RichEditorPlugin<'handleKeyCommand'> {
  return {
    handleKeyCommand(command, editorState) {
      if (command === 'backspace' && editorState.getCurrentContent().getPlainText().length === 0) {
        onDelete?.();
        return 'handled';
      }

      return 'not-handled';
    },
  };
}
