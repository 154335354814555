import { UI } from '@taraai/types';
import { RichEditorPlugin } from 'components/editor/types';

import { labelDecorator } from './decorator';
import { getTextToLabels, labelsToText } from './pipeline';
import { getSelectorDecorator } from './selectorDecorator';

export function createLabelsPlugin({
  createLabel,
  getLabels,
  readOnly,
}: {
  createLabel: (title: string) => void;
  getLabels: () => UI.UILabel[];
  readOnly?: boolean;
}): RichEditorPlugin<'decorator' | 'read' | 'save'> {
  return {
    decorator: [labelDecorator, getSelectorDecorator({ createLabel, getLabels, readOnly })],
    read: getTextToLabels(getLabels),
    save: labelsToText,
  };
}
