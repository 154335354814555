import styled from '@emotion/styled';
import { Data, UI } from '@taraai/types';
import ProfileAvatarController from 'components/app/controllers/ProfileAvatarController';
import Text from 'components/core/controllers/views/Text';
import { css } from 'emotion';
import React from 'react';
import { RootState } from 'reduxStore';
import { strings } from 'resources/i18n';
import { atomic } from 'resources/theme';

import { AuthButtons } from './views/AuthButtons';
import { EditUserData } from './views/EditUserData';
import { UserTeams } from './views/UserTeams';

export interface ProfileLayoutProps {
  authData: RootState['firebase']['auth'];
  nameProps: Record<string, unknown>;
  onLogoutClick: () => void;
  emailResetSent: boolean;
  onPasswordReset: () => void;
  isBasicAuth: boolean;
  teams: UI.UITeam[];
  preferredTeamId: Data.Id.TeamId;
  onSetPreferredTeamId: (teamId: Data.Id.TeamId) => Promise<void>;
}

export default function ProfileLayout({
  authData,
  nameProps,
  onLogoutClick,
  emailResetSent,
  onPasswordReset,
  isBasicAuth,
  teams,
  preferredTeamId,
  onSetPreferredTeamId,
}: ProfileLayoutProps): JSX.Element {
  return (
    <PageContainer>
      <Text
        className={css`
          color: #405161;
          font-size: 1.5rem;
          font-weight: 500;
          line-height: 1.5rem;
        `}
      >
        {strings.profile.profile}
      </Text>
      <ColumnsContainer>
        <ProfileAvatarController />
        <FormContainer>
          <EditUserData authData={authData} nameProps={nameProps} />
          <Separator />
          <UserTeams onSetPreferredTeamId={onSetPreferredTeamId} preferredTeamId={preferredTeamId} teams={teams} />
          <Separator />
          <AuthButtons
            emailResetSent={emailResetSent}
            isBasicAuth={isBasicAuth}
            onLogoutClick={onLogoutClick}
            onPasswordReset={onPasswordReset}
            userEmail={authData.email}
          />
        </FormContainer>
      </ColumnsContainer>
    </PageContainer>
  );
}

const PageContainer = styled.div`
  background-color: ${atomic.get(atomic.colors.grey1)};
  display: flex;
  flex-direction: column;
  padding: 4.5rem 7rem 4.5rem 5rem;
  height: 100%;
  width: 100%;
  overflow: auto;
`;

const ColumnsContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 2.5rem;
`;

const FormContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 2.5rem;
`;

const Separator = styled.hr`
  background-color: #eaeef5;
  height: 1px;
  border: none;
  margin: 1.5rem 0;
`;
