import { Data } from '@taraai/types';
import { addAttachmentSansRedux } from 'firestore';
import { DelayedPromiseCreator, ErrorConstant, invariant } from 'remirror';
import { ImageAttributes } from 'remirror/extensions';

type DelayedImage = DelayedPromiseCreator<ImageAttributes>;
type SetProgress = (progress: number) => void;
export interface FileWithProgress {
  file: File;
  progress: SetProgress;
}

export function imageUploader(
  files: FileWithProgress[],
  path: string,
  id: Data.Id.TaskId | Data.Id.RequirementId,
): DelayedImage[] {
  invariant(files.length > 0, {
    code: ErrorConstant.EXTENSION,
    message: 'The upload handler was applied for the image extension without any valid files',
  });

  const completed = 0;
  const promises: Array<DelayedPromiseCreator<ImageAttributes>> = [];

  // eslint-disable-next-line no-loops/no-loops
  for (const { file, progress } of files) {
    promises.push(() =>
      addAttachmentSansRedux({ path, file, id }).then((src: string) => {
        progress(completed / files.length);
        return { src };
      }),
    );
  }

  return promises;
}
