/* istanbul ignore file */
import { createSelector, Selector } from '@reduxjs/toolkit';
import { UI } from '@taraai/types';
import { RootState } from 'reduxStore/store';
import { Query } from 'reduxStore/utils/selectors';
import { sort } from 'tools/libraries/helpers/sort';

export const selectUserAssignedTasksOrderedByCreatedAt = (
  assignedTaskSlices: Query<UI.UITask>[],
): Selector<RootState, (UI.UITask | undefined)[]> => {
  return createSelector(
    assignedTaskSlices.flatMap(({ selector }) => selector),
    (...tasks) => {
      const cleanTasks = tasks.filter((task) => task !== undefined);
      return (
        sort(
          cleanTasks.flatMap((task) => task),
          'createdAtDesc',
        ) || []
      );
    },
  );
};
