import { css } from 'emotion';
import React from 'react';
import { strings } from 'resources/i18n';

export interface AddSubtaskProps {
  setShowSubtaskInput: (value: boolean) => void;
  focusTask: () => void;
}

/**
 * AddSubtask
 * view for add subtask button
 *
 */
export default function AddSubtask({ setShowSubtaskInput, focusTask }: AddSubtaskProps): JSX.Element {
  return (
    <div
      className={css`
        font-size: 0.875rem;
        font-weight: normal;
        color: #949caf;
        cursor: pointer;
      `}
      data-cy='cy-sub'
      onClick={async (): Promise<void> => {
        await setShowSubtaskInput(true);
        focusTask();
      }}
      onKeyDown={async (): Promise<void> => {
        await setShowSubtaskInput(true);
        focusTask();
      }}
      role='button'
      tabIndex={0}
    >
      {strings.task.addSubtask}
    </div>
  );
}
