import { Box, styled, Text } from '@taraai/design-system';
import { Data, UI } from '@taraai/types';
import DashboardStatistics from 'components/app/controllers/DashboardStatistics';
import DashboardTasks from 'components/app/controllers/DashboardTasks';
import { linkTo } from 'components/Router/paths';
import { css } from 'emotion';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getSubscriptionType, hasFeature, selectActiveTeam } from 'reduxStore';
import { strings } from 'resources';

const media = window.matchMedia('(max-width: 500px)');

export interface DashboardSprintProps {
  orgId: Data.Id.OrganizationId;
  allTeamsWithActiveSprintsOrdered: UI.UITeam[];
  activeSprintIds: Data.Id.SprintId[];
}

/**
 * DashboardSprint contains the views and controllers for
 * all user sprint related information
 *
 */
export default function DashboardSprint({
  orgId,
  allTeamsWithActiveSprintsOrdered,
  activeSprintIds,
}: DashboardSprintProps): JSX.Element {
  const history = useHistory();
  const teamId = useSelector(selectActiveTeam);
  const isEnabled = useSelector(hasFeature('alphaMonetization', orgId));
  const subscriptionType = useSelector(getSubscriptionType(orgId));
  const isPremium = subscriptionType !== null;
  const showUpgradeButton = isEnabled && !isPremium;

  return (
    <div>
      {showUpgradeButton && (
        <PremiumTrialWarningBox background='$grey1' center space='$16px'>
          <StyledText color='$cobalt' size='$14px' textAlign='left'>
            <strong>{strings.upgrade.homeUpgradeBanner.freeUser}, </strong>
            {strings.upgrade.homeUpgradeBanner.freeLimitations}
          </StyledText>

          <UpgradeButton
            border='$cobalt'
            color='$cobalt'
            onClick={() => history.push(linkTo('upgrade', { orgId, teamId }))}
          >
            <span>{strings.upgrade.homeUpgradeBanner.callToAction}</span>
          </UpgradeButton>
        </PremiumTrialWarningBox>
      )}
      <div
        className={css`
          margin-top: 1rem;
          display: flex;
          flex-direction: column;
          border: 0.0625rem solid #dee3ec;
          border-radius: 0.125rem 0.125rem 0rem 0rem;
        `}
      >
        {activeSprintIds.length > 0 && (
          <div
            className={css`
              height: 0.375rem;
              border-radius: 0.125rem 0.125rem 0rem 0rem;
              background-color: #389e0d;
            `}
          />
        )}
        <div
          className={css`
            display: flex;
            flex-direction: ${media.matches ? 'column' : 'row'}; ;
          `}
        >
          {activeSprintIds.length > 0 && (
            <DashboardStatistics
              activeSprintIds={activeSprintIds}
              allTeamsWithActiveSprintsOrdered={allTeamsWithActiveSprintsOrdered}
              orgId={orgId}
            />
          )}
          <DashboardTasks activeSprintIds={activeSprintIds} orgId={orgId} />
        </div>
      </div>
    </div>
  );
}

const PremiumTrialWarningBox = styled(Box, {
  background: 'linear-gradient(135.19deg, rgba(88, 166, 231, 0.15) 0.34%, rgba(119, 93, 250, 0.15) 100%)',
  color: '#0021E2',
  display: 'flex',
  justifyContent: 'space-between',
  paddingLeft: '15px',
  paddingRight: '15px',
  borderRadius: '4px',
});

const UpgradeButton = styled(Box.Button, {
  marginLeft: '96px',
  width: '7.6875rem',
  height: '2rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'transparent',
  borderRadius: '4px',
  fontSize: '$14px',
  textAlign: 'center',
  transition: '0.4s',
});

const StyledText = styled(Text, {
  width: '64%',
});
