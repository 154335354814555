import { createSelector, unwrapResult } from '@reduxjs/toolkit';
import { isLoaded, useFirestoreConnect } from '@taraai/read-write';
import { Data, TaskStatus, UI } from '@taraai/types';
import { MoveTask } from '@taraai/types/dist/ui';
import CompleteSprintModal from 'components/app/controllers/views/CompleteSprintModal';
import { OptionDefinition } from 'components/core/controllers/views/Select/Select';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  getSprint,
  getUpcomingSprints,
  selectActiveTeam,
  selectActiveWorkspace,
  selectTeam,
  useAppDispatch,
} from 'reduxStore';
import { completeSprint } from 'reduxStore/sprints/actions/complete';
import { getCompleteSprintData } from 'reduxStore/sprints/queries/complete-sprint-data';
import { strings } from 'resources/i18n';
import { COMPLETE_AND_CREATE_NEW_SPRINT, useToast } from 'tools';
import { segment } from 'tools/libraries/analytics';

/**
 * CompleteSprintController
 * controller for all complete sprint modal logic
 * This component contains a performance issue as it is using useParams
 * and it is not at the very top of the rendering tree.
 * We are skipping it because it is
 * @deprecated
 */
const CompleteSprintController = (): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const { sprintId: sprintIdParam } = useParams<{
    sprintId: Data.Id.SprintId;
  }>();
  const orgId = useSelector(selectActiveWorkspace);
  const teamId = useSelector(selectActiveTeam);

  const currentTeam = useSelector(selectTeam(orgId, teamId));

  const currentSprintId = useSelector(createSelector([selectTeam(orgId, teamId)], (team) => team?.currentSprintId));

  const sprintId = sprintIdParam === 'current' ? currentSprintId : sprintIdParam;

  const history = useHistory();
  const { addToast } = useToast();
  const [selectedSprint, setSelectedSprint] = useState<OptionDefinition>({
    label: 'None',
    value: null,
  });

  const selectedSprintSlice = getSprint(orgId, sprintId ?? '');

  const upcomingSprintSlice = getUpcomingSprints(orgId, teamId, {
    orderBy: 'sprintNumberDesc',
  });

  const sprintData = useSelector(selectedSprintSlice.selector); // dependency
  const completedSprintSlice = getCompleteSprintData(
    orgId,
    sprintId ?? '',
    sprintData?.startDate ?? null,
    sprintData?.endDate ?? null,
  );

  useFirestoreConnect([...selectedSprintSlice.query, ...completedSprintSlice.query, ...upcomingSprintSlice.query]);

  const upcomingSprints = useSelector(upcomingSprintSlice.selector);
  const completeSprintData = useSelector(completedSprintSlice.selector);

  const handleCompleteSprint = useCallback(async () => {
    const selectedNextSprint = selectedSprint?.value || COMPLETE_AND_CREATE_NEW_SPRINT;
    const nextSprintId = selectedNextSprint === 'Backlog' ? null : selectedNextSprint.toString();

    let moveTask: MoveTask;
    switch (selectedNextSprint) {
      case 'Backlog':
        moveTask = 'Backlog';
        break;
      case COMPLETE_AND_CREATE_NEW_SPRINT:
        moveTask = 'NewSprint';
        break;
      default:
        moveTask = 'SetSprint';
    }

    const id = sprintData?.id || '';

    dispatch(
      completeSprint({
        id,
        nextSprintId,
        moveTask,
      }),
    )
      .then(unwrapResult)
      .then((success) => {
        segment.track('SprintCompleted', {
          orgID: orgId,
          sprintID: id,
          nextSprintID: nextSprintId,
        });
        const message = strings
          .formatString(strings.sprints.completedToast, {
            completedSprint: sprintData?.sprintName ?? '',
          })
          .toString();
        addToast({
          message,
          timeoutMs: 3000,
          type: 'success',
        });
        return success;
      })
      .catch((error: Error) => {
        const { message } = error;
        addToast({
          message,
          type: 'error',
        });
      });

    history.go(-1);
  }, [addToast, dispatch, history, orgId, selectedSprint?.value, sprintData?.id, sprintData?.sprintName]);

  if (!isLoaded(completeSprintData)) {
    return null;
  }

  const {
    allPullRequestsCount,
    closedPullRequestsCount,
    totalEffortCompleted,
    effortCompletedPercent,
    incompleteSprintTasks,
    sprintTasks,
    totalCommits,
    totalEffortEstimated,
    totalTasksCompleted,
    totalTasks,
  } = completeSprintData;

  const tasksRemaining = incompleteSprintTasks?.length ?? 0;

  const completedSprintTasks = sprintTasks?.filter(
    (task) => (task as UI.UITask).status === TaskStatus.Done,
  ) as UI.UITask[];

  if (!isLoaded(currentTeam)) {
    return null;
  }

  return (
    <CompleteSprintModal
      allPullRequestsCount={allPullRequestsCount}
      allSprintTasks={sprintTasks}
      closedPullRequestsCount={closedPullRequestsCount}
      completedEffort={totalEffortCompleted}
      completedSprintTasks={completedSprintTasks}
      currentSprint={sprintData}
      effortCompletedPercent={effortCompletedPercent}
      handleCompleteSprint={handleCompleteSprint}
      selectedSprint={selectedSprint}
      setSelectedSprint={setSelectedSprint}
      tasksRemaining={tasksRemaining}
      totalCommits={totalCommits}
      totalEffort={totalEffortEstimated}
      totalTasksCompletedNumber={totalTasksCompleted}
      totalTasksNumber={totalTasks}
      upcomingSprints={upcomingSprints}
    />
  );
};

export default CompleteSprintController;
