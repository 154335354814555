import { unwrapResult } from '@reduxjs/toolkit';
import { Box, Fluid, Hidden, HStack, styled, Text, VStack } from '@taraai/design-system';
import { Data } from '@taraai/types';
import { noop } from '@taraai/utility';
import { useSprintNameConfig } from 'components/editor/config';
import { getStyledRichEditor, RichEditorHandle } from 'components/editor/RichEditor';
import { RichEditorProvider } from 'components/editor/RichEditorProvider';
import { useHover } from 'components/hooks';
import React from 'react';
import { updateSprint, useAppDispatch } from 'reduxStore';
import { CalendarIcon, PencilIcon } from 'resources/assets/icons/components';
import { strings } from 'resources/i18n';
import { useClickOutside, useToast } from 'tools';
import { StopPropagation } from 'tools/helpers/StopPropagation';
import { formatDMMM } from 'tools/libraries/helpers/dates';

import { SprintHeaderPopupMenu } from './SprintHeaderPopupMenu';
import { useSprintSettingsModal } from './SprintSettingsContext';

type SprintType = 'active' | 'complete' | 'upcoming';
type Props = {
  sprintId: Data.Id.SprintId;
  name: string;
  type: SprintType;
  endDateSeconds: number;
  startDateSeconds: number;
  sprintIsPhantom: boolean | undefined;
};

export function SprintHeader({
  sprintId,
  name,
  type,
  endDateSeconds,
  startDateSeconds,
  sprintIsPhantom,
}: Props): JSX.Element {
  const [isEditingName, setIsEditingName] = React.useState(false);
  const headerContainerRef = React.useRef<HTMLDivElement>(null);
  const sprintTitleRef = React.useRef<RichEditorHandle>(null);

  const dispatch = useAppDispatch();
  const config = useSprintNameConfig();
  const [nameRef, isNameHovered] = useHover();
  const [dateRef, isDateHovered] = useHover();
  const { addToast } = useToast();
  const { handleOpenSprintSettings, handleSprintSettingsType, handleSprintSettingId } = useSprintSettingsModal();

  const onSubmit = (newSprintName: string): void => {
    if (newSprintName === name) {
      setIsEditingName(false);
    } else {
      dispatch(updateSprint({ id: sprintId, sprintName: newSprintName }))
        .then(unwrapResult)
        .then(() => {
          setIsEditingName(false);
        })
        .catch((error) => {
          addToast({ type: 'error', message: `Sprint Name Failed To Update (${error.message})` });
          setIsEditingName(false);
        });
    }
  };

  const handleIndividualSprintSettings = React.useCallback(() => {
    handleSprintSettingsType('individualSprintDates');
    handleSprintSettingId(sprintId);
    handleOpenSprintSettings();
  }, [handleOpenSprintSettings, handleSprintSettingId, handleSprintSettingsType, sprintId]);

  useClickOutside(headerContainerRef, () => {
    sprintTitleRef.current?.blur();
  });

  return (
    <Box ref={headerContainerRef} space='$4px'>
      <VStack space='$8px'>
        <HStack>
          <Hidden hidden={!isEditingName} strategy='remove'>
            <StopPropagation>
              <RichEditorProvider
                config={config}
                initialValue={name}
                onSave={onSubmit}
                readOnly={!isEditingName}
                saveStrategy='saveOnReturn'
                selectAllContent
              >
                <EditNameContainer border='$grey4' borderRadius='$4px'>
                  <SprintNameEditor
                    ref={sprintTitleRef}
                    placeholder={strings.textarea.placeholder}
                    saveOnBlur
                    type={type}
                  />
                </EditNameContainer>
              </RichEditorProvider>
            </StopPropagation>
          </Hidden>
          <Hidden hidden={isEditingName}>
            <SprintNameContainer ref={nameRef} onHover={isNameHovered && !sprintIsPhantom}>
              <Title ref={nameRef} size='$16px' type={type} weight='bold'>
                {name}
              </Title>
              {!sprintIsPhantom && (
                <SprintEditNameIcon
                  center
                  onClick={!sprintIsPhantom ? () => setIsEditingName(true) : noop}
                  onHover={isNameHovered}
                  spaceLeft='$8px'
                >
                  {PencilIcon}
                </SprintEditNameIcon>
              )}
            </SprintNameContainer>
          </Hidden>
        </HStack>
        <HStack>
          <Fluid>
            <HStack>
              <Title
                ref={dateRef}
                color='$coreGrey'
                onClick={!sprintIsPhantom ? () => handleIndividualSprintSettings() : noop}
                size='$12px'
                textAlign='center'
              >
                {formatDMMM(startDateSeconds)}
                {strings.sprints.sprintColumn.sprintDateSpace}
                {formatDMMM(endDateSeconds)}
              </Title>
              {!sprintIsPhantom && isDateHovered && (
                <Box height='$2px' spaceLeft='$8px' width='$2px'>
                  {CalendarIcon}
                </Box>
              )}
            </HStack>
          </Fluid>
          <PopupMenuContainer>
            <SprintHeaderPopupMenu isPhantom={sprintIsPhantom} sprintId={sprintId} />
          </PopupMenuContainer>
        </HStack>
      </VStack>
    </Box>
  );
}

const SprintNameContainer = styled(
  Box,
  {
    display: 'flex',
    cursor: 'pointer',
  },
  {
    onHover: {
      true: { cursor: 'pointer' },
    },
  },
);

const EditNameContainer = styled(Box, {
  padding: '0 80px 0 0',
});

const SprintEditNameIcon = styled(
  Box,
  {
    'cursor': 'pointer',
    'color': '$coreGrey',
    'opacity': '0',
    '&:hover': {
      color: '$indigo',
    },
  },
  {
    onHover: {
      true: { cursor: 'pointer', opacity: '1' },
    },
  },
);

const SprintNameEditor = styled(
  getStyledRichEditor({
    fontSize: '$16px',
    fontWeight: '$bold',
    lineHeight: '$16px',
    caretColor: 'colors.$focus',
    border: '1px solid $grey4',
    width: '130px',
  }),
  {},
  {
    type: {
      active: { color: '$indigo' },
      complete: { color: '$success' },
      upcoming: { color: '$coreGrey' },
    },
  },
);

const PopupMenuContainer = styled(Box, {
  top: '-24px',
});

const Title = styled(
  Text,
  {
    borderLeft: '0.1px solid transparent',
  },
  {
    type: {
      active: { color: '$indigo' },
      complete: { color: '$success' },
      upcoming: { color: '$coreGrey' },
    },
  },
);
