/* eslint-disable rulesdir/imports */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Data } from '@taraai/types';
import { createFirestoreId, RootStateWithProfile } from 'reduxStore';
import { ExtraAPI } from 'reduxStore/utils/types';
import { createSprint } from 'reduxStore/writes/sprints';

type createSprintPayload = {
  taskId: Data.Id.TaskId;
};

export const createAndPopulateSprint = createAsyncThunk(
  'CreateAndPopulateSprint',
  async ({ taskId }: createSprintPayload, { extra, getState }) => {
    const { getFirestore, getTeamId, getUserId, getOrgId } = extra as ExtraAPI;
    const state = getState() as RootStateWithProfile;
    const userId = getUserId(state);
    const teamId = getTeamId(state);
    const orgId = getOrgId();
    const firestore = getFirestore();
    const newSprintId = createFirestoreId(firestore);

    return firestore.mutate({
      reads: {
        uid: () => userId,
        newId: () => newSprintId,
        task: { path: `orgs/${orgId}/tasks`, id: taskId },
        previousSprints: {
          collection: `orgs/${orgId}/sprints`,
          where: [['teamId', '==', teamId]],
          orderBy: ['endDate', 'desc'],
          limit: 1,
        },
      },
      writes: [createSprint],
    });
  },
);
