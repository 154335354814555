import { isLoaded, useFirestoreConnect } from '@taraai/read-write';
import { TeamDetailsModal } from 'components/app/controllers/views/TeamDetailsModal';
import React from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import {
  getCompletedSprints,
  selectActiveTeam,
  selectActiveWorkspace,
  selectAuth,
  selectProfile,
  selectTeam,
  selectUserTeams,
} from 'reduxStore';

export interface TeamDetailsControllerProps {
  noModal?: boolean;
  closeModal?: () => void;
}

/**
 * TeamDetailsController
 * Team Details Controller for the page view
 *
 */
export default function TeamDetailsController({
  noModal = false,
  closeModal,
}: TeamDetailsControllerProps): JSX.Element | null {
  const orgId = useSelector(selectActiveWorkspace);
  const teamId = useSelector(selectActiveTeam);
  const profileId = useSelector(selectAuth).uid;
  const team = useSelector(selectTeam(orgId, teamId));
  const hasAlternativeTeams = useSelector(compose((teams) => teams.length > 1, selectUserTeams(orgId)));
  const profile = useSelector(selectProfile);
  const completedSlice = getCompletedSprints(orgId, teamId, {
    orderBy: 'sprintNumber',
  });
  useFirestoreConnect(completedSlice.query);
  const hasCompleteSprint = useSelector(compose((data) => data?.map((sprint) => sprint.id), completedSlice.selector));

  if (!isLoaded(team) || !isLoaded(hasCompleteSprint)) {
    return null;
  }
  const isMember = (profile.teamIds[orgId] || []).includes(team.id);
  const canLeave = isMember && profile.teamIds[orgId].length > 1;
  const isPreferred = profile.preferredTeamIds[orgId] === team.id;
  const isSprintCompleteOrActive = hasCompleteSprint.length > 0 || team.currentSprintId !== null;
  return (
    <TeamDetailsModal
      canLeave={canLeave}
      closeModal={closeModal}
      hasAlternativeTeams={hasAlternativeTeams}
      isMember={isMember}
      isPreferred={isPreferred}
      isSprintCompleteOrActive={isSprintCompleteOrActive}
      noModal={noModal}
      orgId={orgId}
      profileId={profileId}
      teamId={teamId}
      teamName={team.name}
      username={profile.name}
    />
  );
}
