import { Functions } from '@taraai/types';
import { createCloudFunctionAction } from 'reduxStore/utils/createCloudFunctionAction';

export const connectToOrganization = createCloudFunctionAction<
  Functions.ConnectToOrganization.Payload,
  Functions.ConnectToOrganization.Success
>({
  typePrefix: 'ConnectToOrganization',
  responseDecoder: 'ConnectToOrganizationResponse',
  cloudFunctionName: 'connectToOrganization',
});
