import { styled } from '@taraai/design-system';
import React, { ComponentProps, useState } from 'react';
import AriaMenuButton from 'react-aria-menubutton';
import { strings } from 'resources';

import MenuItem from './MenuItem';
import { menuStyle, profileMenuStyle } from './menuStyles';
import { LeafMenuOption, MenuOption } from './menuTypes';

interface MenuProps extends Omit<React.HTMLProps<HTMLElement>, 'style'> {
  /**
   * An array of items to show in the menu.
   */
  options: MenuOption[];

  isDisabled?: boolean;

  /**
   * determines the position of the menu. Options include bottomLeft, bottomRight, topLeft, topRight.
   */
  // eslint-disable-next-line react/no-unused-prop-types
  position?: string;
  /**
   * determines if the menu is used for the sorting in the sprint column
   */
  sortFilterMenu?: boolean;

  children: JSX.Element | JSX.Element[] | string;

  /**
   * Determines if the menu list should have left padding for selected check mark
   */
  showSelection?: boolean;
  showTooltip?: boolean;
  title?: string;
  styledType?: ComponentProps<typeof MenuContainer>['styledType'];
}
/**
 * A wrapper around the AriaMenuButton.
 *
 * FIXME: we are not currently using sub-menus, so while they render, they are not functional
 *
 * - [MDN Menu Element](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/menu)
 * - [Designs](https://zpl.io/VYXLOMJ)
 *
 */

export default function Menu({
  options,
  isDisabled,
  sortFilterMenu = false,
  showSelection = false,
  showTooltip = false,
  title,
  children,
  styledType = 'default',
}: MenuProps): JSX.Element {
  const [selectedItem, setSelectedItem] = useState('');

  function onSelection(value: string, event: React.SyntheticEvent): void {
    const selected = options.find((option) => option.title === value) as LeafMenuOption;
    if (typeof selected?.onSelect === 'function') {
      selected.onSelect(event);
      setSelectedItem(selected.title);
    }
  }

  return (
    // WATCH OUT, this component keeps the initial reference to the onSelection callback
    // So any closure values in this handler can be outdated
    <AriaMenuButton.Wrapper onSelection={onSelection}>
      <AriaMenuButton.Button>{children}</AriaMenuButton.Button>
      <MenuContainer styledType={styledType}>
        <ul>
          {sortFilterMenu && <List>{strings.sprints.sortBy.sort}</List>}
          {options.map(
            (option: MenuOption) =>
              !option.hidden && (
                <MenuItem
                  key={option.title}
                  isDisabled={isDisabled}
                  option={option}
                  selectedItem={selectedItem}
                  showSelection={showSelection}
                  showTooltip={showTooltip}
                  title={title}
                />
              ),
          )}
        </ul>
      </MenuContainer>
    </AriaMenuButton.Wrapper>
  );
}

const List = styled('li', {
  fontSize: '0.8125rem',
  color: '#949caf',
  padding: '0.9rem 1.5rem 0.3rem 1.5rem',
});
const MenuContainer = styled(AriaMenuButton.Menu, menuStyle, {
  styledType: {
    default: { right: '0' },
    menu: { right: 'auto' },
    profileMenu: profileMenuStyle,
    commentDeleteMenu: { right: '0' },
  },
});
