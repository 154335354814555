import { Selector } from '@reduxjs/toolkit';
import { UISprint } from '@taraai/types/dist/ui';
import { isNonEmptyString } from '@taraai/utility';
import { RootState } from 'reduxStore/store';
import { createIndividualSelector, IndividualQuery, inertIndividualQuery } from 'reduxStore/utils/selectors';
import { ReduxFirestoreQuery } from 'reduxStore/utils/types';

export type SprintFragment = Pick<UISprint, 'id' | 'completedAt' | 'computedOnCompletion' | 'isComplete'>;
export type SprintSelector = Selector<RootState, UISprint | SprintFragment | undefined>;
export type SprintListSelector = Selector<RootState, UISprint[] | undefined>;

function queryBuilder(
  orgId: string,
  storeAs: string,
  options?: Omit<ReduxFirestoreQuery, 'storeAs'>,
): IndividualQuery<UISprint> {
  const query: ReduxFirestoreQuery = {
    ...{
      collection: `orgs/${orgId}/sprints`,
      storeAs,
    },
    ...options,
  };

  return {
    query: [query],
    selector: createIndividualSelector<UISprint>(query),
  };
}

export function getSprint(orgId: string, sprintId: string | undefined): IndividualQuery<UISprint> {
  if (!isNonEmptyString(orgId) || !isNonEmptyString(sprintId)) {
    return inertIndividualQuery();
  }

  return queryBuilder(orgId, `sprint/${orgId}/sprint-${sprintId}`, {
    where: [['__name__', '==', sprintId]],
  });
}

export function getLastSprint(orgId: string, teamId: string): IndividualQuery<UISprint> {
  if (!isNonEmptyString(orgId) || !isNonEmptyString(teamId)) {
    return inertIndividualQuery();
  }

  return queryBuilder(orgId, `sprint/${orgId}/${teamId}/last-sprint`, {
    orderBy: ['endDate', 'desc'],
    limit: 1,
    where: [
      ['teamId', '==', teamId],
      ['archived', '==', false],
      ['deleted', '==', false],
    ],
  });
}
