/* istanbul ignore file */
/* eslint-disable @typescript-eslint/no-explicit-any */

let output = {};

function requireAll(webpack: __WebpackModuleApi.RequireContext): any {
  return webpack.keys().reduce((obj, key) => {
    output = Object.assign(obj, {
      [key.replace('./', '').replace('Schema.json', '')]: webpack(key),
    });
    return output;
  }, {} as any);
}

const Schemas = requireAll(require.context('@taraai/types/dist/schemas', true, /\.json$/));

export default Schemas;
