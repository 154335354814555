import { createAsyncThunk } from '@reduxjs/toolkit';
import { Data, Functions } from '@taraai/types';
import { decode } from 'reduxStore/utils/decoders';
import { ExtraAPI } from 'reduxStore/utils/types';

export const uninstallIntegration = createAsyncThunk(
  'UninstallIntegration',
  async (integrationId: Data.Id.IntegrationId, { extra }) => {
    const { getFirebase, getOrgId } = extra as ExtraAPI;
    const orgId = getOrgId();

    const payloadDraft: Functions.UninstallIntegration.Payload = {
      organizationId: orgId,
      integrationId,
    };

    const payload = decode<Functions.UninstallIntegration.Payload>(payloadDraft, 'UninstallIntegrationPayload');

    await getFirebase().functions().httpsCallable('uninstallIntegration')(payload);
  },
);
