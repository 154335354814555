import { css, cx } from 'emotion';
import React from 'react';

export type ButtonColor =
  | 'primary'
  | 'blue'
  | 'green'
  | 'red'
  | 'grey'
  | 'gray'
  | 'wireframe'
  | 'ghost'
  | 'gradient'
  | 'red2gray';

export type ButtonSize = 'compact' | 'small' | 'default' | 'large' | 'fullWidth';

type ButtonType = 'button' | 'submit' | 'reset';

const sizes: Record<ButtonSize, string> = {
  default: css`
    min-width: 7.5rem;
    min-height: 2.875rem;
    font-size: 0.875rem;
    font-weight: 500;
  `,
  compact: css`
    padding: 0.25rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `,
  small: css`
    min-width: 4.25rem;
    min-height: 1.6875rem;
    font-size: 0.875rem;
    font-weight: 500;
  `,
  large: css`
    min-width: 8rem;
    min-height: 3.125rem;
    font-size: 1rem;
    font-weight: 500;
  `,
  fullWidth: css`
    width: 100%;
    min-height: 2.875rem;
    font-size: 0.875rem;
    font-weight: 500;
  `,
};

const colors: Record<
  ButtonColor,
  {
    normal: string;
    active?: string;
    border?: string;
    disabled?: string;
  } & ({ hover?: string; hoverOpacity?: undefined } | { hover?: undefined; hoverOpacity: number })
> = {
  primary: { normal: '#4550ce', hover: '#616cea', active: '#3e48c0' },
  blue: { normal: '#4550ce', hover: '#616cea', active: '#3e48c0' },
  green: { normal: '#389e0d', hover: '#60b13d', active: '#2f8909' },
  red: { normal: '#e53935', hover: '#ea615d', active: '#d5322e' },
  red2gray: { normal: '#e86262', hover: '#ea615d', active: '#d5322e', disabled: '#C8D0DF' },
  gray: { normal: '#eef1f7', active: '#eaeef5' },
  grey: { normal: '#eef1f7', active: '#eaeef5' },
  ghost: {
    normal: '#ffffff',
    border: '0.0625rem solid #c8d0df',
  },
  wireframe: {
    normal: 'transparent',
    hover: '#eaeef5',
    border: '0.0625rem solid #eaeef5',
  },
  gradient: {
    normal: 'linear-gradient(138deg, #58a6e7 0%, #775dfa 100%)',
    hoverOpacity: 0.8,
  },
};

interface ButtonProps extends Omit<React.HTMLProps<HTMLButtonElement>, 'size'> {
  color?: ButtonColor;
  size?: ButtonSize;
  type?: ButtonType;
}

/**
 * A wrapper around `<button>` with Tara's default styles
 *
 * Defaults to a size of 7.5rem * 2.875rem, but can be passed a size prop to make
 * it larger and will grow in width to accept longer text.
 *
 * - [Designs](https://zpl.io/aM13MLN)
 */
export default function Button({
  className,
  children,
  disabled,
  name,
  color = 'primary',
  size = 'default',
  ...props
}: ButtonProps): JSX.Element {
  if (typeof children !== 'string' && name === undefined) {
    // eslint-disable-next-line no-console
    console.warn('<Button>: (A11y) No name was provided but non-string children were passed.');
  }
  const buttonColor = colors[color];
  return (
    <button
      className={cx([
        css`
          padding: 1rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          border-radius: 0.1875rem;
          transition: background 0.25s ease;
          background: ${!disabled ? buttonColor.normal : buttonColor.disabled ?? buttonColor.normal};
          cursor: ${disabled ? 'not-allowed' : 'pointer'};
          opacity: ${disabled ? 0.65 : 1};
          transition: border-color 0.25s ease;
          border: ${buttonColor.border ?? 'none'};
          color: ${['grey', 'gray', 'ghost'].includes(color) ? '#67728b' : '#FFFFFF'};
          transition: all 0.2s ease-in-out;
          &:hover {
            background: ${!disabled ? buttonColor.hover : buttonColor.normal};
            opacity: ${buttonColor.hoverOpacity};
          }
          &:active {
            background: ${buttonColor.active};
            border: ${color === 'ghost' ? '0.0625rem solid #949caf' : 'none'};
          }
        `,
        sizes[size],
        className,
      ])}
      disabled={disabled}
      type='button'
      {...props}
    >
      {children}
    </button>
  );
}
