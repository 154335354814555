import { getCustomSize, styled, tokens } from '@taraai/design-system';
import { UI } from '@taraai/types';
import React, { useEffect, useState } from 'react';
import { updateTask, useAppDispatch } from 'reduxStore';
import { useDebounced } from 'tools/utils/hooks/useDebounced';

type ColorTokens = keyof typeof tokens.colors;

type Props = {
  backgroundColor?: ColorTokens;
  color?: ColorTokens;
  effortLevel: UI.UITask['effortLevel'];
  taskId: UI.UITask['id'];
};

const MIN = 0;
const MAX = 99;

export const EffortInput = ({
  backgroundColor = '$grey6',
  color = '$white',
  effortLevel,
  taskId,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const [effortLevelState, setEffortLevelState] = useState<string | number>(effortLevel);

  useEffect(() => {
    setEffortLevelState(effortLevel);
  }, [effortLevel]);

  const debouncedUpdateTask = useDebounced((payload) => dispatch(updateTask(payload)), 300);

  const updateEffortLevel = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const { value } = event.target;
    const parsedValue = Number.parseInt(value, 10);
    const isInRange = parsedValue >= MIN && parsedValue <= MAX;

    if (value === '' || isInRange) {
      setEffortLevelState(value);
      await debouncedUpdateTask({ id: taskId, effortLevel: parsedValue });
    }
  };

  return (
    <InputStyled
      max={MAX}
      min={MIN}
      onChange={updateEffortLevel}
      onClick={(event) => event.stopPropagation()}
      placeholder='0'
      style={{ backgroundColor: tokens.colors[backgroundColor], color: tokens.colors[color] }}
      type='number'
      value={effortLevelState}
    />
  );
};

const effortInputSize = getCustomSize(16);

const InputStyled = styled('input', {
  'width': effortInputSize,
  'height': effortInputSize,
  'borderRadius': '$circle',
  'padding': 'none',
  'border': 'none',
  'textAlign': 'center',
  'fontSize': '$10px',
  'fontFamily': '$basic',
  'fontWeight': 'medium',
  'MozAppearance': 'textfield',
  '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: '0',
  },
  'cursor': 'text',
  'outline': 'none',
  '&:focus': {
    border: 'solid 1px colors.$grey6',
    backgroundColor: '$white',
    color: '$dark',
    cursor: 'default',
  },
});
