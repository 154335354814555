import { unwrapResult } from '@reduxjs/toolkit';
import { Box } from '@taraai/design-system';
import { Attachment, Data, Markdown } from '@taraai/types';
import { UITask } from '@taraai/types/dist/ui';
import { parseMentionsFromPlainText } from '@taraai/utility';
import { useTaskTitleConfig } from 'components/editor/config';
import { makeCopyDocumentTitle } from 'components/editor/utils';
import { linkTo } from 'components/Router/paths';
import React, { ReactNode, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { createTask, getPathForTask, removeAttachment, selectActiveTeam, updateTask, useAppDispatch } from 'reduxStore';
import { strings } from 'resources';
import { useToast } from 'tools';
import { segment } from 'tools/libraries/analytics';
import { formatI18n } from 'tools/libraries/helpers/formatI18n';
import { useForceSave } from 'tools/reconciliation/useForceSave';

import { RemirrorDefinePanel } from './RemirrorDefinePanel';

type Props = { orgId: Data.Id.OrganizationId; task: UITask };

export function DefineTask({ task, orgId }: Props): JSX.Element | null {
  const dispatch = useAppDispatch();
  const { addToast } = useToast();
  const teamId = useSelector(selectActiveTeam);
  const history = useHistory();

  const path = getPathForTask(task.id, orgId);
  const description = task.description ?? null;
  const title = task.title ?? null;
  const attachments = task.attachments ?? [];

  const handleAttachmentRemove = useCallback(
    (attachment: Attachment): void => {
      dispatch(removeAttachment({ type: 'task', attachment, path }))
        .then(unwrapResult)
        .catch((error: Error) => {
          const message = formatI18n(strings.attachments.uploadError)({
            errorMessage: error.message,
          });
          addToast({ message, type: 'error' });
        });
    },
    [addToast, dispatch, path],
  );

  const titleConfig = useTaskTitleConfig({ singleLine: false });

  const uploadDescriptionMarkdown = useCallback(
    async (newDescription: Markdown): Promise<string | Error | null> => {
      try {
        await dispatch(
          updateTask({
            description: newDescription,
            id: task.id,
            mentionedUserIds: parseMentionsFromPlainText(newDescription),
          }),
        ).then(unwrapResult);
        return newDescription;
      } catch (err) {
        const error = err as Error;
        addToast({ message: error.message, type: 'error' });
        return error;
      }
    },
    [addToast, dispatch, task.id],
  );

  const uploadTitle = useCallback(
    async (newTitle: string): Promise<string | Error | null> => {
      try {
        await dispatch(updateTask({ title: newTitle, id: task.id })).then(unwrapResult);
        return newTitle;
      } catch (err) {
        const error = err as Error;
        addToast({ message: error.message, type: 'error' });
        return error;
      }
    },
    [addToast, dispatch, task.id],
  );

  const {
    forceSave: forceSaveDescription,
    trySave: trySaveDescription,
    saveState: descriptionSaveState,
    getLatestOutOfSyncValue: getLatestOutOfSyncValueDescription,
  } = useForceSave(description, uploadDescriptionMarkdown);

  const { trySave: trySaveTitle } = useForceSave(title, uploadTitle);

  const createNewDocument = useCallback(async (): Promise<void> => {
    const latestDescription = getLatestOutOfSyncValueDescription();
    try {
      await dispatch(
        createTask({
          title: makeCopyDocumentTitle(title, task.id, 'task'),
          description: latestDescription,
          status: task.status,
        }),
      )
        .then(unwrapResult)
        .then((taskID) => {
          segment.track('TaskCreated', { orgID: orgId, taskID, location: 'Requirement' });
          history.replace(linkTo('task', { orgId, teamId, taskId: taskID }));
        });
    } catch (error) {
      addToast({ type: 'error', message: strings.task.failedToCreateTask });
    }
  }, [addToast, dispatch, getLatestOutOfSyncValueDescription, history, orgId, task.id, task.status, teamId, title]);

  useEffect(() => {
    document.title = strings.formatString(strings.titles.task, { id: task.id, title: task.title }) as string;
    return () => {
      // Restore the original page title when the component unmounts
      document.title = strings.titles.default;
    };
  }, [task.id, task.title]);

  if (!task) return <Container />;

  return (
    <Container>
      <RemirrorDefinePanel
        attachments={attachments}
        createNewDocument={createNewDocument}
        description={task.description}
        descriptionSaveState={descriptionSaveState}
        forceSaveDescription={forceSaveDescription}
        handleAttachmentRemove={handleAttachmentRemove}
        id={task.id}
        placeholder={strings.editor.placeholder}
        title={task.title}
        titleConfig={titleConfig}
        trySaveDescription={trySaveDescription}
        trySaveTitle={trySaveTitle}
        type='tasks'
      />
    </Container>
  );
}

function Container({ children }: { children?: ReactNode }): JSX.Element {
  return (
    <Box background='$grey2' center='horz' full spaceTop='$24px'>
      {children}
    </Box>
  );
}
