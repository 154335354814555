import { css } from 'emotion';
import React from 'react';
import { atomic } from 'resources/theme';

import NoInvitesMessage from './NoInvitesMessage';

const InvitedUsersList = ({ children = [] }: React.HTMLProps<HTMLDivElement>): JSX.Element => {
  if (Array.isArray(children) && children.length === 0) {
    return (
      <div>
        <NoInvitesMessage />
      </div>
    );
  }

  return (
    <ul
      className={css`
        margin-top: 1rem;
        border-radius: 0.1875rem;
        border-style: solid;
        border-width: 0.0625rem;
        border-color: ${atomic.get(atomic.colors.grey4)};
      `}
    >
      {children}
    </ul>
  );
};
export default InvitedUsersList;
