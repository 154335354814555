import { Box, getCustomSize, HStack, mapTokens, Spacer, styled, Text } from '@taraai/design-system';
import Icon from 'components/core/controllers/views/Icon';
import moment from 'moment';
import React from 'react';
import { strings } from 'resources';

import { SprintSettingsViewProps } from './types';

const StyledIcon = styled(
  Icon,
  {
    height: '100%',
    width: '100%',
    padding: 0,
  },
  {
    fillColor: mapTokens('colors', (color) => ({ color })),
  },
);

const iconSize = getCustomSize(10);

export const Footer = ({ sprintSettings }: SprintSettingsViewProps): JSX.Element => (
  <Box background='$white' space='$16px'>
    <HStack alignY='center'>
      <Box height={iconSize} width={iconSize}>
        <StyledIcon fillColor='$success' name='error' />
      </Box>
      <Spacer space='$8px' />
      <Text color='$success' size='$12px'>
        {strings.formatString(strings.sprintSettings.yourSprintsEnd, {
          endDay: strings.sprintSettings.weekDays[sprintSettings.endDay],
          endTime: moment(`${sprintSettings.endHours}.${sprintSettings.endMinutes}`, ['HH.mm']).format('hh.mm a'),
        })}
      </Text>
    </HStack>
  </Box>
);
