import { createMutate } from '@taraai/read-write';
import { Data } from '@taraai/types';
import { RequirementWrites, SprintWrites, TaskWrites } from 'reduxStore/writes';

type OrphanTask = null;

type MoveSprint = {
  destinationSprintId: Data.Id.SprintId | OrphanTask;
};

type MoveRequirement = {
  destinationRequirementId: Data.Id.RequirementId | OrphanTask;
};

type MoveTaskPayload = {
  taskId: Data.Id.TaskId;
  insertAfterId: Data.Id.TaskId | null;
  sourceSprintId: Data.Id.SprintId | OrphanTask;
  visibleList: string[];
  removeFromCarryOver?: boolean;
  assignee?: string;
} & (MoveSprint | MoveRequirement);

type Globals = { getOrgId: Data.Id.OrganizationId; getUserId: Data.Id.UserId };

/*
  To make sure the tasks is moved to the "most likely" spot the user
  wants to drop at AND user concurrency is "respected"
  ( two users moving tasks at the same time ) it is important
  to use the insertAfterId as a reference point and get the list
  of tasks from the reads
*/
export const moveSprintTask = createMutate({
  action: 'MoveTask',
  readwrite: (payload: MoveTaskPayload, { getUserId, getOrgId }: Globals) => {
    const { taskId, insertAfterId, assignee, sourceSprintId, removeFromCarryOver, visibleList } = payload;

    if ('destinationSprintId' in payload && typeof payload.destinationSprintId === 'string') {
      const { destinationSprintId } = payload;
      return {
        read: {
          task: { path: `orgs/${getOrgId}/tasks`, id: taskId },
          destinationSprint: { path: `orgs/${getOrgId}/sprints`, id: destinationSprintId },
          ...(sourceSprintId ? { sourceSprint: { path: `orgs/${getOrgId}/sprints`, id: sourceSprintId } } : {}),
          insertAfterId: () => insertAfterId,
          visibleList: () => visibleList,
          assignee: () => assignee,
          removeFromCarryOver: () => removeFromCarryOver,
          uid: () => getUserId,
        },
        write: [TaskWrites.moveTask, SprintWrites.insertNear],
      };
    }

    if ('destinationRequirementId' in payload && typeof payload.destinationRequirementId === 'string') {
      const { destinationRequirementId } = payload;
      return {
        read: {
          task: { path: `orgs/${getOrgId}/tasks`, id: taskId },
          requirement: { path: `orgs/${getOrgId}/requirements`, id: destinationRequirementId },
          insertAfterId: () => insertAfterId,
          assignee: () => assignee,
          visibleList: () => visibleList,
          uid: () => getUserId,
          ...(sourceSprintId ? { sourceSprint: { path: `orgs/${getOrgId}/sprints`, id: sourceSprintId } } : {}),
        },
        write: [TaskWrites.moveTask, RequirementWrites.insertNear],
      };
    }

    return {
      read: { task: { path: `orgs/${getOrgId}/tasks`, id: taskId }, uid: () => getUserId },
      write: [TaskWrites.moveToOrphan],
    };
  },
});
