interface CommitMessage {
  title: string;
  body: string;
}

const NEWLINE = '\n';

export default function parseMessage(message: string): CommitMessage {
  const [title, ...bodyLines] = message.split(NEWLINE);
  return {
    title,
    body: bodyLines.join(NEWLINE),
  };
}
