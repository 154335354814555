import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getSubscriptionType, selectActiveWorkspace } from 'reduxStore';

import { CheckoutSuccessView } from './CheckoutSuccessView';

export function CheckoutSuccessController(): JSX.Element {
  const orgId = useSelector(selectActiveWorkspace);

  const subscriptionType = useSelector(getSubscriptionType(orgId));

  const [isLoading, setIsLoading] = useState(true);

  // Wait for workspace custom claims to be set
  useEffect(() => {
    if (subscriptionType !== null) {
      setIsLoading(false);
    }
  }, [subscriptionType]);

  return <CheckoutSuccessView isLoading={isLoading} />;
}
