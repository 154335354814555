import Avatar from 'components/core/controllers/views/Avatar';
import { FastSmallSpinner } from 'components/core/controllers/views/Spinners';
import Text from 'components/core/controllers/views/Text';
import { css } from 'emotion';
import React from 'react';
import { Profile } from 'reduxStore/utils';
import { strings } from 'resources';

type ProfileAvatarViewProps = {
  uploadStatus: string;
  handleUploadProfileImage: (file: File) => void;
  handleRemoveProfileImage: () => void;
  user: Profile;
};

export default function ProfileAvatarView({
  uploadStatus,
  handleUploadProfileImage,
  handleRemoveProfileImage,
  user,
}: ProfileAvatarViewProps): JSX.Element {
  return (
    <div
      className={css`
        position: relative;
        display: flex;
        width: fit-content;
        height: fit-content;
        justify-content: center;
        align-items: center;
        /* use id to dynamically toggle avatar shading and buttons showing */
        #profile-avatar {
          filter: ${uploadStatus && 'brightness(40%)'};
        }
        #profile-avatar-actions {
          visibility: ${uploadStatus && 'visible'};
        }
        &:hover #profile-avatar-actions {
          visibility: visible;
        }
        &:hover #profile-avatar {
          filter: brightness(35%);
        }
      `}
    >
      <div
        className={css`
          z-index: 1;
          position: absolute;
          visibility: hidden;
          display: flex;
          flex-direction: column;
        `}
        id='profile-avatar-actions'
      >
        {uploadStatus ? (
          // show loading status
          <div
            className={css`
              display: flex;
              flex-direction: column;
              align-items: center;
            `}
          >
            <FastSmallSpinner color='#ffffff' />
            <Text color='#ffffff'>{uploadStatus}</Text>
          </div>
        ) : (
          // show add photo button
          <label
            className={css`
              overflow: hidden;
              position: relative;
              cursor: pointer;
            `}
          >
            <Text color='#ffffff'>
              {user.avatarURL ? strings.profileImage.changePhoto : strings.profileImage.uploadPhoto}
            </Text>
            <input
              className={css`
                pointer-events: none;
                display: block;
                font-size: 999px;
                filter: alpha(opacity=0);
                min-height: 100px;
                min-width: 30px;
                opacity: 0;
                position: absolute;
                right: 0;
                text-align: right;
                top: 0;
              `}
              onChange={(event): void => {
                event.preventDefault();
                if (event?.target?.files) {
                  handleUploadProfileImage(event?.target?.files[0]);
                }
              }}
              type='file'
            />
          </label>
        )}
        {user.avatarURL && !uploadStatus && (
          // show remove photo button
          <button
            className={css`
              cursor: pointer;
              border: none;
              background: none;
              margin-top: 1.5rem;
            `}
            onClick={handleRemoveProfileImage}
            type='button'
          >
            <Text color='#ffffff'>{strings.profileImage.removePhoto}</Text>
          </button>
        )}
      </div>
      <div id='profile-avatar'>
        <Avatar size='9.375rem' user={user} />
      </div>
    </div>
  );
}
