import styled from '@emotion/styled';
import Button from 'components/core/controllers/views/Button';
import Icon from 'components/core/controllers/views/Icon';
import React from 'react';

import { SprintSelectorOption } from './types';

type SprintOptionProps = {
  sprint: SprintSelectorOption;
  isActiveOption: boolean;
  isSelectedOption: boolean;
  dataCy?: string;
};

export default function SprintOption({
  sprint,
  dataCy,
  isActiveOption,
  isSelectedOption,
}: SprintOptionProps): JSX.Element {
  return (
    <Container data-cy={dataCy} isActiveOption={isActiveOption} name={sprint.sprintName}>
      <Title data-cy={dataCy} isActiveOption={isActiveOption}>
        {sprint.sprintName}
      </Title>
      {isSelectedOption && <TickIcon />}
    </Container>
  );
}

type ActiveProps = {
  isActiveOption: boolean;
};

const Container = styled(({ isActiveOption, dataCy, ...props }) => (
  <Button color='ghost' data-cy={dataCy} size='compact' {...props} />
))`
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  outline: none;
  border: none;
  background-color: ${({ isActiveOption }: ActiveProps): string => (isActiveOption ? '#fbfbfd' : 'white')};
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #fbfbfd;
    outline: none;
    border: none;
  }

  &:focus {
    outline: none;
    border: none;
  }
`;

const Title = styled.div`
  color: #303f4b;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0.5rem;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: ${({ isActiveOption }: ActiveProps): number => (isActiveOption ? 0.7 : 1)};
  }
`;

const TickIcon = styled((props) => <Icon name='tick' {...props} />)`
  padding: 0;
`;
