/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable id-length */
import { Box, Text, tokens } from '@taraai/design-system';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { Bar, BarStack } from '@visx/shape';
import { Text as VText } from '@visx/text';
import { BarStackVerticalProps } from 'components/app/GitInsights/_types';
import { backgroundTypes, GraphColors } from 'components/app/GitInsights/Shared/constants';
import React from 'react';

import { SVGBackgroundPatterns } from './SVGBackgroundPatterns';

export const background = 'transparent';
const defaultMargin = { top: 0, left: 20, right: 0, bottom: 20 };

export const VerticalStacked = ({
  data,
  dataKeys,
  width,
  height,
  margin = defaultMargin,
  getAxisBottomInfo,
  barHeight,
  showAxisBottomInfo = true,
  showAxisLeftInfo = true,
  comparisonValue,
  title,
  bottomTitle,
  leftAxiosLabel,
  showValues = false,
  showGridLines = true,
  useBackgroundPatterns = false,
}: // TODO: Setup tooltips in later version
// tooltipData,
BarStackVerticalProps): JSX.Element | null => {
  // Container bounds
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  const keys = dataKeys;

  const xAxisTotals = data.reduce((allTotals, current) => {
    const totalData = keys.reduce((recordTotal, k) => {
      // eslint-disable-next-line no-param-reassign
      recordTotal += Number(current[k]);
      return recordTotal;
    }, 0);
    allTotals.push(totalData);
    return allTotals;
  }, [] as number[]);

  // scales
  const leftScale = scaleLinear<number>({
    domain: [0, Math.max(...xAxisTotals)],
    nice: true,
  });

  const bottomScale = scaleBand<string>({
    domain: data.map(getAxisBottomInfo),
    padding: 0.2,
  });

  // Legends/Colors
  const colorScale = scaleOrdinal({
    domain: keys,
    range: GraphColors.slice(0, dataKeys.length),
  });

  // Set the ranges
  bottomScale.rangeRound([0, xMax]);
  leftScale.rangeRound([yMax, 0]);

  return width < 10 ? null : (
    <Box>
      {title && (
        <Text color='$secondaryText' size='$12px'>
          {title}
        </Text>
      )}

      {comparisonValue && (
        <Text color='$secondaryText' size='$12px'>
          {comparisonValue}
        </Text>
      )}

      <svg height={height + 20} width={width}>
        {SVGBackgroundPatterns({ dataLength: data.length })}
        <rect fill={tokens.colors.$darker} height={height} rx={14} width={width} x={0} y={0} />
        <Group left={leftAxiosLabel ? 25 : 0} spacing={10} top={margin.top + 10}>
          {showGridLines && (
            <GridRows
              height={yMax}
              left={margin.left}
              numTicks={3}
              scale={leftScale}
              stroke={tokens.colors.$secondaryText}
              strokeOpacity={0.1}
              top={margin.top}
              width={xMax}
            />
          )}

          <BarStack
            color={colorScale}
            data={data}
            keys={keys}
            x={getAxisBottomInfo}
            xScale={bottomScale}
            yScale={leftScale}
          >
            {(barStacks) =>
              barStacks.map((barStack) =>
                barStack.bars.map((bar) => {
                  return (
                    <>
                      <Group key={`bar-stack-${barStack.index}-${bar.index}`}>
                        <Bar
                          fill={
                            backgroundTypes[bar.index] === 'solid' || !useBackgroundPatterns
                              ? GraphColors[barStack.index]
                              : `url(#background-${backgroundTypes[bar.index]}-${GraphColors[barStack.index]})`
                          }
                          height={barHeight || bar.height}
                          width={bar.width}
                          x={bar.x}
                          y={bar.y}
                        />
                        {showValues && (
                          <VText
                            dx={bar.width / 2}
                            dy={bar.height / 2}
                            fill='white'
                            fontSize='12px'
                            stroke='white'
                            textAnchor='middle'
                            verticalAnchor='middle'
                            width={bar.width}
                            x={bar.x}
                            y={bar.y}
                          >
                            {bar.bar.data[bar.key] === 0 ? '' : bar.bar.data[bar.key]}
                          </VText>
                        )}
                      </Group>
                    </>
                  );
                }),
              )
            }
          </BarStack>

          {showAxisLeftInfo && (
            <AxisLeft
              hideAxisLine
              hideTicks
              label={leftAxiosLabel}
              labelProps={{ fill: tokens.colors.$secondaryText, fontSize: '11px', dx: '0.7em' }}
              left={20}
              numTicks={3}
              scale={leftScale}
              stroke={tokens.colors.$secondaryText}
              tickLabelProps={() => ({
                fill: tokens.colors.$secondaryText,
                fontSize: 11,
                textAnchor: 'end',
                dy: '0.15em',
                dx: '0.10em',
              })}
              tickStroke={tokens.colors.$secondaryText}
            />
          )}

          {showAxisBottomInfo && (
            <AxisBottom
              hideAxisLine
              scale={bottomScale}
              stroke={tokens.colors.$secondaryText}
              tickLabelProps={() => ({
                fill: tokens.colors.$secondaryText,
                fontSize: 11,
                textAnchor: 'middle',
              })}
              tickStroke='transparent'
              top={yMax + margin.top}
            />
          )}
        </Group>
      </svg>

      {bottomTitle && (
        <Text color='$secondaryText' size='$12px' style={{ display: 'block', marginTop: '12px' }} textAlign='center'>
          {bottomTitle}
        </Text>
      )}
    </Box>
  );
};
