import { DraftStyleMap } from 'draft-js';
import { CSSProperties } from 'react';

export function generateThreadsStyleMap(threadIds: string[]): DraftStyleMap {
  const threads = threadIds.map((threadId) => [threadId, generateThreadStyle(threadId)] as const);

  return Object.fromEntries(threads);
}

function generateThreadStyle(threadId: string): DraftStyleMap {
  return {
    '--thread-id': threadId,
    ...commentStyles,
  } as DraftStyleMap;
}

const commentStyles: CSSProperties = {
  // TODO: Move value to tokens after designs will be provided
  background: '#FFE9A8',
  cursor: 'pointer',
};
