import { styled, tokens } from '@taraai/design-system';
import { getSelectedTeamId } from 'components/app/GlobalNav/helpers';
import { Tab, Tabs } from 'components/core/controllers/views/Tabs';
import { linkTo } from 'components/Router/paths';
import { UseLoadChartsData } from 'firestore/gitInsights/chartData/LoadChartsData';
import React, { useMemo, useState } from 'react';
import deepEquals from 'react-fast-compare';
import { Container, setConfiguration } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { getOrgTeams, selectActiveWorkspace, selectPreferredTeamId, useAddInsightChartData } from 'reduxStore';

import { LoadingController } from './Shared/LoadingController';
import { InjectDisplayFlexProps } from './Shared/utils';
import { TeamDropdown } from './TeamDropdown/TeamDropdown';
import { TimeSwitcher } from './TimeSwitcher/TimeSwitcher';
import { CustomerTab } from './Views/Customer/CustomerTab';
import { TeamTab } from './Views/Team/TeamTab';

export function GitInsightsPage(): JSX.Element | null {
  const orgId = useSelector(selectActiveWorkspace);
  const orgTeams = useSelector(getOrgTeams(orgId).selector, deepEquals);
  const preferredTeamId = useSelector(selectPreferredTeamId(orgId));
  const teamId = getSelectedTeamId(orgTeams || []) || preferredTeamId;

  const INSIGHTSPATH = linkTo('gitlabInsights', { orgId, teamId });

  const tabs = useMemo(
    (): Tab[] => [
      {
        label: 'Customer Value',
        route: INSIGHTSPATH,
        dataCy: 'CUSTOMER',
      },
      {
        label: 'Team Health',
        route: INSIGHTSPATH,
        dataCy: 'TEAM',
      },
    ],
    [INSIGHTSPATH],
  );

  const handleTabChange = (_tab: Tab): void =>
    setSelectedTab(tabs.find((tab) => tab.label === _tab.label) || ({} as Tab));

  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  // Grid Setup
  setConfiguration({ defaultScreenClass: 'xxl', gridColumns: 12 });

  // TODO: Change this to Redux Selector
  const addChartMetrics = useAddInsightChartData();

  const chartData = UseLoadChartsData();

  const showLoading = chartData === null;

  if (chartData !== null) addChartMetrics(chartData);

  return (
    <PageContainer>
      <Container>
        <Tabs onTabChange={(_tab) => handleTabChange(_tab)} selectedTab={selectedTab} tabs={tabs} variant='insights' />
        <FiltersContainer>
          <FiltersArea>
            <TeamDropdown />
            <TimeSwitcher />
          </FiltersArea>
        </FiltersContainer>
        <LoadingController dataToWatch={showLoading}>
          {selectedTab.label === 'Customer Value' ? <CustomerTab /> : <TeamTab />}
        </LoadingController>
      </Container>
    </PageContainer>
  );
}

const PageContainer = styled('div', {
  // TODO: Add darkmode colors to tokens. For now, just hard code in the background color.
  background: tokens.colors.$darker,
  color: tokens.colors.$white,
  height: '100%',
  width: '100%',
  padding: '4.5rem 0 4.5rem 0',
  overflow: 'auto',
});

const FiltersContainer = styled('div', {
  width: '100%',
  padding: '24px 0',
});

const FiltersArea = styled('div', {
  ...InjectDisplayFlexProps,
  justifyContent: 'space-between',
  height: '38px',
  minHeight: '38px',
});
