import { Fluid, HStack, styled } from '@taraai/design-system';
import Button from 'components/core/controllers/views/Button';
import Icon from 'components/core/controllers/views/Icon';
import React from 'react';

type SelectButtonProps = {
  active: boolean;
  name?: string;
  clearSelection: () => void;
  openPopup: () => void;
  children: React.ReactNode;
};

export const SelectButton = ({ active, name, clearSelection, openPopup, children }: SelectButtonProps): JSX.Element => {
  const clear = (event: React.MouseEvent): void => {
    event.stopPropagation();
    clearSelection();
  };

  return (
    <FilterButton color='ghost' name={name} onClick={openPopup} size='compact'>
      <Fluid>
        <HStack alignY='center'>
          <Fluid>{children}</Fluid>
          {active ? <ExitIcon name='exit' noPadding onClick={clear} /> : <Icon name='expdown' noPadding />}
        </HStack>
      </Fluid>
    </FilterButton>
  );
};

const FilterButton = styled(Button, {
  display: 'flex',
  justifyContent: 'space-between',
  height: '100%',
  width: '100%',
});

const ExitIcon = styled(Icon, { 'color': '$grey6', 'width': '0.75rem', ':hover': { color: '$focus' } });
