import { Box, HStack, Text } from '@taraai/design-system';
import { Attachment } from '@taraai/types';
import React from 'react';

type Props = {
  attachment: Attachment;
  onRemove: (attachment: Attachment) => void;
};

export function EditorAttachment({ attachment, onRemove }: Props): JSX.Element {
  return (
    <a href={attachment.url} rel='noopener noreferrer' target='_blank'>
      <Box background='$dark' borderRadius='$2px' space='$8px'>
        <HStack alignY='center' space='$8px'>
          {attachIcon}
          <Text color='$grey2' size='$12px'>
            {attachment.name}
          </Text>
          <Box.Button
            onClick={(event) => {
              event.preventDefault();
              onRemove(attachment);
            }}
          >
            {removeIcon}
          </Box.Button>
        </HStack>
      </Box>
    </a>
  );
}

const attachIcon = (
  <svg fill='none' height='16' viewBox='0 0 16 16' width='16' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12.2 10.33V5.2a4.2 4.2 0 00-8.4 0v7a2.8 2.8 0 005.6 0v-7a1.4 1.4 0 00-2.8 0v6.07'
      stroke='#949CAF'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
    />
  </svg>
);

const removeIcon = (
  <svg fill='none' height='12' viewBox='0 0 12 12' width='12' xmlns='http://www.w3.org/2000/svg'>
    <path
      clipRule='evenodd'
      d='M9.56 10.34c.11.1.25.16.4.16.14 0 .28-.06.38-.16a.54.54 0 000-.78L6.77 6l3.57-3.56a.54.54 0 000-.78.54.54 0 00-.78 0L6 5.23 2.44 1.66a.54.54 0 00-.78 0 .54.54 0 000 .78L5.23 6 1.66 9.56a.54.54 0 000 .78c.11.1.25.16.39.16s.28-.06.39-.16L6 6.77l3.56 3.57z'
      fill='#949CAF'
      fillRule='evenodd'
    />
  </svg>
);
