type AdjacentTriplet<T> = {
  prev: NonNullable<T> | undefined;
  current: T;
  next: NonNullable<T> | undefined;
};

export function* getAdjacentItemsIterator<T>(array: T[]): Generator<AdjacentTriplet<T>, void, unknown> {
  const withIndices = array.map((item, idx) => [idx, item] as const);
  // eslint-disable-next-line no-loops/no-loops
  for (const [idx, item] of withIndices) {
    const prevItem = array[idx - 1] ?? undefined;
    const nextItem = array[idx + 1] ?? undefined;
    yield {
      prev: prevItem,
      current: item,
      next: nextItem,
    };
  }
}

export function findInIterator<T>(iter: Generator<T>, pred: (val: T) => boolean): T | undefined {
  // eslint-disable-next-line no-loops/no-loops
  for (const item of iter) {
    if (pred(item)) {
      return item;
    }
  }
  return undefined;
}
