import { unwrapResult } from '@reduxjs/toolkit';
import LeaveWorkspaceModal from 'components/app/controllers/views/LeaveWorkspaceModal';
import { usePathParams } from 'components/Router/paths';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { leaveOrganization, useAppDispatch } from 'reduxStore';
import { selectOrganization } from 'reduxStore/organization/selectors';
import { strings } from 'resources/i18n';
import { useToast } from 'tools';

export default function LeaveWorkspaceController(): JSX.Element {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { orgId } = usePathParams('workspace');
  const org = useSelector(selectOrganization(orgId));
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const onSubmit = useCallback(() => {
    setIsLoading(true);
    dispatch(leaveOrganization())
      .then(unwrapResult)
      .then(() => history.push('/logout'))
      .finally(() => setIsLoading(false))
      .catch((error: Error) => {
        const message = error.message || strings.workspace.leaveWorkspace.error;
        addToast({
          message,
          timeoutMs: 3500,
          type: 'error',
        });
      });
  }, [addToast, dispatch, history, setIsLoading]);

  return <LeaveWorkspaceModal isLoading={isLoading} onSubmit={onSubmit} organizationName={org.name} />;
}
