import Button from 'components/core/controllers/views/Button';
import Modal from 'components/core/controllers/views/Modal';
import { css, cx } from 'emotion';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ConnectWithGithub, pendingPR, reviewPR } from 'resources/assets';
import { strings } from 'resources/i18n';

/**
 * GithubLearnMore displays the learn more modal
 */
export default function GithubLearnMore(): JSX.Element {
  const history = useHistory();

  return (
    <Modal
      bodyStyle={css`
        min-width: 50rem;
        max-width: 50rem;
      `}
      footer={
        <div
          className={css`
            display: flex;
            width: 100%;
            justify-content: center;
          `}
        >
          <Button
            className={cx(css`
              margin: 0rem;
              padding: 0rem;
            `)}
            color='primary'
            onClick={(): void => history.goBack()}
          >
            {strings.dashboard.pullRequest.okay}
          </Button>
        </div>
      }
      header={
        <div
          className={css`
            color: var(--dark);
            font-size: 16px;
            font-weight: 500;
            display: flex;
            width: 100%;
            justify-content: center;
          `}
        >
          {strings.dashboard.pullRequest.learnMoreModal.title}
        </div>
      }
    >
      <div
        className={css`
          display: flex;
          flex-direction: row;
          width: 100%;
        `}
      >
        <div
          className={css`
            display: flex;
            flex-direction: column;
            width: 30%;
            margin: 0.625rem;
          `}
        >
          <img alt={strings.dashboard.pullRequest.learnMoreModal.connectGithubTitle} src={ConnectWithGithub} />
          <div
            className={css`
              font-size: 0.875rem;
              font-weight: 600;
            `}
          >
            {strings.dashboard.pullRequest.learnMoreModal.connectGithubTitle}
          </div>
          <div
            className={css`
              color: var(--slate-grey);
              line-height: 1.46;
              font-size: 0.8125rem;
              margin-top: 1rem;
            `}
          >
            {strings.dashboard.pullRequest.learnMoreModal.connectGithubBody}
          </div>
        </div>
        <div
          className={css`
            display: flex;
            flex-direction: column;
            width: 30%;
            margin: 0.625rem;
          `}
        >
          <img alt={strings.dashboard.pullRequest.learnMoreModal.seePRs} src={pendingPR} />
          <div
            className={css`
              font-size: 0.875rem;
              font-weight: 600;
            `}
          >
            {strings.dashboard.pullRequest.learnMoreModal.seePRs}
          </div>
          <div
            className={css`
              color: var(--slate-grey);
              line-height: 1.46;
              font-size: 0.8125rem;
              margin-top: 1rem;
            `}
          >
            {strings.dashboard.pullRequest.learnMoreModal.seePRsBody}
          </div>
        </div>
        <div
          className={css`
            display: flex;
            flex-direction: column;
            width: 30%;
            margin: 0.625rem;
          `}
        >
          <img
            alt={strings.dashboard.pullRequest.learnMoreModal.reviewPRs}
            src={reviewPR}
            style={{ height: '163px' }}
          />
          <div
            className={css`
              font-size: 0.875rem;
              font-weight: 600;
            `}
          >
            {strings.dashboard.pullRequest.learnMoreModal.reviewPRs}
          </div>
          <div
            className={css`
              color: var(--slate-grey);
              line-height: 1.46;
              font-size: 0.8125rem;
              margin-top: 1rem;
            `}
          >
            {strings.dashboard.pullRequest.learnMoreModal.reviewPRsBody}
          </div>
        </div>
      </div>
    </Modal>
  );
}
