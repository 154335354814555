/* eslint-disable @typescript-eslint/no-explicit-any */
import { AnyAction } from '@reduxjs/toolkit';
import { DefaultRootState } from 'react-redux';

let globalState: DefaultRootState;
/**
 * @deprecated: If you find yourself using this, either your slice is too small or
 * the data flow is not the right one
 */
export function getGlobalState(): DefaultRootState {
  return globalState;
}

/**
 * Injects the global state as a MW
 * @deprecated: This is a bad practice
 */
export const hackForSearchReducer = (storeAPI: any) => {
  return (next: any) => (action: AnyAction) => {
    globalState = storeAPI.getState() as DefaultRootState;
    return next(action);
  };
};
