import { createAsyncThunk } from '@reduxjs/toolkit';
import { Functions } from '@taraai/types';
import { ExtraAPI } from 'reduxStore/utils/types';

export const updateSprintSettings = createAsyncThunk(
  'UpdateSprintSettings',
  async (payload: Functions.UpdateSprintSettings.Payload, { extra }): Promise<void> => {
    const { getFirebase } = extra as ExtraAPI;

    await getFirebase().functions().httpsCallable('updateSprintSettings')(payload);
  },
);
