/* eslint-disable react-hooks/rules-of-hooks */
import { unwrapResult } from '@reduxjs/toolkit';
import { Box } from '@taraai/design-system';
import { Attachment, Data, Markdown } from '@taraai/types';
import { UIRequirement } from '@taraai/types/dist/ui';
import { parseMentionsFromPlainText } from '@taraai/utility';
import { useRequirementTitleConfig } from 'components/editor/config';
import { makeCopyDocumentTitle } from 'components/editor/utils';
import { linkTo } from 'components/Router/paths';
import { updateRequirement } from 'firestore';
import React, { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  createRequirement,
  getPathForRequirement,
  removeAttachment,
  selectActiveTeam,
  useAppDispatch,
} from 'reduxStore';
import { strings } from 'resources';
import { useToast } from 'tools';
import { segment } from 'tools/libraries/analytics';
import { formatI18n } from 'tools/libraries/helpers/formatI18n';
import { useForceSave } from 'tools/reconciliation/useForceSave';

import { RemirrorDefinePanel } from './RemirrorDefinePanel';

type Props = { orgId: Data.Id.OrganizationId; requirement: UIRequirement };

export function DefineRequirement({ requirement, orgId }: Props): JSX.Element | null {
  const dispatch = useAppDispatch();
  const { addToast } = useToast();
  const path = getPathForRequirement(requirement.id, orgId);
  const teamId = useSelector(selectActiveTeam);
  const history = useHistory();

  const description = requirement.description ?? null;
  const title = requirement.title ?? null;
  const attachments = useMemo(() => requirement.attachments ?? [], [requirement]);

  const handleAttachmentRemove = useCallback(
    (attachment: Attachment): void => {
      dispatch(removeAttachment({ type: 'task', attachment, path }))
        .then(unwrapResult)
        .catch((error: Error) => {
          const message = formatI18n(strings.attachments.uploadError)({
            errorMessage: error.message,
          });
          addToast({ message, type: 'error' });
        });
    },
    [addToast, dispatch, path],
  );

  const titleConfig = useRequirementTitleConfig({ singleLine: false });

  const uploadDescriptionMarkdown = useCallback(
    async (newDescription: Markdown): Promise<string | Error | null> => {
      try {
        await updateRequirement(orgId, {
          description: newDescription,
          id: requirement.id,
          mentionedUserIds: parseMentionsFromPlainText(newDescription),
        });
        return newDescription;
      } catch (err) {
        const error = err as Error;
        addToast({ message: error.message, type: 'error' });
        return error;
      }
    },
    [addToast, orgId, requirement.id],
  );

  const uploadTitle = useCallback(
    async (newTitle: string): Promise<string | Error | null> => {
      try {
        await updateRequirement(orgId, { title: newTitle, id: requirement.id });
        return newTitle;
      } catch (err) {
        const error = err as Error;
        addToast({ message: error.message, type: 'error' });
        return error;
      }
    },
    [addToast, orgId, requirement.id],
  );
  const {
    forceSave: forceSaveDescription,
    trySave: trySaveDescription,
    saveState: descriptionSaveState,
    getLatestOutOfSyncValue: getLatestOutOfSyncValueDescription,
  } = useForceSave(description, uploadDescriptionMarkdown);

  const { trySave: trySaveTitle } = useForceSave(title, uploadTitle);

  const createNewDocument = useCallback(async (): Promise<void> => {
    const currentMarkdown = getLatestOutOfSyncValueDescription();
    try {
      await dispatch(
        createRequirement({
          title: makeCopyDocumentTitle(title, requirement.id, 'requirement'),
          description: currentMarkdown,
          assignedTeamIds: [teamId],
          attachments,
        }),
      )
        .then(unwrapResult)
        .then(({ id: requirementID }) => {
          segment.track('RequirementCreated', { orgID: orgId, requirementID, location: 'WorkDrawer' });
          history.replace(linkTo('requirement', { orgId, teamId, requirementId: requirementID }));
        });
    } catch (error) {
      addToast({ type: 'error', message: strings.requirements.failedToCreateRequirement });
    }
  }, [
    addToast,
    attachments,
    dispatch,
    getLatestOutOfSyncValueDescription,
    history,
    orgId,
    requirement.id,
    teamId,
    title,
  ]);

  useEffect(() => {
    document.title = strings.formatString(strings.titles.requirement, { title: requirement.title }) as string;
    return () => {
      // Restore the original page title when the component unmounts
      document.title = strings.titles.default;
    };
  }, [requirement.title]);

  if (!requirement) return <Container />;

  return useMemo(
    () => (
      <Container>
        <RemirrorDefinePanel
          attachments={attachments}
          createNewDocument={createNewDocument}
          description={requirement.description}
          descriptionSaveState={descriptionSaveState}
          forceSaveDescription={forceSaveDescription}
          handleAttachmentRemove={handleAttachmentRemove}
          id={requirement.id}
          placeholder={strings.editor.placeholder}
          title={requirement.title}
          titleConfig={titleConfig}
          trySaveDescription={trySaveDescription}
          trySaveTitle={trySaveTitle}
          type='requirements'
        />
      </Container>
    ),
    [
      attachments,
      createNewDocument,
      descriptionSaveState,
      forceSaveDescription,
      handleAttachmentRemove,
      requirement.description,
      requirement.id,
      requirement.title,
      titleConfig,
      trySaveDescription,
      trySaveTitle,
    ],
  );
}

function Container({ children }: { children?: ReactNode }): JSX.Element {
  return (
    <Box background='$white' center='horz' full spaceTop='$24px'>
      {children}
    </Box>
  );
}
