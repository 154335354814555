import { ContentBlock, DraftStyleMap } from 'draft-js';

const codeStyle = {
  'backgroundColor': '$grey1',
  'border': '1px solid colors.$grey4',
  'borderRadius': '0.125rem',
  'fontFamily': '$code !important',
  'fontWeight': '$codeMedium',

  '& *': {
    color: 'inherit',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
  },
};

export const containerStyle = {
  'color': '$dark',
  'fontSize': '$14px',
  'lineHeight': '$14px',

  'h1, h2, h3, h4, h5, h6, p': {
    fontSize: 'inherit',
    lineHeight: 'inherit',
    margin: '$8px $none',
    padding: 0,
  },

  'h1, h2, h3, h4, h5, h6': {
    fontWeight: '$bold',
  },

  'h1': {
    fontSize: '$18px',
    lineHeight: '$18px',
  },

  'h2': {
    fontSize: '$16px',
    lineHeight: '$16px',
  },

  'ul, ol': {
    marginBottom: 0,
    marginTop: 0,
  },

  'ul > li > div, ol > li > div': {
    marginLeft: '0.25rem',
  },

  'ul > li.public-DraftStyleDefault-unorderedListItem': {
    'listStyle': 'none',

    '&:before': {
      content: "''",
      position: 'absolute',
      left: '-1rem',
      top: '0.5rem',
      height: '6px',
      width: '6px',
      borderRadius: '$circle',
      background: 'colors.$dark',
    },
  },

  'ol > li:before': {
    left: '-16px !important',
    textAlign: 'left !important',
  },

  'a': {
    'color': '$focus',
    'cursor': 'pointer',
    'textDecoration': 'none',
    '&:hover': {
      opacity: 0.6,
    },
    '&:focus': {
      outline: 'none',
    },
  },

  'blockquote': {
    borderLeft: 'space.$4px solid colors.$grey4',
    color: '$grey7',
    paddingLeft: '$8px',
    margin: '$8px $none',
  },

  // Apply style based on a custom css prop
  '[style*="--draft-js-inline-style-code"]': {
    ...codeStyle,
    'color': '$severity1',
    'padding': '$none $4px',

    /**
     * Join with the preceding code bit visually.
     * This happens when you have a different style in the middle of inline code.
     */
    '+ [style*="--draft-js-inline-style-code"]': {
      borderLeft: 'none',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      marginLeft: 'calc($4px * -1)',
      paddingLeft: 0,
    },
  },

  // Multiple (multi-line) code blocks
  '.public-DraftStyleDefault-pre': {
    ...codeStyle,
    'color': '$dark',
    'fontSize': '$12px',
    'margin': '$8px 0',
    'padding': '$4px $12px',

    '[style*="--draft-js-inline-style-code"]': {
      backgroundColor: 'initial',
      border: 'initial',
      borderRadius: 'initial',
      margin: 'initial',
      padding: 'initial',
    },
  },

  '.public-DraftEditorPlaceholder-root': {
    color: '$grey6',
    opacity: 0.7,
  },
};

export const flexEditorWrapper = {
  // We need to permeate all of the Draft.js layers so that we reach the editable content
  '&, > div, > div > div, > div > div > div, > div > div > div > div': {
    display: 'flex',
    flex: '1 0 auto',
    flexDirection: 'column',
  },
};

export const styleMap = {
  'CODE': {
    '--draft-js-inline-style-code': 1,
  },
  // @TODO this should be generated from comments in Firestore
  'comment-1': {
    background: 'pink',
  },
} as DraftStyleMap;

export const blockStyleFn = (block: ContentBlock): string => block.getType();
