import { Box, Fluid, getCustomSize, HStack, styled, Text, tokens, Tooltip, VStack } from '@taraai/design-system';
import Button from 'components/core/controllers/views/Button';
import Icon from 'components/core/controllers/views/Icon';
import React, { useState } from 'react';
import { getCurrentUserIdToken } from 'reduxStore';
import { strings } from 'resources/i18n';
import { useToast } from 'tools';

const apiIconSize = getCustomSize(32);
const idTokenMaxWidth = getCustomSize(300);

export function ApiAuthentication(): JSX.Element {
  const [idToken, setIdToken] = useState<string | undefined>();
  const [isTokenCopied, setIsTokenCopied] = useState(false);

  const generateCurrentUserIdToken = async (): Promise<void> => {
    const currentUserIdToken = await getCurrentUserIdToken();
    setIdToken(currentUserIdToken);
  };

  const refreshCurrentUserIdToken = async (): Promise<void> => {
    await generateCurrentUserIdToken();
    setIsTokenCopied(false);
  };

  return (
    <Box spaceBottom='$24px'>
      <VStack space='$16px'>
        <Text color='$grey6' size='$14px' uppercase>
          {strings.integrations.api.header}
        </Text>
        <Box background='$white' shadow='$card' spaceHorz='$16px' spaceVert='$24px'>
          <HStack alignY='center' space='$8px'>
            <Box space='$8px'>
              <ApiIcon borderRadius='$8px' center height={apiIconSize} width={apiIconSize}>
                <ApiIconText size='$12px' weight='bold'>
                  {strings.integrations.api.icon}
                </ApiIconText>
              </ApiIcon>
            </Box>
            <Fluid>
              <VStack>
                <Text color='$dark' size='$16px'>
                  {strings.integrations.api.title}
                </Text>
                <Subtitle size='$14px'>
                  {strings.formatString(strings.integrations.api.subtitle, {
                    link: (
                      <DocumentationLink color='$focus' size='$14px'>
                        <a
                          href='https://help.tara.ai/en/articles/5498852-the-tara-api'
                          rel='noopener noreferrer'
                          target='_blank'
                        >
                          {strings.integrations.api.documentation}
                        </a>
                      </DocumentationLink>
                    ),
                  })}
                </Subtitle>
              </VStack>
            </Fluid>
            <Box maxWidth={idTokenMaxWidth} width='$full'>
              <VStack space='$12px'>
                {idToken ? (
                  <ShowIdToken idToken={idToken} isTokenCopied={isTokenCopied} setIsTokenCopied={setIsTokenCopied} />
                ) : (
                  <CreateIdToken generateCurrentUserIdToken={generateCurrentUserIdToken} />
                )}
                <Footer refresh={idToken ? refreshCurrentUserIdToken : undefined} />
              </VStack>
            </Box>
          </HStack>
        </Box>
      </VStack>
    </Box>
  );
}

function Footer({ refresh }: { refresh?: () => Promise<void> }): JSX.Element {
  const { addToast } = useToast();

  function handleRefresh(): void {
    if (refresh) {
      refresh();
      addToast({
        message: strings.integrations.api.refreshSuccess,
        timeoutMs: 2500,
      });
    }
  }

  return (
    <HStack>
      {refresh && (
        <RefreshButton color='$focus' onClick={handleRefresh} size='$12px'>
          {strings.integrations.api.refresh}
        </RefreshButton>
      )}
      <Fluid />
      <Text size='$12px'>{strings.integrations.api.tokenTime}</Text>
    </HStack>
  );
}

function ShowIdToken({
  idToken,
  isTokenCopied,
  setIsTokenCopied,
}: {
  idToken: string;
  isTokenCopied: boolean;
  setIsTokenCopied: (value: boolean) => void;
}): JSX.Element {
  const copyToken = (): void => {
    if (idToken) {
      navigator.clipboard.writeText(idToken);
      setIsTokenCopied(true);
    }
  };

  return (
    <Box border='$grey4' borderRadius='$4px' space='$8px'>
      <HStack alignY='center'>
        <Fluid>
          <Text singleLine size='$14px'>
            {idToken}
          </Text>
        </Fluid>
        <Tooltip title={isTokenCopied ? strings.inviteUserPopup.copied : strings.inviteUserPopup.copy}>
          <Icon color={tokens.colors.$focus} name='copyInvite' noPadding onClick={copyToken} />
        </Tooltip>
      </HStack>
    </Box>
  );
}

function CreateIdToken({
  generateCurrentUserIdToken,
}: {
  generateCurrentUserIdToken: () => Promise<void>;
}): JSX.Element {
  return (
    <CreateButton color='green' name='create invite link' onClick={generateCurrentUserIdToken} size='fullWidth'>
      <CopyIcon name='copy' />
      {strings.integrations.api.createApiKey}
    </CreateButton>
  );
}

const Subtitle = styled(Text, { color: '#575f65' });

const DocumentationLink = styled(Text, { ':hover': { color: '#575f65' } });

const RefreshButton = styled(Text, { 'cursor': 'pointer', ':hover': { textDecoration: 'underline' } });

const CreateButton = styled(Button, {
  padding: '0.6rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const ApiIcon = styled(Box, {
  background: 'linear-gradient(198.29deg, #F4F4F9 9.47%, #D3DEFF 146.18%)',
  boxShadow: '0rem 0.025rem 0.0375rem rgba(111, 104, 162, 0.4), inset 0rem -0.025rem 0.05rem rgba(0, 0, 0, 0.03)',
});

const ApiIconText = styled(Text, { color: '#808EFF' });

const CopyIcon = styled(Icon, {
  color: 'white',
  padding: '0rem',
  verticalAlign: 'sub',
  paddingRight: '0.3125rem',
});
