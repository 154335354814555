import { compose, createSelector } from '@reduxjs/toolkit';
import { isLoaded } from '@taraai/read-write';
import { Data, UI, User } from '@taraai/types';
import SprintDetailsLayout from 'components/app/layouts/SprintDetailsLayout';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getUsers, selectActiveTeam, selectActiveWorkspace, selectTeam } from 'reduxStore';
import { strings } from 'resources/i18n';
import shallowEqual from 'shallowequal';
import { findInIterator, getAdjacentItemsIterator } from 'tools/helpers/iterators';
import { collator, sort } from 'tools/libraries/helpers/sort';
import { markPage, Marks } from 'tools/utils/perfmarks';

import { useDataLoader } from './useDataLoader';

/** Pass through only active sprint and sprints that are before it. */
const isSprintBeforeOrActive =
  (activeSprint: UI.UISprint | undefined) =>
  (sprint: UI.UISprint): boolean => {
    if (!activeSprint) {
      // when there is no active sprint, show only completed ones
      return sprint.isComplete;
    }
    const isCurrentlyActiveSprint = activeSprint.id === sprint.id;
    const sprintStartsBeforeActive = activeSprint?.sprintNumber > sprint.sprintNumber;
    return sprintStartsBeforeActive || isCurrentlyActiveSprint;
  };

/**
 * SprintDetailsController does…
 * Accesses higher level data that will be passed to multiple child components in SprintDetailsLayout
 */

const byUnassigned = {
  desc: (tasksItem: UI.UITask): string | boolean => tasksItem.assignee === null && tasksItem.createdAt.valueOf(),
};
const byStatus = { asc: 'status' };
export default function SprintDetailsController(): JSX.Element | null {
  const { sprintId: sprintIdParam } = useParams<{
    sprintId: Data.Id.SprintId;
  }>();

  const orgId = useSelector(selectActiveWorkspace);
  const teamId = useSelector(selectActiveTeam);

  const {
    activeSprint,
    completedSprints,
    requirements,
    selectedSprint,
    selectedSprintData,
    sprintTasks,
    sprintTasksSelector,
    upcomingSprint,
  } = useDataLoader();

  const currentSprintId = useSelector(compose((data) => data?.currentSprintId, selectTeam(orgId, teamId)));
  const sprintId = sprintIdParam === 'current' ? currentSprintId : sprintIdParam;

  const usernamesById: Record<Data.Id.UserId, User['name']> | undefined = useSelector(
    compose(
      (users) => users?.reduce((record, user) => ({ ...record, [user.id]: user.name }), {}),
      getUsers(orgId).selector,
    ),
    shallowEqual,
  );

  const byAssigneeName = {
    asc: (tasksItem: UI.UITask): string =>
      (usernamesById && tasksItem.assignee !== null && usernamesById[tasksItem.assignee]) || '',
    comparer: collator.compare,
  };

  const sortedTasksSelector = createSelector(sprintTasksSelector, (allTasks) =>
    sort(allTasks || [], [byUnassigned, byAssigneeName, byStatus]),
  );

  const sprintsToSort = activeSprint ? [...completedSprints, ...upcomingSprint] : completedSprints;

  const allSprintIdsSorted = sort(Array.from(sprintsToSort), 'sprintNumber')
    .filter(isSprintBeforeOrActive(activeSprint))
    .map((sprint) => sprint.id);

  const adjacentSprints = findInIterator(
    getAdjacentItemsIterator(allSprintIdsSorted),
    ({ current }) => current === selectedSprint?.id,
  );

  const lastCompletedFromInactiveSprint =
    !selectedSprint?.id && allSprintIdsSorted.slice(allSprintIdsSorted.length - 1)[0];

  // Consider page loaded when there is no sprint id
  const loaded = !sprintId || (isLoaded(selectedSprint) && isLoaded(sprintTasks));

  useEffect(() => {
    document.title = strings.titles.progress;
    return () => {
      // Restore the original page title when the component unmounts
      document.title = strings.titles.default;
    };
  }, []);

  if (!isLoaded(currentSprintId)) {
    return null;
  }

  if (loaded) {
    markPage(Marks.PageSprintDetails, true);
  }

  return (
    <SprintDetailsLayout
      currentSprintId={currentSprintId}
      isLoaded={loaded}
      nextSprintId={adjacentSprints?.next || null}
      organizationId={orgId}
      previousSprintId={adjacentSprints?.prev || lastCompletedFromInactiveSprint || null}
      requirements={requirements}
      selectedSprint={selectedSprint}
      selectedSprintData={selectedSprintData}
      tasksSelector={sortedTasksSelector}
    />
  );
}
