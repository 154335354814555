import { styled } from '@taraai/design-system';
import ImportModalController from 'components/app/controllers/ImportModalController';
import { SlackConnectAccountNotificationBar } from 'components/app/controllers/SlackConnectAccountNotificationBar/SlackConnectAccountNotificationBar';
import { SprintCompletedNotificationBarController } from 'components/app/controllers/SprintCompletedNotificationBarController';
import TaskModalController from 'components/app/controllers/TaskModalController';
import { DNDProvider } from 'components/app/DragAndDrop';
import { GlobalNav } from 'components/app/GlobalNav';
import { UpgradeModalProvider, useUpgradeModal } from 'components/app/monetization/UpgradeModalContext';
import { SprintSettingsProvider } from 'components/app/SprintPage';
import React from 'react';
import { useSelector } from 'react-redux';
import { getSubscriptionType, hasFeature, selectActiveWorkspace } from 'reduxStore';

interface ScaffoldProps {
  children?: React.ReactNode;
  isStorybookRender?: boolean;
}

const Scaffold: React.FC = ({ children, isStorybookRender }: ScaffoldProps) => {
  const orgId = useSelector(selectActiveWorkspace);
  const premiumGatingEnabled = useSelector(hasFeature('premiumGating', orgId));
  const subscriptionType = useSelector(getSubscriptionType(orgId));
  const isGated = subscriptionType === null && premiumGatingEnabled;
  const { handleOpenCloseUpgradeModal, handleModalType } = useUpgradeModal();

  return (
    // TODO: remove this storybook fix when find way to mock new data layer API
    <UpgradeModalProvider isStorybookRender={isStorybookRender}>
      <SprintSettingsProvider>
        <SlackConnectAccountNotificationBar />
        <SprintCompletedNotificationBarController />
        <Container>
          <GlobalNav />
          <ImportModalController />
          <TaskModalController />
          <DNDProvider
            isGated={isGated}
            onUpgradeCardDrop={handleOpenCloseUpgradeModal}
            setUpgradeModalType={handleModalType}
          >
            <Main>{children}</Main>
          </DNDProvider>
        </Container>
      </SprintSettingsProvider>
    </UpgradeModalProvider>
  );
};

export default Scaffold;

const Main = styled('main', {
  backgroundColor: '#ffffff',
  height: 'calc(100% - 3rem)',
  width: '100%',
});

const Container = styled('div', {
  display: 'flex',
  width: '100%',
  height: '100%',
  flexDirection: 'column',
});
