import { Data, UI } from '@taraai/types';
import Logo from 'components/core/controllers/views/Logo';
import Text from 'components/core/controllers/views/Text';
import { css } from 'emotion';
import React from 'react';
import { atomic, strings } from 'resources';

type IntegrationWorkspaceSelectViewProps = {
  service: Data.InstallableService;
  userOrgs: UI.UIOrganization[];
  onWorkspaceClick: (orgId: Data.Id.OrganizationId) => (event: React.MouseEvent<HTMLElement>) => void;
};

export function WorkspaceSelectLayout({
  userOrgs,
  onWorkspaceClick,
  service,
}: IntegrationWorkspaceSelectViewProps): JSX.Element {
  return (
    <div
      className={css`
        margin: auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: fit-content;
        width: 21.875rem;
      `}
    >
      <Text
        className={css`
          text-align: center;
          margin-bottom: 2rem;
          margin-top: 6.25rem;
          color: ${atomic.get(atomic.colors.dark)};
        `}
        h3
      >
        {strings.setupIntegration[service].selectWorkspace.title}
      </Text>
      <div
        className={css`
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.14);
          border: solid 1px ${atomic.get(atomic.colors.grey5)};
          border-radius: 3px;
          background: ${atomic.get(atomic.colors.white)};
        `}
      >
        {userOrgs.map((org) => (
          <button
            key={org.id}
            className={css`
              padding: 1rem;
              height: 3.4375rem;
              width: 100%;
              display: flex;
              align-items: center;
              cursor: pointer;
              background: none;
              outline: 0;
              border: none;
              &:not(:first-child) {
                border-top: solid 1px ${atomic.get(atomic.colors.grey5)};
              }
              &:hover {
                background-color: ${atomic.get(atomic.colors.grey1)};
              }
            `}
            onClick={onWorkspaceClick(org.id)}
            type='button'
          >
            <Logo color={org.color} name={org.name} shape='square' size='medium' url={org.logoURL} />
            <Text
              className={css`
                color: ${atomic.get(atomic.colors.dark)};
                text-align: left;
                margin-left: 0.5rem;
                padding-bottom: 0.25rem;
              `}
              h4
            >
              {org.name}
            </Text>
          </button>
        ))}
      </div>
    </div>
  );
}
