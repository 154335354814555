import { Popup, styled, Text } from '@taraai/design-system';
import { Data } from '@taraai/types';
import Icon from 'components/core/controllers/views/Icon';
import React from 'react';
import { strings } from 'resources';

type Props = {
  hasConnectedData: boolean;
  showPopup: boolean;
  taskId: Data.Id.TaskId;
  setShowPopup: (show: boolean) => void;
};

export const InfoPopup = ({ hasConnectedData, showPopup, taskId, setShowPopup }: Props): JSX.Element | null => {
  if (!hasConnectedData) return null;
  return (
    <Popup
      content={
        <Popup.Content small>
          <Text size='$12px'>
            {strings.formatString(strings.taskSidebar.modules.git.addTaskIdNote, {
              taskId: strings.formatString(strings.task.taskId, { taskId }) as string,
            })}
          </Text>
        </Popup.Content>
      }
      onHide={() => setShowPopup(false)}
      placement='bottom-end'
      show={showPopup}
    >
      <StyledIcon name='merge' noPadding onMouseEnter={() => setShowPopup(true)} />
    </Popup>
  );
};

const StyledIcon = styled(Icon, {
  height: '0.75rem',
  width: '0.75rem',
});
