import Firebase from 'firebase/compat/app';

// Bump this number to force clear firestore persistence for all users
export const FIRESTORE_PERSISTENCE_VERSION = 4;

export const FIRESTORE_PERSISTENCE_VERSION_KEY = 'firestore_persistence_version';

export function getLocalFirestorePersistenceVersion(): number {
  const versionString = localStorage.getItem(FIRESTORE_PERSISTENCE_VERSION_KEY);
  const version = versionString && parseInt(versionString, 10);
  return version && !isNaN(version) ? version : 0;
}

export async function maybeClearFirestorePersistence(firestore: Firebase.firestore.Firestore): Promise<void> {
  if (getLocalFirestorePersistenceVersion() < FIRESTORE_PERSISTENCE_VERSION) {
    await firestore.clearPersistence();
  }
  localStorage.setItem(FIRESTORE_PERSISTENCE_VERSION_KEY, FIRESTORE_PERSISTENCE_VERSION.toString());
}
