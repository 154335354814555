/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';
import { Functions } from '@taraai/types';
import { UICompleteSprint } from '@taraai/types/dist/ui/sprint';
import { isNonEmptyString } from '@taraai/utility';
import { hideSprintCompletingNotification, showSprintCompletingNotification } from 'reduxStore/sprints/reducers';
import { decode } from 'reduxStore/utils/decoders';
import { ExtraAPI } from 'reduxStore/utils/types';
import { strings } from 'resources';
import { segment } from 'tools/libraries/analytics';

import { createSprint } from './create';

export const completeSprint = createAsyncThunk(
  'CompleteSprint',
  async ({ id: sprintId, nextSprintId, moveTask }: UICompleteSprint, { dispatch, extra }) => {
    const { getOrgId, getFirebase } = extra as ExtraAPI;
    const orgId = getOrgId();

    if (!isNonEmptyString(sprintId)) throw strings.sprints.error.missingSprintId;

    let moveRemainingTasksTo;
    switch (moveTask) {
      case 'SetSprint':
        moveRemainingTasksTo = nextSprintId;
        break;
      case 'NewSprint':
        moveRemainingTasksTo = await dispatch(createSprint())
          .then(unwrapResult)
          .then((success: any) => {
            segment.track('SprintCreated', {
              orgID: orgId,
              sprintID: success.id,
              location: 'SprintComplete',
            });
            return success.id;
          });
        break;
      case 'Backlog':
        moveRemainingTasksTo = null;
        break;
      default:
        moveRemainingTasksTo = null;
    }

    const payloadDraft: Functions.CompleteSprint.Payload = {
      orgId,
      sprintId,
      moveRemainingTasksTo,
    };

    const payload = decode<Functions.CompleteSprint.Payload>(payloadDraft, 'CompleteSprintPayload');

    await dispatch(showSprintCompletingNotification({ sprintId }));

    await getFirebase().functions().httpsCallable('completeSprint')(payload);

    await dispatch(hideSprintCompletingNotification());

    return { id: moveRemainingTasksTo };
  },
);
