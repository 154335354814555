import { Box, Fluid, HStack, styled } from '@taraai/design-system';
import flow from 'lodash.flow';
import React, { useEffect, useRef, useState } from 'react';
import { onBackspaceDown, onEnterDown } from 'tools/helpers';
import { useDebounced } from 'tools/utils/hooks/useDebounced';

type Props = {
  initialFocus?: boolean;
  onDelete?: () => void;
  onEnter: (text: string) => void;
  placeholder: string;
};

export function WorkDrawerInlineInput({ placeholder, onEnter, initialFocus = false, onDelete }: Props): JSX.Element {
  const [text, setText] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (initialFocus) inputRef.current?.focus();
  }, [initialFocus]);

  const handleOnBackspace = (content: string): void => {
    if (!content.length) onDelete?.();
  };
  const debouncedDelete = useDebounced(handleOnBackspace, 1100);

  return (
    <Wrapper background='$grey1' border={['$grey1', '$2px']} spaceLeft='$4px' spaceVert='$16px'>
      <Box spaceVert='$2px'>
        <HStack alignY='center'>
          {/* This Box is a placeholder for an arrow to align the input with the text in headers. */}
          <Box height='$16px' width='$16px' />
          <Fluid>
            <StyledInput
              ref={inputRef}
              onChange={(event) => setText(event.target.value)}
              onKeyDown={flow(
                onEnterDown(() => onEnter(text)),
                onBackspaceDown(() => debouncedDelete(text)),
              )}
              placeholder={placeholder}
            />
          </Fluid>
        </HStack>
      </Box>
    </Wrapper>
  );
}

const Wrapper = styled(Box, {
  border: 'borderWidths.$1px solid transparent',
  borderRadius: '$2px',
});

const StyledInput = styled('input', {
  'border': 'none',
  'caretColor': 'colors.$focus',
  'width': '90%',
  '&:focus': {
    outline: 'none',
  },
  '::placeholder': {
    color: '$grey5',
    fontSize: '$12px',
    fontWeight: '$regular',
  },
});
