import { EditorCommand } from 'components/editor/editorCommand';
import { getFirstPassingResult } from 'components/editor/plugins/utils';
import { RichEditorPlugin } from 'components/editor/types';
import { getDefaultKeyBinding, KeyBindingUtil } from 'draft-js';
import React from 'react';

type KeyBinding = (event: React.KeyboardEvent) => EditorCommand | null;

const customKeyBindingMap: KeyBinding[] = [
  (event) =>
    KeyBindingUtil.hasCommandModifier(event) && event.shiftKey && event.keyCode === 88 // event.code = "KeyX"
      ? 'strikethrough'
      : null,
  (event) =>
    KeyBindingUtil.hasCommandModifier(event) && event.shiftKey && event.keyCode === 67 // event.code = "KeyC"
      ? 'code'
      : null,
  (event) =>
    hasCommandWithOptionModifier(event) && event.shiftKey && event.keyCode === 67 // event.code = "KeyC"
      ? 'code-block'
      : null,
  (event) =>
    KeyBindingUtil.hasCommandModifier(event) && event.shiftKey && event.keyCode === 55 // event.code = "Digit7"
      ? 'ordered-list-item'
      : null,
  (event) =>
    KeyBindingUtil.hasCommandModifier(event) && event.shiftKey && event.keyCode === 56 // event.code = "Digit8"
      ? 'unordered-list-item'
      : null,
  (event) =>
    KeyBindingUtil.hasCommandModifier(event) && event.shiftKey && event.keyCode === 57 // event.code = "Digit9"
      ? 'blockquote'
      : null,
];

export const keyBindingFn: NonNullable<RichEditorPlugin['keyBindingFn']> = getFirstPassingResult(
  customKeyBindingMap,
  Boolean,
  getDefaultKeyBinding,
);

const hasCommandWithOptionModifier = (event: React.KeyboardEvent): boolean =>
  event.altKey && (KeyBindingUtil.usesMacOSHeuristics() ? event.metaKey : event.ctrlKey);
