import { Box, fade, Fluid, HStack, Popup, styled, Text, tokens, VStack } from '@taraai/design-system';
import { useGetOrgMetricsMembers, useGetOrgMetricsMembersByIds } from 'firestore/gitInsights/members';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  gitInsightsSelectionState,
  useAddInsightSelection,
  useClearInsightSelections,
  useRemoveInsightSelection,
} from 'reduxStore';

import { placeholderHook } from './fakeData';
import { Options } from './Options';
import { SelectedItems } from './Selected';

export function TeamDropdown(): JSX.Element | null {
  const [showPopup, setShowPopup] = React.useState(false);
  const { defaultValue, allTeams } = placeholderHook;

  const { selected, selectionType } = useSelector(gitInsightsSelectionState);
  const selectedUsers = useGetOrgMetricsMembersByIds(selected) ?? [];

  return (
    <Popup
      content={<PopupContent allTeams={allTeams} />}
      onHide={() => setShowPopup(false)}
      placement='bottom-start'
      show={showPopup}
    >
      <Container onClick={() => setShowPopup((prev) => !prev)}>
        {selectedUsers?.length > 0 ? (
          <SelectedItems selected={selectedUsers} type={selectionType} />
        ) : (
          <Text color='$secondaryText' size='$14px'>
            {defaultValue}
          </Text>
        )}
      </Container>
    </Popup>
  );
}

const Container = styled(Box.Button, {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid',
  borderColor: 'rgba(255, 255, 255, 0.15)',
  color: '#fff',
  borderRadius: tokens.space.$4px,
  padding: `${tokens.space.$16px} ${tokens.space.$24px}`,
  width: 'fit-content',
});

type PopupContentProps = {
  allTeams: {
    id: string;
    name: string;
    members: {
      id: string;
      name: string;
    }[];
  }[];
};
function PopupContent({ allTeams }: PopupContentProps): JSX.Element {
  const state = useSelector(gitInsightsSelectionState);
  const add = useAddInsightSelection();
  const remove = useRemoveInsightSelection();
  const clear = useClearInsightSelections(allTeams[0].id);
  const addOrRemove = (id: string): void => {
    if (state.selected.includes(id)) {
      remove(id);
    } else {
      state.selected.length < 4 && add(id);
    }
  };

  const usersOptions = useGetOrgMetricsMembers()?.map((data) => ({ id: data.id, name: data.name }));

  return (
    <ContentContainer background='$darker' border='$white' space='$12px'>
      <VStack space='$12px'>
        <HStack>
          <Text color='$white' size='$12px' weight='bold'>
            Select users
          </Text>
          <Fluid />
          <Box.Button onClick={() => clear()}>
            <Text color='$secondaryText' size='$10px'>
              Clear All
            </Text>
          </Box.Button>
        </HStack>

        <OptionsContainer>
          <VStack>
            <Options
              addOrRemove={addOrRemove}
              items={
                (usersOptions as {
                  id: string;
                  name: string;
                }[]) || []
              }
              selected={state.selected}
              type={state.selectionType}
            />
          </VStack>
        </OptionsContainer>

        <Separator />
        <FooterContainer>
          <Text color='$white' size='$12px'>
            You can select 1-4 items to compare
          </Text>
        </FooterContainer>
      </VStack>
    </ContentContainer>
  );
}

const ContentContainer = styled(Box, {
  position: 'absolute',
  boxShadow: '$popup',
  border: `1px solid ${fade('$white', '$10%')}`,
  borderRadius: '0.1875rem',
  width: '18rem',
  cursor: 'default',
  pointerEvents: 'auto',
  outline: 'none',
});

const OptionsContainer = styled(Box, {
  maxHeight: '16rem',
  overflow: 'auto',
});

const FooterContainer = styled(Box, {
  position: 'sticky',
  bottom: 0,
  left: 0,
  right: 0,
  padding: '1rem',
  backgroundColor: '$darker',
  boxShadow: '$popup',
});

const Separator = styled('hr', {
  backgroundColor: fade('$white', '$10%'),
  height: '1px',
  border: 'none',
  width: '110%',
  marginLeft: '-5%',
});
