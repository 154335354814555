const effortLevelWithSpaceRegExp = /( )?\+([1-9]\d?\b)( )?/;

type TaskWithEffortLevel<Task> = Task & { effortLevel?: number | undefined };

export function extractEffortLevel<Task extends { title: string }>(
  task: TaskWithEffortLevel<Task>,
): TaskWithEffortLevel<Task> {
  const result: TaskWithEffortLevel<Task> = { ...task };
  result.title = task.title.replace(effortLevelWithSpaceRegExp, (match, prefix, effortLevel, suffix, offset, title) => {
    result.effortLevel = +effortLevel;
    const isAtStartOrEnd = offset === 0 || offset + match.length === title.length;
    return prefix && suffix && !isAtStartOrEnd ? prefix : '';
  });
  return result;
}
