import { Box, VStack } from '@taraai/design-system';
import { Data, UI } from '@taraai/types';
import { Summary } from 'components/app/shared/Summary';
import React from 'react';
import deepEquals from 'react-fast-compare';
import { TaskGitData, UserFragment } from 'reduxStore';

import { TaskDetailsActivity } from './Activity/TaskDetailsActivity';
import { Assignee } from './Assignee';
import { Collaborators } from './Collaborators';
import { Comments } from './Comments';
import { Effort } from './Effort';
import { Git } from './Git';
import { Requirement } from './Requirement';
import { Sprint } from './Sprint';
import { Status } from './Status';
import { SubtasksSection } from './Subtasks/SubtasksSection';
import { SubtaskFragment } from './TaskDetails';
import { TitleController } from './Title/TitleController';

type Props = {
  comments?: UI.UIComment[];
  effortUnit: Data.EffortUnit;
  gitData?: TaskGitData;
  handleViewModal: () => void;
  orgId: Data.Id.OrganizationId;
  showSummary?: boolean;
  subtasksData?: SubtaskFragment[];
  subtasksIds: { id: Data.Id.TaskId }[];
  taskDeleteCallback: () => void;
  taskId: Data.Id.TaskId;
  teamId: Data.Id.TeamId;
  users: UserFragment[];
};

export const TaskDetailsView = React.memo(function TaskDetailsView({
  comments,
  effortUnit,
  gitData,
  handleViewModal,
  orgId,
  showSummary,
  subtasksData,
  subtasksIds,
  taskDeleteCallback,
  taskId,
  teamId,
  users,
}: Props): JSX.Element {
  return (
    <Box background='$grey1' height='$full' maxHeight='$full'>
      <VStack full space='$1px'>
        <TitleController
          orgId={orgId}
          subtasksIds={subtasksIds}
          taskDeleteCallback={taskDeleteCallback}
          taskId={taskId}
          teamId={teamId}
        />
        {showSummary && <Summary handleViewModal={handleViewModal} taskId={taskId} />}
        <Status taskId={taskId} />
        <Effort effortUnit={effortUnit} taskId={taskId} />
        <Assignee location='TaskDetails' orgId={orgId} taskId={taskId} users={users} />
        <Requirement taskId={taskId} />
        <Sprint taskId={taskId} />
        <Collaborators orgId={orgId} taskId={taskId} users={users} />
        <SubtasksSection subtasks={subtasksData} taskId={taskId} />
        <Comments comments={comments} onClickViewModal={handleViewModal} taskId={taskId} />
        <Git gitData={gitData} taskId={taskId} />
        <TaskDetailsActivity orgId={orgId} taskId={taskId} />
      </VStack>
    </Box>
  );
},
deepEquals);
