/*
 *  This file contains all unique test-ids that are used in cypress e2e tests.
 *  Used as attributes values make the tests more bulletproof against dynamic UI components.
 *  If there would expansion to support different langues in the future, this will allow the
 *  tests to work in those different configurations.
 */
export const requirementDetailsTestIds = {
  HEADER_NAME_INPUT: 'requirement-detail-name-input',
  HEADER_THREE_DOT_MENU: 'requirement-detail-menu-icon',
  ADD_TASK_INPUT: 'requirement-detail-add-task-input',
  SEARCH_TASK_INPUT: 'requirement-detail-search-task-input',
  TASK_AMOUNT_HEADER: 'requirement-detail-task-amount-text',
  INFORMATION_BADGE: 'requirement-detail-archived-badge',
};

export const requirementsListTestIds = {
  REQUIREMENT_NAME: 'requirement-list-name-text',
  REQUIREMENT_AUTHOR: 'requirement-list-author-text',
  REQUIREMENT_UPDATE_DATE: 'requirement-list-update-date-text',
  CREATE_REQUIREMENT_BUTTON: 'requirement-list-create-new-button',
  REQUIREMENTS_DESCRIPTION: 'requirement-list-description-text',
  ARCHIVE_REQUIREMENT: 'requirement-archive-menu',
  RESTORE_REQUIREMENT: 'requirement-unarchive-menu',
};

export const newRequirementTestIds = {
  REQUIREMENT_NAME_INPUT: 'new-requirement-name-input',
  REQUIREMENT_CONFIRM_BUTTON: 'new-requirement-confirm-button',
  REQUIREMENT_CANCEL_BUTTON: 'new-requirement-cancel-button',
};

export const avatarTestIds = {
  USER_AVATAR: 'user-avatar',
  USER_AVATAR_NO_PHOTO: 'user-avatar-initials',
  REQUIREMENT_AVATAR: 'requirement-avatar',
};

export const dropDownTestIds = {
  TEXT_INPUT: 'dropdown-text-input',
  CLOSE_POPUP_BUTTON: 'dropdown-close-button',
};
export const homePageTestIds = {
  TARA_LOGO: 'home-page-tara-logo',
  HOME_ICON: 'home-page-home-icon',
  PLAN_NEW_SPRINT_BUTTON: 'home-page-create-sprint-button',
  SPRINT_DATES: 'home-page-sprint-dates-text',
  VIEW_SPRINT_BUTTON: 'home-page-view-sprint-button',
  TASK_AMOUNT_ASSIGNED: 'home-page-task-assigned-count-text',
  USER_EFFORT_IN_SPRINT: 'home-page-user-effort-text',
  USER_PERCENT_DONE_IN_SPRINT: 'home-page-user-percent-done-text',
  TEAM_EFFORT_IN_SPRINT: 'home-page-team-effort-text',
  TEAM_PERCENT_DONE_IN_SPRINT: 'home-page-team-percent-done-text',
  PULL_REQUESTS: {
    GITHUB: {
      LINK_PROFILE: 'home-page-github-link-profile',
      INSTALL_REPOS: 'home-page-github-install-repos',
    },
  },
};

export const sprintsTabTestIds = {
  TOTAL_EFFORT: 'sprints-tab-total-effort-text',
  THREE_DOT_MENU_SPRINT: 'sprints-tab-three-dot-menu',
  START_SPRINT_BUTTON: 'sprints-tab-start-sprint-button',
  COMPLETE_SPRINT: 'sprints-tab-complete-sprint-button',
  DELETE_SPRINT: 'sprint-delete-menu',
  VIEW_SPRINT_DETAILS: 'sprints-tab-complete-sprint-button',
  SPRINT_EFFORT: 'sprints-tab-sprint-effort-text',
  CREATE_SPRINT_EMPTY_PAGE: 'sprints-tab-create-sprint-empty-page-button',
  CREATE_SPRINT_RIGHT_CORNER: 'sprints-tab-create-sprint-right-button',
  NEW_TASK_INPUT_SPRINT_COLUMN: 'sprints-tab-new-task-input-sprint-column',
  NEW_TASK_INPUT_SPRINT_BACKLOG: 'sprints-backlog-new-task-input',
  SPRINT_DATES: 'sprints-tab-sprints-date-text',
  SPRINT_EDIT_MODAL: {
    SPRINT_NAME_HEADER: 'sprint-edit-modal-name-header',
    EDIT_NAME_FIELD: 'sprint-edit-modal-name-input',
    SAVE_EDIT: 'sprint-edit-modal-save-button',
  },
};

export const sprintBacklogButtons = {
  TRELLO: 'sprint-backlog-trello-import-button',
  ASANA: 'sprint-backlog-asana-import-button',
};

export const gitlabIntegrationTestIds = {
  CONNECT_BUTTON: 'integrations-tab-gitlab-connect-button',
  MANAGE_BUTTON: 'integrations-tab-gitlab-manage-button',
  GENERATE_BUTTON: 'integrations-gitlab-modal-generate-token',
  DONE_BUTTON: 'integrations-gitlab-modal-done',
  URL_COPY: 'integrations-gitlab-modal-webhook-url-copy',
  TOKEN_COPY: 'integrations-gitlab-modal-secret-token-copy',
};

export const navigationDrawerButtons = {
  WORKSPACE_SWITCHER: 'mainnav-workspace-switch',
};
export const teamSwitcherDropdown = {
  TEAM_SWITCHER: 'team-switcher',
};

export const sprintColumnSortMenuItems = {
  NONE: 'sprint-column-sort-by-none',
  TASK_TITLE_AZ: 'sprint-column-sort-by-task-name-ascending',
  TASK_TITLE_ZA: 'sprint-column-sort-by-task-name-descending',
  ASSIGNEE: 'sprint-column-sort-by-assignee',
  TASK_STATUS: 'sprint-column-sort-by-task-status',
  DATE_CREATED_OLDEST: 'sprint-column-sort-by-date-created-oldest',
  DATE_CREATED_NEWEST: 'sprint-column-sort-by-date-created-newest',
};

export const sprintSettingsTestIds = {
  CANCEL_BUTTON: 'sprint-settings-cancel-button',
  SAVE_BUTTON: 'sprint-settings-save-button',
  RADIO_OPTION: 'sprint-settings-option-radio',
};

export const taskListAttributeTestIds = {
  STATUS_DROPDOWN: 'task-listview-status-dropdown',
  ASSIGNEE_DROPDOWN: 'task-listview-assignee-dropdown',
  SPRINT_DROPDOWN: 'task-listview-sprint-dropdown',
};

export const taskListTestIds = {
  TASK_STATUS_DROPDOWN_BUTTON: 'task-list-status-button',
  TASK_ID_TEXT: 'task-list-id-text',
  MANAGE_ASSIGNMENT_BUTTON: 'task-list-assignment-button',
  MANAGE_SPRINT_BUTTON: 'task-list-sprint-button',
};

export const taskModalTestIds = {
  AUTHOR_NAME: 'task-modal-author-text',
  CREATION_DATE: 'task-modal-create-date-text',
  UPDATE_DATE: 'task-modal-update-date-text',
  TASK_NAME: 'task-modal-task-name-text',
  TASK_ID: 'task-modal-task-id-text',
  REQUIREMENT: 'task-modal-requirement-text',
  THREE_DOT_MENU: 'task-modal-three-dot-menu',
  DELETE_TASK: 'task-delete-menu',
  EFFORT_INPUT: 'task-modal-effort-input',
  COPY_URL: 'click-copy-task-URL-icon',
  TASK_MODAL_ROW: {
    ASSIGN_TO_SELF: 'task-modal-assign-to-self',
    TASK_MODAL_WHOLE_ROW: 'task-modal-single-row',
    TASK_MODAL_SHORTCUT_CELL: 'task-modal-shortcut-cell',
    SHORTCUT_KEY_ICON: 'task-modal-shortcut-icon',
    ROW_NAME_TEXT: 'task-modal-row-name',
    TASK_MODAL_STATUS: 'task-status-change',
    BRANCH_URL: 'branch-snippet-copy-icon',
    COMMIT_URL: 'copy-commit-message-icon',
    SPRINT_DROPDOWN: 'task-modal-sprint-dropdown',
    REQUIREMENT_DROPDOWN: 'task-modal-requirement-dropdown',
    EFFORT_INPUT: 'task-modal-effort-input',
    ASSIGNEE_DROPDOWN: 'task-modal-assignee-dropdown',
    COLLABORATOR_DROPDOWN: 'task-modal-collaborator-dropdown',
  },
};

export const teamDetailsTestIds = {
  LEAVE_TEAM: 'team-details-leave-team-button',
  LEAVE_TEAM_MODAL_OPEN: 'team-details-leave-modal-open',
  LEAVE_TEAM_MODAL_CONFIRM_BUTTON: 'team-details-leave-modal-confirm-button',
  LEAVE_TEAM_MODAL_CANCEL_BUTTON: 'team-details-leave-modal-cancel-button',
  JOIN_TEAM: 'team-details-join-team-button',
  REMOVE_MEMBER_BUTTON: 'team-details-remove-member-button',
  SET_PREFERRED_BUTTON: 'team-details-set-preferred-button',
  EDIT_TEAM_NAME_BUTTON: 'team-details-edit-team-name-button',
  EDIT_TEAM_NAME_CANCEL_BUTTON: 'team-details-edit-team-name-cancel-button',
  EDIT_TEAM_NAME_CONFIRM_BUTTON: 'team-details-edit-team-name-confirm-button',
  DELETE_MODAL_DELETE_BUTTON: 'team-details-delete-team-button',
  DELETE_MODAL_CANCEL_BUTTON: 'team-details-delete-team-cancel-button',
};

export const workspaceTeamsTestIds = {
  // Team list
  CREATE_TEAM_BUTTON: 'workspace-settings-create-team-button',
  ADD_MEMBER_BUTTON_ELEMENT: 'workspace-settings-icon-group-add-member-button-element',
  ADD_MEMBER_BUTTON_PLUS: 'workspace-settings-icon-group-add-member-button-plus',
  JOIN_TEAM_BUTTON: 'workspace-settings-join-team-button',
};

export const teamSwitcherTestIds = {
  SEARCH_INPUT: 'members-icon-group-search-input',
  OPEN_SETTINGS: 'members-icon-group-open-team-settings',
  CREATE_TEAM_BUTTON: 'members-icon-group-create-team-button',
  TEAM_SELECT_ITEM: 'members-icon-team-select-item',
  MANAGE_TEAM_BUTTON: 'members-icon-group-manage-team-button',
};

export const inputTestIds = {
  INPUT_FIELD: 'input-field',
  CONFIRM_INPUT: 'input-confirm-button',
  CANCEL_INPUT: 'input-cancel-button',
};

export const createTeamModalTestIds = {
  TEAM_NAME_INPUT: 'create-new-team-popup-name-input',
  TEAM_CREATION_CREATE_BUTTON: 'create-new-team-create-button',
  TEAM_CREATION_CANCEL_BUTTON: 'create-new-team-cancel-button',
};

export const userProfileTestIds = {
  NAME_INPUT: 'user-profile-name-input',
  EMAIL_INPUT: 'user-profile-email-input',
  LOGOUT_BUTTON: 'user-profile-logout-button',
  SET_PREFERRED_TEAM_BUTTON: 'user-profile-set-preferred-team-button',
};

export const workspaceTestIds = {
  DELETE_MODAL_OPEN: 'workspace-delete-modal-open',
  DELETE_MODAL_CONFIRM_INPUT: 'workspace-delete-modal-confirm-input',
  DELETE_MODAL_CONFIRM_BUTTON: 'workspace-delete-modal-confirm-button',
  DELETE_MODAL_CANCEL_BUTTON: 'workspace-delete-modal-cancel-button',
  NAME_INPUT: 'workspace-name-input',
  URL_INPUT: 'workspace-url-input',
  LEAVE_MODAL_OPEN: 'workspace-leave-modal-open',
  LEAVE_MODAL_CONFIRM_BUTTON: 'workspace-leave-modal-confirm-button',
  LEAVE_MODAL_CANCEL_BUTTON: 'workspace-leave-modal-cancel-button',
  CREATE_NEW_BUTTON: 'workspace-switcher-create-new-button',
  SINGLE_WORKSPACE_ON_LIST: 'workspace-switcher-single-workspace',
  OPEN_SETTINGS: 'workspace-switcher-open-settings',
};

export const onboardingTestIds = {
  ORGANIZATION_NAME_INPUT: 'onboarding-screen-organization-name-input',
};

export const sendInvitePopupTestIds = {
  EMAIL_ADDRESSES: 'send-invite-addresses-input',
  SEND_INVITE_BUTTON: 'send-invite-button',
};

export const tableTestIds = {
  SINGLE_TABLE_ROW: 'table-row',
};

export const tabTestIds = {
  SINGLE_TAB: 'single-tab',
};

export const toastTestIds = {
  TOAST_CONTAINER: 'toast-container',
};

export const integrationTestIds = {
  TRELLO_IMPORT: 'trello-import-integrations-page',
  ASANA_IMPORT: 'asana-import-integrations-page',
  GITHUB_CONNECT: 'github-connect-integrations-page',
  GITLAB_CONNECT: 'gitlab-connect-integrations-page',
  SLACK_CONNECT: 'slack-connect-integrations-page',
  PROFILE_DISCONNECT: '{service}-profile-disconnect-button',
};

export const workspaceSetupTestIds = {
  FIRST_STEP_WORKSPACE_NAME_INPUT: 'workspace-setup-step-one-workspace-name-input',
  FIRST_STEP_WORKSPACE_SLUG_INPUT: 'workspace-setup-step-one-workspace-slug-input',
  FIRST_STEP_NEXT_BUTTON: 'workspace-setup-step-one-next-button',
  SECOND_STEP_INPUT: 'workspace-setup-step-two-{taskNumber}-task',
  SECOND_STEP_NEXT_BUTTON: 'workspace-setup-step-two-next-button',
  SECOND_STEP_SKIP_BUTTON: 'workspace-setup-step-two-skip-button',
  THIRD_STEP_NEXT_BUTTON: 'workspace-setup-step-three-next-button',
  FOURTH_STEP_NEXT_BUTTON: 'workspace-setup-step-four-go-to-sprint-button',
  STEP_INDICATOR_CONTAINER: 'workspace-setup-progress-indicator-wrapper',
};

export const globalNavTestIds = {
  HOME: 'global-nav-home',
  DEFINE: 'global-nav-define',
  SPRINT: 'global-nav-sprint',
  PROGRESS: 'global-nav-progress',
  INSIGHTS: 'global-nav-insights',
  SLACK: 'global-nav-slack',
  GITHUB: 'global-nav-github',
  GITLAB: 'global-nav-gitlab',
};

export const taskDetailsTestIds = {
  TASK_TITLE: 'task-sidebar-task-title',
  SUBTASK_TITLE: 'task-sidebar-subtask-title',
  SUBTASK_CHECKBOX: 'task-sidebar-subtask-title',
  SUBTASK_ASSIGNEE_DROPDOWN: 'task-sidebar-subtask-assignee-dropdown',
  GIT_DATA: 'task-sidebar-git-data',
  INFO_POPUP: 'task-sidebar-git-info-popup',
  VIEW_COMMENTS: 'task-sidebar-view-comments',
  NO_COMMENTS: 'task-sidebar-no-comments',
  NO_ACTIVITY: 'task-sidebar-no-activity',
  REVISION_ENTRY: 'task-sidebar-activity-revision-entry',
};

export const defineRequirementDetailsIds = {
  TEAM_SELECTOR: 'define-requirement-details-team-selector',
};
