import Avatar from 'components/core/controllers/views/Avatar';
import Text from 'components/core/controllers/views/Text';
import { css } from 'emotion';
import React from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import { getUsers, selectActiveWorkspace } from 'reduxStore';
import { atomic, strings } from 'resources';

export const MentionTooltipContent: React.FC<{ userId: string }> = ({ userId }) => {
  const orgId = useSelector(selectActiveWorkspace);
  const user = useSelector(
    compose((data) => data?.find((userData) => userData.id === userId), getUsers(orgId).selector),
  );
  if (!user) {
    // eslint-disable-next-line no-console
    console.error('Mention for non-existent user', userId);
    return <div>User not found</div>;
  }

  // Treat user as active also if there's no access level info available
  const isUserActive = !user.accessLevels?.[orgId] || user.accessLevels?.[orgId] !== 'deactivated';

  const tooltipContent = isUserActive
    ? user.name
    : strings.formatString(strings.editor.mentions.deactivatedUser, {
        user: user.name,
      });

  return (
    <div
      className={css`
        align-items: center;
        display: flex;
        min-width: 100px;
        padding: 11px 16px 10px 10px;
      `}
    >
      <Avatar size='2.5rem' user={user} />
      <Text
        className={css`
          ${atomic.color(isUserActive ? atomic.colors.dark : '#949caf')};
          font-size: 16px;
          font-stretch: normal;
          font-style: normal;
          font-weight: 600;
          letter-spacing: normal;
          line-height: 1.38;
          padding: 0 0.625rem;
        `}
      >
        {tooltipContent}
      </Text>
    </div>
  );
};
