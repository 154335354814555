import { createSlice } from '@reduxjs/toolkit';
import { Data } from '@taraai/types';

import { listOrganizationUsers, setUserAccessLevel } from './actions/listOrganizationUsers';

export interface UsersWithAccessLevelsMap {
  [userId: string]: Data.UserWithAccessLevel;
}

const slice = createSlice({
  name: 'organizationUsers',
  initialState: {} as {
    [orgId: string]: UsersWithAccessLevelsMap;
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      listOrganizationUsers.fulfilled,
      (state, { payload: { organizationId, usersWithAccessLevels } }) => ({
        ...state,
        [organizationId]: usersWithAccessLevels.reduce(
          (acc, user) => ({
            ...acc,
            [user.user.id]: user,
          }),
          {},
        ),
      }),
    );
    builder.addCase(setUserAccessLevel, (state, { payload: { accessLevel, organizationId, userId } }) =>
      state[organizationId]?.[userId]
        ? {
            ...state,
            [organizationId]: {
              ...(state[organizationId] || {}),
              [userId]: {
                ...state[organizationId][userId],
                accessLevel,
              },
            },
          }
        : state,
    );
  },
});

export const usersWithAccessLevelsReducer = slice.reducer;
