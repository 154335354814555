/* eslint-disable sonarjs/no-duplicate-string */
export default {
  dark: '--atomic-colors-dark',
  error: '--atomic-colors-error',
  error1: '--atomic-colors-error-1',
  error2: '--atomic-colors-error-2',
  error3: '--atomic-colors-error-3',
  focus: '--atomic-colors-focus',
  grey1: '--atomic-colors-grey-1',
  grey2: '--atomic-colors-grey-2',
  grey3: '--atomic-colors-grey-3',
  grey4: '--atomic-colors-grey-4',
  grey5: '--atomic-colors-grey-5',
  grey6: '--atomic-colors-grey-6',
  grey7: '--atomic-colors-grey-7',
  greySlate: '--atomic-colors-grey-slate',
  inherit: '--atomic-colors-inherit',
  initial: '--atomic-colors-initial',
  loading: '--atomic-colors-primary',
  primary: '--atomic-colors-primary',
  secondary: '--atomic-colors-secondary',
  success: '--atomic-colors-success',
  success1: '--atomic-colors-success-1',
  success2: '--atomic-colors-success-2',
  success3: '--atomic-colors-success-3',
  success4: '--atomic-colors-success-4',
  transparent: '--atomic-colors-transparent',
  upgrade: '--atomic-colors-primary',
  white: '--atomic-colors-white',
  black: '--atomic-colors-black',
};
