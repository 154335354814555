import { Box, HStack, Radio, Spacer, Text, VStack } from '@taraai/design-system';
import { Data } from '@taraai/types';
import React from 'react';
import { strings } from 'resources';

import { SprintSettingsViewProps } from './types';

const moveToValues = ['Backlog', 'NextSprint'] as const;

export const UncompletedTasks = ({ onChange, sprintSettings }: SprintSettingsViewProps): JSX.Element => (
  <Box background='$grey1' spaceHorz='$16px' spaceVert='$12px'>
    <VStack>
      <Text color='$dark' size='$14px'>
        {strings.sprintSettings.uncompletedTasks}
      </Text>
      <Spacer space='$4px' />
      <Text color='$grey6' size='$12px'>
        {strings.sprintSettings.selectWhereUncompletedTasks}
      </Text>
      <Spacer space='$16px' />
      <Radio.Group
        onChange={(moveRemainingTasksTo: Data.MoveRemainingTasksTo) =>
          onChange({ ...sprintSettings, moveRemainingTasksTo })
        }
        value={sprintSettings.moveRemainingTasksTo}
      >
        <HStack space='$16px'>
          {moveToValues.map((option) => (
            <Radio key={option} value={option}>
              <Text size='$12px' weight='medium'>
                {strings.sprintSettings.moveRemainingTasksTo[option]}
              </Text>
            </Radio>
          ))}
        </HStack>
      </Radio.Group>
    </VStack>
  </Box>
);
