import { isASCIIChar } from 'components/editor/plugins/utils';
import { RichEditorPlugin } from 'components/editor/types';
import { DraftHandleValue } from 'draft-js';

import { downgradeMentionsTransform, upgradeMentionsTransform } from './mention';
import { mentionDropdownDecorator } from './mentionDropdown';
import { mentionTagDecorator } from './mentionTag';

export function createMentionPlugin(
  getUserTagForId: (id: string) => string | undefined,
): RichEditorPlugin<'decorator' | 'read' | 'save' | 'handleBeforeInput'> {
  return {
    decorator: [mentionTagDecorator, mentionDropdownDecorator],
    handleBeforeInput: (chars: string): DraftHandleValue =>
      chars === '. ' && !isASCIIChar(chars) ? 'handled' : 'not-handled',
    read: upgradeMentionsTransform(getUserTagForId),
    save: downgradeMentionsTransform,
  };
}
