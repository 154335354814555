import { Data } from '@taraai/types';
import { tz } from 'moment-timezone';

/**
 * @param tzValue a standard value from tz database e.g. "Europe/Warsaw"
 * https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
 * @returns another value from tz database that maps to timezone dropdown selector entry
 */
export function mapTimezoneToShortListValue(tzValue: string): Data.Timezone {
  // Try matching by city
  const city = tzValue.split('/')[1];
  const matchingCityRecord = Data.TIMEZONES_SHORTLIST.find(({ label }) => label.includes(city));

  if (matchingCityRecord) {
    return matchingCityRecord.value;
  }

  // Filter by continent
  const continent = tzValue.split('/')[0];
  const matchingContinentList = Data.TIMEZONES_SHORTLIST.filter(({ value }) => value.includes(continent));

  // And find by offset (e.g. +1:00)
  const offsetStr = tz(tzValue).format('Z');
  const matchingContinentAndOffsetRecord = matchingContinentList.find(({ label }) => label.includes(offsetStr));

  if (matchingContinentAndOffsetRecord) {
    return matchingContinentAndOffsetRecord.value;
  }

  // If there are any continent matches, return first result
  if (matchingContinentList.length > 0) {
    return matchingContinentList[0].value;
  }

  // If that doesn't work, get entry with matching offset from full db
  const matchingOffsetRecord = Data.TIMEZONES_SHORTLIST.find(({ label }) => label.includes(offsetStr));
  if (matchingOffsetRecord) {
    return matchingOffsetRecord.value;
  }

  // If nothing works get LA tz
  return 'America/Los_Angeles';
}

export function guessTimezone(): Data.Timezone {
  const tzValue = tz.guess();
  return mapTimezoneToShortListValue(tzValue);
}
