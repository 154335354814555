import LinkifyIt from 'linkify-it';
import tlds from 'tlds';

export const linkifyIt = new LinkifyIt();
linkifyIt.tlds(tlds);

/**
 * Patched version of `linkifyIt.match()` that replaces
 * `http` protocol with `https` in matched results.
 */
export const matchLink = (text: string): LinkifyIt.Match[] | null => {
  const matches = linkifyIt.match(text);
  return (
    matches?.map((match) => {
      const url = match.url.replace('http://', 'https://');
      return {
        ...match,
        url,
      };
    }) ?? null
  );
};
