import { Data, UI } from '@taraai/types';
import { isNonEmptyString } from '@taraai/utility';
import { createStandardSelector, inertQuery, Query } from 'reduxStore/utils/selectors';
import { ReduxFirestoreQuery } from 'reduxStore/utils/types';

export const getOrgTeams = (orgId: Data.Id.OrganizationId): Query<UI.UITeam> => {
  if (!isNonEmptyString(orgId)) {
    return inertQuery();
  }

  const query: ReduxFirestoreQuery = {
    collection: `orgs/${orgId}/teams`,
    where: [
      ['isPublic', '==', true],
      ['deletedAt', '==', null],
    ],
    orderBy: ['createdAt', 'asc'],
    storeAs: `teams/${orgId}`,
  };

  return {
    query: [query],
    selector: createStandardSelector(query),
  };
};
