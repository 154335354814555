/* eslint-disable react-hooks/exhaustive-deps */
import { getUserMetricsPath } from '@taraai/utility';
import { collection, getFirestore, onSnapshot, query } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { selectActiveWorkspace, selectAuth } from 'reduxStore';

/**
 * @returns get first metrics document in the users sub collection
 *
 * in the future we should modify this to get a given orgs metrics but for now it just returns the first one
 */
export function useGetUserOrgMetricsOrgId(): string | null {
  const firestore = getFirestore();
  const orgId: string = useSelector(selectActiveWorkspace);
  const userId = useSelector(selectAuth).uid;

  // State where user Ids are set. Null is the default so it will function as the loading state
  const [userMetricsDocId, setUserMetricsDocId] = useState<string | null>(null);

  useEffect(
    () => {
      if (!orgId || !userId) return;

      const collectionRef = collection(firestore, getUserMetricsPath(userId));

      // setup collection query
      const collectionQuery = query(collectionRef);

      onSnapshot(
        collectionQuery,
        (querySnapshot) => {
          const orgMetricsDocumentIds = [] as string[];

          querySnapshot.forEach((doc) => {
            const orgDocId = doc.id;

            orgMetricsDocumentIds.push(orgDocId);
          });

          if (!deepEquals(orgMetricsDocumentIds[0], userMetricsDocId)) setUserMetricsDocId(orgMetricsDocumentIds[0]);
        },
        (error) => {
          throw new Error(error.message);
        },
      );
    },
    // Include only dependencies necessary to the UI to rerender to prevent looping
    [orgId, userId],
  );

  return userMetricsDocId;
}
