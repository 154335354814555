import { Box, getCustomSize, styled, Text, VStack } from '@taraai/design-system';
import { DroppableArea, DroppableType } from 'components/app/DragAndDrop';
import { DNDContext } from 'components/app/DragAndDrop/DNDProvider';
import { getDraggableDescription } from 'components/app/DragAndDrop/utils';
import React from 'react';
import { strings } from 'resources';

export const RemoveSprintArea = React.memo(function RemoveSprintArea() {
  return (
    <DNDContext.Consumer>
      {(value) => {
        if (
          value.isDragging &&
          getDraggableDescription(value.draggedElement || '').droppableType === DroppableType.sprint
        ) {
          return (
            <RemoveContainer>
              <DroppableArea
                description={{
                  id: 'RemoveSprintArea',
                  type: DroppableType.removeFromSprint,
                  visibleList: [],
                  list: [],
                }}
                draggingOverStyles={{
                  background: 'rgba(77, 91, 255, 0.1)',
                  borderColor: '#4D5BFF',
                  color: '#4D5BFF',
                }}
                showPlaceholder={false}
                styles={{
                  borderStyle: 'dashed',
                  borderWidth: '2px',
                  height: '100%',
                  color: '$grey7',
                }}
              >
                {(provided, snapshot) => {
                  return (
                    <Box full>
                      <VStack align='center' alignY='center' full>
                        <Text color={snapshot.isDraggingOver ? '$indigo' : '$dark'} size='$14px' textAlign='center'>
                          {RemoveIcon}
                          {strings.sprints.sprintColumn.removeFromSprint}
                        </Text>
                        <Text color={snapshot.isDraggingOver ? '$indigo' : '$dark'} size='$10px' textAlign='center'>
                          {strings.sprints.sprintColumn.returnTo}
                        </Text>
                      </VStack>
                    </Box>
                  );
                }}
              </DroppableArea>
            </RemoveContainer>
          );
        }
        return <></>;
      }}
    </DNDContext.Consumer>
  );
});

const RemoveContainer = styled(Box, {
  top: '0',
  height: getCustomSize(80),
  width: getCustomSize(360),
  background: 'colors.$grey4',
  opacity: '0.9',
  position: 'absolute',
  borderRadius: '$4px',
  zIndex: '1',
  margin: '0',
  left: '0',
});

function RemoveIcon({ color }: { color: string }): JSX.Element {
  return (
    <svg fill='none' height='13' viewBox='0 0 13 13' width='13' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0)'>
        <path
          d='M6.50098 11.7485C9.40047 11.7485 11.751 9.39803 11.751 6.49854C11.751 3.59904 9.40047 1.24854 6.50098 1.24854C3.60148 1.24854 1.25098 3.59904 1.25098 6.49854C1.25098 9.39803 3.60148 11.7485 6.50098 11.7485Z'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M8.5 6.5H4.5' stroke={color} strokeLinecap='round' />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect fill='white' height='12' transform='translate(0.5 0.5)' width='12' />
        </clipPath>
      </defs>
    </svg>
  );
}
