import { Data } from '@taraai/types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteComponentProps, RouteProps, useHistory, useParams } from 'react-router-dom';
import { isProfileDeactivated } from 'reduxStore';
import { RootState } from 'reduxStore/store';
import { setOriginalRouteCookie } from 'tools/utils/originalRoute';

/**
 * Render route only for authenticated users,
 * otherwise redirect to `/login`.
 */
const AuthenticatedRoute: React.FC<RouteProps> = ({ children, render, ...props }: RouteProps) => {
  const auth = useSelector((state: RootState) => state.firebase.auth);
  const { orgId } = useParams<{ orgId?: Data.Id.OrganizationId }>();
  const isDeactivated = useSelector(isProfileDeactivated(orgId ?? ''));

  const childrenProvided = React.Children.count(children) > 0;

  if (childrenProvided && render) {
    // TODO: better wording here
    // eslint-disable-next-line no-console
    console.warn('both children and render provided, will use render()');
  }

  const history = useHistory();

  const innerRender = (renderProps: RouteComponentProps): React.ReactNode => {
    if (auth.isEmpty) {
      // If user enters a route e.g. /task/123, while being logged out/unregistered
      // we want to keep the original route to be able to redirect him back after login/onboarding
      const originalRoute = history.location.pathname + history.location.search;
      setOriginalRouteCookie(originalRoute);

      return <Redirect to={{ pathname: '/login' }} />;
    }

    if (isDeactivated) {
      return <Redirect to={{ pathname: '/deactivated' }} />;
    }

    if (render) {
      return render(renderProps);
    }
    return <>{children}</>;
  };

  return <Route {...props} render={innerRender} />;
};

export default AuthenticatedRoute;
