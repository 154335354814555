import { createSelector, Selector } from '@reduxjs/toolkit';
import { Data, UI } from '@taraai/types';
import { compose } from 'redux';
import { getOrg, IndividualQuery, RootState, RootStateWithProfile, selectActiveOrgIds } from 'reduxStore';
import { ReduxFirestoreQuery } from 'reduxStore/utils/types';

export function getIsOnboardedSlice(orgId: Data.Id.OrganizationId | undefined): {
  query: ReduxFirestoreQuery[];
  selector: Selector<RootStateWithProfile, boolean | undefined>;
} {
  const orgSlice = getOrg(orgId);
  return {
    query: orgSlice.query,
    selector: createSelector([selectHasActiveOrgs, selectIsOnboarded(orgSlice)], (hasActiveOrgs, isOnboarded) =>
      orgId ? isOnboarded : hasActiveOrgs,
    ),
  };
}

const selectHasActiveOrgs = compose((activeOrgIds) => activeOrgIds.length > 0, selectActiveOrgIds);

const selectIsOnboarded = (orgSlice: IndividualQuery<UI.UIOrganization>): ((state: RootState) => boolean | undefined) =>
  compose((data): boolean | undefined => data && data.onboardingStatus === 'finished', orgSlice.selector);
