import ImportServicesListController from 'components/app/controllers/ImportServicesListController';
import IntegrationsController from 'components/app/controllers/IntegrationsController';
import { InsightsIntegrationController } from 'components/app/controllers/IntegrationsController/InsightsIntegrationController';
import { ApiAuthentication } from 'components/app/controllers/views/ApiAuthentication';
import IntegrationsHeader, { InsightsIntegrationHeader } from 'components/app/controllers/views/IntegrationsHeader';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectActiveWorkspace, selectOrganization } from 'reduxStore';

const IntegrationsLayout = (): JSX.Element => {
  const orgId = useSelector(selectActiveWorkspace);
  const org = useSelector(selectOrganization(orgId));
  return (
    <>
      {org.featureFlags.gitlabInsights && <InsightsIntegrationHeader />}
      {org.featureFlags.gitlabInsights && <InsightsIntegrationController />}
      <IntegrationsHeader />
      <IntegrationsController />
      <ApiAuthentication />
      <ImportServicesListController />
    </>
  );
};

export default IntegrationsLayout;
