import { HStack, Text } from '@taraai/design-system';
import { UI } from '@taraai/types';
import { EffortInput } from 'components/app/TaskDetails/common/EffortInput';
import { Section } from 'components/app/TaskDetails/common/Section';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectTaskValue } from 'reduxStore';
import { strings } from 'resources';

type Props = {
  effortUnit: UI.UIOrganization['effortUnit'];
  taskId: UI.UITask['id'];
};

export function Effort({ effortUnit, taskId }: Props): JSX.Element | null {
  const effortLevel = useSelector((state) => selectTaskValue(state, taskId, 'effortLevel'));

  return effortLevel ? (
    <Section
      right={
        <HStack space='$4px'>
          <EffortInput effortLevel={effortLevel} taskId={taskId} />
          <Text color='$grey6' size='$12px'>
            {strings.taskSidebar.modules.effort.units[effortUnit]}
          </Text>
        </HStack>
      }
      title={strings.taskSidebar.modules.effort.title}
    />
  ) : null;
}
