import { OptionDefinition, SelectProps } from 'components/core/controllers/views/Select/Select';
import { useCallback, useState } from 'react';

import { ValidationResult } from './commonTypes';

export interface UseSelectProps {
  initialSelectedOption?: OptionDefinition | null;
  initialValidationResult?: ValidationResult;
  options?: OptionDefinition[];
  onChange?: (option: OptionDefinition) => void;
  validator?: (option: OptionDefinition | null) => Promise<ValidationResult>;
  placeholder?: string;
}

/*
 * Hook to use with Select component
 */
export function useSelect({
  initialSelectedOption = null,
  initialValidationResult = { isValid: true, message: '' },
  options = [],
  validator,
  onChange,
  placeholder,
}: UseSelectProps): [OptionDefinition | null, ValidationResult, SelectProps] {
  const [selectedOption, setSelectedOption] = useState<OptionDefinition | null>(initialSelectedOption);
  const [validationResult, setValidationResult] = useState<ValidationResult>(initialValidationResult);

  const onChangeHandler = useCallback(
    async (option: OptionDefinition): Promise<void> => {
      setSelectedOption(option);
      setValidationResult((await validator?.(option)) || initialValidationResult);
      await onChange?.(option);
    },
    [initialValidationResult, onChange, validator],
  );
  return [selectedOption, validationResult, { onChange: onChangeHandler, options, placeholder }];
}
