/* eslint-disable react-hooks/exhaustive-deps */
import { Data } from '@taraai/types';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { selectActiveWorkspace } from 'reduxStore';

/**
 * returns requirement document fields based on fields arguments and requirement id
 * This should be used most places in the UI where a requirements data is to be rendered.
 *
 * @param requirementId
 * @param fields
 * @returns requested requirement fields of second argument
 */
export function useGetRequirementFields<K extends keyof Data.Requirement>(
  requirementId: string | null | undefined,
  fields: K[],
): Pick<Data.Requirement, K> | null {
  const firestore = getFirestore();
  const [requirementFields, setRequirementFields] = useState<Pick<Data.Requirement, K> | null>(null);
  const orgId = useSelector(selectActiveWorkspace);

  useEffect(() => {
    if (!requirementId) return;
    const requirementsRef = doc(firestore, `orgs/${orgId}/requirements`, requirementId);
    onSnapshot(
      requirementsRef,
      (querySnapshot) => {
        const docData = querySnapshot.data() as Data.Requirement;
        const docExists = querySnapshot.exists();
        if (docExists) {
          // TODO: resolve issues with decoder
          // const decodedDoc = decode<Data.Requirement>({ id: querySnapshot.id, ...docData }, 'UIRequirement');

          // pick document fields
          const requirementPartial = fields.reduce((acc, field) => ({ ...acc, [field]: docData[field] }), {}) as Pick<
            Data.Requirement,
            K
          >;

          // if changes update state
          if (!deepEquals(requirementPartial, requirementFields)) setRequirementFields(requirementPartial);
        }
      },
      (error) => {
        throw new Error(error.message);
      },
    );
  }, [requirementId]);

  return requirementFields;
}
