import { styled } from '@taraai/design-system';
import React from 'react';
import { Col, Row, setConfiguration } from 'react-grid-system';

import { CommitLifecycle } from './sections/CommitLifecycle';
import { CustomerValueBreakdown } from './sections/CustomerValueBreakdown';
import { CustomerValueByFeature } from './sections/CustomerValueByFeature';

export function CustomerTab(): JSX.Element {
  // Grid Setup
  setConfiguration({ defaultScreenClass: 'xxl', gridColumns: 12 });

  return (
    <>
      <GridRow>
        <Col lg={8} xl={8} xxl={8}>
          <CustomerValueBreakdown />
        </Col>

        <Col lg={4} xl={4} xxl={4}>
          <CommitLifecycle />
        </Col>
      </GridRow>

      <GridRow>
        <Col lg={12} xl={12} xxl={12}>
          <CustomerValueByFeature />
        </Col>
      </GridRow>
    </>
  );
}

const GridRow = styled(Row, {
  marginBottom: '$24px',
});
