/**
 * Simple helper class to enable strings with multiple
 * named variants.
 *
 * e.g. `myString` and `myString.error` as two separate, but related strings
 *
 * Why not use String{} directly? Because linters don't like it, as it is unclear.
 *
 * TODO: move to a Tara Custom Objects directory.
 */
export default class MultiString extends String {
  background?: string;

  default?: string;

  error?: MultiString | string;

  light?: string;

  primary?: MultiString | string;

  success?: MultiString | string;

  text?: string;

  variant?: string;

  white?: string;

  hover?: MultiString;

  hLink?: string;

  disabled?: string;
}
