import { Box, VStack } from '@taraai/design-system';
import { SprintDetailsSidebarDescription } from 'components/app/controllers/views/SprintDetailsSidebarDescription';
import { SprintDetailsSidebarName } from 'components/app/controllers/views/SprintDetailsSidebarName';
import { RichEditorHandle } from 'components/editor/RichEditor';
import React, { Ref } from 'react';

interface SprintDetailsSidebarHeaderViewProps {
  counter?: {
    currentLength: number;
    maxLength: number;
  };
  isEditing?: boolean;
  onCancel?: () => void;
  onEdit?: () => void;
  onSubmit?: () => void;
  sprintName: string;
  sprintState?: 'active' | 'completed';
  submitDisabled?: boolean;
  descriptionRef: Ref<RichEditorHandle>;
}

export function SprintDetailsSidebarHeaderView({
  counter,
  isEditing,
  onCancel,
  onEdit,
  onSubmit,
  sprintName,
  sprintState,
  submitDisabled,
  descriptionRef,
}: SprintDetailsSidebarHeaderViewProps): JSX.Element {
  return (
    <Box background='$white' borderBottom='$grey2' spaceVert='$12px'>
      <VStack space='$12px'>
        <SprintDetailsSidebarName
          isEditing={isEditing}
          onCancel={onCancel}
          onEdit={onEdit}
          onSubmit={onSubmit}
          sprintName={sprintName}
          sprintState={sprintState}
          submitDisabled={submitDisabled}
        />
        <Box spaceHorz='$12px'>
          <SprintDetailsSidebarDescription counter={counter} descriptionRef={descriptionRef} isEditing={isEditing} />
        </Box>
      </VStack>
    </Box>
  );
}
