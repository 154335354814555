/* eslint-disable sonarjs/no-duplicate-string */
import { Data, UI } from '@taraai/types';
import { CREATION_DEFAULTS } from '@taraai/types/dist/decoder';
import { getNumberAtEndAsString } from '@taraai/utility';
import uniq from 'lodash.uniq';
import { getNewSprintName } from 'reduxStore/sprints/actions/create';
import { decode } from 'reduxStore/utils/decoders';
import { Write } from 'reduxStore/utils/types';
import { durationMilliseconds, plusMilliseconds, toTimestamp } from 'tools';

const SERVER_TIMESTAMP = '::serverTimestamp';

type CreateSprintReads = {
  uid: Data.Id.UserId;
  newId: string;
  previousSprints: UI.UISprint[];
  task?: UI.UITask;
};
export const createSprint = ({ newId, uid, task, previousSprints }: CreateSprintReads): Write[] => {
  const previousSprint = previousSprints[0];
  const startDate = previousSprint.endDate;
  const duration = durationMilliseconds(previousSprint.endDate, previousSprint.startDate);
  const endDate = plusMilliseconds(startDate, duration);
  const sprintNumber = parseInt(getNumberAtEndAsString(previousSprint.sprintName) as string, 10);

  const writes = [] as Write[];
  const sprintWrite = decode<UI.UISprintCreation>(
    {
      id: newId,
      path: previousSprint.path,
      teamId: previousSprint.teamId,
      startDate: toTimestamp(startDate),
      endDate: toTimestamp(endDate),
      createdAt: ['::serverTimestamp'],
      updatedAt: ['::serverTimestamp'],
      sprintName: getNewSprintName(previousSprint, sprintNumber + 1),
      sprintNumber: previousSprint.sprintNumber + 1,
      orderedTaskIds: task ? [task.id] : [],
      totalTasksCount: task ? 1 : 0,
    },
    'UISprintCreation',
    CREATION_DEFAULTS,
  );

  writes.push(sprintWrite as unknown as Write);

  if (task && task?.path) {
    writes.push({
      id: task.id,
      path: task.path,
      sprint: newId,
      updatedBy: uid,
    });
  }

  if (task?.sprint && task?.path) {
    writes.push({
      id: task.sprint,
      path: task.path.replace('/tasks', '/sprints'),
      orderedTaskIds: ['::arrayRemove', task.id],
      carryOverTaskIds: ['::arrayRemove', task.id],
    });
  }

  return writes;
};

type InsertNearReads = {
  task: UI.UITask;
  destinationSprint: UI.UISprint;
  sourceSprint?: UI.UISprint;
  insertAfterId: Data.Id.TaskId | null;
  uid: Data.Id.UserId;
  removeFromCarryOver?: boolean;
  visibleList: string[] | null;
};
export const insertNear = ({
  task,
  destinationSprint,
  insertAfterId,
  uid,
  removeFromCarryOver,
  visibleList,
}: InsertNearReads): Write => {
  const { id, path } = destinationSprint;
  const orderedTaskIds = JSON.parse(JSON.stringify(visibleList || [])).filter(
    (taskID: Data.Id.TaskId) => taskID !== task.id && taskID !== null,
  );

  const index = orderedTaskIds.lastIndexOf(insertAfterId);

  orderedTaskIds.splice(index + 1, 0, task.id);

  const optionals = {} as Partial<UI.UISprintMutation>;

  if (removeFromCarryOver) {
    optionals.carryOverTaskIds = ['::arrayRemove', task.id];
  }

  return decode<UI.UISprintMutation>(
    {
      ...optionals,
      id,
      path,
      orderedTaskIds: uniq(orderedTaskIds),
      updatedBy: uid,
      updatedAt: [SERVER_TIMESTAMP],
    },
    'UISprintMutation',
  ) as unknown as Write;
};

type RemoveTaskReads = {
  uid: Data.Id.UserId;
  task: UI.UITask;
  sprint: UI.UISprint;
};

export const removeTask = ({ uid, task, sprint }: RemoveTaskReads): Write => {
  return decode<UI.UISprintMutation>(
    {
      id: sprint.id,
      path: sprint.path,
      updatedBy: uid,
      updatedAt: [SERVER_TIMESTAMP],
      carryOverTaskIds: ['::arrayRemove', task.id],
      orderedTaskIds: ['::arrayRemove', task.id],
    },
    'UISprintMutation',
  ) as unknown as Write;
};
