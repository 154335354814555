import { Box, Fluid, HStack, styled, Text } from '@taraai/design-system';
import Icon from 'components/core/controllers/views/Icon';
import Logo from 'components/core/controllers/views/Logo';
import { FastSmallSpinner } from 'components/core/controllers/views/Spinners';
import React from 'react';

export type OptionSize = 'small' | 'medium';

type Props = {
  title: string;
  isActive: boolean;
  isSelected: boolean;
  waiting: boolean;
  avatarURL?: string | null;
  mode?: 'default' | 'avatar';
  size?: OptionSize;
};

const sizeTextMap: Record<OptionSize, React.ComponentProps<typeof Text>['size']> = {
  small: '$14px',
  medium: '$16px',
} as const;

export const Option = ({
  title,
  isSelected,
  isActive,
  waiting,
  avatarURL,
  mode = 'default',
  size = 'medium',
}: Props): JSX.Element => (
  <Container align='center' alignY='center' isActive={isActive} space='$8px'>
    {mode === 'avatar' && <Logo name={title} shape='circle' size={size} url={avatarURL} />}
    <Fluid enabled='horizontal'>
      <Title color='$dark' isActive={isActive} size={sizeTextMap[size]}>
        {title}
      </Title>
    </Fluid>
    <Box>
      <Spinner isVisible={waiting} noPadding />
      <TickIcon isVisible={isSelected && !waiting} name='tick' noPadding />
    </Box>
  </Container>
);

const Container = styled(
  HStack,
  {
    'padding': '0.5rem 1rem',
    'transition': 'background 0.2s ease-in-out',
    'cursor': 'pointer',

    '&:hover': {
      backgroundColor: '$grey1',
    },

    '&:focus': {
      outline: 'none',
      border: 'none',
    },
  },
  {
    isActive: {
      true: {
        backgroundColor: '$grey1',
      },
    },
  },
);

const Title = styled(
  Text,
  {
    'transition': 'opacity 0.2s ease-in-out',
    '&:hover': { opacity: 1 },
  },
  {
    isActive: { true: { '&:hover': { opacity: 0.7 } } },
  },
);

const transitionOpacity = { transition: 'opacity 0.2s ease-in-out', opacity: 0 };
const isVisible = { isVisible: { true: { opacity: 1 } } };

const TickIcon = styled(Icon, transitionOpacity, isVisible);

const Spinner = styled(
  FastSmallSpinner,
  {
    ...transitionOpacity,
    position: 'absolute',
    top: 0,
    right: 0,
  },
  isVisible,
);
