import { Tab, Tabs } from 'components/core/controllers/views/Tabs';
import React, { ComponentProps, useCallback, useEffect, useRef } from 'react';
import { useQuerySetState, useQueryValue } from 'tools';

type Props = Omit<ComponentProps<typeof Tabs>, 'onTabChange' | 'selectedTab'> & {
  queryParamName?: string;
};

/**
 * TabBar renders a bar view of tabs to display components and labels
 */
export default function TabBar({ queryParamName = 'tab', tabs, ...props }: Props): JSX.Element {
  const tabFromQuery = useQueryValue(queryParamName);
  const setQuery = useQuerySetState();
  const initialSelectedTab = useRef(getSelectedTabFromQuery(tabs, tabFromQuery));

  const selectedTab = getSelectedTabFromQuery(tabs, tabFromQuery);

  const setSearchFromTab = useCallback(
    (tab?: Tab) => setQuery({ [queryParamName]: tab ? tab.route : null }, ({ [queryParamName]: value }) => !value),
    [queryParamName, setQuery],
  );

  useEffect(() => {
    setSearchFromTab(initialSelectedTab.current);
    return () => setSearchFromTab();
  }, [queryParamName, setSearchFromTab]);

  const handleTabChange = useCallback(
    (tab: Tab): void => {
      if (!tab.disabled) {
        setSearchFromTab(tab);
      }
    },
    [setSearchFromTab],
  );

  return (
    <>
      <Tabs {...props} onTabChange={handleTabChange} selectedTab={selectedTab} tabs={tabs} />
      {selectedTab.content}
    </>
  );
}

function getSelectedTabFromQuery(tabs: Tab[], tabQuery: string | null): Tab {
  return tabs.find((tab) => !tab.disabled && tab.route === tabQuery) ?? tabs.find((tab) => !tab.disabled) ?? tabs[0];
}
