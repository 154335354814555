import { Identifiable } from '@taraai/types';

export const isSelected = (option: Identifiable, selection: Identifiable | Identifiable[]): boolean => {
  const singleSelection = selection as Identifiable;
  const multiSelection = selection as Identifiable[];
  if (singleSelection.id) {
    return option.id === singleSelection.id;
  }
  return !!multiSelection.find((selectedOption) => selectedOption.id === option.id);
};
