/* eslint-disable sonarjs/cognitive-complexity */
import { unwrapResult } from '@reduxjs/toolkit';
import { ModalType } from 'components/app/monetization/UpgradeModalContext';
import React, { createContext, useCallback, useState } from 'react';
import { BeforeCapture, DragDropContext, DropResult } from 'react-beautiful-dnd';
import { moveSprintTask, useAppDispatch, useSearchMentions } from 'reduxStore';
import { createAndPopulateSprint } from 'reduxStore/sprints/actions/createAndPopulate';
import { removeFromSprint } from 'reduxStore/tasks/actions/removeFromSprint';
import { strings } from 'resources';
import { GATED_TASK_LIMIT, getInsertAfterId, getIsDropInPlanned, useToast } from 'tools';

import { DroppableType } from './types';
import { getDraggableDescription, getDroppableDescription } from './utils';

export type DragStatus =
  | {
      isDragging: true;
      draggedElement: string | null;
    }
  | { isDragging: false };

export const DNDContext = createContext<DragStatus>({ isDragging: false });

export const DNDProvider = ({
  children = [],
  onUpgradeCardDrop,
  setUpgradeModalType,
  isGated,
}: {
  children: JSX.Element | JSX.Element[];
  onUpgradeCardDrop?: () => void;
  setUpgradeModalType?: (type: ModalType) => void;
  isGated?: boolean;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { whenError } = useToast();
  const [globalDragStatus, setGlobalDragStatus] = useState<DragStatus>({
    isDragging: false,
  });
  const [assignee = undefined] = useSearchMentions();

  const onDragEnd = useCallback(
    ({ source, destination, draggableId }: DropResult): void => {
      setGlobalDragStatus({
        isDragging: false,
      });
      // Droped nowhere it can be droped, so nothing to do.
      if (!destination) return;

      // If same source && same location within the list, the element didn't move at all, so nothing to do
      if (!(source.droppableId !== destination.droppableId || source.index !== destination.index)) return;

      // we extract the actual element ID, so we remove the sourceType from the ID.
      // we extract the sourceType so we know if the sprint has to be cleaned up too
      // This is composed as ${droppableType}-${draggableId} to enable repeated elements in the screen
      const {
        id: draggableElementId,
        droppableType: originType,
        droppableId: originId,
      } = getDraggableDescription(draggableId);

      const deserializedDestination = getDroppableDescription(destination.droppableId);

      const { visibleList } = deserializedDestination;

      const removeFromCarryOver =
        getIsDropInPlanned({
          list: visibleList || deserializedDestination.list,
          targetIdx: destination.index,
        }) ||
        (originType === DroppableType.sprint && originId !== deserializedDestination.id);

      const insertAfterId = getInsertAfterId({
        list: visibleList || deserializedDestination.list,
        elementId: draggableElementId,
        targetIdx: destination.index,
      });

      if (
        deserializedDestination.type === DroppableType.sprint &&
        isGated &&
        visibleList &&
        visibleList.length > GATED_TASK_LIMIT &&
        onUpgradeCardDrop &&
        setUpgradeModalType
      ) {
        setUpgradeModalType('taskLimit');
        return onUpgradeCardDrop();
      }

      if (deserializedDestination.type === DroppableType.payWall && onUpgradeCardDrop && setUpgradeModalType) {
        setUpgradeModalType('sprintColumn');
        return onUpgradeCardDrop();
      }
      if (deserializedDestination.type === DroppableType.removeFromSprint && originId) {
        dispatch(
          removeFromSprint({
            sourceSprintId: originId,
            taskId: draggableElementId,
          }),
        )
          .then(unwrapResult)
          .catch(whenError(strings.task.failedToUpdateTask));
      } else if (deserializedDestination.type === DroppableType.requirement) {
        dispatch(
          moveSprintTask({
            destinationRequirementId: deserializedDestination.id,
            sourceSprintId: originType === DroppableType.sprint && originId ? originId : null,
            taskId: draggableElementId,
            insertAfterId,
            assignee,
            visibleList,
          }),
        )
          .then(unwrapResult)
          .catch(whenError(strings.task.failedToUpdateTask));
      } else if (deserializedDestination.type === DroppableType.sprint) {
        if (deserializedDestination.id === undefined) {
          dispatch(createAndPopulateSprint({ taskId: draggableElementId }))
            .then(unwrapResult)
            .catch((err) => {
              // Issue to be solved: https://app.tara.ai/tara-ai/team-tara/tasks/5361
              // Cause: The cache Reducer triggers a false positive error. The BE actually saved the changes properly,
              // but the read-write library returns error
              // eslint-disable-next-line no-console
              console.log(err);
              return true;
              // return whenError(strings.task.failedToUpdateTask);
            });
        } else {
          dispatch(
            moveSprintTask({
              destinationSprintId: deserializedDestination.id,
              sourceSprintId: originType === DroppableType.sprint && originId ? originId : null,
              taskId: draggableElementId,
              insertAfterId,
              assignee,
              removeFromCarryOver,
              visibleList,
            }),
          )
            .then(unwrapResult)
            .catch(whenError(strings.task.failedToUpdateTask));
        }
      } else if (
        deserializedDestination.type === DroppableType.workdrawerTasks &&
        originType !== DroppableType.workdrawerRepo
      ) {
        dispatch(
          moveSprintTask({
            taskId: draggableElementId,
            destinationSprintId: null,
            sourceSprintId: originType === DroppableType.sprint && originId ? originId : null,
            insertAfterId,
            assignee,
            visibleList,
          }),
        )
          .then(unwrapResult)
          .catch(whenError(strings.task.failedToUpdateTask));
      }
    },
    [isGated, onUpgradeCardDrop, setUpgradeModalType, dispatch, assignee, whenError],
  );

  const onBeforeCapture = useCallback((before: BeforeCapture): void => {
    setGlobalDragStatus({
      isDragging: true,
      draggedElement: before.draggableId,
    });
  }, []);

  return (
    <DragDropContext onBeforeCapture={onBeforeCapture} onDragEnd={onDragEnd}>
      <DNDContext.Provider value={globalDragStatus}>{children}</DNDContext.Provider>
    </DragDropContext>
  );
};
