"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.organizationDecoder = exports.accessLevelDecoder = void 0;
var ts_data_json_1 = require("ts.data.json");
exports.accessLevelDecoder = ts_data_json_1.JsonDecoder.oneOf([ts_data_json_1.JsonDecoder.isExactly('admin'), ts_data_json_1.JsonDecoder.isExactly('user'), ts_data_json_1.JsonDecoder.isExactly('deactivated')], 'AccessLevel');
exports.organizationDecoder = ts_data_json_1.JsonDecoder.object({
    accessLevel: exports.accessLevelDecoder,
}, 'CustomClaims.OrganizationStanza');
exports.default = ts_data_json_1.JsonDecoder.object({
    orgs: ts_data_json_1.JsonDecoder.dictionary(exports.organizationDecoder, 'CustomClaims.OrganizationsMap'),
}, 'CustomClaims');
