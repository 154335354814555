import { Box, HStack, Text } from '@taraai/design-system';
import React from 'react';

import { SprintSettingsViewProps } from './types';

export const Save = ({ state }: SprintSettingsViewProps): JSX.Element => (
  <Box background='$grey1' spaceHorz='$16px' spaceVert='$12px'>
    <Text size='$12px'>
      <HStack align='right' space='$4px'>
        <Box.Button
          background='$grey2'
          border='$grey5'
          borderRadius='$4px'
          center
          onClick={() => state.onReset()}
          space='$12px'
        >
          <p>Reset</p>
        </Box.Button>
        <Box.Button
          background='$success'
          border='$white'
          borderRadius='$4px'
          center
          onClick={() => state.onSave()}
          space='$12px'
        >
          <Text color='$white' size='$10px'>
            Save
          </Text>
        </Box.Button>
      </HStack>
    </Text>
  </Box>
);
