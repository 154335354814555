const ORIGINAL_ROUTE_KEY = 'original_route';
const SKIP_ROUTES = ['/'];

export const setOriginalRouteCookie = (originalRoute: string): void => {
  if (SKIP_ROUTES.includes(originalRoute)) return;
  localStorage.setItem(ORIGINAL_ROUTE_KEY, originalRoute);
};

export const getOriginalRouteCookie = (): string | null => {
  return localStorage.getItem(ORIGINAL_ROUTE_KEY);
};

export const removeOriginalRouteCookie = (): void => {
  localStorage.removeItem(ORIGINAL_ROUTE_KEY);
};
