import { linkTo } from 'components/Router/paths';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectPreferredTeamId } from 'reduxStore';
import { useLoadedOrgId } from 'tools/utils/hooks/useLoadedOrgId';

import { MobileNote } from './MobileNote';

export const OnboardingMobileNoteController: React.FC = () => {
  const orgId = useLoadedOrgId() || '';

  const preferredTeamId = useSelector(selectPreferredTeamId(orgId));

  const onClick = (): void => {
    window.history.pushState(null, '', linkTo('sprints', { orgId, teamId: preferredTeamId }));
    window.history.go();
  };

  return <MobileNote onClick={onClick} />;
};
