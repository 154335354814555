import { Box, styled, Text, VStack } from '@taraai/design-system';
import { Data } from '@taraai/types';
import { SmartText } from 'components/app/controllers/views/SmartText';
import { useTaskDescriptionConfig } from 'components/editor/config';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectTaskValue } from 'reduxStore';
import { strings } from 'resources';

type Props = {
  handleViewModal: () => void;
  taskId: Data.Id.TaskId;
};

export function Summary({ handleViewModal, taskId }: Props): JSX.Element {
  const description = useSelector((state) => selectTaskValue(state, taskId, 'description') ?? '');
  const config = useTaskDescriptionConfig({ onAttachment });
  return (
    <Box background='$grey1' borderBottom='$grey2' space='$12px'>
      <Box.Button onClick={handleViewModal}>
        <VStack space='$12px'>
          {description.length ? (
            <Description size='$12px'>
              <SmartText config={config} limit={300} text={description} />
            </Description>
          ) : null}
          <ActionText color='$focus' size='$12px'>
            {description.length ? strings.taskSidebar.editTaskDetails : strings.taskSidebar.noDescription}
          </ActionText>
        </VStack>
      </Box.Button>
    </Box>
  );
}

const Description = styled(Text, {
  overflow: 'hidden',
});

const ActionText = styled(Text, {
  ':hover': { textDecoration: 'underline' },
});

function onAttachment(): Promise<string> {
  return Promise.resolve('');
}
