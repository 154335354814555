import { Dropdown, HStack, Text } from '@taraai/design-system';
import { UI } from '@taraai/types';
import { isNonEmptyString } from '@taraai/utility';
import Avatar from 'components/core/controllers/views/Avatar';
import Fuse from 'fuse.js';
import React, { ReactElement, useMemo } from 'react';

const fuseOptions = {
  keys: ['name'],
  ignoreLocation: true,
};

export function UserDropdown({
  children,
  onSelect,
  searchQuery,
  show,
  users,
}: {
  children: ReactElement;
  onSelect: (user: UI.UIUser) => void;
  searchQuery: string;
  show: boolean;
  users: UI.UIUser[];
}): JSX.Element {
  const dropdownItems = useMemo(() => {
    const fuse = new Fuse(users, fuseOptions);
    const filteredUsers: Fuse.FuseResult<UI.UIUser>[] = isNonEmptyString(searchQuery)
      ? fuse.search(searchQuery)
      : users.map((user, index) => ({ item: user, refIndex: index }));
    return filteredUsers.map(({ item }) => (
      <Dropdown.DefaultItem key={item.id} onClick={() => onSelect(item)}>
        <HStack alignY='center' space='$8px'>
          <Avatar size='24px' user={item} />
          <Text color='$dark' size='$14px'>
            {item.name}
          </Text>
        </HStack>
      </Dropdown.DefaultItem>
    ));
  }, [onSelect, searchQuery, users]);

  return (
    <Dropdown
      items={dropdownItems}
      placement='bottom-start'
      preventBlurOnSelection
      selectOnTab
      show={show && dropdownItems.length > 0}
    >
      {children}
    </Dropdown>
  );
}
