import SprintInsightsController from 'components/app/controllers/SprintInsightsController';
import React from 'react';

/**
 * SprintInsights route for sprint insights modal page
 *
 */
export default function SprintInsights(): JSX.Element {
  return <SprintInsightsController />;
}
