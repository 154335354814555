import React from 'react';
import { strings } from 'resources';

import { FilterElementLayout } from './FilterElementLayout';
import { ProgressBackground } from './ProgressBackground';

export function PlannedEffortEntry({
  totalPlannedEffort,
  recommendedEffort,
}: {
  totalPlannedEffort: number;
  recommendedEffort: number;
}): JSX.Element {
  const isOverloaded = totalPlannedEffort > recommendedEffort;
  return (
    <ProgressBackground
      color={isOverloaded ? '$failure' : '$grey7'}
      mode={isOverloaded ? 'secondary' : 'primary'}
      progress={1}
    >
      <FilterElementLayout
        active
        color={isOverloaded ? '$failure' : '$grey7'}
        effort={totalPlannedEffort}
        icon={isOverloaded ? 'redAlert' : undefined}
        label={strings.sprintFilters.plannedEffort}
        summary={strings.formatString(strings.sprintFilters.recommendedEffort, { recommendedEffort }) as string}
      />
    </ProgressBackground>
  );
}
