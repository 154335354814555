import { styled, Text } from '@taraai/design-system';
import React from 'react';

import { LoadingImage } from './LoadingController';
import { InjectDisplayFlexProps } from './utils';

type EmptyDataProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataToWatch?: any;
};

export const EmptyData = ({ dataToWatch }: EmptyDataProps): JSX.Element => {
  return (
    <>
      {!!dataToWatch && dataToWatch.length === 0 && (
        <DataContainer>
          <Text color='$background' size='$16px'>
            No data available for this chart{' '}
            <span aria-label='chart emoji' role='img'>
              📈
            </span>
          </Text>
        </DataContainer>
      )}

      {dataToWatch === undefined && (
        <DataContainer>
          <LoadingImageStyled />
        </DataContainer>
      )}
    </>
  );
};
const DataContainer = styled('div', {
  height: '300px',
  ...InjectDisplayFlexProps,
  alignItems: 'center',
  margin: '35px',
});

const LoadingImageStyled = styled(LoadingImage, {
  width: '65%',
});
