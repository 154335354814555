import { Fluid, HStack, Text } from '@taraai/design-system';
import { PopupButton } from 'components/editor/plugins/shared/PopupButton';
import React from 'react';
import { strings } from 'resources/i18n';

type Props = { onCopy: () => void; onRemove: () => void; src: string };
export function ImagePopup({ onCopy, onRemove, src }: Props): JSX.Element {
  return (
    <HStack alignY='center' space='$16px'>
      <Fluid>
        <Text singleLine size='$16px'>
          <a href={src} rel='noopener noreferrer' target='_blank'>
            {src}
          </a>
        </Text>
      </Fluid>
      <PopupButton iconName='linkcopy' onClick={onCopy} title={strings.editor.attachments.copy} />
      <PopupButton iconName='modalexit' onClick={onRemove} title={strings.editor.attachments.remove} />
    </HStack>
  );
}
