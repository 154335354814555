"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.payloadDecoder = void 0;
var ts_data_json_1 = require("ts.data.json");
var custom_claims_1 = require("../decoders/custom-claims");
exports.payloadDecoder = ts_data_json_1.JsonDecoder.object({
    organizationId: ts_data_json_1.JsonDecoder.string,
    userId: ts_data_json_1.JsonDecoder.string,
    accessLevel: custom_claims_1.accessLevelDecoder,
}, 'Payload');
