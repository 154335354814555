import { keyframes } from 'emotion';

/**
 * Simple flash animation for select events.
 */
export const flash = keyframes`
    0% {
      background-color: #ffffff;
      color: #303f4b;
    }
    50% {
      background-color: #4550ce;
      color: #ffffff;
    }
  `;

/**
 * Hover state
 */
export const hover = {
  '&:hover, &:focus': {
    backgroundColor: '#4550ce',
    color: '#ffffff',
    outline: 'none',
    svg: {
      color: '#ffffff',
    },
  },
};

export const menuStyle = {
  backgroundColor: '#ffffff',
  minWidth: '8.375rem',
  maxHeight: '15.625rem',
  overflow: 'auto',
  borderRadius: '0.1875rem',
  right: 'auto',
  whiteSpace: 'nowrap',
  border: 'solid 0.0625rem #c8d0df',
  boxShadow: '0 0 0.625rem 0 rgba(0, 0, 0, 0.14)',
  position: 'absolute',
  zIndex: '500',
  fontSize: '0.875rem',
};

export const profileMenuStyle = {
  backgroundColor: '#ffffff',
  minWidth: '8.375rem',
  maxHeight: '18rem',
  overflow: 'auto',
  borderRadius: '0.1875rem',
  whiteSpace: 'nowrap',
  border: 'solid 0.0625rem #c8d0df',
  boxShadow: '0 0 0.625rem 0 rgba(0, 0, 0, 0.14)',
  bottom: '4.375rem',
  left: '0.625rem',
  position: 'fixed',
  zIndex: '500',
};

export const menuItemStyle = {
  'height': '2.75rem',
  'paddingTop': '0.9rem',
  'paddingLeft': '1.5rem',
  'paddingRight': '1.5rem',
  'position': 'relative',
  'width': '100%',
  'zIndex': '500',
  'cursor': 'pointer',
  '&:active': {
    animation: `${flash} 0.5s 4`,
  },
};
