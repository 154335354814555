import styled from '@emotion/styled';
import { useMediaQuery } from '@material-ui/core';
import { compose } from '@reduxjs/toolkit';
import { linkTo, usePathParams } from 'components/Router/paths';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getOrg, selectActiveOrgIds, selectPreferredTeamId } from 'reduxStore';
import { atomic } from 'resources';
import { useLoadedOrgId } from 'tools/utils/hooks/useLoadedOrgId';

import { FlowWrapper, Step } from './FlowWrapper';
import { WorkspaceStep } from './Steps';
import { StepName } from './types';

const defaultSteps: [StepName, Step][] = [['start', WorkspaceStep]];

interface Props {
  steps?: [StepName, Step][];
}

/**
 * Onboarding v2
 */
export const OnboardingController = ({ steps = defaultSteps }: Props): JSX.Element => {
  const { small } = atomic.responsive.breakpoints[0];
  const isDesktop = useMediaQuery(`(min-width: ${small}px)`);

  const { orgId } = usePathParams('onboarding');

  const loadedOrgId = useLoadedOrgId();

  const orgSlice = getOrg(loadedOrgId);

  const isOnboardingFlowFinished = useSelector(
    compose((org) => org?.onboardingStatus === 'finished', orgSlice.selector),
  );

  const hasActiveOrgs = useSelector(
    compose(
      (data: string[]) => !!data.length,
      (data) => data.filter((id) => id !== orgId),
      selectActiveOrgIds,
    ),
  );

  const preferredTeamId = useSelector(selectPreferredTeamId(loadedOrgId));
  const history = useHistory();
  const onFinish = (): void => {
    if (!loadedOrgId || !preferredTeamId) {
      throw new Error('This is unexpected');
    }

    const FIRST_TASK_PARAMS = '?type=task&active=1';
    const sprintPage = linkTo(
      'sprints',
      {
        orgId: loadedOrgId,
        teamId: preferredTeamId,
      },
      FIRST_TASK_PARAMS,
    );

    const mobileNotePage = linkTo('onboardingMobileNote', {
      orgId: loadedOrgId,
    });

    history.push(isDesktop ? sprintPage : mobileNotePage);
  };

  return (
    <PageContainer>
      <FlowWrapper
        hasActiveOrgs={hasActiveOrgs}
        initialStep='start'
        isOnboardingFlowFinished={isOnboardingFlowFinished}
        loadedOrgId={loadedOrgId}
        onFinish={onFinish}
        preferredTeamId={preferredTeamId}
        steps={steps}
      />
    </PageContainer>
  );
};

const PageContainer = styled.div`
  height: 100%;
`;
