import { Popup } from '@taraai/design-system';
import { useFirestoreConnect } from '@taraai/read-write';
import React, { useCallback, useEffect, useState } from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  getOrgList,
  getSubscriptionType,
  selectActiveOrgIds,
  selectActiveTeam,
  selectActiveWorkspace,
} from 'reduxStore';

import { WorkspaceSwitcherNavButton } from './NavButton';
import { WorkspaceSwitcherPopup } from './Popup';

export function WorkspaceSwitcher(): JSX.Element | null {
  const [showPopup, setShowPopup] = useState(false);

  const activeOrgIds = useSelector(selectActiveOrgIds, deepEquals);

  const activeOrgsSlice = getOrgList(activeOrgIds);

  useFirestoreConnect(activeOrgsSlice.query);

  const activeUserOrgs = useSelector(activeOrgsSlice.selector);

  const teamId = useSelector(selectActiveTeam);

  const selectedOrgId = useSelector(selectActiveWorkspace);
  const selectedOrg = activeUserOrgs.find((org) => org.id === selectedOrgId);

  const subscriptionType = useSelector(getSubscriptionType(selectedOrgId));
  const isPremium = subscriptionType !== null;

  const closePopup = useCallback(() => {
    setShowPopup(false);
  }, [setShowPopup]);

  const history = useHistory();

  useEffect(() => closePopup(), [history.location.pathname, closePopup]);

  if (!selectedOrg) {
    return null;
  }

  return (
    <Popup
      content={
        <WorkspaceSwitcherPopup
          color={selectedOrg.color}
          isPremium={isPremium}
          logoUrl={selectedOrg.logoURL}
          orgName={selectedOrg.name}
          selectedOrgId={selectedOrgId}
          selectedTeamId={teamId}
          userOrgs={activeUserOrgs}
        />
      }
      onHide={() => setShowPopup(false)}
      show={showPopup}
    >
      <WorkspaceSwitcherNavButton
        interactive={!showPopup}
        onClick={() => setShowPopup((prev) => !prev)}
        selectedOrg={selectedOrg}
      />
    </Popup>
  );
}
