import { css, cx } from 'emotion';
import React, { useEffect, useState } from 'react';

export interface CheckboxProps {
  /**
   * The <label> for this checkbox
   */
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean | string;
  className?: string;
  label?: string;
  disabled?: boolean;
  name?: string;
}

/**
 * A wrapper around <input type="checkbox"/>
 *
 * - [Design](https://zpl.io/bL137LQ)
 */
export default function Checkbox({
  checked,
  className,
  disabled,
  label,
  onChange,
  ...props
}: CheckboxProps): JSX.Element {
  const [isChecked, setIsChecked] = useState(false);
  useEffect(
    function checkedSetter() {
      if (typeof checked === 'boolean') {
        setIsChecked(checked);
      }
    },
    [checked],
  );
  function localOnChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setIsChecked(checked ? !event.target.checked : event.target.checked);
    if (typeof onChange === 'function') onChange(event);
  }
  return (
    <label
      className={cx(
        css`
          display: flex;
          flex-direction: row;
          align-items: center;
          position: relative;
          cursor: pointer;
          user-select: none;
          height: 1.2rem;

          color: ${disabled ? '#c8d0df' : '#303f4b'};
        `,
        className,
      )}
    >
      <input
        type='checkbox'
        {...props}
        className={css`
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        `}
        onChange={localOnChange}
      />
      <span
        className={css`
          position: absolute;
          top: 0;
          left: 0;
          height: 1.2rem;
          width: 1.2rem;
          font-size: 0.875rem;
          border-radius: 0.1em;
          background-color: ${isChecked && !disabled ? '#50af28' : '#ebeef4'};
          &:after {
            content: '';
            position: absolute;
            display: ${isChecked ? 'block' : 'none'};
            left: 0.45rem;
            top: 0.1rem;
            width: 0.25rem;
            height: 0.75rem;
            border: solid ${disabled ? '#c8d0df' : '#FFFFFF'};
            border-width: 0 0.0625rem 0.0625rem 0;
            transform: rotate(45deg);
          }
        `}
      />
      <span
        className={css`
          margin-left: 1.5rem;
        `}
      >
        {label}
      </span>
    </label>
  );
}
