import { Box, styled, tokens } from '@taraai/design-system';
import { LegendOrdinal } from '@visx/legend';
import { GraphLegendProps } from 'components/app/GitInsights/_types';
import { InjectDisplayFlexProps } from 'components/app/GitInsights/Shared/utils';
import React from 'react';

export const GraphLegend = ({ scale, colorShapeFormat = 'circle' }: GraphLegendProps): JSX.Element => {
  return scale ? (
    <Box full>
      <StyledLegendOrdinal
        direction='row'
        labelMargin='0 15px 0 0'
        scale={scale}
        shape={colorShapeFormat}
        shapeHeight={10}
        shapeWidth={10}
      />
    </Box>
  ) : (
    <></>
  );
};

const StyledLegendOrdinal = styled(LegendOrdinal, {
  ...InjectDisplayFlexProps,
  fontSize: '$12px',
  color: tokens.colors.$background,
  minWidth: '200px',
});
