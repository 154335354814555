"use strict";
/* istanbul ignore file - all functions are tested because components using them are tested */
Object.defineProperty(exports, "__esModule", { value: true });
exports.tokens = exports.styled = exports.mapTokens = exports.mapBreakpoints = exports.GlobalStyles = exports.getCustomSize = exports.fade = void 0;
var styled_1 = require("./styled");
Object.defineProperty(exports, "fade", { enumerable: true, get: function () { return styled_1.fade; } });
Object.defineProperty(exports, "getCustomSize", { enumerable: true, get: function () { return styled_1.getCustomSize; } });
Object.defineProperty(exports, "GlobalStyles", { enumerable: true, get: function () { return styled_1.GlobalStyles; } });
Object.defineProperty(exports, "mapBreakpoints", { enumerable: true, get: function () { return styled_1.mapBreakpoints; } });
Object.defineProperty(exports, "mapTokens", { enumerable: true, get: function () { return styled_1.mapTokens; } });
Object.defineProperty(exports, "styled", { enumerable: true, get: function () { return styled_1.styled; } });
Object.defineProperty(exports, "tokens", { enumerable: true, get: function () { return styled_1.tokens; } });
