import { Box, Hidden, Text } from '@taraai/design-system';
import React from 'react';
import { strings } from 'resources/i18n';
import { SaveState } from 'tools/reconciliation/useForceSave';

interface Props {
  editorState: SaveState;
  refresh: () => void;
  createNewDocument: () => void;
  save: () => Promise<string | Error | null>;
}

/**
 * Editor Notifications
 */
export function EditorNotification({ editorState, refresh, createNewDocument, save }: Props): JSX.Element | null {
  let messageText = '';
  let buttonText = '';
  let buttonAction = (): void => undefined;

  switch (editorState) {
    case SaveState.dirtyConflict:
      messageText = strings.editor.saveState.dirtyConflict;
      buttonText = strings.editor.notificationHeaderButton.createNew;
      buttonAction = createNewDocument;
      break;
    case SaveState.cleanConflict:
      messageText = strings.editor.saveState.cleanConflict;
      buttonText = strings.editor.notificationHeaderButton.refresh;
      buttonAction = refresh;
      break;
    case SaveState.saving:
      messageText = strings.editor.saveState.saving;
      break;
    case SaveState.saved:
      messageText = strings.editor.saveState.saved;
      break;
    case SaveState.error:
      messageText = strings.editor.saveState.error;
      buttonText = strings.editor.notificationHeaderButton.save;
      buttonAction = save;
      break;
  }

  return (
    <Box center spaceHorz='$12px'>
      <Box spaceTop='$2px'>
        <Text color={editorState !== SaveState.cleanConflict ? '$grey6' : '$failure'} size='$12px'>
          {messageText}
        </Text>
      </Box>
      <Hidden hidden={!buttonText}>
        <Box spaceHorz='$8px'>
          <Box.Button
            background='$focus'
            borderRadius='$4px'
            center
            height='$24px'
            onClick={buttonAction}
            spaceHorz='$8px'
            spaceVert='$2px'
          >
            <Text color='$white' size='$10px' weight='medium'>
              {buttonText}
            </Text>
          </Box.Button>
        </Box>
      </Hidden>
    </Box>
  );
}
