import { FileUploader } from '@remirror/core';
import { FileAttributes } from '@remirror/extension-file';
import { Data } from '@taraai/types';
import { addAttachmentSansRedux } from 'firestore';

type UploaderParams = { path: string; id: Data.Id.TaskId | Data.Id.RequirementId };

class TaraFileUploader implements FileUploader<FileAttributes> {
  private file: File | null = null;

  private path: string;

  private id: Data.Id.TaskId | Data.Id.RequirementId;

  constructor({ path, id }: UploaderParams) {
    this.path = path;
    this.id = id;
  }

  insert(file: File): FileAttributes {
    this.file = file;
    return getDefaultFileAttrs(file);
  }

  async upload(): Promise<FileAttributes> {
    const { file, path, id } = this;

    if (!file) {
      throw new Error('No file to upload');
    }

    // wait for the upload to finish
    const uploadUrl = await addAttachmentSansRedux({ path, file, id });
    return { ...getDefaultFileAttrs(file), url: uploadUrl };
  }

  abort(): void {
    // this.xhr.abort();
  }
}

export function getDefaultFileAttrs(file: File): FileAttributes {
  return {
    fileName: file.name,
    fileType: file.type,
    fileSize: file.size,
  };
}

export function createTaraFileUploader({ path, id }: UploaderParams): FileUploader<FileAttributes> {
  return new TaraFileUploader({ path, id });
}
