import styled from '@emotion/styled';
import { Data, UI } from '@taraai/types';
import { TeamMemberSelector } from 'components/app/controllers/Selectors/TeamMemberSelector';
import Table from 'components/core/controllers/Table';
import Logo from 'components/core/controllers/views/Logo';
import SpinnerButton from 'components/core/controllers/views/SpinnerButton';
import { linkTo } from 'components/Router/paths';
import { css } from 'emotion';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { selectActiveTeam } from 'reduxStore';
import { workspaceTeamsTestIds } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';

export type TeamsLayoutProps = {
  orgId: Data.Id.OrganizationId;
  teams: UI.UITeam[];
  userTeamsIds: string[];
  onJoinTeamClick: (teamId: string, teamName: string) => void;
};

/**
 * TeamsLayout
 */
export default function TeamsLayout({
  orgId,
  teams,
  userTeamsIds = [],
  onJoinTeamClick,
}: TeamsLayoutProps): JSX.Element {
  const { search } = useLocation();
  const teamId = useSelector(selectActiveTeam);
  const history = useHistory();
  const tableData = teams.map((team) => ({
    id: team.id,
    name: (
      <NameRow>
        <Logo name={team.name} shape='circle' size='medium' />
        <NameLabel>{team.name}</NameLabel>
      </NameRow>
    ),
    members: <TeamMemberSelector orgId={orgId} teamId={team.id} />,
    join: userTeamsIds.includes(team.id) ? (
      <JoinedLabel>{strings.teams.joined}</JoinedLabel>
    ) : (
      <JoinButton
        color='ghost'
        data-cy={workspaceTeamsTestIds.JOIN_TEAM_BUTTON}
        name={`joinTeam-${team.id}`}
        onClick={(event: React.SyntheticEvent): void => {
          event.stopPropagation();
          onJoinTeamClick(team.id, team.name);
        }}
        size='small'
        type='button'
      >
        {strings.teams.join}
      </JoinButton>
    ),
  }));

  return (
    <PageContainer>
      <Link
        className={css`
          float: right;
          font-size: 0.875rem;
          text-decoration: none;
          padding-left: 0.75rem;
          margin-bottom: 1rem;
          color: #2ea2ff;
          &:hover {
            text-decoration: underline;
            color: #1d98ff;
          }
        `}
        data-cy={workspaceTeamsTestIds.CREATE_TEAM_BUTTON}
        // TODO: [Multi-team] - add link to create team modal
        to={linkTo('teamCreate', { orgId, teamId }, search)}
      >
        {strings.teams.create}
      </Link>
      <Table
        className={css`
          flex-grow: 1;
        `}
        data={tableData}
        headers={{
          name: { name: strings.teams.table.name, width: '70%' },
          members: { name: strings.teams.table.members, width: '25%' },
          join: { name: '', width: '5%' },
        }}
        tableRowOnClick={(row) => (): void => {
          history.push(linkTo('teamDetails', { orgId, teamId: row.id }, search));
        }}
      />
    </PageContainer>
  );
}

const PageContainer = styled.div`
  padding-bottom: 2.5rem;
  background-color: #fff;
`;

const NameRow = styled.div`
  font-size: 0.875rem;
  padding: 0.5rem 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  &:hover {
    background-color: #fbfbfd;
  }
`;

const NameLabel = styled.div`
  color: #303f4b;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0.5rem;
`;

const JoinedLabel = styled.p`
  font-style: italic;
  text-align: center;
  color: #405161;
  width: 100%;
`;

const JoinButton = styled((props) => <SpinnerButton {...props} />)`
  padding: 0.25rem 0.5rem;
  display: block;
  font-size: 0.875rem;
  width: 100%;
  min-width: 6.5rem;
`;
