import { getCustomSize, styled } from '@taraai/design-system';
import React from 'react';

import { IntegrationsPanel } from './IntegrationsPanel/IntegrationsPanel';
import { Navigation } from './Navigation';
import { ProfileSection } from './ProfileSection/ProfileSection';
import { TeamSwitcher } from './TeamSwitcher/TeamSwitcher';
import Users from './Users';
import { WorkspaceSwitcher } from './WorkspaceSwitcher/WorkspaceSwitcher';

export function GlobalNav(): JSX.Element {
  return (
    <Bar>
      <LeftPanel>
        <WorkspaceSwitcher />
        <Navigation />
      </LeftPanel>
      <IntegrationsPanel />
      <RightPanel>
        <TeamSwitcher />
        <Users />
        <ProfileSection />
      </RightPanel>
    </Bar>
  );
}

const LeftPanel = styled('div', {
  display: 'flex',
  flex: 1,
  justifyContent: 'left',
});

const RightPanel = styled('div', {
  display: 'flex',
  flex: 1,
  justifyContent: 'flex-end',
});

const Bar = styled('div', {
  position: 'relative',
  justifyContent: 'space-between',
  display: 'flex',
  height: getCustomSize(48),
  boxShadow: '$navbar',
  backgroundColor: '#ffffff',
  zIndex: 6,
});
