import { CheckoutSuccessController } from 'components/app/monetization/CheckoutSuccess';
import React from 'react';

/**
 * Upgrade
 * route for Upgrade
 *
 */
export default function CheckoutSuccess(): JSX.Element {
  return <CheckoutSuccessController />;
}
