import { createAsyncThunk } from '@reduxjs/toolkit';
import { Data, TaskStatus } from '@taraai/types';
import { UISprint } from '@taraai/types/dist/ui';
import { RootState } from 'reduxStore/store';
import { ExtraAPI, Write } from 'reduxStore/utils/types';

import { updateSprint } from './update';

type moveBackIncompleteTasksPayload = {
  previousSprintId: Data.Id.SprintId;
  currentSprintId: Data.Id.SprintId;
};

type sprintActionPayload = Pick<UISprint, 'id'> & { previousRequiresAction?: Data.SprintRequiredActions | null };

export const mergeIncompleteTasks = createAsyncThunk(
  'mergeIncompleteTasks',
  async (payload: sprintActionPayload, { dispatch }): Promise<unknown> => {
    const { id: sprintId } = payload;
    return dispatch(updateSprint({ id: sprintId, carryOverTaskIds: [], requiresAction: null }));
  },
);

export const dismissSprintSummary = createAsyncThunk(
  'dismissSprintSummary',
  async (payload: sprintActionPayload, { dispatch }): Promise<unknown> => {
    const { id: sprintId, previousRequiresAction } = payload;

    return dispatch(
      updateSprint({
        id: sprintId,
        requiresAction:
          previousRequiresAction === Data.SprintRequiredActions.reconcileSummary
            ? Data.SprintRequiredActions.reconcile
            : null,
      }),
    );
  },
);

export const dismissReconcile = createAsyncThunk(
  'dismissReconcile',
  async (payload: sprintActionPayload, { dispatch }): Promise<unknown> => {
    const { id: sprintId, previousRequiresAction } = payload;

    return dispatch(
      updateSprint({
        id: sprintId,
        requiresAction:
          previousRequiresAction === Data.SprintRequiredActions.reconcileSummary
            ? Data.SprintRequiredActions.summary
            : null,
      }),
    );
  },
);

export const moveBackIncompleteTasks = createAsyncThunk(
  'moveBackIncompleteTasks',
  async (payload: moveBackIncompleteTasksPayload, { extra, getState }): Promise<unknown> => {
    const { getOrgId, getUserId, getFirestore } = extra as ExtraAPI;
    const state = getState() as RootState;
    const orgId = getOrgId();
    const userId = getUserId(state);
    const firestore = getFirestore();

    const taskPath = `orgs/${orgId}/tasks`;
    const sprintPath = `orgs/${orgId}/sprints`;

    const { currentSprintId, previousSprintId } = payload;

    const reads = {
      uid: () => userId,
      sprintId: () => currentSprintId,
      currentSprint: { collection: sprintPath, doc: currentSprintId },
    };

    const updateCurrentSprint = ({ sprintId, currentSprint }: Record<string, string & UISprint>): Write => {
      return {
        id: sprintId,
        path: sprintPath,
        requiresAction: null,
        updatedBy: currentSprintId,
        updatedAt: ['::serverTimestamp'],
        carryOverTaskIds: [],
        orderedTaskIds: ['::arrayRemove', ...currentSprint.carryOverTaskIds],
      };
    };

    const writePreviousSprint = ({ uid, currentSprint }: Record<string, string & UISprint>): Write => {
      return {
        id: previousSprintId,
        path: sprintPath,
        requiresAction: null,
        updatedBy: uid,
        carryOverTaskIds: [],
        updatedAt: firestore.Timestamp.now(),
        orderedTaskIds: ['::arrayUnion', ...currentSprint.carryOverTaskIds],
      };
    };

    const taskWrites = ({ uid, currentSprint }: Record<string, string & UISprint>): Write[] => {
      return (
        currentSprint?.carryOverTaskIds &&
        currentSprint.carryOverTaskIds
          .filter((taskId: Data.Id.TaskId) => currentSprint.orderedTaskIds.indexOf(taskId) !== -1)
          .map((taskId: Data.Id.TaskId): Write => {
            return {
              id: taskId,
              path: taskPath,
              updatedBy: uid,
              updatedAt: ['::serverTimestamp'],
              sprint: previousSprintId,
              status: TaskStatus.Done,
            };
          })
      );
    };

    return firestore.mutate({
      reads,
      writes: [updateCurrentSprint, writePreviousSprint, taskWrites],
    });
  },
);
