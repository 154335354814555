import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetUserEmailsPayload, GetUserEmailsResponse } from '@taraai/types/src/functions/get-users-emails';
import { ExtraAPI } from 'reduxStore/utils';
import { decode } from 'reduxStore/utils/decoders';

export const listOrgUserEmails = createAsyncThunk('ListOrgUserEmails', async (actionPayload: void, { extra }) => {
  const { getFirebase, getOrgId } = extra as ExtraAPI;
  const organizationId = getOrgId();

  const payload: GetUserEmailsPayload = {
    type: 'GetByOrganization',
    organizationId,
  };

  const response = await getFirebase().functions().httpsCallable('getUsersEmails')(payload);

  const result = decode<GetUserEmailsResponse>(response.data, 'GetUserEmailsResponse');

  if (result.status === 'error') {
    throw new Error(result.errors[0]?.error);
  }

  return result.value;
});
