import { AddToastFn } from 'components/app/controllers/Toast/ToastContext';
import { createEntity } from 'components/editor/entities';
import { zwsp } from 'components/editor/plugins/utils';
import { PluginFunctions } from 'components/editor/types';
import { DraftHandleValue, EditorState, Modifier, SelectionState } from 'draft-js';
import { strings } from 'resources';

import { getImage } from './utils';

export const createInsertFiles =
  (addToast: AddToastFn, uploadAttachment: (file: File) => Promise<string>) =>
  (
    selection: SelectionState | null,
    files: File[],
    { setEditorState }: Pick<PluginFunctions, 'setEditorState'>,
  ): DraftHandleValue => {
    files
      .reduce(async (previousPromise, nextFile): Promise<void> => {
        await previousPromise;
        const url = await uploadAttachment(nextFile as File).catch(() => undefined);
        if (!url) throw new Error("Couldn't upload an attachment");

        // If file is not an image do not insert it into editor
        if (!nextFile.type.startsWith('image/')) return;

        const image = await getImage(url);
        if (!image) throw new Error('File type is not supported');
        setEditorState(insertImage(url, selection));
      }, Promise.resolve())
      .catch(() => addToast({ message: strings.editor.filesNotUploaded, timeoutMs: 5500, type: 'error' }));
    return 'handled';
  };

const insertImage =
  (url: string, selection: SelectionState | null) =>
  (state: EditorState): EditorState => {
    const currentSelection = selection ?? state.getSelection();
    const content = state.getCurrentContent();
    const { contentWithEntity, key } = createEntity('IMAGE', { src: url, alt: 'image' }, content);
    return EditorState.push(
      state,
      Modifier.insertText(contentWithEntity, currentSelection, zwsp, undefined, key),
      'insert-characters',
    );
  };
