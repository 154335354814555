import { css, cx } from 'emotion';
import React from 'react';
import { atomic } from 'resources/theme';

export interface TableLayoutProps extends Omit<React.HTMLProps<HTMLTableElement>, 'style'> {
  header?: JSX.Element;
  footer?: JSX.Element;
  style?: {
    body?: string;
    footer?: string;
    header?: string;
  };
}

/**
 * TableBody does…
 *
 */
export default function TableLayout({
  children,
  header,
  footer,
  style: { body: bodyStyle, footer: footerStyle, header: headerStyle } = {},
  ...props
}: TableLayoutProps): JSX.Element {
  return (
    <table
      {...props}
      className={css`
        border-collapse: collapse;
        width: 100%;
      `}
    >
      {header && (
        <thead
          className={cx(
            css`
              background-color: #fbfbfd;
              ${atomic.responsive.query({
                nanoTiny: true,
                tinySmall: true,
                smallMedium: true,
              })} {
                display: none;
              }
            `,
            headerStyle,
          )}
        >
          {header}
        </thead>
      )}
      <tbody
        className={cx(
          css`
            background-color: #ffffff;
            ${atomic.responsive.query({
              nanoTiny: true,
              tinySmall: true,
              smallMedium: true,
            })} {
              background-color: transparent;
              display: flex;
              flex-direction: column;
            }
          `,
          bodyStyle,
        )}
      >
        {children}
      </tbody>
      {footer && (
        <tfoot
          className={cx(
            css`
              ${atomic.responsive.query({
                nanoTiny: true,
                tinySmall: true,
                smallMedium: true,
              })} {
                display: none;
              }
            `,
            footerStyle,
          )}
        >
          {footer}
        </tfoot>
      )}
    </table>
  );
}
