import { Tooltip } from '@taraai/design-system';
import Icon from 'components/core/controllers/views/Icon';
import { css } from 'emotion';
import React from 'react';
import { sprintsTabTestIds } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';

export interface TotalEffortProps {
  totalEffort: number;
  completedEffort: number;
  estimatedEffort: number;
  isTextHidden?: boolean;
  navigateToSprintInsights?: (event: React.SyntheticEvent) => void;
  navigateToSprintDetailsInsights?: (event: React.SyntheticEvent) => void;
  completedSprintsWithEffortEstimation: number;
}

/**
 * TotalEffort renders effort level in sprint column header based on total effort and
 * an option prop of estimated effort
 *
 */
export default function TotalEffort({
  completedSprintsWithEffortEstimation,
  totalEffort,
  completedEffort,
  estimatedEffort,
  navigateToSprintInsights,
  navigateToSprintDetailsInsights,
  isTextHidden,
}: TotalEffortProps): JSX.Element {
  const isOverloaded = estimatedEffort > 0 && totalEffort > estimatedEffort;

  return (
    <Tooltip
      placement='left'
      title={
        isOverloaded
          ? (strings.formatString(strings.sprints.sprintColumn.overloadTooltip, {
              number: estimatedEffort,
            }) as string)
          : strings.sprints.sprintColumn.effortTooltip
      }
    >
      <div
        className={css`
          display: flex;
          align-items: center;
          padding-right: 0.0625rem;
          cursor: ${completedSprintsWithEffortEstimation > 0 && 'pointer'};
          :hover {
            opacity: 0.7;
            transition: 0.1s all 0s ease;
          }
          :focus {
            outline: none;
          }
        `}
        onClick={isTextHidden ? navigateToSprintDetailsInsights : navigateToSprintInsights}
        onKeyDown={navigateToSprintInsights}
        role='button'
        tabIndex={0}
      >
        {isOverloaded && (
          <Icon
            className={css`
              padding: 0rem;
              padding-right: 0.25rem;
              padding-bottom: ${isTextHidden ? '0rem' : '0.0625rem'};
              display: flex;
              align-self: center;
            `}
            name='overload'
          />
        )}
        <div
          className={css`
            color: ${isOverloaded ? '#d99b59' : '#949caf'};
            font-size: 0.875rem;
            font-weight: 500;
            display: ${isTextHidden && 'none'};
          `}
        >
          {strings.sprints.sprintColumn.effort}
        </div>
        <div
          className={css`
            display: flex;
            align-items: center;
            height: 1.8rem;
            border-radius: 0.1875rem;
            border: ${isOverloaded ? 'solid 0.0625rem #d99b59' : 'solid 0.0625rem #dee3ec'};
            font-size: 0.875rem;
            font-weight: 500;
            text-align: center;
            padding: 0.25rem 0.375rem 0.1875rem 0.375rem;
            min-width: 1.5625rem;
            color: ${isOverloaded ? '#d99b59' : '#949caf'};
            margin: ${isTextHidden ? '0rem 0rem 0rem 0.25rem' : '0rem 0.5rem'};
          `}
          data-cy={sprintsTabTestIds.SPRINT_EFFORT}
        >
          {strings.formatString(strings.sprints.sprintColumn.totalEffortCompleted, {
            effortCompleted: completedEffort,
            effortTotal: totalEffort,
          })}
        </div>
      </div>
    </Tooltip>
  );
}
