import { WhereOptions } from '@taraai/read-write';
import { Data, TaskStatus, UI } from '@taraai/types';
import { isNonEmptyString } from '@taraai/utility';
import { createStandardSelector, inertQuery, Query } from 'reduxStore/utils/selectors';
import { ReduxFirestoreQuery } from 'reduxStore/utils/types';

function queryBuilder(orgId: Data.Id.OrganizationId, where: WhereOptions[]): Query<UI.UITask> {
  if (!isNonEmptyString(orgId)) {
    return inertQuery();
  }

  const queryTask: ReduxFirestoreQuery = {
    collection: `orgs/${orgId}/tasks`,
    where,
    orderBy: ['status', 'desc'],
    storeAs: `tasks/${orgId}/imports/${where[0][2]}`,
  };

  return {
    query: [queryTask],
    selector: createStandardSelector<UI.UITask>(queryTask),
  };
}

export function getImportedTasks(
  orgId: Data.Id.OrganizationId,
  service: Data.ExternalIssue.Any['service'],
): Query<UI.UITask> {
  if (![orgId, service].every(isNonEmptyString)) {
    return inertQuery();
  }

  const where: WhereOptions[] = [
    ['externalIssue.service', '==', service],
    ['_relationships.parent', '==', null],
    ['archived', '==', false],
    ['deleted', '==', false],
    ['status', '<', TaskStatus.Done],
  ];

  return queryBuilder(orgId, where);
}
