import { ClassNamesArg } from '@emotion/core';
import Icon from 'components/core/controllers/views/Icon';
import { css, cx, keyframes } from 'emotion';
import React from 'react';
import { onEnterDown } from 'tools/helpers';

const glowBox = keyframes`
50% {
  border: solid 0.0625rem #389e0d;
}
`;

const glowText = keyframes`
50% {
  color: #389e0d;
}
`;

const glowIcon = keyframes`
50% {
  background-color: #ddf4d9;
  color: #389e0d;
}
`;

interface RequirementsListElementProps {
  onClick?: () => void;
  glow?: boolean;
  label: string;
  iconStyle?: string;
  labelStyle?: string;
  description?: string | React.ReactText[] | JSX.Element;
  icon: ({ glowClass }: { glowClass: ClassNamesArg }) => JSX.Element;
  rightIcon?: JSX.Element;
}

const RequirementsListElement = ({
  onClick,
  glow = false,
  label,
  labelStyle,
  iconStyle,
  description,
  icon: ImageIcon,
  rightIcon,
}: RequirementsListElementProps): JSX.Element => (
  <div
    className={cx(
      css`
        padding: 0.9375rem 0.5rem 0.9375rem 1rem;
        border: solid 0.0625rem transparent;
        border-bottom: 0.0625rem solid #eaeef5;
        display: flex;
        align-items: center;
        justify-content: space-between;
        :focus {
          outline: 0;
        }
        :hover {
          background-color: #fbfbfd;
        }
      `,
      {
        [css`
          animation: ${glowBox} 1s;
        `]: glow,
      },
    )}
    onClick={onClick}
    onKeyDown={onEnterDown(onClick)}
    role='button'
    tabIndex={0}
  >
    <div
      className={cx(
        css`
          display: flex;
          align-items: center;
        `,
        iconStyle,
      )}
    >
      <div
        className={css`
          display: flex;
          margin-right: 0.5rem;
        `}
      >
        <ImageIcon
          glowClass={{
            [css`
              animation: ${glowIcon} 1s;
            `]: glow,
          }}
        />
      </div>
      <div>
        <div
          className={cx(
            css`
              padding-top: 0.1875rem;
              color: #303f4b;
              font-size: 1rem;
              font-weight: 500;
            `,
            labelStyle,
            {
              [css`
                animation: ${glowText} 1s;
              `]: glow,
            },
          )}
        >
          {label}
        </div>
        <div
          className={cx(
            css`
              font-size: 0.875rem;
              font-weight: normal;
              color: #949caf;
              padding-top: 0.25rem;
            `,
            {
              [css`
                animation: ${glowText} 1s;
              `]: glow,
            },
          )}
        >
          {description}
        </div>
      </div>
    </div>
    {rightIcon || (
      <Icon
        className={css`
          color: #949caf;
        `}
        name='right'
      />
    )}
  </div>
);

export default RequirementsListElement;
