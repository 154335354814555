import { Timestamp } from '@taraai/types';
import moment from 'moment';
import { firestore } from 'tools/libraries/firebaseValues';

const MINUTES_IN_SECONDS = 60;

const HOUR_IN_SECONDS = 60 * MINUTES_IN_SECONDS;

const DAY_IN_SECONDS = 24 * HOUR_IN_SECONDS;

export const WEEK_IN_SECONDS = 7 * DAY_IN_SECONDS;

const MONTH_IN_SECONDS = 28 * DAY_IN_SECONDS;

const YEAR_IN_SECONDS = 365 * DAY_IN_SECONDS;

/**
 * Formats a data like `5d` as a short version of Moment's `fromNow()`,
 * where that would be `5 days ago`.
 *
 * Source: https://github.com/moment/moment/issues/2781#issuecomment-416499369
 *
 * TODO: support localization
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
export function timeSince(date: Date): string {
  const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);
  let intervalType;
  let interval: string | number = Math.floor(seconds / YEAR_IN_SECONDS);
  if (interval >= 1) {
    intervalType = 'y';
  } else {
    interval = Math.floor(seconds / MONTH_IN_SECONDS);
    if (interval >= 1) {
      intervalType = 'M';
    } else {
      interval = Math.floor(seconds / DAY_IN_SECONDS);
      if (interval >= 1) {
        intervalType = 'd';
      } else {
        interval = Math.floor(seconds / HOUR_IN_SECONDS);
        if (interval >= 1) {
          intervalType = 'h';
        } else {
          interval = Math.floor(seconds / MINUTES_IN_SECONDS);
          if (interval >= 1) {
            intervalType = 'm';
          } else {
            interval = '';
            intervalType = 'now';
          }
        }
      }
    }
  }

  return `${interval}${intervalType}`;
}

export function isBefore(timestampA: Timestamp | null, timestampB: Timestamp | null): boolean {
  return compareDates(timestampA, timestampB) < 0;
}

export const isBeforeNow = (timestamp: Timestamp): boolean => isBefore(timestamp, firestore.Timestamp.now());

/**
 * Compares (ASCENDING - from oldest to newest) two timestamps and returns a number that can be used in a sorting function.
 */
export function compareDates(timestampA: Timestamp | null, timestampB: Timestamp | null): number {
  const { seconds: secondsA = 0, nanoseconds: nanoA = 0 } = timestampA ?? {};
  const { seconds: secondsB = 0, nanoseconds: nanoB = 0 } = timestampB ?? {};
  const seconds = secondsA - secondsB;
  return seconds !== 0 ? seconds : nanoA - nanoB;
}

export function plusSeconds(initialValue: Timestamp | Date, seconds: number): Timestamp {
  return plusMilliseconds(initialValue, getMillisecondsFromSeconds(seconds));
}

export function plusMilliseconds(initialValue: Timestamp | Date, ms: number): Timestamp {
  const timestamp = toTimestamp(initialValue);

  return firestore.Timestamp.fromMillis((timestamp as Timestamp).toMillis() + ms);
}

export function durationMilliseconds(start: Timestamp | Date, end: Timestamp | Date): number {
  return toTimestamp(start).toMillis() - toTimestamp(end).toMillis();
}

export function formatDMMMYYYY(timestamp: Timestamp): string {
  return moment(toDate(timestamp)).format('D MMM YYYY');
}

export function formatDMMM(timestamp: Timestamp | number): string {
  const date = typeof timestamp === 'number' ? toDate({ seconds: timestamp }) : toDate(timestamp);
  return moment(date).format('D MMM');
}

export function getEpochSecondsFromDate(date: Date | number): number {
  return moment(date).valueOf() / 1000;
}

function getNumberOfDaysFromSeconds(seconds: number): number {
  return moment.duration(seconds, 'seconds').asDays();
}

export function getNumberOfWeeksFromSeconds(seconds: number): number {
  const numberOfDays = getNumberOfDaysFromSeconds(seconds);
  return Math.floor(numberOfDays / 7);
}

export function getMillisecondsFromSeconds(seconds: number): number {
  return seconds * 1000;
}

interface MaybeTimestamp {
  seconds?: unknown;
  nanoseconds?: unknown;
}

export function toDate(timestamp: Timestamp | MaybeTimestamp | Date): Date {
  if (timestamp instanceof Date) {
    return timestamp;
  }

  if (timestamp instanceof firestore.Timestamp) {
    return timestamp.toDate();
  }

  const { seconds } = timestamp as MaybeTimestamp;

  if (typeof seconds === 'number') {
    return new Date(seconds * 1000);
  }

  throw new Error(`Failed to convert ${timestamp} to Date`);
}

export function toTimestamp(date: Date | MaybeTimestamp | Timestamp): Timestamp {
  if (date instanceof firestore.Timestamp) {
    return date;
  }

  if (date instanceof Date) {
    return firestore.Timestamp.fromDate(date);
  }

  const { seconds } = date as MaybeTimestamp;

  if (typeof seconds === 'number') {
    return firestore.Timestamp.fromMillis(seconds * 1000);
  }

  throw new Error(`Failed to convert ${date} to Timestamp`);
}

export function toEpoch(timestamp: Timestamp | Date): number {
  return Math.floor(toTimestamp(timestamp).toMillis() / 1000);
}

export function fromEpoch(seconds: number): Timestamp {
  return toTimestamp({ seconds } as Timestamp);
}

export function daysFromEpoch(timestamp: Timestamp): number {
  const seconds = timestamp instanceof Date ? timestamp.getTime() : (toTimestamp(timestamp) as Timestamp).seconds;
  return Math.floor(seconds / 60 / 60 / 24);
}

export const formatFromNow = (timestamp: Timestamp): string => moment(toDate(timestamp)).fromNow();
