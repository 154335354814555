import { styled } from '@taraai/design-system';
import { UI } from '@taraai/types';
import { getInitials } from '@taraai/utility';
import Icon from 'components/core/controllers/views/Icon';
import { css, cx } from 'emotion';
import React from 'react';
import { IconName } from 'resources';
import taraBot from 'resources/assets/icons/taraIcons/tara-bot.svg';
import { avatarTestIds } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';
import { atomic } from 'resources/theme';
import { formatColor, stringToColor } from 'tools/libraries/helpers/colors';

/**
 * Avatars can be one of the Tara Design System's predefined size or
 * an arbitrary [CSS <length>](https://developer.mozilla.org/en-US/docs/Web/CSS/length) string.
 */
export type TAvatarSize = 'small' | 'medium' | 'large' | 'extra-large' | string;

export interface AvatarProps {
  /**
   * The user who's avatar we want to display
   */
  user?: Pick<UI.UIUser, 'avatarURL' | 'name'> | null;
  /**
   * The requirement avatar we want to display
   */
  requirement?: Pick<UI.UIRequirement, 'title'>;
  /**
   * The icon name we want to display
   */
  icon?: IconName;
  /**
   * Any additional positional styles
   */
  className?: string;
  figureStyle?: string;

  /**
   * The size of the avatar, one of the defaults of Tara's design system
   * or a [CSS <length>](https://developer.mozilla.org/en-US/docs/Web/CSS/length) string.
   */
  size?: TAvatarSize;
}

export const avatarSizes: { [key: string]: string } = {
  'extra-small': '1rem',
  'small': '1.5rem',
  'medium': '2rem',
  'large': '3rem',
  'extra-large': '5rem',
  'xx-large': '8rem',
};

/**
 * A wrapper around `<figure>` that generates a circular image of the user, or their initials.
 *
 * - [Designs](https://zpl.io/amWmg8e)
 *
 * @deprecated - we should use Avatar from design-system for all new components
 */
export default function Avatar({
  user,
  requirement,
  className,
  figureStyle,
  icon,
  size = 'extra-large',
}: AvatarProps): JSX.Element | null {
  const calculatedSize = avatarSizes[size as TAvatarSize] ?? size;

  const circle = '50%';
  const square = '0.1875rem';

  let avatar = (
    <div
      className={css`
        background-color: #3368f4;
        height: ${calculatedSize};
        width: ${calculatedSize};
      `}
    >
      <Icon
        className={css`
          padding: calc(${calculatedSize}*0.1);
          height: calc(${calculatedSize}*0.8);
          width: calc(${calculatedSize}*0.8);
        `}
        color='black'
        name='profile'
      />
    </div>
  );

  if (user) {
    const altString = String(strings.formatString(strings.avatar.alt, { name: user.name }));
    const bgColor = stringToColor(user.name);
    avatar = user.avatarURL ? (
      <img
        alt={altString}
        className={css`
          object-fit: cover;
          height: 100%;
          min-height: 100%;
          width: 100%;
          min-width: 100%;
          background-color: ${formatColor(bgColor)};
        `}
        data-cy={avatarTestIds.USER_AVATAR}
        src={user.avatarURL}
      />
    ) : (
      <div
        aria-label={altString}
        className={css`
          font-size: calc(${calculatedSize} * 0.4);
          padding-top: calc(${calculatedSize} * 0.3);
          line-height: calc(${calculatedSize} * 0.5);
          margin: auto;
          height: 100%;
          width: 100%;
          ${atomic.color(atomic.colors.white)};
          ${atomic.backgroundColor(formatColor(bgColor))};
        `}
        data-cy={avatarTestIds.USER_AVATAR_NO_PHOTO}
      >
        {getInitials(user.name)}
        {icon ? (
          <Icon
            className={css`
              position: absolute;
              bottom: 0;
              right: 0;
              width: 1rem;
              height: 1rem;
              padding: 0;
            `}
            color='black'
            name={icon}
          />
        ) : null}
      </div>
    );
  }

  if (requirement) {
    const altString = String(strings.formatString(strings.avatar.alt, { name: requirement.title }));
    const bgColor = stringToColor(requirement.title);
    avatar = (
      <div
        aria-label={altString}
        className={css`
          font-size: calc(${calculatedSize} * 0.4);
          padding-top: calc(${calculatedSize} * 0.3);
          line-height: calc(${calculatedSize} * 0.5);
          margin: auto;
          height: 100%;
          width: 100%;
          ${atomic.color(atomic.colors.white)};
          ${atomic.backgroundColor(formatColor(bgColor))};
        `}
        data-cy={avatarTestIds.REQUIREMENT_AVATAR}
      >
        {getInitials(requirement.title)}
      </div>
    );
  }

  return (
    <div
      className={cx(
        css`
          position: relative;
          min-height: ${calculatedSize} !important;
          height: ${calculatedSize} !important;
          min-width: ${calculatedSize} !important;
          width: ${calculatedSize} !important;
        `,
        className,
      )}
    >
      <figure
        className={cx(
          css`
            border-radius: ${requirement ? square : circle};
            overflow: hidden;
            height: 100% !important;
            width: 100% !important;
            text-align: center;
          `,
          figureStyle,
        )}
        title={user ? user.name : 'Tara-bot'}
      >
        {avatar}
      </figure>
    </div>
  );
}
interface UserAvatarProps extends Pick<AvatarProps, 'size' | 'user'> {
  showBorder?: boolean;
}

/**
 * @deprecated - we should use Avatar from design-system for all new components
 */
export function UserAvatar({ showBorder, size, user }: UserAvatarProps): JSX.Element {
  return <StyledAvatar showBorder={showBorder} size={size} user={user ?? { name: 'Tara-bot', avatarURL: taraBot }} />;
}

export const StyledAvatar = styled(
  Avatar,
  {},
  {
    showBorder: {
      true: { borderRadius: '50%', boxShadow: '0 0 0 3px #389e0d' },
    },
  },
);
