import 'resources/styles';

import Router from 'components/Router/Router';
import ThemeProvider from 'components/ThemeProvider';
import React from 'react';
import { MemoryRouter } from 'react-router-dom';
import { ReduxContext } from 'reduxStore';

function Template(): JSX.Element {
  return <Router />;
}

export default Template;

// This component is used only for tests. It should be completely removed later.
export function Root({ children }: React.HTMLProps<HTMLElement>): JSX.Element {
  return (
    <ReduxContext>
      <MemoryRouter>
        <ThemeProvider>{children}</ThemeProvider>
      </MemoryRouter>
    </ReduxContext>
  );
}
