import { Box, Fluid, styled, VStack } from '@taraai/design-system';
import { Data } from '@taraai/types';
import { WorkDrawerImportToastController } from 'components/app/controllers/WorkDrawerImportToastController';
import { GithubImportToastController } from 'components/app/controllers/WorkDrawerToastController';
import { sidePanelWidth } from 'components/app/layouts/SidePanelsLayout';
import { RemoveSprintArea } from 'components/app/layouts/SprintColumnsLayout/RemoveSprintArea';
import React, { RefObject, useLayoutEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectActiveTeam, selectActiveWorkspace } from 'reduxStore';
import { useWorkDrawerScrollState } from 'reduxStore/workDrawer';
import { strings } from 'resources';

import { OrphanTasks } from './components/OrphanTasks';
import { WorkDrawerRequirements } from './components/Requirements';
import { WorkDrawerImports } from './components/WorkDrawerImports';
import { WorkDrawerRepos } from './components/WorkDrawerRepos';
import { WorkDrawerHeader } from './WorkDrawerSections';

type Props = {
  onRequirementSelect: (requirementId: Data.Id.RequirementId) => void;
  onTaskSelect: (taskId: Data.Id.TaskId) => void;
  selectedTaskId: Data.Id.TaskId | undefined;
  redirectToFirstRequirement: boolean;
};

export const WorkDrawer = React.memo(function WorkDrawer({
  onRequirementSelect,
  onTaskSelect,
  selectedTaskId,
  redirectToFirstRequirement,
}: Props): JSX.Element {
  const orgId = useSelector(selectActiveWorkspace);
  const teamId = useSelector(selectActiveTeam);

  return (
    <WorkDrawerContainer borderRight='$grey4' width={sidePanelWidth}>
      <VStack full>
        <RemoveSprintArea />
        <WorkDrawerHeader />
        <Fluid>
          <WorkDrawerBodyWrapper>
            <OrphanTasks onTaskSelect={onTaskSelect} selectedTaskId={selectedTaskId} />
            <WorkDrawerRequirements
              onRequirementSelect={onRequirementSelect}
              onTaskSelect={onTaskSelect}
              orgId={orgId}
              redirectToFirstRequirement={redirectToFirstRequirement}
              selectedTaskId={selectedTaskId}
            />
            <WorkDrawerRepos onTaskSelect={onTaskSelect} orgId={orgId} teamId={teamId} />
            <WorkDrawerImports
              onTaskSelect={onTaskSelect}
              orgId={orgId}
              selectedTaskId={selectedTaskId}
              teamId={teamId}
            />
          </WorkDrawerBodyWrapper>
        </Fluid>
      </VStack>
    </WorkDrawerContainer>
  );
});

function WorkDrawerBodyWrapper({ children }: { children: React.ReactNode }): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);

  useScrollState(ref);

  return (
    <ScrollContainer ref={ref} background='$white' spaceBottom='$12px'>
      <VStack>{children}</VStack>
      <BottomPosition space='$8px'>
        <GithubImportToastController />
        <WorkDrawerImportToastController
          importInProgressCopy={strings.importNotificationBar.asana.importInProgressCopy}
          service='asana'
        />
        <WorkDrawerImportToastController
          importInProgressCopy={strings.importNotificationBar.trello.importInProgressCopy}
          service='trello'
        />
      </BottomPosition>
    </ScrollContainer>
  );
}

/**
 * Use the previously saved scroll position on mount, save the scroll position on unmount.
 *
 * Note that the result of `useWorkDrawerScrollState` never changes.
 */
function useScrollState(ref: RefObject<HTMLDivElement>): void {
  const scrollState = useWorkDrawerScrollState();

  useLayoutEffect(() => {
    const scrollElement = ref.current as HTMLDivElement;
    const { getScroll, setScroll } = scrollState;
    const scroll = getScroll();
    if (typeof scroll !== 'undefined') scrollElement.scrollTop = scroll;
    return () => setScroll(scrollElement.scrollTop);
  }, [ref, scrollState]);
}

const WorkDrawerContainer = styled(Box, {
  zIndex: 1,
  position: 'absolute',
  height: 'calc(100% - 3rem)',
  backgroundColor: '$white',
});

const ScrollContainer = styled(Box, {
  overflowY: 'scroll',
});

const BottomPosition = styled(Box, {
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
});
