import React from 'react';

export const PencilIcon = (
  <svg fill='none' height='14' viewBox='0 0 15 14' width='15' xmlns='http://www.w3.org/2000/svg'>
    <path
      clipRule='evenodd'
      d='M11.7024 0.33331C12.0359 0.331052 12.3665 0.394893 12.6752 0.521144C12.9839 0.647395 13.2645 0.833554 13.5008 1.06887L13.1168 1.4545L13.5023 1.07037C13.7377 1.30662 13.9239 1.58718 14.0503 1.89584C14.1766 2.20451 14.2404 2.53515 14.2382 2.86865C14.2359 3.20215 14.1676 3.53189 14.0371 3.83881C13.9071 4.14468 13.7179 4.42184 13.4805 4.65438L13.2649 4.86996L13.2567 4.87834L13.2483 4.88656L5.59888 12.536C5.59745 12.5374 5.59601 12.5389 5.59455 12.5404C5.59271 12.5422 5.59084 12.544 5.58897 12.5459C5.52048 12.6134 5.43527 12.6616 5.34204 12.6855L1.58432 13.6493C1.39803 13.6971 1.20034 13.643 1.06434 13.507C0.928334 13.371 0.874207 13.1734 0.921963 12.9871L1.8852 9.22934C1.90942 9.13486 1.95858 9.04863 2.02755 8.97967L9.9164 1.09081C10.149 0.853417 10.4263 0.66429 10.7322 0.534297C11.0392 0.403879 11.3689 0.335567 11.7024 0.33331ZM5.20904 11.3866L12.1022 4.49345L10.0776 2.46888L3.18268 9.36377L5.20904 11.3866ZM2.70289 10.4227L4.15029 11.8676L2.2046 12.3666L2.70289 10.4227ZM13.0354 3.413C12.9899 3.5202 12.9315 3.62119 12.8618 3.71387L10.8573 1.70929C10.9499 1.63976 11.0508 1.58152 11.1579 1.53603C11.3325 1.46183 11.52 1.42297 11.7098 1.42169C11.8995 1.4204 12.0876 1.45672 12.2632 1.52854C12.4384 1.60022 12.5978 1.70584 12.732 1.83933C12.8656 1.97353 12.9712 2.13283 13.0429 2.30806C13.1148 2.48359 13.1511 2.67162 13.1498 2.86128C13.1485 3.05093 13.1097 3.23845 13.0354 3.413Z'
      fill='currentColor'
      fillRule='evenodd'
    />
  </svg>
);
