import { styled } from '@taraai/design-system';
import { Data } from '@taraai/types';
import React, { MutableRefObject } from 'react';

import { TeamItem } from './TeamItem';
import { TeamsSection } from './types';

type PopupListProps = {
  disableOptions: boolean;
  sections: TeamsSection[];
  selectedTeamId: Data.Id.TeamId;
  preferredTeamId: Data.Id.TeamId;
  bottomRef?: MutableRefObject<HTMLDivElement | null>;
};

export function PopupList({
  disableOptions,
  sections,
  selectedTeamId,
  preferredTeamId,
  bottomRef,
}: PopupListProps): JSX.Element {
  return (
    <Container>
      {sections.map((section) => (
        <SectionContainer key={section.id}>
          <SectionHeader>{section.label}</SectionHeader>
          {section.options?.map((option) => (
            <TeamItem
              key={option.id}
              isDisabled={disableOptions}
              isPreferred={preferredTeamId === option.id}
              isSelected={selectedTeamId === option.id}
              teamId={option.id}
              teamName={option.name}
            />
          ))}
          <div ref={bottomRef} />
        </SectionContainer>
      ))}
    </Container>
  );
}

export const SectionHeader = styled('div', {
  fontSize: '0.75rem',
  fontWeight: '500',
  color: '$grey6',
  textAlign: 'left',
  padding: '0.75rem 1rem 0.5rem',
});

export const EmptySectionHeader = styled('div', {
  paddingTop: '0.75rem',
});

export const Container = styled('div', {
  width: '100%',
  maxHeight: '14.25rem',
  overflowY: 'auto',
});

export const SectionContainer = styled('div', {
  width: '100%',
});
