import { createAsyncThunk } from '@reduxjs/toolkit';
import { Data, UI } from '@taraai/types';
import { RootState } from 'reduxStore/store';
import { decode } from 'reduxStore/utils/decoders';
import { ExtraAPI, Write } from 'reduxStore/utils/types';

export type UpdateRequirementTeamAction = keyof Pick<
  UI.UIRequirementChangeset,
  'appendTeamIds' | 'removeTeamIds' | 'orderedTaskIds'
>;
type UIRequirementChangeset = Omit<Partial<UI.UIRequirement>, 'assignedTeamIds'> & {
  assignedTeamIds?: Array<'::arrayUnion' | '::arrayRemove' | Data.Id.TeamId>;
};
type RequirementPathId = { id: string; path: string };

const write = ({
  uid,
  requirement,
  changes,
  appendTeamIds,
  removeTeamIds,
}: {
  uid: string;
  requirement: RequirementPathId;
  changes: UIRequirementChangeset;
  appendTeamIds?: string[];
  removeTeamIds?: string[];
}): Write => {
  if (appendTeamIds) {
    changes.assignedTeamIds = ['::arrayUnion'].concat(appendTeamIds);
  } else if (removeTeamIds) {
    changes.assignedTeamIds = ['::arrayRemove'].concat(removeTeamIds);
  }

  return {
    path: requirement.path,
    id: requirement.id,
    ...changes,
    updatedAt: ['::serverTimestamp'],
    updatedBy: uid,
  };
};

export const updateRequirement = createAsyncThunk(
  'UpdateRequirement',
  async ({ id: requirementId, ...propsToSet }: Omit<UI.UIRequirementChangeset, 'updatedAt'>, { extra, getState }) => {
    try {
      const { getFirestore, getOrgId, getUserId } = extra as ExtraAPI;
      const state = getState() as RootState;
      const orgId = getOrgId();
      const uid = getUserId(state);
      const firestore = getFirestore();

      const decodedChangesWithId = decode<UI.UIRequirementChangeset>(
        {
          id: requirementId,
          ...propsToSet,
          updatedAt: firestore.Timestamp.now(),
          updatedBy: uid,
        },
        'UIRequirementChangeset',
      );

      const { id, appendTeamIds, removeTeamIds, ...changes } = decodedChangesWithId;
      const path = `orgs/${orgId}/requirements`;

      const operation = propsToSet.description
        ? {
            reads: {
              requirement: { collection: path, doc: id },
              uid: () => uid,
              changes: () => changes,
              appendTeamIds: () => appendTeamIds,
              removeTeamIds: () => removeTeamIds,
            },
            writes: [write],
          }
        : write({ requirement: { id, path }, uid, changes, appendTeamIds, removeTeamIds });

      await firestore.mutate(operation);

      return decodedChangesWithId as UI.UIRequirementChangeset;
    } catch (err) {
      return err;
    }
  },
);
