import { UIOrganizationStanza } from '@taraai/types/src/ui';
import { assertNever } from '@taraai/utility';
import Logo from 'components/core/controllers/views/Logo';
import { FastSmallSpinner } from 'components/core/controllers/views/Spinners';
import Text from 'components/core/controllers/views/Text';
import { css, cx } from 'emotion';
import React, { useMemo } from 'react';
import { strings } from 'resources';

export type LogoChangeStatus = 'none' | 'uploading' | 'removing';

type WorkspaceLogoViewProps = {
  canChange: boolean;
  changeStatus: LogoChangeStatus;
  handleRemoveWorkspaceLogo: () => void;
  handleUploadWorkspaceLogo: (file: File) => void;
  org: UIOrganizationStanza;
};

export default function WorkspaceLogoView({
  canChange,
  changeStatus,
  handleRemoveWorkspaceLogo,
  handleUploadWorkspaceLogo,
  org,
}: WorkspaceLogoViewProps): JSX.Element {
  const changeStatusLabel = useMemo(() => {
    switch (changeStatus) {
      case 'uploading':
        return strings.workspaceLogo.uploading;
      case 'removing':
        return strings.workspaceLogo.removing;
      case 'none':
        return '';
      default:
        return assertNever(changeStatus);
    }
  }, [changeStatus]);

  return (
    <div
      className={css`
        position: relative;
        display: flex;
        width: fit-content;
        height: fit-content;
        justify-content: center;
        align-items: center;
        /* dynamically toggle logo shading and buttons showing */
        .workspace-logo {
          filter: ${changeStatus !== 'none' && 'brightness(40%)'};
        }
        .workspace-logo-actions {
          visibility: ${changeStatus !== 'none' && 'visible'};
        }
        ${canChange &&
        `
          &:hover .workspace-logo-actions {
            visibility: visible;
          }
          &:hover .workspace-logo {
            filter: brightness(35%);
          }
        `}
      `}
    >
      <div
        className={cx(
          css`
            z-index: 1;
            position: absolute;
            visibility: hidden;
            display: flex;
            flex-direction: column;
          `,
          'workspace-logo-actions',
        )}
      >
        {changeStatus !== 'none' ? (
          // show loading status
          <div
            className={css`
              display: flex;
              flex-direction: column;
              align-items: center;
            `}
          >
            <FastSmallSpinner color='#ffffff' />
            <Text color='#ffffff'>{changeStatusLabel}</Text>
          </div>
        ) : (
          // show add photo button
          <label
            className={css`
              overflow: hidden;
              position: relative;
              cursor: pointer;
            `}
          >
            <Text color='#ffffff'>
              {org.logoURL ? strings.workspaceLogo.changeLogo : strings.workspaceLogo.uploadLogo}
            </Text>
            <input
              className={css`
                pointer-events: none;
                display: block;
                font-size: 999px;
                filter: alpha(opacity=0);
                min-height: 100px;
                min-width: 30px;
                opacity: 0;
                position: absolute;
                right: 0;
                text-align: right;
                top: 0;
              `}
              onChange={(event): void => {
                event.preventDefault();
                if (event?.target?.files) {
                  handleUploadWorkspaceLogo(event?.target?.files[0]);
                }
              }}
              type='file'
            />
          </label>
        )}
        {org.logoURL && changeStatus === 'none' && (
          // show remove logo button
          <button
            className={css`
              cursor: pointer;
              border: none;
              background: none;
              margin-top: 1.5rem;
            `}
            onClick={handleRemoveWorkspaceLogo}
            type='button'
          >
            <Text color='#ffffff'>{strings.workspaceLogo.removeLogo}</Text>
          </button>
        )}
      </div>
      <div className='workspace-logo'>
        <Logo color={org.color} name={org.name} shape='square' size='9.375rem' url={org.logoURL} />
      </div>
    </div>
  );
}
