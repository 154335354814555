"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ts_data_json_1 = require("ts.data.json");
var InstallableServiceDecoder = ts_data_json_1.JsonDecoder.oneOf([
    ts_data_json_1.JsonDecoder.isExactly('github'),
    ts_data_json_1.JsonDecoder.isExactly('slack'),
    ts_data_json_1.JsonDecoder.isExactly('gitlab'),
    ts_data_json_1.JsonDecoder.isExactly('gitlabApp'),
], 'InstallableService');
exports.default = InstallableServiceDecoder;
