import React from 'react';
import { Redirect } from 'react-router-dom';
import { getInvitationTokenFromCookie } from 'tools/utils/invitationToken';

import { linkTo } from './paths';

/**
 * Checks invitationToken cookie and redirects to invite
 */
const InvitationContext: React.FC = ({ children }) => {
  const tokenFromCookie = getInvitationTokenFromCookie();
  if (tokenFromCookie) {
    return <Redirect to={linkTo('invite', { token: undefined })} />;
  }

  return <>{children}</>;
};

export default InvitationContext;
