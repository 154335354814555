import { Box, Fluid, getCustomSize, HStack, Spacer, styled, Text, Tree } from '@taraai/design-system';
import { tokens } from '@taraai/design-system/dist/core/tokens';
import React, { forwardRef, ReactElement, ReactNode } from 'react';

type CommonHeaderProps = { title: string; widget?: ReactNode; onClick?: () => void };

export function PrimaryHeader({
  icon,
  title,
  widget,
  onClick,
}: { icon?: ReactElement } & CommonHeaderProps): JSX.Element {
  return (
    <Tree.Header onClick={onClick} spaceBottom='$8px' spaceLeft='$4px' spaceRight='$8px' spaceTop='$16px'>
      <HStack alignY='center'>
        <Tree.Arrow arrowColor={tokens.colors.$darker} />
        {icon || ''}
        <Spacer space='$4px' />
        <Fluid>
          <Text color='$darker' size='$12px' uppercase weight='medium'>
            {title}
          </Text>
        </Fluid>
        {widget}
      </HStack>
    </Tree.Header>
  );
}

const customTitleHeight = getCustomSize(66);

export const SecondaryHeader = forwardRef<
  HTMLDivElement,
  { selected?: boolean; isUnfolded?: boolean } & CommonHeaderProps
>(function SecondaryHeader({ selected, title, widget, onClick, isUnfolded }, ref): JSX.Element {
  return (
    <TreeHeaderWithSelection
      ref={ref}
      background='$white'
      borderRadius='$2px'
      isUnfolded={isUnfolded}
      onClick={onClick}
      selected={selected}
      spaceRight='$8px'
    >
      <HStack alignY='center'>
        <Tree.Arrow />
        <Fluid>
          <Box center='vert' height={customTitleHeight}>
            <Box spaceBottom='$2px'>
              <TextWrapper color='$coreGrey' singleLine size='$14px' weight='medium'>
                {title}
              </TextWrapper>
            </Box>
          </Box>
        </Fluid>
        {widget}
      </HStack>
    </TreeHeaderWithSelection>
  );
});

const TreeHeaderWithSelection = styled(
  Tree.Header,
  { ':hover': { backgroundColor: tokens.colors.$white } },
  {
    selected: { true: { boxShadow: 'inset 0 0 0 1px colors.$lavender' } },
    isUnfolded: { true: { backgroundColor: tokens.colors.$white } },
  },
);

const TextWrapper = styled(Text, { 'whiteSpace': 'unset', ':hover': { color: tokens.colors.$coreGrey } });
