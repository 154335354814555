import { UpgradeController } from 'components/app/monetization/Upgrade';
import React from 'react';

/**
 * Upgrade
 * default pricing to monthly
 */
export default function Upgrade(): JSX.Element {
  return <UpgradeController />;
}
