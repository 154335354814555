import { styled, Tooltip } from '@taraai/design-system';
import Tara from '@taraai/types';
import { MaybeReusableLink } from 'components/app/controllers/InviteUsersController';
import Icon from 'components/core/controllers/views/Icon';
import moment from 'moment';
import React, { useState } from 'react';
import { atomic } from 'resources';
import { strings } from 'resources/i18n';
import { toDate } from 'tools/libraries/helpers/dates';

/**
 * A presentational component to render invitation link in invite user popup
 */
export default function InviteLinkSnippet({
  maybeReusableLink,
  isUsedDuringOnboarding,
}: {
  maybeReusableLink: MaybeReusableLink | undefined;
  isUsedDuringOnboarding?: boolean;
}): JSX.Element {
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const copyInvite = (): void => {
    if (maybeReusableLink?.invitationLink) {
      navigator.clipboard.writeText(maybeReusableLink.invitationLink);
      setIsLinkCopied(true);
    }
  };

  const formatDate = (date: Tara.Timestamp): string =>
    `${strings.inviteUserPopup.validTill}
     ${moment(toDate(date)).format('LL')}
     ${moment(toDate(date)).format('LT')}`;

  return (
    <>
      <Wrapper isUsedDuringOnboarding={isUsedDuringOnboarding}>
        <LinkWrapper isUsedDuringOnboarding={isUsedDuringOnboarding}>{maybeReusableLink?.invitationLink}</LinkWrapper>
        <Tooltip title={isLinkCopied ? strings.inviteUserPopup.copied : strings.inviteUserPopup.copy}>
          <StyledIcon
            color={atomic.colors.focus}
            name='copyInvite'
            onClick={copyInvite}
            onMouseEnter={(): void => setIsLinkCopied(false)}
          />
        </Tooltip>
      </Wrapper>
      {maybeReusableLink?.expiredAt && (
        <ExpirationDate isUsedDuringOnboarding={isUsedDuringOnboarding}>
          {formatDate(maybeReusableLink.expiredAt)}
        </ExpirationDate>
      )}
    </>
  );
}

const Wrapper = styled(
  'div',
  {
    backgroundColor: '$white',
    border: 'borderWidths.$1px solid colors.$grey4',
    borderRadius: '0.1875rem',
    padding: '0.5rem',
    marginLeft: '1rem',
    marginRight: '1rem',
    marginBottom: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '2.37rem',
  },
  {
    isUsedDuringOnboarding: {
      true: {
        margin: '0rem',
        marginBottom: '0.5rem',
      },
    },
  },
);

const LinkWrapper = styled(
  'div',
  {
    color: '$grey7',
    fontSize: '0.75rem',
    fontWeight: '500',
    fontFamily: 'SourceCodePro',
    overflow: 'hidden',
  },
  {
    isUsedDuringOnboarding: {
      true: {
        overflow: 'unset',
        fontSize: '0.65vw',
      },
    },
  },
);

const StyledIcon = styled(Icon, {
  padding: '0rem',
  display: 'flex',
  cursor: 'pointer',
});

const ExpirationDate = styled(
  'div',
  {
    color: '$grey6',
    fontSize: '.75rem',
    lineHeight: '1rem',
    marginLeft: '1rem',
    marginBottom: '1rem',
  },
  {
    isUsedDuringOnboarding: {
      true: {
        marginLeft: '0',
      },
    },
  },
);
