import React, { ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { GetRemainingTrialDays } from 'tools/utils/getRemainingTrialDays';

import { UpgradeModal } from './UpgradeModal';

export type ModalType = 'sprintColumn' | 'taskLimit' | null;

export type ModalContentType = 'startTrial' | 'endTrial' | 'gatedSprint';
export interface UpgradeModalContextType {
  handleOpenCloseUpgradeModal: () => void;
  isUpgradeModalOpen: boolean;
  handleModalType: (type: ModalType) => void;
  handleModalContentType?: (contentType: ModalContentType) => void;
}

const UpgradeModalContext = React.createContext<UpgradeModalContextType>({
  handleOpenCloseUpgradeModal: () => undefined,
  isUpgradeModalOpen: false,
  handleModalType: () => undefined,
  handleModalContentType: () => undefined,
});

export function useUpgradeModal(): UpgradeModalContextType {
  return useContext(UpgradeModalContext);
}

export function UpgradeModalProvider({
  children,
  isStorybookRender,
}: {
  children: ReactNode;
  isStorybookRender: boolean | undefined;
}): null | JSX.Element {
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
  const [modalType, setModalType] = useState<ModalType>('sprintColumn');
  const [modalContentType, setModalContentType] = useState<ModalContentType>('gatedSprint');

  // Premium Trial Control
  // TODO: I'm wondering a better way to fix this, it's related to mocking data coming from new data layer API.
  // When we call this data layer API in a Storybook component, the mock os not able to find it.
  const remainingTrialDays = isStorybookRender ? 10 : GetRemainingTrialDays();
  const shouldManagePremiumTrialWarnings = Boolean(localStorage.getItem('tara-premium-trial-active')) === true;

  const handleOpenCloseUpgradeModal = useCallback(() => {
    isUpgradeModalOpen && setModalType('sprintColumn');
    setIsUpgradeModalOpen(!isUpgradeModalOpen);
  }, [isUpgradeModalOpen]);

  const handleModalType = useCallback((type) => {
    setModalType(type);
  }, []);

  const handleModalContentType = useCallback((contentType) => {
    setModalContentType(contentType);
  }, []);

  useEffect(() => {
    if (shouldManagePremiumTrialWarnings && remainingTrialDays === 0) {
      setModalContentType('endTrial');
      setIsUpgradeModalOpen(true);
      localStorage.removeItem('tara-premium-trial-active');
    }
  }, [remainingTrialDays, shouldManagePremiumTrialWarnings]);

  return (
    <UpgradeModalContext.Provider
      value={{ handleOpenCloseUpgradeModal, isUpgradeModalOpen, handleModalType, handleModalContentType }}
    >
      {isUpgradeModalOpen && (
        <UpgradeModal
          handleOpenCloseModal={handleOpenCloseUpgradeModal}
          modalContentType={modalContentType}
          modalType={modalType}
        />
      )}
      {children}
    </UpgradeModalContext.Provider>
  );
}
