import { createAsyncThunk } from '@reduxjs/toolkit';
import { UI } from '@taraai/types';
import { isNonEmptyString } from '@taraai/utility';
import { ExtraAPI } from 'reduxStore/utils/types';
import { strings } from 'resources';

type IdentifiableRequirement = Pick<UI.UIRequirement, 'id'>;

export const archiveRequirement = createAsyncThunk(
  'ArchiveRequirement',
  async ({ id }: IdentifiableRequirement, { extra }): Promise<IdentifiableRequirement> => {
    const { getOrgId, getFirestore } = extra as ExtraAPI;
    const orgId = getOrgId();
    if (!isNonEmptyString(id)) throw strings.requirements.missingCredentials;
    await getFirestore().update(`orgs/${orgId}/requirements/${id}`, {
      archived: true,
    });
    return { id };
  },
);

export const restoreArchivedRequirement = createAsyncThunk(
  'RestoreArchivedRequirement',
  async ({ id }: IdentifiableRequirement, { extra }): Promise<IdentifiableRequirement> => {
    const { getOrgId, getFirestore } = extra as ExtraAPI;
    const orgId = getOrgId();
    if (!isNonEmptyString(id)) throw strings.requirements.missingCredentials;
    await getFirestore().update(`orgs/${orgId}/requirements/${id}`, {
      archived: false,
    });
    return { id };
  },
);
