import { useTaskTitleConfig } from 'components/editor/config';
import { getStyledRichEditor, RichEditorHandle } from 'components/editor/RichEditor';
import { RichEditorProvider } from 'components/editor/RichEditorProvider';
import React from 'react';
import { strings } from 'resources';

type Props = {
  initialValue?: string;
  onBlur?: () => void;
  onDelete?: () => void;
  onFocus?: () => void;
  onSave: (content: string) => void;
};

export const Editor = React.forwardRef<RichEditorHandle, Props>(function Editor(
  { initialValue, onBlur, onDelete, onFocus, onSave },
  ref,
): JSX.Element {
  return (
    <RichEditorProvider
      config={useTaskTitleConfig({ mentions: true, onBackspaceDelete: onDelete, singleLine: true })}
      initialValue={initialValue}
      onSave={onSave}
      saveStrategy={initialValue ? 'both' : 'saveOnReturn'}
      selectAllContent={!!initialValue}
    >
      <TaskEditor
        ref={ref}
        onBlur={onBlur}
        onFocus={onFocus}
        placeholder={!initialValue ? strings.taskSidebar.addNewSubtask : ''}
      />
    </RichEditorProvider>
  );
});

const TaskEditor = getStyledRichEditor({
  fontSize: '$12px',
  lineHeight: '$12px',
  color: '$grey7',
});
