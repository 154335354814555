import { Box, Fluid, HStack, Spacer, styled, Text, VStack } from '@taraai/design-system';
import Icon from 'components/core/controllers/views/Icon';
import React from 'react';
import { CompleteSprintData } from 'reduxStore/sprints/queries/complete-sprint-data';
import { strings } from 'resources/i18n';

function getTasksDoneStats(totalTasksNumber: number | null, totalTasksCompletedNumber: number): string | string[] {
  if (totalTasksNumber !== null) {
    return strings.formatString(strings.sprints.completedHeader.taskValues, {
      total: totalTasksNumber.toString(),
      done: totalTasksCompletedNumber.toString(),
    });
  }

  if (totalTasksCompletedNumber === 1) {
    return strings.sprints.completedHeader.onlyCompletedTaskValuesSingular;
  }
  return strings.formatString(strings.sprints.completedHeader.onlyCompletedTaskValuesPlural, {
    done: totalTasksCompletedNumber.toString(),
  });
}

type Props = {
  completeSprintData: CompleteSprintData | undefined;
};

export function CompletedSprintDetailsSummary({ completeSprintData }: Props): JSX.Element {
  const {
    allPullRequestsCount = 0,
    closedPullRequestsCount = 0,
    totalEffortCompleted = 0,
    effortCompletedPercent = 0,
    totalCommits = 0,
    totalEffortEstimated = 0,
    totalTasksCompleted = 0,
    totalTasks = 0,
  } = completeSprintData ?? {};

  return (
    <HStack space='$24px'>
      <Card background='$white' borderRadius='$4px' spaceVert='$24px'>
        <VStack align='center'>
          <MergeIcon color='#6F42C1' name='merge' noPadding />
          <Spacer space='$16px' />
          <Text color='$dark' size='$12px'>
            {strings.sprints.completedHeader.prsClosed}
          </Text>
          <Text color='$dark' size='$18px' weight='medium'>
            {strings.formatString(strings.sprints.completedHeader.values, {
              percent: +Math.round((closedPullRequestsCount / allPullRequestsCount) * 100) || 0,
              total: allPullRequestsCount,
              completed: closedPullRequestsCount,
            })}
          </Text>
          <Text color='$grey6' size='$12px'>
            {strings.formatString(strings.sprints.completedHeader.prCommits, {
              number: totalCommits,
            })}
          </Text>
        </VStack>
      </Card>
      <Card background='$white' borderRadius='$4px' spaceVert='$24px'>
        <VStack align='center'>
          <TickIconBackground background='$success' borderRadius='$circle'>
            <Center>
              <TickIcon name='tick' noPadding />
            </Center>
          </TickIconBackground>
          <Spacer space='$16px' />
          <Text color='$dark' size='$12px'>
            {strings.sprints.completedHeader.effortCompleted}
          </Text>
          <Text color='$dark' size='$18px' weight='medium'>
            {strings.formatString(strings.sprints.completedHeader.values, {
              total: totalEffortEstimated,
              completed: totalEffortCompleted,
              percent: +effortCompletedPercent || 0,
            })}
          </Text>
          <Text color='$grey6' size='$12px'>
            {getTasksDoneStats(totalTasks, totalTasksCompleted)}
          </Text>
        </VStack>
      </Card>
    </HStack>
  );
}

const Center: React.FC = ({ children }) => (
  <Fluid>
    <VStack align='center' alignY='center'>
      {children}
    </VStack>
  </Fluid>
);

const Card = styled(Box, { width: '13.75rem', boxShadow: '0 0 1.25rem 0 rgba(0, 0, 0, 0.08)' });

const MergeIcon = styled(Icon, { width: '3.125rem', height: '3.125rem' });

const TickIcon = styled(Icon, { width: '1.5rem', height: '1.2rem', color: '$white' });

const TickIconBackground = styled(Box, { width: '3.125rem', height: '3.125rem' });
