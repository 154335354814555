import { Box, HStack, Text, VStack } from '@taraai/design-system';
import { tokens } from '@taraai/design-system/dist/core/tokens';
import { StandardSpinner } from 'components/core/controllers/views/Spinners';
import { linkTo } from 'components/Router/paths';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { selectActiveTeam, selectActiveWorkspace } from 'reduxStore';
import { strings } from 'resources';

import checkoutSuccess from './images/checkoutSuccess.svg';

type Props = {
  isLoading: boolean;
};

export function CheckoutSuccessView({ isLoading }: Props): JSX.Element {
  const history = useHistory();
  const orgId = useSelector(selectActiveWorkspace);
  const teamId = useSelector(selectActiveTeam);

  return (
    <Box background='$checkoutSuccessBGGradient' height='$full' width='$full'>
      {isLoading && <StandardSpinner backgroundColor={tokens.colors.$primaryBlue} fillSpace size='medium' />}
      <VStack space='$2px'>
        <Box center>
          <img alt='Checkout success' src={checkoutSuccess} />
        </Box>
        <Box center>
          <Text color='$white' size='$38px'>
            {strings.upgrade.checkoutSuccess.congrats}
          </Text>
        </Box>
        <Box center>
          <Text color='$white' size='$38px'>
            {strings.upgrade.checkoutSuccess.welcome}
          </Text>
        </Box>
        <Box center space='$24px'>
          <HStack space='$16px'>
            <Box.Button
              background='$white'
              borderRadius='$4px'
              center
              onClick={() => history.push(linkTo('sprints', { orgId, teamId }))}
              spaceBottom='$4px'
              spaceHorz='$24px'
              spaceTop='$8px'
            >
              <Text color='$dark' size='$18px'>
                {strings.upgrade.checkoutSuccess.explore}
              </Text>
            </Box.Button>
            <Box.Button
              border='$white'
              borderRadius='$4px'
              center
              onClick={() => history.push(linkTo('workspace', { orgId, teamId }, '?tab=Billing'))}
              spaceBottom='$4px'
              spaceHorz='$24px'
              spaceTop='$8px'
            >
              <Text color='$white' size='$18px'>
                {strings.upgrade.checkoutSuccess.viewPayment}
              </Text>
            </Box.Button>
          </HStack>
        </Box>
      </VStack>
    </Box>
  );
}
