/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/no-shadow */
import { createSelector, unwrapResult } from '@reduxjs/toolkit';
import { Box, Tooltip, Tree, VStack } from '@taraai/design-system';
import { useFirestoreConnect } from '@taraai/read-write';
import { Data, TaskStatus, UI } from '@taraai/types';
import { isNonEmptyString } from '@taraai/utility';
import { DroppableArea, DroppableDescription, DroppableType } from 'components/app/DragAndDrop';
import { DNDContext } from 'components/app/DragAndDrop/DNDProvider';
import { EmptyDrawerRequirements } from 'components/app/EmptyWorkDrawer';
import { TaskCreation } from 'components/app/TaskCreation';
import {
  CreateTaskEditor,
  TaskCardsWraper,
  TopLevelCreationButton,
  useScrollIntoViewRef,
} from 'components/app/WorkDrawer/WorkDrawerSections';
import { PrimaryHeader, SecondaryHeader } from 'components/app/WorkDrawerHeaders';
import { WorkDrawerInlineInput } from 'components/app/WorkDrawerInlineInput';
import { extractTaskData } from 'components/editor/plugins/extractTaskData';
import { RichEditorHandle } from 'components/editor/RichEditor';
import { linkTo } from 'components/Router/paths';
import { AppCuesWrapper } from 'components/services/AppCues';
import { useGetRequirementFields, useGetWorkDrawerRequirementIds } from 'firestore';
import React, { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router';
import { compose } from 'redux';
import {
  createRequirement,
  createTask,
  getRequirement,
  orderedDocuments,
  requirementTasks,
  selectActiveTeam,
  useAppDispatch,
  useFilteredSummaryRequirementIds,
  useFilteredSummaryTaskIds,
  useIsSearchActive,
  useSetNextTaskId,
} from 'reduxStore';
import { useAppIsSelected } from 'reduxStore/appSelection';
import { decode } from 'reduxStore/utils/decoders';
import {
  useWorkDrawerMultipleUnfoldedSetState,
  useWorkDrawerScrollBlock,
  useWorkDrawerSettingsState,
  useWorkDrawerUnfoldedState,
} from 'reduxStore/workDrawer';
import { strings } from 'resources';
import { segment } from 'tools/libraries/analytics';
import { useToast } from 'tools/utils/hooks/useToast';

export function WorkDrawerRequirements({
  onRequirementSelect,
  onTaskSelect,
  orgId,
  selectedTaskId,
  redirectToFirstRequirement,
}: {
  onRequirementSelect: (requirementId: Data.Id.RequirementId) => void;
  onTaskSelect: (taskId: Data.Id.TaskId) => void;
  orgId: string;
  selectedTaskId: Data.Id.TaskId | undefined;
  redirectToFirstRequirement: boolean;
}): JSX.Element | null {
  const dispatch = useAppDispatch();
  const [unfolded, setUnfolded] = useWorkDrawerUnfoldedState('requirementsSection');
  const [showInput, setShowInput] = useState(false);
  const setUnfoldedWorkDrawer = useWorkDrawerMultipleUnfoldedSetState();
  const teamId = useSelector(selectActiveTeam);

  const submitting = useRef(false);
  const { addToast } = useToast();
  const history = useHistory();

  const handleRequirementCreation = useCallback(
    async (title: string): Promise<void> => {
      if (submitting.current) return;
      if (!isNonEmptyString(title)) return;

      submitting.current = true;
      try {
        decode<UI.UIRequirementPartial>({ title }, 'UIRequirementPartial');
      } catch (error) {
        submitting.current = false;
        return addToast({ type: 'error', message: strings.requirements.createTooShort });
      }

      await dispatch(createRequirement({ title, assignedTeamIds: [teamId] }))
        .then(unwrapResult)
        .then(({ id }) => {
          segment.track('RequirementCreated', { orgID: orgId, requirementID: id, location: 'WorkDrawer' });
          history.replace(linkTo('requirement', { orgId, teamId, requirementId: id }));
        })
        .catch(() => addToast({ type: 'error', message: strings.requirements.failedToCreateRequirement }));

      submitting.current = false;
    },
    [addToast, dispatch, history, orgId, teamId],
  );

  const requirementSearchIds = useFilteredSummaryRequirementIds();
  const isSearchActive = useIsSearchActive();

  const requirementIds = useGetWorkDrawerRequirementIds();

  const firstRequirementId = requirementIds?.[0] || '';

  useEffect(() => {
    if (isSearchActive) {
      setUnfoldedWorkDrawer(requirementSearchIds);
    }
  }, [isSearchActive, requirementSearchIds, setUnfoldedWorkDrawer]);

  const { teamId: definePageTeamId } = useParams<{
    teamId: Data.Id.TeamId;
  }>();

  // This function will only work on the define page.
  const firstRequirementPath = linkTo('requirement', {
    orgId,
    requirementId: firstRequirementId,
    teamId: definePageTeamId,
  });

  if (!requirementIds) return null;

  return (
    /* v2.40.2 hotfix to redirect without using useHistory object as dependency */
    <>
      {firstRequirementId && redirectToFirstRequirement && <Redirect to={firstRequirementPath} />}
      <Tree unfolded={unfolded}>
        <AppCuesWrapper targetName='requirements-header'>
          <PrimaryHeader
            onClick={() => setUnfolded(!unfolded)}
            title={strings.requirements.title}
            widget={
              <Tree.Widget>
                {({ toggle, unfolded }) => (
                  <TopLevelCreationButton
                    onClick={() => {
                      if (!unfolded) toggle();
                      setShowInput(true);
                    }}
                    text='Create'
                  />
                )}
              </Tree.Widget>
            }
          />
        </AppCuesWrapper>
        <Tree.Content strategy='lazy'>
          <VStack space='$2px'>
            {showInput && (
              <WorkDrawerInlineInput
                initialFocus
                onDelete={() => setShowInput(false)}
                onEnter={(title) => {
                  handleRequirementCreation(title);
                  setShowInput(false);
                }}
                placeholder={strings.workDrawer.createRequirement}
              />
            )}
            {requirementIds.length ? (
              requirementIds.map((requirementId) => (
                <WorkDrawerRequirementsItem
                  key={requirementId}
                  onRequirementSelect={onRequirementSelect}
                  onTaskSelect={onTaskSelect}
                  orgId={orgId}
                  requirementId={requirementId}
                  selectedTaskId={selectedTaskId}
                />
              ))
            ) : (
              <EmptyDrawerRequirements />
            )}
          </VStack>
        </Tree.Content>
      </Tree>
    </>
  );
}

function WorkDrawerRequirementsItem({
  onRequirementSelect,
  onTaskSelect,
  orgId,
  selectedTaskId,
  requirementId,
}: {
  onRequirementSelect: (requirementId: Data.Id.RequirementId) => void;
  onTaskSelect: (taskId: Data.Id.TaskId) => void;
  orgId: string;
  requirementId: Data.Id.RequirementId;
  selectedTaskId: Data.Id.TaskId | undefined;
}): JSX.Element | null {
  const [unfolded, setUnfolded] = useWorkDrawerUnfoldedState('requirements', requirementId);
  const editorRef = useRef<RichEditorHandle>(null);
  const block = useWorkDrawerScrollBlock();
  const selected = useAppIsSelected('requirement', requirementId);
  const ref = useScrollIntoViewRef<HTMLDivElement>(selected, block);

  const requirementPartial = useGetRequirementFields(requirementId, ['title']);

  const title = requirementPartial?.title;

  useEffect(() => {
    if (selected) editorRef.current?.focus();
  }, [selected]);

  if (!title) return null;

  return (
    <VStack space='$1px' style={{ paddingLeft: '24px' }}>
      <Tree unfolded={unfolded}>
        {requirementPartial.title.length > 60 ? (
          <Tooltip placement='right-start' title={requirementPartial.title}>
            <SecondaryHeader
              ref={ref}
              onClick={() => {
                setUnfolded(!unfolded);
                onRequirementSelect(requirementId);
              }}
              selected={selected}
              title={`${requirementPartial.title.substring(0, 60)}...`}
            />
          </Tooltip>
        ) : (
          <SecondaryHeader
            ref={ref}
            onClick={() => {
              setUnfolded(!unfolded);
              onRequirementSelect(requirementId);
            }}
            selected={selected}
            title={
              requirementPartial.title.length > 60
                ? `${requirementPartial.title.substring(0, 60)}...`
                : requirementPartial.title
            }
          />
        )}

        <Tree.Content strategy='lazy'>
          <RequirementTaskCards
            editorRef={editorRef}
            onTaskSelect={onTaskSelect}
            orgId={orgId}
            requirementId={requirementId}
            selectedTaskId={selectedTaskId}
          />
        </Tree.Content>
      </Tree>
    </VStack>
  );
}

function RequirementTaskCards({
  onTaskSelect,
  orgId,
  requirementId,
  editorRef,
  selectedTaskId,
}: {
  onTaskSelect: (taskId: Data.Id.TaskId) => void;
  editorRef?: RefObject<RichEditorHandle>;
  orgId: Data.Id.OrganizationId;
  requirementId: Data.Id.RequirementId;
  selectedTaskId: Data.Id.TaskId | undefined;
}): JSX.Element | null {
  const dispatch = useAppDispatch();
  const isSearchActive = useIsSearchActive();
  const showAssignedToSprintTasks = useWorkDrawerSettingsState('tasks:show-assigned-to-sprint', {
    checkSearchIsActive: true,
  });
  const showDoneTasks = useWorkDrawerSettingsState('tasks:show-done', {
    checkSearchIsActive: true,
  });

  const individualRequirementSlice = getRequirement(orgId, requirementId ?? '');
  const orderedTaskIds: Data.Id.TaskId[] | undefined = useSelector(
    createSelector(individualRequirementSlice.selector, (requirement) => requirement && requirement?.orderedTaskIds),
    deepEquals,
  );

  const searchTaskIds = useFilteredSummaryTaskIds({ requirementId });

  const tasksSlice = requirementTasks(orgId, requirementId);

  const taskIds: Data.Id.TaskId[] = useSelector(
    compose(
      (tasks: UI.UITask[]) =>
        tasks &&
        tasks
          .filter(
            (task) =>
              (showDoneTasks ? true : task.status !== TaskStatus.Done) &&
              (showAssignedToSprintTasks ? true : task.sprint === null) &&
              (isSearchActive ? searchTaskIds.includes(task.id) : true),
          )
          .map(({ id }) => id),
      orderedDocuments(tasksSlice.selector, () => orderedTaskIds || []),
    ),
    deepEquals,
  );

  useFirestoreConnect([...tasksSlice.query, ...individualRequirementSlice.query]);
  const submitting = useRef(false);
  const { addToast } = useToast();
  const droppableAreaDescription: DroppableDescription = {
    id: requirementId,
    type: DroppableType.requirement,
    visibleList: taskIds,
    list: taskIds,
  };

  useSetNextTaskId(taskIds, selectedTaskId);

  const handleTaskCreation = useCallback(
    async (title: string, requirementId: Data.Id.RequirementId): Promise<boolean> => {
      if (submitting.current) return false;
      submitting.current = true;
      const taskToCreate = { ...extractTaskData(title), _relationships: { requirement: requirementId } };
      let success = false;
      await dispatch(createTask({ ...taskToCreate }))
        .then(unwrapResult)
        .then((newTaskId) => {
          segment.track('TaskCreated', { orgID: orgId, taskID: newTaskId, location: 'WorkDrawer' });
          success = true;
        })
        .catch(() => addToast({ type: 'error', message: strings.task.failedToCreateTask }));

      submitting.current = false;

      editorRef?.current?.clear();
      editorRef?.current?.focus();

      return success;
    },
    [addToast, dispatch, orgId, editorRef],
  );

  const addRequirementTaskMarkup = (placeholder: string): React.ReactElement => {
    return (
      <Box spaceLeft='$12px' spaceTop='$2px'>
        <Box background='$white' borderRadius='$2px' space='$8px' spaceRight='$4px'>
          <TaskCreation onEnter={(newTaskTitle) => handleTaskCreation(newTaskTitle, requirementId)}>
            <CreateTaskEditor ref={editorRef} placeholder={placeholder} />
          </TaskCreation>
        </Box>
      </Box>
    );
  };

  return (
    <DroppableArea description={droppableAreaDescription}>
      {() => {
        if (taskIds && taskIds.length) {
          return (
            <>
              <DNDContext.Consumer>
                {(dragStatus) => {
                  return (
                    <TaskCardsWraper
                      initialShowCreation={!dragStatus.isDragging}
                      onTaskSelect={onTaskSelect}
                      requirementId={requirementId}
                      taskIds={taskIds}
                    />
                  );
                }}
              </DNDContext.Consumer>
            </>
          );
        }

        return addRequirementTaskMarkup(strings.workDrawer.createFirstRequirementTask);
      }}
    </DroppableArea>
  );
}
