import { Selector } from '@reduxjs/toolkit';
import { Box, Hidden, styled, VStack } from '@taraai/design-system';
import { Data, UI } from '@taraai/types';
import { CompletedSprintDetailsSummary } from 'components/app/controllers/CompletedSprintDetailsSummary';
import SprintDetailsSummary from 'components/app/controllers/SprintDetailsSummary';
import SprintDetailsTimeline from 'components/app/controllers/SprintDetailsTimeline';
import SprintDetailsHeader from 'components/app/controllers/views/SprintDetailsHeader';
import SprintDetailsNoActiveSprints from 'components/app/controllers/views/SprintDetailsNoActiveSprints';
import { StandardSpinner } from 'components/core/controllers/views/Spinners';
import React from 'react';
import { CompleteSprintData } from 'reduxStore/sprints/queries/complete-sprint-data';
import { RootState } from 'reduxStore/store';

type RequirementFragment = Pick<UI.UIRequirement, 'id' | 'title'>;

export interface SprintDetailsLayoutProps {
  organizationId: Data.Id.OrganizationId;
  currentSprintId: string | undefined;
  selectedSprint: UI.UISprint | undefined;
  requirements: RequirementFragment[];
  today?: Date;
  nextSprintId: Data.Id.SprintId | null;
  previousSprintId: Data.Id.SprintId | null;
  isLoaded: boolean;
  selectedSprintData: CompleteSprintData | undefined;
  tasksSelector: Selector<RootState, UI.UITask[] | undefined>;
}
/**
 * Layout for the Sprint Details page and takes in a current sprint to pass to child component controllers and views
 */

export default function SprintDetailsLayout({
  selectedSprint,
  requirements,
  organizationId,
  today = new Date(),
  nextSprintId,
  previousSprintId,
  currentSprintId,
  isLoaded,
  selectedSprintData,
  tasksSelector,
}: SprintDetailsLayoutProps): JSX.Element {
  return (
    <VStack alignY='center'>
      <Hidden hidden={!isLoaded}>
        <Container>
          <SprintDetailsHeader
            completeSprintData={selectedSprintData}
            nextSprintId={nextSprintId}
            organizationId={organizationId}
            previousSprintId={previousSprintId}
            selectedSprint={selectedSprint}
          />
          {selectedSprint ? (
            <>
              <SprintDetailsTimeline
                currentDate={today}
                organizationId={organizationId}
                requirements={requirements}
                selectedSprint={selectedSprint}
                tasksSelector={tasksSelector}
              />
              {/* This flag is to prevent details summary from showing up
      on completed sprint details */}
              {selectedSprint?.isComplete ? (
                <CompletedSprintDetailsSummary completeSprintData={selectedSprintData} />
              ) : (
                <SprintDetailsSummary
                  currentSprintId={currentSprintId}
                  organizationId={organizationId}
                  sprint={selectedSprint}
                />
              )}
            </>
          ) : (
            <SprintDetailsNoActiveSprints />
          )}
        </Container>
      </Hidden>
      <Hidden hidden={isLoaded}>
        <Box height='$fullScreenHeight'>
          <StandardSpinner fillSpace size='medium' />
        </Box>
      </Hidden>
    </VStack>
  );
}

const Container = styled(VStack, { padding: '0rem 1.875rem 0rem 1.875rem' });
