"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useKeyboardSelection = void 0;
var react_1 = require("react");
function useKeyboardSelection(_a) {
    var length = _a.length, onClick = _a.onClick, onHide = _a.onHide, selectOnTab = _a.selectOnTab, show = _a.show;
    var selectedIndex = useSelectedIndex(show, length);
    (0, react_1.useEffect)(function () {
        if (!show)
            return;
        document.addEventListener('keydown', handleKeyDown, true);
        return function () { return document.removeEventListener('keydown', handleKeyDown, true); };
        function handleKeyDown(event) {
            switch (event.keyCode) {
                case 9: // event.code = "Tab"
                case 13: // event.code = "Enter"
                    if (selectOnTab || event.keyCode === 13) {
                        event.preventDefault();
                        event.stopPropagation();
                        if (typeof selectedIndex.index === 'number')
                            onClick(selectedIndex.index);
                    }
                    onHide();
                    return;
                case 27: // event.code = "Escape"
                    event.preventDefault();
                    onHide();
                    return;
                case 38: // event.code = "ArrowUp"
                    event.preventDefault();
                    selectedIndex.goToPrevIndex();
                    return;
                case 40: // event.code = "ArrowDown"
                    event.preventDefault();
                    selectedIndex.goToNextIndex();
            }
        }
    }, [onClick, onHide, selectOnTab, selectedIndex, show]);
    return selectedIndex.index;
}
exports.useKeyboardSelection = useKeyboardSelection;
function useSelectedIndex(show, length) {
    var _a = (0, react_1.useState)(undefined), index = _a[0], setIndex = _a[1];
    // Immediately deselect when `show` is false or the index is out of bounds
    if (typeof index === 'number' && (!show || (index > 0 && index >= length)))
        setIndex(undefined);
    return (0, react_1.useMemo)(function () { return ({
        index: index,
        goToPrevIndex: function () { return setIndex(function (prevIndex) { return ((prevIndex !== null && prevIndex !== void 0 ? prevIndex : 0) + length - 1) % length; }); },
        goToNextIndex: function () { return setIndex(function (prevIndex) { return ((prevIndex !== null && prevIndex !== void 0 ? prevIndex : -1) + 1) % length; }); },
    }); }, [length, index]);
}
