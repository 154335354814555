import { Data, UI } from '@taraai/types';
import { decode } from 'reduxStore/utils/decoders';
import { Write } from 'reduxStore/utils/types';

type RemoveRequirementReads = {
  task: UI.UITask;
  requirement: UI.UIRequirement;
};

export const removeTask = ({ task, requirement }: RemoveRequirementReads): Write | null => {
  const isSameRequirement = task._relationships.requirement === requirement.id;
  const { id, path } = requirement;

  if (isSameRequirement) return null;

  return decode<UI.UIRequirementMutation>(
    { id, path, orderedTaskIds: ['::arrayRemove', task.id] },
    'UIRequirementMutation',
  );
};

interface InsertNearReads {
  uid: Data.Id.UserId;
  task: UI.UITask;
  requirement: UI.UIRequirement;
  insertAfterId: Data.Id.TaskId;
  assignee?: string;
  visibleList: string[] | null;
}

export const insertNear = ({ task, requirement, insertAfterId, uid, visibleList }: InsertNearReads): Write => {
  const { id, path } = requirement;
  const orderedTaskIds = JSON.parse(JSON.stringify(visibleList || [])).filter(
    (taskID: Data.Id.TaskId) => taskID !== task.id && taskID !== null,
  );
  const index = orderedTaskIds.findIndex((taskId: Data.Id.TaskId) => taskId === insertAfterId);

  orderedTaskIds.splice(index + 1, 0, task.id);

  return decode<UI.UIRequirementMutation>(
    { id, path, orderedTaskIds, updatedBy: uid, updatedAt: ['::serverTimestamp'] },
    'UIRequirementMutation',
  );
};
