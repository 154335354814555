import { UI } from '@taraai/types';
import { isNonEmptyString } from '@taraai/utility';
import { createStandardSelector, inertQuery, Query } from 'reduxStore/utils/selectors';
import { ReduxFirestoreQuery } from 'reduxStore/utils/types';

export function getOpenPullRequests(orgId: string): Query<UI.UIPullRequest> {
  if (!isNonEmptyString(orgId)) {
    return inertQuery();
  }
  const query: ReduxFirestoreQuery = {
    collection: `orgs/${orgId}/pull-requests`,
    where: [['state', '==', 'open']],
    orderBy: [['createdAt', 'asc']],
    storeAs: `pullrequests/${orgId}/open`,
  };

  return {
    query: [query],
    selector: createStandardSelector<UI.UIPullRequest>(query),
  };
}

export function getBlocked(orgId: string, limit = 50): Query<UI.UIPullRequest> {
  if (!isNonEmptyString(orgId)) {
    return inertQuery();
  }

  const query: ReduxFirestoreQuery = {
    collection: `orgs/${orgId}/pull-requests`,
    where: [
      ['externalService.service', '==', 'github'],
      ['state', '==', 'open'],
      ['mergeableState', 'in', ['pending', 'checks-failed', 'blocked', 'conflicts']],
    ],
    orderBy: [['createdAt', 'desc']],
    limit,
    storeAs: `pullrequests/${orgId}/blocked`,
  };

  return {
    query: [query],
    selector: createStandardSelector<UI.UIPullRequest>(query),
  };
}
