import { Data, UI } from '@taraai/types';
import GanttChart from 'components/core/controllers/views/GanttChart';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Selector, useSelector } from 'react-redux';
import { compose } from 'redux';
import { getUsers, useAppDispatch } from 'reduxStore';
import { openTaskModal } from 'reduxStore/modals/actions';
import { RootState } from 'reduxStore/store';
import { toDate } from 'tools/libraries/helpers/dates';
import { filterTaraTasks, getResults } from 'tools/libraries/helpers/tableFilter';

type RequirementFragment = Pick<UI.UIRequirement, 'id' | 'title'>;
type UserFragment = Pick<UI.UIUser, 'id' | 'name'>;

export interface SprintDetailsLayoutProps {
  organizationId: Data.Id.OrganizationId;
  selectedSprint: UI.UISprint;
  requirements: RequirementFragment[];
  currentDate?: Date;
  tasksSelector: Selector<RootState, UI.UITask[] | undefined>;
}

// FIXME: this should be locale and user specified
const DATE_FORMAT = 'D MMM ddd';

export function enumerateDaysBetweenDates(start: Date, end: Date): moment.Moment[] {
  const dates: moment.Moment[] = [];
  const currDate = moment(start).subtract(1, 'days').startOf('day');
  const lastDate = moment(end).startOf('day');
  // eslint-disable-next-line no-loops/no-loops
  while (currDate.add(1, 'days').diff(lastDate) < 0) {
    dates.push(currDate.clone());
  }
  return dates;
}

/**
 * Displays the Gantt Chart and handles all data that will be passed to the chart
 */
export default function SprintDetailsTimeline({
  selectedSprint,
  organizationId,
  currentDate = new Date(),
  requirements,
  tasksSelector,
}: SprintDetailsLayoutProps): JSX.Element | null {
  const [filteredByAssignee, setFilteredByAssignee] = useState<UI.UITask[]>([]);
  const [filteredByRequirement, setFilteredByRequirement] = useState<UI.UITask[]>([]);
  const [filteredTasks, setFilteredTasks] = useState<UI.UITask[]>([]);

  const dispatch = useAppDispatch();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tasks = useSelector(tasksSelector) ?? [];

  const assignees = useSelector(
    compose(
      (users = []) => users.filter(({ id }) => tasks.some(({ assignee }) => assignee === id)),
      getUsers(organizationId).selector,
    ),
  );

  const handleFiltering = useCallback(
    (selectedAssignees: UserFragment[], selectedRequirements: RequirementFragment[]) => {
      const assigneeIds = selectedAssignees.map(({ id }) => id);
      const requirementsIds = selectedRequirements.map(({ id }) => id);
      setFilteredByAssignee(filterTaraTasks(tasks, 'assignee', assigneeIds));
      setFilteredByRequirement(filterTaraTasks(tasks, '_relationships', requirementsIds));
    },
    [tasks],
  );

  useEffect(() => {
    const combinedResults = getResults(filteredByAssignee, filteredByRequirement);
    setFilteredTasks(combinedResults);
  }, [filteredByAssignee, filteredByRequirement]);

  const toggleModal = useCallback(
    (task: UI.UITask): { payload: string; type: string } => dispatch(openTaskModal(task.id)),
    [dispatch],
  );

  const { startDate, endDate } = selectedSprint;

  const enumeratedDates = enumerateDaysBetweenDates(toDate(startDate), toDate(endDate));
  const formattedDates = enumeratedDates.map((date: moment.Moment) => date.format(DATE_FORMAT));
  const today = moment(currentDate).format(DATE_FORMAT);

  return (
    <GanttChart
      assignees={assignees}
      dates={formattedDates}
      handleFiltering={handleFiltering}
      orgId={organizationId}
      requirements={requirements}
      sprint={selectedSprint}
      tasks={filteredTasks || tasks}
      today={today}
      toggleModal={toggleModal}
    />
  );
}
