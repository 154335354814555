"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tree = void 0;
var utility_1 = require("@taraai/utility");
var react_1 = __importStar(require("react"));
var Box_1 = require("./Box");
var core_1 = require("./core");
var Hidden_1 = require("./Hidden");
var TreeContext = (0, react_1.createContext)({ toggle: utility_1.noop, unfolded: false });
function Tree(_a) {
    var children = _a.children, _b = _a.initialUnfolded, initialUnfolded = _b === void 0 ? false : _b, onChange = _a.onChange, forceUnfolded = _a.unfolded;
    var _c = (0, react_1.useState)(initialUnfolded), unfolded = _c[0], setUnfolded = _c[1];
    var toggle = (0, react_1.useCallback)(function () {
        if (typeof forceUnfolded === 'boolean') {
            /* istanbul ignore next */
            return onChange === null || onChange === void 0 ? void 0 : onChange(!forceUnfolded);
        }
        setUnfolded(function (prevUnfolded) {
            onChange === null || onChange === void 0 ? void 0 : onChange(!prevUnfolded);
            return !prevUnfolded;
        });
    }, [forceUnfolded, onChange]);
    return (react_1.default.createElement(TreeContext.Provider, { value: { toggle: toggle, unfolded: forceUnfolded !== null && forceUnfolded !== void 0 ? forceUnfolded : unfolded } }, children));
}
exports.Tree = Tree;
Tree.Widget = TreeContext.Consumer;
/** Accepts the same props as Box.Button; `onClick` is optional since `onDoubleClick` is provided */
Tree.Header = (0, react_1.forwardRef)(function TreeHeader(_a, ref) {
    var _b = _a.onClick, onClick = _b === void 0 ? utility_1.noop : _b, props = __rest(_a, ["onClick"]);
    var toggle = (0, react_1.useContext)(TreeContext).toggle;
    return react_1.default.createElement(Box_1.Box.Button, __assign({ ref: ref }, props, { onClick: onClick, onDoubleClick: toggle }));
});
Tree.Arrow = function TreeArrow(_a) {
    var clickArea = _a.clickArea, arrowColor = _a.arrowColor;
    var _b = (0, react_1.useContext)(TreeContext), toggle = _b.toggle, unfolded = _b.unfolded;
    var arrow = unfolded ? arrows(arrowColor).unfolded : arrows(arrowColor).folded;
    return (react_1.default.createElement(Box_1.Box.Button, { center: true, onClick: function (event) {
            event.stopPropagation();
            toggle();
        }, 
        /**
         * The double click should be ignored so as not to undo the second click.
         * That's because only the second consecutive click is registered in a special way, and the third, the fourth,
         * and so on clicks are just normal clicks. Because ther is no clean way of tracking that, we'll just handle all
         * single clicks consistently.
         */
        onDoubleClick: function (event) { return event.stopPropagation(); } },
        react_1.default.createElement("div", { style: __assign({ position: 'absolute', bottom: 0, left: 0, right: 0, top: 0 }, clickArea) }),
        arrow));
};
var arrows = function (arrowColor) {
    return {
        folded: (react_1.default.createElement("svg", { fill: arrowColor || core_1.tokens.colors.$grey6, height: '16', width: '16', xmlns: 'http://www.w3.org/2000/svg' },
            react_1.default.createElement("path", { d: 'M10.5 8L7.9 5.4a.3.3 0 00-.4.2v4.8c0 .2.3.3.4.2L10.5 8z' }))),
        unfolded: (react_1.default.createElement("svg", { fill: arrowColor || core_1.tokens.colors.$grey6, height: '16', width: '16', xmlns: 'http://www.w3.org/2000/svg' },
            react_1.default.createElement("path", { d: 'M8 10.5l2.6-2.6c.1-.1 0-.4-.2-.4H5.6c-.2 0-.3.3-.2.4L8 10.5z' }))),
    };
};
Tree.Content = function TreeContent(props) {
    return react_1.default.createElement(Hidden_1.Hidden, __assign({ hidden: !(0, react_1.useContext)(TreeContext).unfolded }, props));
};
Tree.FoldedContent = function TreeFoldedContent(props) {
    return react_1.default.createElement(Hidden_1.Hidden, __assign({ hidden: (0, react_1.useContext)(TreeContext).unfolded }, props));
};
