import { Identifiable, UI } from '@taraai/types';
import { getFilterByNameFn } from 'components/app/controllers/Selectors/common/filterFn';
import { Option } from 'components/app/controllers/Selectors/common/Option';
import SearchHeader from 'components/app/controllers/Selectors/common/SearchHeader';
import { SectionType, Selector } from 'components/core/controllers/Selector';
import { SelectorPosition } from 'components/core/controllers/Selector/types';
import React, { FunctionComponent } from 'react';

type UserFragment = Pick<UI.UIUser, 'id' | 'name' | 'avatarURL'>;
type Section = SectionType<Identifiable>;

type SelectorProps = Omit<
  React.ComponentProps<typeof Selector>,
  'renderOption' | 'onSelectOption' | 'onDeselectOption'
>;

type Props = SelectorProps & {
  closePopupOnSelection?: boolean;
  selection: UserFragment[];
  headerTitle?: string;
  searchPlaceholder: string;
  selectorPosition?: SelectorPosition;
  Wrapper?: FunctionComponent<{ 'data-cy'?: string }>;
  optionSize?: 'small' | 'medium';
  onSelectOption: (user: UserFragment) => void;
  onDeselectOption: (user: UserFragment) => void;
};

const filterFn = getFilterByNameFn<Section, Identifiable>();

/**
 * UserSelector is an UI component build on top of the Selector to handle selecting users.
 * It supports:
 * - multiple and single selection of user by mouse clicking and keyboard navigation
 * - searching users by name
 * - displaying user names and avatars in dropdown
 *
 * This component should be wrapped by parent component to handle specific use case
 * (e.g. TeamMemberSelector to select members of a team)
 */
export function UserSelector({
  closePopupOnSelection,
  headerTitle,
  searchPlaceholder,
  optionSize,
  ...props
}: Props): JSX.Element {
  return (
    <Selector
      closePopupOnSelection={closePopupOnSelection}
      filterFn={filterFn}
      renderHeader={(headerProps): JSX.Element => (
        <SearchHeader
          hideCloseIcon
          searchPlaceholder={searchPlaceholder}
          showSearch
          title={headerTitle}
          {...headerProps}
        />
      )}
      renderOption={({ option, ...optionProps }) => (
        <Option
          avatarURL={(option as UserFragment).avatarURL}
          mode='avatar'
          size={optionSize}
          title={(option as UserFragment).name}
          {...optionProps}
        />
      )}
      {...(props as unknown as Omit<Props, 'onSelectOption' | 'onDeselectOption'> & {
        onSelectOption: (user: Identifiable) => void;
        onDeselectOption: (user: Identifiable) => void;
      })}
    />
  );
}
