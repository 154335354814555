import { unwrapResult } from '@reduxjs/toolkit';
import { Data } from '@taraai/types';
import { linkTo } from 'components/Router/paths';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useAppDispatch } from 'reduxStore';
import { setupIntegration } from 'reduxStore/setupIntegration/setupIntegration';
import { strings } from 'resources';
import { useToast } from 'tools';
import { segment } from 'tools/libraries/analytics';
import { clearPendingIntegrationOrg } from 'tools/utils/pendingIntegrationOrg';

import { InstallableServiceWithRedirects } from './interfaces';
import { SetupIntegrationLayout } from './SetupIntegrationLayout';

type SetupIntegrationProps = {
  orgId?: Data.Id.OrganizationId;
  service: InstallableServiceWithRedirects;
  urlPayload: Record<string, string>;
  teamId: Data.Id.TeamId;
};

/**
 * Calls setupIntegration cloud function and handles errors
 * Then it redirects the user to integrations page
 */
export function SetupIntegration({ orgId, service, teamId, urlPayload }: SetupIntegrationProps): JSX.Element {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { addToast } = useToast();

  useEffect(() => {
    (async (): Promise<void> => {
      clearPendingIntegrationOrg();
      if (!orgId) {
        return;
      }

      try {
        const response = await dispatch(
          setupIntegration({
            service,
            organizationId: orgId,
            payload: urlPayload,
          }),
        ).then(unwrapResult);
        segment.track('IntegrationConnected', {
          orgID: orgId,
          service: response.service,
          location: 'SetupIntegration',
        });
        switch (response.service) {
          case 'github':
          case 'slack':
            return history.replace(
              linkTo('workspace', { orgId: response.organizationId, teamId }, '?tab=Integrations'),
            );
          case 'gitlab':
            throw new Error('Server responded with payload for incorrect service');
          case 'gitlabApp':
            return history.replace(
              linkTo('gitlabInsightsIntegrationComplete', { orgId: response.organizationId, teamId }),
            );
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error((error as { message: string }).message);
        addToast({
          message: strings.setupIntegration.error,
          type: 'error',
          timeoutMs: 3500,
        });

        history.replace(linkTo('integrations', { orgId, teamId }));
      }
    })();
  }, [dispatch, orgId, service, history, addToast, urlPayload, teamId]);

  return <SetupIntegrationLayout service={service} />;
}
