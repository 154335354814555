// eslint-disable-next-line import/no-unresolved
import { Transaction } from '@firebase/firestore-types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Data, UI } from '@taraai/types';
import { addDuration, getNumberAtEndAsString } from '@taraai/utility';
import { reduxStore, RootStateWithProfile } from 'reduxStore/store';
import { CREATION_DEFAULTS, decode } from 'reduxStore/utils/decoders';
import { ExtraAPI } from 'reduxStore/utils/types';
import { strings } from 'resources/i18n';

export const getNewSprintData = async (transaction: Transaction, extra: ExtraAPI): Promise<Data.Sprint> => {
  const { getOrgId, getFirestore, getTeamId } = extra as ExtraAPI;
  const state = reduxStore.getState() as RootStateWithProfile;
  const firestore = getFirestore();
  const teamId = getTeamId(state);
  const orgId = getOrgId();
  const orgRef = firestore.collection('orgs').doc(orgId);
  const teamRef = orgRef.collection('teams').doc(teamId);

  const teamData = (await transaction.get(teamRef)).data() as Data.Team;
  if (!teamData) throw new Error('Team does not exist');

  const { totalSprintsCount, sprintSettings } = teamData;
  const newSprintNumber = totalSprintsCount + 1;

  const lastCreatedSprintSnapshot = await orgRef
    .collection('sprints')
    .where('teamId', '==', teamId)
    .where('archived', '==', false)
    .where('deleted', '==', false)
    .orderBy('createdAt', 'desc')
    .limit(1)
    .get();

  const lastCreatedSprintData = (lastCreatedSprintSnapshot.docs.pop()?.data() as Data.Sprint) || undefined;

  const now = firestore.Timestamp.now();
  const startDate = lastCreatedSprintData?.endDate ?? now;
  const endDate = firestore.Timestamp.fromDate(addDuration(startDate.toDate(), sprintSettings.duration));

  return decode<Data.Sprint>(
    {
      teamId,
      startDate,
      endDate,
      createdAt: now,
      updatedAt: now,
      sprintName: getNewSprintName(lastCreatedSprintData, newSprintNumber),
      sprintNumber: newSprintNumber,
    },
    'Sprint',
    CREATION_DEFAULTS,
  );
};

export const createSprint = createAsyncThunk('CreateSprint', async (___, { extra }) => {
  const { getOrgId, getFirestore } = extra as ExtraAPI;
  const firestore = getFirestore();
  const orgId = getOrgId();

  return firestore.runTransaction(async (transaction) => {
    const orgRef = firestore.collection('orgs').doc(orgId);
    const newSprintRef = orgRef.collection('sprints').doc();
    const newSprintData = await getNewSprintData(transaction, extra as ExtraAPI);

    transaction.set(newSprintRef, newSprintData);

    return { id: newSprintRef.id, ...newSprintData } as UI.UISprint;
  });
});

export function getNewSprintName(lastSprintData: Data.Sprint | undefined, newSprintNumber: number): string {
  if (lastSprintData) {
    const titleNumberString = getNumberAtEndAsString(lastSprintData.sprintName);

    // If number found in the title, increment it by 1
    if (titleNumberString) {
      const nextNumber = parseInt(titleNumberString, 10) + 1;
      return lastSprintData.sprintName.replace(titleNumberString, nextNumber.toString());
    }

    // If no number found in the title, append ' 2' for the next sprint title
    return lastSprintData.sprintName.concat(' 2');
  }

  // If this is the first sprint or any other edge case occurs,
  // use the default title using the org sprintNumber value
  return strings.formatString(strings.sprints.sprintNumber, {
    number: newSprintNumber.toString(),
  }) as string;
}
