import { UI } from '@taraai/types';
import Logo from 'components/core/controllers/views/Logo';
import { css } from 'emotion';
import React from 'react';
import { homePageTestIds } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';
import { formatDMMM } from 'tools';

export interface TeamStatsProps {
  done: number;
  effort: number;
  effortCompleted: number;
  teamName: string;
  sprint: UI.UISprint;
}

/**
 * TeamStats shows the percentage of tasks that are completed
 * and the effort estimates for the tasks during the sprint
 *
 */
export default function TeamStats({ done, effort, effortCompleted, teamName, sprint }: TeamStatsProps): JSX.Element {
  return (
    <div
      className={css`
        margin: 1rem;
        background-color: #ffffff;
      `}
    >
      <div
        className={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 22px;
        `}
      >
        <div
          className={css`
            font-size: 0.75rem;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.025rem;
            text-align: center;
            color: #67728b;
            text-transform: uppercase;
          `}
        >
          {teamName}
        </div>
        <div
          className={css`
            font-size: 0.75rem;
            font-weight: 500;
            padding-top: 4px;
            color: #949caf;
            :hover {
              color: #949caf;
            }
          `}
        >
          {formatDMMM(sprint.startDate)}
          {strings.sprints.sprintColumn.sprintDateSpace}
          {formatDMMM(sprint.endDate)}
        </div>
      </div>
      <div
        className={css`
          display: flex;
          justify-content: center;
        `}
      >
        <div
          className={css`
            float: left;
          `}
        >
          <div
            className={css`
              font-size: 1.125rem;
              font-weight: 600;
              text-align: center;
              color: #575f65;
            `}
            data-cy={homePageTestIds.TEAM_PERCENT_DONE_IN_SPRINT}
          >
            {strings.formatString(strings.keyStatistics.totalTasksDone, {
              number: Number.isNaN(done) ? 0 : done,
            })}
          </div>
          <p
            className={css`
              font-size: 0.75rem;
              text-align: center;
              color: #949caf;
              padding: 1.3125rem;
              padding-top: 0.3125rem;
            `}
          >
            {strings.keyStatistics.done}
          </p>
        </div>
        <Logo name={teamName} shape='circle' size='medium' />
        <div
          className={css`
            float: right;
          `}
        >
          <div
            className={css`
              font-size: 1.125rem;
              font-weight: 600;
              text-align: center;
              color: #575f65;
            `}
            data-cy={homePageTestIds.TEAM_EFFORT_IN_SPRINT}
          >
            {strings.formatString(strings.keyStatistics.totalEffortCompleted, {
              effortCompleted,
              effort,
            })}
          </div>
          <p
            className={css`
              font-size: 0.75rem;
              text-align: center;
              color: #949caf;
              padding: 1.3125rem;
              padding-top: 0.3125rem;
            `}
          >
            {strings.keyStatistics.effort}
          </p>
        </div>
      </div>
    </div>
  );
}
