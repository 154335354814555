import styled from '@emotion/styled';
import { Tooltip } from '@taraai/design-system';
import SpinnerButton from 'components/core/controllers/views/SpinnerButton';
import React from 'react';
import { teamDetailsTestIds } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';

interface Props {
  dataCy?: string;
  isPreferred: boolean;
  onClick: (event: React.SyntheticEvent) => void;
}

export function PreferredTeamButton({ dataCy, isPreferred, onClick }: Props): JSX.Element {
  return (
    <PreferredContainer>
      <Tooltip placement='bottom' title={strings.teamDetails.preferredTooltip}>
        {isPreferred ? (
          <PreferredLabel>{strings.profile.teams.preferredTeamLabel}</PreferredLabel>
        ) : (
          // div tag is needed because Function components cannot be given refs
          <div>
            <SetPreferredButton dataCy={dataCy} onClick={onClick}>
              {strings.profile.teams.setPreferredTeamButtonLabel}
            </SetPreferredButton>
          </div>
        )}
      </Tooltip>
    </PreferredContainer>
  );
}

const SetPreferredButton = styled((props) => (
  <SpinnerButton
    color='ghost'
    dataCy={teamDetailsTestIds.SET_PREFERRED_BUTTON}
    size='compact'
    type='button'
    {...props}
  />
))`
  width: 100%;
  height: 1.75rem;
  min-width: 8.5rem;
  margin: 0.25rem;
  display: block;
  font-size: 0.875rem;
`;

export const PreferredContainer = styled.div`
  align-self: center;
`;

export const PreferredLabel = styled.div`
  font-size: 0.875rem;
  min-width: 8.5rem;
  height: 1.75rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  line-height: 1;
  letter-spacing: normal;
  align-items: center;
  color: #389e0d;
  border-radius: 3px;
  border: solid 1px #389e0d;
  padding: 0.25rem;
  margin: 0.25rem;
  &:hover {
    cursor: not-allowed;
  }
`;
