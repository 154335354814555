import { Radio, Text, VStack } from '@taraai/design-system';
import { TaskStatus } from '@taraai/types';
import { SprintDetailsSidebarModule } from 'components/app/controllers/views/SprintDetailsSidebarModule';
import { Header } from 'components/app/controllers/views/SprintDetailsSidebarModule/Header';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { hasFeature, selectActiveWorkspace } from 'reduxStore';
import { strings } from 'resources/i18n';

import { RadioFilterEntry } from './RadioFilterEntry';
import { TaskSummaries } from './types';

function SprintFiltersModuleHeader({
  onFilterReset,
  resetDisabled,
}: {
  onFilterReset: () => void;
  resetDisabled: boolean;
}): JSX.Element {
  return (
    <Header
      button={
        <Text
          color={resetDisabled ? '$grey2' : '$focus'}
          onClick={() => {
            if (!resetDisabled) {
              onFilterReset();
            }
          }}
          size='$10px'
          style={{ cursor: resetDisabled ? 'default' : 'pointer', userSelect: 'none' }}
          weight='medium'
        >
          {strings.sprintFilters.reset}
        </Text>
      }
      icon='filters'
      title={strings.sprintFilters.moduleTitle}
    />
  );
}

type Props = {
  taskSummaries: TaskSummaries;
  filterValue: TaskStatus | undefined;
  onFilterSelect: (status: TaskStatus | undefined) => void;
  onFilterReset: () => void;
};

export function SprintFiltersModule({ taskSummaries, filterValue, onFilterSelect, onFilterReset }: Props): JSX.Element {
  const totalTaskCount = taskSummaries.reduce((sum, { taskCount }) => sum + taskCount, 0);
  const enabledFilterTypes = taskSummaries.reduce(
    (enabled, { taskCount }, filterType) => (taskCount > 0 ? [...enabled, filterType as TaskStatus] : enabled),
    [] as TaskStatus[],
  );

  // if there is only one valid filter we can apply
  // we want to show it as if the filter is applied,
  // without actually applying it (it makes no difference, since sprint only contains one type of tasks)
  const onlyEnabledFilterType = enabledFilterTypes.length === 1 ? enabledFilterTypes[0] : undefined;

  useEffect(() => {
    // reset filters if we have only one option (it makes no difference if the filter is applied or not)
    if (onlyEnabledFilterType) {
      onFilterReset();
    }
  }, [onFilterReset, onlyEnabledFilterType]);

  const orgId = useSelector(selectActiveWorkspace);

  const isQAStatusEnabled = useSelector(hasFeature('qaStatus', orgId));

  return (
    <SprintDetailsSidebarModule
      header={
        <SprintFiltersModuleHeader
          onFilterReset={onFilterReset}
          resetDisabled={filterValue === undefined || !!onlyEnabledFilterType}
        />
      }
    >
      <Radio.Group
        deselectable
        onChange={(value) => {
          if (!onlyEnabledFilterType) onFilterSelect(value);
        }}
        value={onlyEnabledFilterType ?? filterValue}
      >
        <VStack space='$4px'>
          {taskSummaries.map(({ taskCount, plannedEffort }, taskStatus) =>
            !isQAStatusEnabled ? (
              taskStatus !== TaskStatus.QA && (
                <RadioFilterEntry
                  key={taskStatus}
                  plannedEffort={plannedEffort}
                  selectedFilterType={onlyEnabledFilterType ?? filterValue}
                  taskCount={taskCount}
                  taskStatus={taskStatus}
                  totalTaskCount={totalTaskCount}
                />
              )
            ) : (
              <RadioFilterEntry
                key={taskStatus}
                plannedEffort={plannedEffort}
                selectedFilterType={onlyEnabledFilterType ?? filterValue}
                taskCount={taskCount}
                taskStatus={taskStatus}
                totalTaskCount={totalTaskCount}
              />
            ),
          )}
        </VStack>
      </Radio.Group>
    </SprintDetailsSidebarModule>
  );
}
