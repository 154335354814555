import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Data } from '@taraai/types';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { RootStateWithProfile } from 'reduxStore/store';

export type GitInsightsTimeSegment = 'weekly' | 'monthly' | 'quarterly';

export type GitInsightsSelectionType = 'teams' | 'users';

type State = {
  selectionType: GitInsightsSelectionType;
  selected: string[];
  timeSegment: GitInsightsTimeSegment;
  chartData: Data.ChartData | null;
};

// TODO: find way to inject active team as initial state for now set on initial insights page load
export const initialState: State = { timeSegment: 'weekly', selected: [], selectionType: 'users', chartData: null };

// get all state for firestore hooks
const gitInsightsSlice = createSlice({
  name: 'gitInsights',
  initialState,
  reducers: {
    setSelectionType: (state: State, { payload: { type } }: PayloadAction<{ type: GitInsightsSelectionType }>) => {
      state.selectionType = type;
      state.selected = [];
    },
    addItemToSelection: (state: State, { payload: { id } }: PayloadAction<{ id: string }>) => {
      state.selected = [...state.selected, id];
    },
    addChartData: (state: State, { payload: { chartData } }: PayloadAction<{ chartData: Data.ChartData | null }>) => {
      state.chartData = chartData;
    },
    removeItemFromSelection: (state: State, { payload: { id } }: PayloadAction<{ id: string }>) => {
      state.selected = state.selected.filter((item) => item !== id);
    },
    selectTimeSegment: (
      state: State,
      { payload: { timeSegment } }: PayloadAction<{ timeSegment: GitInsightsTimeSegment }>,
    ) => {
      state.timeSegment = timeSegment;
    },
    resetOrInitializeSelection: (
      state: State,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { payload: { activeTeamId } }: PayloadAction<{ activeTeamId: string }>,
    ) => {
      // set selected to empty array for now, in future set to active team members
      state.selected = [];
      state.chartData = null;
      state.selectionType = initialState.selectionType;
      state.timeSegment = initialState.timeSegment;
    },
  },
});

const gitInsightsActions = gitInsightsSlice.actions;
export const gitInsightsReducer = gitInsightsSlice.reducer;

/**
 * sets selection type of gitInsights selection either 'teams' or 'users'
 */
export function useSetInsightSelectionType(): (type: GitInsightsSelectionType) => void {
  const dispatch = useDispatch();
  return useCallback(
    (type) => {
      dispatch(gitInsightsActions.setSelectionType({ type }));
    },
    [dispatch],
  );
}

/**
 * add   an item from the selection git insights, can be either userId or teamId
 */
export function useAddInsightSelection(): (selectionId: string) => void {
  const dispatch = useDispatch();
  return useCallback(
    (selectionId) => {
      dispatch(gitInsightsActions.addItemToSelection({ id: selectionId }));
    },
    [dispatch],
  );
}

/**
 * remove an item from the selection git insights, can be either userId or teamId
 */
export function useRemoveInsightSelection(): (selectionId: string) => void {
  const dispatch = useDispatch();
  return useCallback(
    (selectionId) => {
      dispatch(gitInsightsActions.removeItemFromSelection({ id: selectionId }));
    },
    [dispatch],
  );
}

/**
 * sets timeSegment selection to either "weekly", "monthly", "quarterly".
 */
export function useSetInsightsTimeSegment(): (timeSegment: GitInsightsTimeSegment) => void {
  const dispatch = useDispatch();
  return useCallback(
    (timeSegment) => {
      dispatch(gitInsightsActions.selectTimeSegment({ timeSegment }));
    },
    [dispatch],
  );
}

/**
 * sets the initial state to reset or initialize the slice the active teamId must be passed in
 */
export function useClearInsightSelections(activeTeamId: string): () => void {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(gitInsightsActions.resetOrInitializeSelection({ activeTeamId }));
  }, [dispatch, activeTeamId]);
}

export function gitInsightsSelectionState(state: RootStateWithProfile): State {
  return state.gitInsights;
}

/**
 * set chart data when it is fetched from hook
 */
export function useAddInsightChartData(): (chartData: Data.ChartData | null) => void {
  const dispatch = useDispatch();
  return useCallback(
    (chartData) => {
      dispatch(gitInsightsActions.addChartData({ chartData }));
    },
    [dispatch],
  );
}

/**
 * gets chart data for a given chartName
 */
export function getChartData(state: RootStateWithProfile): Partial<Data.ChartData> | null {
  if (!state.gitInsights.chartData && state.gitInsights.chartData === null) return null;
  return state.gitInsights.chartData;
}
