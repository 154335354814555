import { unwrapResult } from '@reduxjs/toolkit';
import React, { useState } from 'react';
import { useAppDispatch } from 'reduxStore';
import { subscriptionPurchase } from 'reduxStore/monetization';
import { useToast } from 'tools';

import { UpgradeView } from './UpgradeView';

export function UpgradeController(): JSX.Element {
  const dispatch = useAppDispatch();
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  function sendToCheckout(price: string): void {
    setIsLoading(true);
    dispatch(subscriptionPurchase({ price }))
      .then(unwrapResult)
      .catch(() => {
        setIsLoading(false);
        addToast({ type: 'error', message: 'Error processing payment.', timeoutMs: 3000 });
      });
  }

  return <UpgradeView isLoading={isLoading} sendToCheckout={sendToCheckout} />;
}
