import { Box, Fluid, HStack, styled, Text } from '@taraai/design-system';
import { Tokens } from '@taraai/design-system/dist/core/tokens';
import { TaskStatus } from '@taraai/types';
import Icon from 'components/core/controllers/views/Icon';
import React from 'react';
import { strings } from 'resources/i18n';
import { mapTaskStatusToColor } from 'tools/utils/mapTaskStatusToColor';

interface SprintFilterCardProps {
  assignee?: string;
  statusFilter?: TaskStatus;

  onFilterReset: () => void;
}

const getFilterText = (statusFilter: TaskStatus | undefined, assigneeFilter: string | undefined): string => {
  if (statusFilter !== undefined && assigneeFilter !== undefined) {
    return strings.sprints.sprintColumn.twoFiltersApplied;
  }
  if (statusFilter !== undefined) {
    return strings.formatString(strings.sprints.sprintColumn.filteredByStatus, {
      status: strings.sprints.sprintColumn.simpleLabels[statusFilter],
    }) as string;
  }
  if (assigneeFilter !== undefined) {
    return strings.formatString(strings.sprints.sprintColumn.filteredByAssignee, {
      assignee: assigneeFilter,
    }) as string;
  }
  return '';
};

export function SprintFilterCard({ assignee, statusFilter, onFilterReset }: SprintFilterCardProps): JSX.Element {
  const hasAssigneeFilter = assignee !== undefined;
  const hasStatusFilter = statusFilter !== undefined;
  const hasOnlyAssigneeFilter = hasAssigneeFilter && !hasStatusFilter;
  const mainColor = getMainColor(statusFilter, hasOnlyAssigneeFilter);
  return (
    <Box background={mainColor} border='$grey4' borderRadius='$4px' space='$8px'>
      <HStack alignY='center' space='$4px'>
        <FilterIcon name='filters' noPadding />
        <Text color='$white' size='$10px' weight='bold'>
          {getFilterText(statusFilter, assignee)}
        </Text>
        <Fluid />
        <Box.Button background='$white' borderRadius='$2px' onClick={onFilterReset} spaceHorz='$8px' spaceVert='$2px'>
          <Text color={mainColor} size='$10px' weight='medium'>
            {strings.menuFilter.reset}
          </Text>
        </Box.Button>
      </HStack>
    </Box>
  );
}

const FilterIcon = styled(Icon, { width: '$12px', height: '$12px', color: '$white' });

function getMainColor(
  statusFilter: TaskStatus | undefined,
  hasOnlyAssigneeFilter: boolean,
): keyof Tokens['colors'] | undefined {
  if (hasOnlyAssigneeFilter) return '$grey7';
  if (statusFilter !== undefined) return mapTaskStatusToColor[statusFilter];
  return undefined;
}
