/* eslint-disable id-length */
import { Box, Dropdown, fade, HStack, styled, Text, tokens } from '@taraai/design-system';
import { Data, TaskStatus } from '@taraai/types';
import Icon from 'components/core/controllers/views/Icon';
import React, { SyntheticEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { hasFeature, selectActiveWorkspace, updateTask, useAppDispatch } from 'reduxStore';
import { mapTaskStatusToColor } from 'tools/utils/mapTaskStatusToColor';

import { taskStatusMap } from './types';

type Props = {
  showLabel?: boolean;
  taskId: Data.Id.TaskId;
  status: TaskStatus;
};

export function StatusSelector({ showLabel, status, taskId }: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const [show, setShow] = useState(false);
  const selectedStatus = taskStatusMap[status];
  const statusColor = selectedStatus.color;

  const orgId = useSelector(selectActiveWorkspace);

  const isQAStatusEnabled = useSelector(hasFeature('qaStatus', orgId));

  const updateStatus = (key: TaskStatus) => async (): Promise<void> => {
    await dispatch(updateTask({ id: taskId, status: key }));
  };

  const onClick = (event: SyntheticEvent): void => {
    event.stopPropagation();
    setShow((prev) => !prev);
  };

  const taskStatusComponents = [Todo, Doing, Done, QA];

  const taskStatusMapWithComponents = taskStatusMap
    .map((data) => ({
      ...data,
      Component: taskStatusComponents[data.key],
    }))
    // Just make QA available if feature flag is enabled
    // This is the best way to handle this since the main types will always have the QA status
    .filter((item) => (isQAStatusEnabled ? item : item.label !== 'QA'));

  return (
    <Dropdown
      items={taskStatusMapWithComponents.map(({ color, key, name, label, Component }) => (
        <Dropdown.Item key={key} onClick={updateStatus(key)}>
          {({ selected }) => (
            <Component selected={selected} spaceHorz='$8px' spaceVert='$4px'>
              <HStack alignY='center' space='$8px'>
                <StatusIcon name={name} noPadding />
                <Text color={color} size='$10px' uppercase weight='medium'>
                  {label}
                </Text>
              </HStack>
            </Component>
          )}
        </Dropdown.Item>
      ))}
      onHide={() => setShow(false)}
      placement='bottom-start'
      show={show}
      variant='small'
    >
      <Box.Button
        background={fade(statusColor, '$20%')}
        borderRadius='$circle'
        center='vert'
        height='$16px'
        onClick={onClick}
        spaceHorz='$4px'
      >
        <HStack alignY='center' space='$4px'>
          <StatusIcon name={selectedStatus.name} noPadding />
          {showLabel && (
            <Text color={statusColor} size='$10px' uppercase weight='medium'>
              {selectedStatus.label}
            </Text>
          )}
          <DropdownIcon color={tokens.colors[statusColor]} name='dropdownindicator' noPadding />
        </HStack>
      </Box.Button>
    </Dropdown>
  );
}

const StatusIcon = styled(Icon, {
  width: '0.625rem',
  height: '0.625rem',
});

const DropdownIcon = styled(Icon, {
  width: '0.375rem',
  height: '0.375rem',
  marginTop: '1px',
});

const Todo = styled(
  Box,
  { ':hover': { backgroundColor: fade(mapTaskStatusToColor[TaskStatus.Todo], '$10%') } },
  { selected: { true: { backgroundColor: fade(mapTaskStatusToColor[TaskStatus.Todo], '$10%') } } },
);

const Doing = styled(
  Box,
  { ':hover': { backgroundColor: fade(mapTaskStatusToColor[TaskStatus.Doing], '$10%') } },
  { selected: { true: { backgroundColor: fade(mapTaskStatusToColor[TaskStatus.Doing], '$10%') } } },
);

const Done = styled(
  Box,
  { ':hover': { backgroundColor: fade(mapTaskStatusToColor[TaskStatus.Done], '$10%') } },
  { selected: { true: { backgroundColor: fade(mapTaskStatusToColor[TaskStatus.Done], '$10%') } } },
);

const QA = styled(
  Box,
  { ':hover': { backgroundColor: fade(mapTaskStatusToColor[TaskStatus.QA], '$10%') } },
  { selected: { true: { backgroundColor: fade(mapTaskStatusToColor[TaskStatus.QA], '$10%') } } },
);
