"use strict";
/* eslint-disable security/detect-non-literal-regexp */
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseMentionsFromPlainText = exports.markdownMentionIdRegExp = void 0;
var mentionIdUnboundRegExp = /[\dA-Za-z]+/u;
exports.markdownMentionIdRegExp = new RegExp(String.raw(templateObject_1 || (templateObject_1 = __makeTemplateObject(["@tara-user-(", ")"], ["@tara-user-(", ")"])), mentionIdUnboundRegExp.source), 'gu');
function parseMentionsFromPlainText(plainText) {
    return Array.from(plainText.matchAll(exports.markdownMentionIdRegExp), function (_a) {
        var id = _a[1];
        return id;
    });
}
exports.parseMentionsFromPlainText = parseMentionsFromPlainText;
var templateObject_1;
