import { styled } from '@taraai/design-system';
import { isLoaded } from '@taraai/read-write';
import { UI } from '@taraai/types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { compose } from 'redux';
import {
  selectActiveTeam,
  selectActiveWorkspace,
  selectAllTeams,
  selectAuth,
  selectCurrentTeam,
  selectPreferredTeam,
  selectUserTeams,
} from 'reduxStore';
import { strings } from 'resources';
import { useClickOutside } from 'tools';

import { TeamSwitcherPopup } from './Popup';
import { TeamSwitcherButton } from './SwitcherButton';
import { TeamsSection } from './types';

function teamPreview(team: UI.UITeam): Pick<UI.UITeam, 'id' | 'name'> {
  return {
    id: team.id,
    name: team.name,
  };
}

/**
 * TeamSwitcher allows to switch current team
 */
export function TeamSwitcher(): JSX.Element | null {
  const orgId = useSelector(selectActiveWorkspace);
  const teamId = useSelector(selectActiveTeam);

  const preferredTeam = useSelector(
    compose((team) => team && teamPreview(team), selectPreferredTeam(orgId)),
    deepEquals,
  );
  const currentTeam = useSelector(
    compose((team) => team && teamPreview(team), selectCurrentTeam(orgId, teamId)),
    deepEquals,
  );

  const profileId = useSelector(compose((data) => data?.uid, selectAuth));

  const userTeams = useSelector(
    compose((teams) => teams?.map((team) => teamPreview(team)), selectUserTeams(orgId)),
    deepEquals,
  );
  const allTeams = useSelector(
    compose((teams) => teams?.map((team) => teamPreview(team)), selectAllTeams(orgId)),
    deepEquals,
  );

  const selectedTeam = currentTeam ?? userTeams[0];
  const popupRef = useRef<HTMLDivElement>(null);

  const sections: TeamsSection[] = [{ id: '1', label: strings.teamSwitcher.selectTeam, options: allTeams }];

  const [showPopup, setShowPopup] = useState(false);

  const openPopup = useCallback(() => {
    setShowPopup(true);
  }, [setShowPopup]);

  const closePopup = useCallback(() => {
    setShowPopup(false);
  }, [setShowPopup]);

  const history = useHistory();

  useEffect(() => closePopup(), [history.location.pathname, closePopup]);

  useClickOutside(popupRef, closePopup);

  if (!isLoaded(preferredTeam)) return null;

  return (
    <Wrapper>
      <TeamSwitcherButton interactive={!showPopup} onClick={openPopup} selectedTeamName={selectedTeam.name}>
        {showPopup && (
          <TeamSwitcherPopup
            containerRef={popupRef}
            preferredTeamId={preferredTeam.id}
            profileId={profileId}
            sections={sections}
            selectedTeamId={selectedTeam.id}
          />
        )}
      </TeamSwitcherButton>
    </Wrapper>
  );
}

const Wrapper = styled('div', {
  position: 'relative',
});
