import { css, cx } from 'emotion';
import React from 'react';
import { atomic } from 'resources/theme';

/**
 * TableCell does…
 *
 */

export interface TableCellProps extends React.HTMLProps<HTMLTableCellElement> {
  columnSpan?: number;
}
export default function TableCell({ className, children, columnSpan = 1, ...props }: TableCellProps): JSX.Element {
  return (
    <td
      className={cx(
        css`
          color: #303f4b;
          display: table-cell;
          font-size: 0.875rem;
          padding: 0.75rem;
          width: ${columnSpan && '100%'};
          :last-child {
            text-align: right;
          }
          :first-child {
            text-align: left;
          }
          ${atomic.responsive.query({
            nanoTiny: true,
            tinySmall: true,
            smallMedium: true,
          })} {
            width: auto;
            display: flex;
            padding: 0.3125rem;
          }
        `,
        className,
      )}
      colSpan={columnSpan}
      {...props}
    >
      {children}
    </td>
  );
}
