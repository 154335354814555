import React from 'react';

export const CalendarIcon = (
  <svg fill='none' height='13' viewBox='0 0 12 13' width='12' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4.4271 1.91665H7.9896V0.927063C7.9896 0.599264 8.25432 0.333313 8.58335 0.333313C8.91239 0.333313 9.1771 0.599264 9.1771 0.927063V1.91665H10.1667C11.04 1.91665 11.75 2.62544 11.75 3.49998V11.4166C11.75 12.29 11.04 13 10.1667 13H2.25002C1.37548 13 0.666687 12.29 0.666687 11.4166V3.49998C0.666687 2.62544 1.37548 1.91665 2.25002 1.91665H3.2396V0.927063C3.2396 0.599264 3.50432 0.333313 3.83335 0.333313C4.16239 0.333313 4.4271 0.599264 4.4271 0.927063V1.91665ZM1.85419 6.46873H3.83335V5.08331H1.85419V6.46873ZM1.85419 7.65623V9.23956H3.83335V7.65623H1.85419ZM5.02085 7.65623V9.23956H7.39585V7.65623H5.02085ZM8.58335 7.65623V9.23956H10.5625V7.65623H8.58335ZM10.5625 5.08331H8.58335V6.46873H10.5625V5.08331ZM10.5625 10.4271H8.58335V11.8125H10.1667C10.3844 11.8125 10.5625 11.6344 10.5625 11.4166V10.4271ZM7.39585 10.4271H5.02085V11.8125H7.39585V10.4271ZM3.83335 10.4271H1.85419V11.4166C1.85419 11.6344 2.03132 11.8125 2.25002 11.8125H3.83335V10.4271ZM7.39585 5.08331H5.02085V6.46873H7.39585V5.08331Z'
      fill='#525965'
    />
  </svg>
);
