import styled from '@emotion/styled';
import { Identifiable } from '@taraai/types';
import { isSelected } from 'components/core/controllers/Selector/helpers';
import { Index, OptionRenderProps, SectionRenderProps, SectionType } from 'components/core/controllers/Selector/types';
import React from 'react';

import { SelectorOption } from './Option';

type Props<Section extends SectionType<Option>, Option extends Identifiable> = {
  sections: Section[];
  selection: Option | Option[];
  activeOptionIndex: Index | null;
  renderOption: (props: OptionRenderProps<Option>) => JSX.Element;
  renderSectionHeader?: (props: SectionRenderProps<Section>) => JSX.Element | null;
  renderSectionFooter?: (props: SectionRenderProps<Section>) => JSX.Element | null;
  onSelect: (option: Option) => (event: React.SyntheticEvent) => void;
};

export function SelectorPopupList<Section extends SectionType<Option>, Option extends Identifiable>({
  sections,
  selection,
  activeOptionIndex,
  renderOption,
  renderSectionHeader,
  renderSectionFooter,
  onSelect,
}: Props<Section, Option>): JSX.Element | null {
  const showList = sections.length > 0;
  if (!showList) {
    return null;
  }

  return (
    <Container>
      {sections.map((section, sectionIndex): JSX.Element | null => (
        <SectionContainer key={section.id}>
          {renderSectionHeader?.({ section })}
          {section.options?.map((option, optionIndex): JSX.Element | null => (
            <SelectorOption
              key={option.id}
              isActive={
                sectionIndex === activeOptionIndex?.sectionIndex && optionIndex === activeOptionIndex?.optionIndex
              }
              isSelected={isSelected(option, selection)}
              onSelect={onSelect(option)}
              option={option}
              renderOption={renderOption}
            />
          ))}
          {renderSectionFooter?.({ section })}
        </SectionContainer>
      ))}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  max-height: 14.25rem;
  overflow-y: auto;
`;

const SectionContainer = styled.div`
  width: 100%;
`;
