import { css, cx } from 'emotion';
import React from 'react';
import { atomic } from 'resources/theme';

/**
 * TableHeaderCell does…
 *
 */
export default function TableHeaderCell({
  className,
  children,
  ...props
}: React.HTMLProps<HTMLTableCellElement>): JSX.Element {
  return (
    <th
      className={cx(
        css`
          color: #67728b;
          display: table-cell;
          font-size: 0.75rem;
          font-weight: 500;
          padding: 0.75rem;
          text-align: left;
          text-transform: uppercase;
          :last-child {
            text-align: right;
          }
          :first-child {
            text-align: left;
          }
          ${atomic.responsive.query({
            nanoTiny: true,
            tinySmall: true,
            smallMedium: true,
          })} {
            display: flex;
          }
        `,
        className,
      )}
      {...props}
    >
      {children}
    </th>
  );
}
