import ActiveUsersController from 'components/app/controllers/ActiveUsersController';
import DeactivatedUsersController from 'components/app/controllers/DeactivatedUsersController';
import InvitedUsersController from 'components/app/controllers/InvitedUsersController';
import InviteUsersController from 'components/app/controllers/InviteUsersController';
import Button from 'components/core/controllers/views/Button';
import TabBar from 'components/core/controllers/views/TabBar';
import { usePathParams } from 'components/Router/paths';
import { css } from 'emotion';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { isProfileAdmin } from 'reduxStore';
import { strings } from 'resources/i18n';

/**
 * UsersLayout displays users tabbed view
 */
export default function UsersLayout(): JSX.Element {
  const { orgId } = usePathParams('workspace');
  const isAdmin = useSelector(isProfileAdmin(orgId));
  const [inviteUsersOpen, setInviteUsersOpen] = useState(false);

  const tabs = [
    {
      label: strings.users.activeUsers.tabLabel,
      content: <ActiveUsersController />,
      route: 'active-users',
    },
    {
      label: strings.users.invitedUsers.tabLabel,
      content: <InvitedUsersController />,
      route: 'invited-users',
    },
    ...(isAdmin
      ? [
          {
            label: strings.users.deactivatedUsers.tabLabel,
            content: <DeactivatedUsersController />,
            route: 'deactivated-users',
          },
        ]
      : []),
  ];

  return (
    <div
      className={css`
        padding-bottom: 2.5rem;
        background-color: #fff;
        height: 100%;
        overflow: auto;
      `}
    >
      {inviteUsersOpen && (
        <div
          className={css`
            position: absolute;
            top: 5.5rem;
            right: 7rem;
          `}
        >
          <InviteUsersController onClose={(): void => setInviteUsersOpen(false)} />
        </div>
      )}
      <TabBar queryParamName='usersTab' tabs={tabs}>
        <Button
          className={css`
            padding: 0.5rem 1rem;
          `}
          onClick={(): void => setInviteUsersOpen(true)}
          size='small'
        >
          {strings.users.invite}
        </Button>
      </TabBar>
    </div>
  );
}
