import TeamDetailsController from 'components/app/controllers/TeamDetailsController';
import React from 'react';

/**
 * TeamDetailsPage
 * Team Details Page
 *
 */
export default function TeamDetailsPage(): JSX.Element {
  return <TeamDetailsController />;
}
