import { Data } from '@taraai/types';
import TaskController from 'components/app/controllers/TaskController';
import React from 'react';
import { useParams } from 'react-router-dom';

export default function TaskPage(): JSX.Element {
  const { taskId } = useParams<{ taskId: Data.Id.TaskId }>();

  return <TaskController key={taskId} taskId={taskId} />;
}
