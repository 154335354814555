import { Data, UI } from '@taraai/types';
import { isNonEmptyString } from '@taraai/utility';
import { compose } from 'redux';
import { createIndividualSelector, inertQuery, Query, ReduxFirestoreQuery } from 'reduxStore/utils';

export type TaskSummaryRecord = { summaries: Record<Data.Id.TaskId, UI.TaskSummary>; type: 'tasks' };

export function getTaskSummary(orgId: Data.Id.OrganizationId, archived = false): Query<UI.TaskSummary> {
  if (!isNonEmptyString(orgId)) {
    return inertQuery();
  }

  const storeAs = `orgs/${orgId}/summaries/tasks`;

  const query: ReduxFirestoreQuery = {
    collection: `orgs/${orgId}/summaries`,
    doc: 'tasks',
    storeAs,
  };

  return {
    query: [query],
    selector: compose(
      (data: TaskSummaryRecord) => filterArchivedTasks(data, archived),
      createIndividualSelector(query),
    ),
  };
}

export function filterArchivedTasks(doc: TaskSummaryRecord, archived = true): UI.TaskSummary[] {
  return Object.keys(doc?.summaries || {})
    .filter((id: string) => {
      const data: UI.TaskSummary = doc?.summaries[id];
      return data.archived === archived;
    })
    .map((taskId: string) => {
      return doc?.summaries[taskId];
    });
}
