import { Box, HStack, styled } from '@taraai/design-system';
import { UI } from '@taraai/types';
import React from 'react';

import { Filters } from './Filters';

type RequirementFragment = Pick<UI.UIRequirement, 'id' | 'title'>;
type UserFragment = Pick<UI.UIUser, 'id' | 'name'>;

interface Props {
  dates: string[];
  today: string;
  assignees: UserFragment[];
  requirements: RequirementFragment[];
  handleFiltering: (selectedAssignees: UserFragment[], selectedRequirements: RequirementFragment[]) => void;
}

export function TableHeader({ dates, today, assignees, requirements, handleFiltering }: Props): JSX.Element {
  const gridTemplateColumns = { gridTemplateColumns: `40% repeat(${dates.length}, 1fr)` };

  return (
    <Container style={gridTemplateColumns}>
      <Box space='$8px' spaceLeft='$12px'>
        <HStack space='$16px'>
          <Filters assignees={assignees} handleFiltering={handleFiltering} requirements={requirements} />
        </HStack>
      </Box>
      {dates.map((date) => (
        <DateWrapper key={date} today={date === today}>
          <Day>{date.split(' ')[2]}</Day>
          <br />
          <Date>{date.slice(0, -4)}</Date>
        </DateWrapper>
      ))}
    </Container>
  );
}

const Container = styled('div', { display: 'grid' });

const focusBorder = 'borderWidths.$1px solid colors.$focus';

const DateWrapper = styled(
  'span',
  {
    minHeight: '3rem',
    fontSize: '0.75rem',
    fontWeight: 500,
    textAlign: 'center',
    borderLeft: 'borderWidths.$1px solid colors.$grey3',
  },
  {
    today: {
      true: {
        'backgroundColor': 'rgba(29, 152, 255, 0.04)',
        'color': '$focus',
        'borderLeft': focusBorder,
        'borderRight': focusBorder,
        '> span': {
          color: '$focus',
        },
      },
    },
  },
);

const Day = styled('span', {
  color: '$grey7',
  position: 'relative',
  top: '0.625rem',
});

const Date = styled('span', {
  color: '$grey5',
  position: 'relative',
  top: '0.9375rem',
});
