import { UI } from '@taraai/types';
import AddTaskCollaborators from 'components/app/controllers/AddTaskCollaborators';
import RequirementSelector from 'components/app/controllers/RequirementSelector';
import { SprintSelector } from 'components/app/controllers/Selectors/SprintSelector';
import SetTaskAsignee from 'components/app/controllers/SetTaskAsignee';
import SubtasksController from 'components/app/controllers/SubtasksController';
import TaskStatusDropdown from 'components/app/controllers/TaskStatusDropdown';
import AddSubtask from 'components/app/controllers/views/AddSubtask';
import EffortSelectorLevel from 'components/app/controllers/views/EffortSelectorLevel';
import { PopupHandle } from 'components/core/controllers/Selector';
import { RichEditorHandle } from 'components/editor/RichEditor';
import { css, cx } from 'emotion';
import React, { useCallback, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import ReactSelect from 'react-select';
import { taskModalTestIds } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';
import { onEnterDown } from 'tools/helpers';
import { useToast } from 'tools/utils/hooks/useToast';

type TaskFragment = Omit<
  UI.UITask,
  'updatedBy' | 'deadline' | 'archived' | 'lastUpdatedVia' | 'deleted' | 'tags' | 'author' | 'statusHistory'
>;
export interface TaskRowProps extends React.HTMLProps<HTMLDivElement> {
  status?: boolean;
  effort?: boolean;
  assignee?: boolean;
  collaborators?: boolean;
  sprint?: boolean;
  subtasks?: boolean;
  requirement?: boolean;
  task: TaskFragment;
}
/**
 * TaskRow
 * reusable view that renders different task detail components based on props
 *
 */
export default function TaskRow({
  className,
  status,
  effort,
  assignee,
  collaborators,
  sprint,
  subtasks,
  requirement,
  task,
  ...props
}: TaskRowProps): JSX.Element {
  const [showSubtaskInput, setShowSubtaskInput] = useState(false);

  const addTaskRef = useRef<RichEditorHandle>(null);
  const focusTask = (): void => addTaskRef.current?.focus();

  const effortRef = useRef<HTMLInputElement>(null);
  const focusEffort = (): void => effortRef.current?.focus();

  const statusRef = useRef<ReactSelect>(null);
  const focusStatus = (): void => {
    if (statusRef.current?.state.menuIsOpen === false) {
      statusRef.current.onMenuOpen();
      statusRef.current.select.focus();
    }
  };

  const sprintRef = useRef<PopupHandle>(null);
  const openSprintsDropdown = (): void => {
    sprintRef.current?.openPopup();
  };

  const requirementsRef = useRef<HTMLInputElement>(null);
  const clickRequirements = (): void => requirementsRef.current?.click();

  const assigneeRef = useRef<HTMLInputElement>(null);
  const clickAssignee = (): void => assigneeRef.current?.click();

  const collaboratorsRef = useRef<HTMLInputElement>(null);
  const clickCollaborators = (): void => collaboratorsRef.current?.click();

  const { addToast } = useToast();

  const showUpdateFailed = useCallback(
    () =>
      addToast({
        type: 'error',
        message: strings.task.failedToUpdateTask,
      }),
    [addToast],
  );

  const row = [
    ...(status
      ? [
          {
            handleClick: (): void => focusStatus(),
            shortcutKey: 'S',
            name: strings.task.status,
            action: strings.task.actions.editStatus,
            component: (
              <TaskStatusDropdown
                dataCy={taskModalTestIds.TASK_MODAL_ROW.TASK_MODAL_STATUS}
                statusRef={statusRef}
                task={task}
              />
            ),
          },
        ]
      : []),
    ...(effort
      ? [
          {
            handleClick: (): void => focusEffort(),
            shortcutKey: 'E',
            name: strings.task.effort,
            action: strings.task.actions.editEffort,
            component: (
              <EffortSelectorLevel
                dataCy={taskModalTestIds.TASK_MODAL_ROW.EFFORT_INPUT}
                effortRef={effortRef}
                task={task}
              />
            ),
          },
        ]
      : []),
    ...(assignee
      ? [
          {
            handleClick: (): void => clickAssignee(),
            shortcutKey: 'A',
            name: strings.task.assignee,
            action: strings.task.actions.addAssignee,
            component: (
              <SetTaskAsignee
                Ref={assigneeRef}
                dataCy={taskModalTestIds.TASK_MODAL_ROW.ASSIGNEE_DROPDOWN}
                onError={showUpdateFailed}
                task={task}
              />
            ),
          },
        ]
      : []),
    ...(collaborators
      ? [
          {
            handleClick: (): void => clickCollaborators(),
            shortcutKey: 'C',
            name: strings.task.collaborator,
            action: strings.task.actions.addCollaborator,
            component: (
              <AddTaskCollaborators
                Ref={collaboratorsRef}
                dataCy={taskModalTestIds.TASK_MODAL_ROW.COLLABORATOR_DROPDOWN}
                onError={showUpdateFailed}
                task={task}
              />
            ),
          },
        ]
      : []),
    ...(sprint
      ? [
          {
            handleClick: (): void => openSprintsDropdown(),
            shortcutKey: 'P',
            name: strings.task.sprint,
            action: strings.task.actions.selectSprint,
            component: (
              <SprintSelector ref={sprintRef} dataCy={taskModalTestIds.TASK_MODAL_ROW.SPRINT_DROPDOWN} task={task} />
            ),
          },
        ]
      : []),
    ...(subtasks
      ? [
          {
            handleClick: (): void => {
              setShowSubtaskInput(true);
              focusTask();
            },
            shortcutKey: 'T',
            name: strings.task.subtasks,
            action: strings.task.actions.addSubtask,
            component: <AddSubtask focusTask={focusTask} setShowSubtaskInput={setShowSubtaskInput} />,
            secondComponent: (
              <SubtasksController showSubtaskInput={showSubtaskInput} task={task} taskRef={addTaskRef} />
            ),
          },
        ]
      : []),
    ...(requirement
      ? [
          {
            handleClick: (): void => clickRequirements(),
            shortcutKey: 'R',
            name: strings.task.requirement,
            action: strings.task.actions.selectRequirement,
            component: (
              <RequirementSelector
                dataCy={taskModalTestIds.TASK_MODAL_ROW.REQUIREMENT_DROPDOWN}
                requirementsRef={requirementsRef}
                task={task}
              />
            ),
          },
        ]
      : []),
  ][0];

  const handleRowClick = useCallback(() => {
    row.handleClick();
  }, [row]);

  const handleKeyDown = useCallback(
    (event) => {
      event.preventDefault();
      row.handleClick();
    },
    [row],
  );

  useHotkeys(
    row?.shortcutKey,
    (event) => {
      handleKeyDown(event);
    },
    {},
    [row],
  );

  return (
    <div
      className={cx(
        css`
          padding: 1.25rem 1.5rem;
          border-top: solid 0.0625rem #dee3ec;
          border-bottom: solid 1px transparent;
          :hover {
            background-color: #fbfbfd;
            cursor: pointer;
          }
          :focus-within {
            outline: none;
            border-top: solid 1px #1d98ff;
            border-bottom: solid 1px #1d98ff;
            :last-child {
              border-bottom-left-radius: 0.375rem;
              border-bottom-right-radius: 0.375rem;
            }
            p {
              color: #1d98ff;
            }
          }
        `,
        className,
      )}
      onClick={handleRowClick}
      onKeyDown={onEnterDown(handleRowClick)}
      role='button'
      tabIndex={0}
      {...props}
    >
      <div
        className={css`
          max-height: 1.625rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
        data-cy={taskModalTestIds.TASK_MODAL_ROW.TASK_MODAL_WHOLE_ROW}
      >
        <div
          className={css`
            display: flex;
          `}
          data-cy={taskModalTestIds.TASK_MODAL_ROW.TASK_MODAL_SHORTCUT_CELL}
        >
          <p
            className={css`
              color: #607292;
              font-size: 0.75rem;
              font-weight: 600;
              text-transform: uppercase;
              align-self: center;
              margin-right: 35px;
            `}
            data-cy={taskModalTestIds.TASK_MODAL_ROW.ROW_NAME_TEXT}
          >
            {row && row.name}
          </p>
        </div>
        {row && row.component}
      </div>
      {row && row.secondComponent}
    </div>
  );
}
