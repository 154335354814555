/* istanbul ignore file */
import 'firebase/functions';

import Firebase from 'firebase/compat/app';

import { analytics, auth, firestore, functions, performance } from './firebaseValues';
import { maybeClearFirestorePersistence } from './firestorePersistence';
import { PROD, storeLog } from './loggers';
import { reportError } from './stackdriver';

const { app: firebaseApp, initializeApp } = Firebase;

export interface LoginOptions {
  additionalUserInfo?: Firebase.auth.AdditionalUserInfo | null;
  user?: Firebase.User | null;
}

export interface FirebaseClassConfig {
  appId: string;
  apiKey: string;
  projectId: string;
  storageBucket: string;
  authDomain: string;
  messagingSenderId: string;
  databaseURL: string;
}

export const FirebaseConfig = {
  appId: process.env.REACT_APP_FIREBASE_APP_ID as string,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY as string,
  projectId: process.env.REACT_APP_GCP_PROJECT_ID as string,
  storageBucket: `${process.env.REACT_APP_GCP_PROJECT_ID}.appspot.com`,
  authDomain: `${process.env.REACT_APP_GCP_PROJECT_ID}.firebaseapp.com`,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID as string,
  databaseURL:
    (process.env.REACT_APP_FIREBASE_DATABASE_URL as string) ||
    `https://${process.env.REACT_APP_GCP_PROJECT_ID}.firebaseio.com`,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID as string,
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export async function firebaseInit(config: FirebaseClassConfig = FirebaseConfig): Promise<Firebase.app.App> {
  // Setup the entire Firebase app w/ Config
  if (Firebase.apps.length === 0) {
    initializeApp(config);
    // Sets up Firebase Functions of app
    functions();
  }
  // Sets up Firebase Firestore of app
  if (firestore && firestore()) {
    firestore.setLogLevel((process.env.REACT_APP_FIRESTORE_LOG_LEVEL as Firebase.firestore.LogLevel) ?? 'error');

    const {
      polling = false,
      cache = process.env.REACT_APP_OFFLINE ? -1 : 10_000_000,
      tabs = false,
      persistence = true,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } = (window as any).taraOverrides ?? {};
    let settings: Firebase.firestore.Settings = {
      experimentalForceLongPolling: polling,
      cacheSizeBytes: cache,
    };

    if (process.env.REACT_APP_OFFLINE) {
      settings = {
        ...settings,
        host: 'localhost:8080',
        ssl: false,
      };
      auth().useEmulator('http://localhost:9099/');
      firestore().useEmulator('localhost', 8080);
      functions().useEmulator('localhost', 5001);
    }

    // Sets up settings for Firebase Cache Size of App
    firestore().settings(settings);

    // Setup persistent data store for offline usage
    if (PROD) {
      // FIXME: make sure to ask the user if they're on a trusted device before enabling persistence
      if (persistence) {
        try {
          await maybeClearFirestorePersistence(firestore());
        } catch (error) {
          storeLog('Clearing Persistence Failed');
          reportError(error as Error);
        }

        await firestore()
          .enablePersistence({
            synchronizeTabs: tabs,
          })
          .catch((error: Firebase.FirebaseError) => {
            if (error.code === 'failed-precondition') storeLog('Persistence Failed due to being open in another tab.');
            else if (error.code === 'unimplemented') storeLog('Persistence Failed due to no Browser Support.');
            else {
              storeLog('Persistence Failed due to unknown reasons.');
              reportError(error);
            }
          });
      }
      performance();
      analytics();
    }
  }

  return firebaseApp();
}
