/* eslint-disable sonarjs/cognitive-complexity  */
import { createSelector, OutputParametricSelector, unwrapResult } from '@reduxjs/toolkit';
import { Box, getCustomSize, styled, Text, VStack } from '@taraai/design-system';
import { Data, UI } from '@taraai/types';
import { parseLabelsFromPlainText, unique } from '@taraai/utility';
import { SprintColumnToastController } from 'components/app/controllers/SprintColumnToastController/SprintColumnToastController';
import { SprintColumnTasksView } from 'components/app/controllers/views/SprintColumnTasksView';
import { SprintFilterCard } from 'components/app/controllers/views/SprintFilterCard';
import { DroppableArea, DroppableType } from 'components/app/DragAndDrop';
import { useUpgradeModal } from 'components/app/monetization/UpgradeModalContext';
import { SprintHeader } from 'components/app/SprintPage';
import { extractEffortLevel, extractMentions } from 'components/editor/plugins';
import React, { useCallback, useMemo } from 'react';
import deepEquals from 'react-fast-compare';
import { DefaultRootState, shallowEqual, useSelector } from 'react-redux';
import {
  createTask,
  getSubscriptionType,
  hasFeature,
  QueryAlias,
  RootState,
  selectActiveTeam,
  selectActiveWorkspace,
  selectAssigneeFilter,
  selectProfile,
  selectSprintDocument,
  selectStatusFilter,
  selectTaskBy,
  selectUserDocument,
  useAppDispatch,
  useFilteredTaskCount,
  useIsSearchActive,
  useIsStatusFilteringActive,
} from 'reduxStore';
import { getCompletedSprints } from 'reduxStore/sprints/queries/getCompletedSprints';
import { computeCompletedEffort, computeTotalEffort, lastNSprintsEstimatedEffort } from 'reduxStore/sprints/selectors';
import { strings } from 'resources';
import { useToast } from 'tools';
import { segment } from 'tools/libraries/analytics';

export type SprintColumnType = 'active' | 'complete' | 'upcoming';

type SprintColumnViewProps = {
  acceptDrops: boolean;
  currentSprintId: string;
  onFilterReset: () => void;
  onTaskSelect: (taskId: Data.Id.TaskId) => void;
  sprintId: Data.Id.SprintId;
  sprintName: string;
  sprintEndDateSeconds: number;
  sprintStartDateSeconds: number;
  sprintIsComplete: boolean;
  offsetFromCurrent: number;
  sprintIsPhantom?: boolean;
  taskAlias: QueryAlias;
  sprintRequiresAction: Data.SprintRequiredActions | null;
  sprintCarryOverTaskIds: Data.Id.TaskId[];
  idx: number;
};

type Effort = {
  overload?: number;
  total: number;
  completed: number;
};

const createEffortSelector: () => OutputParametricSelector<
  RootState,
  string,
  Effort,
  (
    state: RootState,
    orgId: Data.Id.OrganizationId,
    sprintId: Data.Id.SprintId,
    isPhantom: boolean,
    tasks: UI.UITask[],
  ) => Effort
> = () =>
  createSelector(
    (state: DefaultRootState) => state as RootState,
    (state: DefaultRootState, orgId: Data.Id.OrganizationId) => orgId,
    (state: DefaultRootState, orgId: Data.Id.OrganizationId, sprintId: Data.Id.SprintId) => sprintId,
    (state: DefaultRootState, orgId: Data.Id.OrganizationId, sprintId: Data.Id.SprintId, isPhantom: boolean) =>
      isPhantom,
    (
      state: DefaultRootState,
      orgId: Data.Id.OrganizationId,
      sprintId: Data.Id.SprintId,
      isPhantom: boolean,
      tasks: UI.UITask[],
    ) => tasks,
    (state, orgId, sprintId, isPhantom, tasks): Effort => {
      if (isPhantom) {
        return { total: 0, completed: 0 };
      }

      const sprint = selectSprintDocument(state, sprintId);
      const completed = computeCompletedEffort(sprint, tasks);
      const total = computeTotalEffort(sprint, tasks);

      let overload;
      if (sprint?.isComplete) {
        overload = Math.max(0, total - completed);
      } else {
        const completedSprints = getCompletedSprints(orgId, sprint?.teamId ?? '').selector(state);
        const estimated = lastNSprintsEstimatedEffort(completedSprints, sprint);
        overload = estimated !== undefined ? Math.max(0, total - estimated) : undefined;
      }

      return { total, completed, overload };
    },
  );

export const SprintColumnView: React.FC<SprintColumnViewProps> = React.memo(function SprintColumnView({
  acceptDrops,
  currentSprintId,
  onFilterReset,
  onTaskSelect,
  sprintId,
  sprintName,
  sprintEndDateSeconds,
  sprintStartDateSeconds,
  sprintIsComplete,
  sprintIsPhantom,
  offsetFromCurrent,
  taskAlias,
  sprintRequiresAction,
  sprintCarryOverTaskIds = [],
  idx,
}: SprintColumnViewProps): JSX.Element {
  const { addToast } = useToast();
  const dispatch = useAppDispatch();

  // @TODO: All the logic should be in the SprintColumnController
  const { showSprintOverloadAlerts = true } = useSelector(selectProfile);

  const orgId = useSelector(selectActiveWorkspace);
  const teamId = useSelector(selectActiveTeam);
  const subscriptionType = useSelector(getSubscriptionType(orgId));
  const premiumGatingEnabled = useSelector(hasFeature('premiumGating', orgId));
  const showUpgradeColumn =
    offsetFromCurrent >= 1 && sprintIsPhantom && subscriptionType === null && premiumGatingEnabled;

  const tasks = useSelector(
    (state) => selectTaskBy(state, (task) => !task?.archived && task?.sprint === sprintId) ?? [],
    shallowEqual,
  );

  const effortSelector = useMemo(createEffortSelector, [sprintId]);
  const { total, overload } = useSelector(
    (state: RootState) => effortSelector(state, orgId, sprintId, sprintIsPhantom || false, tasks),
    shallowEqual,
  );

  const statusFilter = useSelector(selectStatusFilter, shallowEqual);
  const assigneeFilter = useSelector(selectAssigneeFilter, deepEquals);
  const assignee = useSelector((state) =>
    selectUserDocument(state, assigneeFilter && assigneeFilter[0])
      ?.name.split(' ')
      .shift(),
  );

  const areFilteredByStatus = statusFilter?.sprint === sprintId && statusFilter?.status !== undefined;
  const taskCount = useFilteredTaskCount();
  const isSearchActive = useIsSearchActive();
  const isFilteringActive = useIsStatusFilteringActive();
  const isAnyFilteringActive = isSearchActive || isFilteringActive;

  const showFilterCard = !!sprintId && statusFilter?.sprint === sprintId && taskCount > 0 && !isAnyFilteringActive;

  let type: SprintColumnType = 'upcoming';
  if (sprintIsComplete === true) {
    type = 'complete';
  } else if (sprintId === currentSprintId) {
    type = 'active';
  }

  const handleTaskCreation = useCallback(
    async (
      title: string,
      assignedSprintId: Data.Id.SprintId,
      insertAtIndex?: number,
      taskIds?: Data.Id.TaskId[],
    ): Promise<boolean> => {
      const partialTask = extractMentions(extractEffortLevel({ title }));
      const labels = unique(parseLabelsFromPlainText(partialTask.title));
      const taskToCreate = { ...partialTask, sprint: assignedSprintId, labels };

      let success = false;
      dispatch(createTask({ ...taskToCreate, insertAtIndex, taskIds }))
        .then(unwrapResult)
        .then((newTaskId) => {
          segment.track('TaskCreated', { orgID: orgId, taskID: newTaskId, location: 'SprintsColumn' });
          success = true;
        })
        .catch(() => addToast({ type: 'error', message: strings.task.failedToCreateTask }));

      return success;
    },
    [addToast, dispatch, orgId],
  );

  const thereAreCarriedOverTasks = useMemo(() => sprintCarryOverTaskIds.length > 0, [sprintCarryOverTaskIds]);

  const showReconcileMessage = useMemo(
    () =>
      (sprintRequiresAction === Data.SprintRequiredActions.reconcile ||
        sprintRequiresAction === Data.SprintRequiredActions.reconcileSummary) &&
      thereAreCarriedOverTasks,
    [sprintRequiresAction, thereAreCarriedOverTasks],
  );

  const showPreviousSprintCompletedToast = useMemo(
    () =>
      sprintRequiresAction === Data.SprintRequiredActions.reconcileSummary ||
      sprintRequiresAction === Data.SprintRequiredActions.summary,
    [sprintRequiresAction],
  );
  return (
    <VStack data-click='sprint-column' full space='$8px'>
      <SprintContainer>
        <Box borderRadius='$4px' full>
          <VStack full>
            <SprintHeader
              endDateSeconds={sprintEndDateSeconds}
              name={sprintName}
              sprintId={sprintId}
              sprintIsPhantom={sprintIsPhantom}
              startDateSeconds={sprintStartDateSeconds}
              type={type}
            />
            {showPreviousSprintCompletedToast && (
              <SprintColumnToastController
                orgId={orgId}
                sprintId={sprintId}
                sprintRequiresAction={sprintRequiresAction}
                sprintStartDateSeconds={sprintStartDateSeconds}
                teamId={teamId}
              />
            )}
            <ScrollContainer background='$white' borderBottomRadius='$2px' full>
              {showUpgradeColumn ? (
                <DroppableArea
                  description={{
                    id: 'upgradeColumn',
                    type: DroppableType.payWall,
                  }}
                >
                  {() => <UpgradeSprintColumn />}
                </DroppableArea>
              ) : (
                <SprintColumnTasksView
                  acceptDrops={acceptDrops}
                  areFilteredByAssignee={assigneeFilter !== undefined}
                  areFilteredByStatus={areFilteredByStatus}
                  effortOverload={overload}
                  effortTotal={total}
                  idx={idx}
                  onTaskCreate={handleTaskCreation}
                  onTaskSelect={onTaskSelect}
                  showReconcile={showReconcileMessage}
                  showSprintOverloadAlerts={showSprintOverloadAlerts}
                  sprintId={sprintId}
                  sprintIsPhantom={sprintIsPhantom}
                  sprintRequiresAction={sprintRequiresAction}
                  taskAlias={taskAlias}
                  type={type}
                />
              )}
            </ScrollContainer>
          </VStack>
        </Box>
      </SprintContainer>
      {showFilterCard && (
        <SprintFilterCard assignee={assignee} onFilterReset={onFilterReset} statusFilter={statusFilter?.status} />
      )}
    </VStack>
  );
});

const UpgradeSprintColumn = (): JSX.Element => {
  const { handleOpenCloseUpgradeModal } = useUpgradeModal();
  const columnHeight = getCustomSize(202);
  const textWidth = getCustomSize(140);
  return (
    <Box background='$grey1' center height={columnHeight} space='$24px'>
      <VStack space='$8px'>
        <Box center spaceBottom='$2px'>
          {UpgradeIconSVG}
        </Box>
        <Box center width={textWidth}>
          <Text color='$grey6' size='$10px' textAlign='center'>
            {strings.sprints.upgradeColumn.upgradeToPremium}
          </Text>
        </Box>
        <Box.Button center onClick={handleOpenCloseUpgradeModal}>
          <Text color='$focus' size='$10px'>
            {strings.sprints.upgradeColumn.learnMore}
          </Text>
        </Box.Button>
      </VStack>
    </Box>
  );
};

const UpgradeIconSVG = (
  <svg fill='none' height='21' viewBox='0 0 24 21' width='24' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M3.22499 0.999815H20.775C22.2539 0.999815 23.4 2.14594 23.4 3.62482V13.0748C23.4 14.5538 22.2538 15.6998 20.775 15.6998H16.65V17.1248C16.65 18.6038 15.5038 19.7498 14.025 19.7498H9.97499C8.49606 19.7498 7.34999 18.6037 7.34999 17.1248V14.3498H3.22499C1.74606 14.3498 0.599987 13.2037 0.599987 11.7248V3.62482C0.599987 2.14596 1.74604 0.999815 3.22499 0.999815ZM20.775 13.1498C20.8095 13.1498 20.8285 13.1441 20.8359 13.1413C20.8376 13.1406 20.8389 13.1401 20.8398 13.1396C20.8402 13.1387 20.8408 13.1375 20.8415 13.1358C20.8443 13.1284 20.85 13.1093 20.85 13.0748V3.62482C20.85 3.59033 20.8443 3.57127 20.8415 3.56387C20.8408 3.56218 20.8402 3.56091 20.8398 3.56C20.8389 3.55956 20.8376 3.55899 20.8359 3.55834C20.8285 3.55549 20.8095 3.54982 20.775 3.54982H16.65V13.1498H20.775ZM9.89999 17.1248C9.89999 17.1593 9.90566 17.1784 9.90851 17.1858C9.90916 17.1875 9.90973 17.1887 9.91017 17.1896C9.91108 17.1901 9.91235 17.1906 9.91405 17.1913C9.92144 17.1941 9.94051 17.1998 9.97499 17.1998H14.025C14.0595 17.1998 14.0785 17.1941 14.0859 17.1913C14.0876 17.1906 14.0889 17.1901 14.0898 17.1896C14.0902 17.1887 14.0908 17.1875 14.0915 17.1858C14.0943 17.1784 14.1 17.1593 14.1 17.1248V15.0998V3.54982H9.89999V13.7498V17.1248ZM3.14999 11.7248C3.14999 11.7593 3.15567 11.7784 3.15851 11.7858C3.15917 11.7875 3.15973 11.7887 3.16018 11.7896C3.16109 11.7901 3.16235 11.7906 3.16405 11.7913C3.17144 11.7941 3.19051 11.7998 3.22499 11.7998H7.34999V3.54982H3.22499C3.19051 3.54982 3.17144 3.55549 3.16405 3.55834C3.16235 3.55899 3.16109 3.55956 3.16018 3.56C3.15974 3.56091 3.15916 3.56218 3.15851 3.56387C3.15567 3.57127 3.14999 3.59033 3.14999 3.62482V11.7248Z'
      fill='#C8D0DF'
      stroke='white'
      strokeWidth='1.2'
    />
  </svg>
);

const SprintContainer = styled(Box, {
  overflow: 'hidden',
  flexShrink: 1,
});

const ScrollContainer = styled(Box, {
  overflowY: 'overlay',
  flexShrink: 1,
});
