import styled from '@emotion/styled';
import { css, cx, keyframes } from 'emotion';
import React, { useCallback, useEffect, useState } from 'react';
import { sleep } from 'tools';

const ANIMATION_DURATION = 3.2;

export type AnimatedTextCollectionProps = {
  texts: string[];
};

export default function AnimatedTextCollection({ texts }: AnimatedTextCollectionProps): JSX.Element {
  const [visibleTextIndex, setVisibleTextIndex] = useState(0);

  const changeVisibleText = useCallback(
    async (currentIndex: number): Promise<void> => {
      await sleep(ANIMATION_DURATION * 1000);
      const index = getNextIndex(currentIndex, texts.length);
      setVisibleTextIndex(index);
    },
    [texts.length],
  );

  useEffect(() => {
    (async (): Promise<void> => {
      await changeVisibleText(visibleTextIndex);
    })();
  }, [visibleTextIndex, changeVisibleText]);

  const longestText = texts.reduce((left: string, right: string) => {
    return left.length > right.length ? left : right;
  });

  return (
    <Container>
      <InvisiblePlaceholder>{longestText}</InvisiblePlaceholder>
      {texts.map((text, index) => {
        const shouldShow = index === visibleTextIndex;
        const shouldHide = index === getPreviousIndex(visibleTextIndex, texts.length);
        return (
          <p
            key={text}
            className={cx(
              css`
                opacity: 0;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
              `,
              shouldShow ? showingTextStyle : null,
              shouldHide ? hidingTextStyle : null,
            )}
          >
            {text}
          </p>
        );
      })}
    </Container>
  );
}

const slideIn = keyframes`
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) translateY(-100%);
  }
  60% {
    transform: translate(-50%, -50%) translateY(20%);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) translateY(0);
  }
`;

const slideOut = keyframes`{
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) translateY(0);
  }
  60% {
    opacity: 0;
    transform: translate(-50%, -50%) translateY(120%);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) translateY(100%);
  }
}
`;

const showingTextStyle = css`
  opacity: 1;
  animation: ${slideIn} ${ANIMATION_DURATION / 2}s;
`;

const hidingTextStyle = css`
  opacity: 0;
  animation: ${slideOut} ${ANIMATION_DURATION / 2}s;
`;

const getNextIndex = (currentIndex: number, count: number): number => {
  const nextIndex = currentIndex + 1;
  return nextIndex === count ? 0 : nextIndex;
};

const getPreviousIndex = (currentIndex: number, count: number): number => {
  return currentIndex === 0 ? count - 1 : currentIndex - 1;
};

const Container = styled.div`
  font-size: 1.125rem;
  font-weight: 500;
  text-align: center;
  color: #67728b;
  margin: 2.5rem 0;
  position: relative;
`;

const InvisiblePlaceholder = styled.p`
  opacity: 0;
`;
