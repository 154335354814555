/* istanbul ignore file */
import { AnyAction } from 'redux';
import { auth, firebase, performance } from 'tools/libraries';
import { OFFLINE } from 'tools/libraries/loggers';

export enum Marks {
  PageSprint = '@page/sprintPlanning',
  PageSprintDetails = '@page/sprintDetails',
}

const tracer: Record<string, firebase.performance.Trace> = {};

const collectionRegExp =
  /(orgs|requirements|sprints|commits|pull-requests|users|service|branches-tasks|pull-requests-tasks|commits-tasks|comments|revisions|tasks)/;
const perf =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ((window && window.performance && window.performance) as any) || undefined;

const heapSize: Record<string, number> = {};
function snapshotHeap(marker: string, isDone = false): number | null {
  const size = (perf && perf.memory && parseInt(`${perf.memory.usedJSHeapSize / 1024}`, 10)) || undefined;
  if (!size) return null;

  if (!isDone) {
    heapSize[marker] = size;
    return null;
  }

  const diff = size - heapSize[marker];
  heapSize[marker] = 0;
  return diff;
}

export function markPage(marker: Marks, isDone = false): void {
  if (OFFLINE) return;
  try {
    const trace = tracer[marker] || performance().trace(marker);
    if (!isDone) {
      trace.start();
      snapshotHeap(marker);
      tracer[marker] = trace;
      trace.putAttribute('version', process?.env?.REACT_APP_VERSION ?? 'unknown');
      trace.putAttribute('uid', auth().currentUser?.uid ?? 'unknown');
    } else {
      const heap = snapshotHeap(marker, isDone);
      if (heap) {
        trace.incrementMetric('heapChangeKB', heap);
      }
      trace.stop();
      tracer[marker] = performance().trace(marker);
    }
  } catch (err) {
    // ensure timings never impact the user
  }
}

export function markCollection(action: AnyAction, isDone = false): void {
  if (OFFLINE) return;
  try {
    const collectionPath: string = (action.meta && action.meta.collection) || '';
    const collection = (collectionPath || '')
      .split('/')
      .map((id) => (id.match(collectionRegExp) ? id : '**'))
      .join('/');
    const marker = `@rrf/query_${collection}`;
    const trace = tracer[marker] || performance().trace(marker);
    if (!isDone) {
      trace.start();
      snapshotHeap(marker);
      tracer[marker] = trace;
      trace.putAttribute('version', process?.env?.REACT_APP_VERSION ?? 'unknown');
      trace.putAttribute('uid', auth().currentUser?.uid ?? 'unknown');
    } else {
      const docs = Object.keys(action.payload.data || {}).length;
      const heap = snapshotHeap(marker, isDone);
      if (heap) {
        trace.incrementMetric('heapChangeKB', heap);
      }
      trace.incrementMetric('docsCount', docs);
      trace.stop();
      tracer[marker] = performance().trace(marker);
    }
  } catch (err) {
    // ensure timings never impact the user
  }
}
