const INVITATION_TOKEN_KEY = 'invitation_token';

export const setInvitationTokenCookie = (invitationToken: string): void => {
  localStorage.setItem(INVITATION_TOKEN_KEY, invitationToken);
};

export const getInvitationTokenFromCookie = (): string | undefined => {
  return localStorage.getItem(INVITATION_TOKEN_KEY) ?? undefined;
};

export const removeInvitationTokenCookie = (): void => {
  localStorage.removeItem(INVITATION_TOKEN_KEY);
};
