import { unwrapResult } from '@reduxjs/toolkit';
import { SprintSettingsView } from 'components/app/controllers/views/SprintSettingsView';
import { Status } from 'components/app/controllers/views/SprintSettingsView/types';
import { usePathParams } from 'components/Router/paths';
import React from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { selectOrganization, selectTeam, updateSprintSettings, useAppDispatch } from 'reduxStore';
import { strings } from 'resources';
import { useToast } from 'tools';

/**
 * TODO:
 * - remove unused component that is similar to this one
 */
export const SprintSettings = (): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const { orgId, teamId } = usePathParams('sprints');
  const org = useSelector(selectOrganization(orgId));
  const team = useSelector(selectTeam(orgId, teamId));
  const [sprintSettings, setSprintSettings] = React.useState(team?.sprintSettings);
  const [status, setStatus] = React.useState<Status>('clean');

  const { addToast, removeToast } = useToast();

  React.useEffect(() => {
    if (!deepEquals(sprintSettings, team?.sprintSettings)) {
      setStatus('dirty');
    } else {
      setStatus('clean');
    }
  }, [sprintSettings, team?.sprintSettings]);

  const onSave = React.useCallback(() => {
    if (sprintSettings) {
      setStatus('saving');
      addToast({
        message: 'Saving...',
        id: 'sprint-settings-save',
      });
      dispatch(
        updateSprintSettings({
          teamId,
          organizationId: orgId,
          sprintSettings,
        }),
      )
        .then((results) => {
          removeToast('sprint-settings-save');
          addToast({
            addOnTop: true,
            message: strings.sprintsSettings.updateSuccess,
            timeoutMs: 2000,
          });
          setStatus('clean');
          return unwrapResult(results);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
          setStatus('error');
          addToast({
            message: strings.sprintsSettings.updateError,
            type: 'error',
          });
        });
    }
  }, [sprintSettings, addToast, dispatch, teamId, orgId, removeToast]);

  const onReset = React.useCallback(() => {
    setSprintSettings(team?.sprintSettings);
  }, [team?.sprintSettings]);

  if (!sprintSettings) {
    return null;
  }

  return (
    <>
      <SprintSettingsView
        onChange={setSprintSettings}
        sprintSettings={sprintSettings}
        state={{ status, onSave, onReset }}
        testMode={org?.featureFlags.testMode}
      />
    </>
  );
};
