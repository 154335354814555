import { styled } from '@taraai/design-system';
import AuthLayout from 'components/app/layouts/AuthLayout';
import FirebaseLogin from 'components/core/controllers/FirebaseLogin';
import React from 'react';
import { LoginBanner } from 'resources';
import { strings } from 'resources/i18n';

export default function Login(): JSX.Element {
  return (
    <AuthLayout
      bannerAnimation='https://tara.ai/vid/taragif1.mp4'
      bannerImage={LoginBanner}
      bannerSubtitle={strings.authLayout.bannerSubtitle}
      bannerTitle={strings.authLayout.bannerTitle}
      title={strings.formatString(
        strings.authLayout.getStarted,
        <EmphasizedText> {strings.authLayout.free}</EmphasizedText>,
      )}
    >
      <FirebaseLogin />
    </AuthLayout>
  );
}

const EmphasizedText = styled('span', { fontWeight: '700' });
