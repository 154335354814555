import { createAction, createSlice } from '@reduxjs/toolkit';
import { Data } from '@taraai/types';

export const showSprintCompletingNotification = createAction<{
  sprintId: Data.Id.SprintId;
}>('showSprintCompletingNotification');

export const hideSprintCompletingNotification = createAction('hideSprintCompletingNotification');

export type SprintCompletingNotification = { status: 'open'; sprintId: Data.Id.SprintId } | { status: 'closed' };

const slice = createSlice({
  name: 'sprints',
  initialState: {
    sprintCompletingNotification: {
      status: 'closed',
    } as SprintCompletingNotification,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(showSprintCompletingNotification, (state, action) => ({
      ...state,
      sprintCompletingNotification: {
        status: 'open',
        sprintId: action.payload.sprintId,
      },
    }));
    builder.addCase(hideSprintCompletingNotification, (state) => ({
      ...state,
      sprintCompletingNotification: {
        status: 'closed',
      },
    }));
  },
});

export const sprintsReducer = slice.reducer;
