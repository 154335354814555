import { styled } from '@taraai/design-system';
import Icon from 'components/core/controllers/views/Icon';
import { getRouteName, linkTo } from 'components/Router/paths';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectActiveTeam, selectActiveWorkspace } from 'reduxStore';
import { selectOrganization } from 'reduxStore/organization/selectors';
import { globalNavTestIds } from 'resources/cypress/testAttributesValues';
import { useQueryValue } from 'tools';

export function IntegrationsPanel(): JSX.Element | null {
  const orgId = useSelector(selectActiveWorkspace);
  const teamId = useSelector(selectActiveTeam);

  const org = useSelector(selectOrganization(orgId));

  const installedIntegrations = org.integrations.map((stanza) => stanza.service);
  const routeName = getRouteName();
  const isInWorkspace = routeName === 'workspace';
  const inIntegrationsTab = useQueryValue('tab') === 'Integrations';
  const isNavOnIntegrations = isInWorkspace && inIntegrationsTab;

  return (
    <Container to={linkTo('integrations', { orgId, teamId })}>
      {integrations.map(getIconName(installedIntegrations)).map(({ dataCy, icon }) => (
        <IntegrationIndicator key={icon} dataCy={dataCy} name={icon} />
      ))}
      {isNavOnIntegrations && <SelectionIndicator />}
    </Container>
  );
}

const integrations = [
  {
    name: 'slack',
    activeIconName: 'slackSmall',
    inactiveIconName: 'slackSmallInactive',
    dataCy: globalNavTestIds.SLACK,
  },
  {
    name: 'github',
    activeIconName: 'githubSmall',
    inactiveIconName: 'githubSmallInactive',
    dataCy: globalNavTestIds.GITHUB,
  },
  {
    name: 'gitlab',
    activeIconName: 'gitlabSmall',
    inactiveIconName: 'gitlabSmallInactive',
    dataCy: globalNavTestIds.GITLAB,
  },
] as const;

function getIconName(installedIntegrations: string[]) {
  return ({ name, activeIconName, inactiveIconName, dataCy }: typeof integrations[number]) => ({
    name,
    icon: installedIntegrations.includes(name) ? activeIconName : inactiveIconName,
    dataCy,
  });
}

const Container = styled(Link, {
  'outline': 'inherit',
  'borderRadius': '.15rem',
  'width': '5.0625rem',
  'display': 'flex',
  'alignItems': 'center',
  'justifyContent': 'space-between',
  'padding': '0 0.25rem',
  'position': 'relative',
  ':hover': {
    transition: 'all 0.25s ease-in-out',
    background: '#f4f6fb',
  },
});

const IntegrationIndicator = styled(Icon, {
  padding: 0,
});

const SelectionIndicator = styled('div', {
  position: 'absolute',
  display: 'flex',
  backgroundColor: '#4550ce',
  width: '100%',
  bottom: '0',
  left: '0rem',
  height: '0.1875rem',
  minHeight: '0.1875rem',
  transition: 'all 0.25s ease-in-out',
});
