/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-constant-condition */
/* eslint-disable no-loops/no-loops */

import { notUndefined } from '@taraai/utility';
import { EditorCommand } from 'components/editor/editorCommand';
import { PluginFunctions, RichEditorHandlers, RichEditorPlugin } from 'components/editor/types';
import {
  ContentBlock,
  ContentState,
  DraftDragType,
  DraftHandleValue,
  EditorState,
  Modifier,
  SelectionState,
} from 'draft-js';
import flow from 'lodash.flow';
import { useMemo } from 'react';
import { P } from 'uc.micro';

/** Zero-width space for use in various plugins */
export const zwsp = '\u200B';

// Detect if the search starts with '/{number} patter, e.g: /123'
export const taskNumberRegExp = /^\/\d+$/gi;

export function composePlugins(...plugins: Partial<RichEditorPlugin>[]): RichEditorPlugin {
  return {
    decorator: plugins.flatMap((plugin) => plugin.decorator).filter(notUndefined),
    handleReturn: composeHandler(plugins, (plugin) => plugin.handleReturn),
    handleKeyCommand: composeHandler(plugins, (plugin) => plugin.handleKeyCommand),
    handleBeforeInput: composeHandler(plugins, (plugin) => plugin.handleBeforeInput),
    handlePastedText: composeHandler(plugins, (plugin) => plugin.handlePastedText),
    handlePastedFiles: composeHandler(plugins, (plugin) => plugin.handlePastedFiles),
    handleDroppedFiles: composeHandler(plugins, (plugin) => plugin.handleDroppedFiles),
    handleDrop: composeHandler(plugins, (plugin) => plugin.handleDrop),
    keyBindingFn: composeKeyBindingFn(plugins),
    preRead: flow(plugins.map((plugin) => plugin.preRead).filter(notUndefined)),
    read: flow(plugins.map((plugin) => plugin.read).filter(notUndefined)),
    save: flow(
      plugins
        .map((plugin) => plugin.save)
        .filter(notUndefined)
        .reverse(),
    ),
    postSave: flow(
      plugins
        .map((plugin) => plugin.postSave)
        .filter(notUndefined)
        .reverse(),
    ),
  };
}

function composeHandler<Args extends any[]>(
  plugins: Partial<RichEditorPlugin>[],
  getHandler: (plugin: Partial<RichEditorPlugin>) => ((...args: Args) => DraftHandleValue) | undefined,
): (...args: Args) => DraftHandleValue {
  return getFirstPassingResult(
    plugins.map((plugin) => getHandler(plugin)),
    (result) => result === 'handled',
    () => 'not-handled',
  );
}

function composeKeyBindingFn(
  plugins: Partial<RichEditorPlugin>[],
): (event: React.KeyboardEvent) => EditorCommand | null {
  return getFirstPassingResult(
    plugins.map((plugin) => plugin.keyBindingFn),
    Boolean,
    () => null,
  );
}

export const useHandlers = (
  plugin: Partial<RichEditorPlugin>,
  { save, setEditorState, setForceSelectedEntity, setThreadIds }: PluginFunctions,
): RichEditorHandlers =>
  useMemo(() => {
    const pluginFunctions = { save, setEditorState, setForceSelectedEntity, setThreadIds };
    const {
      handleReturn,
      handleKeyCommand,
      handleBeforeInput,
      handlePastedText,
      handlePastedFiles,
      handleDroppedFiles,
      handleDrop,
      keyBindingFn,
    } = plugin;
    return {
      handleReturn:
        handleReturn &&
        ((event: React.KeyboardEvent<Element>, editorState: EditorState) =>
          handleReturn(event, editorState, pluginFunctions)),
      handleKeyCommand:
        handleKeyCommand &&
        ((command: EditorCommand, editorState: EditorState, eventTimeStamp: number) =>
          handleKeyCommand(command, editorState, eventTimeStamp, pluginFunctions)),
      handleBeforeInput:
        handleBeforeInput &&
        ((chars: string, editorState: EditorState, eventTimeStamp: number) =>
          handleBeforeInput(chars, editorState, eventTimeStamp, pluginFunctions)),
      handlePastedText:
        handlePastedText &&
        ((text: string, html: string | undefined, editorState: EditorState) =>
          handlePastedText(text, html, editorState, pluginFunctions)),
      handlePastedFiles: handlePastedFiles && ((files: Array<File>) => handlePastedFiles(files, pluginFunctions)),
      handleDroppedFiles:
        handleDroppedFiles &&
        ((selection: SelectionState, files: Array<File>) => handleDroppedFiles(selection, files, pluginFunctions)),
      handleDrop:
        handleDrop &&
        ((selection: SelectionState, dataTransfer: Record<string, unknown>, isInternal: DraftDragType) =>
          handleDrop(selection, dataTransfer, isInternal, pluginFunctions)),
      keyBindingFn,
    };
  }, [plugin, save, setEditorState, setForceSelectedEntity, setThreadIds]);

/**
 * Like `[].map` combined with `[].find`, but more optimal: functions are called only up to the
 * point where one of them returns a result for which `isPassing` returns `true`. In this case that
 * result is returned. Otherwise the result of `getDefaultResult` is returned.
 */
export function getFirstPassingResult<Args extends any[], Result>(
  funs: (((...args: Args) => Result) | undefined)[],
  isPassing: (result: Result) => boolean,
  getDefaultResult: (...args: Args) => Result,
): (...args: Args) => Result {
  const definedFuns = funs.filter(notUndefined);
  return (...args: Args) => {
    for (const fun of definedFuns) {
      const result = fun(...args);
      if (result && isPassing(result)) {
        return result;
      }
    }
    return getDefaultResult(...args);
  };
}

const whitespaceRegExp = /\s/;

export function hasWhitespace(chars: string): boolean {
  return whitespaceRegExp.test(chars);
}

export function hasPunctuation(chars: string): boolean {
  return P.test(chars);
}

const chameleonPunctuation = /['\u2018\u2019]/;

export function hasChameleonPunctuation(chars: string): boolean {
  return chameleonPunctuation.test(chars);
}

interface TakeWhileFlags {
  bailOnWhitespace?: boolean;
  bailOnPunctuation?: boolean;
  bailOnText?: boolean;
  entityKey?: string | null;
}

/**
 * This function traverses the text until any of the specified flags fails.
 * It returns the last matching offset, the traversed text, and the last seen entity key: if the
 * traverse ended because a different entity was encountered, its key is returned.
 * The direction is determined by the `isBackward` argument.
 *
 * Flags include:
 * - bailOnWhitespace: bails if the encountered character is whitespace
 * - bailOnPunctuation: bails if the encountered character is punctuation
 *   (for the exact rules of chameleon characters see:
 *   https://github.com/facebook/draft-js/blob/master/src/model/modifier/DraftRemovableWord.js)
 * - bailOnText: bails if the encountered character is neither whitespace nor punctuation
 * - entityKey: bails if the encountered entity is different; `null` means a non-entity, and
 *   `undefined` means anything
 *
 * Note: if `isBackward` is `true`, the index just before `anchorOffset` is the start.
 */
export function takeWhile(
  block: ContentBlock,
  anchorOffset: number,
  isBackward = false,
  flags: TakeWhileFlags,
): { entityKey?: string | null; offset: number; text: string } {
  const text = block.getText();
  const characterList = block.getCharacterList();
  const [step, offsetToIndex] = isBackward ? [-1, -1] : [1, 0];
  let index = anchorOffset + offsetToIndex;
  let entityKey: string | null = null;
  while (true) {
    if (
      (isBackward ? index < 0 : index >= text.length) ||
      takeWhileShouldBail(text, index, (entityKey = characterList.get(index).getEntity()), flags)
    ) {
      const offset = index - offsetToIndex;
      return {
        entityKey,
        offset,
        text: isBackward ? text.slice(offset, anchorOffset) : text.slice(anchorOffset, offset),
      };
    }
    index += step;
  }
}

function takeWhileShouldBail(
  text: string,
  index: number,
  currentEntityKey: string | null,
  {
    bailOnWhitespace = false,
    bailOnPunctuation = false,
    bailOnText = false,
    entityKey = currentEntityKey,
  }: TakeWhileFlags,
): boolean {
  return (
    (bailOnWhitespace && hasWhitespace(text[index])) ||
    (bailOnPunctuation && tryBailOnPunctuation(text, index)) ||
    (bailOnText && !hasWhitespace(text[index]) && !hasPunctuation(text[index])) ||
    currentEntityKey !== entityKey
  );
}

function tryBailOnPunctuation(text: string, index: number): boolean {
  return hasChameleonPunctuation(text[index])
    ? index === 0 ||
        index === text.length - 1 ||
        hasWhitespace(text[index - 1]) ||
        hasPunctuation(text[index - 1]) ||
        hasWhitespace(text[index + 1]) ||
        hasPunctuation(text[index + 1])
    : hasPunctuation(text[index]);
}

export interface Part {
  text: string;
  entityKey: string | null;
}

/**
 * This function takes a ContentState and returns it modified based on the `before` and `after`
 * parts: if there are some characters in them that have different entity keys, the difference is
 * applied as if `before` represented the initial `content` and `after` represented the target
 * content.
 *
 * The actual text of `before` and `after` parts is not taken into consideration, only its length.
 * Inline styles are also not handled here. As such, parts from `before` and `after` should have
 * identical text and inline style differences should be handled elsewhere.
 *
 * Note that parts with empty text (`''`) are permitted and their entity keys will be ignored.
 */
export function reconcileEntities(
  content: ContentState,
  startKey: string,
  startOffset: number,
  before: Part[],
  after: Part[],
): ContentState {
  let nextContent = content;
  let currentOffset = startOffset;
  let beforeIndex = 0;
  let beforeOffset = 0;
  let afterIndex = 0;
  let afterOffset = 0;
  while (beforeIndex < before.length && afterIndex < after.length) {
    const commonLength = Math.min(
      before[beforeIndex].text.length - beforeOffset,
      after[afterIndex].text.length - afterOffset,
    );
    if (commonLength && before[beforeIndex].entityKey !== after[afterIndex].entityKey) {
      nextContent = Modifier.applyEntity(
        nextContent,
        SelectionState.createEmpty(startKey).merge({
          anchorOffset: currentOffset,
          focusOffset: currentOffset + commonLength,
        }) as SelectionState,
        after[afterIndex].entityKey,
      );
    }
    currentOffset += commonLength;
    beforeOffset += commonLength;
    afterOffset += commonLength;
    if (beforeOffset === before[beforeIndex].text.length) {
      beforeIndex += 1;
      beforeOffset = 0;
    }
    if (afterOffset === after[afterIndex].text.length) {
      afterIndex += 1;
      afterOffset = 0;
    }
  }
  return nextContent;
}

export function isWithinSelection(inner: SelectionState, outer: SelectionState): boolean {
  const getStartEnd = (sel: SelectionState): [number, number] => [sel.getStartOffset(), sel.getEndOffset()];

  if (
    !inner.getHasFocus() ||
    !outer.getHasFocus() ||
    inner.getAnchorKey() !== outer.getAnchorKey() ||
    inner.getFocusKey() !== outer.getFocusKey()
  ) {
    return false;
  }

  const [innerStart, innerEnd] = getStartEnd(inner);
  const [outerStart, outerEnd] = getStartEnd(outer);
  return innerStart >= outerStart && innerStart <= outerEnd && innerEnd <= outerEnd && innerEnd >= outerStart;
}

export function isASCIIChar(str: string): boolean {
  return /^\S+$/.test(str);
}
