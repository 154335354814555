import { Box, HStack, styled, Text, tokens, VStack } from '@taraai/design-system';
import { Data } from '@taraai/types';
import { SprintRequiredActions } from '@taraai/types/dist/data/sprint';
import Icon from 'components/core/controllers/views/Icon';
import React, { useCallback } from 'react';
import { useAppDispatch } from 'reduxStore';
import { dismissSprintSummary } from 'reduxStore/sprints/actions/reconcile';
import { strings } from 'resources/i18n';
import { useToast } from 'tools';

interface Props {
  sprintId: Data.Id.SprintId;
  sprintName: string;
  totalTasksCount: number;
  sprintRequiresAction: SprintRequiredActions | null;
}

export function SprintColumnToastView({
  sprintId,
  sprintName,
  totalTasksCount,
  sprintRequiresAction,
}: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const { whenError } = useToast();

  const handleDismiss = useCallback(() => {
    dispatch(dismissSprintSummary({ id: sprintId, previousRequiresAction: sprintRequiresAction })).catch(
      whenError((err) => `${strings.sprints.error.updateFailure} ${err.message}`),
    );
  }, [dispatch, sprintId, sprintRequiresAction, whenError]);

  return (
    <VStack>
      <Box background='$grey2' height='$4px' />
      <Box background='$white' space='$1px'>
        <VStack space='$1px'>
          <HStack>
            <SprintHeader color='$dark' size='$12px' textAlign='center' type='main' weight='bold'>
              {strings.formatString(strings.sprints.reconcile.sprintCompleted, {
                sprintName,
              })}
            </SprintHeader>

            <Box.Button onClick={handleDismiss}>
              <Icon name='cross' onClick={handleDismiss} stroke={tokens.colors.$dark} />
            </Box.Button>
          </HStack>
          <SprintHeader size='$12px' textAlign='center' type='main'>
            {strings.formatString(
              totalTasksCount > 0
                ? strings.sprints.reconcile.someTasksCompleted
                : strings.sprints.reconcile.noTasksCompleted,
              {
                tasksCount: totalTasksCount,
              },
            )}
          </SprintHeader>
        </VStack>
      </Box>
    </VStack>
  );
}

const SprintHeader = styled(
  Text,
  {
    display: 'flex',
    order: '0',
    flexGrow: '1',
    borderRadius: '$4px $4px $none $none',
  },
  {
    type: {
      main: {
        padding: '$8px',
      },
      subHeader: { marginLeft: '$24px' },
    },
  },
);
