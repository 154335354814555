import { Box, styled } from '@taraai/design-system';
import React from 'react';
import { useClickOutside } from 'tools';

import { IndividualSprintSettings, SprintSettings } from './Settings';
import { SprintStartEndDates } from './Settings/SprintStartEndDates';
import { SprintSettingsId, SprintSettingsType } from './SprintSettingsContext';

type Props = {
  handleOpenSprintSettings: () => void;
  sprintSettingsType: SprintSettingsType;
  id: SprintSettingsId; // Data.Id.TeamId | Data.Id.SprintId | null;
};

export const SettingsModal = ({ handleOpenSprintSettings, sprintSettingsType, id }: Props): JSX.Element => {
  const onClickOutsideSettings = React.useCallback(() => {
    handleOpenSprintSettings();
  }, [handleOpenSprintSettings]);

  const settingsRef = React.useRef<HTMLDivElement>(null);
  useClickOutside(settingsRef, onClickOutsideSettings);

  return (
    <Modal center>
      <Box background='$white' borderRadius='$8px' space='$4px' width='$fitContent'>
        {sprintSettingsType === 'globalSprintSettings' && (
          <Box ref={settingsRef}>
            <SprintSettings />
          </Box>
        )}
        {sprintSettingsType === 'individualSprintSettings' && id && (
          <Box ref={settingsRef}>
            <IndividualSprintSettings id={id} />
          </Box>
        )}
        {sprintSettingsType === 'individualSprintDates' && id && (
          <Box ref={settingsRef}>
            <SprintStartEndDates id={id} />
          </Box>
        )}
      </Box>
    </Modal>
  );
};

const Modal = styled(Box, {
  background: 'rgba(0,0,0,0.5)',
  height: '100%',
  width: '100%',
  position: 'absolute',
  zIndex: '10',
});
