/* eslint-disable sonarjs/no-duplicate-string */
import { getCustomSize, styled } from '@taraai/design-system';
import Icon from 'components/core/controllers/views/Icon';
import Text from 'components/core/controllers/views/Text';
import { css } from 'emotion';
import React, { ReactNode } from 'react';
import { atomic, IconName } from 'resources';

export interface Tab {
  label: string;
  content?: JSX.Element;
  route: string;
  disabled?: boolean;
  icon?: IconName;
  dataCy?: string;
}

type Props = {
  children?: ReactNode;
  lineMarginX?: string;
  onTabChange: (tab: Tab) => void;
  selectedTab?: Tab;
  tabButtonMargin?: 'none';
  tabs: Tab[];
  tabsSeparatorBottomMargin?: 'none' | 'small';
  variant?: 'globalNav' | 'settings' | 'insights';
};

export function Tabs({
  children,
  lineMarginX,
  onTabChange,
  selectedTab,
  tabButtonMargin,
  tabs,
  tabsSeparatorBottomMargin,
  variant,
}: Props): JSX.Element {
  const TabsWrapper = styled(
    'div',
    {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
    },
    {
      variant: {
        globalNav: {},
        settings: { backgroundColor: '$grey1', padding: '$none 3rem' },
        insights: {},
      },
    },
  );

  const TabButton = styled(
    'button',
    {
      'alignItems': 'center',
      'background': 'none',
      'border': 'none',
      'color': '$grey6',
      'cursor': 'pointer',
      'display': 'flex',
      'listStyle': 'none',
      'margin': '$none $16px',
      'height': '$full',
      'padding': '$16px',
      'position': 'relative',
      'whiteSpace': 'nowrap',
      ':focus-within': { outline: 'none', color: '$darkModePurple' },
    },
    {
      isGlobalNav: {
        true: {
          '--selectedSize': getCustomSize(3),
          '--selectedTransform': 'translateY(-100%)',
          'color': '$dark',
          'margin': '$none $12px',
          'padding': '$none $1px',
        },
      },
      isInsightsPage: {
        true: {
          '--selectedSize': getCustomSize(3),
          '--selectedTransform': 'translateY(-100%)',
          'color': '$secondaryText',
          'margin': '$none $12px',
          'padding': '$none $1px',
          ':focus-within': { outline: 'none', color: '$darkModePurple' },
        },
      },
      selected: {
        true: {
          'color': '$darkModePurple',
          'g': {
            fill: '$darkModePurple',
          },
          '&:after': {
            backgroundColor: '$darkModePurple',
            content: '""',
            height: 'var(--selectedSize, space.$2px)',
            left: 0,
            position: 'absolute',
            top: '100%',
            transform: 'var(--selectedTransform)',
            width: '100%',
            zIndex: 1,
            marginTop: variant === 'insights' ? '16px' : '0',
          },
        },
      },
      disabled: {
        true: { cursor: 'not-allowed', color: '$dark' },
      },
      margin: { none: { margin: 0 } },
    },
  );

  const SpaceBox = styled('div', { flexGrow: 1 });

  const TabsSeparator = styled(
    'div',
    {
      backgroundColor: '$grey2',
      height: variant === 'insights' ? 'space.$1px' : 'space.$2px',
      marginTop: variant === 'insights' ? '17px' : '0',
      marginBottom: variant === 'insights' ? '0' : '$16px',
      position: 'relative',
      opacity: variant === 'insights' ? '0.15' : '1',
    },
    {
      bottomMargin: {
        none: { marginBottom: '$none' },
        small: { marginBottom: '$8px' },
      },
    },
  );

  return (
    <>
      <TabsWrapper variant={variant}>
        {tabs.map((tab) => (
          <TabButton
            key={tab.label}
            data-cy={tab.dataCy}
            disabled={tab.disabled}
            id={tab.label}
            isGlobalNav={variant === 'globalNav'}
            isInsightsPage={variant === 'insights'}
            margin={tabButtonMargin}
            onClick={() => onTabChange(tab)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                onTabChange(tab);
              }
            }}
            selected={selectedTab && tab.label === selectedTab.label}
            tabIndex={0}
            type='button'
          >
            {tab.icon ? (
              <Icon
                // TODO: provide variants in Icon component
                className={css`
                  height: 0.875rem;
                  width: 0.875rem;
                  padding: 0.8125rem;
                `}
                color={atomic.colors.inherit}
                name={tab.icon}
              />
            ) : (
              <Text
                // TODO: provide variants in Text component
                className={css`
                  font-size: 0.875rem;
                `}
                fontWeight='500'
              >
                {tab.label}
              </Text>
            )}
          </TabButton>
        ))}
        <SpaceBox />
        {children}
      </TabsWrapper>
      {variant !== 'globalNav' && (
        <TabsSeparator
          bottomMargin={tabsSeparatorBottomMargin}
          style={{ marginLeft: lineMarginX, marginRight: lineMarginX }}
        />
      )}
    </>
  );
}
