import { Box } from '@taraai/design-system';
import { Data } from '@taraai/types';
import { DefinePanelController } from 'components/app/DefinePanel';
import { DefineSidePanelController } from 'components/app/DefineSidePanel/DefineSidePanel';
import { SidePanelsLayout } from 'components/app/layouts/SidePanelsLayout/SidePanelsLayout';
import React from 'react';
import { useParams } from 'react-router';

export function DefinePage(): JSX.Element {
  const { requirementId, taskId } = useParams<{
    requirementId: Data.Id.RequirementId;
    taskId: Data.Id.TaskId;
  }>();

  return (
    <SidePanelsLayout
      left={<Box />}
      right={<DefineSidePanelController requirementId={requirementId} taskId={taskId} />}
    >
      <DefinePanelController />
    </SidePanelsLayout>
  );
}
