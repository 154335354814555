import MultiString from 'tools/libraries/helpers/MultiString';

export interface Theme {
  color: {
    transparent: string;
    white: MultiString;
    grey: MultiString;
    black: MultiString;
    dark: MultiString;
    primary: MultiString;
    secondary: MultiString;
    success: MultiString;
    error: MultiString;
    background: MultiString;
    text: MultiString;
    input: {
      error: string;
      dark: string;
      placeholder: {
        default: string;
        error: string;
      };
      icon: {
        default: string;
        error: string;
        focus: string;
      };
      border: {
        default: string;
        focus: string;
        error: string;
        success: string;
        dark: string;
        style: {
          regular: string;
        };
      };
    };
  };
  font: {
    menu: {
      size: string;
    };
    input: {
      size: string;
    };
    button: {
      size: {
        tiny: string;
        small: string;
        medium: string;
        large: string;
      };
    };
    dropdown: {
      size: string;
    };
  };
  space: {
    icon: {
      size: {
        tiny: string;
        small: string;
        medium: string;
        large: string;
        huge: string;
      };
      padding: {
        tiny: string;
        small: string;
        medium: string;
        large: string;
        huge: string;
      };
      border: {
        none: string;
        thin: string;
        regular: string;
      };
    };
    avatar: {
      size: {
        tiny: string;
        small: string;
        medium: string;
        large: string;
      };
    };
    input: {
      padding: string;
      height: {
        regular: string;
      };
      icon: {
        padding: string;
      };
      border: {
        regular: string;
        width: {
          regular: string;
        };
        radius: {
          regular: string;
        };
      };
    };
    button: {
      height: string;
      width: string;
      border: {
        none: string;
        thin: string;
        regular: string;
      };
      size: {
        tiny: {
          height: string;
          width: string;
        };
        small: {
          height: string;
          width: string;
        };
        medium: {
          height: string;
          width: string;
        };
        large: {
          height: string;
          width: string;
        };
      };
    };
  };
  text: {
    size: {
      h1: Record<string, unknown>;
    };
  };
  animation: {
    loading: {
      spin: string;
    };
  };
}

const theme: Theme = {
  color: {
    transparent: '--atomic-theme-color-transparent',
    get white(): MultiString {
      const style = new MultiString('--atomic-theme-color-white');
      style.text = '--atomic-theme-color-white-text';
      return style;
    },
    get grey(): MultiString {
      const style = new MultiString('--atomic-theme-color-grey');
      style.text = '--atomic-theme-color-grey-text';
      return style;
    },
    get black(): MultiString {
      const style = new MultiString('--atomic-theme-color-black');
      style.text = '--atomic-theme-color-black-text';
      return style;
    },
    get dark(): MultiString {
      const style = new MultiString('--atomic-theme-color-dark');
      style.text = '--atomic-theme-color-dark-text';
      return style;
    },
    get primary(): MultiString {
      const style = new MultiString('--atomic-theme-color-primary');

      style.text = '--atomic-theme-color-primary-text';
      return style;
    },
    get secondary(): MultiString {
      const style = new MultiString('--atomic-theme-color-secondary');
      style.text = '--atomic-theme-color-secondary-text';
      return style;
    },
    get success(): MultiString {
      const style = new MultiString('--atomic-theme-color-success');
      style.text = '--atomic-theme-color-success-text';
      return style;
    },
    get error(): MultiString {
      const style = new MultiString('--atomic-theme-color-error');
      style.variant = '--atomic-theme-color-error-3';
      style.light = '--atomic-theme-color-error-2';
      style.white = '--atomic-theme-color-error-1';
      style.text = '--atomic-theme-color-error-text';
      return style;
    },
    get background(): MultiString {
      const style = new MultiString('--atomic-theme-color-background');
      style.default = '--atomic-theme-color-background-default';
      style.primary = '--atomic-theme-color-background-primary';
      style.success = '--atomic-theme-color-background-success';
      style.error = '--atomic-theme-color-background-error';
      return style;
    },
    get text(): MultiString {
      const style = new MultiString('--atomic-theme-color-text');
      style.primary = new MultiString('--atomic-theme-color-text-primary');
      style.primary.background = '--atomic-theme-color-text-primary-background';
      style.success = new MultiString('--atomic-theme-color-text-success');
      style.success.background = '--atomic-theme-color-text-success-background';
      style.error = new MultiString('--atomic-theme-color-text-error');
      style.error.background = '--atomic-theme-color-text-error-background';
      style.hover = new MultiString('--atomic-theme-color-text-hover');
      style.hover.hLink = '--atomic-theme-color-text-hover-link';
      style.disabled = '--atomic-theme-color-text-disabled';
      return style;
    },
    input: {
      dark: '--atomic-theme-color-input-dark',
      error: '--atomic-theme-color-input-error',
      border: {
        style: {
          regular: '--atomic-input-border-style-regular',
        },
        default: '--atomic-theme-color-input-border-default',
        error: '--atomic-theme-color-input-border-error',
        focus: '--atomic-theme-color-input-border-focus',
        success: '--atomic-theme-color-input-border-success',
        dark: '--atomic-theme-color-input-border-dark',
      },
      placeholder: {
        default: '--atomic-theme-color-input-placeholder-default',
        error: '--atomic-theme-color-input-placeholder-error',
      },
      icon: {
        default: '--atomic-theme-color-input-icon-default',
        error: '--atomic-theme-color-input-icon-error',
        focus: '--atomic-theme-color-input-icon-focus',
      },
    },
  },
  font: {
    menu: {
      size: '--atomic-theme-font-menu',
    },
    input: {
      size: '--atomic-theme-font-input',
    },
    button: {
      size: {
        tiny: '--atomic-theme-font-button-size-tiny',
        small: '--atomic-theme-font-button-size-small',
        medium: '--atomic-theme-font-button-size-medium',
        large: '--atomic-theme-font-button-size-large',
      },
    },
    dropdown: {
      size: '--atomic-theme-font-dropdown',
    },
  },
  space: {
    icon: {
      size: {
        tiny: '--atomic-theme-space-icon-size-tiny',
        small: '--atomic-theme-space-icon-size-small',
        medium: '--atomic-theme-space-icon-size-medium',
        large: '--atomic-theme-space-icon-size-large',
        huge: '--atomic-theme-space-icon-size-huge',
      },
      padding: {
        tiny: '--atomic-theme-space-icon-padding-tiny',
        small: '--atomic-theme-space-icon-padding-small',
        medium: '--atomic-theme-space-icon-padding-medium',
        large: '--atomic-theme-space-icon-padding-large',
        huge: '--atomic-theme-space-icon-padding-huge',
      },
      border: {
        none: '--atomic-theme-space-icon-border-none',
        thin: '--atomic-theme-space-icon-border-thin',
        regular: '--atomic-theme-space-icon-border-regular',
      },
    },
    avatar: {
      size: {
        tiny: '--atomic-theme-space-avatar-size-tiny',
        small: '--atomic-theme-space-avatar-size-small',
        medium: '--atomic-theme-space-avatar-size-medium',
        large: '--atomic-theme-space-avatar-size-large',
      },
    },
    input: {
      padding: '--atomic-theme-space-input-padding',
      height: {
        regular: '--atomic-theme-space-input-height-regular',
      },
      icon: {
        padding: '--atomic-theme-space-input-icon-padding',
      },
      border: {
        regular: '--atomic-theme-space-input-border-regular',
        width: {
          regular: '--atomic-theme-space-input-border-width-regular',
        },
        radius: {
          regular: '--atomic-theme-space-input-border-radius-regular',
        },
      },
    },
    button: {
      height: '--atomic-theme-space-button-height',
      width: '--atomic-theme-space-button-width',
      border: {
        none: '--atomic-theme-space-button-border-none',
        thin: '--atomic-theme-space-button-border-thin',
        regular: '--atomic-theme-space-button-border-regular',
      },
      size: {
        tiny: {
          height: '--atomic-theme-space-button-size-tiny-height',
          width: '--atomic-theme-space-button-size-tiny-width',
        },
        small: {
          height: '--atomic-theme-space-button-size-small-height',
          width: '--atomic-theme-space-button-size-small-width',
        },
        medium: {
          height: '--atomic-theme-space-button-size-medium-height',
          width: '--atomic-theme-space-button-size-medium-width',
        },
        large: {
          height: '--atomic-theme-space-button-size-large-height',
          width: '--atomic-theme-space-button-size-large-width',
        },
      },
    },
  },
  text: {
    size: {
      // eslint-disable-next-line id-length
      h1: {},
    },
  },
  animation: {
    loading: {
      spin: '--atomic-theme-animation-loading-spin',
    },
  },
};

export default theme;
