type Pending = { pending: true };
type AsyncData<T> = T & { fulfilled: true };
type AsyncError = { error: true; message?: string };

export type Async<T> = AsyncData<T> | AsyncError | Pending;

export const pending: Pending = { pending: true };

export type UnwrappedResult<T> = {
  pending: boolean;
  data: T | null;
  error: AsyncError | null;
};
export function unwrap<T>(async: Async<T>): UnwrappedResult<T> {
  return { pending: isPending(async), error: error(async), data: data(async) };
}

function isPending(result: Async<unknown>): boolean {
  return (result as Pending).pending;
}

function error(result: Async<unknown>): AsyncError | null {
  if ((result as AsyncError).error) {
    return result as AsyncError;
  }
  return null;
}

function data<T>(result: Async<T>): T | null {
  if ((result as AsyncData<T>).fulfilled) {
    return result as AsyncData<T>;
  }
  return null;
}
