import { UserStanza } from '@taraai/types';
import Avatar from 'components/core/controllers/views/Avatar';
import { css } from 'emotion';
import React from 'react';
import { strings } from 'resources/i18n';
import { atomic } from 'resources/theme';

type ReviewersListProps = React.HTMLProps<HTMLDivElement> & {
  reviewers: UserStanza[];
};

const ReviewersList: React.FC<ReviewersListProps> = ({ className, reviewers = [] }: ReviewersListProps) => {
  const additionalReviewers = reviewers.length - 3;
  return (
    <div className={className}>
      <p
        className={css`
          font-size: 0.75rem;
          font-weight: 500;
          color: ${atomic.get(atomic.colors.grey6)};
          text-transform: uppercase;
        `}
      >
        {strings.pullRequestDetails.pendingReview}
      </p>
      <div
        className={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 0.75rem;
          padding-top: 0.5rem;
          color: ${atomic.get(atomic.colors.grey6)};
        `}
      >
        {reviewers.slice(0, 3).map((reviewer) => (
          <Avatar
            key={reviewer.id}
            className={css`
              margin-right: 0.25rem;
              padding: 0;
              flex-shrink: 0;
            `}
            icon='github'
            size='32px'
            user={reviewer}
          />
        ))}
        <p>{additionalReviewers > 0 ? `+${additionalReviewers}` : ''}</p>
      </div>
    </div>
  );
};

export default ReviewersList;
