import { Box, getCustomSize, styled, Text, VStack } from '@taraai/design-system';
import ListView from 'components/core/layouts/ListView';
import { linkTo } from 'components/Router/paths';
import { css } from 'emotion';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { selectActiveTeam, selectActiveWorkspace } from 'reduxStore';
import { strings } from 'resources';

import magicImage from './images/magic.svg';
import { ModalContentType, ModalType } from './UpgradeModalContext';

export const UpgradeModal = ({
  handleOpenCloseModal,
  modalType,
  modalContentType,
}: {
  handleOpenCloseModal: () => void;
  modalType: ModalType;
  modalContentType: ModalContentType;
}): JSX.Element => {
  const history = useHistory();
  const orgId = useSelector(selectActiveWorkspace);
  const teamId = useSelector(selectActiveTeam);
  const customImageHeight = getCustomSize(178);
  const customImageWidth = getCustomSize(404);
  const customPopupWidth = getCustomSize(452);

  const endTrialTextBullets: JSX.Element[] = [
    <>{strings.upgradeModal.endTrial.unlimitedTeams}</>,
    <>{strings.upgradeModal.endTrial.unlimitedSprints}</>,
    <>{strings.upgradeModal.endTrial.unlimitedTasks}</>,
  ];
  const openExtendTrialURL = (): void => {
    window.open('https://calendly.com/tara-ai/extendmytrial', '_blank');
    handleOpenCloseModal();
  };

  const modalContent = {
    startTrial: (
      <VStack alignY='center' space='$12px'>
        <Text color='$dark' size='$24px' weight='bold'>
          {strings.upgradeModal.startTrial.header}
        </Text>
        <Text color='$grey7' size='$16px' style={{ marginBottom: '12px' }} weight='regular'>
          {strings.upgradeModal.startTrial.subHeader}, <strong>{strings.upgradeModal.startTrial.free}</strong>
        </Text>

        <Text color='$grey7' size='$16px' style={{ marginBottom: '12px' }} weight='regular'>
          {strings.upgradeModal.startTrial.premiumAdvantages}
        </Text>

        <Box.Button
          background='$primaryGradient'
          borderRadius='$4px'
          center
          onClick={handleOpenCloseModal}
          spaceVert='$12px'
        >
          <Box spaceBottom='$2px'>
            <Text color='$white' size='$14px' weight='medium'>
              {strings.upgradeModal.startTrial.getStarted}
            </Text>
          </Box>
        </Box.Button>
      </VStack>
    ),
    endTrial: (
      <VStack alignY='center' space='$12px'>
        <Text color='$dark' size='$24px' weight='bold'>
          {strings.upgradeModal.endTrial.endedTrial}
        </Text>

        <Text color='$grey7' size='$16px' style={{ marginBottom: '12px' }} weight='regular'>
          {strings.upgradeModal.endTrial.endedTrialSubTitle}
          <ListView
            className={css`
              list-style: inside;
              overflow: auto;
              position: relative;
              max-height: 100%;
              max-width: 100%;
              padding-left: 12px;
            `}
          >
            {endTrialTextBullets}
          </ListView>
        </Text>

        <Box.Button
          background='$primaryGradient'
          borderRadius='$4px'
          center
          onClick={() => history.push(linkTo('upgrade', { orgId, teamId }))}
          spaceVert='$12px'
        >
          <Box spaceBottom='$2px'>
            <Text color='$white' size='$14px' weight='medium'>
              {strings.upgradeModal.unlockPremium}
            </Text>
          </Box>
        </Box.Button>

        <Box.Button border='$grey5' borderRadius='$4px' center onClick={openExtendTrialURL} spaceVert='$12px'>
          <Text color='$grey7' size='$14px'>
            {strings.upgradeModal.extendTrial}
          </Text>
        </Box.Button>
      </VStack>
    ),
    gatedSprint: (
      <VStack space='$12px'>
        <Box spaceBottom='$12px'>
          <ImgBackground borderRadius='$8px' center height={customImageHeight} maxWidth={customImageWidth}>
            <StyledImg alt='tara logo' src={magicImage} />
          </ImgBackground>
        </Box>
        <Text color='$grey7' size='$16px' textAlign='left'>
          {modalType === 'sprintColumn'
            ? strings.upgradeModal.header.sprintColumn
            : strings.upgradeModal.header.taskLimit}
        </Text>
        <Text color='$dark' size='$24px' weight='medium'>
          {strings.upgradeModal.subHeader}
        </Text>
        <Text color='$grey7' size='$16px'>
          {strings.upgradeModal.body}
        </Text>
        <Box spaceTop='$12px'>
          <VStack space='$12px'>
            <Box.Button
              background='$primaryGradient'
              borderRadius='$4px'
              center
              onClick={() => history.push(linkTo('upgrade', { orgId, teamId }))}
              spaceVert='$12px'
            >
              <Box spaceBottom='$2px'>
                <Text color='$white' size='$14px' weight='medium'>
                  {modalType === 'sprintColumn' ? strings.upgradeModal.unlockPremium : strings.upgradeModal.learnMore}
                </Text>
              </Box>
            </Box.Button>
            <Box.Button border='$grey5' borderRadius='$4px' center onClick={handleOpenCloseModal} spaceVert='$12px'>
              <Text color='$grey7' size='$14px'>
                {modalType === 'sprintColumn' ? strings.upgradeModal.extendTrial : strings.upgradeModal.noTY}
              </Text>
            </Box.Button>
          </VStack>
        </Box>
      </VStack>
    ),
  };

  return (
    <Popup center onClick={handleOpenCloseModal}>
      <Box background='$white' borderRadius='$8px' space='$24px' width={customPopupWidth}>
        {modalContent[modalContentType] || modalContent.gatedSprint}
      </Box>
    </Popup>
  );
};

const Popup = styled(Box, {
  background: 'rgba(0,0,0,0.5)',
  height: '100%',
  width: '100%',
  position: 'absolute',
  zIndex: '10',
});

const StyledImg = styled('img', {
  height: '100%',
});

const ImgBackground = styled(Box, {
  overflow: 'fit-content',
  background:
    'radial-gradient(64.17% 58.19% at 50% 50%, #1A2A8B 0%, #121B50 100%), linear-gradient(0deg, #818181, #818181)',
});
