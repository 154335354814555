import { Data, UI } from '@taraai/types';
import { SprintSelector } from 'components/app/controllers/Selectors/SprintSelector';
import { Section } from 'components/app/TaskDetails/common/Section';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectTaskDocument } from 'reduxStore';
import { strings } from 'resources';

type Props = {
  taskId: Data.Id.TaskId;
};

export function Sprint({ taskId }: Props): JSX.Element {
  const taskSelectedSprintId = useSelector((state) => selectTaskDocument(state, taskId, ['sprint'])?.sprint);
  const task: Pick<UI.UITask, 'id' | 'sprint'> = {
    id: taskId,
    sprint: taskSelectedSprintId || '',
  };

  return (
    <Section right={<SprintSelector task={task} />} spaceVert='$2px' title={strings.taskSidebar.modules.sprint.title} />
  );
}
