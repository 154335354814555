"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSubtaskSummariesPath = exports.getSprintsTaskSummariesPath = exports.getRequirementsTaskSummariesPath = exports.getReposTaskSummariesPath = exports.getImportsTaskSummariesPath = exports.getBacklogTaskSummariesPath = exports.getTaskCommitsSummariesPath = exports.getTaskPullRequestsSummariesPath = exports.getTaskBranchesSummariesPath = exports.getTasksSummariesPath = exports.getRequirementsSummariesPath = exports.getSprintsSummariesPath = exports.getInsightsRepositoryPath = exports.getInsightsCommitsPath = exports.getInsightsPRPath = exports.getInsightsOrgMetricsPath = exports.getInsightsMembersPath = exports.getUserMetricsPath = exports.getUsersPath = exports.getTeamsPath = exports.getRequirementsPath = exports.getSprintsPath = exports.getTaskRevisionsPath = exports.getTaskCommentsPath = exports.getTasksPath = exports.getOrganizationsPath = void 0;
var getOrganizationsPath = function () { return 'orgs'; };
exports.getOrganizationsPath = getOrganizationsPath;
var getTasksPath = function (orgId) { return "orgs/".concat(orgId, "/tasks"); };
exports.getTasksPath = getTasksPath;
var getTaskCommentsPath = function (orgId, taskId) { return "orgs/".concat(orgId, "/tasks/").concat(taskId, "/comments"); };
exports.getTaskCommentsPath = getTaskCommentsPath;
var getTaskRevisionsPath = function (orgId, taskId) {
    return "orgs/".concat(orgId, "/tasks/").concat(taskId, "/revisions");
};
exports.getTaskRevisionsPath = getTaskRevisionsPath;
var getSprintsPath = function (orgId) { return "orgs/".concat(orgId, "/sprints"); };
exports.getSprintsPath = getSprintsPath;
var getRequirementsPath = function (orgId) { return "orgs/".concat(orgId, "/requirements"); };
exports.getRequirementsPath = getRequirementsPath;
var getTeamsPath = function (orgId) { return "orgs/".concat(orgId, "/teams"); };
exports.getTeamsPath = getTeamsPath;
var getUsersPath = function () { return 'users'; };
exports.getUsersPath = getUsersPath;
var getUserMetricsPath = function (userId) { return "users/".concat(userId, "/orgMetrics"); };
exports.getUserMetricsPath = getUserMetricsPath;
var getInsightsMembersPath = function (orgId) { return "orgMetrics/".concat(orgId, "/members"); };
exports.getInsightsMembersPath = getInsightsMembersPath;
var getInsightsOrgMetricsPath = function (orgId) { return "orgMetrics/".concat(orgId); };
exports.getInsightsOrgMetricsPath = getInsightsOrgMetricsPath;
var getInsightsPRPath = function (orgId) { return "orgMetrics/".concat(orgId, "/pullRequests"); };
exports.getInsightsPRPath = getInsightsPRPath;
var getInsightsCommitsPath = function (orgId) { return "orgMetrics/".concat(orgId, "/commits"); };
exports.getInsightsCommitsPath = getInsightsCommitsPath;
var getInsightsRepositoryPath = function (orgId) { return "orgMetrics/".concat(orgId, "/repository"); };
exports.getInsightsRepositoryPath = getInsightsRepositoryPath;
var getSprintsSummariesPath = function (orgId) { return "orgs/".concat(orgId, "/summaries/sprints"); };
exports.getSprintsSummariesPath = getSprintsSummariesPath;
var getRequirementsSummariesPath = function (orgId) { return "orgs/".concat(orgId, "/summaries/requirements"); };
exports.getRequirementsSummariesPath = getRequirementsSummariesPath;
var getTasksSummariesPath = function (orgId) { return "orgs/".concat(orgId, "/summaries/tasks"); };
exports.getTasksSummariesPath = getTasksSummariesPath;
var getTaskBranchesSummariesPath = function (orgId) { return "orgs/".concat(orgId, "/tasks-branches-summaries"); };
exports.getTaskBranchesSummariesPath = getTaskBranchesSummariesPath;
var getTaskPullRequestsSummariesPath = function (orgId) {
    return "orgs/".concat(orgId, "/tasks-pull-requests-summaries");
};
exports.getTaskPullRequestsSummariesPath = getTaskPullRequestsSummariesPath;
var getTaskCommitsSummariesPath = function (orgId) { return "orgs/".concat(orgId, "/tasks-commits-summaries"); };
exports.getTaskCommitsSummariesPath = getTaskCommitsSummariesPath;
var getBacklogTaskSummariesPath = function (orgId) {
    return "orgs/".concat(orgId, "/summaries/childTaskSummaries/backlogChildTaskSummaries");
};
exports.getBacklogTaskSummariesPath = getBacklogTaskSummariesPath;
var getImportsTaskSummariesPath = function (orgId) {
    return "orgs/".concat(orgId, "/summaries/childTaskSummaries/importsChildTaskSummaries");
};
exports.getImportsTaskSummariesPath = getImportsTaskSummariesPath;
var getReposTaskSummariesPath = function (orgId) {
    return "orgs/".concat(orgId, "/summaries/childTaskSummaries/reposChildTaskSummaries");
};
exports.getReposTaskSummariesPath = getReposTaskSummariesPath;
var getRequirementsTaskSummariesPath = function (orgId) {
    return "orgs/".concat(orgId, "/summaries/childTaskSummaries/requirementsChildTaskSummaries");
};
exports.getRequirementsTaskSummariesPath = getRequirementsTaskSummariesPath;
var getSprintsTaskSummariesPath = function (orgId) {
    return "orgs/".concat(orgId, "/summaries/childTaskSummaries/sprintsChildTaskSummaries");
};
exports.getSprintsTaskSummariesPath = getSprintsTaskSummariesPath;
var getSubtaskSummariesPath = function (orgId) {
    return "orgs/".concat(orgId, "/summaries/childTaskSummaries/subtaskChildTaskSummaries");
};
exports.getSubtaskSummariesPath = getSubtaskSummariesPath;
