import { Box, Fluid, getCustomSize, HStack, styled } from '@taraai/design-system';
import React, { ReactNode } from 'react';

interface SidePanelsLayoutProps {
  children: ReactNode;
  left: ReactNode;
  right: ReactNode;
  hideRight?: boolean;
}

export const sidePanelWidth = getCustomSize(297);

export const SidePanelsLayout = ({ children, left, right, hideRight = false }: SidePanelsLayoutProps): JSX.Element => {
  return (
    <HStack full>
      <Box background='$grey2' maxHeight='$full' width={sidePanelWidth}>
        {left}
      </Box>
      <Fluid>{children}</Fluid>
      {!hideRight && (
        <RightPanel background='$grey1' borderLeft='$grey4' maxHeight='$full' width={sidePanelWidth}>
          {right}
        </RightPanel>
      )}
    </HStack>
  );
};

const RightPanel = styled(Box, {
  zIndex: '4',
});
