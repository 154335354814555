import { Functions } from '@taraai/types';
import { createCloudFunctionAction } from 'reduxStore/utils/createCloudFunctionAction';

export const setupIntegration = createCloudFunctionAction<
  Functions.SetupIntegration.Payload,
  Functions.SetupIntegration.Success
>({
  typePrefix: 'SetupIntegration',
  cloudFunctionName: 'setupIntegration',
  responseDecoder: 'SetupIntegrationResponse',
});
