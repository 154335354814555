import { styled } from '@taraai/design-system';
import InviteUsersController from 'components/app/controllers/InviteUsersController';
import Icon from 'components/core/controllers/views/Icon';
import Text from 'components/core/controllers/views/Text';
import React, { useCallback, useState } from 'react';

export const Users = (): JSX.Element => {
  const [inviteUsersOpen, setInviteUsersOpen] = useState(false);
  const openInviteUsersPopup = useCallback(() => setInviteUsersOpen(true), []);
  const closeInviteUsersPopup = useCallback(() => setInviteUsersOpen(false), []);

  return (
    <Wrapper interactive={!inviteUsersOpen} onClick={openInviteUsersPopup}>
      <AddText>Invite</AddText>
      <AddIcon name='inviteButton' />
      {inviteUsersOpen && (
        <InvitePopupPositioner>
          <InviteUsersController onClose={closeInviteUsersPopup} />
        </InvitePopupPositioner>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(
  'div',
  {
    display: 'flex',
    alignItems: 'center',
    outline: 'inherit',
    background: 'none',
    border: 'none',
    position: 'relative',
    width: 'fit-content',
    padding: '0 $16px',
    boxShadow: '$dividerLeft',
    cursor: 'pointer',
    role: 'button',
    name: 'openInvitePopupButton',
    pointerEvents: 'none',
  },
  {
    interactive: {
      true: {
        pointerEvents: 'auto',
      },
    },
  },
);

const AddText = styled(Text, {
  fontWeight: '400',
  fontSize: '14px',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  backgroundImage: 'linear-gradient(135.19deg, #58a6e7 0.34%, #775dfa 100%)',
  backgroundSize: '100%',
  WebkitTextFillColor: 'transparent',
});

const AddIcon = styled(Icon, {
  minHeight: '20px',
  padding: '0rem',
  minWidth: '20px',
  marginLeft: '0.5rem',
});

const InvitePopupPositioner = styled('div', {
  position: 'absolute',
  pointerEvents: 'auto',
  top: '2.5rem',
  right: '0.75rem',
});
