import { useFirestoreConnect } from '@taraai/read-write';
import { Data } from '@taraai/types';
import { getPath, linkTo, routes } from 'components/Router/paths';
import { History, Location } from 'history';
import React, { useLayoutEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  generatePath,
  match,
  matchPath,
  Redirect,
  RouteComponentProps,
  RouteProps,
  useHistory,
  useLocation,
} from 'react-router-dom';
import {
  reduxStore,
  RootStateWithProfile,
  selectActiveOrgIds,
  selectActiveWorkspace,
  selectPreferredTeamId,
  selectUserTeams,
} from 'reduxStore';
import { AppSelectionType, useAppSetSelectionState } from 'reduxStore/appSelection';
import { useQueryValue } from 'tools';
import { firestore } from 'tools/libraries/firebaseValues';
import { getOriginalRouteCookie, removeOriginalRouteCookie } from 'tools/utils/originalRoute';

import AuthenticatedRoute from './AuthenticatedRoute';
import { getIsOnboardedSlice } from './getIsOnboarded';

/**
 * Render route if user is authenticated and onboarded,
 * otherwise show Onboarding.
 */
export const OnboardedRoute: React.FC<RouteProps> = ({ children, render, ...props }) => {
  const childrenProvided = React.Children.count(children) > 0;

  if (childrenProvided && render) {
    // TODO: better wording here
    // eslint-disable-next-line no-console
    console.warn('both children and render provided, will use render()');
  }

  return (
    <AuthenticatedRoute
      {...props}
      render={(renderProps): React.ReactElement => (
        <RouteRenderer render={render} renderProps={renderProps}>
          {children}
        </RouteRenderer>
      )}
    />
  );
};

type Params = {
  orgId?: Data.Id.OrganizationId;
  teamId?: Data.Id.TeamId;
  requirementId?: Data.Id.RequirementId;
  sprintId?: Data.Id.SprintId;
  taskId?: Data.Id.TaskId;
};

type ParamsKey = keyof Params;

const paramOrder: ParamsKey[] = ['orgId', 'teamId', 'requirementId', 'sprintId', 'taskId'];

async function getBestOrgIdReplacement(params: Readonly<Params>): Promise<string> {
  const state = reduxStore.getState() as RootStateWithProfile;
  const preferredOrgId = selectActiveOrgIds(state)[0];
  const { orgId: paramOrgId = preferredOrgId } = params;
  return paramOrgId === '*' ? preferredOrgId : paramOrgId;
}

async function getBestTeamIdReplacement(params: Readonly<Params>): Promise<string> {
  const state = reduxStore.getState() as RootStateWithProfile;
  const orgId = params.orgId as string;
  const paramTeamId = params.teamId;
  const preferredTeamId = selectPreferredTeamId(orgId)(state);
  const teamIds = selectUserTeams(orgId)(state).map((team) => team.id) || [];
  return paramTeamId && teamIds.includes(paramTeamId) ? paramTeamId : preferredTeamId;
}

async function getBestRequirementIdReplacement(params: Readonly<Params>): Promise<string> {
  const orgId = params.orgId as string;
  const { teamId, requirementId: paramRequirementId } = params;
  const requirementsCollection = firestore().collection('orgs').doc(orgId).collection('requirements');
  if (paramRequirementId && paramRequirementId !== '*') {
    const paramRequirementDoc = await requirementsCollection.doc(paramRequirementId).get();
    if (
      paramRequirementDoc.exists &&
      (teamId ? (paramRequirementDoc.data()?.assignedTeamIds ?? []).includes(teamId) : true)
    ) {
      return paramRequirementId;
    }
  }
  let requirementsQuery = requirementsCollection.where('archived', '==', false).where('deleted', '==', false);
  if (teamId) requirementsQuery = requirementsQuery.where('assignedTeamIds', 'array-contains', teamId);
  const requirements = await requirementsQuery.orderBy('createdAt', 'desc').limit(1).get();
  const requirementIds = requirements.docs.map((doc) => doc.id);
  return requirementIds[0];
}

async function getBestSprintIdReplacement(params: Readonly<Params>): Promise<string> {
  const orgId = params.orgId as string;
  const { teamId, sprintId: paramSprintId } = params;
  const sprintsCollection = firestore().collection('orgs').doc(orgId).collection('sprints');
  if (paramSprintId && paramSprintId !== '*') {
    const paramSprintDoc = await sprintsCollection.doc(paramSprintId).get();
    if (paramSprintDoc.exists && (teamId ? paramSprintDoc.data()?.teamId === teamId : true)) {
      return paramSprintId;
    }
  }
  let sprintsQuery = sprintsCollection.where('deleted', '==', false);
  if (teamId) sprintsQuery = sprintsQuery.where('teamId', '==', teamId);
  const sprints = await sprintsQuery.orderBy('isComplete', 'desc').limit(1).get();
  const sprintIds = sprints.docs.map((doc) => doc.id);
  return sprintIds[0];
}

async function getBestTaskIdReplacement(params: Readonly<Params>): Promise<string> {
  const orgId = params.orgId as string;
  const { requirementId, sprintId, taskId: paramTaskId } = params;
  const tasksCollection = firestore().collection('orgs').doc(orgId).collection('tasks');
  if (paramTaskId && paramTaskId !== '*') {
    const paramTaskDoc = await tasksCollection.doc(paramTaskId).get();
    if (
      paramTaskDoc.exists &&
      (requirementId ? paramTaskDoc.data()?._relationships.requirement === requirementId : true) &&
      (sprintId ? paramTaskDoc.data()?.sprint === sprintId : true)
    ) {
      return paramTaskId;
    }
  }
  let tasksQuery = tasksCollection.where('deleted', '==', false);
  if (requirementId) tasksQuery = tasksQuery.where('_relationships.requirement', '==', requirementId);
  if (sprintId) tasksQuery = tasksQuery.where('sprint', '==', sprintId);
  const tasks = await tasksQuery.orderBy('createdAt', 'desc').limit(1).get();
  return tasks.docs[0].id;
}

async function getBestReplacement(params: Readonly<Params>, paramKey: ParamsKey): Promise<string | null> {
  try {
    switch (paramKey) {
      case 'orgId':
        return getBestOrgIdReplacement(params);
      case 'teamId':
        return getBestTeamIdReplacement(params);
      case 'requirementId':
        return getBestRequirementIdReplacement(params);
      case 'sprintId':
        return getBestSprintIdReplacement(params);
      case 'taskId':
        return getBestTaskIdReplacement(params);
    }
  } catch {
    return null;
  }
}

function getCurrentRouteMatch(pathname: string): match<Params> | undefined {
  return keys(routes)
    .map((routeKey) =>
      matchPath(pathname, {
        path: getPath(routeKey),
        exact: true,
        strict: true,
      }),
    )
    .find(isDynamic);
}

async function dynamicReroute(routeMatch: match<Params>, history: History, location: Location<History>): Promise<void> {
  const { path, params } = routeMatch;
  try {
    // Take only actual route params in correct order
    const paramKeysInOrder = paramOrder.filter((param) => param in params);

    const newParams = await paramKeysInOrder.reduce(async (previousPromise: Promise<Params>, paramKey: ParamsKey) => {
      const currentParams = await previousPromise;
      const fallback = currentParams[paramKey];
      currentParams[paramKey] = (await getBestReplacement(currentParams, paramKey)) ?? fallback;
      return currentParams;
    }, Promise.resolve(params));
    const finalPath = generatePath(path, newParams);
    const pathMatchesLocation = matchPath(location.pathname, { path })?.isExact ?? false;
    const pathMatchesFinal = matchPath(finalPath, { path })?.isExact ?? false;
    if (pathMatchesLocation && pathMatchesFinal) {
      history.replace({ ...location, pathname: finalPath });
    }
  } catch {
    history.replace('/');
  }
}

const RouteRenderer: React.FC<{
  render: RouteProps['render'];
  renderProps: RouteComponentProps;
}> = ({ children, render, renderProps }): React.ReactElement | null => {
  const history = useHistory();
  const orgId = useSelector(selectActiveWorkspace);
  const location = useLocation();
  const routeMatch = useMemo(() => getCurrentRouteMatch(location.pathname), [location.pathname]);
  const setAppSelection = useAppSetSelectionState();
  const { requirementId, taskId } = useParams<{
    requirementId: Data.Id.RequirementId;
    taskId: Data.Id.TaskId;
  }>();
  const typeFromQuery = useQueryValue('type');
  const idFromQuery = useQueryValue('active');
  const selectionType = (typeFromQuery || (requirementId ? 'requirement' : 'task')) as AppSelectionType;
  const selectionId = idFromQuery || requirementId || taskId;
  if (selectionType && selectionId) setAppSelection(selectionType, selectionId);

  useLayoutEffect(() => {
    if (routeMatch) {
      dynamicReroute(routeMatch, history, location as Location<History<unknown>>);
    }
  }, [history, location, routeMatch]);
  const isOnboardedSlice = getIsOnboardedSlice(orgId);
  useFirestoreConnect(isOnboardedSlice.query);
  const isOnboarded = useSelector(isOnboardedSlice.selector);
  if (isOnboarded === undefined) {
    return null;
  }
  if (!isOnboarded) {
    return <Redirect to={linkTo('onboarding', { orgId })} />;
  }
  // It may happen that before entering the app the user wanted to enter a different page
  // They got redirected to login and now we want to redirect them back to the original route
  const originalRoute = getOriginalRouteCookie();
  if (originalRoute) {
    removeOriginalRouteCookie();
    return <Redirect to={originalRoute} />;
  }

  return <>{render ? render(renderProps) : children}</>;
};

function keys<Key extends string>(object: Record<Key, unknown>): Key[] {
  return Object.keys(object) as Key[];
}

function isDynamic(matchedPath: match | null | undefined): matchedPath is match {
  if (!matchedPath) {
    return false;
  }
  return Object.values(matchedPath.params).some((param) => param === '*');
}
