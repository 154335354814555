import { useGetOrganizationFields } from 'firestore';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';

// Helper used to get remaining premium trial days for our users.
export const GetRemainingTrialDays = (): number | null => {
  const [orgEndTrialDate, setOrgEndTrialDate] = useState<string>('');

  const orgEndTrialField = useGetOrganizationFields(['trialEndDate']);

  useEffect(() => {
    const endTrialDate = orgEndTrialField?.trialEndDate?.toDate();
    if (endTrialDate !== null && endTrialDate !== undefined) {
      setOrgEndTrialDate(endTrialDate.toLocaleDateString('en-US'));

      if (localStorage.getItem('tara-premium-trial-active') === null) {
        localStorage.setItem('tara-premium-trial-active', 'true');
      }
    }
  }, [orgEndTrialField]);

  const format = 'MM-DD-YYYY';
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const todayDate = new Date();

  const todayDateFormatted = moment.tz(todayDate.toLocaleDateString('en-US'), format, timezone);
  const endTrialDateFormatted = moment.tz(orgEndTrialDate, format, timezone);

  const daysRemaining = endTrialDateFormatted.diff(todayDateFormatted, 'days', true);

  return daysRemaining > 0 ? daysRemaining : null;
};
