import 'firebase/compat/analytics';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/performance';
import 'firebase/compat/remote-config';
import 'firebase/compat/storage';

import firebase from 'firebase/compat/app';

export const { analytics, auth, database, firestore, functions, performance, remoteConfig, storage } = firebase;

export { firebase };
