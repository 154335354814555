"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useHover = void 0;
var react_1 = require("react");
function useHover(ref) {
    var _a = (0, react_1.useState)(false), isHovered = _a[0], setHovered = _a[1];
    (0, react_1.useEffect)(function () {
        document.addEventListener('mouseover', handleMouseOver);
        document.addEventListener('mouseout', handleMouseOut);
        window.addEventListener('mouseover-test', handleMouseOverTest);
        return function () {
            document.removeEventListener('mouseover', handleMouseOver);
            document.removeEventListener('mouseout', handleMouseOut);
            window.removeEventListener('mouseover-test', handleMouseOverTest);
        };
        function handleMouseOver(event) {
            var _a;
            if ((_a = ref.current) === null || _a === void 0 ? void 0 : _a.contains(event.target))
                setHovered(true);
        }
        function handleMouseOut(event) {
            var _a;
            if ((_a = ref.current) === null || _a === void 0 ? void 0 : _a.contains(event.target))
                setHovered(false);
        }
        function handleMouseOverTest() {
            setHovered(true);
        }
    }, [ref]);
    return isHovered;
}
exports.useHover = useHover;
