import { Popup } from '@taraai/design-system';
import { createEntity, getEntityData } from 'components/editor/entities';
import { RichEditorContext } from 'components/editor/RichEditorProvider';
import { DraftDecoratorComponentProps } from 'components/editor/types';
import { ContentState, EditorState, Modifier, SelectionState } from 'draft-js';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { LinkPopup } from './LinkPopup';

/**
 * Link
 * A component for the LINK entity in Draft
 */
export function Link({
  blockKey,
  children,
  contentState,
  end,
  entityKey,
  start,
}: DraftDecoratorComponentProps): JSX.Element {
  const { forceSelectedEntity, setDisableHandlers, setEditorState, setForceSelectedEntity } =
    useContext(RichEditorContext);
  const [isOpen, setIsOpen] = useState(false);
  const { url } = getEntityData('LINK', contentState, entityKey);

  const handleOpen = useCallback(
    (event) => {
      event.preventDefault();
      setDisableHandlers(true);
      setIsOpen(true);
    },
    [setDisableHandlers],
  );

  const handleClose = useCallback(() => {
    setIsOpen(false);
    setDisableHandlers(false);
  }, [setDisableHandlers]);

  const handleRemove = useCallback(() => {
    setEditorState((editorState) =>
      EditorState.push(
        editorState,
        Modifier.applyEntity(editorState.getCurrentContent(), getSelectionState(blockKey, start, end), null).set(
          'selectionAfter',
          getSelectionState(blockKey, end),
        ) as ContentState,
        'apply-entity',
      ),
    );
    handleClose();
  }, [blockKey, end, handleClose, setEditorState, start]);

  const handleEditLink = useCallback(
    (nextUrl: string) => {
      if (nextUrl.trim() === '') return handleRemove();
      setEditorState((editorState) => {
        const { contentWithEntity, key } = createEntity('LINK', { url: nextUrl }, editorState.getCurrentContent());
        return EditorState.push(
          editorState,
          Modifier.applyEntity(contentWithEntity, getSelectionState(blockKey, start, end), key).set(
            'selectionAfter',
            getSelectionState(blockKey, end),
          ) as ContentState,
          'apply-entity',
        );
      });
      handleClose();
    },
    [blockKey, end, handleClose, handleRemove, setEditorState, start],
  );

  useEffect(() => {
    if (entityKey === forceSelectedEntity) {
      setDisableHandlers(true);
      setIsOpen(true);
      setTimeout(() => {
        setForceSelectedEntity(undefined);
      });
    }
  }, [entityKey, forceSelectedEntity, setDisableHandlers, setForceSelectedEntity]);

  return (
    <Popup
      content={
        <Popup.Content>
          <LinkPopup initialUrl={url} onEditLink={handleEditLink} onRemove={handleRemove} />
        </Popup.Content>
      }
      onHide={handleClose}
      show={isOpen}
    >
      <a href={url} onClick={handleOpen} rel='noreferrer noopener' target='_blank'>
        {children}
      </a>
    </Popup>
  );
}

function getSelectionState(blockKey: string, anchorOffset: number, focusOffset = anchorOffset): SelectionState {
  return SelectionState.createEmpty(blockKey).merge({
    anchorOffset,
    focusOffset,
    hasFocus: true,
  }) as SelectionState;
}
