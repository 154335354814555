import { styled } from '@taraai/design-system';
import { Attachment } from '@taraai/types';
import React from 'react';

import { EditorAttachment } from './EditorAttachment';

type Props = {
  /**
   * List of attachments.
   */
  attachments: Attachment[];
  /**
   * Function called after user deletes an attachment.
   */
  onRemove: (attachment: Attachment) => void;
};

/**
 * EditorAttachments - responsible for displaying attached files and let user to delete them.
 */
export function EditorAttachments({ attachments, onRemove }: Props): JSX.Element {
  return (
    <Wrapper>
      {attachments.map((attachment: Attachment) => (
        <EditorAttachment key={attachment.url} attachment={attachment} onRemove={onRemove} />
      ))}
    </Wrapper>
  );
}

const Wrapper = styled('div', {
  'display': 'flex',
  'flexDirection': 'row',
  'flexWrap': 'wrap',
  '& > * + *': {
    marginLeft: '$8px',
  },
});
