import Scaffold from 'components/app/layouts/Scaffold';
import React from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { Redirect, RouteComponentProps, Switch } from 'react-router-dom';
import { selectActiveOrgIds, selectPreferredTeamId } from 'reduxStore';

import { OrgAndTeamIsLoaded } from './OrgAndTeamIsLoaded';
import { linkTo } from './paths';
import { OnboardedRoute } from './routes/OnboardedRoute';

/**
 * Guarantees for all children routes that `orgId` path param:
 * - is present and valid,
 * - current user has access to that org.
 *
 * If any of these requirements are not met it will either do a redirect
 * to the first org that user has access to, or to `/404`.
 *
 * Also wraps children routes in `<Scaffold>` and `<OrgAndTeamIsLoaded>`
 */
const OrganizationTeamContext: React.FC = ({ children }) => {
  const activeOrgIds = useSelector(selectActiveOrgIds, deepEquals);
  const [firstOrgId] = activeOrgIds;

  const preferredTeamId = useSelector(selectPreferredTeamId(firstOrgId));

  const renderWithOrgId = (routeProps: RouteComponentProps<{ orgId: string }>): JSX.Element => {
    if (!activeOrgIds.includes(routeProps.match.params.orgId)) {
      return <Redirect to={linkTo('notFound')} />;
    }

    return (
      <OrgAndTeamIsLoaded>
        <Scaffold>{children}</Scaffold>
      </OrgAndTeamIsLoaded>
    );
  };

  const renderWithoutOrgId = (): JSX.Element => {
    if (!firstOrgId || !preferredTeamId) {
      return <Redirect to={linkTo('onboarding', { orgId: undefined })} />;
    }

    // Or redirect to the first available organization path
    return <Redirect to={linkTo('home', { orgId: firstOrgId, teamId: preferredTeamId })} />;
  };

  return (
    <Switch>
      <OnboardedRoute exact path='/' render={renderWithoutOrgId} />
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      <OnboardedRoute path='/:orgId/:teamId' render={renderWithOrgId as any} />
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      <OnboardedRoute path='/:orgId' render={renderWithOrgId as any} />
    </Switch>
  );
};

export default OrganizationTeamContext;
