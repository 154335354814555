import { getInitials } from '@taraai/utility';
import tinycolor from 'tinycolor2';

export interface Color {
  red: number;
  blue: number;
  green: number;

  alpha: number;
}

export type ColorType = 'hex' | 'rgb' | 'rgb%' | 'rgba' | 'rgba%';

export function rgba(red: number, green: number, blue: number, alpha: number): Color {
  return { red, green, blue, alpha };
}

const TARA_AVATAR_COLORS: Color[] = [
  rgba(38, 64, 78, 1),
  rgba(108, 149, 255, 1),
  rgba(17, 58, 166, 1),
  rgba(68, 112, 230, 1),
  rgba(10, 42, 124, 1),
  rgba(114, 192, 255, 1),
  rgba(31, 133, 215, 1),
  rgba(83, 172, 242, 1),
  rgba(21, 116, 192, 1),
  rgba(153, 97, 241, 1),
  rgba(80, 16, 181, 1),
  rgba(138, 83, 224, 1),
  rgba(58, 5, 141, 1),
  rgba(242, 96, 135, 1),
  rgba(142, 19, 52, 1),
  rgba(228, 65, 109, 1),
  rgba(130, 30, 57, 1),
  rgba(255, 147, 106, 1),
  rgba(201, 65, 13, 1),
  rgba(248, 121, 73, 1),
  rgba(175, 53, 7, 1),
  rgba(252, 207, 63, 1),
  rgba(210, 141, 43, 1),
  rgba(252, 200, 31, 1),
  rgba(197, 132, 28, 1),
  rgba(57, 209, 154, 1),
  rgba(55, 169, 70, 1),
  rgba(21, 158, 107, 1),
  rgba(19, 151, 37, 1),
  rgba(56, 107, 240, 1),
  rgba(52, 158, 243, 1),
  rgba(124, 67, 214, 1),
  rgba(208, 40, 85, 1),
  rgba(253, 108, 54, 1),
  rgba(217, 172, 26, 1),
  rgba(6, 124, 80, 1),
  rgba(37, 84, 207, 1),
  rgba(54, 139, 207, 1),
  rgba(102, 40, 200, 1),
  rgba(168, 24, 63, 1),
  rgba(248, 88, 27, 1),
  rgba(213, 149, 22, 1),
  rgba(82, 196, 97, 1),
];

/**
 * Formats an Color into a CSS color
 */
export function formatColor(color: Color, type: ColorType = 'rgba'): string {
  switch (type) {
    case 'rgb':
      return `rgb(${color.red}, ${color.green}, ${color.blue})`;
    case 'rgb%':
      return `rgb(${color.red / 255}%, ${color.green / 255}%, ${color.blue / 255}%)`;
    case 'rgba%':
      return `rgba(${color.red / 255}%, ${color.green / 255}%, ${color.blue / 255}%, ${color.alpha / 1}%)`;
    case 'hex':
      return [
        '#',
        color.red.toString(16).padStart(2, '0'),
        color.green.toString(16).padStart(2, '0'),
        color.blue.toString(16).padStart(2, '0'),
      ].join('');
    default:
      return `rgba(${color.red}, ${color.green}, ${color.blue}, ${color.alpha})`;
  }
}
/**
 * Deterministically converts a string into color that in Tara's style guide
 */
export function stringToColor(str = ''): Color {
  const initials = getInitials(str).padEnd(2, '*');
  return TARA_AVATAR_COLORS[(initials.charCodeAt(0) + initials.charCodeAt(1)) % TARA_AVATAR_COLORS.length];
}

export function getContrastingColor(color: string): string {
  return tinycolor(color).isLight() ? '#000000' : '#ffffff';
}
