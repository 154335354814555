/* eslint-disable @typescript-eslint/no-explicit-any */
import { UI } from '@taraai/types';
// eslint-disable-next-line import/no-named-default
import { default as fastSort, ISortByObjectSorter } from 'fast-sort';
import strings from 'resources/i18n';

import { compareDates } from './dates';

// provides language-sensitive, case-insensitive, natural sorting comparator
export const collator = new Intl.Collator(strings.getLanguage(), {
  numeric: true,
});

export type CustomSorts = Record<
  'taskTitleAscending' | 'taskTitleDescending' | 'taskStatusByAssignee' | 'createdAtDesc',
  ISortByObjectSorter<any>[]
>;

const customSorts: CustomSorts = {
  taskTitleAscending: [{ asc: 'title', comparer: collator.compare }],
  taskTitleDescending: [{ desc: 'title', comparer: collator.compare }],
  taskStatusByAssignee: [
    { asc: 'status' },
    {
      asc: (assignee: UI.UIUser): string => assignee?.name || '',
      comparer: collator.compare,
    },
  ],
  createdAtDesc: [{ desc: 'createdAt' }],
};

/**
 *
 * @param items
 * Array to be sorted
 *
 * @param prop
 * Either a key that you would like to sort form or a custom sort method
 * in tools/libraries/helpers/sorts.ts
 *
 * @param descending
 * sorts are a ascending by default unless descending is passed in
 *
 * Note: dates use custom sorts using the compareDates method
 */
export const sort = <T>(
  items: T[],
  prop: keyof T | keyof CustomSorts | ISortByObjectSorter<any>[],
  descending = false,
): T[] => {
  if (
    items === undefined ||
    items === null ||
    prop === undefined ||
    prop === null ||
    !Array.isArray(items) ||
    (typeof prop !== 'string' && !Array.isArray(prop))
  ) {
    throw Error('Invalid sort properties');
  }

  // public custom sort by array
  if (Array.isArray(prop)) {
    return fastSort(Array.from(items)).by(prop as any);
  }

  // custom sort if key is in customSorts
  if (customSorts[prop as keyof CustomSorts]) {
    const sortingFunc = customSorts[prop as keyof CustomSorts] as unknown;
    return fastSort(Array.from(items)).by(sortingFunc as ISortByObjectSorter<T>[]);
  }

  // standard sort if date timestamp key
  if (prop === 'createdAt' || prop === 'updatedAt' || prop === 'completedAt' || prop === 'endDate') {
    return descending
      ? fastSort(Array.from(items)).by({
          desc: prop as any,
          comparer: compareDates,
        })
      : fastSort(Array.from(items)).by({
          asc: prop as any,
          comparer: compareDates,
        });
  }

  // standard sort by key
  return descending
    ? fastSort(Array.from(items)).by({
        desc: prop as any,
        comparer: collator.compare,
      })
    : fastSort(Array.from(items)).by({
        asc: prop as any,
        comparer: collator.compare,
      });
};
