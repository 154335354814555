import { css } from 'emotion';
import React from 'react';
import { strings } from 'resources';

export const NotFound: React.FC = () => {
  return (
    <div
      className={css`
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      `}
    >
      <span
        className={css`
          margin-bottom: 1.25rem;
          font-size: 3.75rem;
          font-weight: normal;
        `}
      >
        {strings.emoji.noMouth}
      </span>
      <span
        className={css`
          margin-bottom: 1rem;
          font-weight: normal;
        `}
      >
        {strings.notFound}
      </span>
    </div>
  );
};
