import { UI } from '@taraai/types';
import { labelDisplayTextRegExp } from '@taraai/utility';
import { forEachTextMatch } from 'components/editor/entities';
import { DraftDecorator } from 'components/editor/types';

import { getLabelSelector } from './LabelSelector';

export const getSelectorDecorator = ({
  createLabel,
  getLabels,
  readOnly,
}: {
  createLabel: (title: string) => void;
  getLabels: () => UI.UILabel[];
  readOnly?: boolean;
}): DraftDecorator => ({
  strategy: (block, callback) => {
    forEachTextMatch(labelDisplayTextRegExp, block, callback);
  },
  component: getLabelSelector({ createLabel, getLabels, readOnly }),
});
