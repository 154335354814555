import { Box, fade, HStack, styled, Text } from '@taraai/design-system';
import Logo from 'components/core/controllers/views/Logo';
import React from 'react';
import { Selected } from 'resources/assets/icons/components';

type Props = {
  addOrRemove: (id: string) => void;
  items: {
    id: string;
    name: string;
  }[];
  selected: string[];
  type?: 'teams' | 'users';
};

export function Options({ addOrRemove, items, selected, type }: Props): JSX.Element | null {
  const [searchTerm, setSearchTerm] = React.useState('');
  const filteredItems = items.filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()));
  return (
    <>
      <SearchContainer>
        <StyledInput
          onChange={(element) => setSearchTerm(element.target.value)}
          placeholder={`Search ${type}...`}
          value={searchTerm}
        />
      </SearchContainer>
      {filteredItems.map((item) => (
        <OptionContainer key={item.id} onClick={() => addOrRemove(item.id)}>
          <HStack alignY='center' space='$8px'>
            <Logo name={item.name} shape='square' size='small' />
            <Text color='$white' size='$12px'>
              {item.name}
            </Text>
          </HStack>
          {selected.includes(item.id) && Selected}
        </OptionContainer>
      ))}
    </>
  );
}

const SearchContainer = styled(Box, {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0.5rem',
});

const StyledInput = styled('input', {
  display: 'flex',
  color: '$white',
  width: '100%',
  padding: '$8px $16px',
  border: `1px solid ${fade('$white', '$10%')}`,
  borderRadius: '$4px',
  cursor: 'pointer',
});

const OptionContainer = styled(Box.Button, {
  'display': 'flex',
  'flexDirection': 'row',
  'alignItems': 'center',
  'justifyContent': 'space-between',
  'padding': '0.5rem',
  'cursor': 'pointer',
  '&:hover': {
    backgroundColor: fade('$white', '$10%'),
  },
});
