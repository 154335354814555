import { Box, Hidden } from '@taraai/design-system';
import { Data } from '@taraai/types';
import { SectionWithLoader } from 'components/app/TaskDetails/common/Section';
import { ActionIcon } from 'components/app/TaskDetails/common/styles';
import { SubtaskFragment } from 'components/app/TaskDetails/TaskDetails';
import React, { useCallback, useEffect, useState } from 'react';
import { strings } from 'resources';

import { CreateSubtask } from './CreateSubtask';
import { SubtasksList } from './SubtasksList';

type Props = {
  subtasks: SubtaskFragment[] | undefined;
  taskId: Data.Id.TaskId;
};

export const SubtasksSection = ({ subtasks, taskId }: Props): JSX.Element => {
  const [isCreating, setIsCreating] = useState(false);
  const isEmpty = subtasks?.length === 0;

  const startCreating = useCallback(() => setIsCreating(true), []);
  const stopCreating = useCallback(() => setIsCreating(false), []);

  useEffect(() => {
    setIsCreating(false);
  }, [taskId]);

  return (
    <SectionWithLoader
      content={(loadedSubtasks) => (
        <Hidden hidden={!isCreating && isEmpty}>
          <Box spaceTop='$12px'>
            <SubtasksList subtasks={loadedSubtasks} />
            <CreateSubtask isCreating={isCreating} onBlur={stopCreating} onFocus={startCreating} taskId={taskId} />
          </Box>
        </Hidden>
      )}
      data={subtasks}
      right={
        <Hidden hidden={isCreating || !isEmpty || !subtasks}>
          <ActionIcon name='bluePlus' noPadding onClick={startCreating} />
        </Hidden>
      }
      title={strings.taskSidebar.modules.subtasks}
    />
  );
};
