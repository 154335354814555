import { Box, Fluid, getCustomSize, HStack, styled, Text } from '@taraai/design-system';
import Icon from 'components/core/controllers/views/Icon';
import React from 'react';
import { IconName } from 'resources';

import { FilterColor } from './types';

type Props = {
  color?: FilterColor;
  label: React.ReactNode;
  icon?: IconName;
  effort: number;
  summary: string;
  active: boolean;
};

export function FilterElementLayout({ active, color, label, effort, summary, icon }: Props): JSX.Element {
  return (
    <HStack alignY='center' space='$8px'>
      <Fluid>
        <HStack alignY='center' space='$4px'>
          <Text color={color} size='$12px' weight='medium'>
            {label}
          </Text>
          {icon && <StatusIcon name={icon} noPadding />}
        </HStack>
      </Fluid>
      <Box>
        <Text color={active ? color : '$grey6'} size='$12px' weight='medium'>
          {effort}
        </Text>
      </Box>
      <Summary>
        <Text color={active ? color : '$grey6'} size='$10px'>
          {summary}
        </Text>
      </Summary>
    </HStack>
  );
}

const Summary = styled(Box, {
  width: '93px',
  textAlign: 'right',
  top: '2px',
});

const IconSize = getCustomSize(10);
const StatusIcon = styled(Icon, { width: IconSize, height: IconSize, top: '1px' });
