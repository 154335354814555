import { Box, styled, Text, VStack } from '@taraai/design-system';
import React, { ReactNode } from 'react';

import { useBlockedPulls } from './hooks/useBlockedPulls';

export function Insights(): JSX.Element {
  const { blocked } = useBlockedPulls();

  return (
    <Container>
      <Box background='$white' borderRadius='$8px' shadow='$navbar' space='$12px'>
        <VStack space='$12px'>
          <Text color='$dark' size='$16px' weight='bold'>
            Blockers Report for Last Month
          </Text>
          <Separator />
          <VStack space='$12px'>
            {blocked?.map((pullRequest) => {
              return (
                <Box key={pullRequest.id}>
                  <VStack>
                    <Text color='$focus' size='$16px'>
                      <a href={pullRequest.url} rel='noopener noreferrer' target='_blank'>
                        {pullRequest.title}
                      </a>
                    </Text>
                    <Text size='$12px'>Status: {pullRequest.mergeableState}</Text>
                  </VStack>
                </Box>
              );
            })}
          </VStack>
        </VStack>
      </Box>
    </Container>
  );
}

function Container({ children }: { children?: ReactNode }): JSX.Element {
  return (
    <Box background='$grey2' full spaceHorz='$2px' spaceTop='$24px'>
      {children}
    </Box>
  );
}

const Separator = styled('hr', {
  backgroundColor: '#eaeef5',
  height: '1px',
  border: 'none',
  width: '200%',
  marginLeft: '-50%',
});
