import { Avatar, Box, HStack, styled } from '@taraai/design-system';
import { MemberMetrics } from '@taraai/types/dist/data';
import React from 'react';
import { GitInsightsSelectionType } from 'reduxStore';

type Props = {
  selected: MemberMetrics[];
  type: GitInsightsSelectionType;
};

export function SelectedItems({ selected, type }: Props): JSX.Element {
  if (type === 'users') {
    return renderUserSelection(selected);
  }

  return renderTeamSelection(selected);
}

function renderUserSelection(selected: MemberMetrics[]): JSX.Element {
  if (selected.length === 1) {
    return (
      <HStack align='left' alignY='center' space='$8px'>
        <Box>{selected[0].name}</Box>
        <Avatar title={selected[0].name} url={selected[0].avatar_url} />
      </HStack>
    );
  }
  return (
    <>
      <HStack>
        {selected.map((user) => {
          return (
            <OverlapAvatar key={user.id}>
              <Avatar title={user.name} url={user.avatar_url} />
            </OverlapAvatar>
          );
        })}
      </HStack>
    </>
  );
}

function renderTeamSelection(selected: MemberMetrics[]): JSX.Element {
  return <Box>{selected.length} teams selected</Box>;
}

const OverlapAvatar = styled(Box, {
  position: 'relative',
  marginLeft: '-2px',
});
