import { CharacterMetadata, ContentBlock, ContentState, DraftEntityMutability } from 'draft-js';

interface Entities {
  // Built-in
  IMAGE: { src: string; alt: 'image' };
  LINK: { url: string };
  // Custom
  label: { color?: string; description?: string; id: string; title: string };
  mention: { id: string };
}

export type EntityType = keyof Entities;

type EntityPayload<Type extends EntityType> = Entities[Type];

const entityToMutability: Record<EntityType, DraftEntityMutability> = {
  IMAGE: 'IMMUTABLE',
  LINK: 'MUTABLE',
  label: 'IMMUTABLE',
  mention: 'IMMUTABLE',
};

/**
 * Creates an entity in a type-safe way.
 * Returns both the new content and the entity key as a convenience.
 */
export function createEntity<Type extends EntityType>(
  type: Type,
  data: EntityPayload<Type>,
  content: ContentState,
): { contentWithEntity: ContentState; key: string } {
  const contentWithEntity = content.createEntity(type, entityToMutability[type], data);
  const key = contentWithEntity.getLastCreatedEntityKey();
  return { contentWithEntity, key };
}

/**
 * Returns `true` iff the passed character has the given entity type.
 * Note that it's possible for `char` to be `undefined` when using IME.
 */
export function hasEntityOfType(type: EntityType, content: ContentState, char: CharacterMetadata | undefined): boolean {
  const entityKey = char?.getEntity() ?? null;
  return entityKey !== null && content.getEntity(entityKey).getType() === type;
}

/**
 * Returns `true` iff the passed character has no entity.
 */
export function hasNoEntity(char: CharacterMetadata): boolean {
  return char.getEntity() === null;
}

/**
 * Returns data for the specified entity.
 * Additionally verifies `entityKey` (the type allows `null` and `undefined` for
 * convenience but they are not accepted) and the type of the entity.
 */
export function getEntityData<Type extends EntityType>(
  type: Type,
  content: ContentState,
  entityKey: string | null | undefined,
): EntityPayload<Type> {
  if (!entityKey) {
    throw new Error('Expected entity key to be defined');
  }
  const entity = content.getEntity(entityKey);
  if (entity.getType() !== type) {
    throw new Error(`Entity type mismatch; expected ${type}, got ${entity.getType()}`);
  }
  return content.getEntity(entityKey).getData();
}

/**
 * For each part (text or entity) in a block calls `callback` with the `start`
 * and the `end` offset.
 */
export function forEachRange(block: ContentBlock, callback: (start: number, end: number) => void): void {
  block.findEntityRanges(() => true, callback);
}

/**
 * For each entity of the specified type in a block calls `callback` with the
 * `start` and the `end` offset.
 */
export function forEachEntityRange(
  type: EntityType,
  content: ContentState,
  block: ContentBlock,
  callback: (start: number, end: number) => void,
): void {
  block.findEntityRanges((char) => hasEntityOfType(type, content, char), callback);
}

/**
 * For each text part (no entity) in a block calls `callback` with the `start`
 * and the `end` offset.
 */
export function forEachTextRange(block: ContentBlock, callback: (start: number, end: number) => void): void {
  block.findEntityRanges(hasNoEntity, callback);
}

/**
 * Returns ranges of entities of the specified type in a block.
 */
export function getEntityRanges(type: EntityType, content: ContentState, block: ContentBlock): [number, number][] {
  const ranges: [number, number][] = [];
  forEachEntityRange(type, content, block, (start, end) => {
    ranges.push([start, end]);
  });
  return ranges;
}

/**
 * For each matching text part (no entity) in a block calls `callback` with the
 * `start` and the `end` offset, and also all match groups.
 */
export function forEachTextMatch(
  regexp: RegExp,
  block: ContentBlock,
  callback: (start: number, end: number, ...match: string[]) => void,
): void {
  const text = block.getText();
  return forEachTextRange(block, (start, end) => {
    Array.from(text.slice(start, end).matchAll(regexp)).forEach((match) => {
      callback(start + (match.index ?? 0), start + (match.index ?? 0) + match[0].length, ...match);
    });
  });
}

/**
 * Returns the `start` and the `end` offset, and also all match groups of
 * matching text parts (no entities) in a block.
 */
export function getTextMatches(regexp: RegExp, block: ContentBlock): [number, number, ...string[]][] {
  const ranges: [number, number, ...string[]][] = [];
  forEachTextMatch(regexp, block, (start, end, ...match) => {
    ranges.push([start, end, ...match]);
  });
  return ranges;
}
