/* eslint-disable sonarjs/cognitive-complexity */
import { Box } from '@taraai/design-system';
import { useFirestoreConnect } from '@taraai/read-write';
import { Data } from '@taraai/types';
import SprintColumnsController from 'components/app/controllers/SprintColumnsController';
import { SidePanelsLayout } from 'components/app/layouts/SidePanelsLayout/SidePanelsLayout';
import { RequirementDetailsController } from 'components/app/RequirementDetails/RequirementDetails';
import { TaskDetails } from 'components/app/TaskDetails/TaskDetails';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { compose } from 'redux';
import {
  getSprint,
  getTask,
  selectActiveTeam,
  selectActiveWorkspace,
  selectNextTaskId,
  selectSprintValue,
  selectTaskValue,
  selectTeam,
  useAppDispatch,
} from 'reduxStore';
import { openTaskModal } from 'reduxStore/modals/actions';
import { strings } from 'resources/i18n';
import { useQuerySetState, useQueryValue } from 'tools';
import { segment } from 'tools/libraries/analytics';

/**
 * Sprint Plan page. This page is a part of Tara 3.0 redesign.
 */
export const SprintPage = React.memo(function SprintPage(): JSX.Element {
  const { sprintId } = useParams<{ sprintId: Data.Id.SprintId }>();
  const orgId = useSelector(selectActiveWorkspace);
  const teamId = useSelector(selectActiveTeam);

  const typeFromQuery = useQueryValue('type');
  const idFromQuery = useQueryValue('active');
  const setQuery = useQuerySetState();

  const selectedTaskId = (typeFromQuery === 'task' && idFromQuery) || undefined;

  // gets sprint id from selected task
  const selectedTaskSprintId = useSelector((state) => selectTaskValue(state, selectedTaskId, 'sprint'));

  const setSelected = useCallback((name: string, id: string): void => setQuery({ type: name, active: id }), [setQuery]);

  const currentSprintId =
    useSelector(compose((data) => data?.currentSprintId ?? null, selectTeam(orgId, teamId))) || '';

  const initialSelectedSprintId =
    selectedTaskSprintId || (typeFromQuery === 'sprint' && idFromQuery) || sprintId || currentSprintId;

  const isInitialSprintLoaded = useSelector(
    (state) => selectSprintValue(state, initialSelectedSprintId, 'endDate') !== undefined,
  );

  // if a task is selected wait until the corresponding sprint id is pulled from the store. If no task is selected return true to unblock rendering.
  const isTaskMaybeLoaded = selectedTaskId ? !!selectedTaskSprintId || selectedTaskSprintId === null : true;
  const isEverythingLoaded = isInitialSprintLoaded && isTaskMaybeLoaded;
  const [selectedSprint, setSelectedSprint] = useState<
    { sprintId: Data.Id.SprintId; teamId: Data.Id.TeamId } | undefined
  >(undefined);
  if (isEverythingLoaded && (!selectedSprint || teamId !== selectedSprint.teamId))
    setSelectedSprint({ sprintId: initialSelectedSprintId, teamId });

  const selectedSlice = getSprint(orgId, selectedSprint?.sprintId);
  // get task so that selectedTaskSprintId wont get stuck
  const selectedTaskSlice = getTask(orgId, selectedTaskId);

  useFirestoreConnect(getSprint(orgId, initialSelectedSprintId).query);
  useFirestoreConnect(selectedSlice.query);
  useFirestoreConnect(selectedTaskSlice.query);

  const dispatch = useAppDispatch();

  const handleTaskSelect = useCallback(
    (taskId: Data.Id.TaskId) => {
      segment.track('TaskClicked', {
        orgID: orgId,
        taskID: taskId,
        location: 'SprintPageWorkDrawer',
      });
      dispatch(openTaskModal(taskId));
    },
    [dispatch, orgId],
  );

  const handleSprintSelect = useCallback(
    (newSprintId?: Data.Id.SprintId) => {
      const nextSprintId = newSprintId || currentSprintId;
      setSelected('sprint', nextSprintId);
      setSelectedSprint({ sprintId: nextSprintId, teamId });
    },
    [currentSprintId, setSelected, teamId],
  );

  const nextTaskId = useSelector(selectNextTaskId);
  const navigateAfterTaskDelete = useCallback((): void => {
    const nextTaskQuery = { type: 'task', active: nextTaskId };
    const defaultQuery = { type: null, active: null };
    setQuery(nextTaskId ? nextTaskQuery : defaultQuery);
  }, [setQuery, nextTaskId]);

  useEffect(() => {
    document.title = strings.titles.sprint;
    return () => {
      // Restore the original page title when the component unmounts
      document.title = strings.titles.default;
    };
  }, []);

  return (
    <SidePanelsLayout
      hideRight={[null, 'sprint'].includes(typeFromQuery)}
      left={<Box />}
      right={
        <>
          {typeFromQuery === 'task' && (
            <TaskDetails
              orgId={orgId}
              showSummary
              taskDeleteCallback={navigateAfterTaskDelete}
              taskId={idFromQuery ?? ''}
              teamId={teamId}
            />
          )}
          {typeFromQuery === 'requirement' && (
            <RequirementDetailsController
              orgId={orgId}
              requirementId={idFromQuery ?? ''}
              showOnSprintPage
              teamId={teamId}
            />
          )}
        </>
      }
    >
      {isEverythingLoaded && (
        <>
          <SprintColumnsController
            currentSprintId={currentSprintId}
            onTaskSelect={handleTaskSelect}
            selectedSprintId={selectedSprint?.sprintId || ''}
            setSelectedSprintId={handleSprintSelect}
          />
        </>
      )}
    </SidePanelsLayout>
  );
});
