import { forEachTextMatch } from 'components/editor/entities';
import { DraftDecorator } from 'components/editor/types';

import DraftMentionInserter from './DraftMentionInserter';

export const mentionDropdownDecorator: DraftDecorator = {
  strategy: (block, callback) => {
    forEachTextMatch(/(?:^|\s)(@)(\S*)/g, block, callback);
  },
  component: DraftMentionInserter,
};
