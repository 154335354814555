import { styled } from '@taraai/design-system';
import Button, { ButtonSize } from 'components/core/controllers/views/Button';
import Icon from 'components/core/controllers/views/Icon';
import { TLogoSize } from 'components/core/controllers/views/Logo';
import React from 'react';
import { IconName } from 'resources';
import { workspaceTeamsTestIds } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';

import { IconElement, IconElementProps } from './IconElement';

const LeftToRight = 'left-to-right';
const RightToLeft = 'right-to-left';

export type IconElementData = Pick<IconElementProps, 'tooltip' | 'name' | 'url'> & { id: string };

export interface IconGroupProps {
  elements: IconElementData[];
  maxShown?: number;
  onElementClick?: (elementId: string, event: React.SyntheticEvent) => void;
  onPlusButtonClick?: (event: React.SyntheticEvent) => void;
  onCountButtonClick?: (event: React.SyntheticEvent) => void;
  showPlusButton: boolean;
  elementSize?: TLogoSize;
  hideInvisibleElementsCounter?: boolean;
  plusIconVariant?: 'fullSize';
  plusButtonVariant?: 'gradient';
  plusButtonIcon?: IconName;
  plusButtonIconColor?: string;
  plusButtonSize?: ButtonSize;
  stackMode?: typeof LeftToRight | typeof RightToLeft;
  dataCyElement?: string;
}

/**
 * IconGroup
 * A group of icons
 */
export function IconGroup({
  elements,
  maxShown,
  onElementClick,
  onPlusButtonClick,
  onCountButtonClick,
  showPlusButton,
  elementSize,
  hideInvisibleElementsCounter = false,
  plusButtonIcon = 'circleplus',
  plusButtonIconColor,
  plusButtonVariant,
  plusIconVariant,
  plusButtonSize = 'compact',
  stackMode = LeftToRight,
  dataCyElement,
}: IconGroupProps): JSX.Element {
  const visibleElements = elements.slice(0, maxShown);
  const sortedVisibleElements = stackMode === LeftToRight ? visibleElements : visibleElements.reverse();

  const invisibleElementsCount = (maxShown && elements.length - maxShown) || 0;
  const showInvisibleElementsCounter = !hideInvisibleElementsCounter && invisibleElementsCount > 0;

  return (
    <IconsContainer stackMode={stackMode}>
      {sortedVisibleElements.map((props) => (
        <IconElement
          key={props.id}
          data-cy={dataCyElement}
          onClick={(event): void => onElementClick?.(props.id, event)}
          size={elementSize}
          {...props}
        />
      ))}
      {showPlusButton && (
        <PlusButton
          color='ghost'
          data-cy={workspaceTeamsTestIds.ADD_MEMBER_BUTTON_PLUS}
          elementSize={elementSize === 'small' ? 'small' : undefined}
          name='add button'
          noRadius={plusButtonIcon !== 'circleplus'}
          onClick={onPlusButtonClick}
          size={plusButtonSize}
          stackMode={stackMode}
          title={strings.teams.selector.plusButton}
          variant={plusButtonVariant}
        >
          <PlusIcon
            color={plusButtonIconColor}
            data-cy={workspaceTeamsTestIds.ADD_MEMBER_BUTTON_PLUS}
            name={plusButtonIcon}
            variant={plusIconVariant}
          />
        </PlusButton>
      )}
      {showInvisibleElementsCounter && (
        <CountButton color='ghost' onClick={onCountButtonClick} size='compact'>
          {strings.formatString(strings.avatarPicker.plus, {
            count: invisibleElementsCount,
          })}
        </CountButton>
      )}
    </IconsContainer>
  );
}

export const IconsContainer = styled(
  'div',
  { display: 'inline-flex', alignItems: 'center', paddingLeft: '0.6rem' },
  {
    stackMode: {
      'left-to-right': { flexDirection: 'row' },
      'right-to-left': { flexDirection: 'row-reverse' },
    },
  },
);

const PlusButton = styled(
  Button,
  {
    'height': '2.25rem',
    'width': '2.25rem',
    'padding': 0,
    'marginLeft': '-0.6rem',
    'borderRadius': '$circle',
    'border': 'borderWidths.$2px solid colors.$white',
    'backgroundColor': '#fff',
    'cursor': 'pointer',
    'outline': 'none',
    'transition': 'all 0.2s ease-in-out',
    '&:hover': { transform: 'scale(1.2)', zIndex: 2 },
    '&:focus': { transform: 'scale(1.1)', zIndex: 2 },
  },
  {
    noRadius: { true: { borderRadius: '$none' } },
    variant: {
      gradient: { background: 'linear-gradient(138deg, #58a6e7 0%, #775dfa 100%)' },
    },
    elementSize: {
      small: { height: '1.75rem', width: '1.75rem' },
    },
    stackMode: {
      'left-to-right': { order: 'unset', zIndex: 1 },
      'right-to-left': { order: -1, zIndex: 0 },
    },
  },
);

const PlusIcon = styled(
  Icon,
  { padding: 0 },
  {
    variant: { fullSize: { width: '$full', height: '$full' } },
  },
);

const CountButton = styled(Button, {
  'marginLeft': '0.2rem',
  'fontSize': '0.875rem',
  'border': 'none',
  'outline': 'none',
  'padding': 0,
  'color': '$grey6',
  'backgroundColor': 'inherit',
  'position': 'relative',
  '&:hover': { transform: 'scale(1.2)', cursor: 'pointer' },

  '::before': {
    content: '""',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: '1px',
    backgroundColor: '$grey6',
    transform: 'scaleX(0)',
    opacity: 0,
    transition: 'all 0.2s ease-in-out',
  },

  ':hover::before, :focus::before': {
    opacity: 1,
    transform: 'scaleX(1)',
  },

  ':focus::before': { transform: 'scaleX(1.1)' },
});
