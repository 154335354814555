/* eslint-disable react-hooks/exhaustive-deps */
import { Data } from '@taraai/types';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { selectActiveWorkspace } from 'reduxStore';
import { decode } from 'reduxStore/utils/decoders';

/**
 * returns entire organization document
 *
 * @param organizationId
 * @returns Complete organization document
 */
export function useGetOrganization(organizationId: string): Data.Organization | null {
  const firestore = getFirestore();
  const [organization, setOrganization] = useState<Data.Organization | null>(null);

  useEffect(() => {
    if (!organizationId || !organizationId.length) return;
    const organizationsRef = doc(firestore, 'orgs', organizationId);
    onSnapshot(
      organizationsRef,
      (querySnapshot) => {
        const docData = querySnapshot.data();
        if (docData) {
          // decode document
          const decodedDoc = decode<Data.Organization>({ id: querySnapshot.id, ...docData }, 'UIOrganization');

          // if changes update state
          if (!deepEquals(decodedDoc, organization)) setOrganization(decodedDoc);
        }
      },
      // todo asses error handling strategy
      (error) => {
        throw new Error(error.message);
      },
    );
  }, [firestore, organization, organizationId]);

  return organization;
}

/**
 * returns organization document fields based on fields arguments and organization id
 * This should be used most places in the UI where a organizations data is to be rendered.
 *
 * @param fields
 * @returns requested organization fields of second argument
 */
export function useGetOrganizationFields<K extends keyof Data.Organization>(
  fields: K[],
): Pick<Data.Organization, K> | null {
  const firestore = getFirestore();
  const [organizationFields, setOrganizationFields] = useState<Pick<Data.Organization, K> | null>(null);
  const organizationId = useSelector(selectActiveWorkspace);

  useEffect(() => {
    if (!organizationId || !organizationId.length) return;

    const organizationsRef = doc(firestore, 'orgs', organizationId);

    onSnapshot(
      organizationsRef,
      (querySnapshot) => {
        const docData = querySnapshot.data();
        const docExists = querySnapshot.exists();
        if (docExists) {
          // decode document
          const decodedDoc = decode<Data.Organization>({ id: querySnapshot.id, ...docData }, 'UIOrganization');

          // pick document fields
          const OrganizationPartial = fields.reduce(
            (acc, field) => ({ ...acc, [field]: decodedDoc[field] }),
            {},
          ) as Pick<Data.Organization, K>;

          // if changes update state
          if (!deepEquals(OrganizationPartial, organizationFields)) setOrganizationFields(OrganizationPartial);
        }
      },
      // todo asses error handling strategy
    );
  }, []);

  return organizationFields;
}
