import { createSelector } from '@reduxjs/toolkit';
import { styled } from '@taraai/design-system';
import { Data } from '@taraai/types';
import { AccentedText } from 'components/app/AccentedText';
import React, { ReactNode, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { defaultLabels, searchActions, selectSearchQuery, useAppDispatch } from 'reduxStore';

interface Props {
  children?: ReactNode;
  id: string;
  filterOnClick?: boolean;
}

const labels = defaultLabels as Record<string, Data.DefaultLabel>;

/**
 * ReduxLabelChip
 * A connected redux component that takes in a label id and renders `AccentedText`
 */
export function ReduxLabelChip({ children, filterOnClick = false, id }: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const description = labels[id]?.description;
  const isFilteringId = useSelector(createSelector(selectSearchQuery, (query) => (query?.labels ?? []).includes(id)));
  const onLabelClick = useCallback(
    (event) => {
      event.stopPropagation();
      dispatch(searchActions.search({ source: 'label', labels: isFilteringId ? [] : [id] }));
    },
    [dispatch, id, isFilteringId],
  );
  return (
    <Wrapper isClickable={filterOnClick} onClick={filterOnClick ? onLabelClick : undefined}>
      <AccentedText title={description}>{children ?? labels[id]?.title}</AccentedText>
    </Wrapper>
  );
}

const Wrapper = styled(
  'span',
  {},
  {
    isClickable: {
      true: {
        ':hover': {
          cursor: 'pointer',
        },
      },
    },
  },
);
