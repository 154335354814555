const imageCache = new Map<string, Promise<HTMLImageElement | null>>();

export async function getImage(url?: string): Promise<HTMLImageElement | null> {
  if (!url) return null;
  if (!imageCache.has(url)) {
    imageCache.set(
      url,
      new Promise<HTMLImageElement | null>((resolve) => {
        const image = new Image();
        image.onerror = () => resolve(null);
        image.onabort = () => resolve(null);
        image.onload = () => resolve(image);
        image.src = url;
      }),
    );
  }
  return imageCache.get(url) as Promise<HTMLImageElement | null>;
}
