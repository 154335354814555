import { Box } from '@taraai/design-system';
import { useFirestoreConnect } from '@taraai/read-write';
import { Data } from '@taraai/types';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getRequirement, getTask, selectActiveWorkspace } from 'reduxStore';

import { DefineRequirement } from './DefineRequirement';
import { DefineTask } from './DefineTask';

export function DefinePanelController(): JSX.Element | null {
  const { requirementId, taskId } = useParams<{
    requirementId?: Data.Id.RequirementId;
    taskId?: Data.Id.TaskId;
  }>();
  const orgId = useSelector(selectActiveWorkspace);
  const requirementSlice = getRequirement(orgId, requirementId ?? '');
  const taskSlice = getTask(orgId, taskId ?? '');
  const requirement = useSelector(requirementSlice.selector);
  const task = useSelector(taskSlice.selector);
  useFirestoreConnect([...requirementSlice.query, ...taskSlice.query]);

  return (
    <Box background='$grey2' height='$full'>
      {requirement && <DefineRequirement key={requirement.id} orgId={orgId} requirement={requirement} />}
      {task && <DefineTask key={task.id} orgId={orgId} task={task} />}
    </Box>
  );
}
