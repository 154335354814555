/* istanbul ignore file */
import { createFirestoreInstance, ReactReduxFirebaseProvider } from '@taraai/read-write';
import firebase from 'firebase/compat/app';
import React from 'react';

import { useAppDispatch } from './hooks';

export function ReduxContext({ children }: React.HTMLProps<HTMLElement>): JSX.Element {
  const dispatch = useAppDispatch();
  return (
    <ReactReduxFirebaseProvider
      config={{
        userProfile: 'users',
        enableLogging: false,
        enableClaims: true,
        useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
      }}
      createFirestoreInstance={createFirestoreInstance}
      dispatch={dispatch}
      firebase={firebase}
    >
      {children}
    </ReactReduxFirebaseProvider>
  );
}
