import { styled } from '@taraai/design-system';
import { Data, UI } from '@taraai/types';
import { linkTo } from 'components/Router/paths';
import React from 'react';
import { Link } from 'react-router-dom';
import { strings } from 'resources';
import { workspaceTestIds } from 'resources/cypress/testAttributesValues';

import { CurrentPlan } from './Sections/CurrentPlan';
import { CurrentWorkspace } from './Sections/CurrentWorkspace';
import { WorkspaceSection } from './Sections/WorkspaceSection';

type WorkspaceSwitcherPopupProps = {
  userOrgs: UI.UIOrganization[];
  selectedOrgId: Data.Id.OrganizationId;
  selectedTeamId: Data.Id.TeamId;
  containerRef?: React.RefObject<HTMLDivElement>;
  color?: string | null;
  logoUrl?: string | null;
  orgName: string;
  isPremium: boolean;
};

export function WorkspaceSwitcherPopup({
  userOrgs,
  selectedOrgId,
  selectedTeamId,
  containerRef,
  color,
  logoUrl,
  orgName,
  isPremium,
}: WorkspaceSwitcherPopupProps): JSX.Element {
  return (
    <Container ref={containerRef}>
      <CurrentWorkspace color={color} logoUrl={logoUrl} name={orgName} />
      <CurrentPlan isPremium={isPremium} orgId={selectedOrgId} teamId={selectedTeamId} />
      <WorkspaceSection orgs={userOrgs} selectedOrgId={selectedOrgId} selectedTeamId={selectedTeamId} />
      <OptionsContainer>
        <OptionButton
          data-cy={workspaceTestIds.CREATE_NEW_BUTTON}
          primary
          to={linkTo('teamCreate', { orgId: selectedOrgId, teamId: selectedTeamId }, '?tab=Teams')}
        >
          {strings.workspacePulldown.createNewTeam}
        </OptionButton>
      </OptionsContainer>
    </Container>
  );
}

export const Container = styled('div', {
  zIndex: 7,
  position: 'absolute',
  left: '1rem',
  top: '2.5rem',
  boxShadow: '$popup',
  border: 'borderWidths.$1px solid colors.$grey5',
  borderRadius: '0.1875rem',
  width: '17.875rem',
  backgroundColor: '$white',
  cursor: 'default',
  pointerEvents: 'auto',
  outline: 'none',
});

const OptionsContainer = styled('div', {
  border: 'borderWidths.$1px solid colors.$grey5',
  borderWidth: '0.0625rem 0 0 0',
});

const OptionButton = styled(
  Link,
  {
    'background': 'none',
    'border': 'none',
    'outline': 'inherit',
    'width': '$full',
    'height': '1.75rem',
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'flex-start',
    'paddingLeft': '1rem',
    'fontSize': '0.8125rem',
    ':hover': {
      cursor: 'pointer',
      backgroundColor: '$grey1',
    },
  },
  {
    primary: {
      true: {
        color: '$focus',
      },
    },
  },
);
