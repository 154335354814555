import { Data, UI } from '@taraai/types';
import { MergeableState } from '@taraai/types/src/data/pull-request';
import { strings } from 'resources/i18n';

export function mapStatusesToPRStateString(statuses: Data.PullRequestMergeableState[]): string | null {
  switch (getMergeableState(statuses)) {
    case null:
      return null;
    case 'blocked':
      return strings.sprints.sprintDetails.gitStatus.blocked;
    case 'merged':
      return strings.sprints.sprintDetails.gitStatus.merged;
    default:
      return strings.sprints.sprintDetails.gitStatus.pending;
  }
}

function getMergeableState(statuses: Data.PullRequestMergeableState[]): MergeableState | null {
  if (statuses.some((status) => status === 'blocked')) return 'blocked';
  if (statuses.every((status) => status === 'merged')) return 'merged';
  return 'pending';
}

export const getCommitCountString = (commitCount?: number): string | null => {
  if (typeof commitCount !== 'number') return null;

  const commitCountString =
    commitCount === 1
      ? strings.sprints.sprintDetails.gitStatus.commitCount
      : strings.sprints.sprintDetails.gitStatus.commitsCount;

  return strings.formatString(commitCountString, { commitCount }) as string;
};

export const getGitStatus = (task: UI.UITask): string | null => {
  const hasFailedCIJob = task.gitStatus?.lastJob?.status === 'failed';

  if (hasFailedCIJob) return strings.sprints.sprintDetails.gitStatus.ciFailed;

  // Do not show git status for closed pull requests
  const statuses = Object.values(task.gitStatus?.pullRequests ?? {}).filter((status) => status !== 'closed');

  return statuses.length ? mapStatusesToPRStateString(statuses) : getCommitCountString(task.gitStatus?.commits);
};
