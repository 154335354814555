"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CurrentTool = exports.OrgSize = exports.JobFunction = void 0;
var JobFunction;
(function (JobFunction) {
    JobFunction["ENGINEERING_MANAGER"] = "Engineering Manager";
    JobFunction["ENGINEER"] = "Engineer";
    JobFunction["PRODUCT_MANAGER"] = "Product Manager";
    JobFunction["PROGRAM_MANAGER"] = "Program Manager";
    JobFunction["EXECUTIVE_DIRECTOR"] = "Executive / Director";
    JobFunction["OTHER"] = "Other";
})(JobFunction = exports.JobFunction || (exports.JobFunction = {}));
var OrgSize;
(function (OrgSize) {
    OrgSize["STARTUP"] = "1 - 10";
    OrgSize["SMALL"] = "11 - 50";
    OrgSize["MEDIUM"] = "51 - 500";
    OrgSize["LARGE"] = "501 - 1000";
    OrgSize["ENTERPRISE"] = "1000+";
})(OrgSize = exports.OrgSize || (exports.OrgSize = {}));
var CurrentTool;
(function (CurrentTool) {
    CurrentTool["JIRA"] = "Jira";
    CurrentTool["TRELLO"] = "Trello";
    CurrentTool["ASANA"] = "Asana";
    CurrentTool["SPREADSHEETS_DOCS"] = "Spreadsheets & Docs";
    CurrentTool["OTHER"] = "Other";
})(CurrentTool = exports.CurrentTool || (exports.CurrentTool = {}));
