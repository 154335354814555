// eslint-disable-next-line import/no-unresolved
import { DocumentData, DocumentReference, DocumentSnapshot } from '@firebase/firestore-types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { loadStripe } from '@stripe/stripe-js';
import { RootStateWithProfile } from 'reduxStore/store';
import { ExtraAPI } from 'reduxStore/utils/types';

type Props = { price: string };
type CheckoutSession = { error?: { message: string }; sessionId?: string };

const STRIPE_PUBLIC_API_KEY = process.env.REACT_APP_STRIPE_PUBLIC_API_KEY as string;

export const subscriptionPurchase = createAsyncThunk(
  'subscriptionPurchase',
  async ({ price }: Props, { extra, getState }) => {
    const { getOrgId, getUserId, getFirestore, getTeamId } = extra as ExtraAPI;
    const firestore = getFirestore();
    const state = getState() as RootStateWithProfile;
    const teamId = getTeamId(state);
    const userId = getUserId(state);
    const orgId = getOrgId();

    try {
      const quantity = (await firestore.collection('users').where('organizationIds', 'array-contains', orgId).get())
        .size;

      await firestore
        .collection('customers')
        .doc(orgId)
        .collection('checkout_sessions')
        .add({
          price,
          quantity,
          metadata: {
            orgId,
            userId,
          },
          allow_promotion_codes: true,
          success_url: `${window.location.origin}/${orgId}/${teamId}/checkout-success`,
          cancel_url: `${window.location.origin}/${orgId}/${teamId}/upgrade`,
        })
        .then((docRef: DocumentReference<DocumentData>) => {
          // Wait for the CheckoutSession to get attached by the extension
          if (docRef !== undefined)
            docRef.onSnapshot(async (snap: DocumentSnapshot<DocumentData>) => {
              const { error, sessionId } = (snap.data() ?? {}) as CheckoutSession;

              if (sessionId && !error) {
                // We have a session, let's redirect to Checkout
                const stripe = await loadStripe(STRIPE_PUBLIC_API_KEY);
                return stripe?.redirectToCheckout({ sessionId });
              }

              // Show an error to your customer and inspect `stripe-handleWebhookEvents` Cloud Function logs in the Firebase console
              if (error) {
                throw new Error(`An error occurred: ${error.message}`);
              }

              // Checkout session is still processing, do nothing
              return null;
            });
        });
    } catch (error) {
      throw new Error(`An error occurred: ${(error as { message: string }).message}`);
    }
  },
);
