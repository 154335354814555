import Input from 'components/core/controllers/views/Input';
import Text from 'components/core/controllers/views/Text';
import { css } from 'emotion';
import React, { useCallback, useRef, useState } from 'react';
import { ColorResult, SketchPicker } from 'react-color';
import { hexColorValidator, useClickOutside, useFormInput } from 'tools';

export type Color = string;

export type ColorPickerProps = {
  color: Color;
  label?: string;
  onChange?: (color: Color) => void;
};

export function ColorPicker({ color, label, onChange }: ColorPickerProps): JSX.Element {
  const [pickerColor, setPickerColor] = useState(color);
  const [showPicker, setShowPicker] = useState(false);

  const [inputColor, inputProps, isInputColorValid, setInputColor] = useFormInput(color, {
    debounceValidation: true,
    initialValid: true,
    validator: hexColorValidator,
  });

  const onColorChangeFromPicker = useCallback(
    (newColor: Color) => {
      if (showPicker && pickerColor !== color) {
        onChange?.(newColor);
        setInputColor(newColor);
      }
    },
    [color, onChange, pickerColor, setInputColor, showPicker],
  );
  const onColorChangeFromInput = useCallback(
    (newColor: Color) => {
      if (inputColor === color) return;
      if (!isInputColorValid) {
        // reset input color
        setInputColor(color);
      }
      onChange?.(newColor);
      setPickerColor(newColor);
    },
    [color, inputColor, isInputColorValid, onChange, setInputColor],
  );

  const onColorClick = useCallback(() => {
    onColorChangeFromPicker(pickerColor);
    setShowPicker(!showPicker);
  }, [showPicker, onColorChangeFromPicker, pickerColor]);
  const onClickOutsidePicker = useCallback(() => {
    onColorChangeFromPicker(pickerColor);
    setShowPicker(false);
  }, [onColorChangeFromPicker, pickerColor]);
  const onClickOutsideInput = useCallback(() => {
    onColorChangeFromInput(inputColor);
  }, [inputColor, onColorChangeFromInput]);

  const pickerRef = useRef<HTMLDivElement>(null);
  useClickOutside(pickerRef, onClickOutsidePicker);
  const inputRef = useRef<HTMLDivElement>(null);
  useClickOutside(inputRef, onClickOutsideInput);

  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
      `}
    >
      {label && (
        <Text
          className={css`
            font-size: 1rem;
            color: #67728b;
            padding-bottom: 0.5rem;
          `}
        >
          {label}
        </Text>
      )}
      <div
        className={css`
          display: flex;
          flex-direction: row;
        `}
      >
        <div
          ref={pickerRef}
          className={css`
            position: relative;
          `}
        >
          <button
            className={css`
              width: 4.625rem;
              height: 2.875rem;
              border-radius: 6px;
              border: solid 1px #c8d0df;
              background-color: ${color};
              outline: none;
            `}
            onClick={onColorClick}
            type='button'
          />
          {showPicker && (
            <SketchPicker
              className={css`
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translate(-50%, 0.5rem);
                box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.14);
                z-index: 2;
              `}
              color={pickerColor}
              onChangeComplete={(newColor: ColorResult): void => {
                setPickerColor(newColor.hex);
              }}
            />
          )}
        </div>
        <div
          ref={inputRef}
          className={css`
            display: flex;
            flex-direction: column;
            margin-left: 0.5rem;
            width: 100%;
          `}
        >
          <Input
            {...inputProps}
            style={{
              main: css`
                font-size: 1rem;
                color: #303f4b;
                background-color: #ffffff;
                border-radius: 6px;
                &:focus-within {
                  border-radius: 6px;
                }
              `,
              container: css`
                height: 2.875rem;
              `,
              bottomLabelStyle: css`
                font-size: 0.875rem;
                margin-top: 0.25rem;
              `,
            }}
          />
        </div>
      </div>
    </div>
  );
}
