/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable react-hooks/exhaustive-deps */
import { Data } from '@taraai/types';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useGetUserOrgMetricsOrgId } from 'firestore/user';
import { useEffect, useState } from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { gitInsightsSelectionState } from 'reduxStore';

export const UseLoadChartsData = (): Data.ChartData | null => {
  const [chartData, setChartData] = useState<Data.ChartData | null>(null);
  const functions = getFunctions();
  const getInsightState = useSelector(gitInsightsSelectionState);
  const { selectionType, selected, timeSegment } = getInsightState;

  const metricsOrgId = useGetUserOrgMetricsOrgId();

  const selectedmemberids = selected && selected !== null && selected.length > 0 ? selected : undefined;

  useEffect(() => {
    if (!metricsOrgId || !selectionType) return;
    // set to null as loading state
    setChartData(null);
    httpsCallable(
      functions,
      'ProcessMetricsChartDataOnCall',
    )({
      orgid: metricsOrgId,
      iscronjob: false,
      selectedtimesegment: timeSegment || 'weekly',
      selectedmemberids: selectedmemberids === null ? undefined : selectedmemberids,
    })
      .then((responseData: unknown): void => {
        if (responseData === undefined || responseData === null) return;
        const chartDataToSet = (responseData as Record<'data', Data.ChartData>)?.data;
        if (chartDataToSet === undefined || chartDataToSet === null) return;
        if (!deepEquals(chartDataToSet, chartData)) setChartData(chartDataToSet);
      })
      .catch((error) => {
        throw new Error((error as Error).message);
      });
  }, [metricsOrgId, selectionType, selected, timeSegment]);

  return chartData;
};
