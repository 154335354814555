import { createSlice } from '@reduxjs/toolkit';
import { Data } from '@taraai/types';

const slice = createSlice({
  name: 'workspace',
  initialState: '' as Data.Id.OrganizationId,
  reducers: {
    setCurrentWorkspaceId: (state, action): void => action.payload,
  },
});

export const workspaceReducer = slice.reducer;

export const setWorkspace = slice.actions.setCurrentWorkspaceId;

export const selectActiveWorkspace = (state: { workspace: string }): string => state.workspace;

export const selectActiveSprintPath = (state: { workspace: string }): string => `orgs/${state.workspace}/sprints`;

export const selectActiveTaskPath = (state: { workspace: string }): string => `orgs/${state.workspace}/tasks`;

export const selectActiveSummariesPath = (state: { workspace: string }): string => `orgs/${state.workspace}/summaries`;

export const selectActiveRequirementPath = (state: { workspace: string }): string =>
  `orgs/${state.workspace}/requirements`;

export const selectActiveTeamPath = (state: { workspace: string }): string => `orgs/${state.workspace}/teams`;
