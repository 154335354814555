import { css } from 'emotion';
import React from 'react';
import { strings } from 'resources/i18n';

/**
 * SprintInsightsHeader renders simple sprint insight modal header
 */
export default function SprintInsightsHeader(): JSX.Element {
  return (
    <div
      className={css`
        color: #303f4b;
        font-size: 1rem;
        font-weight: 500;
      `}
    >
      {strings.sprints.insights.pastSprintInsights}
    </div>
  );
}
