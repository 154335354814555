import { Box, styled, Text } from '@taraai/design-system';
import Icon from 'components/core/controllers/views/Icon';
import React from 'react';
import { strings } from 'resources';

type Props = {
  onClick: () => void;
  remainingTrialDays: number | null;
};

export const GetPremiumButton = ({ onClick, remainingTrialDays }: Props): JSX.Element => {
  return (
    <StyledButton background='$debug' borderRadius='$4px' onClick={onClick} spaceHorz='$8px' spaceVert='$2px'>
      <GradientText size='$14px' weight='medium'>
        {remainingTrialDays && remainingTrialDays > 0
          ? `${remainingTrialDays} ${strings.upgrade.trialDaysLeft}`
          : strings.upgrade.getPremium}
        <StyledIcon name='premiumStar' />
      </GradientText>
    </StyledButton>
  );
};

const GradientText = styled(Text, {
  'backgroundClip': 'text',
  // eslint-disable-next-line no-secrets/no-secrets
  'WebkitBackgroundClip': 'text',
  'backgroundImage': 'linear-gradient(135.19deg, #58a6e7 0.34%, #775dfa 100%)',
  'backgroundSize': '100%',
  'WebkitTextFillColor': '#58a6e7',
  'display': 'flex',
  'alignItems': 'center',
  ':hover': {
    WebkitTextFillColor: 'white !important',
  },
});

const StyledButton = styled(Box.Button, {
  'marginLeft': '$24px',
  'display': 'flex',
  'flexDirection': 'column',
  'justifyContent': 'center',
  'alignItems': 'center',
  'maxWidth': '7.6875rem',
  'maxHeight': '2rem',
  'left': '0px',
  'top': '8px',
  'background': 'linear-gradient(135.19deg, rgba(88, 166, 231, 0.1) 0.34%, rgba(119, 93, 250, 0.1) 100%)',
  'transition': '0.4s',
  ':hover': {
    background: 'linear-gradient(135.19deg, #58A6E7 0.34%, #775DFA 100%)',
    color: 'white !important',
  },
});

const StyledIcon = styled(Icon, {
  padding: '0rem',
  paddingLeft: '0.3125rem',
  verticalAlign: 'sub',
});
