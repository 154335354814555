import { Box, HStack, Text } from '@taraai/design-system';
import { Data, UI } from '@taraai/types';
import SprintEffort from 'components/app/controllers/SprintEffort';
import React from 'react';
import { strings } from 'resources/i18n';

type Props = {
  orgId: Data.Id.OrganizationId;
  sprint: UI.UISprint;
  tasks: UI.UITask[];
};

export function GanttChartFooter({ orgId, sprint, tasks = [] }: Props): JSX.Element {
  return (
    <Box background={tasks.length % 2 === 0 ? '$grey1' : '$white'} space='$8px' spaceLeft='$12px'>
      <HStack space='$16px'>
        <Box center>
          <Text color='$dark' size='$14px'>
            {strings.formatString(strings.sprints.sprintTimeline.tasks, {
              count: tasks.length,
            })}
          </Text>
        </Box>
        <SprintEffort currentSprint={sprint} isTextHidden orgId={orgId} tasks={tasks} />
      </HStack>
    </Box>
  );
}
