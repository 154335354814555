import { useMediaQuery } from '@material-ui/core';
import { Box, getCustomSize, HStack, styled, Text, VStack } from '@taraai/design-system';
import { isLoaded } from '@taraai/read-write';
import { Data } from '@taraai/types';
import { Preview } from 'components/app/Onboarding/Preview';
import { StepName } from 'components/app/Onboarding/types';
import { linkTo } from 'components/Router/paths';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  atomic,
  ConfirmationStep,
  LoginBanner,
  ModesStepBanner,
  strings,
  TaraLogo,
  VelocityStepBanner,
} from 'resources';

import * as TEST_IDS from './test-ids';
import { Step, WithLoaded } from './types';

export type FlowWrapperProps<Steps extends [StepName, Step][]> = {
  /**
   * the id of the preferred team that is initially created for a workspace
   */
  preferredTeamId?: Data.Id.TeamId;
  /**
   * indicates if there are workspaces that the logged in user is a part of
   */
  hasActiveOrgs: boolean;
  /**
   * sets the step from which to start
   */
  initialStep?: Steps[number][0];
  /**
   * indicates if all data has finished saving in the background
   */
  isOnboardingFlowFinished: boolean;
  /**
   * undefined if the user doesn't yet have an access to the organization, defined otherwise
   */
  loadedOrgId?: Data.Id.OrganizationId;
  /**
   * array of steps
   */
  steps: Steps;
  /**
   * called after all steps are finished
   */
  onFinish?: () => void | Promise<void>;
};

/**
 * FlowWrapper
 *
 * responsible for:
 * - displaying correct step,
 * - layout step according to the designs,
 * - handles errors in onNext and onFinish callbacks
 */
export function FlowWrapper<Steps extends [StepName, Step][]>({
  hasActiveOrgs,
  initialStep,
  isOnboardingFlowFinished,
  loadedOrgId,
  preferredTeamId,
  steps,
}: FlowWrapperProps<Steps>): JSX.Element {
  const showRightContainer = useRightContainerVisibility();
  const [step, setStep] = useState(() =>
    Math.max(
      0,
      steps.findIndex(([stepName]) => stepName === initialStep),
    ),
  );
  const withPreferredTeam = useWithLoaded(loadedOrgId, preferredTeamId);
  const stepsCount = steps.length;

  const advanceToNextStep = useCallback(
    async (currentStep: number, onNext?: () => Promise<void>): Promise<void> => {
      const maxStep = stepsCount - 1;
      const isLastStep = currentStep === maxStep;

      if (!isLastStep) {
        setStep(currentStep + 1);
      }

      await onNext?.();
    },
    [stepsCount],
  );

  if (stepsCount <= step) {
    return <div data-testid={TEST_IDS.EMPTY_DIV} />;
  }

  const [currentStepName, CurrentStep] = steps[step];

  const BannerImages: string[] = [LoginBanner, ModesStepBanner, VelocityStepBanner, ConfirmationStep];
  const image = BannerImages[step];

  return (
    <HStack data-testid={TEST_IDS.WRAPPER}>
      <Box background='$white' space='$24px' width={getCustomSize(400)}>
        <Box space='$24px'>
          <VStack>
            {hasActiveOrgs ? (
              <Link to={linkTo('home', { orgId: '', teamId: '' })}>
                <Box width={getCustomSize(135)}>
                  <TaraLogoImg alt={strings.logo.tara} data-testid={TEST_IDS.TARA_LOGO} src={TaraLogo} />
                </Box>
              </Link>
            ) : (
              <Text size='$38px' textAlign='left'>
                <Box width={getCustomSize(135)}>
                  <TaraLogoImg alt={strings.logo.tara} data-testid={TEST_IDS.TARA_LOGO} src={TaraLogo} />
                </Box>
              </Text>
            )}
            <Box spaceBottom='$16px' spaceTop='$24px'>
              <Box spaceTop='$24px' />
              <CurrentStep isOnboardingFlowFinished={isOnboardingFlowFinished} withPreferredTeam={withPreferredTeam}>
                {({ Form, childProps, onNext: stepOnNext }): JSX.Element => (
                  <Form
                    hasActiveOrgs={hasActiveOrgs}
                    isOnboardingFlowFinished={isOnboardingFlowFinished}
                    {...childProps}
                    onNext={(): Promise<void> => advanceToNextStep(step, stepOnNext)}
                  />
                )}
              </CurrentStep>
            </Box>
          </VStack>
        </Box>
      </Box>
      {showRightContainer && (
        <RightContainer height='$full'>
          <VStack data-testid={TEST_IDS.RIGHT_CONTAINER}>
            <Preview image={image} stepName={currentStepName} />
          </VStack>
        </RightContainer>
      )}
    </HStack>
  );
}

type NonNullableArray<T extends unknown[]> = {
  [I in keyof T]: NonNullable<T[I]>;
};

/**
 * Hide right container on small mobile devices
 */
function useRightContainerVisibility(): boolean {
  const { small } = atomic.responsive.breakpoints[0];
  const isDesktop = useMediaQuery(`(min-width: ${small}px)`);
  const hasEnoughVerticalSpace = useMediaQuery(`(min-height: ${small}px)`);

  return isDesktop || hasEnoughVerticalSpace;
}

/**
 * Returns a callback (A) that takes another callback (B).
 * When A is called:
 * - if all deps are not undefined, B is called (with deps passed)
 * - if some deps are undefined, calling B is deferred until all are defined
 *
 * Example:
 *
 * const withTasks = useWithLoaded(tasks); // tasks are UITask[] | undefined
 * const handleClick = useCallback(
 *   withTasks(async (tasks) => { // tasks are ensured to be UITask[] here
 *     ...
 *   }),
 *   []
 * );
 */
export function useWithLoaded<Deps extends unknown[]>(...deps: Deps): WithLoaded<NonNullableArray<Deps>> {
  const deferredCallbacks = useRef<((...args: NonNullableArray<Deps>) => Promise<void>)[]>([]);
  const isEverythingLoaded = isLoaded(...deps);
  useEffect(() => {
    if (isEverythingLoaded) {
      deferredCallbacks.current.forEach((call) => {
        // eslint-disable-next-line prefer-spread
        call.apply(undefined, deps as NonNullableArray<Deps>);
      });
      deferredCallbacks.current = [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEverythingLoaded]);
  return useCallback<WithLoaded<NonNullableArray<Deps>>>(
    (callback) => async () => {
      if (!isEverythingLoaded) {
        return new Promise((resolve, reject) => {
          deferredCallbacks.current.push(async (...args) => {
            try {
              // eslint-disable-next-line prefer-spread
              await callback.apply(undefined, args);
              resolve();
            } catch (error) {
              reject(error);
            }
          });
        });
      }
      // eslint-disable-next-line prefer-spread
      await callback.apply(undefined, deps as NonNullableArray<Deps>);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isEverythingLoaded],
  );
}

const RightContainer = styled(Box, {
  width: 'calc(100vw - 25rem)',
});

const TaraLogoImg = styled('img', {
  width: '100%',
});
