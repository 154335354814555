/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-constant-condition */
/* eslint-disable no-loops/no-loops */

import { Popup, styled } from '@taraai/design-system';
import { getEntityData } from 'components/editor/entities';
import { isWithinSelection } from 'components/editor/plugins/utils';
import { RichEditorContext } from 'components/editor/RichEditorProvider';
import { DraftDecoratorComponentProps } from 'components/editor/types';
import { ContentState, EditorState, Modifier, SelectionState } from 'draft-js';
import React, { CSSProperties, useCallback, useContext, useEffect, useState } from 'react';

import { ImagePopup } from './ImagePopup';
import { getImage } from './utils';

export function Image({
  blockKey,
  children,
  contentState,
  end,
  entityKey,
  start,
}: DraftDecoratorComponentProps): JSX.Element {
  const { editorState, setEditorState } = useContext(RichEditorContext);
  const selection = editorState.getSelection();
  const { alt, src } = getEntityData('IMAGE', contentState, entityKey);
  const [show, setShow] = useState(false);
  const [image, setImage] = useState<HTMLImageElement>();

  const atStart = selection.getAnchorKey() === blockKey && selection.getAnchorOffset() === start;
  const selected = isWithinSelection(
    getSelectionState(blockKey, start, end),
    getSelectionForBlock(blockKey, editorState.getCurrentContent(), selection),
  );

  const handleCopy = useCallback((): void => {
    navigator.clipboard.writeText(src);
    setShow(false);
  }, [src]);

  const handleRemove = useCallback((): void => {
    setEditorState((editorState) =>
      EditorState.push(
        editorState,
        Modifier.removeRange(editorState.getCurrentContent(), getSelectionState(blockKey, start, end), 'forward'),
        'remove-range',
      ),
    );
    setShow(false);
  }, [blockKey, end, setEditorState, start]);

  useEffect(() => {
    let mounted = true;
    (async () => {
      const image = await getImage(src);
      if (!image || !mounted) return;
      setImage(image);
    })();
    return () => {
      mounted = false;
    };
  }, [src]);

  return (
    <Popup
      content={
        <Popup.Content small>
          <ImagePopup onCopy={handleCopy} onRemove={handleRemove} src={src} />
        </Popup.Content>
      }
      onHide={() => setShow(false)}
      show={show}
    >
      <Wrapper
        atStart={atStart}
        onClick={() => setShow((prev) => !prev)}
        selected={selected}
        style={
          image &&
          ({
            '--src': `url(${JSON.stringify(image.src)})`,
            '--ratio': image.height / image.width,
            '--width': `min(${image.width > image.height ? '100%' : '50%'}, ${image.width}px)`,
          } as CSSProperties)
        }
        title={alt}
      >
        {children}
      </Wrapper>
    </Popup>
  );
}

/**
 * This is an ugly hack, but Draft.js doesn't handle inline images otherwise. Draft.js expects only text for inline
 * content and will break otherwise (it checks textContent.length to verify the position/selection).
 *
 * :after and :before have to be used because their content is not selectable and so Draft.js is in sync with DOM.
 *
 * If the image is blinking a lot, check that you don't have the cache disabled in dev tools.
 */
const imageStyles = {
  backgroundImage: 'linear-gradient(var(--selectionColor), var(--selectionColor)), var(--src)',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  content: '""',
  display: 'inline-block',
  maxWidth: 'var(--max-width)',
  paddingTop: 'calc(var(--ratio) * var(--width))',
  width: 'var(--width)',
};

const Wrapper = styled(
  'span',
  {
    '&:after': imageStyles,
    '&:before': imageStyles,
  },
  {
    atStart: {
      true: { '&:before': { display: 'none' } },
      false: { '&:after': { display: 'none' } },
    },
    selected: {
      true: { '--selectionColor': '#3390FC99' },
      false: { '--selectionColor': 'transparent' },
    },
  },
);

function getSelectionForBlock(
  expectedBlockKey: string,
  content: ContentState,
  selection: SelectionState,
): SelectionState {
  const startBlockKey = selection.getStartKey();
  const endBlockKey = selection.getEndKey();
  let blockKey = startBlockKey;
  let block;
  while (true) {
    if (blockKey === expectedBlockKey)
      return getSelectionState(
        blockKey,
        blockKey === startBlockKey ? selection.getStartOffset() : 0,
        // Infinity should suffice for the use we have
        blockKey === endBlockKey ? selection.getEndOffset() : Infinity,
      );
    if (blockKey === endBlockKey) break;
    block = content.getBlockAfter(blockKey);
    // eslint-disable-next-line no-continue
    if (!block) continue;
    blockKey = block.getKey();
  }
  // If there is no match, return the original selection - it will suffice
  return selection;
}

function getSelectionState(blockKey: string, anchorOffset: number, focusOffset = anchorOffset): SelectionState {
  return SelectionState.createEmpty(blockKey).merge({
    anchorOffset,
    focusOffset,
    hasFocus: true,
  }) as SelectionState;
}
