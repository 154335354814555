import Button from 'components/core/controllers/views/Button';
import Text from 'components/core/controllers/views/Text';
import { linkTo } from 'components/Router/paths';
import { css } from 'emotion';
import React from 'react';
import { useHistory } from 'react-router';
import { strings } from 'resources';
import { UsersEmptyState } from 'resources/assets';

export const Deactivated: React.FC = () => {
  const history = useHistory();

  return (
    <div
      className={css`
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        transform: translateY(-15%);
      `}
    >
      <img
        alt={strings.logo.tara}
        className={css`
          height: 6.25rem;
          margin-bottom: 2rem;
        `}
        src={UsersEmptyState}
      />
      <Text
        className={css`
          color: var(--slate-grey);
          margin-bottom: 2rem;
          font-weight: normal;
        `}
      >
        {strings.deactivated.userDoesNotExist}
      </Text>
      <Button
        className={css`
          padding: 0.5rem 1rem;
          min-height: 1.6875rem;
        `}
        onClick={(): void => history.push(linkTo('logout'))}
      >
        {strings.deactivated.backToLogin}
      </Button>
    </div>
  );
};
