import { Box, styled, VStack } from '@taraai/design-system';
import { SprintColumnWrapper } from 'components/app/layouts/SprintColumnsLayout/SprintColumnsLayout';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useCallback } from 'react';
import { strings } from 'resources';

type Props = {
  onClick: () => void;
  loadForward?: boolean;
};

export function LoadMoreColumn({ onClick, loadForward = false }: Props): JSX.Element {
  return (
    <SprintColumnWrapper>
      <VStack full>
        <LoadMoreHeader space='$4px' width='$fitContent'>
          {loadForward ? strings.sprints.sprintColumn.nextSprint : strings.sprints.sprintColumn.previousSprint}
        </LoadMoreHeader>
        <LoadMoreButton data-click='sprint-column-load-more' onClick={onClick}>
          <LoadMoreButtonText>
            {loadForward
              ? strings.sprints.sprintColumn.loadNextTenSprints
              : strings.sprints.sprintColumn.loadPreviousTenSprints}
          </LoadMoreButtonText>
        </LoadMoreButton>
      </VStack>
    </SprintColumnWrapper>
  );
}

const LoadMoreHeader = styled(Box, {
  color: '$grey6',
  fontSize: '$12px',
  lineHeight: '$16px',
  marginBottom: '16px',
});

const LoadMoreButton = styled(Box, {
  height: '127px',
  width: '100%',
  background: 'white',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  color: '$focus',
  border: '4px solid',
  borderColor: '$grey3',
  fontSize: '$12px',
  cursor: 'pointer',
});

const LoadMoreButtonText = styled(Box, {
  textAlign: 'center',
});
