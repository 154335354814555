import { createAsyncThunk } from '@reduxjs/toolkit';
import { Functions, UI } from '@taraai/types';
import { CREATION_DEFAULTS, decode } from 'reduxStore/utils/decoders';
import { ExtraAPI } from 'reduxStore/utils/types';

export const inviteUsers = createAsyncThunk(
  'InviteUsers',
  async ({ emails, message, teamId }: UI.Invitation, { extra }) => {
    const { getFirebase, getOrgId } = extra as ExtraAPI;
    const orgId = getOrgId();

    const payloadDraft: Functions.InviteUsers.Payload = {
      organizationId: orgId,
      teamId,
      emails,
      message,
    };

    const payload = decode<Functions.InviteUsers.Payload>(payloadDraft, 'InviteUserPayload', CREATION_DEFAULTS);

    await getFirebase().functions().httpsCallable('inviteUsers')(payload);
  },
);
