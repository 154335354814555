import { Data, UI } from '@taraai/types';
import { isNonEmptyString } from '@taraai/utility';
import { createStandardSelector, inertQuery, Query } from 'reduxStore/utils/selectors';
import { ReduxFirestoreQuery } from 'reduxStore/utils/types';

export const getIntegrationSecret = (
  orgId: Data.Id.OrganizationId,
  integrationId: Data.Id.IntegrationId | undefined,
): Query<UI.UIIntegrationSecret> => {
  if (![orgId, integrationId].every(isNonEmptyString)) {
    return inertQuery();
  }
  const query: ReduxFirestoreQuery = {
    collection: `orgs/${orgId}/integrations/${integrationId}/secrets`,
    storeAs: `integrations/${orgId}/${integrationId}/secrets`,
  };

  return {
    query: [query],
    selector: createStandardSelector<UI.UIIntegrationSecret>(query),
  };
};
