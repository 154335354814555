import { unwrapResult } from '@reduxjs/toolkit';
import { GitlabInsightsModal } from 'components/app/controllers/views/GitlabModal/GitlabInsightsModal';
import { usePathParams } from 'components/Router/paths';
import React, { useCallback, useState } from 'react';
import { useAppDispatch } from 'reduxStore';
import { setupIntegration } from 'reduxStore/setupIntegration/setupIntegration';
import { useToast } from 'tools';
import { segment } from 'tools/libraries/analytics';

export interface GitlabModalControllerProps extends React.HTMLProps<HTMLDivElement> {
  closeModal: () => void;
  instructionImgSrc: string | undefined;
  secretToken: string | undefined;
}

export function GitlabInsightsModalController({
  closeModal,
  instructionImgSrc,
  secretToken: secretTokenFromProps,
}: GitlabModalControllerProps): JSX.Element {
  const dispatch = useAppDispatch();
  const { orgId } = usePathParams('workspace');
  const { addToast } = useToast();

  const [secretToken, setSecretToken] = useState(secretTokenFromProps);

  const [isGeneratingToken, setIsGeneratingToken] = useState(false);
  const generateToken = useCallback(async () => {
    setIsGeneratingToken(true);
    await dispatch(
      setupIntegration({
        service: 'gitlab',
        organizationId: orgId,
        payload: undefined,
      }),
    )
      .then(unwrapResult)
      .then((result) => {
        if (result.service !== 'gitlab') {
          throw new Error('Got response for incorrect service.');
        }
        segment.track('IntegrationConnected', {
          orgID: orgId,
          service: 'gitlab',
          location: 'GitlabModal',
        });
        return setSecretToken(result.secretToken);
      })
      .catch((error) => {
        addToast({
          type: 'error',
          message: error.message,
          timeoutMs: 3000,
        });
      })
      .finally(() => {
        setIsGeneratingToken(false);
      });
  }, [dispatch, orgId, addToast]);
  return (
    <GitlabInsightsModal
      closeModal={closeModal}
      generateToken={generateToken}
      instructionImgSrc={instructionImgSrc}
      isGeneratingToken={isGeneratingToken}
      secretToken={secretToken}
    />
  );
}
