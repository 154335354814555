import { Box } from '@taraai/design-system';
import React, { ReactElement, ReactNode } from 'react';

import { Header } from './Header';

interface SprintDetailsSidebarModuleProps {
  children?: ReactNode;
  header?: ReactElement<typeof Header>;
  type?: 'primary' | 'secondary';
}

export function SprintDetailsSidebarModule({
  children,
  header,
  type = 'primary',
}: SprintDetailsSidebarModuleProps): JSX.Element {
  return (
    <Box
      background={type === 'primary' ? '$white' : '$grey1'}
      borderBottom='$grey2'
      spaceBottom='$12px'
      spaceHorz='$12px'
      spaceTop={header ? '$8px' : '$12px'}
    >
      {header}
      <Box>{children}</Box>
    </Box>
  );
}

SprintDetailsSidebarModule.Header = Header;
