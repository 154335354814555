"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotEmptyString = void 0;
var ts_data_json_1 = require("ts.data.json");
exports.NotEmptyString = new ts_data_json_1.JsonDecoder.Decoder(function (value) {
    if (typeof value !== 'string') {
        return (0, ts_data_json_1.err)("".concat(value, " is not a string"));
    }
    var trimmedString = value.trim();
    if (trimmedString.length === 0) {
        return (0, ts_data_json_1.err)('Expected not empty string');
    }
    return (0, ts_data_json_1.ok)(trimmedString);
});
