import { styled } from '@taraai/design-system';
import Icon from 'components/core/controllers/views/Icon';
import React, { KeyboardEventHandler, MouseEventHandler } from 'react';
import { workspaceTeamsTestIds } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';

interface Props {
  onClick: MouseEventHandler;
  onKeyDown: KeyboardEventHandler;
}

/**
 * AddTeamMemberCell
 * table cell for add team member button
 *
 */
export function AddTeamMemberCell({ onClick, onKeyDown }: Props): JSX.Element {
  return (
    <AddContainer onClick={onClick} onKeyDown={onKeyDown} role='button' tabIndex={0}>
      <AddIcon color='#eaeef5' data-cy={workspaceTeamsTestIds.ADD_MEMBER_BUTTON_PLUS} name='circleplus' />
      <AddLabel data-cy={workspaceTeamsTestIds.ADD_MEMBER_BUTTON_ELEMENT}>
        {strings.memberManager.addMembers.success}
      </AddLabel>
    </AddContainer>
  );
}

const AddLabel = styled('span', {
  color: '#949caf',
  paddingLeft: '8px',
  paddingTop: '4px',
});

const AddIcon = styled(Icon, {
  height: '32px',
  padding: '0rem',
  width: '32px',
});

const AddContainer = styled('div', {
  'alignItems': 'center',
  'color': '#949caf',
  'display': 'flex',
  'fontSize': '0.875rem',
  'padding': '0.75rem',
  'position': 'relative',
  '&:focus-within': {
    fontWeight: '500',
    color: '#303f4b',
  },
});
