import { unwrapResult } from '@reduxjs/toolkit';
import { UI } from '@taraai/types';
import { EditTeamDetailsView } from 'components/app/controllers/views/EditTeamDetailsView';
import React, { useCallback } from 'react';
import { setPreferredTeamId, updateTeam, updateTeamMembership, useAppDispatch } from 'reduxStore';
import { decodeChain } from 'reduxStore/utils/decoders';
import { strings } from 'resources/i18n';
import { useToast } from 'tools';
import { formatI18n } from 'tools/libraries/helpers/formatI18n';

interface Props {
  isMember: boolean;
  isPreferred: boolean;
  profileId: string;
  teamId: string;
  teamName: string;
  username: string;
}

/**
 * EditTeamDetailsController
 */
export function EditTeamDetailsController({
  isMember,
  isPreferred,
  profileId,
  teamId,
  teamName,
  username,
}: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const { whenSuccess, whenError } = useToast();

  const addUserToTeam = useCallback((): void => {
    const data = { username, teamName };
    const success = formatI18n(strings.memberManager.addMember.success)(data);
    const error = formatI18n(strings.memberManager.addMember.error)(data);
    dispatch(updateTeamMembership({ action: 'add', teamId, userIds: [profileId] }))
      .then(unwrapResult)
      .then(whenSuccess(success))
      .catch(whenError(error));
  }, [dispatch, profileId, teamId, teamName, username, whenError, whenSuccess]);

  const updateName = useCallback(
    (newTeamName: string): void => {
      decodeChain<UI.UITeamChangeset>({ id: teamId, name: newTeamName }, 'UITeamPartial')
        .then((data) => dispatch(updateTeam(data)))
        .then(unwrapResult)
        .then(whenSuccess(strings.teamDetails.editNameSuccess))
        .catch(whenError((err) => `${strings.teamDetails.editError}: ${err.message}`));
    },
    [teamId, dispatch, whenError, whenSuccess],
  );

  const setAsPreferred = useCallback((): void => {
    dispatch(setPreferredTeamId({ teamId }))
      .then(unwrapResult)
      .then(whenSuccess(strings.profile.teams.setPreferredSuccess))
      .catch(whenError((err) => `${strings.profile.teams.setPreferredError} ${err.message}`));
  }, [dispatch, teamId, whenSuccess, whenError]);

  return (
    <EditTeamDetailsView
      isMember={isMember}
      isPreferred={isPreferred}
      joinTeam={addUserToTeam}
      setAsPreferred={setAsPreferred}
      teamName={teamName}
      updateName={updateName}
    />
  );
}
