import { createAsyncThunk } from '@reduxjs/toolkit';
import { ExtraAPI } from 'reduxStore/utils/types';
import { strings } from 'resources/i18n';
import { THREE_MINUTES, TWO_MEGABYTES } from 'tools/libraries/helpers/constants';
import { v4 as uuidv4 } from 'uuid';

export const setOrganizationLogo = createAsyncThunk(
  'SetOrganizationLogo',
  async ({ file }: { file: File }, { extra }) => {
    const { getOrgId, getFirestore, getFirebase } = extra as ExtraAPI;
    const orgId = getOrgId();

    if (file.size > TWO_MEGABYTES) {
      throw strings.workspaceLogo.imageTooLarge;
    }
    const fileRef = getFirebase().storage().ref(`orgs/${orgId}/logos/${uuidv4()}`);
    const uploadTask = fileRef.put(file);
    return new Promise<string>((resolve, reject) => {
      const uploadTimeout = setTimeout(() => {
        uploadTask.cancel();
        return reject(new Error(strings.workspaceLogo.uploadTimeout));
      }, THREE_MINUTES);
      uploadTask.on(
        'state_changed',
        (snapshot) => snapshot,
        (err: Error) => {
          clearTimeout(uploadTimeout);
          return reject(err.message);
        },
        async () => {
          const logoURL = await fileRef.getDownloadURL();
          await getFirestore()
            .update(`orgs/${orgId}`, { logoURL })
            .catch((error: Error) => {
              reject(error);
            });
          clearTimeout(uploadTimeout);
          return resolve(logoURL);
        },
      );
    });
  },
);
