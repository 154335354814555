import { UI } from '@taraai/types';
import EffortSelector from 'components/app/controllers/EffortSelector';
import { css } from 'emotion';
import React from 'react';
import { strings } from 'resources/i18n';

type TaskFragment = Pick<UI.UITask, 'id' | 'effortLevel' | 'effortUnit'>;

export interface EffortSelectorLevelProps {
  task: TaskFragment;
  effortRef: React.RefObject<HTMLInputElement>;
  dataCy?: string;
}

/**
 * EffortSelectorLevel
 * view for task modal effort row feature
 *
 */
export default function EffortSelectorLevel({ task, effortRef, dataCy }: EffortSelectorLevelProps): JSX.Element {
  return (
    <div
      className={css`
        display: flex;
      `}
      data-cy={dataCy}
    >
      <EffortSelector Ref={effortRef} dataCy={dataCy} task={task} />
      <p
        className={css`
          align-self: center;
          color: #949caf;
          font-size: 0.875rem;
          font-weight: normal;
          padding-left: 0.4375rem;
          text-transform: lowercase;
        `}
        data-cy='effort-cy'
      >
        {strings.workspace.effortToggler.units[task.effortUnit]}
      </p>
    </div>
  );
}
