import { useEffect, useState } from 'react';

/** Gets boundingClientRect of the provided element */
export const useElementBoundingRect = (
  elRef: React.MutableRefObject<HTMLElement | null>,
): { width: number; height: number; top: number; left: number } => {
  const [boundingRect, setBoundingRect] = useState<DOMRect | null>(null);

  useEffect(() => {
    if (elRef.current) {
      const currentRect = elRef.current?.getBoundingClientRect();
      setBoundingRect(currentRect ?? null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elRef.current]);

  return {
    width: boundingRect?.width ?? 0,
    height: boundingRect?.height ?? 0,
    top: boundingRect?.top ?? 0,
    left: boundingRect?.left ?? 0,
  };
};
