import { UI } from '@taraai/types';

export function textToLabel(text: string, labelMap: Map<string, UI.UILabel>): UI.UILabel | undefined {
  const possibleId = text.slice('[#tara-label-'.length, text.length - 1);
  return labelMap.get(possibleId);
}

export function labelIdToText(id: string): string {
  return `[#tara-label-${id}]`;
}

export function labelTitleToDisplayText(title: string): string {
  return `#${title}`;
}
