import styled from '@emotion/styled';
import Icon from 'components/core/controllers/views/Icon';
import { css } from 'emotion';
import React from 'react';

export type FooterButtonProps = {
  title: string;
  onClick: (event: React.SyntheticEvent) => void;
};

export default function FooterButton({ title, onClick }: FooterButtonProps): JSX.Element {
  return (
    <Container onClick={onClick} role='button'>
      <Icon
        className={css`
          width: 2rem;
          height: 2rem;
          padding: 0;
          margin-right: 0.5rem;
        `}
        name='circleplus'
      />
      <Text>{title}</Text>
    </Container>
  );
}

const Container = styled.div`
  font-size: 0.875rem;
  box-shadow: 0 -1px 0 0 #c8d0df;
  position: relative;
  color: #1d98ff;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const Text = styled.span`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background-color: #1d98ff;
    transform: scaleX(0);
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }

  &:hover::before {
    opacity: 1;
    transform: scaleX(1);
  }

  &:focus::before {
    transform: scaleX(1.1);
  }
`;
