import { RichEditorPlugin } from 'components/editor/types';
import { DraftHandleValue, EditorState, Modifier } from 'draft-js';
import { Dispatch, SetStateAction } from 'react';

export const createMaxLengthPlugin = (options: {
  maxLength: number;
}): RichEditorPlugin<'handleBeforeInput' | 'handlePastedText'> => ({
  handleBeforeInput: (chars, editorState) => handleIncomingText(chars, editorState, options.maxLength),
  handlePastedText: (text, html, editorState, { setEditorState }) =>
    handleIncomingText(text, editorState, options.maxLength, setEditorState),
});

function handleIncomingText(
  text: string,
  editorState: EditorState,
  maxLength: number,
  setEditorState?: Dispatch<SetStateAction<EditorState>>,
): DraftHandleValue {
  setEditorState &&
    setEditorState(
      EditorState.push(
        editorState,
        Modifier.replaceText(
          editorState.getCurrentContent(),
          editorState.getSelection(),
          text.substr(0, maxLength - 1),
        ),
        'insert-fragment',
      ),
    );

  return editorState.getCurrentContent().getPlainText().length + text.length > maxLength ? 'handled' : 'not-handled';
}
