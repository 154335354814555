import { AddIconAndLabel, Avatar, Box } from '@taraai/design-system';
import React from 'react';

export type Props = {
  isListEmpty: boolean;
  onClick: () => void;
};

export const AddCollaboratorButton = ({ onClick, isListEmpty }: Props): JSX.Element => (
  <Box spaceVert='$2px' width='$fitContent'>
    {isListEmpty ? (
      <Box.Button onClick={onClick}>
        <Avatar />
      </Box.Button>
    ) : (
      <AddIconAndLabel onClick={onClick} />
    )}
  </Box>
);
