import { Data } from '@taraai/types';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { selectAuth } from 'reduxStore';
import { decode } from 'reduxStore/utils/decoders';

/**
 * returns user document fields based on fields arguments and user id
 * This should be used most places in the UI where a user data is to be rendered.
 *
 * @param fields
 * @returns requested user fields of second argument
 */
export function useGetUserFields<K extends keyof Data.User>(fields: K[]): Pick<Data.User, K> | null {
  const firestore = getFirestore();
  const [userFields, setUserFields] = useState<Pick<Data.User, K> | null>(null);

  const userId = useSelector(selectAuth).uid;

  useEffect(() => {
    if (!userId || !userId.length) return;

    const usersRef = doc(firestore, 'users', userId);

    onSnapshot(
      usersRef,
      (querySnapshot) => {
        const docData = querySnapshot.data();
        const docExists = querySnapshot.exists();
        if (docExists) {
          // decode document
          const decodedDoc = decode<Data.User>({ id: querySnapshot.id, ...docData }, 'UIUser');

          // pick document fields
          const UserPartial = fields.reduce((acc, field) => ({ ...acc, [field]: decodedDoc[field] }), {}) as Pick<
            Data.User,
            K
          >;

          // if changes update state
          if (!deepEquals(UserPartial, userFields)) setUserFields(UserPartial);
        }
      },
      // todo asses error handling strategy
      (error) => {
        throw new Error(error.message);
      },
    );
  }, [fields, firestore, userId, userFields]);

  return userFields;
}
