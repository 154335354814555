import { Box, HStack, styled } from '@taraai/design-system';
import React, { useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { SprintPathId } from 'tools/utils/hooks/usePagination';

import { LoadMoreColumn } from './LoadMoreColumn';

type Props = {
  anchorLeft: number;
  items: SprintPathId[];
  renderItem: (
    item: SprintPathId,
    ref: React.RefObject<HTMLDivElement>,
    offsetFromSelected: number,
    offsetFromActive: number,
    idx: number,
  ) => JSX.Element;
  selectedId: string;
  currentId: string;
  onScrollBackEdge: () => void;
  onScrollForwardEdge: () => void;
};

export function ScrollPane({
  anchorLeft,
  items,
  renderItem,
  selectedId,
  currentId,
  onScrollBackEdge,
  onScrollForwardEdge,
}: Props): JSX.Element {
  const paneRef = useRef<HTMLDivElement>(null);
  const oldSelectedId = useRef(selectedId);
  const isScrollUserHandled = useRef(false);

  const itemsWithRefs = useMemo(() => {
    return items.map((item) => ({ item, ref: React.createRef<HTMLDivElement>() }));
  }, [items]);
  const selectedItemRef = itemsWithRefs.find(({ item }) => item[1] === selectedId);
  const currentIndex = items.findIndex(([, id]) => id === currentId);

  // Scroll must be set in the middle only when the scrolls is handled by the "Jumpt to current sprint" button
  // Otherwise, the scroll must be handled by the user.
  const handleScroll = (): void => {
    isScrollUserHandled.current = true;
  };

  useLayoutEffect(() => {
    const selectedItemDidNotChange = oldSelectedId.current === selectedId;
    if (selectedItemDidNotChange && selectedItemRef && paneRef.current) {
      // when selectedId did not change but selectedIndex did
      // it means that we need to immediately set new scroll position, since selected element
      // is now at a different offset
      const itemOffset = selectedItemRef.ref.current?.offsetLeft ?? 0;
      if (!isScrollUserHandled.current) {
        const scrollPosition = itemOffset - anchorLeft;
        paneRef.current.scrollTo({ top: 0, left: scrollPosition, behavior: 'smooth' });
      }
    }
  }, [anchorLeft, selectedId, selectedItemRef]);

  useEffect(() => {
    if (oldSelectedId.current !== selectedId && selectedItemRef && paneRef.current) {
      // when selectedId changed, scroll using smooth animation
      isScrollUserHandled.current = false;
      const itemOffset = selectedItemRef.ref.current?.offsetLeft ?? 0;
      const scrollPosition = itemOffset - anchorLeft;
      if (!isScrollUserHandled.current) {
        paneRef.current.scrollTo({ top: 0, left: scrollPosition, behavior: 'smooth' });
      }
    }
    oldSelectedId.current = selectedId;
  }, [anchorLeft, selectedId, selectedItemRef]);

  const isTheLastSprintPhantom = itemsWithRefs.length && items[items.length - 1][2];

  return (
    <Wrapper ref={paneRef} full onScroll={handleScroll}>
      <HStack full style={{ paddingLeft: anchorLeft }}>
        {itemsWithRefs.length && <LoadMoreColumn onClick={onScrollBackEdge} />}
        {itemsWithRefs.map(({ item, ref }, idx) => {
          const selectedItemIdx = itemsWithRefs.findIndex((itemWithRef) => itemWithRef.item[1] === selectedId);
          const offsetFromSelected = idx - selectedItemIdx;
          const offsetFromActive = idx - currentIndex;
          return renderItem(item, ref, offsetFromSelected, offsetFromActive, idx);
        })}
        {!isTheLastSprintPhantom && <LoadMoreColumn loadForward onClick={onScrollForwardEdge} />}
        <Box full />
      </HStack>
    </Wrapper>
  );
}

const Wrapper = styled(Box, {
  overflow: 'auto',
});
