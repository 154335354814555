import { styled } from '@taraai/design-system';
import React from 'react';
import { Col, Row } from 'react-grid-system';

import { PrMrHealth } from './sections/PrMrHealth';
import { TeamHealth } from './sections/TeamHealth';
import { TeamWorkloadBreakdown } from './sections/TeamWorkloadBreakdown';

export function TeamTab(): JSX.Element {
  return (
    <>
      <GridRow>
        <Col lg={12} xl={12} xxl={12}>
          <TeamHealth />
        </Col>
      </GridRow>

      <GridRow>
        <Col lg={6} xl={6} xxl={6}>
          <TeamWorkloadBreakdown />
        </Col>

        <Col lg={6} xl={6} xxl={6}>
          <PrMrHealth />
        </Col>
      </GridRow>
    </>
  );
}

const GridRow = styled(Row, {
  marginBottom: '$24px',
});
