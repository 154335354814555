import { createAsyncThunk } from '@reduxjs/toolkit';
import { Data } from '@taraai/types';
import { CREATION_DEFAULTS, decode } from 'reduxStore/utils/decoders';
import { ExtraAPI } from 'reduxStore/utils/types';

export const createLabel = createAsyncThunk('CreateLabel', async (title: string, { extra }) => {
  const { getOrgId, getFirestore } = extra as ExtraAPI;
  const orgId = getOrgId();
  const firestore = getFirestore();

  const label = decode<Data.CustomLabel>({ title }, 'CustomLabel', CREATION_DEFAULTS);

  return firestore
    .set<Data.CustomLabel>(`orgs/${orgId}/labels/${title}`, label)
    .then((docRef) => ({ ...label, id: docRef.id }));
});
