import { useFirestoreConnect } from '@taraai/read-write';
import { Data } from '@taraai/types';
import SprintColumnsLayout from 'components/app/layouts/SprintColumnsLayout';
import { browserHistory } from 'components/Router/history';
import { linkTo } from 'components/Router/paths';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getSprintTasks, RootState, selectActiveTeam, selectActiveWorkspace, selectSprintDocument } from 'reduxStore';
import getSprintsWindow from 'reduxStore/sprints/queries/getSprintsWindow';
import { fromEpoch, toEpoch } from 'tools/libraries/helpers/dates';
import { useShallowMemo } from 'tools/utils/hooks/general';
import { usePagination } from 'tools/utils/hooks/usePagination';

const pageSizeBack = 6;
const pageSizeForward = 6;

type Props = {
  currentSprintId: Data.Id.SprintId;
  selectedSprintId: Data.Id.SprintId;
  setSelectedSprintId: (id: Data.Id.SprintId) => void;
  onTaskSelect: (taskId: Data.Id.TaskId) => void;
};

/**
 * SprintColumnsController is responsible for the sprints moving window columns page data management
 * Accesses higher level data that will be passed to multiple child components in SprintColumnsLayout
 */

const checkIfTaskSlicesIsLoaded = (source: unknown): string[] =>
  source instanceof Array ? source.flatMap(({ query }) => query) : [];

export default function SprintColumnsController({
  currentSprintId,
  selectedSprintId,
  setSelectedSprintId,
  onTaskSelect,
}: Props): JSX.Element | null {
  const history = useHistory();
  const orgId = useSelector(selectActiveWorkspace);
  const teamId = useSelector(selectActiveTeam);

  const selectedSprintEndDateSeconds = useSelector((state: RootState) => {
    const sprint = selectSprintDocument(state, selectedSprintId);
    return sprint ? toEpoch(sprint.endDate) : toEpoch(new Date());
  });

  const loadSprintWindow = useCallback(
    (limitBefore, limitAfter, pivotEndDate) => {
      return getSprintsWindow(orgId, teamId, fromEpoch(pivotEndDate), {
        limitBefore,
        limitAfter,
      });
    },
    [orgId, teamId],
  );

  const {
    items: sprints,
    loadCurrentSprint,
    setSprint,
    prependPage,
    appendPage,
  } = usePagination(
    pageSizeBack,
    pageSizeForward,
    selectedSprintId,
    currentSprintId,
    selectedSprintEndDateSeconds,
    loadSprintWindow,
  );

  // bulk load all tasks up top
  const taskSlices = getSprintTasks(orgId, sprints?.map(([, id]) => id) ?? []);
  useFirestoreConnect(checkIfTaskSlicesIsLoaded(taskSlices));

  const showSprintDetailsSidebar = useCallback((): void => {
    history.push(linkTo('sprints', { orgId, teamId }));
  }, [history, orgId, teamId]);

  // Key used to trigger a rendering in the sprintColumnsLayout
  // even when no other state value has changed.
  // This is required to trigger the scrollPane to move the
  // scroll to the selected either when the selected changes or not.
  // Which is triggered by clicking to "Jump to current sprint"
  const [renderKey, setRenderKey] = useState(Math.floor(Math.random() * 100));
  const selectCurrentSprint = useCallback((): void => {
    const initialSprintId = loadCurrentSprint();
    if (!sprints || !initialSprintId) return;

    browserHistory.pushRoute(
      linkTo('sprintSelected', {
        orgId,
        teamId,
        sprintId: initialSprintId,
      }),
    );
    const newKey = Math.floor(Math.random() * 100);
    setRenderKey(newKey);
    showSprintDetailsSidebar();
    setSelectedSprintId(initialSprintId);
    setSprint(initialSprintId);
  }, [loadCurrentSprint, sprints, orgId, teamId, showSprintDetailsSidebar, setSelectedSprintId, setSprint]);

  return useShallowMemo(
    () => (
      <SprintColumnsLayout
        key={renderKey}
        currentSprintId={currentSprintId}
        onScrollBackEdge={prependPage}
        onScrollForwardEdge={appendPage}
        onSelectCurrentSprint={selectCurrentSprint}
        onTaskSelect={onTaskSelect}
        selectedSprintId={selectedSprintId}
        sprints={sprints}
      />
    ),
    [currentSprintId, onTaskSelect, selectedSprintId, sprints, renderKey],
  );
}
