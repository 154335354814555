import styled from '@emotion/styled';

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const NameContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
`;

export const NameSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  height: 100%;
`;

export const NameLabel = styled.span`
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #67728b;
  margin-bottom: 0.5rem;
`;

export const NameField = styled.div`
  font-size: 1.125rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #303f4b;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
