"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseEmail = void 0;
function parseEmail(email) {
    var parts = email.split('@');
    if (parts.length === 2) {
        var name_1 = parts[0].trim();
        var domain = parts[1].trim();
        if (name_1.length > 0 && domain.length > 0) {
            return {
                full: email.trim(),
                name: name_1,
                domain: domain,
            };
        }
    }
    throw new Error("Failed to parse email ".concat(email));
}
exports.parseEmail = parseEmail;
