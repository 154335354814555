import { createAsyncThunk } from '@reduxjs/toolkit';
import { Functions } from '@taraai/types';
import { ExtraAPI } from 'reduxStore/utils';
import { decode } from 'reduxStore/utils/decoders';

export type DisconnectProfileActionPayload = Functions.DisconnectProfile.Payload;

export const disconnectProfile = createAsyncThunk(
  'DisconnectProfile',
  async (payload: DisconnectProfileActionPayload, { extra }) => {
    const { getFirebase } = extra as ExtraAPI;

    const valid = decode<Functions.DisconnectProfile.Payload>(payload, 'DisconnectProfilePayload');

    await getFirebase().functions().httpsCallable('disconnectProfile')(valid);
  },
);
