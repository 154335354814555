import Tara, { Data, UI } from '@taraai/types';
import Select from 'components/core/controllers/views/Select';
import { OptionDefinition } from 'components/core/controllers/views/Select/Select';
import React from 'react';
import { useSelector } from 'react-redux';
import { isProfileAdmin, selectAuth } from 'reduxStore';
import { strings } from 'resources/i18n';
import { useSelect } from 'tools';

const allAccessLevels: Tara.Data.AccessLevel[] = ['admin', 'user', 'deactivated'];

export interface UserAccessLevelSelectorProps {
  accessLevel: Data.AccessLevel;
  onAccessLevelChange?: (userId: string, accessLevel: Data.AccessLevel) => void;
  orgId: Data.Id.OrganizationId;
  user: UI.UIUser;
}

export default function UserAccessLevelSelector({
  accessLevel,
  onAccessLevelChange,
  orgId,
  user,
}: UserAccessLevelSelectorProps): JSX.Element {
  const isAdmin = useSelector(isProfileAdmin(orgId));
  const profileId = useSelector(selectAuth).uid;

  const options = allAccessLevels.map((option: Data.AccessLevel) => ({
    label: strings.users.activeUsers.roleSelector.labels[option],
    value: option,
  }));

  const initialSelectedOption = options.find((option) => option.value === accessLevel);

  const [, , roleSelectProps] = useSelect({
    initialSelectedOption,
    options,
    placeholder: strings.users.activeUsers.roleSelector.placeholder,
    async onChange(option: OptionDefinition) {
      const newAccessLevel = option?.value as Data.AccessLevel;
      const userId = user.id;
      if (onAccessLevelChange) {
        onAccessLevelChange(userId, newAccessLevel);
      }
    },
  });

  return (
    <Select {...roleSelectProps} defaultValue={initialSelectedOption} isDisabled={!isAdmin || profileId === user.id} />
  );
}
