import { css } from 'emotion';
import React from 'react';
import { atomic } from 'resources/theme';

const Separator: React.FC = () => (
  <div
    className={css`
      height: 0.0625rem;
      background-color: ${atomic.get(atomic.colors.grey3)};
      margin-top: 1.5rem;
      margin-bottom: 2rem;
    `}
  />
);

export default Separator;
