import { Data, UI } from '@taraai/types';
import { AddCommentInput } from 'components/app/controllers/AddCommentInput';
import { css } from 'emotion';
import React, { useCallback, useRef } from 'react';

import Comment from './Comment';
import NoComments from './NoComments';

interface CommentBoxViewProps {
  comments: UI.UIComment[];
  onDeleteComment: (commentId: Data.Id.CommentId) => void;
  showMenuFor?: Data.Id.UserId;
  taskId: Data.Id.TaskId;
}

export default function CommentBoxView({
  comments,
  onDeleteComment,
  showMenuFor,
  taskId,
}: CommentBoxViewProps): JSX.Element {
  const bottomRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = useCallback(() => {
    if (bottomRef && bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [bottomRef]);

  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
        height: 100%;
      `}
    >
      <div
        className={css`
          align-items: center;
          flex: 1 1 auto;
          overflow-y: auto;
          position: relative;
        `}
      >
        {comments.length > 0 ? (
          <ul
            className={css`
              list-style: none;
              margin: 0;
              width: 100%;
            `}
          >
            {comments.map((comment) => (
              <li key={comment.id}>
                <Comment
                  comment={comment}
                  onDeleteComment={onDeleteComment}
                  showMenu={showMenuFor === comment.authorId}
                />
              </li>
            ))}
          </ul>
        ) : (
          <div
            className={css`
              margin-top: 4rem;
            `}
          >
            <NoComments />
          </div>
        )}
        <div ref={bottomRef} />
      </div>
      <footer
        className={css`
          border-top: solid 0.0625rem #dee3ec;
          flex: 0 0 auto;
          width: 100%;
        `}
      >
        <AddCommentInput onCommentAdded={scrollToBottom} taskId={taskId} />
      </footer>
    </div>
  );
}
