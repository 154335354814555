import { unwrapResult } from '@reduxjs/toolkit';
import { Box } from '@taraai/design-system';
import { Attachment, Data, Markdown, Timestamp, UI } from '@taraai/types';
import { parseLabelsFromPlainText, parseMentionsFromPlainText, unique } from '@taraai/utility';
import TaskCommentBox from 'components/app/controllers/TaskCommentBox';
import { TaskNameController } from 'components/app/controllers/TaskNameController';
import TaskModalHeader from 'components/app/controllers/views/TaskModalHeader';
import TaskNavigationCount from 'components/app/controllers/views/TaskNavigationCount';
import TaskRevision from 'components/app/controllers/views/TaskRevision';
import TaskRows from 'components/app/controllers/views/TaskRows';
import Avatar from 'components/core/controllers/views/Avatar';
import Icon from 'components/core/controllers/views/Icon';
import Modal from 'components/core/controllers/views/Modal';
import { makeCopyDocumentTitle } from 'components/editor/utils';
import { EditorAttachments } from 'components/EditorAttachments';
import RemirrorEditor from 'components/remirrorEditor';
import { linkTo } from 'components/Router/paths';
import { css } from 'emotion';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  createTask,
  getPathForTask,
  removeAttachment,
  selectActiveTeam,
  selectActiveWorkspace,
  useAppDispatch,
} from 'reduxStore';
import { TaskGitData } from 'reduxStore/git-task-lifecycle/queries/task-git-data';
import { updateTask } from 'reduxStore/tasks/actions/update';
import { taskModalTestIds } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';
import { toDate, useRerender, useToast } from 'tools';
import moment from 'tools/helpers/moment';
import { segment } from 'tools/libraries/analytics';
import { formatI18n } from 'tools/libraries/helpers/formatI18n';
import { useForceSave } from 'tools/reconciliation/useForceSave';

import { GitTaskData } from './GitTaskData';

// TODO replace with comments loaded from firestore
// TODO: enable when it's ready or moved to feature flag
// const MOCK_COMMENTS = [
//   {
//     startBlockIndex: 0,
//     startOffset: 0,
//     endBlockIndex: 0,
//     endOffset: 5,
//     id: 'comment-1',
//   },
// ];

type Props = {
  author?: UI.UIUser;
  closeModal?: () => void;
  copyTaskId: () => void;
  currentOrg: Data.Id.OrganizationId;
  currentTaskNumber?: number;
  deleteCurrentTask: () => void;
  gitData: TaskGitData | undefined;
  navigationCategory?: string | null;
  noModal?: boolean;
  setTaskCopiedText: (value: boolean) => void;
  task: UI.UITask;
  taskComments?: UI.UIComment[];
  taskCopiedText: boolean;
  taskCount?: number;
  requirementTitle?: string;
  requirementId?: Data.Id.RequirementId;
};

/**
 * TaskModal
 * contains all sub components of the task drawer modal
 *
 */

// eslint-disable-next-line sonarjs/cognitive-complexity
export default function TaskModal({
  author,
  closeModal,
  copyTaskId,
  currentOrg,
  currentTaskNumber,
  deleteCurrentTask,
  gitData,
  navigationCategory,
  noModal,
  setTaskCopiedText,
  task,
  taskComments,
  taskCopiedText,
  taskCount,
  requirementTitle,
  requirementId,
}: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const orgId = useSelector(selectActiveWorkspace);
  const taskId = task.id;
  const getAllTaskLabels = useCallback(
    ({ title = task.title, description = task.description }: { title?: string; description?: string }) =>
      unique([...parseLabelsFromPlainText(title), ...parseLabelsFromPlainText(description)]),
    [task.description, task.title],
  );
  const history = useHistory();
  const teamId = useSelector(selectActiveTeam);
  const { addToast } = useToast();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [editorKey, rerenderEditor] = useRerender();
  const media = window.matchMedia('(max-width:500px)');

  const uploadMarkdown = useCallback(
    async (markdown: Markdown): Promise<string | Error | null> => {
      try {
        await dispatch(
          updateTask({
            description: markdown,
            id: taskId,
            mentionedUserIds: parseMentionsFromPlainText(markdown),
            labels: getAllTaskLabels({ description: markdown }),
          }),
        ).then(unwrapResult);
        return markdown;
      } catch (error) {
        const err = error as Error;
        addToast({ message: err.message, type: 'error' });
        return err;
      }
    },
    [addToast, dispatch, getAllTaskLabels, taskId],
  );

  const { trySave, saveState, forceSave, getLatestOutOfSyncValue } = useForceSave(task.description, uploadMarkdown);

  const createNewDocument = useCallback(async (): Promise<void> => {
    const currentMarkdown = getLatestOutOfSyncValue();
    try {
      await dispatch(
        createTask({
          ...task,
          description: currentMarkdown,
          title: makeCopyDocumentTitle(task.title, task.id, 'task'),
        }),
      )
        .then(unwrapResult)
        .then((taskID) => {
          segment.track('TaskCreated', { orgID: orgId, taskID, location: 'WorkDrawer' });
          history.replace(linkTo('task', { orgId, teamId, taskId: taskID }));
        });
    } catch (error) {
      addToast({ type: 'error', message: strings.task.failedToCreateTask });
    }
  }, [addToast, dispatch, getLatestOutOfSyncValue, history, orgId, task, teamId]);

  const removeOnMobile = {
    '@media (max-width: 500px)': {
      display: 'none',
    },
  };

  const testForHeader = navigationCategory && !media.matches;

  useHotkeys(
    'esc',
    () => {
      closeModal && closeModal();
      return true;
    },
    {},
    [],
  );

  useEffect(() => {
    document.title = strings.formatString(strings.titles.task, { id: task.id, title: task.title }) as string;
    return () => {
      // Restore the original page title when the component unmounts
      document.title = strings.titles.default;
    };
  }, [task.id, task.title]);

  return (
    <Modal
      bodyStyle={css`
        padding: 0rem;
      `}
      className={css`
        border: solid 0.0625rem #dee3ec;
        ${media.matches ? 'width: 20rem; margin: 0 auto;' : 'width: 60rem;'}
      `}
      closeModal={closeModal}
      header={
        <TaskModalHeader
          closeModal={closeModal}
          copyTaskId={copyTaskId}
          createNewDocument={createNewDocument}
          currentOrg={currentOrg}
          deleteCurrentTask={deleteCurrentTask}
          editorState={saveState}
          refreshEditor={rerenderEditor}
          requirementId={requirementId}
          requirementTitle={requirementTitle}
          saveEditor={forceSave}
          setTaskCopiedText={setTaskCopiedText}
          taskCopiedText={taskCopiedText}
          taskId={task.id}
          taskService={task.externalIssue?.service}
          // Not all services have a url nor repository
          taskServiceRepository={task.externalIssue?.service === 'github' ? task.externalIssue?.repository : ''}
          taskServiceUrl={task.externalIssue?.service === 'github' ? task.externalIssue?.url : ''}
        />
      }
      headerStyle={css`
        background-color: #fbfbfd;
        padding: 0.75rem 0.9375rem 0.75rem 1.5rem;
        border-top-left-radius: 0.375rem;
        border-top-right-radius: 0.375rem;
      `}
      modalContainerHeader={
        testForHeader ? (
          <TaskNavigationCount
            closeModal={closeModal}
            currentTaskNumber={currentTaskNumber}
            navigationCategory={navigationCategory}
            taskCount={taskCount}
          />
        ) : undefined
      }
      noExit
      noModal={noModal}
      superHeader={
        <div
          className={css`
            padding-bottom: 1rem;
            font-size: 0.75rem;
            font-weight: 500;
            display: flex;
            justify-content: space-between;
          `}
        >
          <div
            className={css`
              display: flex;
            `}
          >
            {author && <Avatar size='medium' user={author} />}
            <div
              className={css`
                padding-left: 0.5rem;
                padding-top: 0.25rem;
                align-self: center;
              `}
            >
              <div
                className={css`
                  color: ${noModal ? '#303f4b' : '#ffffff'};
                `}
                data-cy={taskModalTestIds.AUTHOR_NAME}
              >
                {author &&
                  formatI18n(strings.task.createdByAuthor)({
                    author: author.name,
                  })}
              </div>
              <div
                className={css`
                  color: #9c9c9c;
                  opacity: 1;
                `}
                data-cy={taskModalTestIds.CREATION_DATE}
              >
                {moment(toDate(task.createdAt)).format('ll')}
              </div>
            </div>
          </div>
          <div
            className={css`
              display: flex;
              align-items: flex-end;
            `}
          >
            {!noModal && (
              <Icon
                className={css`
                  padding: 0px;
                  padding-right: 4px;
                  padding-bottom: 2px;
                `}
                name='history'
              />
            )}
            <div
              className={css`
                align-self: flex-end;
                color: ${noModal ? '#575f65' : '#ffffff'};
                font-style: italic;
                font-size: 12px;
                font-weight: 500;
                opacity: ${noModal ? '0.5' : '0.8'};
              `}
              data-cy={taskModalTestIds.UPDATE_DATE}
            >
              <span
                className={css`
                  text-decoration: underline;
                `}
              >
                {strings.task.edited}
              </span>
              <TaskUpdatedAt updatedAt={task.updatedAt} />
            </div>
          </div>
        </div>
      }
    >
      <div
        className={css`
          display: flex;
        `}
      >
        <div
          className={css`
            border-right: solid 0.0625rem #dee3ec;
            height: calc(100vh - 12rem);
            overflow: auto;
            width: 40rem;
          `}
        >
          <TaskNameController getAllTaskLabels={getAllTaskLabels} taskId={taskId} value={task.title} />
          <TaskDescription
            attachments={task.attachments ?? []}
            description={task.description}
            orgId={orgId}
            taskId={taskId}
            trySave={trySave}
          />
          <TaskRows task={task} />
          <GitTaskData data={gitData} taskId={taskId} />
          <TaskRevision taskId={task.id} />
          {media.matches && <TaskCommentBox taskComments={taskComments} taskId={taskId} />}
        </div>
        <div
          className={css`
            height: calc(100vh - 12rem);
            width: 20rem;
            ${removeOnMobile}
          `}
        >
          <TaskCommentBox taskComments={taskComments} taskId={taskId} />
        </div>
      </div>
    </Modal>
  );
}

function TaskUpdatedAt({ updatedAt }: { updatedAt: Timestamp }): JSX.Element {
  const [now, setNow] = useState(moment());
  const { text, refreshIn } = useMemo(() => {
    const date = moment(toDate(updatedAt));
    // Prevent negative numbers when update happens
    const seconds = Math.max(now.diff(date, 'seconds'), 0);
    const minutes = now.diff(date, 'minutes');
    const hours = now.diff(date, 'hours');
    const days = now.diff(date, 'days');
    switch (true) {
      case minutes < 1:
        return {
          text: formatI18n(strings.task.lastEditedSeconds)({ seconds }),
          refreshIn: Math.max(seconds, 15) * 1000, // twice as many seconds, at least 15
        };
      case hours < 1:
        return {
          text: formatI18n(strings.task.lastEditedMinutes)({ minutes }),
          refreshIn: 60000, // 1m in ms
        };
      case days < 1:
        return {
          text: formatI18n(strings.task.lastEditedHours)({ hours }),
          refreshIn: 3600000, // 1h in ms
        };
      default:
        return {
          text: formatI18n(strings.task.lastEditedDays)({ days }),
          refreshIn: 86400000, // 1 day in ms
        };
    }
  }, [now, updatedAt]);

  useEffect(() => {
    const handle = setInterval(() => {
      setNow(moment());
    }, refreshIn);
    return () => clearInterval(handle);
  }, [refreshIn]);

  return <>{text}</>;
}

function TaskDescription({
  attachments,
  description,
  orgId,
  taskId,
  trySave,
}: {
  attachments: Attachment[];
  description: string;
  orgId: string;
  taskId: string;
  trySave: (value: string) => Promise<string | Error | null>;
}): JSX.Element {
  const dispatch = useAppDispatch();
  const { addToast } = useToast();
  const path = getPathForTask(taskId, orgId);

  const handleAttachmentRemove = useCallback(
    (attachment: Attachment): void => {
      dispatch(removeAttachment({ type: 'task', attachment, path }))
        .then(unwrapResult)
        .catch((error: Error) => {
          const message = formatI18n(strings.attachments.uploadError)({
            errorMessage: error.message,
          });
          addToast({ message, type: 'error' });
        });
    },
    [addToast, dispatch, path],
  );
  const editor = useMemo(
    () => (
      <RemirrorEditor
        id={taskId}
        initialContent={description}
        onSave={trySave}
        placeholder={strings.editor.taskDescriptionPlaceholder}
        type='tasks'
      />
    ),
    [description, taskId, trySave],
  );
  return (
    <>
      <Box>{editor}</Box>
      <EditorAttachments attachments={attachments} onRemove={handleAttachmentRemove} />
    </>
  );
}
