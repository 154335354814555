import styled from '@emotion/styled';
import { makeStyles, Tooltip } from '@material-ui/core';
import { getEntityData } from 'components/editor/entities';
import { DraftDecoratorComponentProps } from 'components/editor/types';
import React from 'react';

import { MentionTooltipContent } from './MentionTooltipContent';

const getTooltipStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    boxShadow:
      '0 9px 28px 8px rgba(0, 0, 0, 0.05), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.18)',
  },
  arrow: {
    color: 'rgba(255, 255, 255, 1)',
  },
}));

const MentionWrapper = styled.span({
  color: '#1d98ff',
});

/**
 * This component decorates Draft.js `mention` entity
 * with Tooltip component that display basic user info.
 */
export default function DraftMention({ children, contentState, entityKey }: DraftDecoratorComponentProps): JSX.Element {
  const userId = getEntityData('mention', contentState, entityKey).id;
  return (
    <Tooltip
      arrow
      classes={getTooltipStyles()}
      interactive
      placement='bottom'
      title={<MentionTooltipContent userId={userId} />}
    >
      <MentionWrapper>{children}</MentionWrapper>
    </Tooltip>
  );
}
