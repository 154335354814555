import { createMutate } from '@taraai/read-write';
import { Data } from '@taraai/types';
import { SprintWrites, TaskWrites } from 'reduxStore/writes';

type RemoveFromSprintPayload = {
  taskId: Data.Id.TaskId;
  sourceSprintId: Data.Id.SprintId;
};

type Globals = { getOrgId: Data.Id.OrganizationId; getUserId: Data.Id.UserId };

export const removeFromSprint = createMutate({
  action: 'RemoveFromSprint',
  read: ({ taskId, sourceSprintId }: RemoveFromSprintPayload, { getOrgId, getUserId }: Globals) => ({
    sprint: { path: `orgs/${getOrgId}/sprints`, id: sourceSprintId },
    task: { path: `orgs/${getOrgId}/tasks`, id: taskId },
    uid: () => getUserId,
  }),
  write: [SprintWrites.removeTask, TaskWrites.removeFromSprint],
});
