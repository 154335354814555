import { Box, Fluid, HStack, Text, VStack } from '@taraai/design-system';
import { StandardSpinner } from 'components/core/controllers/views/Spinners';
import { usePathParams } from 'components/Router/paths';
import React, { useEffect, useState } from 'react';
import deepEquals from 'react-fast-compare';
import { formatDMMM, useToast } from 'tools';

import taraIcon from './images/taraIcon.svg';
import { getSubscriptionDetails, sendToStripCustomerPortal, SubscriptionDocument } from './utils';

export function BillingDetails(): JSX.Element {
  const [details, setDetails] = useState<SubscriptionDocument>();
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToast();
  const { orgId } = usePathParams('workspace');

  useEffect(() => {
    getSubscriptionDetails(orgId)
      .then((data) => {
        if (!deepEquals(data, details)) setDetails(data);
      })
      .catch(() => {
        addToast({ type: 'error', message: 'Error, failed to fetch subscription details', timeoutMs: 3000 });
      });
  });

  const handleGoToSubscriptionDetails = (): void => {
    setIsLoading(true);
    sendToStripCustomerPortal(orgId);
  };

  return (
    <Box color='$white' shadow='$popup' space='$24px' width='$full'>
      {isLoading ? (
        <Box>
          <StandardSpinner fillSpace size='medium' />
        </Box>
      ) : (
        <Box spaceHorz='$24px'>
          <HStack space='$16px'>
            <Box spaceTop='$16px'>
              <img alt='tara icon' src={taraIcon} />
            </Box>
            <Fluid>
              <VStack space='$12px'>
                <Box>
                  <Text color='$dark' size='$16px'>
                    Tara Premium
                  </Text>
                </Box>
                <HStack>
                  <Text size='$14px'>Subscription Type: </Text>
                  <Text color='$dark' size='$14px'>
                    {details?.role}
                  </Text>
                </HStack>
                <Box>
                  <Text size='$14px'>Subscription Cycle: </Text>
                  <Text color='$dark' size='$14px'>
                    {`${details?.items[0].plan.interval}ly`}
                  </Text>
                </Box>
                <Box>
                  <Text size='$14px'>Next billing date: </Text>
                  <Text color='$dark' size='$14px'>
                    {details?.current_period_end && formatDMMM(details?.current_period_end)}
                  </Text>
                </Box>
                <Box>
                  <Text size='$14px'>Total seats: </Text>
                  <Text color='$focus' size='$14px'>
                    {`${details?.quantity} users`}
                  </Text>
                </Box>
              </VStack>
            </Fluid>
            <HStack space='$16px'>
              <VStack space='$16px'>
                <Box spaceTop='$16px'>
                  <a href='https://tara.ai/contact/' rel='noopener noreferrer' target='_blank'>
                    <Box border='$grey5' borderRadius='$4px' center spaceHorz='$16px' spaceVert='$8px'>
                      <Text size='$14px'>Contact Support</Text>
                    </Box>
                  </a>
                </Box>

                <Box.Button
                  border='$grey5'
                  borderRadius='$4px'
                  center
                  onClick={handleGoToSubscriptionDetails}
                  spaceHorz='$16px'
                  spaceVert='$8px'
                >
                  <Text size='$14px'>Update Billing</Text>
                </Box.Button>
              </VStack>
            </HStack>
          </HStack>
        </Box>
      )}
    </Box>
  );
}
