"use strict";
/* eslint-disable security/detect-non-literal-regexp */
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.issueFooterLinkRegExp = exports.labelLinkRegExp = exports.parseLabelsFromPlainText = exports.labelDisplayTextRegExp = exports.markdownLabelIdRegExp = exports.labelIdRegExp = void 0;
var labelIdUnboundRegExp = /(?:\p{Letter}|\p{Number})(?:\p{Letter}|\p{Number}|[._-]){0,49}/u;
exports.labelIdRegExp = new RegExp("^".concat(labelIdUnboundRegExp.source, "$"), 'gu');
exports.markdownLabelIdRegExp = new RegExp(String.raw(templateObject_1 || (templateObject_1 = __makeTemplateObject(["[#tara-label-(", ")]"], ["\\[#tara-label-(", ")\\]"])), labelIdUnboundRegExp.source), 'gu');
exports.labelDisplayTextRegExp = new RegExp("#((?:".concat(labelIdUnboundRegExp.source, ")|)"), 'gu');
function parseLabelsFromPlainText(plainText) {
    return Array.from(plainText.matchAll(exports.markdownLabelIdRegExp), function (_a) {
        var id = _a[1];
        return id;
    });
}
exports.parseLabelsFromPlainText = parseLabelsFromPlainText;
exports.labelLinkRegExp = new RegExp(String.raw(templateObject_2 || (templateObject_2 = __makeTemplateObject(["[(.+)](https://app.tara.ai/(.+)/*/tasks/(.+))"], ["\\[(.+)\\]\\(https:\\/\\/app\\.tara\\.ai\\/(.+)\\/\\*\\/tasks\\/(.+)\\)"]))), 'gu');
exports.issueFooterLinkRegExp = new RegExp(String.raw(templateObject_3 || (templateObject_3 = __makeTemplateObject(["<br /><br />[Tara Task](https://app.tara.ai/(.+)/*/tasks/(.+))"], ["<br \\/><br \\/>\\[Tara Task\\]\\(https:\\/\\/app\\.tara\\.ai\\/(.+)\\/\\*\\/tasks\\/(.+)\\)"]))), 'gu');
var templateObject_1, templateObject_2, templateObject_3;
