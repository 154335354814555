/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import ReactSelect, { ActionMeta, Styles } from 'react-select';
import { SelectComponents } from 'react-select/src/components';
import { atomic } from 'resources/theme';

export interface OptionDefinition {
  label: string;
  value: number | string | null;
}

export interface ReactSelectComponents {
  ClearIndicator?: SelectComponents<OptionDefinition>['ClearIndicator'];
  Control?: SelectComponents<OptionDefinition>['Control'];
  DropdownIndicator?: SelectComponents<OptionDefinition>['DropdownIndicator'];
  DownChevron?: SelectComponents<OptionDefinition>['DownChevron'];
  CrossIcon?: SelectComponents<OptionDefinition>['CrossIcon'];
  Group?: SelectComponents<OptionDefinition>['Group'];
  GroupHeading?: SelectComponents<OptionDefinition>['GroupHeading'];
  IndicatorsContainer?: SelectComponents<OptionDefinition>['IndicatorsContainer'];
  IndicatorSeparator?: SelectComponents<OptionDefinition>['IndicatorSeparator'];
  Input?: SelectComponents<OptionDefinition>['Input'];
  LoadingIndicator?: SelectComponents<OptionDefinition>['LoadingIndicator'];
  Menu?: SelectComponents<OptionDefinition>['Menu'];
  MenuList?: SelectComponents<OptionDefinition>['MenuList'];
  MenuPortal?: SelectComponents<OptionDefinition>['MenuPortal'];
  LoadingMessage?: SelectComponents<OptionDefinition>['LoadingMessage'];
  NoOptionsMessage?: SelectComponents<OptionDefinition>['NoOptionsMessage'];
  MultiValue?: SelectComponents<OptionDefinition>['MultiValue'];
  MultiValueContainer?: SelectComponents<OptionDefinition>['MultiValueContainer'];
  MultiValueLabel?: SelectComponents<OptionDefinition>['MultiValueLabel'];
  MultiValueRemove?: SelectComponents<OptionDefinition>['MultiValueRemove'];
  Option?: SelectComponents<OptionDefinition>['Option'];
  Placeholder?: SelectComponents<OptionDefinition>['Placeholder'];
  SelectContainer?: SelectComponents<OptionDefinition>['SelectContainer'];
  SingleValue?: SelectComponents<OptionDefinition>['SingleValue'];
  ValueContainer?: SelectComponents<OptionDefinition>['ValueContainer'];
}

export interface SelectProps {
  className?: string;
  components?: ReactSelectComponents;
  innerRef?: React.RefObject<ReactSelect>;
  isSearchable?: boolean;
  onBlur?: (event: React.FocusEvent<Element>) => void;
  onChange?: (option: OptionDefinition, action: ActionMeta<OptionDefinition>) => void;
  options: OptionDefinition[];
  placeholder?: string;
  position?: 'left';
  sprintSelectorStyle?: boolean;
  styles?: Styles;
  value?: OptionDefinition | null;
  defaultValue?: OptionDefinition;
  isDisabled?: boolean;
  dataCy?: string;
}

/**
 * A wrapper around `react-select` with Tara's styling as the default
 *
 * - [React Select docs](https://react-select.com)
 * - [Designs](https://zpl.io/bP13lrx)
 */
export default function Select({
  components,
  position,
  dataCy,
  styles,
  sprintSelectorStyle = false,
  innerRef,
  onChange = (): void => undefined,
  ...props
}: SelectProps): JSX.Element {
  const appStyles: Styles = {
    option: (provided, state) => {
      let backgroundColor = atomic.get(atomic.colors.white);
      if (state.isFocused) backgroundColor = '#f9fafc';
      // temporary solution for isSelected state, replace by selection indicator icon
      if (state.isSelected) backgroundColor = atomic.get(atomic.colors.grey2);
      return {
        ...provided,
        color: atomic.get(atomic.colors.dark),
        padding: '0.8rem 1rem',
        fontSize: '0.875rem',
        backgroundColor: `${backgroundColor} !important`,
      };
    },
    container: (provided) => ({
      ...provided,
      borderRadius: '3px',
      border: sprintSelectorStyle ? 'none' : `solid 1px ${atomic.get(atomic.colors.grey5)}`,
      width: sprintSelectorStyle ? '200px' : undefined,
    }),
    control: (provided) => ({
      ...provided,
      border: 'none',
      boxShadow: 'none',
      paddingLeft: sprintSelectorStyle ? '0px' : '0.375rem',
      backgroundColor: 'none',
      cursor: 'pointer',
    }),
    menu: (provided) => ({
      ...provided,
      margin: 0,
      width: sprintSelectorStyle ? '266px' : '100%',
      right: position === 'left' ? '0' : undefined,
    }),
    menuList: (provided) => ({
      ...provided,
      borderRadius: '3px',
      border: `solid 1px ${atomic.get(atomic.colors.grey5)}`,
      boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.14)',
      maxHeight: '15.625rem',
    }),
    input: (provided) => ({
      ...provided,
      fontSize: '0.875rem',
      color: atomic.get(atomic.colors.grey7),
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: '0.875rem',
      color: atomic.get(atomic.colors.dark),
    }),
    valueContainer: (provided) => ({
      ...provided,
      justifyContent: sprintSelectorStyle ? 'flex-end' : undefined,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: sprintSelectorStyle ? '#949caf' : undefined,
      padding: sprintSelectorStyle ? '0px' : '8px',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: atomic.get(atomic.colors.grey7),
      fontSize: '0.875rem',
    }),
    ...styles,
  };
  return (
    <ReactSelect
      {...(props as any)}
      ref={innerRef}
      components={{
        IndicatorSeparator: (): null => null,
        ...components,
      }}
      data-cy={dataCy}
      onChange={onChange}
      styles={appStyles}
    />
  );
}
