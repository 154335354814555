import { createSlice } from '@reduxjs/toolkit';

import { listOrgUserEmails } from './actions/listUserEmails';

const slice = createSlice({
  name: 'organizationUserEmails',
  initialState: {
    orgEmails: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(listOrgUserEmails.fulfilled, (state, { payload }) => {
      state.orgEmails = payload;
    });
  },
});

export const usersWithEmailsReducer = slice.reducer;
