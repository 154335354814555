import { Data, UI } from '@taraai/types';
import DeleteTaskCommentController from 'components/app/controllers/DeleteTaskCommentController';
import CommentBoxView from 'components/app/controllers/views/CommentBoxView';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAuth } from 'reduxStore';

interface TaskCommentBoxProps {
  taskComments?: UI.UIComment[];
  taskId: Data.Id.TaskId;
}

type DeleteCommentModalVisibility = { visible: true; commentId: Data.Id.CommentId } | { visible: false };

export default function TaskCommentBox({ taskComments, taskId }: TaskCommentBoxProps): JSX.Element {
  const { uid: profileId } = useSelector(selectAuth);

  const [deleteCommentModalVisibility, setDeleteCommentModalVisibility] = useState<DeleteCommentModalVisibility>({
    visible: false,
  });

  const showDeleteCommentModal = useCallback(
    (commentId: Data.Id.CommentId): void => setDeleteCommentModalVisibility({ visible: true, commentId }),
    [setDeleteCommentModalVisibility],
  );

  const hideDeleteCommentModal = useCallback(
    (): void => setDeleteCommentModalVisibility({ visible: false }),
    [setDeleteCommentModalVisibility],
  );

  return (
    <>
      <CommentBoxView
        comments={taskComments || []}
        onDeleteComment={showDeleteCommentModal}
        showMenuFor={profileId}
        taskId={taskId}
      />
      {deleteCommentModalVisibility.visible && (
        <DeleteTaskCommentController
          commentId={deleteCommentModalVisibility.commentId}
          onClose={hideDeleteCommentModal}
          taskId={taskId}
        />
      )}
    </>
  );
}
