import { Data } from '@taraai/types';

import { DraggableDescription, DraggableType, DroppableDescription, DroppableType } from './types';

export function getDroppableId(
  sourceId: Data.Id.RequirementId | Data.Id.TaskId | undefined,
  type: DroppableType,
  visibleList: (string | null)[] | undefined,
  list: string[] | undefined,
): string {
  // Responsible for composing a unique serializable string containing all the required
  // information to be able to operate over this element
  return JSON.stringify({
    id: sourceId,
    type,
    visibleList: visibleList && visibleList.length > 0 ? visibleList : null,
    list: list && list.length > 0 ? list : null,
  });
}

export function getDraggableId(
  id: Data.Id.RequirementId | Data.Id.TaskId | undefined,
  type: DraggableType,
  droppableType: DroppableType,
  droppableId?: Data.Id.SprintId,
): string {
  return JSON.stringify({
    id,
    type,
    droppableType,
    droppableId: droppableId ?? '',
  });
}

export function getDroppableDescription(droppableId: string): DroppableDescription {
  return JSON.parse(droppableId);
}

export function getDraggableDescription(draggableId: string): DraggableDescription {
  return JSON.parse(draggableId);
}
