import moment from 'moment';
import { firestore } from 'tools/libraries/firebaseValues';

export function isTimestamp(
  value: moment.MomentInput | FirebaseFirestore.Timestamp,
): value is FirebaseFirestore.Timestamp {
  return value instanceof firestore.Timestamp;
}

export default function taraMoment(
  dateValue: moment.MomentInput | FirebaseFirestore.Timestamp | void,
  ...args: never[]
): moment.Moment {
  if (!dateValue) return moment(Date.now(), ...args);
  return moment(isTimestamp(dateValue) ? dateValue.toDate() : dateValue, ...args);
}
