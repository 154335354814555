/* eslint-disable sonarjs/no-duplicate-string */

import { PluginFunctions, RichEditorPlugin } from 'components/editor/types';
import { EditorState, Modifier } from 'draft-js';
import createBlockBreakoutPlugin from 'draft-js-block-breakout-plugin';
import flow from 'lodash.flow';
import { KeyboardEvent } from 'react';

import { handleKeyCommand } from './handleKeyCommand';
import { keyBindingFn } from './keyBindingFn';

const { handleReturn } = createBlockBreakoutPlugin();

export const createDefaultPlugin = ({
  saveOnReturn,
  singleLine,
}: {
  saveOnReturn?: boolean;
  singleLine?: boolean;
}): RichEditorPlugin<'handleReturn' | 'handleKeyCommand' | 'handlePastedText' | 'keyBindingFn'> => ({
  handleReturn: flow(saveOnReturn ? handleSaveOnReturn : handleReturn, () => (singleLine ? 'handled' : 'not-handled')),
  handleKeyCommand,
  handlePastedText: singleLine ? handleSingleLinePastedText : () => 'not-handled',
  keyBindingFn,
});

const handleSaveOnReturn: RichEditorPlugin['handleReturn'] = (
  event: KeyboardEvent,
  editorState: EditorState,
  { save }: PluginFunctions,
) => {
  if (event.key !== 'Enter') return 'not-handled';
  save(editorState.getCurrentContent());
  return 'handled';
};

const handleSingleLinePastedText: RichEditorPlugin['handlePastedText'] = (
  text,
  html,
  editorState,
  { setEditorState },
) => {
  setEditorState(
    EditorState.push(
      editorState,
      Modifier.replaceText(editorState.getCurrentContent(), editorState.getSelection(), text.replaceAll(/\n/g, ' ')),
      'insert-fragment',
    ),
  );
  return 'handled';
};
