/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable array-callback-return */
import { Box, styled, Text, VStack } from '@taraai/design-system';
import { useFirestoreConnect } from '@taraai/read-write';
import { Data, UI } from '@taraai/types';
import { AddActionBox } from 'components/app/AddActionBox/AddActionBox';
import { SprintOverloadedCard } from 'components/app/controllers/views/SprintOverloadedCard';
import {
  DraggableElement,
  DraggableType,
  DroppableArea,
  DroppableDescription,
  DroppableType,
} from 'components/app/DragAndDrop';
import { useUpgradeModal } from 'components/app/monetization/UpgradeModalContext';
import { TaskCard } from 'components/app/TaskCard';
import { TaskCreationBox } from 'components/app/WorkDrawer/WorkDrawerSections';
import { RichEditorHandle } from 'components/editor/RichEditor';
import { AppCuesWrapper } from 'components/services/AppCues';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import deepEquals from 'react-fast-compare';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import {
  getCompletedSprints,
  getSubscriptionType,
  hasFeature,
  QueryAlias,
  RootState,
  selectActiveTeam,
  selectActiveWorkspace,
  selectSprintDocument,
  selectTasksByParent,
  selectTeam,
  useFilteredSummaryTaskIds,
  useIsSearchActive,
  useIsStatusFilteringActive,
  useSetNextTaskId,
} from 'reduxStore';
import { mergeIncompleteTasks } from 'reduxStore/sprints/actions/reconcile';
import { strings } from 'resources/i18n';
import { GATED_TASK_LIMIT, useListenToEscapeKey, useQueryValue } from 'tools';
import { useShallowMemo } from 'tools/utils/hooks/general';

import { SprintReconcileView } from './SprintReconcileView';

export type TaskFragment = Pick<
  UI.UITask,
  '_relationships' | 'assignee' | 'description' | 'effortLevel' | 'id' | 'labels' | 'status' | 'title' | 'sprint'
>;

interface Accumulator {
  acc: TaskWithOverloadedStatus[];
  usedEffort: number;
}

type TaskWithOverloadedStatus = TaskFragment & {
  overloaded: boolean;
  sprintId: string;
};

interface GroupedTasks {
  [key: string]: TaskWithOverloadedStatus[];
}

type EmptyHelperDescription = {
  type: 'emptyHelper';
  helperText: string;
};

type GroupHeaderDescription = {
  type: 'sprintGroupHeader';
  headerType: string;
  headerTitle: string;
};

type SprintActionsDescription = {
  type: 'sprintActions';
};

type TaskRenderDescription = {
  type: 'tasks';
  task: TaskWithOverloadedStatus;
};

type CarriedOverTaskRenderDescription = {
  type: 'carriedOverTasks';
  task: TaskWithOverloadedStatus;
};

type ElementTypes =
  | GroupHeaderDescription
  | TaskRenderDescription
  | CarriedOverTaskRenderDescription
  | SprintActionsDescription
  | EmptyHelperDescription;

type ElementsList = ElementTypes[];

type Grouped = { [key: string]: UI.UITask[] };
export function useGroupedTasks(tasks: UI.UITask[], prop: keyof UI.UITask): Grouped {
  return tasks.reduce((acc, curr: UI.UITask) => {
    (acc[curr[prop] as string] || []).push(curr);
    return acc;
  }, {} as Grouped);
}

type SprintType = 'active' | 'complete' | 'upcoming';

export function SprintColumnTasksView({
  acceptDrops,
  areFilteredByStatus,
  areFilteredByAssignee,
  effortOverload,
  effortTotal,
  onTaskSelect,
  showSprintOverloadAlerts,
  sprintId,
  taskAlias,
  onTaskCreate,
  showReconcile,
  sprintRequiresAction,
  idx,
  type,
  sprintIsPhantom,
}: {
  acceptDrops: boolean;
  areFilteredByStatus: boolean;
  areFilteredByAssignee: boolean;
  effortOverload?: number;
  effortTotal: number;
  onTaskSelect: (taskId: Data.Id.TaskId) => void;
  showSprintOverloadAlerts: boolean;
  sprintId: string;
  taskAlias: QueryAlias;
  onTaskCreate: (
    title: string,
    sprintId: Data.Id.SprintId,
    insertAtIndex?: number,
    taskIds?: Data.Id.TaskId[],
  ) => Promise<boolean>;
  showReconcile: boolean;
  sprintRequiresAction: Data.SprintRequiredActions | null;
  idx: number;
  type: SprintType;
  sprintIsPhantom?: boolean;
}): JSX.Element {
  const createInlineTaskRef = useRef<RichEditorHandle>(null);
  const typeFromQuery = useQueryValue('type');
  const idFromQuery = useQueryValue('active');
  const selectedTaskId = (typeFromQuery === 'task' && idFromQuery) || undefined;
  const [showCreation, setShowCreation] = useState(false);
  const submitting = useRef(false);
  submitting.current = false;
  const orgId = useSelector(selectActiveWorkspace);
  const teamId = useSelector(selectActiveTeam);
  const currentSprintId =
    useSelector(compose((data) => data?.currentSprintId ?? null, selectTeam(orgId, teamId))) || '';
  const sprint = useSelector((state: RootState) => {
    return selectSprintDocument(state, sprintId);
  }, deepEquals);
  const dispatch = useDispatch();

  const orderedTaskIds = sprint?.orderedTaskIds || [];

  const handleMerge = useCallback(() => {
    dispatch(mergeIncompleteTasks({ id: sprintId }));
  }, [dispatch, sprintId]);

  const memoTasks = useMemo(selectTasksByParent, [sprintId, taskAlias, handleMerge]);
  const tasks = useSelector((state) => memoTasks(state, sprintId, taskAlias, undefined), deepEquals) as
    | TaskWithOverloadedStatus[]
    | undefined;

  // TODO: This is causing a very big delay on the first loading and it is not always required.
  // -> Create a new selector and / or a new component only loaded when the previous sprint is rellevant
  const completedSprintsSlice = getCompletedSprints(orgId, teamId, { orderBy: 'sprintNumber' });
  useFirestoreConnect(Array.from(completedSprintsSlice.query));
  const completedSprints = useSelector(completedSprintsSlice.selector) ?? [];
  const { sprintName: previousSprintName, id: previousSprintId } = completedSprints[0]
    ? completedSprints[0]
    : { sprintName: '', id: '' };

  // Annotate each task with overloaded flag
  const tasksWithOverloadStatus = useMemo<TaskWithOverloadedStatus[]>(() => {
    // For no overloadPoints annotate mark each task as not overloaded
    if (!showSprintOverloadAlerts || effortOverload === undefined) {
      return tasks?.map((task) => ({ ...task, overloaded: false })) ?? [];
    }

    const availableEffort = effortTotal - effortOverload;

    // Annotate each task with overloaded flag
    const results = tasks?.reduce<Accumulator>(
      ({ acc, usedEffort: prevUsedEffort }, task) => {
        const usedEffort = prevUsedEffort + task.effortLevel;
        const taskWithOverloadedStatus = {
          ...task,
          overloaded: usedEffort > availableEffort,
        };

        return { usedEffort, acc: [...acc, taskWithOverloadedStatus] };
      },
      { acc: [] as TaskWithOverloadedStatus[], usedEffort: 0 },
    );
    return results?.acc ?? [];
  }, [effortOverload, effortTotal, showSprintOverloadAlerts, tasks]);

  const areFiltered = areFilteredByStatus || areFilteredByAssignee;

  const searchTaskIds = useFilteredSummaryTaskIds({ sprintId });
  const isSearchActive = useIsSearchActive();
  const isFilteringActive = useIsStatusFilteringActive();
  const isAnyFilteringActive = isSearchActive || isFilteringActive;

  const filteredTasks = isAnyFilteringActive
    ? tasksWithOverloadStatus.filter((task) => searchTaskIds.includes(task.id))
    : tasksWithOverloadStatus;

  const visibleTaskIds = useMemo(() => filteredTasks?.map((task) => task.id) ?? [], [filteredTasks]);

  useSetNextTaskId(visibleTaskIds, selectedTaskId);

  const premiumGatingEnabled = useSelector(hasFeature('premiumGating', orgId));
  const subscriptionType = useSelector(getSubscriptionType(orgId));
  const isGated = subscriptionType === null && premiumGatingEnabled;
  const { handleOpenCloseUpgradeModal, handleModalType } = useUpgradeModal();

  const handleAddClick = useCallback((): void => {
    if (tasks && isGated && GATED_TASK_LIMIT <= tasks?.length) {
      handleModalType('taskLimit');
      handleOpenCloseUpgradeModal();
    } else {
      setShowCreation(true);
    }
  }, [handleModalType, handleOpenCloseUpgradeModal, isGated, tasks]);

  const handleTaskCreation = useCallback(
    (title: string): void => {
      // @TODO: useRef is always null, that's why i'm being forced to use direct DOM helpers.
      // Find a way to make ref works here.
      const sprintColumn = document.getElementById(`columnSprint${sprintId}`);
      if (submitting.current) return;
      submitting.current = true;
      const currentIds = tasks?.map((task) => task.id);
      onTaskCreate(title, sprintId, undefined, currentIds);
      createInlineTaskRef.current?.clear();
      setShowCreation(false);
      submitting.current = false;
      // Scroll again when the last task is added
      setTimeout(() => {
        sprintColumn?.scrollTo(0, 5000);
      }, 2000);
    },
    [onTaskCreate, sprintId, tasks],
  );

  const handleEmptyTaskTitle = useMemo(
    () => (): void => {
      setShowCreation(false);
    },
    [],
  );

  useEffect(() => {
    if (showCreation) createInlineTaskRef.current?.focus();
  }, [showCreation, setShowCreation]);

  // @TODO: Abstract this method
  const groupedList: GroupedTasks = filteredTasks.reduce<GroupedTasks>(
    (acc, curr: TaskWithOverloadedStatus) => {
      const isCarriedOver =
        sprint?.carryOverTaskIds && !sprint.isComplete ? sprint?.carryOverTaskIds.indexOf(curr.id) !== -1 : false;
      return {
        carriedOverTasks: isCarriedOver ? [...acc.carriedOverTasks, curr] : acc.carriedOverTasks,
        tasks: isCarriedOver ? acc.tasks : [...acc.tasks, curr],
      };
    },
    { carriedOverTasks: [], tasks: [] } as GroupedTasks,
  );

  const headerMap: { [key: string]: { headerText: string; emptyHeaderText?: string; emptyText?: string } } = {
    carriedOverTasks: {
      headerText: strings.formatString(strings.sprints.reconcile.carryOver, {
        sprintName: previousSprintName,
      }) as string,
    },
    tasks: {
      headerText: strings.sprints.reconcile.originallyPlanned,
      emptyHeaderText: strings.sprints.reconcile.plannedForThisSprint,
      emptyText: strings.sprints.reconcile.planATaskHelper,
    },
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const taskList: ElementsList = Object.keys(groupedList).reduce((acc: ElementsList, type: string) => {
    const shouldShowActions = acc[acc.length - 1]?.type === 'carriedOverTasks' && type === 'tasks' && showReconcile;

    const isGroupedSprint = sprintId && sprint?.isComplete === false && sprint.id === currentSprintId;

    const shouldShowGroupHeader = (groupedList[type].length || headerMap[type].emptyHeaderText) && isGroupedSprint;
    const shouldShowEmptyHelper = !groupedList[type].length && headerMap[type].emptyHeaderText && isGroupedSprint;

    return [
      ...acc,
      ...(shouldShowActions
        ? [
            {
              type: 'sprintActions',
            } as SprintActionsDescription,
          ]
        : []),
      ...(shouldShowGroupHeader
        ? [
            {
              type: 'sprintGroupHeader',
              headerType: type,
              headerTitle: groupedList[type].length ? headerMap[type].headerText : headerMap[type].emptyHeaderText,
            } as GroupHeaderDescription,
          ]
        : []),
      ...(shouldShowEmptyHelper
        ? [
            {
              type: 'emptyHelper',
              helperText: headerMap[type].emptyText,
            } as EmptyHelperDescription,
          ]
        : groupedList[type].map(
            (task: TaskWithOverloadedStatus) =>
              ({
                type,
                task,
              } as CarriedOverTaskRenderDescription | TaskRenderDescription),
          )),
    ];
  }, [] as ElementsList);

  // Because section headers count as an index and DND uses that index to know
  // exactly where the task was dropped at we have to create a list with ids and,
  // nulls for the onDragEnd to be able to know where the user actually dropped the draggable element.
  const droppableElementslist: (string | null)[] = taskList.map((element: ElementTypes) =>
    element.type === 'tasks' || element.type === 'carriedOverTasks' ? element.task.id : null,
  ) as (string | null)[];

  const droppableDescription: DroppableDescription = useShallowMemo(
    () => ({
      id: sprintId,
      type: DroppableType.sprint,
      visibleList: droppableElementslist,
      list: orderedTaskIds,
    }),
    [sprintId, orderedTaskIds, visibleTaskIds],
  );

  return (
    <VStack>
      <TasksBox background='$white' borderBottomRadius='$2px' full type={type}>
        <TasksBoxScrollArea id={`columnSprint${sprintId}`} space='$1px'>
          {acceptDrops ? (
            <DroppableArea description={droppableDescription} isDropDisabled={!acceptDrops}>
              {() => (
                <>
                  {!sprintIsPhantom &&
                    taskList.length === 0 &&
                    (areFiltered ? (
                      <EmptyGroupHelper content={strings.sprints.sprintColumn.noTasksAfterFiltering} />
                    ) : (
                      <EmptyGroupHelper content={strings.sprints.sprintColumn.noPlannedTasks} />
                    ))}
                  {sprintIsPhantom && <EmptyGroupHelper content={strings.sprints.sprintColumn.planASprintHelper} />}
                  {taskList.length > 0 && (
                    <VStack>
                      {taskList.map(
                        (
                          element:
                            | GroupHeaderDescription
                            | SprintActionsDescription
                            | TaskRenderDescription
                            | CarriedOverTaskRenderDescription
                            | EmptyHelperDescription,
                          index: number,
                        ) => {
                          switch (element.type) {
                            case 'sprintGroupHeader':
                              return (
                                <SprintGroupHeader
                                  headerType={element.headerType}
                                  index={index}
                                  showBorder={element.headerType === 'carriedOverTasks' && showReconcile}
                                  sprintId={sprintId}
                                  title={element.headerTitle}
                                />
                              );
                            case 'carriedOverTasks':
                              return (
                                <CarriedOverTasks
                                  areFilteredByStatus={areFilteredByStatus}
                                  index={index}
                                  onTaskSelect={onTaskSelect}
                                  org={orgId}
                                  overloaded={element.task.overloaded}
                                  showBorder={showReconcile}
                                  sprintId={sprintId}
                                  taskId={element.task.id}
                                />
                              );
                            case 'tasks':
                              return (
                                <TasksWrapper
                                  areFilteredByStatus={areFilteredByStatus}
                                  index={index}
                                  onExitCreation={setShowCreation}
                                  onTaskSelect={onTaskSelect}
                                  org={orgId}
                                  overloaded={element.task.overloaded}
                                  sprintId={sprintId}
                                  taskId={element.task.id}
                                />
                              );
                            case 'sprintActions':
                              return (
                                <SprintReconcileView
                                  handleMerge={handleMerge}
                                  incompletedTasksCount={groupedList.carriedOverTasks.length}
                                  previousSprintId={previousSprintId}
                                  sprintId={sprintId}
                                  sprintRequiresAction={sprintRequiresAction}
                                />
                              );
                            case 'emptyHelper':
                              return idx === 0 ? (
                                <AppCuesWrapper targetName='first-sprint-empty-state'>
                                  <EmptyGroupHelper content={element.helperText} />
                                </AppCuesWrapper>
                              ) : (
                                <EmptyGroupHelper content={element.helperText} />
                              );
                          }
                        },
                      )}
                    </VStack>
                  )}
                </>
              )}
            </DroppableArea>
          ) : (
            <>
              {filteredTasks.length === 0 && areFiltered && (
                <EmptyGroupHelper content={strings.sprints.sprintColumn.noTasksAfterFiltering} />
              )}
              {filteredTasks.length === 0 && !areFiltered && (
                <EmptyGroupHelper content={strings.sprints.sprintColumn.noPlannedTasks} />
              )}
              {filteredTasks.length > 0 && (
                <VStack space='$1px'>
                  {filteredTasks.map((task) => (
                    <TaskCardItem
                      key={task.id}
                      filteredByStatus={areFilteredByStatus}
                      onTaskSelect={onTaskSelect}
                      overloaded={task.overloaded}
                      path={`orgs/${orgId}/tasks`}
                      taskId={task.id}
                    />
                  ))}
                </VStack>
              )}
            </>
          )}
        </TasksBoxScrollArea>
        {showCreation ? (
          <TaskCreationBox
            createInlineTaskRef={createInlineTaskRef}
            handleEmptyTaskTitle={() => handleEmptyTaskTitle}
            handleTaskCreation={(title: string) => handleTaskCreation(title)}
          />
        ) : (
          !sprintIsPhantom && <AddActionBox onClick={handleAddClick} text={strings.tasks.addTask} />
        )}
      </TasksBox>
      {showSprintOverloadAlerts && filteredTasks.length && effortOverload ? (
        <SprintOverloadedCard effortOverload={effortOverload} />
      ) : null}
    </VStack>
  );
}

const TasksBox = styled(
  Box,
  {
    borderWidth: '1px',
  },
  {
    type: {
      active: { borderColor: 'colors.$lavender' },
      complete: { borderColor: 'colors.$successLight' },
      upcoming: { borderColor: 'colors.$grey2' },
    },
  },
);

const TasksBoxScrollArea = styled(VStack, {
  overflowX: 'hidden',
  scrollbarThumb: '$lavender',
  background: 'white',
  maxHeight: '68vh',
});

const DividerWrapper = styled(
  Box,
  {},
  {
    showBorder: {
      true: { borderTop: '1px solid', borderLeft: '1px solid', borderColor: '$indigo', borderRight: '1px solid' },
    },
  },
);
const TaskWrapper = styled(
  Box,
  {},
  { showBorder: { true: { borderLeft: '1px solid', borderColor: '$indigo', borderRight: '1px solid' } } },
);

const TasksWrapper = ({
  taskId,
  index,
  sprintId,
  areFilteredByStatus,
  onTaskSelect,
  overloaded,
  org,
  onExitCreation,
}: {
  taskId: Data.Id.TaskId;
  index: number;
  sprintId: Data.Id.SprintId;
  areFilteredByStatus: boolean;
  onTaskSelect: (taskId: string) => void;
  org: Data.Id.OrganizationId;
  overloaded: boolean;
  onExitCreation: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element => {
  useListenToEscapeKey('Escape', onExitCreation, false);

  return (
    <Box key={taskId}>
      <span>
        <DraggableElement
          key={`Sprint_${taskId}`}
          index={index}
          sourceId={sprintId}
          sourceType={DroppableType.sprint}
          type={DraggableType.sprintTask}
        >
          <TaskCardItem
            key={taskId}
            filteredByStatus={areFilteredByStatus}
            onTaskSelect={onTaskSelect}
            overloaded={overloaded}
            path={`orgs/${org}/tasks`}
            taskId={taskId}
          />
        </DraggableElement>
      </span>
    </Box>
  );
};

const CarriedOverTasks = ({
  taskId,
  showBorder,
  index,
  sprintId,
  areFilteredByStatus,
  onTaskSelect,
  overloaded,
  org,
}: {
  taskId: Data.Id.TaskId;
  showBorder: boolean;
  index: number;
  sprintId: Data.Id.SprintId;
  areFilteredByStatus: boolean;
  onTaskSelect: (taskId: string) => void;
  overloaded: boolean;
  org: Data.Id.OrganizationId;
}): JSX.Element => (
  <TaskWrapper key={taskId} showBorder={showBorder}>
    <span>
      <DraggableElement
        key={`Sprint_${taskId}`}
        index={index}
        sourceId={sprintId}
        sourceType={DroppableType.sprint}
        type={DraggableType.sprintTask}
      >
        <TaskCardItem
          key={taskId}
          filteredByStatus={areFilteredByStatus}
          onTaskSelect={onTaskSelect}
          overloaded={overloaded}
          path={`orgs/${org}/tasks`}
          taskId={taskId}
        />
      </DraggableElement>
    </span>
  </TaskWrapper>
);

const SprintGroupHeader = ({
  title,
  headerType,
  showBorder,
  index,
  sprintId,
}: {
  title: string;
  headerType: string;
  showBorder: boolean;
  index: number;
  sprintId: Data.Id.SprintId;
}): JSX.Element => (
  <DividerWrapper showBorder={showBorder}>
    <DraggableElement
      key={`Header_${headerType}`}
      index={index}
      sourceId={`${sprintId}_${index}`}
      sourceType={DroppableType.sprint}
      type={DraggableType.dragDisabled}
    >
      <Box space='$8px'>
        <Text color='$grey7' size='$10px' uppercase weight='medium'>
          {title}
        </Text>
      </Box>
    </DraggableElement>
  </DividerWrapper>
);

const EmptyGroupHelper = ({ content }: { content: string }): JSX.Element => (
  <Box background='$grey1' center space='$16px'>
    <Text color='$grey6' size='$10px' textAlign='center'>
      {content}
    </Text>
  </Box>
);

function TaskCardItem({
  onTaskSelect,
  overloaded,
  taskId,
  filteredByStatus,
  path,
}: {
  onTaskSelect: (taskId: Data.Id.TaskId) => void;
  overloaded: boolean;
  taskId: Data.Id.TaskId;
  filteredByStatus?: boolean;
  path: Data.Id.TaskId;
}): JSX.Element | null {
  const onClick = useCallback(() => onTaskSelect(taskId), [onTaskSelect, taskId]);
  return (
    <Box.Button key={taskId} onClick={onClick}>
      <TaskCard key={taskId} filteredByStatus={filteredByStatus} id={taskId} overloaded={overloaded} path={path} />
    </Box.Button>
  );
}
