import { keyframes } from '@emotion/core';
import { compose, unwrapResult } from '@reduxjs/toolkit';
import { useFirestoreConnect } from '@taraai/read-write';
import TaskModal from 'components/app/controllers/views/TaskModal/TaskModal';
import Modal from 'components/core/controllers/views/Modal';
import { StandardSpinner } from 'components/core/controllers/views/Spinners';
import { browserHistory } from 'components/Router/history';
import { linkTo } from 'components/Router/paths';
import { css } from 'emotion';
import React, { useCallback, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import {
  archiveTask,
  getRequirement,
  getTask,
  getTaskComments,
  RootState,
  selectActiveTeam,
  selectActiveWorkspace,
  selectUser,
  taskSubTasks,
  useAppDispatch,
} from 'reduxStore';
import { getTaskGitData } from 'reduxStore/git-task-lifecycle/queries/task-git-data';
import { closeTaskModal } from 'reduxStore/modals/actions';
import { strings } from 'resources/i18n';
import { useToast } from 'tools/utils/hooks/useToast';

function TaskModalController(): JSX.Element | null {
  const [initialPath, setInitialPath] = useState('');

  const location = useLocation();
  const history = useHistory();
  const taskId = useSelector((state: RootState) => state.taskModal.showModalForTask || '');

  const dispatch = useAppDispatch();
  const orgId = useSelector(selectActiveWorkspace);
  const { addToast } = useToast();

  const [taskCopiedText, setTaskCopiedText] = useState(false);

  const taskSlice = getTask(orgId, taskId);
  const taskCommentsSlice = getTaskComments(orgId, taskId);
  const taskSubTasksSlice = taskSubTasks(orgId, taskId);
  const taskGitDataSlice = getTaskGitData(orgId, taskId);

  const currentTask = useSelector(taskSlice.selector);
  const author = useSelector(selectUser(orgId, currentTask?.author ?? ''));
  const taskComments = useSelector(taskCommentsSlice.selector)?.filter(({ deletedAt }) => deletedAt === null);
  const gitData = useSelector(taskGitDataSlice.selector);
  const subtasks = useSelector(taskSubTasksSlice.selector) || [];
  const teamId = useSelector(selectActiveTeam);

  const requirementId = currentTask?._relationships?.requirement || '';
  const individualRequirementSlice = getRequirement(orgId, requirementId);
  const requirementFragment = useSelector(
    compose(
      (requirement) =>
        requirement && {
          title: requirement.title,
        },
      individualRequirementSlice.selector,
    ),
  );

  useFirestoreConnect([
    ...taskSlice.query,
    ...taskCommentsSlice.query,
    ...taskSubTasksSlice.query,
    ...taskGitDataSlice.query,
    ...individualRequirementSlice.query,
  ]);

  const cleanURLAndClose = useCallback((): void => {
    history.replace(initialPath);
    dispatch(closeTaskModal());
  }, [dispatch, history, initialPath]);

  /**
   * This use effect is effectively intercepting any react router history and forcing the modal to close
   * instead of going back on the history stack.
   */
  useEffect((): (() => void) => {
    if (currentTask) {
      setInitialPath(location.pathname + location.search);
      browserHistory.pushRoute(linkTo('taskDetails', { orgId, teamId, taskId }));
    }

    window.onpopstate = (): void => cleanURLAndClose();
    return (): null => (window.onpopstate = null);
  }, [cleanURLAndClose, currentTask, dispatch, location.pathname, location.search, orgId, taskId, teamId]);

  const deleteCurrentTask = (): void => {
    if (!currentTask) return;

    const subtasksIds = subtasks.map((subtask) => ({ id: subtask.id }));
    dispatch(archiveTask([...subtasksIds, { id: currentTask.id }]))
      .then(unwrapResult)
      .then(() =>
        addToast({
          message: `${strings.formatString(strings.task.deleted, {
            taskId: currentTask.id,
          })}`,
          timeoutMs: 3500,
          type: 'success',
        }),
      )
      .catch(() => {
        addToast({ type: 'error', message: strings.task.failedToArchiveTask });
      });

    cleanURLAndClose();
  };

  const copyTaskId = (): void => {
    navigator.clipboard.writeText(`${window.location.origin}/${orgId}/*/tasks/${taskId}`);
    setTaskCopiedText(true);
  };

  if (taskId === '') {
    return <></>;
  }
  if (!currentTask) {
    return (
      <Modal
        className={css`
          animation: ${fadeIn} 2s ease-in-out;
        `}
      >
        <StandardSpinner fillSpace size='medium' />
      </Modal>
    );
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <TaskModal
        key={taskId}
        author={author}
        closeModal={cleanURLAndClose}
        copyTaskId={copyTaskId}
        currentOrg={orgId}
        deleteCurrentTask={deleteCurrentTask}
        gitData={gitData}
        navigationCategory='Sprints'
        requirementId={requirementId}
        requirementTitle={requirementFragment?.title}
        setTaskCopiedText={setTaskCopiedText}
        task={currentTask}
        taskComments={taskComments}
        taskCopiedText={taskCopiedText}
      />
    </DndProvider>
  );
}

export default TaskModalController;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  95% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
