import { Box, Fluid, HStack, styled, Text } from '@taraai/design-system';
import Icon from 'components/core/controllers/views/Icon';
import React, { ReactElement } from 'react';
import { IconName } from 'resources';

import { HeaderButton } from './HeaderButton';

type Size = 'small' | 'medium';
interface HeaderProps {
  button?: ReactElement<typeof HeaderButton>;
  icon?: IconName;
  size?: Size;
  title?: string;
}

export function Header({ button, icon, size = 'medium', title }: HeaderProps): JSX.Element {
  return (
    <Box spaceBottom={size === 'medium' ? '$8px' : undefined} spaceTop='$4px'>
      <HStack alignY='center' space='$2px'>
        {icon && <StyledIcon name={icon} />}
        {title && (
          <Text color='$grey6' size='$10px' weight='medium'>
            {title}
          </Text>
        )}
        <Fluid />
        {button}
      </HStack>
    </Box>
  );
}

Header.Button = HeaderButton;

const StyledIcon = styled(Icon, {
  fill: 'none',
  height: '0.75rem', // 12px
  width: '0.75rem', // 12px
  padding: 0,
  color: '$grey6',
});
