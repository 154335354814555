import { useSelector } from 'react-redux';
import { selectProfile } from 'reduxStore';
import { ConnectGitHubAccountData, ConnectSlackAccountData } from 'types/connect-account';
import { Parameter } from 'types/install-integration';

const getParameters = (authToken: string): Parameter[] => {
  return [
    { name: 'authToken', value: authToken },
    { name: 'redirectUrl', value: window.location.href },
  ];
};

export const useConnectGitHubAccount = (): ConnectGitHubAccountData => {
  const profile = useSelector(selectProfile);
  const authToken = profile.token?.token ?? '';

  const accounts = (profile.externalAccounts?.github || []).map((account) => account.username);

  const actionURL = `${process.env.REACT_APP_API_SERVER}oauth/github`;

  return {
    service: 'github',
    actionURL,
    parameters: getParameters(authToken),
    accounts,
    isFeatureEnabled: true,
    saveOrgForSetupIntegration: false,
  };
};

export const useConnectSlackAccount = (): ConnectSlackAccountData => {
  const profile = useSelector(selectProfile);
  const authToken = profile.token?.token ?? '';

  const accounts = (profile.externalAccounts?.slack || []).map((account) => account.name);

  const actionURL = `${process.env.REACT_APP_API_SERVER}oauth/slack`;

  return {
    service: 'slack',
    actionURL,
    parameters: getParameters(authToken),
    accounts,
    isFeatureEnabled: true,
    saveOrgForSetupIntegration: false,
  };
};
