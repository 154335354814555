/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Data, TaskStatus, UI } from '@taraai/types';
import {
  isNonEmptyString,
  markdownLabelIdRegExp,
  markdownMentionIdRegExp,
  notUndefined,
  unique,
} from '@taraai/utility';
import { labelIdToText } from 'components/editor/plugins/labels/common';
import { mentionIdToText } from 'components/editor/plugins/mention/mention';
import Fuse from 'fuse.js';
import { useSelector } from 'react-redux';
import { getGlobalState } from 'reduxStore/middlewares/globalState';
import { selectRequirementSummaries, selectTaskSummaries } from 'reduxStore/utils/selectors';
import { comp, filter, into, map, transduce } from 'transducers-js';

interface FuseOptions {
  ignoreLocation?: boolean;
  threshold?: number;
}

const defaultFuseOptions = {
  ignoreLocation: true,
  // set threshold to 0.3 for better search results
  threshold: 0.3,
};

export type SearchTaskFragment = Pick<UI.UITask, 'id' | 'title' | 'assignee' | 'labels' | 'sprint' | 'status'>;

export type SearchQuerySource = 'label';

export type SearchFilterEnabled = boolean | 'excludeLabels';

export interface SearchQuery {
  text?: string;
  labels?: string[];
  status?: {
    sprint: Data.Id.SprintId;
    status: TaskStatus;
  };
  mentions?: Data.Id.UserId[];
  source?: SearchQuerySource;
}

export interface SearchState {
  query: SearchQuery | undefined;
  matchedTaskIds: Data.Id.TaskId[];
  matchedRequirementIds: Data.Id.RequirementId[];
  tasksBySprint: Record<Data.Id.SprintId, Data.Id.TaskId[]>;
  tasksByRequirement: Record<Data.Id.RequirementId, Data.Id.TaskId[]>;
  tasksByRepository: Record<string, Data.Id.TaskId[]>;
}

const initialState: SearchState = {
  query: undefined,
  matchedTaskIds: [],
  matchedRequirementIds: [],
  tasksBySprint: {},
  tasksByRequirement: {},
  tasksByRepository: {},
};

const selectSearchState = (state: unknown): SearchState => (state as { search: SearchState }).search;

export const selectSearchQuery = createSelector(selectSearchState, ({ query }) => query ?? {});
export const selectTaskMatches = createSelector(selectSearchState, ({ matchedTaskIds }) => matchedTaskIds ?? []);
export const selectTaskBySprint = createSelector(selectSearchState, ({ tasksBySprint }) => tasksBySprint ?? {});
export const selectTaskByRepository = createSelector(
  selectSearchState,
  ({ tasksByRepository }) => tasksByRepository ?? {},
);
export const selectTaskByRequirement = createSelector(
  selectSearchState,
  ({ tasksByRequirement }) => tasksByRequirement ?? {},
);
export const selectRequirementMatches = createSelector(
  selectSearchState,
  ({ matchedRequirementIds }) => matchedRequirementIds ?? [],
);

export const selectSearchCount = createSelector(
  selectSearchState,
  ({ matchedTaskIds, matchedRequirementIds }) => (matchedTaskIds ?? []).length + (matchedRequirementIds ?? []).length,
);

export const selectStatusFilter = createSelector(selectSearchState, ({ query }) => query?.status);
export const selectAssigneeFilter = createSelector(selectSearchState, ({ query }) =>
  (query?.mentions?.length ?? 0) > 0 ? query?.mentions : undefined,
);

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    search: transducerSearch,
    clearStatus: (state) => {
      state.query = undefined;
    },
    clearSearch: () => {
      return initialState;
    },
  },
});

export const searchActions = searchSlice.actions;

export const searchReducer = searchSlice.reducer;

export function useTaskHasMatch(taskId: Data.Id.TaskId): boolean {
  const taskmatches = useSelector(selectTaskMatches);

  const query = useSelector(selectSearchQuery);
  const { text = '', labels = [], mentions = [] } = query ?? {};
  if (query === undefined || ![text, labels, mentions].some((item) => item.length > 0)) {
    return true;
  }

  return taskmatches.includes(taskId);
}

export function useFilteredTaskCount(): number {
  return (useSelector(selectTaskMatches) || []).length;
}

export function useSearchMentions(): Data.Id.UserId[] {
  const searchQuery = useSelector(selectSearchQuery);

  return searchQuery?.mentions || [];
}

export function convertSearchQueryToPlainText(searchQuery: SearchQuery): string {
  const labels = searchQuery?.labels?.map(labelIdToText).join(' ');
  const mentions = searchQuery?.mentions?.map(mentionIdToText).join(' ');
  const text = searchQuery?.text;

  return [labels, mentions, text].filter(notUndefined).join(' ');
}

/* ****************************
          SUMMARIES
***************************** */

export function useIsSearchActive(): boolean {
  const { text, labels, mentions } = useSelector(selectSearchQuery) || {};
  return [text, labels, mentions].some((prop) => prop && prop.length > 0);
}

export function useIsStatusFilteringActive(): boolean {
  const { status } = useSelector(selectSearchQuery) || {};
  return !!status;
}

export function useFilteredRepoTasks(): Data.Id.TaskId[] {
  return unique(Object.values(useSelector(selectTaskByRepository)).flat());
}

export function useFilteredRepos(): Data.Id.ProductId[] {
  return unique(Object.keys(useSelector(selectTaskByRepository)));
}

export function useFilteredSummaryTaskIds({
  requirementId,
  sprintId,
}: {
  requirementId?: Data.Id.RequirementId | null;
  sprintId?: Data.Id.SprintId | null;
}): Data.Id.TaskId[] {
  const taskmatches = useSelector(selectTaskMatches);
  const tasksByRequirement = useSelector(selectTaskByRequirement);
  const tasksBySprint = useSelector(selectTaskBySprint);
  if (requirementId) return tasksByRequirement[requirementId] || [];
  if (sprintId) return tasksBySprint[sprintId] || [];
  return taskmatches;
}

export function useFilteredOrphanTaskIds(): Data.Id.TaskId[] {
  const taskmatches = useSelector(selectTaskMatches);
  const taskWithSprintOrRequirements = Object.values(useSelector(selectTaskByRequirement))
    .flat()
    .concat(Object.values(useSelector(selectTaskBySprint)).flat());
  return taskmatches.filter((taskId) => !taskWithSprintOrRequirements.includes(taskId));
}

export function useFilteredSummaryRequirementIds(): Data.Id.RequirementId[] {
  return useSelector(selectRequirementMatches);
}

/* ****************************
          TRANSDUCERS
***************************** */

/**
 * Universal append and transverse together.
 * - The function MUST only append and drill down.
 * - No allocations or spreading. This is for performanec to save allocs & GC.
 * - If the object's key already exists, it will skip. This allows for
 * non-destructive accumulators to build up.
 * @param mutatable - Object to be mutated
 * @param child - the mutatable array, set or object to append
 * @return a reference to the newly appended item
 */
function conj<Accumulator, Value extends Record<string, unknown> | string[] | Set<string>>(
  mutatable: Accumulator,
  child: Value,
): Value[keyof Value] | Accumulator[keyof Accumulator];
/**
 * Universal append and transverse.
 * @param mutatable - Mutatable Set to be appended
 * @param child - item(s) to append
 * @return the last appended item
 */
function conj<Accumulator extends Set<string>, Value extends string>(mutatable: Accumulator, child: Value[]): Value;
/**
 * Universal append and transverse.
 * @param mutatable - Mutatable array to be appended
 * @param child - item(s) to append
 * @return the last appended item
 */
function conj<Accumulator extends string[], Value>(mutatable: Accumulator, child: Value[]): Value;
function conj(mutatable: unknown, child: unknown[], onlyUniques = true): unknown {
  if (Array.isArray(mutatable)) {
    child.forEach((element) => {
      if (onlyUniques && mutatable.includes(element)) return;
      mutatable.push(element);
    });
    return mutatable[mutatable.length - 1];
  }

  // if (mutatable instanceof Set) {
  //   child.forEach((element) => mutatable.add(element));
  //   return child[child.length - 1];
  // }

  const obj = mutatable as Record<string, unknown>;
  const keys = Object.keys(child);
  keys.forEach((key) => {
    if (!obj[key]) {
      obj[key] = (child as unknown as Record<string, unknown>)[key];
    }
  });
  return obj[keys[0]];
}

const thread =
  (...fncs: ((accumulator: SearchState, task: UI.TaskSummary) => SearchState)[]) =>
  (acc: SearchState, item: UI.TaskSummary) =>
    fncs.reduce((result, fnc) => fnc(result, item), acc);

// ----- Predicates -----

type Predicate = (task: UI.TaskSummary) => boolean;
const keepTask = (): boolean => true;
const getId = ({ id }: { id: string }): string => id;

const statusPredicate = (query: SearchQuery): Predicate => {
  const { sprint, status } = query.status ?? {};
  if (sprint === undefined || status === undefined) return keepTask;
  return (task: UI.TaskSummary) => (task.sprint === sprint ? task.status === status : true);
};

const assigneePredicate = ({ mentions }: SearchQuery): Predicate => {
  if (!(mentions && mentions.length > 0)) return keepTask;
  return (task: UI.TaskSummary) => (task.assignee ? mentions.includes(task.assignee) : false);
};

const labelPredicate = ({ labels }: SearchQuery): Predicate => {
  if (!labels) return keepTask;

  return (task: UI.TaskSummary) => labels.every((label) => task.labels.includes(label));
};

const textPredicate = ({ text }: SearchQuery, fuseOptions?: FuseOptions): Predicate => {
  if (!isNonEmptyString(text) || text === undefined) return keepTask;
  return ({ title }: UI.TaskSummary) => {
    return title
      ? new Fuse([title.replaceAll(markdownLabelIdRegExp, '').replaceAll(markdownMentionIdRegExp, '')], {
          ...defaultFuseOptions,
          ...fuseOptions,
        }).search(text).length !== 0
      : true;
  };
};

// ----- Accumulators -----

const accumulateIndex =
  <Doc extends { id: string }>(indexName: string, prop: keyof Doc) =>
  (acc: SearchState, task: Doc) => {
    const propName = task[prop] as unknown as keyof Omit<SearchState, 'query'> | undefined;
    if (typeof propName !== 'string' || propName === undefined) return acc;

    const innerA = conj(acc, { [indexName]: {} });
    const innerB = conj(innerA, { [propName]: [] });
    conj(innerB, [task.id]);

    return acc;
  };

const uniqueRequirementIds = (init: string[]): ((acc: SearchState, task: UI.TaskSummary) => SearchState) => {
  const ids = Array.from(new Set(init));
  return (acc: SearchState, task: UI.TaskSummary) => {
    if (task.requirement === undefined) return acc;

    const innerA = conj(acc, { matchedRequirementIds: ids });
    conj(innerA, [task.requirement]);
    return acc;
  };
};

// ----- Transducer Search -----

function transducerSearch(state: SearchState, { payload }: PayloadAction<SearchQuery | undefined>): SearchState {
  const rootState = getGlobalState();
  const taskSummaries = selectTaskSummaries(rootState) || [];
  const requirementSummaries = selectRequirementSummaries(rootState) || [];

  const newQuery =
    (payload || {}) &&
    ({
      ...state.query,
      source: undefined, // Reset source
      ...payload,
    } as SearchQuery);

  // transducers will filter one item at a time, skipping when a predicate returns false
  const xfKeepMatches = comp(
    filter(statusPredicate(newQuery)),
    filter(labelPredicate(newQuery)),
    filter(assigneePredicate(newQuery)),
    filter(textPredicate(newQuery)),
  );

  // create accumulators functions
  const byRequirement = accumulateIndex<UI.TaskSummary>('tasksByRequirement', 'requirement');
  const byRepository = accumulateIndex<UI.TaskSummary>('tasksByRepository', 'product');
  const bySprint = accumulateIndex<UI.TaskSummary>('tasksBySprint', 'sprint');
  const uniqueTaskIds = (acc: SearchState, task: UI.TaskSummary): SearchState => {
    const innerA = conj(acc, { matchedTaskIds: [] });
    conj(innerA, [task.id]);
    return acc;
  };

  // do full text search on requirements.
  const matchedRequirementIds = (into as any)(
    [],
    comp(filter(textPredicate(newQuery, { threshold: 0.2 })), map(getId)),
    requirementSummaries,
  );

  // create accumlator for requirement ids and seed with requirement title matches
  const appendTaskRequirementIds = uniqueRequirementIds(matchedRequirementIds);

  // accumulate all indexes for the search
  const accumulate = thread(appendTaskRequirementIds, uniqueTaskIds, byRequirement, byRepository, bySprint);

  return transduce(xfKeepMatches, accumulate, { query: newQuery } as SearchState, taskSummaries);
}
