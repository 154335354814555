import { createAsyncThunk } from '@reduxjs/toolkit';
import { Data } from '@taraai/types';
import { isNonEmptyString } from '@taraai/utility';
import { RootStateWithProfile } from 'reduxStore/store';
import { ExtraAPI } from 'reduxStore/utils/types';
import { strings } from 'resources';

type SetPreferredTeamIdPayload = {
  teamId: Data.Id.TeamId;
};

export const setPreferredTeamId = createAsyncThunk(
  'SetPreferredTeamId',
  async ({ teamId }: SetPreferredTeamIdPayload, { extra, getState }): Promise<void> => {
    const { getFirestore, getUserId, getOrgId } = extra as ExtraAPI;
    const profileId = getUserId(getState() as RootStateWithProfile);
    const orgId = getOrgId();
    const firestore = getFirestore();

    if (!isNonEmptyString(teamId)) {
      throw strings.profile.teams.missingDataError;
    }

    const path = `users/${profileId}`;

    await firestore.update(path, {
      [`preferredTeamIds.${orgId}`]: teamId,
      updatedAt: firestore.Timestamp.now(),
    });
  },
);
