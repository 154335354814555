import { IconElementData, IconGroup as IconGroupCore } from 'components/core/controllers/IconGroup';
import { TLogoSize } from 'components/core/controllers/views/Logo';
import React, { useCallback } from 'react';
import { workspaceTeamsTestIds } from 'resources/cypress/testAttributesValues';

export interface MembersIconGroupProps {
  elements: IconElementData[];
  elementSize?: TLogoSize;
  openPopup: (() => void) | null;
}

export default function IconGroup({ elements, elementSize, openPopup }: MembersIconGroupProps): JSX.Element {
  const showPlusButton = !!openPopup;

  const onClick = useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault();
      event.stopPropagation();

      openPopup?.();
    },
    [openPopup],
  );

  return (
    <IconGroupCore
      dataCyElement={workspaceTeamsTestIds.ADD_MEMBER_BUTTON_ELEMENT}
      elements={elements}
      elementSize={elementSize}
      maxShown={5}
      onCountButtonClick={onClick}
      onElementClick={(_elementId, event): void => onClick(event)}
      onPlusButtonClick={onClick}
      plusIconVariant='fullSize'
      showPlusButton={showPlusButton}
    />
  );
}
