import { compose, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateWithProfile } from 'reduxStore/store';

export type AppSelectionType = 'task' | 'sprint' | 'requirement';

type SelectedState = { type: AppSelectionType; id: string };

type State = {
  selected?: SelectedState;
};

const initialState: State = { selected: undefined };

const appSelectionSlice = createSlice({
  name: 'appSelection',
  initialState,
  reducers: {
    select: (state, { payload: { type, id } }: PayloadAction<{ type: AppSelectionType; id: string }>) => {
      state.selected = { type, id };
    },
  },
});

const appSelectionActions = appSelectionSlice.actions;
export const appSelectionReducer = appSelectionSlice.reducer;

export function useAppIsSelected(type: AppSelectionType, id: string): boolean {
  const select = useMemo(
    () => compose((state): boolean => state.selected?.type === type && state.selected?.id === id, appSelectionState),
    [id, type],
  );
  return useSelector(select);
}

export function useAppSetSelectionState(): (type: AppSelectionType, id: string) => void {
  const dispatch = useDispatch();
  return useCallback(
    (type, id) => {
      dispatch(appSelectionActions.select({ type, id }));
    },
    [dispatch],
  );
}

function appSelectionState(state: RootStateWithProfile): State {
  return state.appSelection;
}
