import { TeamCreateController } from 'components/app/controllers/TeamCreateController';
import React from 'react';

/**
 * TeamCreate
 * route for team creation
 *
 */
export default function TeamCreate(): JSX.Element {
  return <TeamCreateController />;
}
