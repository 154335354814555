import { Data, Timestamp, UI } from '@taraai/types';
import EditSprintBody from 'components/app/controllers/EditSprintBody';
import Button from 'components/core/controllers/views/Button';
import Modal from 'components/core/controllers/views/Modal';
import Text from 'components/core/controllers/views/Text';
import { css } from 'emotion';
import React from 'react';
import { useHistory } from 'react-router';
import { sprintsTabTestIds } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';

export interface UpdateSprintModalProps extends React.HTMLProps<HTMLDialogElement> {
  currentSprint: UI.UISprint;
  currentDuration: Data.SprintDuration;
  onSubmit: () => void;
  setStartAndEndTimestamps: (startDate: Timestamp, endDate: Timestamp) => void;
  setSprintName: React.Dispatch<React.SetStateAction<string>>;
  estimatedEffort: number;
  totalEffort: number;
  allSprintTasks: UI.UITask[] | undefined;
  unassignedSprintTasks: UI.UITask[] | undefined;
  renderNewSprintPreview: (sprintName?: string) => string;
  isStartSprintPage?: boolean;
  inputIsInvalid: boolean;
}

/**
 * UpdateSprintModal
 * reusable modal to be used for start sprint and edit sprint pages
 *
 */
export default function UpdateSprintModal({
  onSubmit,
  currentDuration,
  currentSprint,
  setStartAndEndTimestamps,
  setSprintName,
  estimatedEffort,
  totalEffort,
  allSprintTasks,
  unassignedSprintTasks,
  renderNewSprintPreview,
  isStartSprintPage,
  inputIsInvalid,
  ...props
}: UpdateSprintModalProps): JSX.Element {
  const history = useHistory();

  const submitBtnContent = isStartSprintPage ? strings.sprints.start.start : strings.sprints.edit.save;

  return (
    <Modal
      {...props}
      className={css`
        width: 31.25rem;
      `}
      footer={
        <div
          className={css`
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
          `}
        >
          <Button
            className={css`
              margin-right: 0.8125rem;
              min-height: 2.375rem;
              min-width: 6.25rem;
              padding: 0rem;
            `}
            color='ghost'
            onClick={(): void => history.go(-1)}
          >
            {strings.sprints.edit.cancel}
          </Button>
          <Button
            className={css`
              min-height: 2.375rem;
              min-width: 6.25rem;
              padding: 0rem;
            `}
            data-cy={sprintsTabTestIds.SPRINT_EDIT_MODAL.SAVE_EDIT}
            onClick={onSubmit}
          >
            {submitBtnContent}
          </Button>
        </div>
      }
      header={
        <Text
          className={css`
            color: #303f4b;
            font-size: 1rem;
            font-weight: 500;
          `}
          data-cy={sprintsTabTestIds.SPRINT_EDIT_MODAL.SPRINT_NAME_HEADER}
        >
          {isStartSprintPage
            ? strings.formatString(strings.sprints.start.startSprint, {
                name: currentSprint.sprintName,
              })
            : strings.formatString(strings.sprints.edit.editSprint, {
                name: currentSprint.sprintName,
              })}
        </Text>
      }
    >
      <Text
        className={css`
          color: red;
          font-size: 1rem;
          font-weight: 500;
          padding-bottom: 5rem;
          margin-bottom: 1rem;
        `}
      >
        {inputIsInvalid && strings.sprints.edit.invalidFields}
      </Text>

      <EditSprintBody
        allSprintTasks={allSprintTasks}
        currentDuration={currentDuration}
        currentSprint={currentSprint}
        estimatedEffort={estimatedEffort}
        renderNewSprintPreview={renderNewSprintPreview}
        setSprintName={setSprintName}
        setStartAndEndTimestamps={setStartAndEndTimestamps}
        totalEffort={totalEffort}
        unassignedSprintTasks={unassignedSprintTasks}
      />
    </Modal>
  );
}
