import { isLoaded } from '@taraai/read-write';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectAuth, selectProfile } from 'reduxStore';

// Wrapper that prevents application to be rendered while auth data is not fully loaded from Firebase
export default function AuthIsLoaded({ children }: { children: JSX.Element }): JSX.Element {
  const auth = useSelector(selectAuth);
  const profile = useSelector(selectProfile);

  if (!isLoaded(auth) || !isLoaded(profile)) return <div data-testid='emptydiv' />;

  return children;
}
