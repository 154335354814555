import { Data, UI } from '@taraai/types';
import { getFileExtension } from '@taraai/utility';
import { arrayUnion, doc, getFirestore, Timestamp, writeBatch } from 'firebase/firestore';
import { FirebaseStorage, getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { getUserId } from 'reduxStore/middlewares';
import { reduxStore, RootState } from 'reduxStore/store';
import { strings } from 'resources/i18n';
import { ONE_HUNDRED_MEGABYTES } from 'tools/libraries/helpers/constants';
import { v4 as uuidv4 } from 'uuid';

type AttachableType = 'task' | 'requirement';

export type AddAttachmentPayload = {
  path: string;
  file: File | null;
  id: Data.Id.RequirementId | Data.Id.TaskId;
};

function uploadStorage(firebaseStorage: FirebaseStorage, path: string, file: File): Promise<string> {
  const uniqueFileName = file.name.replace(/(\.[\w-]+)$/i, `-${uuidv4()}`);
  const rawExtension = getFileExtension(file.name);
  const filenameExtension = rawExtension && `.${rawExtension}`;

  const uploadRef = ref(firebaseStorage, path + uniqueFileName + filenameExtension);

  return uploadBytes(uploadRef, file).then(() => {
    return getDownloadURL(ref(uploadRef));
  });
}

export const addAttachmentSansRedux = ({ path, file, id }: AddAttachmentPayload): Promise<string> => {
  const firebaseStorage = getStorage();
  const state = reduxStore.getState() as RootState;
  const userId = getUserId(state);

  const firestore = getFirestore();
  const batch = writeBatch(firestore);

  if (!file) throw strings.attachments.missingAttachment;

  if (file.size > ONE_HUNDRED_MEGABYTES)
    throw strings.formatString(strings.attachments.tooBigError, {
      attachmentSize: ONE_HUNDRED_MEGABYTES / 1000000,
    });

  return uploadStorage(firebaseStorage, path, file).then(async (attachmentURL: string) => {
    const attachments = arrayUnion({
      name: file.name,
      url: attachmentURL,
    });

    const docRef = doc(firestore, path, id);

    const payload = addOrRemoveAttachmentPayload(
      path.indexOf('requirements') !== -1 ? 'requirement' : 'task',
      userId,
      attachments,
    );

    batch.update(docRef, payload);
    await batch.commit();

    return attachmentURL;
  });
};

function addOrRemoveAttachmentPayload(
  type: AttachableType,
  updatedBy: Data.Id.UserId,
  attachments: FirebaseFirestore.FieldValue,
): UI.UITaskAttachmentChangeset | UI.UIRequirementAttachmentChangeset {
  const lastUpdateVia = 'tara';
  const updatedAt = Timestamp.now();

  switch (type) {
    case 'task':
      return { attachments, updatedBy, lastUpdateVia, updatedAt };
    case 'requirement':
      return { attachments, updatedAt };
  }
}
