import { forEachEntityRange } from 'components/editor/entities';
import { DraftDecorator } from 'components/editor/types';

import DraftMention from './DraftMention';

export const mentionTagDecorator: DraftDecorator = {
  strategy: (block, callback, content) => {
    forEachEntityRange('mention', content, block, callback);
  },
  component: DraftMention,
};
