import { styled } from '@taraai/design-system';
import { UI } from '@taraai/types';
import Icon from 'components/core/controllers/views/Icon';
import Logo from 'components/core/controllers/views/Logo';
import React from 'react';
import { navigationDrawerButtons } from 'resources/cypress/testAttributesValues';

type WorkspaceSwitcherNavButtonProps = {
  selectedOrg: UI.UIOrganization;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  children?: React.ReactNode;
  interactive: boolean;
};

export function WorkspaceSwitcherNavButton({
  selectedOrg,
  onClick,
  children,
  interactive,
}: WorkspaceSwitcherNavButtonProps): JSX.Element {
  return (
    <LogoContainer data-cy={navigationDrawerButtons.WORKSPACE_SWITCHER} interactive={interactive} onClick={onClick}>
      <Logo color={selectedOrg.color} name={selectedOrg.name} shape='square' size='small' url={selectedOrg.logoURL} />
      <ArrowIndicator name='arrowDown' />
      {children}
    </LogoContainer>
  );
}

const LogoContainer = styled(
  'button',
  {
    background: 'none',
    border: 'none',
    outline: 'inherit',
    position: 'relative',
    width: '5rem',
    height: '$full',
    boxShadow: '$dividerRight',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  {
    interactive: {
      true: {
        pointerEvents: 'auto',
      },
      false: {
        pointerEvents: 'none',
      },
    },
  },
);
const ArrowIndicator = styled(Icon, { padding: '0rem' });
