import { createTheme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider as EmoticonThemeProvider } from 'emotion-theming';
import React from 'react';

/**
 * The colors from Jess' design system.
 * Some colors are not listed because they had no name associated.
 *
 * Colors:
 * @see https://zpl.io/awPw6Nj
 *
 * More information about usage:
 * @see https://github.com/emotion-js/emotion/tree/master/packages/emotion-theming
 */
export const emoticonTheme = {
  colors: {
    // Grey Swatch
    dark: '#303f4b',
    grey7: '#67728b',
    grey6: '#949caf',
    grey5: '#c8d0df',
    grey4: '#dee3ec',
    grey3: '#eaeef5',
    grey2: '#eef1f7',
    grey1: '#fbfbfd',
    // Primary
    primary: '#4550ce',
    secondary: '#1d98ff',
    // Background
    backgroundDark: '#f4f4fa',
    backgroundLight: '#f7fafc',
    // Error
    error: '#e53935',
    error1: '#ff4d4f',
    error2: '#cf1322',
    error3: '#a8071a',
    // Success
    success: '#389e0d',
    success1: '#ddf4d9',
    success2: '#addea2',
    success3: '#a0d911',
    success4: '#5b8c00',
    success5: '#3f6600',
    // Accent Swatch
    accentBlue: '#3368f4',
    accentPurple: '#9254de',
    accentHotPink: '#e83364',
    accentPink: '#eb2f96',
    accentGold: '#e7b400',
  },
};

// Theming for Material UI
export const muiTheme = createTheme({
  typography: {
    fontFamily: 'Sofia',
  },
});

export interface ThemeProviderProps {
  children: React.ReactNode;
}

/**
 * ThemeProvider combines all theme providers used in the app
 * and return them as a single component.
 */
export default function ThemeProvider({ children }: ThemeProviderProps): JSX.Element {
  return (
    <EmoticonThemeProvider theme={emoticonTheme}>
      <MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>
    </EmoticonThemeProvider>
  );
}
