import { noop } from '@taraai/utility';
import Icon from 'components/core/controllers/views/Icon';
import { FastSmallSpinner } from 'components/core/controllers/views/Spinners';
import { css, cx } from 'emotion';
import React from 'react';
import { IconName } from 'resources';
import { atomic } from 'resources/theme';

export interface NotificationBarProps {
  bgColor: string;
  children: React.ReactChild | React.ReactChild[];
  iconName?: IconName;
  fgColor?: string;
  isLoading?: boolean;
  showCloseBtn?: boolean;
  className?: string;
  onCloseRequest?: () => void;
}

export const NotificationBar = ({
  bgColor,
  children,
  iconName,
  fgColor = atomic.get(atomic.colors.white),
  isLoading = false,
  showCloseBtn = false,
  className,
  onCloseRequest = noop,
}: NotificationBarProps): JSX.Element | null => {
  return (
    <div
      className={cx(
        css`
          position: relative;
          width: 100vw;
          flex: 0 1 2.5rem;
          background: ${bgColor};
          z-index: 3;
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 2.5rem;
        `,
        className,
      )}
    >
      {iconName && <Icon name={iconName} />}
      <div
        className={css`
          font-family: Sofia;
          font-size: 14px;
          font-weight: 500;
          color: ${fgColor};
          padding-top: 2px;
          display: flex;
        `}
      >
        {children}
      </div>
      {isLoading && <FastSmallSpinner color={fgColor} />}
      {showCloseBtn && (
        <Icon
          className={css`
            position: absolute;
            right: 11px;
            padding: 7px;
            width: 12px;
            height: 12px;

            &:hover {
              cursor: pointer;
            }
          `}
          color={fgColor}
          name='close'
          onClick={onCloseRequest}
        />
      )}
    </div>
  );
};
