import { createAsyncThunk } from '@reduxjs/toolkit';
import { UIOrganization, UIOrganizationChangeset, UIOrganizationPartial } from '@taraai/types/dist/ui';
import { decode } from 'reduxStore/utils/decoders';
import { ExtraAPI, Firestore } from 'reduxStore/utils/types';

interface UpdateOrganizationPayload extends UIOrganizationChangeset {
  meta?: {
    transaction?: Firestore['Transaction'];
  };
}

export const updateOrganization = createAsyncThunk(
  'UpdateOrganization',
  async ({ id: orgId, meta, ...propsToSet }: UpdateOrganizationPayload, { extra }) => {
    const { getFirestore } = extra as ExtraAPI;

    const firestore = getFirestore();
    const path = `orgs/${orgId}`;

    const { id, ...valid } = decode<UIOrganizationPartial>(
      {
        id: orgId,
        ...propsToSet,
        updatedAt: firestore.Timestamp.now(),
      },
      'UIOrganizationPartial',
    );

    if (meta?.transaction) {
      meta.transaction.update(firestore.doc(path), valid);
    } else {
      await firestore.update(path, valid);
    }

    return { ...valid, id: orgId } as Partial<UIOrganization>;
  },
);
