import { Validator } from 'tools/utils/hooks/commonTypes';

const validateHex = (color: string): boolean => {
  if (color.length === 0) return false;

  const regex = /^#([\dA-Fa-f]{6}|[\dA-Fa-f]{3})$/;
  return regex.test(String(color).toLowerCase());
};

export const hexColorValidator: Validator = (value: string) =>
  validateHex(value)
    ? Promise.resolve({ isValid: true, message: '' })
    : Promise.resolve({
        isValid: false,
        message: 'Must be a valid hex format',
      });
