import { styled } from '@taraai/design-system';
import Icon from 'components/core/controllers/views/Icon';
import React from 'react';
import { strings } from 'resources/i18n';

interface SprintOverloadedCardProps {
  effortOverload: number;
}

export function SprintOverloadedCard({ effortOverload }: SprintOverloadedCardProps): JSX.Element {
  return (
    <Container>
      <FlexBoxWrapper>
        <SprintOverloadText>
          {strings.formatString(strings.sprints.sprintColumn.sprintOverloadPoints, { effortOverload })}
        </SprintOverloadText>
      </FlexBoxWrapper>
      <FlexBoxWrapper>
        <SmallIcon name='redAlert' />
      </FlexBoxWrapper>
    </Container>
  );
}

const Container = styled('div', {
  alignItems: 'center',
  background: 'rgba(232, 98, 98, 0.15)',
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0.5rem',
  width: 'fit-content',
  margin: '8px auto 0 auto',
});

const SprintOverloadText = styled('div', {
  fontWeight: '600',
  fontSize: '0.625rem',
  color: '$coreRed',
  padding: '0 0 0.125rem 0.1875rem',
  marginRight: '4px',
});

const FlexBoxWrapper = styled('div', { display: 'flex', alignItems: 'center' });

const SmallIcon = styled(Icon, {
  padding: '0rem',
  width: '0.625rem',
  height: '0.625rem',
  color: '$coreRed',
  fill: '$coreRed',
});
