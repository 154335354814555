import { Text } from '@taraai/design-system';
import { SmartText } from 'components/app/controllers/views/SmartText';
import { useCommentConfig } from 'components/editor/config';
import React from 'react';

export default function CommentDraftContent({ content, limit }: { content: string; limit?: number }): JSX.Element {
  return (
    <Text size='$12px'>
      <SmartText config={useCommentConfig()} limit={limit} text={content} />
    </Text>
  );
}
