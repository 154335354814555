import { FeatureFlag } from '@taraai/types/dist/data';
import { useSelector } from 'react-redux';
import { hasFeature, selectActiveWorkspace } from 'reduxStore';

type Props = {
  flag: FeatureFlag;
  /** Component to render when flag is enabled */
  enabled?: JSX.Element;
  /** Component to render when flag is disabled */
  disabled?: JSX.Element;
};

/**
 * Renders different components based on feature flag
 */
export function FeatureFlagCondition({ flag, enabled, disabled }: Props): JSX.Element | null {
  const orgId = useSelector(selectActiveWorkspace);
  const isFlagEnabled = useSelector(hasFeature(flag, orgId));

  return (isFlagEnabled ? enabled : disabled) ?? null;
}
