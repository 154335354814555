import { useMediaQuery } from '@material-ui/core';
import { Box } from '@taraai/design-system';
import { FormProps } from 'components/app/Onboarding/FlowWrapper';
import { Description, Header, NextButton } from 'components/app/Onboarding/Form';
import { FastSmallSpinner } from 'components/core/controllers/views/Spinners';
import dashify from 'dashify';
import React, { useEffect, useRef } from 'react';
import { strings } from 'resources';
import { workspaceSetupTestIds } from 'resources/cypress/testAttributesValues';

import { ChildProps } from './types';
import { WorkspaceNameInput } from './WorkspaceNameInput';
import { WorkspaceURLInput } from './WorkspaceURLInput';

export const Form = ({
  isFormValid,
  isSubmitting,
  orgName,
  organizationNameInputProps,
  organizationUrlInputProps,
  slug,
  setUrl,
  onNext,
}: FormProps<ChildProps>): JSX.Element => {
  const isDesktop = useMediaQuery('(min-width: 600px)');

  const firstUpdate = useRef(true);
  useEffect(() => {
    // Do not run on initial render
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    setUrl(dashify(orgName));
  }, [orgName, setUrl]);

  return (
    <Box>
      <Description>
        <Header>{strings.onboarding.nameWorkspace}</Header>
      </Description>
      <WorkspaceNameInput
        {...organizationNameInputProps}
        data-cy={workspaceSetupTestIds.FIRST_STEP_WORKSPACE_NAME_INPUT}
      />
      <WorkspaceURLInput
        {...organizationUrlInputProps}
        data-cy={workspaceSetupTestIds.FIRST_STEP_WORKSPACE_SLUG_INPUT}
        value={slug}
      />
      <NextButton
        data-cy={workspaceSetupTestIds.FIRST_STEP_NEXT_BUTTON}
        disabled={!isFormValid || isSubmitting}
        onClick={onNext}
        size={isDesktop ? 'default' : 'fullWidth'}
      >
        {isSubmitting ? (
          <>
            <FastSmallSpinner noPadding /> Creating Workspace..
          </>
        ) : (
          'Create Workspace'
        )}
      </NextButton>
    </Box>
  );
};
