import React from 'react';

export const Selected = (
  <svg fill='none' height='6' viewBox='0 0 9 6' width='9' xmlns='http://www.w3.org/2000/svg'>
    <path
      clipRule='evenodd'
      d='M8.5272 0.312461C8.72246 0.507721 8.72246 0.824307 8.5272 1.01957L3.86053 5.68621C3.66526 5.88148 3.34873 5.88148 3.15344 5.68621L0.486777 3.01955C0.291517 2.82428 0.291517 2.50775 0.486777 2.31248C0.682037 2.11721 0.998624 2.11721 1.19388 2.31248L3.507 4.62555L7.82013 0.312461C8.0154 0.117201 8.33193 0.117201 8.5272 0.312461Z'
      fill='#A8A9E7'
      fillRule='evenodd'
    />
  </svg>
);
