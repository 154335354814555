import CompleteSprintController from 'components/app/controllers/CompleteSprintController';
import React from 'react';
/**
 * CompleteSprint does…
 * Modal when completing sprint and moving tasks to next sprint or selected sprint
 * Displays completed tasks out of total tasks
 */
export default function CompleteSprint(): JSX.Element {
  return <CompleteSprintController />;
}
