import { Data } from '@taraai/types';
import { SectionWithLoader } from 'components/app/TaskDetails/common/Section';
import Activity from 'components/core/controllers/views/Activity';
import React from 'react';
import { useSelector } from 'react-redux';
import { getTaskRevision } from 'reduxStore';
import { strings } from 'resources';

type Props = {
  orgId: Data.Id.OrganizationId;
  taskId: Data.Id.TaskId;
};

export function TaskDetailsActivity({ orgId, taskId }: Props): JSX.Element {
  const taskRevisionsSlice = getTaskRevision(orgId, taskId);
  const revisions = useSelector(taskRevisionsSlice.selector);

  return (
    <SectionWithLoader
      content={(revisionsLoaded) => <Activity revisions={revisionsLoaded} />}
      data={revisions}
      noBorder
      title={strings.taskSidebar.modules.activity}
    />
  );
}
