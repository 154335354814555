/* eslint-disable security/detect-non-literal-regexp */

import { markdownMentionIdRegExp } from '@taraai/utility';

const markdownMentionIdWithSpaceRegExp = new RegExp(String.raw`( )?${markdownMentionIdRegExp.source}( )?`, 'gu');

type TaskWithMentions<Task> = Task & { assignee?: string | undefined; collaborators: string[] };

export function extractMentions<Task extends { title: string }>(task: Task): TaskWithMentions<Task> {
  const result: TaskWithMentions<Task> = { ...task, collaborators: [] };
  result.title = task.title.replaceAll(markdownMentionIdWithSpaceRegExp, (match, prefix, id, suffix, offset, title) => {
    if (typeof result.assignee !== 'string') result.assignee = id;
    else result.collaborators.push(id);

    const isAtStartOrEnd = offset === 0 || offset + match.length === title.length;
    return prefix && suffix && !isAtStartOrEnd ? prefix : '';
  });
  return result;
}
