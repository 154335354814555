import ToastProvider from 'components/app/controllers/Toast/ToastProvider';
import { AutoJoinWorkspaceController } from 'components/app/JoinWorkspace';
import React from 'react';

export default function JoinWorkspace(): JSX.Element {
  return (
    <ToastProvider>
      <AutoJoinWorkspaceController />
    </ToastProvider>
  );
}
