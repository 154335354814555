/* eslint-disable sonarjs/no-redundant-boolean */
import { unwrapResult } from '@reduxjs/toolkit';
import { Box, Popup, styled, Text, VStack } from '@taraai/design-system';
import { Data } from '@taraai/types';
import { noop } from '@taraai/utility';
import React from 'react';
import { useSelector } from 'react-redux';
import { deleteSprint, selectActiveTeam, useAppDispatch } from 'reduxStore';
import { VerticalDots } from 'resources/assets/icons/components';
import strings from 'resources/i18n';
import { useToast } from 'tools';

import { useSprintSettingsModal } from './SprintSettingsContext';

type Props = {
  sprintId: Data.Id.SprintId;
  isPhantom: boolean | undefined;
};

export function SprintHeaderPopupMenu({ sprintId, isPhantom }: Props): JSX.Element | null {
  const [showPopup, setShowPopup] = React.useState(false);
  return (
    <Popup
      content={<PopupContent handleClosePopup={() => setShowPopup(false)} isPhantom={isPhantom} sprintId={sprintId} />}
      onHide={() => setShowPopup(false)}
      placement='bottom-end'
      show={showPopup}
    >
      <Box.Button center onClick={() => setShowPopup((prev) => !prev)} width='$24px'>
        {VerticalDots}
      </Box.Button>
    </Popup>
  );
}

type ContentProps = {
  sprintId: Data.Id.SprintId;
  isPhantom: boolean | undefined;
  handleClosePopup: () => void;
};
const PopupContent = ({ sprintId, isPhantom, handleClosePopup }: ContentProps): JSX.Element => {
  const teamId = useSelector(selectActiveTeam);
  const { handleOpenSprintSettings, handleSprintSettingsType, handleSprintSettingId } = useSprintSettingsModal();
  const { addToast } = useToast();
  const dispatch = useAppDispatch();

  const handleDeleteSprint = React.useCallback(() => {
    dispatch(
      deleteSprint({
        id: sprintId,
      }),
    )
      .then(unwrapResult)
      .catch((error: Error) => {
        const message = strings
          .formatString(strings.sprints.deletingFailure, {
            errorMessage: error.message,
          })
          .toString();
        addToast({ message, type: 'error' });
      });
  }, [addToast, dispatch, sprintId]);

  const handleGlobalSprintSettings = React.useCallback(() => {
    handleClosePopup();
    handleSprintSettingsType('globalSprintSettings');
    handleSprintSettingId(teamId);
    handleOpenSprintSettings();
  }, [handleClosePopup, handleOpenSprintSettings, handleSprintSettingId, handleSprintSettingsType, teamId]);

  const handleIndividualSprintSettings = React.useCallback(() => {
    handleClosePopup();
    handleSprintSettingsType('individualSprintSettings');
    handleSprintSettingId(sprintId);
    handleOpenSprintSettings();
  }, [handleClosePopup, handleOpenSprintSettings, handleSprintSettingId, handleSprintSettingsType, sprintId]);
  return (
    <Container center='true' space='$12px' width='$fitContent'>
      <VStack space='$16px'>
        {/* TODO: Disable sprint complete for now, requires more dev time */}
        {false && (
          <Box.Button
            background='$failure'
            borderRadius='$4px'
            center
            onClick={() => noop}
            spaceHorz='$8px'
            spaceVert='$4px'
          >
            <Text color='$white' size='$12px' weight='bold'>
              Complete this sprint
            </Text>
          </Box.Button>
        )}
        {!isPhantom && (
          <Box.Button onClick={handleIndividualSprintSettings} spaceLeft='$4px'>
            <Text color='$coreGrey' size='$14px'>
              Edit this sprint
            </Text>
          </Box.Button>
        )}
        {/* TODO: Disable sprint deletion for now, a bit buggy */}
        {false && (
          <Box.Button onClick={handleDeleteSprint} spaceLeft='$4px'>
            <Text color='$coreGrey' size='$14px'>
              Delete this sprint
            </Text>
          </Box.Button>
        )}

        <Box.Button onClick={handleGlobalSprintSettings} spaceLeft='$4px'>
          <Text color='$coreGrey' size='$14px'>
            Edit global sprint settings
          </Text>
        </Box.Button>
      </VStack>
    </Container>
  );
};

const Container = styled(Box, {
  zIndex: 7,
  backgroundColor: '$white',
  border: 'borderWidths.$1px solid colors.$grey5',
  borderRadius: '0.1875rem',
  boxShadow: '$popup',
  cursor: 'default',
  outline: 'none',
  pointerEvents: 'auto',
});
