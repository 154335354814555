import { Data } from '@taraai/types';
import InvitedUsersEntry from 'components/app/controllers/views/InvitedUsersEntry';
import InvitedUsersList from 'components/app/controllers/views/InvitedUsersList';
import React from 'react';

export interface InvitedUsersLayoutProps {
  invitations: Pick<Data.OneOffInvitation, 'email' | 'message'>[];
  onResendButtonClick: (email: string, message: string) => void;
  resendingInProgressMap: { [inviteeEmail: string]: boolean };
}

export default function InvitedUsersLayout({
  invitations,
  onResendButtonClick,
  resendingInProgressMap,
}: InvitedUsersLayoutProps): JSX.Element {
  return (
    <div>
      <InvitedUsersList>
        {invitations.map((invitation) => (
          <InvitedUsersEntry
            key={invitation.email}
            invitation={invitation}
            isResendingInProgress={resendingInProgressMap[invitation.email]}
            onResendButtonClick={onResendButtonClick}
          />
        ))}
      </InvitedUsersList>
    </div>
  );
}
