import { Box, Fluid, HStack, styled, Text, VStack } from '@taraai/design-system';
import Icon from 'components/core/controllers/views/Icon';
import { StandardSpinner } from 'components/core/controllers/views/Spinners';
import { AppCuesWrapper } from 'components/services/AppCues';
import React from 'react';
import { IconName } from 'resources';

export interface IntegrationBoxViewProps extends React.HTMLProps<HTMLDivElement> {
  icon: JSX.Element | IconName;
  iconColor?: string;
  title: string;
  description: string | JSX.Element;
  actionButton: JSX.Element;
  children?: JSX.Element | null;
  isLoading: boolean;
  service?: string;
}

const IntegrationBoxView = ({
  icon,
  iconColor = '',
  title,
  description,
  actionButton,
  children,
  isLoading,
  service,
}: IntegrationBoxViewProps): JSX.Element => (
  <Box spaceBottom='$24px'>
    <VStack space='$16px'>
      <AppCuesWrapper targetName={`${service ?? title}-integration-container`}>
        <Box background='$white' shadow='$card' spaceHorz='$16px' spaceVert='$24px'>
          <VStack space='$12px'>
            <HStack alignY='center' space='$8px'>
              {typeof icon === 'string' ? <IntegrationIcon color={iconColor} name={icon} /> : icon}
              <Fluid>
                <VStack>
                  <Text color='$dark' size='$16px'>
                    {title}
                  </Text>
                  <Subtitle size='$14px'>{description}</Subtitle>
                </VStack>
              </Fluid>
              {isLoading ? <IntegrationSpinner size='small' /> : actionButton}
            </HStack>
            {children}
          </VStack>
        </Box>
      </AppCuesWrapper>
    </VStack>
  </Box>
);

export default IntegrationBoxView;

const Subtitle = styled(Text, { color: '#575f65' });

const IntegrationSpinner = styled(StandardSpinner, { marginRight: '2.75em' });

const IntegrationIcon = styled(Icon, { height: '2rem', width: '2rem' });
