// Custom Tara Tags tranformation to HTML
/** ************************************
 *
 *  The marked configuration is taken from here:
 *      https://github.com/remirror/remirror/blob/main/packages/remirror__extension-markdown/src/markdown-to-html.ts
 *
 *  If there is any issue with the mapping / translation between
 *  HTML and MARKDOWN, first update this document with the latest code in that file.
 *
 ************************************ */
import { markdownLabelIdRegExp, markdownMentionIdRegExp } from '@taraai/utility';
import { marked } from 'marked';
import { MentionExtensionAttributes } from 'remirror/extensions';

marked.use({
  renderer: {
    list(body: string, isOrdered: boolean, start: number): string {
      if (isOrdered) {
        const startAttr = start !== 1 ? `start="${start}"` : '';
        return `<ol ${startAttr}>\n${body}</ol>\n`;
      }

      const taskListAttr = body.startsWith('<li data-task-list-item ') ? 'data-task-list' : '';
      return `<ul ${taskListAttr}>\n${body}</ul>\n`;
    },
    listitem(text: string, isTask: boolean, isChecked: boolean): string {
      if (!isTask) {
        return `<li>${text}</li>\n`;
      }

      const checkedAttr = isChecked ? 'data-checked' : '';
      return `<li data-task-list-item ${checkedAttr}>${text}</li>\n`;
    },
  },
});

/* eslint-disable no-loops/no-loops */
export const markdownToHTML = (
  initialContent: string,
  allUsers: MentionExtensionAttributes[],
  allLabels?: MentionExtensionAttributes[],
  sanitizer?: (html: string) => string,
): string => {
  let ret = marked(initialContent, { gfm: true, smartLists: true, xhtml: true, sanitizer });

  const mentionMatches = Array.from(initialContent.matchAll(markdownMentionIdRegExp));
  for (let index = 0; index < mentionMatches.length; index++) {
    const userId = mentionMatches[index][1];
    const user = allUsers.find((element) => element.id === userId);

    if (user) {
      ret = ret.replaceAll(
        mentionMatches[index][0],
        `<span type="suggested" class="remirror-mention-atom remirror-mention-atom-at ProseMirror-selectednode" data-mention-atom-id="${user.id}" data-mention-atom-name="at" contenteditable="false" draggable="true">@${user.label}</span>`,
      );
    }
  }

  if (allLabels) {
    const labelMatches = Array.from(initialContent.matchAll(markdownLabelIdRegExp));
    for (let index = 0; index < labelMatches.length; index++) {
      const userId = labelMatches[index][1];
      const label = allLabels.find((element) => element.id === userId);
      if (label)
        ret = ret.replaceAll(
          labelMatches[index][0],
          `<span type="suggested" class="remirror-mention-atom remirror-mention-atom-at ProseMirror-selectednode" data-mention-atom-id="${label.id}" data-mention-atom-name="hash" contenteditable="false" draggable="true">#${label.label}</span>`,
        );
    }
  }

  // Matching all the markdown images with the format: ![image](URL)
  const matchImagesRegExp = /!\[image]\([^)]*\)/gi;
  const imageMatches = Array.from(initialContent.matchAll(matchImagesRegExp));
  if (imageMatches) {
    for (let index = 0; index < imageMatches.length; index++) {
      const image = imageMatches[index][0];
      // Matches the URL with parenthesis
      const url = image.match(/\([^)]*\)/i);
      if (url) {
        // Replaces only the URL
        const src = url[0].replace(/\(|\)/g, '');
        ret = ret.replace(
          imageMatches[index][0],
          `<img src="${src}" style="width: 100%; min-width: 50px; object-fit: contain;">`,
        );
      }
    }
  }

  return ret;
};
