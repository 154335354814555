import ToastProvider from 'components/app/controllers/Toast/ToastProvider';
import { OnboardingController } from 'components/app/Onboarding';
import React from 'react';

export default function Onboarding(): JSX.Element {
  return (
    <ToastProvider>
      <OnboardingController />
    </ToastProvider>
  );
}
