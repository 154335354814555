/* eslint-disable @typescript-eslint/no-shadow */
import { UI } from '@taraai/types';
import { markdownLabelIdRegExp } from '@taraai/utility';
import { createEntity, getEntityData, getEntityRanges, getTextMatches } from 'components/editor/entities';
import { ContentState, Modifier, SelectionState } from 'draft-js';

import { labelIdToText, labelTitleToDisplayText, textToLabel } from './common';

export function getTextToLabels(getLabels: () => UI.UILabel[]): (source: ContentState) => ContentState {
  return function textToLabels(source: ContentState): ContentState {
    const labelMap = new Map(getLabels().map((label) => [label.id, label]));

    return source.getBlocksAsArray().reduce(
      (content, block) =>
        getTextMatches(markdownLabelIdRegExp, block).reduceRight((content, [start, end, text]) => {
          const label = textToLabel(text, labelMap);
          if (!label) {
            return content;
          }
          const { contentWithEntity, key } = createEntity('label', label, content);
          return Modifier.replaceText(
            contentWithEntity,
            SelectionState.createEmpty(block.getKey()).merge({
              anchorOffset: start,
              focusOffset: end,
            }) as SelectionState,
            labelTitleToDisplayText(label.title),
            undefined,
            key,
          );
        }, content),
      source,
    );
  };
}

export function labelsToText(source: ContentState): ContentState {
  return source.getBlocksAsArray().reduce(
    (content, block) =>
      getEntityRanges('label', content, block).reduceRight(
        (content, [start, end]) =>
          Modifier.replaceText(
            content,
            SelectionState.createEmpty(block.getKey()).merge({
              anchorOffset: start,
              focusOffset: end,
            }) as SelectionState,
            labelIdToText(getEntityData('label', content, block.getEntityAt(start)).id),
          ),
        content,
      ),
    source,
  );
}
