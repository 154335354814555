"use strict";
/**
 * ExternalRepo stanza describes a repository linked to any document related to
 * repository-specific entities (pull request, branch, commit, etc.)
 *
 * It is available on frontend, so it can't contain any secrets.
 *
 * Supported services:
 *   - github
 *
 * Potentially supported services:
 *   - bitbucket
 */
Object.defineProperty(exports, "__esModule", { value: true });
