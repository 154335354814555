import { Box, HStack, styled, Text, VStack } from '@taraai/design-system';
import { Data } from '@taraai/types';
import Icon from 'components/core/controllers/views/Icon';
import React, { useCallback } from 'react';
import { useAppDispatch } from 'reduxStore';
import { dismissReconcile, moveBackIncompleteTasks } from 'reduxStore/sprints/actions/reconcile';
import { strings } from 'resources/i18n';
import { useToast } from 'tools';

interface Props {
  incompletedTasksCount: number;
  previousSprintId: Data.Id.SprintId;
  sprintId: Data.Id.SprintId;
  sprintRequiresAction: Data.SprintRequiredActions | null;
  handleMerge: () => void;
}

export function SprintReconcileView({
  incompletedTasksCount,
  previousSprintId,
  sprintId,
  sprintRequiresAction,
  handleMerge,
}: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const { whenError } = useToast();
  // @TODO: Move all this handlers to the parent component
  const handleMoveBack = useCallback(() => {
    dispatch(moveBackIncompleteTasks({ currentSprintId: sprintId, previousSprintId })).catch(
      whenError((err) => `${strings.sprints.error.updateFailure} ${err.message}`),
    );
  }, [dispatch, sprintId, previousSprintId, whenError]);

  const handleDismiss = useCallback(() => {
    dispatch(dismissReconcile({ id: sprintId, previousRequiresAction: sprintRequiresAction })).catch(
      whenError((err) => `${strings.sprints.error.updateFailure} ${err.message}`),
    );
  }, [dispatch, sprintId, sprintRequiresAction, whenError]);

  return (
    <Box background='$indigo' space='$1px'>
      <VStack space='$1px'>
        <HStack>
          <SprintHeader color='$white' size='$12px' textAlign='center' type='main' weight='bold'>
            {strings.formatString(strings.sprints.reconcile.incompletedTasks, {
              tasksCount: incompletedTasksCount,
            })}
          </SprintHeader>
          <Box.Button onClick={handleDismiss}>
            <Icon name='cross' stroke='white' />
          </Box.Button>
        </HStack>
        <HStack>
          <ButtonContainer center spaceLeft='$8px' spaceRight='$4px'>
            <Box.Button
              background='$white'
              borderRadius='$4px'
              center
              height='$24px'
              onClick={handleMoveBack}
              spaceBottom='$4px'
              spaceHorz='$12px'
              spaceTop='$4px'
              width='$full'
            >
              <HStack space='$4px'>
                <Box>{moveBackIcon}</Box>
                <Text color='$indigo' size='$10px' weight='bold'>
                  {strings.sprints.reconcile.moveBack}
                </Text>
              </HStack>
            </Box.Button>
          </ButtonContainer>
          <ButtonContainer center spaceLeft='$4px' spaceRight='$16px' spaceVert='$8px'>
            <Box.Button
              background='$white'
              borderRadius='$4px'
              center
              height='$24px'
              onClick={handleMerge}
              spaceBottom='$4px'
              spaceHorz='$8px'
              spaceTop='$4px'
              width='$full'
            >
              <HStack space='$8px'>
                <Box>{mergeIntoSprintIcon}</Box>
                <Text color='$indigo' size='$10px' weight='bold'>
                  {strings.sprints.reconcile.mergeIntoSprint}
                </Text>
              </HStack>
            </Box.Button>
          </ButtonContainer>
        </HStack>
      </VStack>
    </Box>
  );
}

const ButtonContainer = styled(Box, {
  flex: '0 0 50%',
});

const SprintHeader = styled(
  Text,
  {
    display: 'flex',
    order: '0',
    flexGrow: '1',
    borderRadius: '$4px $4px $none $none',
  },
  {
    type: {
      main: {
        padding: '$8px',
      },
      subHeader: { marginLeft: '$24px' },
    },
  },
);

const moveBackIcon = (
  <svg fill='none' height='14' viewBox='0 0 13 12' width='15' xmlns='http://www.w3.org/2000/svg'>
    <rect fill='#4D5BFF' height='5' rx='1' transform='rotate(-90 1 12)' width='12' x='1' y='12' />
    <rect fill='#4D5BFF' height='5' opacity='0.2' rx='1' transform='rotate(-90 7 12)' width='12' x='7' y='12' />
  </svg>
);

const mergeIntoSprintIcon = (
  <svg fill='none' height='14' viewBox='0 0 15 14' width='15' xmlns='http://www.w3.org/2000/svg'>
    <rect fill='#4D5BFF' height='4' rx='1' width='12' x='1.5' />
    <rect fill='#4D5BFF' height='4' opacity='0.2' rx='1' width='12' x='1.5' y='5' />
    <rect fill='#4D5BFF' height='4' opacity='0.2' rx='1' width='12' x='1.5' y='10' />
  </svg>
);
