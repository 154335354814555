import { Fluid, HStack, Tooltip } from '@taraai/design-system';
import Input from 'components/core/controllers/views/Input';
import { PopupButton, PopupIcon } from 'components/editor/plugins/shared/PopupButton';
import { css } from 'emotion';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { strings } from 'resources/i18n';

interface Props {
  initialUrl: string;
  onEditLink: (url: string) => void;
  onRemove: () => void;
}

export function LinkPopup({ initialUrl, onEditLink, onRemove }: Props): JSX.Element {
  const inputRef = useRef<HTMLInputElement>(null);
  const [url, setUrl] = useState(initialUrl);

  const savedRef = useRef(false);
  const saveOnce = useCallback(
    (nextUrl: string) => {
      if (savedRef.current) return;
      savedRef.current = true;
      onEditLink(nextUrl);
    },
    [onEditLink],
  );

  const handleKeyDown = (event: React.KeyboardEvent): void => {
    // event.code === 'Enter'
    if (event.keyCode === 13) {
      event.preventDefault();
      event.stopPropagation();
      saveOnce(url);
    }
    // event.code === 'Escape'
    if (event.keyCode === 27) {
      event.preventDefault();
      event.stopPropagation();
      saveOnce(initialUrl);
    }
  };

  // Prevent the effect going off before each render
  const urlRef = useRef(url);
  urlRef.current = url;
  useEffect(() => {
    inputRef.current?.focus();
    return () => {
      if (urlRef.current !== initialUrl) saveOnce(urlRef.current);
    };
  }, [initialUrl, saveOnce]);

  return (
    <HStack alignY='center' space='$16px'>
      <Fluid>
        <Input
          Ref={inputRef}
          naked
          onChange={(event: { target: { value: string } }) => setUrl(event.target.value)}
          onKeyDown={handleKeyDown}
          style={{
            container: css`
              height: 2rem;
            `,
            main: css`
              padding: 0;
            `,
          }}
          value={url}
        />
      </Fluid>
      <Tooltip title={strings.editor.links.goTo}>
        <a href={url} rel='noopener noreferrer' target='_blank'>
          <PopupIcon name='link' />
        </a>
      </Tooltip>
      <PopupButton
        iconName='linkcopy'
        onClick={() => navigator.clipboard.writeText(url)}
        title={strings.editor.links.copy}
      />
      <PopupButton iconName='linkremove' onClick={onRemove} title={strings.editor.links.remove} />
    </HStack>
  );
}
