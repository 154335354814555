import { Box, Text } from '@taraai/design-system';
import React from 'react';
import { strings } from 'resources';

export const Header = (): JSX.Element => (
  <Box background='$white' space='$16px'>
    <Text color='$dark' size='$14px' weight='medium'>
      {strings.sprintSettings.editSprintSettings}
    </Text>
  </Box>
);
