import { compose, unwrapResult } from '@reduxjs/toolkit';
import { isNonEmptyString } from '@taraai/utility';
import WorkspaceLayout from 'components/app/layouts/WorkspaceLayout';
import { usePathParams } from 'components/Router/paths';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  getUsersWithAccessLevels,
  hasFeature,
  isProfileAdmin,
  listOrganizationUsers,
  RootState,
  selectActiveOrgIds,
  updateOrganization,
  useAppDispatch,
} from 'reduxStore';
import { selectOrganization } from 'reduxStore/organization/selectors';
import { strings } from 'resources';
import { useFirebaseInput, useToast } from 'tools';

export default function WorkspaceController(): JSX.Element {
  const dispatch = useAppDispatch();
  const { orgId, teamId } = usePathParams('workspace');
  const org = useSelector(selectOrganization(orgId));
  const orgStanza = { id: orgId, ...org };
  const orgURL = `${window.location.host}/${orgId}`;

  const isAdmin = useSelector(isProfileAdmin(orgId));
  const workspaceCount = useSelector(compose((data) => data?.length, selectActiveOrgIds));
  const isGitlabInsightsEnabled = useSelector(hasFeature('gitlabInsights', orgId));

  const { addToast } = useToast();

  const usersWithAccessLevels = useSelector((state: RootState) => getUsersWithAccessLevels(state, orgId));

  const membersCount = Object.values(usersWithAccessLevels ?? {}).filter(
    (user) => user.accessLevel !== 'deactivated',
  ).length;

  const [nameProps] = useFirebaseInput(org.name, (newName: string) => {
    if (!isNonEmptyString(newName)) {
      addToast({
        type: 'error',
        message: strings.workspace.name.cantBeBlank,
        timeoutMs: 2500,
      });
      return;
    }
    dispatch(updateOrganization({ id: orgId, name: newName }))
      .then(unwrapResult)
      .then(() =>
        addToast({
          type: 'success',
          timeoutMs: 3500,
          message: strings.formatString(strings.workspace.name.success, {
            name: newName,
          }) as string,
        }),
      )
      .catch(() => {
        addToast({
          type: 'error',
          message: strings.workspace.name.couldntChange,
        });
      });
  });

  useEffect(() => {
    async function dispatchListOrganizationUsers(): Promise<void> {
      try {
        await dispatch(listOrganizationUsers());
      } catch {
        addToast({
          message: strings.users.activeUsers.toasts.error,
          timeoutMs: 3500,
          type: 'error',
        });
      }
    }
    dispatchListOrganizationUsers();
  }, [addToast, dispatch, orgId]);

  return (
    <WorkspaceLayout
      canLeave={workspaceCount > 1}
      isAdmin={isAdmin}
      isGitlabInsightsEnabled={isGitlabInsightsEnabled}
      membersCount={membersCount}
      nameProps={nameProps}
      org={orgStanza}
      orgURL={orgURL}
      teamId={teamId}
    />
  );
}
