import { styled } from '@taraai/design-system';
import { AccentedText } from 'components/app/AccentedText';
import { ReduxLabelChip } from 'components/app/controllers/ReduxLabelChip';
import { forEachRange, getEntityData } from 'components/editor/entities';
import { parseText } from 'components/editor/RichEditorProvider';
import { RichEditorConfig } from 'components/editor/types';
import { ContentBlock, ContentState } from 'draft-js';
import flow from 'lodash.flow';
import identity from 'lodash.identity';
import React, { cloneElement, Key, ReactNode, useMemo } from 'react';

interface Props {
  config: RichEditorConfig;
  filterLabelsOnClick?: boolean;
  limit?: number;
  text?: string;
}

/**
 * SmartText
 * For displaying the Draft.js content without using the full editor
 */
export function SmartText({
  config: { parseMode, plugin },
  filterLabelsOnClick = false,
  limit = Infinity,
  text = '',
}: Props): JSX.Element {
  const smartText = useMemo(
    () =>
      flow(
        (textToParse) => parseText(parseMode, textToParse),
        (content) => plugin?.read?.(content) ?? content,
        (content) => contentToChildren({ filterLabelsOnClick, limit }, content),
      )(text),
    [filterLabelsOnClick, limit, parseMode, plugin, text],
  );

  return <DraftEditorBorder>{smartText}</DraftEditorBorder>;
}

type Context = {
  filterLabelsOnClick: boolean;
  limit: number;
};

function contentToChildren(context: Context, content: ContentState): ReactNode[] {
  let remaining = context.limit;
  let addEllipsis = false;
  const children = content.getBlocksAsArray().flatMap((block) => {
    if (remaining <= 0) {
      addEllipsis = true;
      return [];
    }
    const text = block.getText();
    const blockElements: ReactNode[] = [];
    forEachRange(block, (start, end) => {
      if (remaining <= 0) {
        addEllipsis = true;
        return;
      }
      let rangeText = text.slice(start, end);
      if (rangeText.length > remaining) {
        addEllipsis = true;
        rangeText = rangeText.slice(0, remaining);
      }
      remaining -= rangeText.length;
      blockElements.push(getSmartTextPart(context, content, block, start, rangeText));
    });
    blockElements.push(<br />);
    // To prevent unlimited empty blocks
    remaining -= 1;
    return blockElements;
  });
  // Take off the last <br>
  children.pop();
  return flow(addEllipsis ? withEllipsis : identity, withKeys)(children);
}

function getSmartTextPart(
  context: Context,
  content: ContentState,
  block: ContentBlock,
  offset: number,
  text: string,
): ReactNode {
  const key = block.getEntityAt(offset);
  switch (key && content.getEntity(key).getType()) {
    case 'LINK':
      return (
        <a href={getEntityData('LINK', content, key).url} rel='noreferrer noopener' target='_blank'>
          <AccentedText>{text}</AccentedText>
        </a>
      );
    case 'mention':
      return <AccentedText>{text}</AccentedText>;
    case 'label':
      if (!context.filterLabelsOnClick) return <AccentedText>{text}</AccentedText>;
      return (
        <ReduxLabelChip filterOnClick={context.filterLabelsOnClick} id={getEntityData('label', content, key).id}>
          {text}
        </ReduxLabelChip>
      );
    default:
      return text;
  }
}

/**
 * Draft Editor has built-in 0.1px border left style
 * This border is implemented to omit issue with translating text when changing between SmartText and RichEditor
 */
const DraftEditorBorder = styled('div', {
  borderLeft: '.1px solid transparent',
  overflowWrap: 'break-word',
});

function withEllipsis(children: ReactNode[]): ReactNode[] {
  children.push('…');
  return children;
}

function withKeys(children: ReactNode[]): ReactNode[] {
  return children.map((child, index) => (hasKey(child) ? cloneElement(child, { key: `${index}` }) : child));
}

function hasKey(child: ReactNode): child is { key: Key | null } {
  return Boolean(child) && typeof (child as Record<string, unknown>).key !== 'undefined';
}
