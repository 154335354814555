import { identify as fsIdentify, init as fsInit, UserVars } from '@fullstory/browser';

export function init(): void {
  const orgId = process.env.REACT_APP_FULLSTORY_ORG_ID;
  if (!orgId) return;

  fsInit({
    orgId,
    devMode: process.env.REACT_APP_ENV !== 'production',
  });
}

export function identify(uid: string, customVars?: UserVars): void {
  const orgId = process.env.REACT_APP_FULLSTORY_ORG_ID;
  if (!orgId) return;
  fsIdentify(uid, customVars);
}
