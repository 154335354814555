import { Box, VStack } from '@taraai/design-system';
import { TaskPartial } from '@taraai/types';
import { useTaskTitleConfig } from 'components/editor/config';
import { RichEditorProvider } from 'components/editor/RichEditorProvider';
import React, { ReactNode, useEffect, useState } from 'react';
import { decode } from 'reduxStore/utils/decoders';

import { Options } from './Options';

const LIMIT = 140;

type Props = {
  children: ReactNode;
  initialValue?: string;
  onEnter: (text: string) => void;
  handleEmptyTaskTitle?: (text: string) => void;

  optionsAnimation?: {
    onAnimationEnd: () => void;
    show: boolean;
  };
};

export function TaskCreation({
  children,
  initialValue = '',
  onEnter,
  optionsAnimation,
  handleEmptyTaskTitle,
}: Props): JSX.Element {
  const [text, setText] = useState(initialValue);
  const [atLimit, setAtLimit] = useState(false);
  const [tooShort, setTooShort] = useState(true);

  useEffect((): void => {
    try {
      decode<TaskPartial>({ title: text }, 'TaskPartial');
      if (text.length >= LIMIT) return setAtLimit(true);
      setAtLimit(false);
      setTooShort(false);
    } catch {
      setTooShort(true);
    }
  }, [text]);

  return (
    <RichEditorProvider
      config={useTaskTitleConfig({ mentions: true })}
      initialValue={initialValue}
      onSave={!tooShort ? onEnter : handleEmptyTaskTitle}
      onTextChange={setText}
      saveStrategy='saveOnReturn'
      selectAllContent={!!initialValue}
    >
      <VStack>
        <Box color='$coreGrey' spaceRight='$4px'>
          {children}
        </Box>
        <Options atLimit={atLimit} optionsAnimation={optionsAnimation} text={text} tooShort={tooShort} />
      </VStack>
    </RichEditorProvider>
  );
}
