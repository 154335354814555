import { Box, HStack, styled, Text, tokens } from '@taraai/design-system';
import React, { useEffect, useState } from 'react';

const INITIAL = 100;
const STEP = 10;

export function ZoomManager(): JSX.Element {
  // Getting null from localStorage and convert to number is equal to 0
  // So, check if it's not 0 when passing to state.
  const previouslySavedZoom = Number(localStorage.getItem('taraAppZoom'));

  const [zoom, setZoom] = useState(previouslySavedZoom !== 0 ? previouslySavedZoom : INITIAL);

  useEffect(() => {
    localStorage.setItem('taraAppZoom', zoom.toString());
    (document.body.style as unknown as { zoom: string }).zoom = `${zoom}%`;
  }, [zoom]);

  return (
    <HStack align='center' alignY='center' full style={{ width: 64 }}>
      <ZoomButton onClick={() => setZoom(zoom - STEP)} type='button'>
        {lessIcon}
      </ZoomButton>
      <Box.Button onClick={() => setZoom(INITIAL)}>
        <Text color='$grey7' size='$12px' style={{ margin: '0 8px' }}>
          Aa
        </Text>
      </Box.Button>
      <ZoomButton onClick={() => setZoom(zoom + STEP)} type='button'>
        {plusIcon}
      </ZoomButton>
    </HStack>
  );
}

const lessIcon = (
  <svg fill={tokens.colors.$grey6} height='8' viewBox='0 0 24 24' width='5' xmlns='http://www.w3.org/2000/svg'>
    <path d='M0 10h24v4h-24z' />
  </svg>
);

const plusIcon = (
  <svg fill={tokens.colors.$grey6} height='8' viewBox='0 0 24 24' width='5' xmlns='http://www.w3.org/2000/svg'>
    <path d='M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z ' />
  </svg>
);

const ZoomButton = styled('button', {
  'alignItems': 'center',
  'backgroundColor': '$grey1',
  'border': 'none',
  'borderRadius': '50%',
  'cursor': 'pointer',
  'display': 'inline-flex',
  'height': '14px',
  'justifyContent': 'center',
  'margin': '0px',
  'outline': 'none',
  'padding': '$none $4px',
  'width': '14px',
  ':hover': { color: '$grey6' },
});
