import { Accordion, Box, getCustomSize, HStack, Spacer, styled, Text, VStack } from '@taraai/design-system';
import { StandardSpinner } from 'components/core/controllers/views/Spinners';
import React, { useEffect } from 'react';
import { strings } from 'resources';

import copilotColumn from './images/copilot-column.svg';
import enterpriseColumn from './images/enterprise-column.svg';
import featureBreakdown from './images/featureBreakdown.svg';
import freeColumn from './images/free-column.svg';
import insightsColumn from './images/insights-column.svg';

const COPILOT_YEARLY_PRICE_ID = process.env.REACT_APP_STRIPE_COPILOT_YEARLY_PRICE_ID as string;

type Props = {
  isLoading: boolean;
  sendToCheckout: (priceId: string) => void;
};

export function UpgradeView({ isLoading, sendToCheckout }: Props): JSX.Element {
  useEffect(() => {
    document.title = strings.titles.upgrade;
    return () => {
      // Restore the original page title when the component unmounts
      document.title = strings.titles.default;
    };
  }, []);

  return (
    <>
      {isLoading && <StandardSpinner fillSpace size='medium' />}
      <Box background='$darker' center spaceVert='$32px' width='$full'>
        <VStack space='$24px'>
          <HStack>
            <Spacer space='$12px' />
            <Text color='$white' size='$38px' textAlign='left' weight='medium'>
              {strings.upgrade.title}
            </Text>
          </HStack>
          <HStack>
            <Box>
              <img alt='free plan column' src={freeColumn} />
            </Box>
            <Box.Button onClick={() => sendToCheckout(COPILOT_YEARLY_PRICE_ID)}>
              <img alt='co-pilot plan column' src={copilotColumn} />
            </Box.Button>
            <Box>
              <a href='https://tara.ai/demo' rel='noopener noreferrer' target='_blank'>
                <img alt='insights plan column' src={insightsColumn} />
              </a>
            </Box>
            <Box>
              <a href='https://tara.ai/demo' rel='noopener noreferrer' target='_blank'>
                <img alt='enterprise plan column' src={enterpriseColumn} />
              </a>
            </Box>
          </HStack>
        </VStack>
      </Box>
      <Box space='$24px'>
        <VStack align='center'>
          <Accordion>
            <Accordion.Header space='$4px'>
              <HStack space='$4px'>
                <SectionHeaderBackground borderRadius='$4px' center spaceVert='$16px' width='$full'>
                  <SectionHeaderText size='$18px'>Full Feature Breakdown</SectionHeaderText>
                  <Accordion.Arrow />
                </SectionHeaderBackground>
              </HStack>
            </Accordion.Header>
            <Accordion.Content>
              <img alt='plan comparisons' src={featureBreakdown} style={{ overflow: 'hidden' }} />
            </Accordion.Content>
          </Accordion>
          <Spacer space='$24px' />
          <Box spaceBottom='$24px'>
            <VStack align='center' space='$8px'>
              <Box spaceBottom='$24px'>
                <Text color='$dark' size='$38px' weight='medium'>
                  {strings.upgrade.FAQ}
                </Text>
              </Box>
              {strings.upgrade.QA.map(({ question, answer }) => (
                <Accordion key={question}>
                  <Box key={question} borderBottom='$grey4' spaceBottom='$24px' spaceTop='$12px' width={headerWidth}>
                    <Accordion.Header space='$4px'>
                      <HStack alignY='center'>
                        <Box>
                          <Accordion.Arrow />
                        </Box>
                        <Spacer space='$4px' />
                        <Text color='$dark' size='$18px'>
                          {question}
                        </Text>
                      </HStack>
                    </Accordion.Header>
                    <Accordion.Content>
                      <Box space='$24px'>
                        <Text color='$dark' size='$14px'>
                          {answer}
                        </Text>
                      </Box>
                    </Accordion.Content>
                  </Box>
                </Accordion>
              ))}
            </VStack>
          </Box>
        </VStack>
        <Spacer space='$24px' />
      </Box>
    </>
  );
}

const SectionHeaderBackground = styled(Box, {
  background: 'linear-gradient(135.19deg,rgba(88,166,231,.05) .34%,rgba(119,93,250,.05))',
});

const SectionHeaderText = styled(Text, {
  fontWeight: '400',
  fontSize: '18px',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  backgroundImage: 'linear-gradient(135.19deg, #58a6e7 0.34%, #775dfa 100%)',
  backgroundSize: '100%',
  WebkitTextFillColor: 'transparent',
});

export const upgradeBackground = getCustomSize(800);
export const headerWidth = getCustomSize(1140);
