/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
import { MemberMetrics } from '@taraai/types/dist/data';
import { getInsightsMembersPath } from '@taraai/utility';
import { collection, getFirestore, onSnapshot, query } from 'firebase/firestore';
import { useGetUserOrgMetricsOrgId } from 'firestore/user';
import { useEffect, useState } from 'react';
import deepEquals from 'react-fast-compare';

/**
 * @returns Returns orgMetrics members objects within an organization ( workspace )
 */
export function useGetOrgMetricsMembers(): MemberMetrics[] | null {
  const firestore = getFirestore();
  // State where member Ids are set. Null is the default so it will function as the loading state
  const [memberDocs, setMemberDocs] = useState<MemberMetrics[] | null>(null);

  const metricsOrgId = useGetUserOrgMetricsOrgId();

  useEffect(
    () => {
      if (!metricsOrgId) return;

      const collectionRef = collection(firestore, getInsightsMembersPath(metricsOrgId));

      // setup collection query
      const collectionQuery = query(collectionRef);

      onSnapshot(
        collectionQuery,
        (querySnapshot) => {
          const memberDocuments = [] as MemberMetrics[];

          querySnapshot.forEach((doc) => {
            const docData = doc.data() as MemberMetrics;
            memberDocuments.push({ ...docData, id: doc.id });
          });

          if (!deepEquals(memberDocuments, memberDocs)) setMemberDocs(memberDocuments);
        },
        (error) => {
          throw new Error(error.message);
        },
      );
    },
    // Include only dependencies necessary to the UI to rerender to prevent looping
    [metricsOrgId],
  );

  return memberDocs;
}

// function that takes in an array of memberIds and returns the member objects
export function useGetOrgMetricsMembersByIds(memberIds: string[]): MemberMetrics[] | null {
  const members = useGetOrgMetricsMembers();

  if (!members) {
    return null;
  }

  return members.filter((member) => memberIds.includes(member.id!));
}
