import { PatternCircles, PatternLines, PatternWaves } from '@visx/pattern';
import { GraphColors } from 'components/app/GitInsights/Shared/constants';
import React from 'react';

type SVGBackgroundPatternsProps = {
  dataLength: number;
};

export const SVGBackgroundPatterns = ({ dataLength }: SVGBackgroundPatternsProps): JSX.Element[] => {
  // generate unique id's to use as keys for patterns
  const guid = (): string => {
    // eslint-disable-next-line id-length
    const s4 = (): string => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    };
    return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
  };

  const colors = GraphColors.slice(0, dataLength);

  return colors.map((color: string) => (
    <>
      <PatternCircles
        key={guid()}
        fill={color}
        height={6}
        id={`background-circle-${color}`}
        stroke={color}
        strokeDasharray={200}
        strokeWidth={1}
        width={6}
      />

      <PatternLines
        key={guid()}
        height={6}
        id={`background-line-${color}`}
        orientation={['diagonalRightToLeft']}
        stroke={color}
        strokeWidth={0.6}
        width={6}
      />

      <PatternWaves key={guid()} height={6} id={`background-wave-${color}`} stroke={color} strokeWidth={1} width={6} />
    </>
  ));
};
