import { notUndefined } from '@taraai/utility';
import { RichEditorPlugin } from 'components/editor/types';

export function getWhitespacePlugin({ trim = false, collapse = false } = {}): RichEditorPlugin<'postSave'> {
  const mods = [trim ? trimMod : undefined, collapse ? collapseMod : undefined].filter(notUndefined);
  return {
    postSave: (source) => mods.reduce((result, mod) => mod(result), source),
  };
}

function trimMod(text: string): string {
  return text.trim();
}

function collapseMod(text: string): string {
  return text.replaceAll(/\s+/g, ' ');
}
