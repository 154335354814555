import { Data } from '@taraai/types';
import Icon from 'components/core/controllers/views/Icon';
import { linkTo } from 'components/Router/paths';
import { css, cx } from 'emotion';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectActiveTeam } from 'reduxStore';
import { IconName } from 'resources';
import { homePageTestIds } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';
import { atomic } from 'resources/theme';
import { ConnectGitHubAccountData } from 'types/connect-account';
import { InstallGitHubData } from 'types/install-integration';

import ConnectButton, { ConnectButtonState } from './ConnectButton';

export interface GitHubFallbackProps extends React.HTMLProps<HTMLDivElement> {
  organizationId: Data.Id.OrganizationId;
  installGitHubData: InstallGitHubData;
  connectGitHubAccountData?: ConnectGitHubAccountData;
  icon: IconName;
  title: string;
  details?: string;
  iconClassName?: string;
}

export default function GitHubFallback({
  className,
  organizationId,
  installGitHubData,
  connectGitHubAccountData,
  icon,
  title,
  details,
  iconClassName,
  ...props
}: GitHubFallbackProps): JSX.Element {
  const teamId = useSelector(selectActiveTeam);

  const linkGitHubProfileState: ConnectButtonState =
    connectGitHubAccountData && connectGitHubAccountData.accounts.length > 0 ? 'completed' : 'enabled';
  // eslint-disable-next-line no-nested-ternary
  const installGitHubState: ConnectButtonState = installGitHubData.id
    ? 'completed'
    : installGitHubData.userHasPermission
    ? 'enabled'
    : 'disabled';

  let content = null;
  let titleStyle = css`
    font-size: 0.875rem;
    font-weight: normal;
    color: ${atomic.get(atomic.colors.grey7)};
  `;

  if (connectGitHubAccountData && !installGitHubData.id) {
    titleStyle = css`
      font-size: 0.875rem;
      font-weight: 500;
      color: var(--dark);
    `;
    content = (
      <>
        <Link
          className={css`
            font-size: 0.875rem;
            font-weight: 500;
            cursor: pointer;
            padding: 0.625rem;
            ${atomic.color(atomic.colors.secondary)};
            text-decoration: underline;
          `}
          to={linkTo('githubLearnMore', { orgId: organizationId, teamId })}
        >
          {strings.dashboard.learnMore}
        </Link>
        <p
          className={css`
            font-size: 0.75rem;
            font-weight: 500;
            padding-bottom: 0.5rem;
            color: ${atomic.get(atomic.colors.grey6)};
          `}
        >
          {details}
        </p>
        <ConnectButton
          className={css`
            margin: 0.5rem 0;
          `}
          data-cy={homePageTestIds.PULL_REQUESTS.GITHUB.LINK_PROFILE}
          installData={connectGitHubAccountData}
          labels={{
            completed: strings.gitHubFallback.profileLinked,
            enabled: strings.gitHubFallback.linkYourGithubProfile,
            disabled: strings.gitHubFallback.linkYourGithubProfile,
          }}
          method='post'
          state={linkGitHubProfileState}
        />
        <ConnectButton
          className={css`
            margin: 0.5rem 0;
          `}
          data-cy={homePageTestIds.PULL_REQUESTS.GITHUB.INSTALL_REPOS}
          installData={installGitHubData}
          labels={{
            completed: strings.gitHubFallback.repoConnected,
            enabled: strings.gitHubFallback.connectYourGithubRepos,
            disabled: strings.gitHubFallback.connectYourGithubRepos,
          }}
          method='get'
          state={installGitHubState}
        />
      </>
    );
  } else if (!installGitHubData.id) {
    content = (
      <>
        <p
          className={css`
            font-size: 0.75rem;
            font-weight: normal;
            padding-top: 0.5rem;
            color: ${atomic.get(atomic.colors.grey6)};
          `}
        >
          {details}
        </p>
        <ConnectButton
          className={css`
            margin: 1.5rem 0;
          `}
          installData={installGitHubData}
          labels={{
            completed: strings.gitHubFallback.repoConnected,
            enabled: strings.gitHubFallback.connect,
            disabled: strings.gitHubFallback.connect,
          }}
          method='get'
          state={installGitHubState}
        />
      </>
    );
  }

  return (
    <div
      className={cx(
        css`
          padding: 1.625rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          flex-grow: 1;
          text-align: center;
        `,
        className,
      )}
      {...props}
    >
      <Icon
        className={cx(
          css`
            height: 3rem !important;
            width: 3rem !important;
          `,
          iconClassName,
        )}
        name={icon}
      />
      <p className={titleStyle}>{title}</p>
      {content}
    </div>
  );
}
