/* eslint-disable @typescript-eslint/no-shadow */

import { compose, unwrapResult } from '@reduxjs/toolkit';
import { Avatar, Box } from '@taraai/design-system';
import { Data, UI } from '@taraai/types';
import { decodeChain } from '@taraai/types/dist/decoder';
import DropdownController from 'components/core/controllers/DropdownController';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectActiveUsers, selectActiveWorkspace, updateTask, useAppDispatch } from 'reduxStore';
import { strings } from 'resources';
import { taskDetailsTestIds } from 'resources/cypress/testAttributesValues';
import { useToast } from 'tools';

type Props = {
  assignee?: Pick<UI.UIUser, 'id' | 'name' | 'avatarURL'>;
  subtaskId: Data.Id.TaskId;
};

export const SubtaskAssigneeController = ({ assignee, subtaskId }: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const orgId = useSelector(selectActiveWorkspace);
  const [show, setShow] = useState(false);
  const { whenError } = useToast();
  const errorMessage = whenError(strings.task.failedToUpdateTask);

  const updateAssignee = (collaboratorId: Data.Id.UserId | null): void => {
    decodeChain<UI.UITaskChangeset>(
      {
        id: subtaskId,
        assignee: collaboratorId,
      },
      'UITaskChangeset',
    )
      .then((changeset) => dispatch(updateTask({ ...changeset })))
      .then(unwrapResult)
      .catch(errorMessage);
  };

  const users = useSelector(
    compose(
      (data: UI.UIUser[]) => data.map(({ id, name, avatarURL }) => ({ id, name, avatarURL })),
      selectActiveUsers(orgId),
    ),
  );

  return (
    <DropdownController
      data-cy={taskDetailsTestIds.SUBTASK_ASSIGNEE_DROPDOWN}
      header
      headerPlaceholder={strings.dropdown.placeholder.searchUsers}
      headerTitle={strings.dropdown.header.assignTeamMember}
      onAdd={updateAssignee}
      onClose={useCallback(() => setShow(false), [])}
      onRemove={() => updateAssignee(null)}
      options={users}
      selectedOptions={assignee ? [assignee] : []}
      show={show}
    >
      <Box.Button onClick={useCallback(() => setShow((show) => !show), [])}>
        <Avatar title={assignee?.name} url={assignee?.avatarURL} />
      </Box.Button>
    </DropdownController>
  );
};
