/* eslint-disable sonarjs/cognitive-complexity */
import UserAccountSetupProgress from 'components/app/controllers/views/UserAccountSetupProgress';
import { linkTo } from 'components/Router/paths';
import { useGetUserFields } from 'firestore';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router';
import { getUsers, selectActiveTeam, selectAuth, useConnectToOrganization } from 'reduxStore';
import { strings } from 'resources/i18n';
import { getInvitationTokenFromCookie, removeInvitationTokenCookie } from 'tools/utils/invitationToken';

import JoinWorkspaceController from './JoinWorkspaceController';

/**
 * Handles automatic workspace joining when invitationToken cookie is ok,
 * if not display JoinWorkspaceController for manual joining
 */

export default function AutoJoinWorkspaceController(): JSX.Element {
  const [{ error, data }, connectToOrganization] = useConnectToOrganization();
  const tokenFromCookie = getInvitationTokenFromCookie();
  const auth = useSelector(selectAuth);
  const history = useHistory();
  const userIsLogged = !auth.isEmpty;
  const teamId = useSelector(selectActiveTeam);

  const userPartial = useGetUserFields(['organizationIds']);

  const [userOrgIds, setUserOrgIds] = useState<string[]>([]);

  const [orgInvited, setOrgInvited] = useState<string>('');

  const deactivatedUsersInOrg: string[] =
    useSelector(getUsers(orgInvited).selector)
      ?.filter((user) => user.accessLevels?.[orgInvited] === 'deactivated')
      .map((_user) => _user.id) || [];

  useEffect(() => {
    if (userPartial?.organizationIds) {
      setUserOrgIds(userPartial.organizationIds);
    }
  }, [userPartial]);

  useEffect(() => {
    if (data) {
      setOrgInvited(data.organizationId);
    }
  }, [data]);

  const isUserAlreadyInOrganization = userOrgIds.includes(orgInvited);

  const isUserDeactivatedInOrganization = deactivatedUsersInOrg.includes(auth.uid);

  const shouldJoinWorkspaceAutomatically = userIsLogged && tokenFromCookie;

  useEffect(() => {
    if (shouldJoinWorkspaceAutomatically) {
      // tokenFromCookie is always here (shouldJoinWorkspaceAutomatically)
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      connectToOrganization(tokenFromCookie!);
    }
  }, [shouldJoinWorkspaceAutomatically, connectToOrganization, tokenFromCookie]);

  if (isUserAlreadyInOrganization) {
    if (isUserDeactivatedInOrganization) {
      <Redirect to={linkTo('deactivated')} />;
    }

    <Redirect to={linkTo('home', { orgId: orgInvited, teamId })} />;
  }

  // if we should not join automatically, or there was an error
  // fallback on manual joining
  if (!shouldJoinWorkspaceAutomatically || error) {
    if (tokenFromCookie) {
      // make sure that token is stored in URL
      history.replace(linkTo('invite', { token: tokenFromCookie }));
      // if there was a token from cookie, remove it before proceeding
      removeInvitationTokenCookie();
    }
    // display manual joining
    return <JoinWorkspaceController />;
  }

  if (data) {
    // auto joining succeed and user can be redirected to joined workspace home page
    removeInvitationTokenCookie();
    const link = {
      pathname: linkTo('home', { orgId: data.organizationId, teamId }),
    };
    return <Redirect to={link} />;
  }

  const message = strings.joinWorkspace.progress.userAccountSetup;

  return <UserAccountSetupProgress message={message} />;
}
