import { strings } from 'resources/i18n';

/**
 * ErrorMessages:
 * error messages to show a toast if there's a decoder error
 *
 */
export const ToastErrorMessages: Record<string, string> = {
  'TaskPartial.title.minLength': strings.task.titleTooShort,
  'TaskPartial.title.maxLength': strings.task.titleTooLong,
  'UIRequirementPartial.title.minLength': strings.requirements.titleTooShort,
  'UIRequirementPartial.title.maxLength': strings.requirements.titleTooLong,
};

export default ToastErrorMessages;
