import { Box } from '@taraai/design-system';
import React, { ReactNode } from 'react';

type Props = {
  targetName: string;
  children: ReactNode;
  debug?: boolean;
};

export function AppCuesWrapper({ targetName, children, debug = false }: Props): JSX.Element {
  return (
    <Box
      border={debug ? ['$debug', '$2px'] : undefined}
      data-appcues-target={`${targetName}`}
      id={`appcues-${targetName}`}
      space='$none'
    >
      {children}
    </Box>
  );
}
