import { scaleOrdinal } from '@visx/scale';
import { LegendColorScale } from 'components/app/GitInsights/_types';
import { useSelector } from 'react-redux';
import { gitInsightsSelectionState } from 'reduxStore';

import { GraphColors } from './constants';

export const InjectDisplayFlexProps = {
  display: 'flex',
  justifyContent: 'center',
  width: 'auto',
};

/**
 * Define if the chart has valid data to render the DOM markup
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isValidData = (data: any): boolean => data !== null && data !== undefined;

/**
 * When comparing with a time period, we show legends
 * E.g: This week, last week, last month, etc.
 */
export const GenerateComparisonLegendScale = (legends: string[], colors?: string[]): LegendColorScale => {
  if (colors && colors.length !== legends.length) throw new Error('Could not find all colors for all legends');

  /**
   *  We may have custom colors passed inside this function, by default, we use the colors defined by
   * Design team in constants.ts file.
   */
  const barsColors = colors ?? GraphColors.slice(0, legends.length);

  return scaleOrdinal<string, string>({
    domain: legends,
    range: barsColors,
  }) as unknown as LegendColorScale;
};

/**
 * Here, we choose which keys from a object we want to render in the graphs, excluding unecessary keys as labels or other information
 * that aren't related to what the user needs to see inside the bars
 */
export const GetKeysForRenderingInGraph = (data: Record<string, unknown>, excludeKey: string): string[] => {
  return Object.keys(data).filter((record: unknown) => record !== `${excludeKey}`);
};

export const GetStateSelectionType = (): 'multiUser' | 'singleUser' | null => {
  const getInsightState = useSelector(gitInsightsSelectionState);

  const { selected } = getInsightState;

  const isUserComparison = selected.length > 1;

  if (isUserComparison) return 'multiUser';

  return 'singleUser';
};

export const CapitalizeString = (text: string): string => text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();

export const UppercaseString = (text: string): string => text.toUpperCase();
