import { Data } from '@taraai/types';
import TableCell from 'components/core/controllers/views/TableCell';
import TableRow from 'components/core/controllers/views/TableRow';
import { linkTo } from 'components/Router/paths';
import { css, cx } from 'emotion';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectPreferredTeamId } from 'reduxStore';
import { NoActiveSprint, NoAssignedTasks } from 'resources/assets';
import { homePageTestIds } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';
import { atomic } from 'resources/theme';

export interface EmptyTableStateProps {
  orgId: Data.Id.OrganizationId;
  colCount?: number;
  noActiveSprint?: boolean;
  noAssignedTasks?: boolean;
}

/**
 * EmptyTableState is a reusable component to be used for similar empty states
 *
 */
export default function EmptyTableState({
  colCount = 1,
  noActiveSprint = false,
  noAssignedTasks = false,
  orgId,
}: EmptyTableStateProps): JSX.Element {
  const teamId = useSelector(selectPreferredTeamId(orgId));

  const noActiveSprintState = noActiveSprint && (
    <TableCell
      className={css`
        cursor: auto;
        text-align: center !important;
        padding: 3.125rem !important;
      `}
      columnSpan={colCount}
    >
      <img
        alt={strings.emptyRequirement}
        className={css`
          padding: 0.75rem;
          color: '#f4f4fa';
        `}
        src={NoActiveSprint}
      />
      <div
        className={css`
          font-size: 0.875rem;
          font-weight: 500;
          color: #949caf;
        `}
      >
        {strings.dashboard.noActiveSprints}
        <Link
          className={cx(
            css`
              text-decoration: none;
              ${atomic.color(atomic.colors.secondary)}
              &:hover {
                text-decoration: none;
                ${atomic.color(atomic.colors.focus)}
              }
            `,
          )}
          data-cy={homePageTestIds.PLAN_NEW_SPRINT_BUTTON}
          to={linkTo('sprints', { orgId, teamId })}
        >
          {strings.dashboard.planYourNextSprint}
        </Link>
      </div>
    </TableCell>
  );

  const noAssignedTasksState = noAssignedTasks && (
    <TableCell
      className={css`
        cursor: auto;
        padding: 3.125rem !important;
        text-align: center !important;
        :hover {
          background-color: #ffffff;
        }
      `}
      columnSpan={colCount}
    >
      <img
        alt={strings.emptyRequirement}
        className={css`
          padding: 0.75rem;
          color: '#f4f4fa';
        `}
        src={NoAssignedTasks}
      />
      <div
        className={css`
          font-size: 0.875rem;
          font-weight: 500;
          color: #949caf;
          padding: 0.75rem;
        `}
      >
        {strings.dashboard.noAssignedTasks}
      </div>
    </TableCell>
  );

  return (
    <TableRow
      className={css`
        border: 0;
      `}
    >
      {noActiveSprintState || noAssignedTasksState}
    </TableRow>
  );
}
