import { Toast } from '@taraai/design-system';
import React, { ComponentProps, ReactNode, useEffect, useState } from 'react';

const DEFAULT_TIMEOUT = 5000;

type ToastState = 'loading' | 'success' | 'failure';
type Props = {
  children: ReactNode;
  state: ToastState;
  onClick?: () => void;
};

export function PendingActionToast({ children, onClick, state = 'loading' }: Props): JSX.Element | null {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    setVisible(true);
    if (state === 'failure') return;
    const handle = setTimeout(() => setVisible(false), DEFAULT_TIMEOUT);
    return () => clearTimeout(handle);
  }, [state]);

  if (!visible) return null;
  return (
    <Toast kind={stateToKind[state]} onClick={onClick}>
      {children}
    </Toast>
  );
}

const stateToKind: Record<ToastState, ComponentProps<typeof Toast>['kind']> = {
  loading: 'info',
  failure: 'failure',
  success: 'success',
};
