import { Box, styled, VStack } from '@taraai/design-system';
import React from 'react';
import { LoginBanner, OnboardingImage, strings } from 'resources';

import { PreviewProps } from './types';

export const Preview = ({ stepName, image }: PreviewProps): JSX.Element => {
  return (
    <Banner align='center' style={{ backgroundImage: `url(${LoginBanner})` }}>
      {stepName === 'start' && (
        <Box spaceLeft='$24px' spaceRight='$24px' spaceTop='$24px'>
          <Box spaceLeft='$24px' spaceRight='$24px'>
            <BannerTitle>
              {strings.onboarding.workspaceStep.bannerTitle}
              <br />
              {strings.onboarding.workspaceStep.bannerSubtitle}
              <TaraIcon />
            </BannerTitle>

            <BannerImage alt='Git integrations' src={OnboardingImage} />
          </Box>
        </Box>
      )}
      <Box spaceLeft='$24px' spaceTop='$24px' width='$full'>
        <Box spaceLeft='$24px' spaceTop={stepName !== 'start' ? '$24px' : '$1px'}>
          <Box spaceLeft='$24px' spaceTop={stepName !== 'start' ? '$24px' : '$1px'}>
            <BannerImage alt='' src={image} />
          </Box>
        </Box>
      </Box>
    </Banner>
  );
};

const Banner = styled(VStack, {
  height: '$fullScreenHeight',
  overflow: 'hidden',
});
const BannerImage = styled('img', {
  width: '100%',
  borderTopLeftRadius: '$4px',
});
const BannerTitle = styled('h1', {
  fontWeight: '600',
  fontSize: '50px',
  lineHeight: '110%',
  letterSpacing: '-0.03em',
  color: '#A8A9E7',
});

const TaraIcon = (): React.ReactElement => (
  <svg
    fill='none'
    height='42'
    style={{ position: 'absolute', right: '100px', top: '20px' }}
    viewBox='0 0 60 42'
    width='60'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      clipRule='evenodd'
      d='M8.16767 27.3401C15.922 15.6174 26.8474 0 30.0185 0C35.0922 0 60 39.9344 60 39.9344C59.6588 40.5934 59.094 41.1044 58.4102 41.3729C53.5278 43.4574 44.3274 37.7551 36.876 33.1368C33.8738 31.276 31.1555 29.5912 29.1179 28.6626C24.0061 26.331 19.0762 25.2832 14.4549 25.6912C12.2909 25.925 10.1725 26.4806 8.16767 27.3401ZM0 40.0377L5.97008 30.5951C12.3461 28.0144 21.3181 28.6671 25.7407 31.3509C18.8531 36.4006 6.71423 43.5201 1.6405 41.3731C0.978995 41.0968 0.409579 40.6332 0 40.0377Z'
      fill='#F4F4FA'
      fillRule='evenodd'
    />
  </svg>
);
