import { createAsyncThunk } from '@reduxjs/toolkit';
import { Data } from '@taraai/types';
import { ExtraAPI } from 'reduxStore/utils';
import { decode } from 'reduxStore/utils/decoders';

export const checkOrganizationExists = createAsyncThunk(
  'CheckOrganizationExists',
  async ({ orgId }: { orgId: Data.Id.OrganizationId }, { extra }): Promise<boolean> => {
    const { getFirestore } = extra as ExtraAPI;
    const firestore = getFirestore();

    const validOrgId = decode<Data.Id.OrganizationId>(orgId, 'OrganizationId');

    return firestore
      .collection('/orgs')
      .doc(validOrgId)
      .get()
      .then((doc) => doc.exists);
  },
);
