import { Dropdown, Fluid, HStack, Text, tokens, Tree } from '@taraai/design-system';
import { useFirestoreConnect } from '@taraai/read-write';
import { Data, TaskStatus } from '@taraai/types';
import { noop } from '@taraai/utility';
import { DroppableArea, DroppableType } from 'components/app/DragAndDrop';
import { DNDContext } from 'components/app/DragAndDrop/DNDProvider';
import { EmptyDrawerImports, EmptyDrawerProps } from 'components/app/EmptyWorkDrawer/EmptyWorkDrawer';
import {
  SelectTaskFragment,
  selectTaskFragment,
  WorkDrawerImportTasksId,
} from 'components/app/WorkDrawer/WorkDrawerController';
import { TaskCards, TopLevelCreationButton } from 'components/app/WorkDrawer/WorkDrawerSections';
import { PrimaryHeader, SecondaryHeader } from 'components/app/WorkDrawerHeaders';
import React, { useEffect, useState } from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { useAppDispatch, useFilteredSummaryTaskIds, useIsSearchActive, useSetNextTaskId } from 'reduxStore';
import { openImportModal } from 'reduxStore/imports/actions';
import { getImportedTasks } from 'reduxStore/imports/queries/imported-tasks';
import { useWorkDrawerSettingsState, useWorkDrawerUnfoldedState } from 'reduxStore/workDrawer';
import { strings } from 'resources';
import { ASANA_EXPORT_INSTRUCTION_URL, TRELLO_EXPORT_INSTRUCTION_URL } from 'tools/libraries';

export function WorkDrawerImports({
  onTaskSelect,
  orgId,
  selectedTaskId,
  teamId,
}: EmptyDrawerProps & {
  onTaskSelect: (taskId: Data.Id.TaskId) => void;
  selectedTaskId: Data.Id.TaskId | undefined;
}): JSX.Element {
  const [unfolded, setUnfolded] = useWorkDrawerUnfoldedState('imports');
  const asanaSlice = getImportedTasks(orgId, 'asana');
  const trelloSlice = getImportedTasks(orgId, 'trello');
  const imports = useSelector(
    (state) => [
      {
        title: 'asana',
        tasks: selectTaskFragment(state, asanaSlice.query[0]?.storeAs) ?? [],
      },
      {
        title: 'trello',
        tasks: selectTaskFragment(state, trelloSlice.query[0]?.storeAs) ?? [],
      },
    ],
    deepEquals,
  );

  useFirestoreConnect([...asanaSlice.query, ...trelloSlice.query]);

  const isSearchActive = useIsSearchActive();
  useEffect(() => {
    if (isSearchActive) setUnfolded(true);
  }, [isSearchActive, setUnfolded]);

  return (
    <Tree unfolded={unfolded}>
      <PrimaryHeader onClick={() => setUnfolded(!unfolded)} title='Imports' widget={<ImportsDropdown />} />
      <Tree.Content strategy='lazy'>
        {imports.some((importItem) => importItem.tasks?.length) ? (
          imports.map((service) => {
            return (
              service.tasks &&
              service.tasks.length > 0 && (
                <ImportSection
                  key={service.title}
                  onTaskSelect={onTaskSelect}
                  selectedTaskId={selectedTaskId}
                  service={service}
                />
              )
            );
          })
        ) : (
          <EmptyDrawerImports orgId={orgId} teamId={teamId} />
        )}
      </Tree.Content>
    </Tree>
  );
}

function ImportsDropdown(): JSX.Element {
  const dispatch = useAppDispatch();
  const [show, setShow] = useState(false);
  return (
    <Dropdown
      items={[
        <Dropdown.DefaultItem key='trello-upload' onClick={() => dispatch(openImportModal('trello'))}>
          <HStack alignY='center' space='$8px'>
            <Fluid>
              <Text size='$12px'>{strings.integrations.trello.upload}</Text>
            </Fluid>
            {uploadIcon}
          </HStack>
        </Dropdown.DefaultItem>,
        <Dropdown.DefaultItem key='asana-upload' onClick={() => dispatch(openImportModal('asana'))}>
          <HStack alignY='center' space='$8px'>
            <Fluid>
              <Text size='$12px'>{strings.integrations.asana.upload}</Text>
            </Fluid>
            {uploadIcon}
          </HStack>
        </Dropdown.DefaultItem>,
        <a key='trello-info' href={TRELLO_EXPORT_INSTRUCTION_URL} rel='noopener noreferrer' target='_blank'>
          <Dropdown.DefaultItem>
            <Text size='$12px'>{strings.integrations.trello.importInfo}</Text>
          </Dropdown.DefaultItem>
        </a>,
        <a key='asana-info' href={ASANA_EXPORT_INSTRUCTION_URL} rel='noopener noreferrer' target='_blank'>
          <Dropdown.DefaultItem>
            <Text size='$12px'>{strings.integrations.asana.importInfo}</Text>
          </Dropdown.DefaultItem>
        </a>,
      ]}
      onHide={() => setShow(false)}
      placement='bottom-end'
      show={show}
    >
      <TopLevelCreationButton onClick={() => setShow((prev) => !prev)} text='Connect' />
    </Dropdown>
  );
}

function ImportSection({
  service,
  onTaskSelect,
  selectedTaskId,
}: {
  service: WorkDrawerImportTasksId;
  onTaskSelect: (taskId: Data.Id.TaskId) => void;
  selectedTaskId: Data.Id.TaskId | undefined;
}): JSX.Element {
  const { tasks } = service;
  const isSearchActive = useIsSearchActive();
  const searchTaskIds = useFilteredSummaryTaskIds({});
  const showDoneTasks = useWorkDrawerSettingsState('tasks:show-done', { checkSearchIsActive: true });

  const showAssignedToSprintTasks = useWorkDrawerSettingsState('tasks:show-assigned-to-sprint', {
    checkSearchIsActive: true,
  });

  const taskIds: Data.Id.TaskId[] =
    tasks &&
    tasks
      .filter(
        (task: SelectTaskFragment): boolean =>
          (showDoneTasks ? true : task.status !== TaskStatus.Done) &&
          (showAssignedToSprintTasks ? true : task.sprint === null) &&
          (isSearchActive ? searchTaskIds.includes(task.id) : true),
      )
      ?.map((task) => task.id);

  const importedTasksDroppableAreaDescription = {
    id: `${service.title}-imported-tasks`,
    type: DroppableType.workdrawerTasks,
    visibleList: taskIds,
    list: taskIds,
  };

  useSetNextTaskId(taskIds, selectedTaskId);

  return (
    <DroppableArea description={importedTasksDroppableAreaDescription} styles={{ minHeight: '0' }}>
      {() => {
        return (
          <WorkDrawerImportsItem
            key={service.title}
            onTaskSelect={onTaskSelect}
            selectedTaskId={selectedTaskId}
            taskIds={taskIds}
            title={service.title}
          />
        );
      }}
    </DroppableArea>
  );
}

function WorkDrawerImportsItem({
  onTaskSelect,
  selectedTaskId,
  taskIds,
  title,
}: {
  onTaskSelect: (taskId: Data.Id.TaskId) => void;
  selectedTaskId: Data.Id.TaskId | undefined;
  taskIds: Data.Id.TaskId[];
  title: string;
}): JSX.Element {
  const [unfolded, setUnfolded] = useWorkDrawerUnfoldedState('imports', selectedTaskId);

  return (
    <Tree onChange={setUnfolded} unfolded={unfolded}>
      <SecondaryHeader
        onClick={noop}
        title={title}
        widget={
          <Tree.FoldedContent>
            <Text size='$10px'>{taskIds?.length}</Text>
          </Tree.FoldedContent>
        }
      />
      <Tree.Content strategy='lazy'>
        <DNDContext.Consumer>{() => <TaskCards onTaskSelect={onTaskSelect} taskIds={taskIds} />}</DNDContext.Consumer>
      </Tree.Content>
    </Tree>
  );
}

const uploadIcon = (
  <svg fill='none' height='12' stroke={tokens.colors.$grey6} width='12' xmlns='http://www.w3.org/2000/svg'>
    <rect height='11' rx='1.5' width='11' x='0.5' y='0.5' />
    <path d='M6 3.5V8.5M6 3.5L4 5.5M6 3.5L8 5.5' strokeLinecap='round' />
  </svg>
);
