import { isNonEmptyString, notUndefined } from '@taraai/utility';
import Avatar, { TAvatarSize } from 'components/core/controllers/views/Avatar';
import { css, cx } from 'emotion';
import React from 'react';
import { useSelector } from 'react-redux';
import { getUsers, selectActiveWorkspace } from 'reduxStore';

export interface AvatarListControllerProps extends Omit<React.HTMLProps<HTMLDivElement>, 'size'> {
  /** styles to apply to avatars */
  avatarClassName?: string;
  /** Size to display all avatars in */
  size?: TAvatarSize;
  /** User id to display in the avatar */
  userId?: string | null;
  /** List of user ids to display as avatars */
  userIds?: (string | null)[];
}

/**
 * AvatarListController
 *
 * Avatar Controller to display an avatar using just user id(s)
 *
 */
export default function AvatarListController({
  className,
  avatarClassName,
  size = 'medium',
  userId = '',
  userIds = [],
  ...props
}: AvatarListControllerProps): JSX.Element {
  const orgId = useSelector(selectActiveWorkspace);
  const ids = Array.from(new Set([userId, ...userIds].filter(isNonEmptyString)));
  const allUsers = useSelector(getUsers(orgId).selector);
  const usersToDisplay = ids.map((id) => allUsers?.find((user) => user.id === id)).filter(notUndefined);

  return (
    <div
      className={cx(
        css`
          display: flex;
          & > * {
            margin-right: 0.5rem;
          }
          & > *:last-child {
            margin-right: 0rem;
          }
        `,
        className,
      )}
      {...props}
    >
      {usersToDisplay.map((user) => (
        <Avatar key={user.id} className={avatarClassName} size={size} user={user} />
      ))}
    </div>
  );
}
