import { Data } from '@taraai/types';
import { matchPath } from 'react-router-dom';

/**
 * We don't need current organization id to be stored because it is a
 * part of URL. So, neither switchOrg, nor handleOrgs callbacks are required.
 * Though a lot of components depend on currentOrgId field presence in the old store
 * (including new redux store's enhancer).
 * That's why there is a temporary workaround that allows to read current orgId from address bar
 * (same way as via react-router-dom's useParams hook).
 * It is not going to stay for a long time and will be replaced with better
 * approach when both router and full redux are done.
 * Until that it is too hard to synchronize work on these 2 topics and don't block each other.
 */
export function getOrgId(): Data.Id.OrganizationId | undefined {
  const matchedPath = matchPath<{ orgId?: Data.Id.OrganizationId }>(window.location.pathname, {
    path: '/:orgId',
  });
  return matchedPath?.params?.orgId;
}

export function getTeamId(): Data.Id.TeamId | undefined {
  const matchedPath = matchPath<{ teamId?: Data.Id.OrganizationId }>(window.location.pathname, {
    path: '/:orgId/:teamId',
  });
  return matchedPath?.params?.teamId;
}
