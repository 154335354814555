import React from 'react';

export function useHover(isHovered = false): [React.MutableRefObject<null>, boolean] {
  const [value, setValue] = React.useState(isHovered);

  const ref = React.useRef(null) as React.MutableRefObject<null>;

  const handleMouseOver = (): void => setValue(true);
  const handleMouseOut = (): void => setValue(false);

  React.useEffect(
    (): (() => void) => {
      const nodeHTML = ref.current as unknown as HTMLElement;
      if (nodeHTML) {
        nodeHTML.addEventListener('mouseover', handleMouseOver);
        nodeHTML.addEventListener('mouseout', handleMouseOut);

        return (): void => {
          nodeHTML.removeEventListener('mouseover', handleMouseOver);
          nodeHTML.removeEventListener('mouseout', handleMouseOut);
        };
      }
      return (): void => undefined;
    },
    [ref], // Recall only if ref changes
  );

  return [ref, value];
}
