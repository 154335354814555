import { compose } from '@reduxjs/toolkit';
import { Data } from '@taraai/types';
import { Selector } from 'react-redux';
import { RootState } from 'reduxStore/store';

import { getWorkspaceCustomClaimsQuery, WorkspaceCustomClaim } from './queries';

export type SubscriptionType = 'co-pilot' | 'premium';

export const getWorkspaceCustomClaims = (
  orgId: Data.Id.OrganizationId,
): Selector<RootState, Record<string, WorkspaceCustomClaim>> =>
  compose(
    (claims) => (claims || []).reduce((acc, claim) => ({ ...acc, [claim.id]: claim }), {}),
    getWorkspaceCustomClaimsQuery(orgId).selector,
  );

export const getSubscriptionType = (orgId: Data.Id.OrganizationId): Selector<RootState, SubscriptionType | null> =>
  compose((claims) => {
    const subscriptionType = claims.subscription?.type;

    switch (subscriptionType) {
      case 'co-pilot':
      case 'premium':
        return subscriptionType;
      default:
        return null;
    }
  }, getWorkspaceCustomClaims(orgId));
