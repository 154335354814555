import { createAsyncThunk } from '@reduxjs/toolkit';
import { Functions } from '@taraai/types';
import { decode } from 'reduxStore/utils/decoders';
import { ExtraAPI } from 'reduxStore/utils/types';

export type UpdateTeamMembershipChangeset = Omit<Functions.UpdateTeamMembership.Payload, 'organizationId'>;

export const updateTeamMembership = createAsyncThunk(
  'UpdateTeamMembership',
  async (changeset: UpdateTeamMembershipChangeset, { extra }) => {
    const { getOrgId, getFirebase } = extra as ExtraAPI;
    const organizationId = getOrgId();

    const payload = decode<Functions.UpdateTeamMembership.Payload>(
      {
        ...changeset,
        organizationId,
      },
      'UpdateTeamMembershipPayload',
    );

    const response = await getFirebase().functions().httpsCallable('updateTeamMembership')(payload);

    return Functions.unwrap(
      decode<Functions.UpdateTeamMembership.Response>(response.data, 'UpdateTeamMembershipResponse'),
    );
  },
);
