import { Data, UI } from '@taraai/types';
import { MutableRefObject, useMemo, useRef } from 'react';
import deepEquals from 'react-fast-compare';
import { useDispatch, useSelector } from 'react-redux';
import { createLabel, defaultLabels, getCustomLabels, selectActiveWorkspace, selectDefaultLabel } from 'reduxStore';
import { useToast } from 'tools';

import {
  composePlugins,
  createAttachmentPlugin,
  createBackspaceDeletePlugin,
  createLabelsPlugin,
  createMaxLengthPlugin,
  createMentionPlugin,
  getWhitespacePlugin,
  linkPlugin,
  useUserTagForId,
} from './plugins';
import { createTaskNumberPlugin } from './plugins/taskNumber';
import { RichEditorConfig, RichEditorPlugin } from './types';

const noopPlugin: Partial<RichEditorPlugin> = {};

export const maxSprintDescriptionLength = 140;

export const maxSprintNameLength = 15;

export const maxTaskTitleLength = 140;

export function useCommentConfig(): RichEditorConfig {
  const orgId = useSelector(selectActiveWorkspace);
  const mentionPlugin = useMentionPlugin(orgId);
  return useMemo(
    () => ({
      parseMode: 'plaintext',
      plugin: mentionPlugin,
    }),
    [mentionPlugin],
  );
}

export function useSprintNameConfig(): RichEditorConfig {
  return useMemo(
    () => ({
      parseMode: 'plaintext',
      plugin: composePlugins(
        getWhitespacePlugin({ trim: true, collapse: true }),
        createMaxLengthPlugin({ maxLength: maxSprintNameLength }),
      ),
      singleLine: true,
    }),
    [],
  );
}

export function useSprintDescriptionConfig(): RichEditorConfig {
  return useMemo(
    () => ({
      parseMode: 'plaintext',
      plugin: composePlugins(
        getWhitespacePlugin({ trim: true, collapse: true }),
        createMaxLengthPlugin({ maxLength: maxSprintDescriptionLength }),
      ),
    }),
    [],
  );
}

export function useTaskTitleConfig({
  mentions,
  onBackspaceDelete,
  singleLine,
}: {
  mentions?: boolean;
  onBackspaceDelete?: (() => void) | undefined;
  singleLine?: boolean;
} = {}): RichEditorConfig {
  const orgId = useSelector(selectActiveWorkspace);
  const mentionPlugin = useMentionPlugin(orgId);
  const labelsPlugin = useLabelsPlugin(orgId);
  return useMemo(
    () => ({
      parseMode: 'plaintext',
      plugin: composePlugins(
        getWhitespacePlugin({ trim: true, collapse: true }),
        labelsPlugin,
        mentions ? mentionPlugin : noopPlugin,
        createMaxLengthPlugin({ maxLength: maxTaskTitleLength }),
        onBackspaceDelete ? createBackspaceDeletePlugin(onBackspaceDelete) : noopPlugin,
      ),
      singleLine,
    }),
    [labelsPlugin, mentionPlugin, mentions, onBackspaceDelete, singleLine],
  );
}

export function useTaskDescriptionConfig({
  onAttachment,
}: {
  onAttachment: (file: File) => Promise<string>;
}): RichEditorConfig {
  const orgId = useSelector(selectActiveWorkspace);
  const mentionPlugin = useMentionPlugin(orgId);
  const labelsPlugin = useLabelsPlugin(orgId);
  const attachmentPlugin = useAttachmentPlugin(onAttachment);
  return useMemo(
    () => ({
      parseMode: 'markdown',
      plugin: composePlugins(
        labelsPlugin,
        mentionPlugin,
        linkPlugin,
        attachmentPlugin,
        // TODO: enable when it's ready or moved to feature flag
        // createCommentsPlugin({
        //   getComments: () => MOCK_COMMENTS,
        // }),
      ),
    }),
    [attachmentPlugin, labelsPlugin, mentionPlugin],
  );
}

export function useRequirementTitleConfig({
  singleLine,
}: {
  singleLine?: boolean;
} = {}): RichEditorConfig {
  return useMemo(
    () => ({
      parseMode: 'plaintext',
      plugin: getWhitespacePlugin({ trim: true, collapse: true }),
      singleLine,
    }),
    [singleLine],
  );
}

export function useRequirementDescriptionConfig({
  onAttachment,
}: {
  onAttachment: (file: File) => Promise<string>;
}): RichEditorConfig {
  const orgId = useSelector(selectActiveWorkspace);
  const mentionPlugin = useMentionPlugin(orgId);
  const attachmentPlugin = useAttachmentPlugin(onAttachment);
  return useMemo(
    () => ({
      parseMode: 'markdown',
      plugin: composePlugins(mentionPlugin, linkPlugin, attachmentPlugin),
    }),
    [attachmentPlugin, mentionPlugin],
  );
}

export function useSearchBarConfig(): RichEditorConfig {
  const orgId = useSelector(selectActiveWorkspace);
  const mentionPlugin = useMentionPlugin(orgId);
  const labelsPlugin = useLabelsPlugin(orgId);
  const taskNumberPlugin = useTaskNumberPlugin();
  return useMemo(
    () => ({
      parseMode: 'plaintext',
      plugin: composePlugins(
        getWhitespacePlugin({ trim: true, collapse: true }),
        labelsPlugin,
        mentionPlugin,
        taskNumberPlugin,
      ),
      singleLine: true,
    }),
    [labelsPlugin, mentionPlugin, taskNumberPlugin],
  );
}

// Simple plugin hooks
function useAttachmentPlugin(onAttachment: (file: File) => Promise<string>): Partial<RichEditorPlugin> {
  const { addToast } = useToast();
  return useMemo(() => createAttachmentPlugin(addToast, onAttachment), [addToast, onAttachment]);
}

export function useLabelsPlugin(orgId: Data.Id.OrganizationId): Partial<RichEditorPlugin> {
  const dispatch = useDispatch();
  const customLabels = useSelector(getCustomLabels(orgId).selector, deepEquals);
  // Don't recreate the plugin every time labels change
  const labels = useRef() as MutableRefObject<UI.UILabel[]>;
  labels.current = [
    ...(Object.keys(defaultLabels) as Data.DefaultLabelId[]).map(selectDefaultLabel),
    ...(customLabels || []),
  ];
  return useMemo(
    () =>
      createLabelsPlugin({
        createLabel: (title) => dispatch(createLabel(title)),
        getLabels: () => labels.current,
      }),
    [dispatch],
  );
}

export function useMentionPlugin(orgId: Data.Id.OrganizationId): Partial<RichEditorPlugin> {
  const getUserTagForId = useUserTagForId(orgId);
  return useMemo(() => createMentionPlugin(getUserTagForId), [getUserTagForId]);
}

export function useTaskNumberPlugin(): Partial<RichEditorPlugin> {
  return createTaskNumberPlugin();
}
