import { Box, styled, Text } from '@taraai/design-system';
import { Data } from '@taraai/types';
import { linkTo } from 'components/Router/paths';
import React from 'react';
import { Link } from 'react-router-dom';
import { strings } from 'resources';

type Props = {
  isPremium: boolean;
  orgId: Data.Id.OrganizationId;
  teamId: Data.Id.TeamId;
};

export const CurrentPlan = ({ isPremium, orgId, teamId }: Props): JSX.Element | null =>
  isPremium ? (
    <HorizontalRule />
  ) : (
    <Container isPremium={isPremium}>
      <Text color='$white' size='$14px' textAlign='left'>
        {strings.workspacePulldown.freeTier}{' '}
        <StyledLink to={linkTo('upgrade', { orgId, teamId })}>{strings.workspacePulldown.seePlans}</StyledLink>
      </Text>
    </Container>
  );

const Container = styled(
  Box,
  {
    padding: '16px',
  },
  {
    isPremium: {
      true: { background: '$background' },
      false: { background: 'linear-gradient(135.19deg, #58A6E7 0.34%, #775DFA 100%)' },
    },
  },
);

const StyledLink = styled(Link, {
  width: 'fit-content',
  cursor: 'pointer',
  textDecoration: 'underline',
});

const HorizontalRule = styled(Box, {
  border: 0,
  minHeight: '0.0625rem',
  background: '#eaeef5',
});
