import { UI } from '@taraai/types';
import Button from 'components/core/controllers/views/Button';
import Logo from 'components/core/controllers/views/Logo';
import { StandardSpinner } from 'components/core/controllers/views/Spinners';
import Text from 'components/core/controllers/views/Text';
import { css } from 'emotion';
import React from 'react';
import { Async, unwrap } from 'reduxStore';
import { strings } from 'resources/i18n';

import ExpiredInvitation from './ExpiredInvitation';

export type JoinWorkspaceLayoutProps = {
  organizationInvitation: Async<UI.OrganizationInvitation>;
  isLoading: boolean;
  onJoinClick: () => void;
};

export default function JoinWorkspaceLayout({
  organizationInvitation,
  isLoading,
  onJoinClick,
}: JoinWorkspaceLayoutProps): JSX.Element {
  const { pending, data, error } = unwrap(organizationInvitation);
  if (isLoading || pending) return <StandardSpinner fillSpace size='medium' />;
  if (error) return <ExpiredInvitation />;

  return (
    <div
      className={css`
        margin: auto;
        padding-top: 7rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;
        overflow: auto;
        @media (max-width: 500px) {
          width: 90%;
          padding-top: 18%;
        }
      `}
    >
      {data && (
        <>
          <Logo
            className={css`
              margin: 1rem auto;
            `}
            color={data.organization.color}
            figureClassName={css`
              border-radius: 0.5rem;
              box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.21);
            `}
            name={data.organization.name}
            shape='square'
            size='7.5rem'
            url={data.organization.logoURL}
          />
          <Text
            className={css`
              font-size: 1.5rem;
              padding: 1rem;
              font-weight: 600;
              color: #4550ce;
              text-align: center;
            `}
          >
            {data.inviterName
              ? strings.formatString(strings.joinWorkspace.invitationMessageWithInviterName, {
                  inviterName: data.inviterName,
                  organizationName: data.organization.name,
                })
              : strings.formatString(strings.joinWorkspace.invitationMessageWithoutInviterName, {
                  organizationName: data.organization.name,
                })}
          </Text>
          <Text
            className={css`
              font-size: 1.125rem;
              padding: 0.25rem;
              color: #67728b;
              text-align: center;
            `}
          >
            {strings.joinWorkspace.createAccount}
          </Text>
          <Button
            className={css`
              height: 2.375rem;
              padding: 0;
              margin: 2rem 0 2rem;
              width: 25rem;
              max-width: 100%;
            `}
            color='primary'
            name={strings.joinWorkspace.joinTara}
            onClick={onJoinClick}
            size='small'
          >
            {strings.joinWorkspace.joinTara}
          </Button>
        </>
      )}
    </div>
  );
}
