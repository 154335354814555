/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import 'remirror/styles/all.css';
import './editor-tara-defaults.css';

import { FileAttributes, FileExtension, FileOptions } from '@remirror/extension-file';
// THIS IS A WIP DOCUMENT
import {
  ComponentItem,
  NodeViewComponentProps,
  ReactExtensions,
  Remirror,
  TableExtension,
  ThemeProvider,
  Toolbar,
  ToolbarItemUnion,
  useHelpers,
  useKeymap,
  useRemirror,
  UseRemirrorReturn,
} from '@remirror/react';
import { Box, styled, VStack } from '@taraai/design-system';
import { Data, UI } from '@taraai/types';
import { createContextState } from 'create-context-state';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import {
  defaultLabels,
  getCustomLabels,
  selectActiveUsers,
  selectActiveWorkspace,
  selectDefaultLabel,
} from 'reduxStore';
import jsx from 'refractor/lang/jsx';
// eslint-disable-next-line import/no-named-default
import { default as markdownLanguage } from 'refractor/lang/markdown';
import typescript from 'refractor/lang/typescript';
import { AnyExtension, getThemeVar, keys, UploadContext } from 'remirror';
import {
  BlockquoteExtension,
  BoldExtension,
  BulletListExtension,
  CodeBlockExtension,
  CodeExtension,
  DocExtension,
  DropCursorExtension,
  HardBreakExtension,
  HeadingExtension,
  ImageExtension,
  ItalicExtension,
  LinkExtension,
  ListItemExtension,
  MarkdownExtension,
  MentionAtomExtension,
  MentionExtensionAttributes,
  OrderedListExtension,
  PlaceholderExtension,
  StrikeExtension,
  TrailingNodeExtension,
} from 'remirror/extensions';
import { useDebounced } from 'tools/utils/hooks/useDebounced';

import { createTaraFileUploader } from './extensions/file/fileUploader';
import { FileWithProgress, imageUploader } from './extensions/file/imageUploader';
import { FloatingLinkToolbar } from './extensions/Link/link';
import { htmlToMarkdown } from './extensions/markdown/html-to-markdown';
import { markdownToHTML } from './extensions/markdown/markdownToHTML';
import { Suggestor, SuggestorOptions } from './extensions/suggestor/Suggestor';
// import * as awarenessProtocol from 'y-protocols/awareness';
// import { WebrtcProvider } from 'y-webrtc';
// import * as Y from 'yjs';

// @TODO: Create a new component ✅
// @TODO: Modularize in the new component ✅
// @TODO: Replace all css classNames by the design system ✅
// @TODO: Cleanup RemirrorDefinePanel ✅
// @TODO: Cleanup dependencies (specially: createContextState and MarkdownEditor) ✅

// @TODO: Bullets list ✅
// @TODO: Links ✅
// @TODO: File Upload ✅
// @TODO: Add Mentions ✅
// @TODO: Add Labels ✅
// @TODO: Remove old toolbar ✅

// @TODO: Re-add Coediting ❌
// @TODO: BE conflict resolution: The last one that saves, is the SSOT ❌

// const ydoc = new Y.Doc();
// clients connected to the same room-name share document updates
// @ts-ignore
// const provider = new WebrtcProvider('remirror-yjs-demo', ydoc, {
//   // signaling: ['ws://localhost:4444'],
//   //   password: null,
//   //   awareness: null,
//   //   maxConns: null,
//   //   filterBcConns: null,
//   //   peerOpts: {},
// });

// const extensions = () => [
//   new AnnotationExtension(),
//   new PlaceholderExtension({ placeholder: 'Open second tab and start to type...' }),
//   // new YjsExtension({ getProvider: () => provider }),
//   new MarkdownExtension(),
// ];

export type FileComponentProps = NodeViewComponentProps & {
  context?: UploadContext;
  abort: () => void;
};

interface UseSaveHook {
  saving: boolean;
  error: Error | undefined;
}

export type fileSavePathType = 'tasks' | 'requirements';

interface EditorContext extends Props {
  setMarkdown: (markdown: string) => void;
  setVisual: (
    markdown: string,
    allLabels: MentionExtensionAttributes[],
    allUsers: MentionExtensionAttributes[],
  ) => void;
}

interface Props {
  visual: UseRemirrorReturn<ReactExtensions<ReturnType<() => AnyExtension[]>[number]>>;
  markdown: UseRemirrorReturn<ReactExtensions<DocExtension | CodeBlockExtension>>;
}

const [DualEditorProvider, useDualEditor] = createContextState<EditorContext, Props>(({ props }) => {
  return {
    ...props,

    setMarkdown: (text: string) => {
      return props.markdown.getContext()?.setContent({
        type: 'doc',
        content: [
          {
            type: 'codeBlock',
            attrs: { language: 'markdown' },
            content: text ? [{ type: 'text', text }] : undefined,
          },
        ],
      });
    },
    setVisual: (markdown: string, allLabels: MentionExtensionAttributes[], allUsers: MentionExtensionAttributes[]) =>
      props.visual.getContext()?.setContent(markdown),
  };
});

export const RemirrorEditor = React.memo(
  function RemirrorEditor({
    initialContent,
    onSave,
    placeholder,
    id,
    type,
  }: {
    initialContent: string;
    onSave: (value: string) => Promise<string | Error | null>;
    placeholder: string;
    id: Data.Id.TaskId | Data.Id.RequirementId;
    type: fileSavePathType;
  }): JSX.Element {
    const orgId = useSelector(selectActiveWorkspace);
    const savePath = `orgs/${orgId}/${type}/`;
    const [isTextEditorVisible, setIsTextEditorVisible] = useState(true);
    const allUsers = useSelector(
      compose((data: UI.UIUser[]) => data.map((user) => ({ id: user.id, label: user.name })), selectActiveUsers(orgId)),
      deepEquals,
    ) as MentionExtensionAttributes[];

    const defaultLabelMentions = keys(defaultLabels)
      .map(selectDefaultLabel)
      .map((label) => ({
        id: label.id,
        label: label.title,
      }));

    const allLabels = useSelector(
      compose(
        (customLabelMentions: MentionExtensionAttributes[]) => [...defaultLabelMentions, ...customLabelMentions],
        (customLabelsData: UI.UICustomLabel[]) =>
          customLabelsData ? customLabelsData.map((label) => ({ id: label.id, label: label.title })) : [],
        getCustomLabels(orgId).selector,
      ),
      deepEquals,
    ) as MentionExtensionAttributes[];

    const markdownToHtml = useCallback(
      (content: string) => (!content ? '' : markdownToHTML(content, allUsers, allLabels)),
      [allLabels, allUsers],
    );
    const taraFileUploader = createTaraFileUploader({ path: savePath, id });
    // eslint-disable-next-line id-length
    const matcherOptions = { at: allUsers, hash: allLabels } as SuggestorOptions;
    const extensions = (() => [
      new LinkExtension({ autoLink: true }),
      new BoldExtension(),
      new StrikeExtension(),
      new ItalicExtension(),
      new HeadingExtension(),
      new BlockquoteExtension(),
      new BulletListExtension({ enableSpine: true }),
      new OrderedListExtension(),
      new ListItemExtension({ enableCollapsible: true }),
      new CodeExtension(),
      new CodeBlockExtension({ supportedLanguages: [jsx, typescript, markdownLanguage] }),
      new TrailingNodeExtension(),
      new TableExtension(),
      new MarkdownExtension({
        // overwritting the remirror method to add Tara customisations
        htmlToMarkdown,
        markdownToHtml,
        htmlSanitizer: (html) => {
          return html;
        },
      }),
      new ImageExtension({
        enableResizing: true,
        uploadHandler: (files: FileWithProgress[]) => imageUploader(files, savePath, id),
      }),
      /**
       * `HardBreakExtension` allows us to create a newline inside paragraphs.
       * e.g. in a list item
       */
      new HardBreakExtension(),
      new FileExtension({
        uploadFileHandler: () => taraFileUploader,
        pasteRuleRegexp: /.*/,
        // Since we have our own rendering outside of the editor
        // we just render nothing where the user drops the file
        render: (file: FileComponentProps) => {
          if (!/^((?!image).)*$/i.test(file.node.attrs.fileType)) {
            if (file.node.attrs.url && file.node.attrs.url !== '') return <img alt='' src={file.node.attrs.url} />;
            return <>Uploading...</>;
          }
          return <></>;
        },
      } as FileOptions),
      new DropCursorExtension(),
      new MentionAtomExtension({
        // This attribute is important so the editor detects the mentions/labels when prefilled
        extraAttributes: { type: 'suggested' },
        mentionTag: 'span',
        matchers: [
          { name: 'at', char: '@', appendText: ' ', matchOffset: 0 },
          { name: 'hash', char: '#', appendText: ' ', matchOffset: 0 },
        ],
      }),
      new PlaceholderExtension({ placeholder }),
    ]) as () => AnyExtension[];

    const toolbarItems: ToolbarItemUnion[] = [
      {
        type: ComponentItem.ToolbarGroup,
        label: 'Simple Formatting',
        items: [
          { type: ComponentItem.ToolbarCommandButton, commandName: 'toggleBold', display: 'icon' },
          { type: ComponentItem.ToolbarCommandButton, commandName: 'toggleItalic', display: 'icon' },
          { type: ComponentItem.ToolbarCommandButton, commandName: 'toggleStrike', display: 'icon' },
          { type: ComponentItem.ToolbarCommandButton, commandName: 'toggleCode', display: 'icon' },
        ],
        separator: 'end',
      },
      {
        type: ComponentItem.ToolbarGroup,
        label: 'Heading Formatting',
        items: [
          {
            type: ComponentItem.ToolbarCommandButton,
            commandName: 'toggleHeading',
            display: 'icon',
            attrs: { level: 1 },
          },
          {
            type: ComponentItem.ToolbarCommandButton,
            commandName: 'toggleHeading',
            display: 'icon',
            attrs: { level: 2 },
          },
          {
            type: ComponentItem.ToolbarMenu,
            items: [
              {
                type: ComponentItem.MenuGroup,
                role: 'radio',
                items: [
                  {
                    type: ComponentItem.MenuCommandPane,
                    commandName: 'toggleHeading',
                    attrs: { level: 3 },
                  },
                  {
                    type: ComponentItem.MenuCommandPane,
                    commandName: 'toggleHeading',
                    attrs: { level: 4 },
                  },
                  {
                    type: ComponentItem.MenuCommandPane,
                    commandName: 'toggleHeading',
                    attrs: { level: 5 },
                  },
                  {
                    type: ComponentItem.MenuCommandPane,
                    commandName: 'toggleHeading',
                    attrs: { level: 6 },
                  },
                ],
              },
            ],
          },
        ],
        separator: 'end',
      },
      {
        type: ComponentItem.ToolbarGroup,
        label: 'Lists',
        items: [
          {
            type: ComponentItem.ToolbarCommandButton,
            commandName: 'toggleBulletList',
            display: 'icon',
          },
          {
            type: ComponentItem.ToolbarCommandButton,
            commandName: 'toggleOrderedList',
            display: 'icon',
          },
        ],
        separator: 'none',
      },
      {
        type: ComponentItem.ToolbarGroup,
        label: 'Simple Formatting',
        items: [
          {
            type: ComponentItem.ToolbarCommandButton,
            commandName: 'toggleBlockquote',
            display: 'icon',
          },
          { type: ComponentItem.ToolbarCommandButton, commandName: 'toggleCodeBlock', display: 'icon' },
        ],
        separator: 'end',
      },
      {
        type: ComponentItem.ToolbarGroup,
        label: 'History',
        items: [
          { type: ComponentItem.ToolbarCommandButton, commandName: 'undo', display: 'icon' },
          { type: ComponentItem.ToolbarCommandButton, commandName: 'redo', display: 'icon' },
        ],
        separator: 'none',
      },
      {
        type: ComponentItem.ToolbarGroup,
        label: 'Markdown',
        items: [
          {
            type: ComponentItem.ToolbarButton,
            onClick: () => setIsTextEditorVisible(!isTextEditorVisible),
            icon: 'markdownLine',
          },
        ],
        separator: 'none',
      },
    ];

    const rawEditorToolbarItems: ToolbarItemUnion[] = [
      {
        type: ComponentItem.ToolbarGroup,
        label: 'Markdown',
        items: [
          {
            type: ComponentItem.ToolbarButton,
            onClick: () => setIsTextEditorVisible(!isTextEditorVisible),
            icon: 'markdownLine',
          },
        ],
        separator: 'none',
      },
    ];

    const remirrorEditor = useRemirror({
      extensions,
      stringHandler: 'markdown',
      content: '',
    });

    const markdownEditor = useRemirror({
      extensions: () => [
        new DocExtension({ content: 'codeBlock' }),
        new CodeBlockExtension({
          supportedLanguages: [markdownLanguage, typescript],
          defaultLanguage: 'markdown',
          syntaxTheme: 'base16_ateliersulphurpool_light',
          defaultWrap: true,
        }),
      ],
      // extensions,
      builtin: {
        exitMarksOnArrowPress: false,
      },

      stringHandler: 'html',
    });

    // Why is this needed?
    // When accessing the first time to the Tara App, the "allUsers" var will be empty,
    // as the users are still been loaded.
    // Once the users are loaded, we re-render the content
    const textRef = useRef<string>();
    useEffect(() => {
      const context = remirrorEditor.getContext();
      remirrorEditor.manager.view.updateState(remirrorEditor.manager.createState({ content: initialContent }));
      if (context) {
        textRef.current = initialContent;
      }
    }, [allUsers, initialContent, remirrorEditor, allLabels]);

    const saveIt = useCallback(async () => {
      if (textRef.current) await onSave(textRef.current);
      return true;
    }, [onSave]);

    const debouncedSave = useDebounced(saveIt, 2100);

    function useSaveHook() {
      const helpers = useHelpers();
      const [state, setState] = useState<UseSaveHook>({ saving: false, error: undefined });

      useKeymap(
        'Mod-s',
        useCallback(() => {
          // Convert the editor content to markdown.
          const html = helpers.getHTML();
          const markdownToSave = htmlToMarkdown(html);
          if (markdownToSave !== textRef.current) {
            setState({ saving: true, error: undefined });
            onSave(markdownToSave);
          }
          return true;
        }, [helpers]),
      );

      return state;
    }

    const MarkdownTextEditor = () => {
      const { markdown, setVisual } = useDualEditor();

      return (
        <div className='markdowntextEditorWrapper'>
          <Remirror
            autoRender='end'
            manager={markdown.manager}
            onChange={({ helpers, state }) => {
              const newMD = helpers.getText({ state });
              if (textRef.current !== undefined && newMD !== textRef.current) {
                textRef.current = newMD;
                debouncedSave();
              }
              return setVisual(newMD, allLabels, allUsers);
            }}
          >
            <TopToolbarWrapper className='top-toolbar'>
              <Toolbar items={rawEditorToolbarItems} label='Raw Editor Top Toolbar' refocusEditor />
            </TopToolbarWrapper>
          </Remirror>
        </div>
      );
    };

    const VisualEditor = () => {
      const { visual, setMarkdown } = useDualEditor();
      return (
        <div className='visualEditorWrapper'>
          <Remirror
            autoFocus
            autoRender='end'
            hooks={[useSaveHook]}
            initialContent={visual.state}
            manager={visual.manager}
            onChange={({ helpers, state }) => {
              const newMD = helpers.getMarkdown(state);
              if (textRef.current !== undefined && newMD !== textRef.current) {
                // textRef.current = htmlToMarkdown(html);
                textRef.current = newMD;
                debouncedSave();
              }
              return setMarkdown(newMD);
            }}
          >
            <TopToolbarWrapper className='top-toolbar'>
              <Toolbar items={toolbarItems} label='Top Toolbar' refocusEditor />
            </TopToolbarWrapper>
            <Suggestor matcherOptions={matcherOptions} />
            <FloatingLinkToolbar />
          </Remirror>
        </div>
      );
    };

    return (
      <EditorWrapper full isTextEditorVisible={isTextEditorVisible}>
        <DualEditorProvider markdown={markdownEditor} visual={remirrorEditor}>
          <VStack full>
            <ThemeProvider>
              <VisualEditor />
              <MarkdownTextEditor />
            </ThemeProvider>
          </VStack>
        </DualEditorProvider>
      </EditorWrapper>
    );
  },
  (prevProps, nextProps) => prevProps.initialContent !== nextProps.initialContent,
);

const TopToolbarWrapper = styled(Box, {
  '& .remirror-toolbar': {
    'height': '71px',
    '.remirror-separator': {
      height: '34px',
    },
    '.markdowntextEditorWrapper & button': {
      backgroundColor: 'var(--rmr-color-hover-background)',
      color: 'white',
    },
    'padding': '$12px',
    '& .remirror-group': {
      'height': '35px',
      'borderRadius': '6px',
      'border': '1px solid',
      'borderColor': 'var(--rmr-color-border)',
      'overflow': 'hidden',
      '&>div:not(:first-child), button.remirror-menu-button': {
        'borderColor': 'var(--rmr-color-border)',
        'borderWidth': '1px',
        'borderStyle': 'none none none solid',
        '&:hover:not(.remirror-dialog):not[aria-disabled="true"]': {
          backgroundColor: 'var(--rmr-color-hover-background)',
        },
        '.remirror-button[aria-disabled="true"]': {
          backgroundColor: 'var(--rmr-color-background-disabled)',
        },
      },
      '& button': {
        '&:not(.remirror-menu-button)': {
          borderRadius: 0,
          height: '100%',
          borderStyle: 'none',
        },
      },
    },

    '[aria-checked="true"] .remirror-menu-pane-icon, [aria-checked="true"] .remirror-menu-pane-shortcut, [aria-checked="true"] .remirror-menu-pane-label, .remirror-button:not([aria-disabled="true"])[aria-expanded="true"]':
      { color: 'var(--rmr-hue-gray-6)' },
    '& .remirror-popover': {
      '& .remirror-menu-group div': {
        background: 'white',
      },
    },
  },
});

const EditorWrapper = styled(
  Box,
  {
    '& .remirror-theme': {
      'height': '100%',
      '& .visualEditorWrapper, & .markdowntextEditorWrapper': {
        overflow: 'hidden',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      },
      '& .remirror-editor-wrapper': {
        'fontSize': '$14px',
        'flexGrow': 1,
        'overflowY': 'auto',
        'paddingTop': '0px',
        // START CONTENT STYLES
        '& .ProseMirror': {
          'paddingLeft': '25px !important', // Required to show the bullets
          'boxShadow': 'none !important',
          'flexGrow': '1',
          'p, h3, h4': {
            marginTop: getThemeVar('space', 2),
            marginBottom: getThemeVar('space', 2),
          },
          'h1, h2': {
            marginBottom: getThemeVar('space', 3),
            marginTop: getThemeVar('space', 3),
          },
          '& ol': {
            marginLeft: '$16px',
            padding: '$12px 0px',
          },
          // Bullet dot color
          '.remirror-collapsible-list-item-button': {
            backgroundColor: '$dark',
          },
          'pre code': {
            color: 'inherit',
            background: 'inherit',
            padding: 'inherit',
            borderRadius: 'inherit',
            lineHeight: 2.5,
          },
          'code': {
            color: '#A10563',
            background: '#F4F4FA',
            padding: '5px 10px',
            borderRadius: '4px',
          },
          'img': {
            maxWidth: '100%',
          },
        },
        '& .remirror-editor': {
          'overflow': 'auto',
          '& ul': {
            marginLeft: '$24px',
            padding: '$12px 0px',
          },
          '& li': {
            position: 'static',
          },
        },
        '& a': {
          color: '$focus',
        },
        // END CONTENT STYLES
      },
      '& .remirror-toolbar': {
        'borderBottom': '1px solid',
        'borderBottomColor': '$grey2',
        '& .remirror-role:last-child': {
          marginLeft: 'auto',
        },
      },
    },
    '& .remirror-floating-popover': {
      'zIndex': 9,
      'backgroundColor': '$white',
      'boxShadow': '$popup',
      'button': {
        height: '100%',
      },
      '& .remirror-toolbar': {
        height: '36px',
      },
    },
  },
  {
    isTextEditorVisible: {
      true: {
        '.markdowntextEditorWrapper': { display: 'none !important' },
      },
      false: {
        '.visualEditorWrapper': {
          display: 'none !important',
        },
      },
    },
  },
);
