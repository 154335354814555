import { useCallback, useState } from 'react';

import { Color, ColorPickerProps } from './ColorPicker';

type Props = {
  initialColor: Color;
  onChange?: (color: Color) => void;
};

/*
 * Hook to use with ColorPicker component
 */
export function useColorPicker({ initialColor, onChange }: Props): ColorPickerProps {
  const [color, setColor] = useState<Color>(initialColor);

  const onChangeHandler = useCallback(
    (newColor: Color): void => {
      setColor(newColor);
      return onChange?.(newColor);
    },
    [onChange],
  );
  return { color, onChange: onChangeHandler };
}
