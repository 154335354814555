import {
  ComponentItem,
  FloatingToolbar,
  FloatingWrapper,
  ToolbarItemUnion,
  useActive,
  useAttrs,
  useChainedCommands,
  useCurrentSelection,
  useExtensionEvent,
  useUpdateReason,
} from '@remirror/react';
import { styled } from '@taraai/design-system';
import type {
  ChangeEvent,
  Dispatch,
  HTMLProps,
  JSXElementConstructor,
  KeyboardEvent,
  ReactElement,
  SetStateAction,
} from 'react';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { createMarkPositioner, LinkExtension, PositionerParam, ShortcutHandlerProps } from 'remirror/extensions';

type useLinkShortcutReturn = {
  linkShortcut: ShortcutHandlerProps | undefined;
  isEditing: boolean;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
};

function useLinkShortcut(): useLinkShortcutReturn {
  const [linkShortcut, setLinkShortcut] = useState<ShortcutHandlerProps | undefined>();
  const [isEditing, setIsEditing] = useState(false);

  useExtensionEvent(
    LinkExtension,
    'onShortcut',
    useCallback(
      (props) => {
        if (!isEditing) {
          setIsEditing(true);
        }

        return setLinkShortcut(props);
      },
      [isEditing],
    ),
  );

  return { linkShortcut, isEditing, setIsEditing };
}

type useFloatingLinkStateReturn = {
  href: string;
  setHref: Dispatch<SetStateAction<string>>;
  linkShortcut: ShortcutHandlerProps | undefined;
  linkPositioner: PositionerParam;
  isEditing: boolean;
  clickEdit: () => void;
  onRemove: () => void;
  submitHref: () => void;
  cancelHref: () => void;
  clickVisit: () => void;
};

function useFloatingLinkState(): useFloatingLinkStateReturn {
  const chain = useChainedCommands();
  const { isEditing, linkShortcut, setIsEditing } = useLinkShortcut();
  const { to, empty } = useCurrentSelection();

  const url = (useAttrs().link()?.href as string) ?? '';
  const [href, setHref] = useState<string>(url);

  // A positioner which only shows for links.
  const linkPositioner = useMemo(() => createMarkPositioner({ type: 'link' }), []);

  const onRemove = useCallback(() => {
    return chain.removeLink().focus().run();
  }, [chain]);

  const updateReason = useUpdateReason();

  useLayoutEffect(() => {
    if (!isEditing) {
      return;
    }

    if (updateReason.doc || updateReason.selection) {
      setIsEditing(false);
    }
  }, [isEditing, setIsEditing, updateReason.doc, updateReason.selection]);

  useEffect(() => {
    setHref(url);
  }, [url]);

  const submitHref = useCallback(() => {
    setIsEditing(false);
    const range = linkShortcut ?? undefined;

    if (href === '') {
      chain.removeLink();
    } else {
      chain.updateLink({ href, auto: false }, range);
    }

    chain.focus(range?.to ?? to).run();
  }, [setIsEditing, linkShortcut, chain, href, to]);

  const cancelHref = useCallback(() => {
    setIsEditing(false);
  }, [setIsEditing]);

  const clickEdit = useCallback(() => {
    if (empty) {
      chain.selectLink();
    }

    setIsEditing(true);
  }, [chain, empty, setIsEditing]);

  const clickVisit = useCallback(() => {
    // That's a false positive, eslint mistakes it for Node's fs.open()
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    window.open(href, '_blank')?.focus();
    setIsEditing(false);
  }, [href, setIsEditing]);

  return useMemo(
    () => ({
      href,
      setHref,
      linkShortcut,
      linkPositioner,
      isEditing,
      clickEdit,
      onRemove,
      submitHref,
      cancelHref,
      clickVisit,
    }),
    [href, linkShortcut, linkPositioner, isEditing, clickEdit, onRemove, submitHref, cancelHref, clickVisit],
  );
}

const DelayAutoFocusInput = ({
  autoFocus,
  ...rest
}: HTMLProps<HTMLInputElement>): ReactElement<unknown, string | JSXElementConstructor<unknown>> => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!autoFocus) {
      return;
    }

    const frame = window.requestAnimationFrame(() => {
      inputRef.current?.focus();
    });

    return () => {
      window.cancelAnimationFrame(frame);
    };
  }, [autoFocus]);

  return <input ref={inputRef} {...rest} />;
};

export const FloatingLinkToolbar = (): ReactElement<unknown, string | JSXElementConstructor<unknown>> => {
  const { isEditing, linkPositioner, clickEdit, onRemove, submitHref, href, setHref, cancelHref, clickVisit } =
    useFloatingLinkState();

  const active = useActive();
  const activeLink = active.link();
  const { empty } = useCurrentSelection();
  const linkEditItems: ToolbarItemUnion[] = useMemo(
    () => [
      {
        type: ComponentItem.ToolbarGroup,
        label: 'Link',
        items: activeLink
          ? [
              {
                type: ComponentItem.ToolbarButton,
                onClick: () => clickVisit(),
                icon: 'externalLinkFill',
              },
              { type: ComponentItem.ToolbarButton, onClick: () => clickEdit(), icon: 'pencilLine' },
              { type: ComponentItem.ToolbarButton, onClick: onRemove, icon: 'linkUnlink' },
            ]
          : [{ type: ComponentItem.ToolbarButton, onClick: () => clickEdit(), icon: 'link' }],
      },
    ],
    [activeLink, clickVisit, onRemove, clickEdit],
  );

  const items: ToolbarItemUnion[] = useMemo(() => linkEditItems, [linkEditItems]);

  return (
    <>
      <FloatingToolbar enabled={!isEditing} items={items} placement='top' positioner='selection' />
      <FloatingToolbar
        enabled={!isEditing && empty}
        items={linkEditItems}
        placement='bottom'
        positioner={linkPositioner}
      />

      <FloatingWrapper enabled={isEditing} placement='bottom' positioner='always' renderOutsideEditor>
        <DelayAutoFocusInputWrapper>
          <DelayAutoFocusInput
            autoFocus
            onChange={(event: ChangeEvent<HTMLInputElement>) => setHref(event.target.value)}
            onKeyPress={(event: KeyboardEvent<HTMLInputElement>) => {
              const { code } = event;

              if (code === 'Enter') {
                submitHref();
              }

              if (code === 'Escape') {
                cancelHref();
              }
            }}
            placeholder='Enter link...'
            // style={{  }}
            value={href}
          />
        </DelayAutoFocusInputWrapper>
      </FloatingWrapper>
    </>
  );
};

const DelayAutoFocusInputWrapper = styled('div', {
  'padding': '5px 10px',
  'background': 'white',
  'borderRadius': '0.1875rem',
  'boxShadow': '$popup',
  'border': 'borderWidths.$1px solid colors.$grey5',
  'height': '36px',
  'input, input:focus': {
    outline: 'none !important',
    border: 'none',
  },
});
