import React from 'react';

import * as brand from './brand';
import * as flat from './flat';
import * as regular from './regular';
import * as solid from './solid';
import * as tara from './tara';

export type IconName =
  | keyof typeof brand
  | keyof typeof flat
  | keyof typeof regular
  | keyof typeof solid
  | keyof typeof tara;

export const AllIcons: {
  [K in IconName]: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | string;
} = {
  ...brand,
  ...solid,
  ...regular,
  ...flat,
  ...tara,
};

export const ICONS: { [K in IconName]: K } = Object.keys(AllIcons).reduce(
  (acc, icon) => ({ ...acc, [icon]: icon }),
  {},
) as { [K in IconName]: K };

export function getIcon(name: IconName): React.FunctionComponent<React.SVGProps<SVGSVGElement>> | string {
  return AllIcons[name];
}
