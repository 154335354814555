import { Box, styled, Text, tokens, Tooltip } from '@taraai/design-system';
import { InjectDisplayFlexProps } from 'components/app/GitInsights/Shared/utils';
import React, { ReactElement } from 'react';
import { QuestionOutline } from 'resources/assets/icons/components';

export type GraphSectionProps = {
  headerText: string | JSX.Element | ReactElement;
  headerSubText?: string | JSX.Element | ReactElement;
  comparisonPeriodLegend?: string | JSX.Element | ReactElement;
  colorsLegend?: string | JSX.Element | ReactElement;
  children: JSX.Element | ReactElement;
  infoTooltipContent?: string;
};

export const GraphSection = ({
  headerText,
  headerSubText,
  comparisonPeriodLegend,
  children,
  infoTooltipContent,
  colorsLegend,
}: GraphSectionProps): ReactElement => {
  return (
    <Container>
      <Content>
        <TitleContainer>
          <Text color='$background' size='$18px'>
            {headerText}

            {infoTooltipContent && (
              <Tooltip placement='top' title={infoTooltipContent}>
                {QuestionOutline}
              </Tooltip>
            )}
          </Text>

          <br />

          <Text color='$secondaryText' size='$12px'>
            {headerSubText}
          </Text>
        </TitleContainer>
        <ComparisonLegendContainer>
          {typeof comparisonPeriodLegend === 'string' ? (
            <SingleLegendText>{comparisonPeriodLegend}</SingleLegendText>
          ) : (
            comparisonPeriodLegend
          )}
        </ComparisonLegendContainer>
      </Content>

      {children}

      <Box spaceTop='$32px'>
        <ColorsLegendContainer>{colorsLegend}</ColorsLegendContainer>
      </Box>
    </Container>
  );
};

const Content = styled(Box, {
  ...InjectDisplayFlexProps,
  justifyContent: 'space-between',
  marginBottom: '40px',
});

const TitleContainer = styled('div', {
  width: '100%',
});

const ComparisonLegendContainer = styled('div', {
  ...InjectDisplayFlexProps,
  justifyContent: 'right',
});

const ColorsLegendContainer = styled('div', {
  ...InjectDisplayFlexProps,
  justifyContent: 'center',
});

const Container = styled('div', {
  border: '1px solid',
  borderColor: 'rgba(255, 255, 255, 0.15)',
  borderRadius: '$4px',
  padding: tokens.space.$24px,
});

const SingleLegendText = styled('div', {
  fontSize: '$12px',
  color: '$secondaryText',
  paddingTop: '10px',
});
