import { styled } from '@taraai/design-system';
import Icon from 'components/core/controllers/views/Icon';
import React, { useEffect, useRef, useState } from 'react';
import AriaMenuButton from 'react-aria-menubutton';

import LeafMenuItem from './LeafMenuItem';
import { hover, menuItemStyle, menuStyle } from './menuStyles';
import { LeafMenuOption, MenuOption, SubMenuOption } from './menuTypes';

export interface SubMenuItemProps {
  option: MenuOption;

  isDisabled?: boolean;
}

function useHover(isHovered = false): [React.MutableRefObject<null>, boolean] {
  const [value, setValue] = useState(isHovered);

  const ref = useRef(null) as React.MutableRefObject<null>;

  const handleMouseOver = (): void => setValue(true);
  const handleMouseOut = (): void => setValue(false);

  useEffect(
    (): (() => void) => {
      const nodeHTML = ref.current as unknown as HTMLElement;
      if (nodeHTML) {
        nodeHTML.addEventListener('mouseover', handleMouseOver);
        nodeHTML.addEventListener('mouseout', handleMouseOut);

        return (): void => {
          nodeHTML.removeEventListener('mouseover', handleMouseOver);
          nodeHTML.removeEventListener('mouseout', handleMouseOut);
        };
      }
      return (): void => undefined;
    },
    [ref], // Recall only if ref changes
  );

  return [ref, value];
}

export default function SubMenuItemHTML({ option, isDisabled = false }: SubMenuItemProps): JSX.Element {
  const disabled = option.disabled || isDisabled;
  const [hoverRef, isHovered] = useHover();
  return (
    <span ref={hoverRef as React.RefObject<HTMLSpanElement>}>
      <MenuItem key={option.title + option.disabled} disabled={disabled}>
        {option.title}
        <StyledIcon disabled={disabled} name='right' />
        {isHovered && (
          <HoveredMenu>
            <ul>
              {(option as SubMenuOption).options.map((opt): JSX.Element => {
                if (typeof (opt as LeafMenuOption).onSelect === 'function') {
                  return <LeafMenuItem key={opt.title} isDisabled={isDisabled} option={opt} />;
                }
                return <SubMenuItemHTML key={opt.title} isDisabled={isDisabled} option={opt} />;
              })}
            </ul>
          </HoveredMenu>
        )}
      </MenuItem>
    </span>
  );
}
const StyledIcon = styled(
  Icon,
  {
    position: 'absolute',
    top: '0.5rem',
    right: '0rem',
    color: '#303f4b',
  },
  { disabled: { true: { color: '#949caf' } } },
);
const MenuItem = styled(
  AriaMenuButton.MenuItem,
  {
    color: '#303f4b',
    ...menuItemStyle,
    ...hover,
  },
  {
    disabled: { true: { color: '#949caf' } },
  },
);
const HoveredMenu = styled(AriaMenuButton.Menu, {
  ...menuStyle,
  top: '0rem',
  left: '100%',
});
