export default {
  family: '--atomic-fonts-family',
  size: {
    /* eslint-disable id-length */
    p: '--atomic-fonts-size-p',
    h1: '--atomic-fonts-size-h1',
    h2: '--atomic-fonts-size-h2',
    h3: '--atomic-fonts-size-h3',
    h4: '--atomic-fonts-size-h4',
    h5: '--atomic-fonts-size-h4',
    /* eslint-enable id-length */
    default: '--atomic-fonts-size',
    body: '--atomic-fonts-size-body',
    label: '--atomic-fonts-size-label',
    placeholder: '--atomic-fonts-size-placeholder',
  },
  weight: {
    default: '--atomic-fonts-weight',
    light: '--atomic-fonts-weight-light',
    regular: '--atomic-fonts-weight-regular',
    medium: '--atomic-fonts-weight-medium',
    thin: '--atomic-fonts-weight-thin',
    semibold: '--atomic-fonts-weight-semibold',
    bold: '--atomic-fonts-weight-bold',
  },
  decoration: {
    underline: '--atomic-fonts-decoration-underline',
    overline: '--atomic-fonts-decoration-overline',
    none: '--atomic-fonts-decoration-none',
    default: '--atomic-fonts-decoration',
  },
  style: {
    italic: '--atomic-fonts-style-italic',
    normal: '--atomic-fonts-style-normal',
    default: '--atomic-fonts-style',
  },
  transform: {
    none: '--atomic-fonts-transform-inherit',
    uppercase: '--atomic-fonts-transform-uppercase',
    lowercase: '--atomic-fonts-transform-lowercase',
    capitalize: '--atomic-fonts-transform-capitalize',
  },
};
