import { createSelector, Selector } from '@reduxjs/toolkit';
import { Data, UI } from '@taraai/types';
import { isNonEmptyString, notUndefined } from '@taraai/utility';
import { RootState } from 'reduxStore/store';
import { createIndividualSelector, IndividualQuery, inertIndividualQuery } from 'reduxStore/utils/selectors';
import { ReduxFirestoreQuery } from 'reduxStore/utils/types';

export const getOrg = (orgId: Data.Id.OrganizationId | undefined): IndividualQuery<UI.UIOrganization> => {
  if (!isNonEmptyString(orgId) || !orgId) {
    return inertIndividualQuery();
  }

  const query: ReduxFirestoreQuery = {
    collection: 'orgs',
    where: ['__name__', '==', orgId],
    storeAs: `orgs/${orgId}`,
  };

  return {
    query: [query],
    selector: createIndividualSelector<UI.UIOrganization>(query),
  };
};

type GetOrgListRes = {
  query: ReduxFirestoreQuery[];
  selector: Selector<RootState, UI.UIOrganization[]>;
};

export const getOrgList = (orgIds: Data.Id.OrganizationId[]): GetOrgListRes => {
  const orgQueries = orgIds.map(getOrg);

  const query = orgQueries.map(({ query: aQuery }) => aQuery[0]).filter(notUndefined);

  const allSelectors = orgQueries.map((slice) => slice.selector);
  const selector = createSelector(allSelectors, (...orgDocs) => orgDocs.filter(notUndefined));

  return { query, selector };
};
