import styled from '@emotion/styled';

export const SectionHeader = styled.div`
  font-size: 0.75rem;
  font-weight: 500;
  color: #949caf;
  text-align: left;
  padding: 0.75rem 1rem 0.5rem;
`;

export const EmptySectionHeader = styled.div`
  padding-top: 0.75rem;
`;

export const SectionFooter = styled.hr`
  padding-top: 0.75rem;
  border: none;
  border-bottom: solid 1px #dee3ec;
`;
