import { getInitials } from '@taraai/utility';
import { css, cx } from 'emotion';
import React from 'react';
import { strings } from 'resources/i18n';
import { atomic } from 'resources/theme';
import { formatColor, getContrastingColor, stringToColor } from 'tools/libraries/helpers/colors';

export type TLogoSize = 'small' | 'medium' | 'large' | 'extra-large' | string;
type Shape = 'circle' | 'square';

const radiusMap: { [key: string]: string } = {
  circle: '50%',
  square: '0.1875rem',
};

type LogoProps = {
  /**
   * Logo url
   */
  url?: string | null;
  /**
   * Name to generate initials
   */
  name: string;
  /**
   * Color in hex format
   */
  color?: string | null;
  /**
   * Any additional positional styles
   */
  className?: string;
  /**
   * Any additional styles for figure
   */
  figureClassName?: string;
  /**
   * The size of the logo, one of the defaults of Tara's design system
   * or a [CSS <length>](https://developer.mozilla.org/en-US/docs/Web/CSS/length) string.
   */
  size: TLogoSize;
  /**
   * Shape of the logo
   */
  shape: Shape;
  dataCy?: string;
};

export const logoSizes: { [key: string]: string } = {
  'xsmall': '1.25rem',
  'small': '1.5rem',
  'medium': '2rem',
  'large': '3rem',
  'extra-large': '5rem',
  'xx-large': '8rem',
};

/**
 * A wrapper around `<figure>` that generates logo from url or name.
 *
 * - [Designs](https://zpl.io/amWmg8e)
 */
export default function Logo({
  name,
  url,
  color,
  dataCy,
  className,
  figureClassName,
  size,
  shape,
}: LogoProps): JSX.Element | null {
  const calculatedSize = logoSizes[size as TLogoSize] ?? size;

  const altString = String(strings.formatString(strings.avatar.alt, { name }));
  const bgColor = color ?? formatColor(stringToColor(name));
  const fgColor = getContrastingColor(bgColor);
  const initials = getInitials(name);

  const radius = radiusMap[shape];

  return (
    <div
      className={cx(
        css`
          position: relative;
          min-height: ${calculatedSize} !important;
          height: ${calculatedSize} !important;
          min-width: ${calculatedSize} !important;
          width: ${calculatedSize} !important;
        `,
        className,
      )}
      data-cy={dataCy}
    >
      <figure
        className={cx(
          css`
            border-radius: ${radius};
            overflow: hidden;
            height: 100% !important;
            width: 100% !important;
            text-align: center;
            ${atomic.backgroundColor(bgColor)};
          `,
          figureClassName,
        )}
        data-cy={dataCy}
        title={name}
      >
        {url ? (
          <img
            alt={altString}
            className={css`
              object-fit: cover;
              height: 100%;
              min-height: 100%;
              width: 100%;
              min-width: 100%;
            `}
            data-cy={dataCy}
            src={url}
          />
        ) : (
          <div
            aria-label={altString}
            className={css`
              font-size: calc(${calculatedSize} * 0.4);
              padding-top: calc(${calculatedSize} * 0.3);
              line-height: calc(${calculatedSize} * 0.5);
              margin: auto;
              height: 100%;
              width: 100%;
              ${atomic.color(fgColor)};
            `}
            data-cy={dataCy}
          >
            {initials}
          </div>
        )}
      </figure>
    </div>
  );
}
