import { Data } from '@taraai/types';
import React, { CSSProperties } from 'react';
import { Draggable, DraggableStateSnapshot, DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd';

import { DraggableType, DroppableType } from './types';
import { getDraggableId, getDroppableDescription } from './utils';

export const DraggableElement = ({
  children,
  type,
  sourceType,
  index,
  sourceId = '',
  sortable = true,
}: {
  children: JSX.Element;
  type: DraggableType;
  sourceType: DroppableType;
  index: number;
  sourceId?: Data.Id.SprintId;
  sortable?: boolean;
}): JSX.Element => {
  // To enable the same element to be rendered in different _sources_,
  // we combine the elementID and the element source type.
  // Otherwise the beautifulDND gets lost
  // !!! -> It is expected that the key will contain the document id from the firestore
  const draggableId = getDraggableId(children.key?.toString() || '', type, sourceType, sourceId);
  return (
    <Draggable
      key={draggableId}
      draggableId={draggableId}
      index={index}
      isDragDisabled={type === DraggableType.dragDisabled}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getStyle(provided.draggableProps.style, snapshot, !sortable)}
        >
          {children}
        </div>
      )}
    </Draggable>
  );
};

const getStyle = (
  style: DraggingStyle | NotDraggingStyle | undefined,
  snapshot: DraggableStateSnapshot,
  disableOrdering: boolean | undefined,
): CSSProperties | DraggingStyle | NotDraggingStyle | undefined => {
  if (disableOrdering && !snapshot.isDropAnimating) {
    return style;
  }
  if (snapshot.isDropAnimating) {
    return {
      ...style,
      ...(snapshot.draggingOver &&
      getDroppableDescription(snapshot.draggingOver).type !== DroppableType.removeFromSprint
        ? {}
        : { transitionDuration: '0.001s' }),
    };
  }
  return { ...style };
};
