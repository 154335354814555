import React from 'react';

export const QuestionOutline = (
  <svg fill='none' height='13' viewBox='0 0 12 13' width='12' xmlns='http://www.w3.org/2000/svg'>
    <path
      clipRule='evenodd'
      d='M1.62891 6.125C1.62891 3.84683 3.47573 2 5.75391 2C8.03208 2 9.87891 3.84683 9.87891 6.125C9.87891 8.40317 8.03208 10.25 5.75391 10.25C3.47573 10.25 1.62891 8.40317 1.62891 6.125ZM5.75391 1.25C3.06152 1.25 0.878906 3.43261 0.878906 6.125C0.878906 8.81739 3.06152 11 5.75391 11C8.4463 11 10.6289 8.81739 10.6289 6.125C10.6289 3.43261 8.4463 1.25 5.75391 1.25ZM5.92574 4.11724C5.4942 4.11724 5.11385 4.4537 5.11385 4.99496H4.48482C4.48482 4.05872 5.1358 3.46626 5.92574 3.46626C6.71569 3.46626 7.42518 4.01484 7.42518 4.89256C7.42518 5.60205 6.94243 6.09942 6.37192 6.27497C6.07203 6.36274 5.8526 6.46514 5.8526 6.80891L5.83797 7.2112H5.19431V6.80891C5.19431 6.10674 5.61123 5.82148 6.17443 5.64593C6.48163 5.57279 6.7742 5.22902 6.7742 4.89256C6.7742 4.41713 6.35729 4.11724 5.92574 4.11724ZM5.52346 8.63018C5.26014 8.63018 5.04071 8.44001 5.04071 8.17669C5.04071 7.91338 5.26014 7.71589 5.52346 7.71589C5.78677 7.71589 5.99889 7.91338 5.99889 8.17669C5.99889 8.44001 5.78677 8.63018 5.52346 8.63018Z'
      fill='#A1AFC8'
      fillRule='evenodd'
    />
  </svg>
);
