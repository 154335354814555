import { Box, Fluid, HStack, Text } from '@taraai/design-system';
import Logo from 'components/core/controllers/views/Logo';
import React from 'react';

type Props = {
  color?: string | null;
  logoUrl?: string | null;
  name: string;
};

export function CurrentWorkspace({ color, logoUrl, name }: Props): JSX.Element {
  return (
    <Box space='$16px'>
      <Fluid>
        <HStack space='$12px'>
          <Logo color={color} name={name} shape='square' size='small' url={logoUrl} />
          <Text color='$dark' size='$16px' textAlign='left' weight='bold'>
            {name}
          </Text>
        </HStack>
      </Fluid>
    </Box>
  );
}
