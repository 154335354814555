import Tara from '@taraai/types';
import SprintInsightInfo from 'components/app/controllers/views/SprintInsightInfo';
import { css } from 'emotion';
import React from 'react';
import { strings } from 'resources/i18n';

interface SprintPlanningDetailsProps extends React.HTMLProps<HTMLDivElement> {
  estimatedEffort: number;
  totalEffort: number;
  allSprintTasks: Tara.Task[] | undefined;
  unassignedSprintTasks: Tara.Task[] | undefined;
}

/**
 * SprintPlanningDetails
 * effort and task information for sprint planning
 *
 */
export default function SprintPlanningDetails({
  estimatedEffort,
  totalEffort,
  allSprintTasks,
  unassignedSprintTasks,
  ...props
}: SprintPlanningDetailsProps): JSX.Element {
  return (
    <div
      {...props}
      className={css`
        display: flex;
      `}
    >
      <div className={css``}>
        <div
          className={css`
            color: ${totalEffort > estimatedEffort ? '#d99b59' : '#303f4b'};
            font-size: 1.125rem;
            font-weight: 600;
            padding-bottom: 0.375rem;
          `}
        >
          {totalEffort}
        </div>
        <div
          className={css`
            color: #949caf;
            font-size: 0.75rem;
            font-weight: normal;
            border-bottom: 0.0625rem solid #eaeef5;
            padding-right: 4.375rem;
            padding-bottom: 1rem;
          `}
        >
          {strings.sprints.sprintPlanning.totalEffort}
        </div>
        <div
          className={css`
            color: #303f4b;
            font-size: 1.125rem;
            font-weight: 600;
            padding-bottom: 0.375rem;
            padding-top: 1rem;
          `}
        >
          {allSprintTasks?.length}
        </div>
        <div
          className={css`
            color: #949caf;
            font-size: 0.75rem;
            font-weight: normal;
            padding-right: 4.375rem;
          `}
        >
          {strings.sprints.sprintPlanning.tasks}
        </div>
      </div>
      <div className={css``}>
        <div
          className={css`
            color: #389e0d;
            font-size: 1.125rem;
            font-weight: 600;
            padding-bottom: 0.375rem;
          `}
        >
          <SprintInsightInfo estimatedEffort={estimatedEffort} />
        </div>
        <div
          className={css`
            color: #389e0d;
            font-size: 0.75rem;
            font-weight: normal;
            border-bottom: 0.0625rem solid #eaeef5;
            padding-bottom: 1rem;
            padding-right: 1.0625rem;
          `}
        >
          {strings.sprints.sprintPlanning.recommendedEffort}
        </div>
        <div
          className={css`
            color: #303f4b;
            font-size: 1.125rem;
            font-weight: 600;
            padding-bottom: 0.375rem;
            padding-top: 1rem;
          `}
        >
          {unassignedSprintTasks?.length}
        </div>
        <div
          className={css`
            color: #949caf;
            font-size: 0.75rem;
            font-weight: normal;
          `}
        >
          {strings.sprints.sprintPlanning.tasksUnassigned}
        </div>
      </div>
    </div>
  );
}
