import { UI } from '@taraai/types';
import SprintInsightInfo from 'components/app/controllers/views/SprintInsightInfo';
import Icon from 'components/core/controllers/views/Icon';
import { css } from 'emotion';
import React from 'react';
import { strings } from 'resources/i18n';

export interface SprintInsightsBodyProps {
  currentSprint: UI.UISprint | undefined;
  estimatedEffort: number;
  totalEffort: number;
  overload?: boolean;
}

/**
 * SprintInsightsBody renders current sprint and estimated effort details
 * along with indicators and tooltips
 *
 */
export default function SprintInsightsBody({
  currentSprint,
  estimatedEffort = 0,
  totalEffort = 0,
  overload,
}: SprintInsightsBodyProps): JSX.Element {
  return (
    <div>
      <div
        className={css`
          font-size: 0.875rem;
          font-weight: normal;
          color: ${overload ? '#d99b59' : '#575f65'};
          display: flex;
        `}
      >
        {overload && (estimatedEffort as number) >= 0 && (
          <Icon
            className={css`
              padding: 0rem;
              padding-right: 0.25rem;
              display: flex;
              align-self: auto;
              height: 0.875rem;
              width: 0.875rem;
            `}
            name='overload'
          />
        )}
        <div>
          {overload ? strings.sprints.insights.planNextSprintOverload : strings.sprints.insights.planNextSprint}
        </div>
      </div>
      <div
        className={css`
          padding-top: 1.5rem;
          display: flex;
        `}
      >
        <div
          className={css`
            padding-right: 4.6875rem;
          `}
        >
          <div
            className={css`
              color: #303f4b;
              font-size: 1.125rem;
              font-weight: 600;
              padding-bottom: 0.3125rem;
            `}
          >
            {currentSprint && currentSprint.sprintName}
          </div>
          <div
            className={css`
              color: #949caf;
              font-size: 0.75rem;
              font-weight: normal;
            `}
          >
            {strings.sprints.insights.planning}
          </div>
        </div>
        <div
          className={css`
            padding-right: 4.6875rem;
          `}
        >
          <div
            className={css`
              color: ${overload ? '#d99b59' : '#575f65'};
              font-size: 1.125rem;
              font-weight: 600;
            `}
          >
            {totalEffort}
          </div>
          <div
            className={css`
              color: #949caf;
              font-size: 0.75rem;
              padding-top: 0.3125rem;
              font-weight: normal;
            `}
          >
            {strings.sprints.insights.totalEffort}
          </div>
        </div>
        <div>
          <SprintInsightInfo estimatedEffort={estimatedEffort} />
          <div
            className={css`
              color: #389e0d;
              font-size: 0.75rem;
              font-weight: normal;
              padding-top: 0.3125rem;
            `}
          >
            {strings.sprints.insights.recommendedEffort}
          </div>
        </div>
      </div>
    </div>
  );
}
