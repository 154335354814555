import { Data, UI } from '@taraai/types';
import { isNonEmptyString } from '@taraai/utility';
import { compose } from 'redux';
import { createIndividualSelector, inertQuery, Query, ReduxFirestoreQuery } from 'reduxStore/utils';

export type RequirementSummaryRecord = {
  summaries: Record<Data.Id.RequirementId, UI.RequirementSummary>;
  type: 'requirements';
};

export function getRequirementSummary(orgId: Data.Id.OrganizationId, archived = false): Query<UI.RequirementSummary> {
  if (!isNonEmptyString(orgId)) {
    return inertQuery();
  }
  const storeAs = `orgs/${orgId}/summaries/requirements`;
  const query: ReduxFirestoreQuery = {
    collection: `orgs/${orgId}/summaries`,
    doc: 'requirements',
    storeAs,
  };

  return {
    query: [query],
    selector: compose(
      (data: RequirementSummaryRecord) => getSummaries(data, archived),
      createIndividualSelector(query),
    ),
  };
}

export const getSummaries = (doc: RequirementSummaryRecord, archived: boolean): UI.RequirementSummaryOption[] => {
  return Object.keys(doc?.summaries || {})
    .filter((id: string) => {
      const data: UI.RequirementSummary = doc?.summaries[id];
      return data.deleted === false && data.archived === archived;
    })
    .map((summaryId: string) => {
      const data: UI.RequirementSummary = doc?.summaries[summaryId];
      return { id: summaryId, title: data.title };
    });
};
