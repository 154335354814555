import { UI } from '@taraai/types';
import { arrayRemove, arrayUnion, doc, getFirestore, Timestamp, writeBatch } from 'firebase/firestore';
import { decode } from 'reduxStore/utils/decoders';

export type UpdateRequirementTeamAction = keyof Pick<
  UI.UIRequirementChangeset,
  'appendTeamIds' | 'removeTeamIds' | 'orderedTaskIds'
>;

export async function updateRequirement(
  orgId: string,
  { id: requirementId, ...propsToSet }: Omit<UI.UIRequirementChangeset, 'updatedAt'>,
): Promise<UI.UIRequirementChangeset> {
  const firestore = getFirestore();
  const batch = writeBatch(firestore);
  const requirementRef = doc(firestore, `orgs/${orgId}/requirements`, requirementId);

  const decodedChangesWithId = decode<UI.UIRequirementChangeset>(
    {
      id: requirementId,
      ...propsToSet,
      updatedAt: Timestamp.now(),
    },
    'UIRequirementChangeset',
  );

  const { id, appendTeamIds, removeTeamIds, ...valid } = decodedChangesWithId;

  if (appendTeamIds) {
    batch.update(requirementRef, {
      assignedTeamIds: arrayUnion(...appendTeamIds),
    });
  }
  if (removeTeamIds) {
    batch.update(requirementRef, {
      assignedTeamIds: arrayRemove(...removeTeamIds),
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  batch.update(requirementRef, valid as any);
  await batch.commit();

  return decodedChangesWithId as UI.UIRequirementChangeset;
}
