import { UI } from '@taraai/types';
import SprintInsightsBody from 'components/app/controllers/views/SprintInsightsBody';
import SprintInsightsFooter from 'components/app/controllers/views/SprintInsightsFooter';
import SprintInsightsHeader from 'components/app/controllers/views/SprintInsightsHeader';
import Modal from 'components/core/controllers/views/Modal';
import { css } from 'emotion';
import React from 'react';
import { useHistory } from 'react-router-dom';

type SprintFragment = Pick<UI.UISprint, 'id' | 'sprintName' | 'computedOnCompletion'>;
export interface SprintInsightsModalProps {
  currentSprint: UI.UISprint | undefined;
  estimatedEffort: number;
  totalEffort: number;
  overload?: boolean;
  lastNCompletedSprints: SprintFragment[] | undefined;
}

/**
 * SprintInsightsModal does… puts together modal view for sprint insights
 *
 */
export default function SprintInsightsModal({
  currentSprint,
  estimatedEffort,
  totalEffort,
  overload,
  lastNCompletedSprints,
}: SprintInsightsModalProps): JSX.Element {
  const history = useHistory();

  return (
    <Modal
      className={css`
        max-width: 31.25rem;
        min-width: 12.5rem;
      `}
      closeModal={(): void => history.goBack()}
      footer={<SprintInsightsFooter estimatedEffort={estimatedEffort} lastNCompletedSprints={lastNCompletedSprints} />}
      header={<SprintInsightsHeader />}
    >
      <SprintInsightsBody
        currentSprint={currentSprint}
        estimatedEffort={estimatedEffort}
        overload={overload}
        totalEffort={totalEffort}
      />
    </Modal>
  );
}
