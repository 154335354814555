"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.notNull = exports.notUndefined = exports.assertNever = exports.noop = exports.splitArrayOn = void 0;
/**
 * Splits the array in two based on predicate.
 *
 * The element for which predicate returns true is included in the second part.
 *
 * For example:
 *
 * `[2, 4, 5, 0, 8]` with `isOdd` predicate will be split into two arrays like so:
 * `[[2, 4], [5, 0, 8]]`
 *
 * (5 being first odd number signifies the start of second array).
 */
function splitArrayOn(arr, predicate) {
    return arr.reduce(function (_a, element) {
        var before = _a[0], after = _a[1];
        if (after.length > 0 || predicate(element)) {
            // already after the split
            return [before, __spreadArray(__spreadArray([], after, true), [element], false)];
        }
        return [__spreadArray(__spreadArray([], before, true), [element], false), after];
    }, [[], []]);
}
exports.splitArrayOn = splitArrayOn;
/**
 * A simple no-op function.
 */
// eslint-disable-next-line @typescript-eslint/no-empty-function
var noop = function () { };
exports.noop = noop;
/**
 * Asserts that passed parameter is of type never
 */
/* istanbul ignore next */
function assertNever(value, throwError) {
    if (throwError === void 0) { throwError = true; }
    if (throwError) {
        throw new Error("Assertion failed: assumed 'never' got '".concat(value, "'"));
    }
    return value;
}
exports.assertNever = assertNever;
/**
 * Checks if not undefined
 */
function notUndefined(data) {
    return typeof data !== 'undefined';
}
exports.notUndefined = notUndefined;
/**
 * Checks if not null
 */
function notNull(data) {
    return data !== null;
}
exports.notNull = notNull;
