/* eslint-disable @typescript-eslint/no-shadow */

import { Popup } from '@taraai/design-system';
import { UI } from '@taraai/types';
import DropdownBody from 'components/app/controllers/views/DropdownBody';
import DropdownHeader from 'components/app/controllers/views/DropdownHeader';
import Dropdown from 'components/core/controllers/views/Dropdown';
import Fuse from 'fuse.js';
import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { sort } from 'tools/libraries/helpers/sort';

export type DropdownOption =
  | UI.RequirementSummaryOption
  | UI.UIRequirement
  | UI.UIUser
  | UI.SprintSummaryOption
  | UI.UISprint
  | Pick<UI.UIUser, 'id' | 'name' | 'avatarURL'>;

export interface DropdownControllerProps {
  children: ReactElement;
  dataCy?: string;
  exactTypeaheadInputRef?: React.RefObject<HTMLInputElement>;
  header?: boolean;
  headerPlaceholder?: string;
  headerTitle?: string | string[];
  onAdd: (id: string) => void;
  onClose: () => void;
  onRemove: (id: string) => void;
  options: DropdownOption[];
  selectedOptions: DropdownOption[];
  show: boolean;
  disableAvatar?: boolean;
}

/**
 * DropdownController
 * logic for dropdown interactions
 *
 */
export default function DropdownController({
  children,
  dataCy,
  exactTypeaheadInputRef,
  header,
  headerPlaceholder,
  headerTitle,
  onAdd,
  onClose,
  onRemove,
  options,
  disableAvatar = false,
  selectedOptions,
  show,
}: DropdownControllerProps): JSX.Element {
  // new linter form react-script 4 upgrade
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const typeaheadInputRef = exactTypeaheadInputRef ?? useRef<HTMLInputElement>(null);
  const selectedIds = useMemo(() => selectedOptions.map((selectedOption) => selectedOption.id), [selectedOptions]);
  const customSort = [{ desc: (option: { id: string }): number => selectedIds.indexOf(option.id) }];
  const sortedOptions = sort(options, customSort);
  const fuse = useMemo(() => new Fuse(options, fuseOptions), [options]);
  const [query, setQuery] = useState('');
  const [activeResult, setActiveResult] = useState(0);

  const results = useMemo(
    () => (query.length === 0 ? sortedOptions : fuse.search(query).map(({ item }) => item)),
    [fuse, query, sortedOptions],
  );

  const handleSearchChange = useCallback((query: string) => {
    setActiveResult(0);
    setQuery(query);
  }, []);

  const toggleId = useCallback(
    (id: string) => {
      if (selectedIds.includes(id)) onRemove(id);
      else onAdd(id);
    },
    [onAdd, onRemove, selectedIds],
  );

  const handleKeyDown = useCallback(
    (event) => {
      switch (event.keyCode) {
        case KEYS.ESCAPE:
          event.preventDefault();
          onClose();
          break;
        case KEYS.ARROW_UP:
          event.preventDefault();
          setActiveResult((prevActive) => (prevActive === 0 ? 0 : prevActive - 1));
          break;
        case KEYS.ARROW_DOWN:
          event.preventDefault();
          setActiveResult((prevActive) => (prevActive === results.length - 1 ? prevActive : prevActive + 1));
          break;
        case KEYS.ENTER:
          event.preventDefault();
          if (results[activeResult]) toggleId(results[activeResult].id);
      }
    },
    [activeResult, onClose, results, toggleId],
  );

  useEffect(() => {
    typeaheadInputRef.current?.focus();
  }, [typeaheadInputRef]);

  return (
    <Popup
      content={
        <Dropdown
          data-cy={dataCy}
          header={
            header &&
            headerTitle &&
            headerPlaceholder && (
              <DropdownHeader
                headerPlaceholder={headerPlaceholder}
                headerTitle={headerTitle}
                inputRef={typeaheadInputRef}
                onClose={onClose}
                onKeyDown={handleKeyDown}
                onSearchChange={handleSearchChange}
              />
            )
          }
        >
          <DropdownBody
            activeResult={activeResult}
            disableAvatar={disableAvatar}
            onKeyDown={handleKeyDown}
            onOptionClick={toggleId}
            options={options}
            selectedIds={selectedIds}
            typeaheadResults={results}
          />
        </Dropdown>
      }
      onHide={onClose}
      placement='bottom-end'
      show={show}
    >
      {children}
    </Popup>
  );
}

const fuseOptions = {
  keys: ['name', 'title'],
  ignoreLocation: true,
};

const KEYS = {
  ESCAPE: 27,
  ARROW_UP: 38,
  ARROW_DOWN: 40,
  ENTER: 13,
};
