import { Box, Text, VStack } from '@taraai/design-system';
import { css } from 'emotion';
import React from 'react';
import { strings } from 'resources';

import background from './images/background.svg';
import orbLogo from './images/logo-orb.svg';

export function GitInsightsIntegrationSuccess(): JSX.Element {
  return (
    <>
      <Box
        background='$darkModeBlue'
        className={css`
          background-size: cover;
          background-position: center;
          background-image: url('${background}');
        `}
        height='$full'
        width='$full'
      >
        <Box center>
          <VStack>
            <Box center spaceBottom='$32px'>
              <Box width='$fitContent'>
                <img alt='org tara logo' src={orbLogo} />
              </Box>
            </Box>
            <Text color='$darkModePurple' size='$28px' textAlign='center' weight='medium'>
              {strings.gitInsightsIntegrationSuccess.success1}
              <br />
              {strings.gitInsightsIntegrationSuccess.success2}
            </Text>
          </VStack>
        </Box>
      </Box>
    </>
  );
}
