import { Box, styled } from '@taraai/design-system';
import { useTaskTitleConfig } from 'components/editor/config';
import { RichEditor } from 'components/editor/RichEditor';
import { RichEditorProvider } from 'components/editor/RichEditorProvider';
import React from 'react';
import { taskModalTestIds } from 'resources/cypress/testAttributesValues';

type TaskNameInputProps = {
  value: string;
  trySave: (title: string) => void;
  placeholder?: string;
};

export const TaskNameInput: React.FC<TaskNameInputProps> = ({
  value,
  trySave,
  placeholder = '',
}: TaskNameInputProps) => {
  return (
    <RichEditorProvider config={useTaskTitleConfig()} initialValue={value} onSave={trySave}>
      <TaskNameInputWrapper spaceVert='$16px'>
        <RichEditor data-cy={taskModalTestIds.TASK_NAME} isTitle placeholder={placeholder} />
      </TaskNameInputWrapper>
    </RichEditorProvider>
  );
};

const TaskNameInputWrapper = styled(Box, {
  paddingLeft: '1.4375rem',
  paddingRight: '1.4375rem',
});
