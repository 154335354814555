import { unwrapResult } from '@reduxjs/toolkit';
import { Data } from '@taraai/types';
import React, { useCallback } from 'react';
import { updateTask, useAppDispatch } from 'reduxStore';
import { strings } from 'resources/i18n';
import { useToast } from 'tools';
import { useForceSave } from 'tools/reconciliation/useForceSave';

import { TaskNameInput } from './TaskNameInput';

type TaskNameControllerProps = {
  getAllTaskLabels: (overrides: { title: string }) => string[];
  taskId: Data.Id.TaskId;
  value: string;
};

export const TaskNameController: React.FC<TaskNameControllerProps> = ({
  taskId,
  value,
  getAllTaskLabels,
}: TaskNameControllerProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { addToast } = useToast();

  const updateName = useCallback(
    async (title: string): Promise<string | Error | null> => {
      try {
        await dispatch(updateTask({ title, id: taskId, labels: getAllTaskLabels({ title }) })).then(unwrapResult);
        return title;
      } catch (err) {
        const error = err as Error;
        addToast({ message: error.message, type: 'error' });
        return error;
      }
    },
    [addToast, dispatch, getAllTaskLabels, taskId],
  );

  const { trySave } = useForceSave(value, updateName);

  return <TaskNameInput placeholder={placeholder} trySave={trySave} value={value} />;
};

const placeholder = strings.formatString(strings.placeholder.text, {
  field: strings.placeholder.fields.title,
}) as string;
