/* eslint-disable id-length */

// Used in graph bars and legends, don't touch this without talk to the designer.
export const GraphColors = [
  '#A8A9E7',
  '#57D4E3',
  '#FFDB86',
  '#59B4FF',
  '#FAA97E',
  '#B6DB88',
  '#FE8DC1',
  '#F9BA3D',
  '#CDA4FC',
];

// Semantical colors for cases like good/bad, complete/incomplete
// If the graph bars go beyond the limit of GraphColors, then we can use these colors as well
// But only in the case we don't have available colors for the bars (more than 9 bars in the same chart)
export const GraphSemanticalColors = ['#12D4C4', '#FC8C9A', '#67728B'];

// Breakpoints in React Grid System
export const GridBreakpoints = { sm: 576, md: 768, lg: 992, xl: 1200, xxl: 1600, xxxl: 1920 };

export const backgroundTypes = ['solid', 'line', 'wave', 'circle'];
