import { UI } from '@taraai/types';

interface SprintCalculations {
  totalEffortCompleted: number;
  totalEffortEstimated: number;
  totalTasksCompleted: number;
  totalTasks: number;
}
export function getSprintCalculations(
  sprint: UI.UISprint,
  sprintTasks: UI.UITask[] = [],
  completeSprintTasks: UI.UITask[] = [],
): SprintCalculations {
  const { isComplete, computedOnCompletion } = sprint;
  const reduceEffort = (effortTotal: number, task: UI.UITask): number => effortTotal + (Number(task.effortLevel) || 1);

  const totalEffortCompleted = isComplete
    ? computedOnCompletion.totalEffortCompleted ?? 0
    : completeSprintTasks?.reduce(reduceEffort, 0);

  const totalEffortEstimated = isComplete
    ? computedOnCompletion.totalEffortEstimated || 1
    : sprintTasks?.reduce(reduceEffort, 0);

  const totalTasksCompleted = isComplete
    ? computedOnCompletion.totalTasksCompleted || 0
    : completeSprintTasks?.length || 0;

  const totalTasks = isComplete ? computedOnCompletion.totalTasks || 0 : sprintTasks.length;

  return {
    totalEffortCompleted,
    totalEffortEstimated,
    totalTasksCompleted,
    totalTasks,
  };
}

export function getEffortPercent(completed: number, total: number): number {
  return Math.round((completed / total) * 100);
}
