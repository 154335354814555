import { forEachEntityRange } from 'components/editor/entities';
import { DraftDecorator } from 'components/editor/types';
import { ContentBlock, ContentState } from 'draft-js';

import { Link } from './Link';

export const linkDecorator: DraftDecorator = {
  strategy: linkStrategy,
  component: Link,
};

export function linkStrategy(
  block: ContentBlock,
  callback: (start: number, end: number) => void,
  content: ContentState,
): void {
  forEachEntityRange('LINK', content, block, callback);
}
