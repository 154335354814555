import { unwrapResult } from '@reduxjs/toolkit';
import { Data, UI } from '@taraai/types';
import { parseLabelsFromPlainText, unique } from '@taraai/utility';
import { extractEffortLevel, extractMentions } from 'components/editor/plugins';
import React, { useCallback } from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { archiveTask, selectTaskDocument, selectUserDocument, updateTask, useAppDispatch } from 'reduxStore';
import { strings } from 'resources';
import { useToast } from 'tools';

import { Title } from './Title';

type Props = {
  orgId: Data.Id.OrganizationId;
  subtasksIds: Pick<UI.UITask, 'id'>[];
  taskDeleteCallback: () => void;
  taskId: Data.Id.TaskId;
  teamId: Data.Id.TeamId;
};

export const TitleController = ({
  orgId,
  subtasksIds,
  taskDeleteCallback,
  taskId,
  teamId,
}: Props): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const { whenError } = useToast();

  const taskFragment = useSelector(
    (state) => selectTaskDocument(state, taskId, ['author', 'status', 'title', 'updatedAt']),
    deepEquals,
  );

  const author = useSelector((state) => selectUserDocument(state, taskFragment?.author, ['name'])?.name);

  const updateTaskWithTitle = useCallback(
    async (title: string): Promise<void> => {
      const { collaborators, ...taskData } = extractMentions(extractEffortLevel({ title }));
      const labels = unique(parseLabelsFromPlainText(taskData.title));
      await dispatch(
        updateTask({
          id: taskId,
          labels,
          collaborators: collaborators.length ? ['::arrayUnion', ...collaborators] : undefined,
          ...taskData,
        }),
      );
    },
    [dispatch, taskId],
  );

  const archiveCurrentTask = useCallback((): void => {
    dispatch(archiveTask([...subtasksIds, { id: taskId }]))
      .then(unwrapResult)
      .catch(whenError(strings.task.failedToArchiveTask));
    taskDeleteCallback();
  }, [dispatch, subtasksIds, taskId, whenError, taskDeleteCallback]);

  return taskFragment ? (
    <Title
      authorName={author}
      id={taskId}
      onClickArchiveTask={archiveCurrentTask}
      orgId={orgId}
      status={taskFragment.status}
      taskId={taskId}
      teamId={teamId}
      title={taskFragment.title}
      updatedAt={taskFragment.updatedAt}
      updateTask={updateTaskWithTitle}
    />
  ) : null;
};
