import './suggestor-styles.css';

import { useMentionAtom } from '@remirror/react';
import { FloatingWrapper } from '@remirror/react-components';
import React, { useEffect, useState } from 'react';
import { cx } from 'remirror';
import { MentionExtensionAttributes } from 'remirror/extensions';

// It is expected the key to be matchers name and the value
// to be the list of options
export interface SuggestorOptions {
  [key: string]: MentionExtensionAttributes[];
}

export function Suggestor({ matcherOptions }: { matcherOptions: SuggestorOptions }): JSX.Element {
  const [options, setOptions] = useState<MentionExtensionAttributes[]>([]);
  const { state, getMenuProps, getItemProps, indexIsHovered, indexIsSelected } = useMentionAtom({
    items: options,
  });

  useEffect(() => {
    if (!state || !matcherOptions[state.name]) {
      return;
    }

    const items = matcherOptions[state.name];

    const searchTerm = state.query.full.toLowerCase();
    const filteredResults = items
      .filter((item) => item.label.toLowerCase().includes(searchTerm))
      .sort()
      .slice(0, 5);
    setOptions(filteredResults);
  }, [state, matcherOptions]);

  const enabled = !!state;

  return (
    <FloatingWrapper enabled={enabled} placement='bottom-start' positioner='cursor'>
      <div {...getMenuProps()} className='suggestions'>
        {enabled &&
          options.map((option, index) => {
            const isHighlighted = indexIsSelected(index);
            const isHovered = indexIsHovered(index);

            return (
              <div
                key={option.id}
                className={cx('suggestion', isHighlighted && 'highlighted', isHovered && 'hovered')}
                {...getItemProps({
                  item: option,
                  index,
                })}
              >
                {option.label}
              </div>
            );
          })}
      </div>
    </FloatingWrapper>
  );
}
