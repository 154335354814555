import { UI } from '@taraai/types';
import Table from 'components/core/controllers/Table';
import Avatar from 'components/core/controllers/views/Avatar';
import { FastSmallSpinner } from 'components/core/controllers/views/Spinners';
import { css, cx } from 'emotion';
import React from 'react';
import { strings } from 'resources/i18n';
import { atomic } from 'resources/theme';

import EmptyState from './EmptyState';

export interface DeactivatedUsersLayoutProps {
  deactivatedUsers: UI.UIUser[];
  reactivationInProgress?: { [userId: string]: boolean };
  onReactivateUser: (userId: string) => void;
}

export default function DeactivatedUsersLayout({
  deactivatedUsers,
  onReactivateUser,
  reactivationInProgress,
}: DeactivatedUsersLayoutProps): JSX.Element {
  const tableData = deactivatedUsers.map((user) => {
    return {
      id: user.id,
      name: (
        <div
          className={cx(
            css`
              display: flex;
              flex-direction: row;
              align-items: center;
            `,
          )}
        >
          <Avatar
            key={user.id}
            className={css`
              margin-right: 0.7rem;
              opacity: 0.5;
            `}
            size='medium'
            user={user}
          />
          {user.name}
        </div>
      ),
      action: (
        <div>
          {reactivationInProgress && reactivationInProgress[user.id] ? (
            <FastSmallSpinner />
          ) : (
            /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
            <a
              className={css`
                color: ${atomic.get(atomic.colors.secondary)};
                cursor: pointer;
                font-size: 0.875rem;
                font-weight: 500;
              `}
              href='#'
              onClick={(): void => onReactivateUser && onReactivateUser(user.id)}
            >
              {strings.users.deactivatedUsers.reactivate}
            </a>
          )}
        </div>
      ),
    };
  });

  return (
    <div>
      {tableData.length === 0 ? (
        <EmptyState />
      ) : (
        <Table
          data={tableData}
          headers={{
            name: {
              name: strings.users.deactivatedUsers.table.name,
              width: '85%',
            },
            action: {
              name: strings.users.deactivatedUsers.table.action,
              width: '15%',
            },
          }}
        />
      )}
    </div>
  );
}
