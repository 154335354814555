export const PROD = process.env.NODE_ENV === 'production' || false;
export const TESTING = process.env.NODE_ENV === 'test' || process.env.STORYBOOK_ON === 'true' || false;
export const DEBUG = (!TESTING && !PROD) || process.env.REACT_APP_PRODUCTION_LOGGING === 'true' || false;
export const OFFLINE = process.env.REACT_APP_OFFLINE === 'true' || false;

export const AXE_DEBUG = DEBUG && process.env.REACT_APP_AXE_DEBUG === 'true';
const AUTH_DEBUG = DEBUG && process.env.REACT_APP_AUTH_DEBUG === 'true';
const FUNC_DEBUG = DEBUG && process.env.REACT_APP_FUNC_DEBUG === 'true';
export const STORE_DEBUG = DEBUG && process.env.REACT_APP_STORE_DEBUG === 'true';
export const RENDER_DEBUG = false;
export const STRICT_DEBUG = DEBUG && process.env.REACT_APP_STRICT_DEBUG === 'true';

/* eslint-disable no-console */
export const authLog = AUTH_DEBUG ? console.debug : (): false => false;
export const funcLog = FUNC_DEBUG ? console.debug : (): false => false;
export const storeLog = STORE_DEBUG ? console.debug : (): false => false;
