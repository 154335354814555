import { Box, getCustomSize, VStack } from '@taraai/design-system';
import { Data } from '@taraai/types';
import { SprintDetailsSidebarHeader } from 'components/app/controllers/SprintDetailsSidebarHeader';
import { SprintFiltersModuleController } from 'components/app/controllers/SprintFiltersModuleController';
import React from 'react';

type Props = {
  id: Data.Id.SprintId;
  orgId: Data.Id.OrganizationId;
  teamId: Data.Id.TeamId;
};

const sprintSettingsWidth = getCustomSize(400);

export const IndividualSprintSettingsView = ({ id, orgId, teamId }: Props): JSX.Element => {
  return (
    <Box width={sprintSettingsWidth}>
      <VStack space='$4px'>
        <SprintDetailsSidebarHeader sprintId={id} />
        <SprintFiltersModuleController hideFilters orgId={orgId} sprintId={id} teamId={teamId} />
      </VStack>
    </Box>
  );
};
