import { css } from 'emotion';
import React from 'react';

export interface ProgressRowProps {
  start: number;
  end: number;
  statusColor: string;
  statusContent: string;
  color: string;
  gitStatus: string | null;
}

/**
 * GanttChartProgressRow does…
 *
 */
export default function ProgressRow({
  start,
  end,
  statusColor,
  statusContent,
  color,
  gitStatus,
}: ProgressRowProps): JSX.Element {
  return (
    <span
      className={css`
        margin: 0.5rem 0;
        border-radius: 0.1875re;
        background-color: ${statusColor};
        grid-column-start: ${start};
        grid-column-end: ${end};
        text-align: end;
        ::after {
          cursor: default;
          content: '${gitStatus !== null ? gitStatus : statusContent}';
          font-size: 0.75rem;
          font-weight: 400;
          color: ${color};
          font-style: italic;
          vertical-align: sub;
          position: relative;
          right: 0.625rem;
          left: -0.25rem;
          top: 0.0625rem;
        }
      `}
    />
  );
}
