import { CreatedIdentifiable } from 'reduxStore';

import { compareDates } from './dates';

export function compareByCreatedAtAsc<T extends CreatedIdentifiable>(taskA: T, taskB: T): number {
  return compareDates(taskA.createdAt, taskB.createdAt);
}

export function compareByCreatedAtDesc<T extends CreatedIdentifiable>(taskA: T, taskB: T): number {
  return -compareByCreatedAtAsc(taskA, taskB);
}
