"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.anyExternalRepoDecoder = exports.gitlabDecoder = exports.githubDecoder = void 0;
var ts_data_json_1 = require("ts.data.json");
exports.githubDecoder = ts_data_json_1.JsonDecoder.object({
    service: ts_data_json_1.JsonDecoder.isExactly('github'),
    installationId: ts_data_json_1.JsonDecoder.string,
    repository: ts_data_json_1.JsonDecoder.string,
}, 'ExternalRepo.Github');
exports.gitlabDecoder = ts_data_json_1.JsonDecoder.object({
    service: ts_data_json_1.JsonDecoder.isExactly('gitlab'),
    repository: ts_data_json_1.JsonDecoder.string,
    integrationId: ts_data_json_1.JsonDecoder.string,
}, 'ExternalRepo.Gitlab');
exports.anyExternalRepoDecoder = ts_data_json_1.JsonDecoder.oneOf([exports.githubDecoder, exports.gitlabDecoder], 'ExternalRepo.Any');
