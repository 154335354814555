import Icon from 'components/core/controllers/views/Icon';
import { css } from 'emotion';
import React from 'react';
import { strings } from 'resources/i18n';
import { onEnterDown } from 'tools/helpers';

export interface TaskNavigationCountProps {
  taskCount?: number;
  currentTaskNumber?: number;
  navigationCategory: string | null;
  closeModal?: () => void;
}

/**
 * TaskNavigationCount
 * modal container task navigation count
 *
 */
export default function TaskNavigationCount({
  taskCount,
  currentTaskNumber,
  navigationCategory,
  closeModal,
}: TaskNavigationCountProps): JSX.Element {
  return (
    <div
      className={css`
        position: fixed;
        top: 0;
        left: 0;
        padding-top: 24px;
        padding-left: 36px;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        display: flex;
        cursor: pointer;
      `}
      onClick={closeModal}
      onKeyDown={onEnterDown(closeModal)}
      role='button'
      tabIndex={0}
    >
      <Icon
        className={css`
          padding: 0px;
          padding-right: 8px;
        `}
        name='backarrow'
      />
      <span
        className={css`
          max-width: 280px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          text-transform: capitalize;
        `}
      >
        {navigationCategory}
      </span>
      {!!taskCount && taskCount > 0 && !!currentTaskNumber && currentTaskNumber > 0 && (
        <span
          className={css`
            padding-left: 4px;
          `}
        >
          {strings.formatString(strings.task.requirementTaskCount, {
            currentTaskNumber,
            taskCount,
          })}
        </span>
      )}
    </div>
  );
}
