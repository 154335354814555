import { Box, fade, Fluid, styled, Text } from '@taraai/design-system';
import React from 'react';
import { useSelector } from 'react-redux';
import { gitInsightsSelectionState, useSetInsightsTimeSegment } from 'reduxStore';

enum TimeSegment {
  Weekly = 'weekly',
  Monthly = 'monthly',
  Quarterly = 'quarterly',
}

type SegmentButtonProps = {
  isActive: boolean;
  timeSegment: TimeSegment;
  setFunction: (timeSegment: TimeSegment) => void;
};

export function TimeSwitcher(): JSX.Element {
  const state = useSelector(gitInsightsSelectionState);
  const setTimeSegment = useSetInsightsTimeSegment();
  return (
    <Container center>
      {Object.values(TimeSegment).map((timeSegment) => (
        <Fluid key={timeSegment}>
          <SegmentButton
            isActive={state.timeSegment === timeSegment}
            setFunction={setTimeSegment}
            timeSegment={timeSegment}
          />
        </Fluid>
      ))}
    </Container>
  );
}

function SegmentButton({ isActive, timeSegment, setFunction }: SegmentButtonProps): JSX.Element {
  return (
    <Box.Button
      background={isActive ? '$darkModeBlue' : '$darker'}
      center
      onClick={() => setFunction(timeSegment)}
      space='$12px'
    >
      <Text color={isActive ? '$darker' : '$secondaryText'} size='$14px'>
        {timeSegment.charAt(0).toUpperCase() + timeSegment.slice(1)}
      </Text>
    </Box.Button>
  );
}

const Container = styled(Box, {
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'row',
  border: `1px solid ${fade('$white', '$10%')}`,
  borderRadius: '$4px',
});
