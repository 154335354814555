/* eslint-disable react/style-prop-object */
import { Box, Fluid, HStack, styled, VStack } from '@taraai/design-system';
import { Attachment, Data, TaskStatus } from '@taraai/types';
import { CopyShare } from 'components/app/controllers/CopyShare';
// import { CopyShare } from 'components/app/controllers/CopyShare';
// import { EditorNotification } from 'components/editor/EditorNotification';
import { getStyledRichEditor } from 'components/editor/RichEditor';
import { RichEditorProvider } from 'components/editor/RichEditorProvider';
import { RichEditorConfig } from 'components/editor/types';
import { EditorAttachments } from 'components/EditorAttachments';
import RemirrorEditor from 'components/remirrorEditor';
import { fileSavePathType } from 'components/remirrorEditor/Editor';
import { EditorNotification } from 'components/remirrorEditor/EditorNotification';
import React, { ReactNode, useMemo } from 'react';
import { SaveState } from 'tools/reconciliation/useForceSave';
import { mapTaskStatusToColor } from 'tools/utils/mapTaskStatusToColor';

type Props = {
  attachments: Attachment[];
  description: string;
  handleAttachmentRemove: (attachment: Attachment) => void;
  id: Data.Id.RequirementId | Data.Id.TaskId;
  placeholder?: string;
  status?: TaskStatus;
  SubTitle?: ReactNode;
  title: string;
  titleConfig: RichEditorConfig;
  forceSaveDescription: (override?: string) => Promise<string | Error | null>;
  trySaveDescription: (value: string) => Promise<string | Error | null>;
  trySaveTitle: (newTitle: string) => Promise<string | Error | null>;
  type: fileSavePathType;
  createNewDocument: () => void;
  descriptionSaveState: SaveState;
};

export const RemirrorDefinePanel = React.memo(function RemirrorDefinePanel({
  attachments,
  description,
  handleAttachmentRemove,
  placeholder = '',
  id,
  status,
  SubTitle,
  title,
  titleConfig,
  forceSaveDescription,
  trySaveDescription,
  trySaveTitle,
  type,
  createNewDocument,
  descriptionSaveState,
}: Props): JSX.Element | null {
  const copyShareStyle = 'white';

  const editor = useMemo(
    () => (
      <RemirrorEditor
        id={id}
        initialContent={description}
        onSave={trySaveDescription}
        placeholder={placeholder}
        type={type}
      />
    ),
    [description, id, placeholder, trySaveDescription, type],
  );

  return (
    <Box full>
      <VStack full>
        <Fluid>
          <Box background='$white'>
            <VStack full>
              <HStack alignY='center'>
                <MainTitleWrapper status={status}>
                  <RichEditorProvider config={titleConfig} initialValue={title} onSave={trySaveTitle}>
                    <MainTitle />
                  </RichEditorProvider>
                </MainTitleWrapper>
                <Fluid />
                <EditorNotification
                  createNewDocument={createNewDocument}
                  editorState={descriptionSaveState}
                  refresh={() => {
                    return null;
                  }}
                  save={forceSaveDescription}
                />
                <Box spaceRight='$8px'>
                  <CopyShare id={id} style={copyShareStyle} type={type} />
                </Box>
              </HStack>
              {SubTitle}
              <Fluid>{editor}</Fluid>
            </VStack>
          </Box>
        </Fluid>

        {attachments.length ? (
          <AttachmentsBox background='$white'>
            <EditorAttachments attachments={attachments} onRemove={handleAttachmentRemove} />
          </AttachmentsBox>
        ) : null}
      </VStack>
    </Box>
  );
});

const zIndexes = { description: 0, fader: 1, title: 2, toolbar: 3 };

const MainTitleWrapper = styled(
  'div',
  {
    'width': '100%',

    '--bottomPadding': 'space.$12px',
    '--topPadding': 'space.$16px',

    'position': 'relative',
    'zIndex': zIndexes.title,

    '&:before': {
      borderLeft: 'space.$4px solid var(--editorColor)',
      bottom: 'var(--bottomPadding)',
      content: '""',
      position: 'absolute',
      top: 'var(--topPadding)',
    },
  },
  {
    status: {
      [TaskStatus.Todo]: { '--editorColor': `colors.${mapTaskStatusToColor[TaskStatus.Todo]}` },
      [TaskStatus.Doing]: { '--editorColor': `colors.${mapTaskStatusToColor[TaskStatus.Doing]}` },
      [TaskStatus.Done]: { '--editorColor': `colors.${mapTaskStatusToColor[TaskStatus.Done]}` },
      [TaskStatus.QA]: { '--editorColor': `colors.${mapTaskStatusToColor[TaskStatus.QA]}` },
      undefined: { '--editorColor': 'colors.$indigo' },
    },
  },
);

const MainTitle = getStyledRichEditor({
  color: 'var(--editorColor)',
  fontSize: '$18px',
  fontWeight: '$medium',
  lineHeight: '$18px',
  padding: 'var(--topPadding) 24px var(--bottomPadding)',
});

const AttachmentsBox = styled(Box, {
  padding: '12px 24px',
});
