/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
import { Data, UI } from '@taraai/types';
import { SprintRequiredActions } from '@taraai/types/dist/data';
import { decode } from 'reduxStore/utils/decoders';
import { Write } from 'reduxStore/utils/types';

const SERVER_TIMESTAMP = '::serverTimestamp';

type MoveTaskReads = {
  uid: Data.Id.UserId;
  task: UI.UITask & { path: string };
  destinationSprint?: UI.UISprint & { path: string };
  sourceSprint?: (UI.UISprint & { path: string }) | null;
  requirement?: UI.UIRequirement & { path: string };
  assignee?: string;
  removeFromCarryOver?: boolean;
};

export const moveTask = ({
  task,
  uid,
  destinationSprint,
  sourceSprint,
  requirement,
  assignee,
  removeFromCarryOver,
}: MoveTaskReads): Write[] => {
  const writes: Write[] = [];
  writes.push(
    decode<UI.UIRequirementMutation>(
      {
        id: task.id,
        path: task.path,
        updatedBy: uid,
        updatedAt: [SERVER_TIMESTAMP],
        lastUpdateVia: 'tara',
        ...(destinationSprint ? { sprint: destinationSprint.id } : {}),
        ...(destinationSprint && destinationSprint.isComplete ? { status: 2 } : {}),
        ...(assignee ? { assignee } : {}),
        ...(requirement ? { '_relationships.requirement': requirement.id } : {}),
        ...(requirement && sourceSprint ? { sprint: null } : {}),
      },
      'UITaskMutation',
    ),
  );

  if (requirement && task._relationships.requirement !== null) {
    writes.push(
      decode<UI.UIRequirementMutation>(
        {
          id: task._relationships.requirement,
          path: task.path.replace('tasks', 'requirements'),
          updatedAt: [SERVER_TIMESTAMP],
          updatedBy: uid,
          orderedTaskIds: ['::arrayRemove', task.id],
        },
        'UIRequirementMutation',
      ),
    );
  }

  // is moving between sprints
  if (destinationSprint && task.sprint !== null) {
    const isLast = destinationSprint.carryOverTaskIds.length === 1;

    writes.push(
      decode<UI.UISprintMutation>(
        {
          id: task.sprint,
          path: task.path.replace('tasks', 'sprints'),
          ...(isLast && destinationSprint.requiresAction === SprintRequiredActions.reconcile
            ? { requiresAction: null }
            : {}),
          ...(isLast && destinationSprint.requiresAction !== SprintRequiredActions.reconcile
            ? { requiresAction: SprintRequiredActions.summary }
            : {}),
        },
        'UISprintMutation',
      ),
    );
  }

  if (sourceSprint) {
    const isLast = sourceSprint.carryOverTaskIds.length === 1;
    const ismoving = destinationSprint && sourceSprint.id !== destinationSprint.id;

    writes.push(
      decode<UI.UISprintMutation>(
        {
          id: sourceSprint.id,
          path: task.path.replace('tasks', 'sprints'),
          orderedTaskIds: ismoving ? ['::arrayRemove', task.id] : sourceSprint.orderedTaskIds,
          carryOverTaskIds: removeFromCarryOver ? ['::arrayRemove', task.id] : sourceSprint.carryOverTaskIds,
          ...(isLast && sourceSprint.requiresAction === SprintRequiredActions.reconcile
            ? { requiresAction: null }
            : {}),
          ...(isLast && sourceSprint.requiresAction !== SprintRequiredActions.reconcile
            ? { requiresAction: SprintRequiredActions.summary }
            : {}),
        },
        'UISprintMutation',
      ),
    );
  }

  return writes;
};

type OrphanMoveReads = {
  task: Pick<UI.UITask, 'id' | 'path'>;
  uid: Data.Id.UserId;
};

export const moveToOrphan = ({ task, uid }: OrphanMoveReads): Write =>
  decode<UI.UITaskMutation>(
    {
      'id': task.id,
      'path': task.path,
      'sprint': null,
      'updatedBy': uid,
      'updatedAt': [SERVER_TIMESTAMP],
      '_relationships.requirement': null,
      'lastUpdateVia': 'tara',
    },
    'UITaskMutation',
  );

export const removeFromSprint = ({ task, uid }: OrphanMoveReads): Write =>
  decode<UI.UITaskMutation>(
    {
      id: task.id,
      path: task.path,
      sprint: null,
      updatedBy: uid,
      updatedAt: [SERVER_TIMESTAMP],
      lastUpdateVia: 'tara',
    },
    'UITaskMutation',
  );
