import { Data } from '@taraai/types';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectActiveTeam, selectActiveWorkspace } from 'reduxStore';

import { IndividualSprintSettingsView } from './IndividualSprintSettingsView';

type Props = {
  id: Data.Id.SprintId;
};

export const IndividualSprintSettings = ({ id }: Props): JSX.Element => {
  const orgId = useSelector(selectActiveWorkspace);
  const teamId = useSelector(selectActiveTeam);
  return <IndividualSprintSettingsView id={id} orgId={orgId} teamId={teamId} />;
};
