"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Placeholder = void 0;
var react_1 = __importStar(require("react"));
var Box_1 = require("../Box");
var core_1 = require("../core");
var typography_1 = require("../typography");
exports.Placeholder = (0, react_1.forwardRef)(function Placeholder(_a, ref) {
    var children = _a.children, height = _a.height, width = _a.width;
    return (react_1.default.createElement(Box_1.Box, { ref: ref, background: '$debugLight', border: ['$debug', '$2px'], center: true, spaceHorz: '$16px', spaceVert: '$8px', style: { height: height, width: width } }, children ? (react_1.default.createElement(typography_1.Text, { color: '$debug', size: '$16px' }, children)) : (react_1.default.createElement(Image, { xmlns: 'http://www.w3.org/2000/svg' },
        react_1.default.createElement("line", { x1: 0, x2: '100%', y1: 0, y2: '100%' }),
        react_1.default.createElement("line", { x1: '100%', x2: 0, y1: 0, y2: '100%' })))));
});
var Image = (0, core_1.styled)('svg', {
    height: '$full',
    left: 0,
    position: 'absolute',
    stroke: '$debug',
    strokeWidth: 2,
    top: 0,
    width: '$full',
});
