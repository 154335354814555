/* eslint-disable sonarjs/cognitive-complexity */
import { isLoaded } from '@taraai/read-write';
import { Data, UI } from '@taraai/types';
import { linkTo, matchRoute } from 'components/Router/paths';
import React, { useCallback, useEffect, useMemo } from 'react';
import deepEquals from 'react-fast-compare';
import { DefaultRootState, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { compose } from 'redux';
import { getTask, selectActiveTeam, selectActiveWorkspace, selectTaskList, useAppDispatch } from 'reduxStore';
import { openTaskModal } from 'reduxStore/modals/actions';
import {
  useWorkDrawerMarkRendered,
  useWorkDrawerSettingsSetState,
  useWorkDrawerUnfoldedSetState,
  WorkdrawerPageMode,
} from 'reduxStore/workDrawer';
import { useQuerySetState, useQueryValue } from 'tools';
import { segment } from 'tools/libraries/analytics';

import { WorkDrawer } from './WorkDrawer';

export type SelectTaskFragment = Pick<UI.UITask, 'id' | 'status' | 'sprint' | 'externalIssue'>;

export type WorkDrawerImportTasksFragment = { tasks: SelectTaskFragment[]; title: string };

export type WorkDrawerImportTasksId = { tasks: SelectTaskFragment[]; title: string };

export type RepoFragment = Pick<UI.UIProduct, 'id' | 'title'>;

export const WorkDrawerController = function WorkDrawerController(): JSX.Element {
  const teamId = useSelector(selectActiveTeam);
  const orgId = useSelector(selectActiveWorkspace);
  const history = useHistory();
  const setQuery = useQuerySetState();
  const idFromQuery = useQueryValue('active');
  const typeFromQuery = useQueryValue('type');

  const sprintPageTaskId = (typeFromQuery === 'task' && idFromQuery) || undefined;
  const sprintPageRequirementId = (typeFromQuery === 'requirement' && idFromQuery) || undefined;

  const mode: WorkdrawerPageMode = matchRoute(['requirements', 'requirement', 'task'])
    ? WorkdrawerPageMode.define
    : WorkdrawerPageMode.sprints;

  const { requirementId: definePageRequirementId, taskId: definePageTaskId } = useParams<{
    requirementId: Data.Id.RequirementId;
    taskId: Data.Id.TaskId;
  }>();

  const selectedTaskId = mode === WorkdrawerPageMode.sprints ? sprintPageTaskId : definePageTaskId;
  const selectedRequirementId = mode === WorkdrawerPageMode.sprints ? sprintPageRequirementId : definePageRequirementId;

  const selectedTaskSlice = getTask(orgId, selectedTaskId);

  const selectedTaskRequirement = useSelector(
    compose((task) => {
      // if a task is in a sprint then don't return requirementId
      if (task?.sprint) return;
      return task?._relationships.requirement;
    }, selectedTaskSlice.selector),
    deepEquals,
  );

  const isSelectedTaskAnImportedTask = useSelector(
    compose((task) => {
      return task?.externalIssue !== undefined;
    }, selectedTaskSlice.selector),
    deepEquals,
  );

  const handleRequirementSelect = useCallback(
    (requirementId: Data.Id.TaskId | Data.Id.RequirementId): void => {
      if (mode === WorkdrawerPageMode.sprints) {
        segment.track('RequirementClicked', {
          orgID: orgId,
          requirementID: requirementId,
          location: 'SprintPageWorkDrawer',
        });
        setQuery({ type: 'requirement', active: requirementId });
      } else {
        segment.track('RequirementClicked', {
          orgID: orgId,
          requirementID: requirementId,
          location: 'DefinePageWorkDrawer',
        });
        history.push(linkTo('requirement', { orgId, teamId, requirementId }));
      }
    },
    [history, mode, orgId, setQuery, teamId],
  );

  const dispatch = useAppDispatch();
  const handleTaskSelect = useCallback(
    (taskId: Data.Id.TaskId | Data.Id.RequirementId): void => {
      if (mode === WorkdrawerPageMode.sprints) {
        segment.track('TaskClicked', {
          orgID: orgId,
          taskID: taskId,
          location: 'SprintPageWorkDrawer',
        });
        dispatch(openTaskModal(taskId));
      } else {
        segment.track('TaskClicked', {
          orgID: orgId,
          taskID: taskId,
          location: 'DefinePageWorkDrawer',
        });
        history.push(linkTo('task', { orgId, teamId, taskId }));
      }
    },
    [dispatch, history, mode, orgId, teamId],
  );

  const redirectToFirstRequirement = useMemo(
    () => !selectedRequirementId && !selectedTaskId && mode === WorkdrawerPageMode.define,
    [mode, selectedRequirementId, selectedTaskId],
  );

  useDefineWorkDrawerState({
    selectedRequirementId,
    selectedTaskId,
    selectedTaskRequirement,
    unfoldImported: isSelectedTaskAnImportedTask,
    mode,
  });

  return (
    <WorkDrawer
      onRequirementSelect={handleRequirementSelect}
      onTaskSelect={handleTaskSelect}
      redirectToFirstRequirement={redirectToFirstRequirement}
      selectedTaskId={selectedTaskId}
    />
  );
};

function useDefineWorkDrawerState({
  selectedRequirementId,
  selectedTaskId,
  selectedTaskRequirement,
  unfoldImported = undefined,
  mode,
}: {
  selectedRequirementId: string | undefined;
  selectedTaskId: string | undefined;
  selectedTaskRequirement: string | null | undefined;
  unfoldImported: boolean | undefined;
  mode: WorkdrawerPageMode;
}): void {
  const markRendered = useWorkDrawerMarkRendered();
  const setUnfoldedTasks = useWorkDrawerUnfoldedSetState('tasks');
  const setUnfoldedRequirementsSection = useWorkDrawerUnfoldedSetState('requirementsSection');
  const setUnfoldedRequirement = useWorkDrawerUnfoldedSetState(
    'requirements',
    selectedRequirementId || selectedTaskRequirement || '',
  );
  const setUnfoldedImported = useWorkDrawerUnfoldedSetState('imports');
  const setUnfoldedImportedTask = useWorkDrawerUnfoldedSetState('imports', selectedTaskId ?? '');

  const refreshPageMode = useWorkDrawerSettingsSetState(undefined, mode);

  useEffect(() => {
    refreshPageMode(undefined);

    if (selectedRequirementId) {
      setUnfoldedRequirementsSection(true);
      setUnfoldedRequirement(true);
      markRendered();
    } else if (selectedTaskId) {
      if (!isLoaded(selectedTaskRequirement)) return;
      if (unfoldImported) {
        setUnfoldedImported(true);
        setUnfoldedImportedTask(true);
      }
      if (selectedTaskRequirement) {
        setUnfoldedRequirementsSection(true);
        setUnfoldedRequirement(true);
      } else {
        setUnfoldedTasks(true);
      }
      // Don't put it at the end since there's an early return in this branch
      markRendered();
    } else {
      markRendered();
    }
  }, [
    markRendered,
    refreshPageMode,
    selectedRequirementId,
    selectedTaskId,
    selectedTaskRequirement,
    setUnfoldedImported,
    setUnfoldedImportedTask,
    setUnfoldedRequirement,
    setUnfoldedRequirementsSection,
    setUnfoldedTasks,
    unfoldImported,
  ]);
}

export const selectTaskFragment = (state: DefaultRootState, queryAlias: string): SelectTaskFragment[] | undefined =>
  selectTaskList(state, queryAlias, ['id', 'status', 'sprint', 'externalIssue']);
