import { css, cx } from 'emotion';
import React, { useMemo } from 'react';

export interface ListViewProps extends React.HTMLProps<HTMLUListElement> {
  /**
   * An array of children to show in the list.
   */
  children: JSX.Element[];

  /**
   * Styles for the list items
   */
  childrenClassName?: string;
}

/**
 * An optimized scrolling list view.
 *
 * Assume that only the children currently
 *
 * ```jsx
 * <ListView childrenClassName={css``}>
 *  {children}
 * </ListView>
 * ```
 *
 */
export default function ListView({ children, childrenClassName, className, ...props }: ListViewProps): JSX.Element {
  return useMemo(
    () => (
      <ul
        className={cx(
          css`
            list-style-type: none;
            overflow: scroll;
            position: relative;
            max-height: 100%;
            max-width: 100%;
          `,
          className,
        )}
        {...props}
      >
        {children.map((item: JSX.Element) => (
          <li key={item.key || item.toString()} className={cx(css``, childrenClassName)}>
            {item}
          </li>
        ))}
      </ul>
    ),
    [className, children, childrenClassName, props],
  );
}
