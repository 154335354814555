import { keyframes } from '@emotion/core';
import { compose, unwrapResult } from '@reduxjs/toolkit';
import { isLoaded, useFirestoreConnect } from '@taraai/read-write';
import { Data, UI } from '@taraai/types';
import RemovedTaskView from 'components/app/controllers/views/RemovedTaskView';
import LegacyTaskModal from 'components/app/controllers/views/TaskModal';
import { StandardSpinner } from 'components/core/controllers/views/Spinners';
import { css } from 'emotion';
import React, { useState } from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import {
  archiveTask,
  getRequirement,
  getTask,
  getTaskGitData,
  getTaskRevision,
  selectActiveWorkspace,
  selectUser,
  taskSubTasks,
  useAppDispatch,
} from 'reduxStore';
import { getTaskComments } from 'reduxStore/comments/queries';
import strings from 'resources/i18n';
import { useToast } from 'tools/utils/hooks/useToast';

export interface TaskControllerProps {
  taskId: Data.Id.TaskId;
}

/**
 * TaskController
 * top level controller for high level task data
 */
export default function TaskController({ taskId }: TaskControllerProps): JSX.Element {
  const dispatch = useAppDispatch();
  const orgId = useSelector(selectActiveWorkspace);
  const { whenError } = useToast();
  const [taskCopiedText, setTaskCopiedText] = useState(false);

  const taskSlice = getTask(orgId, taskId);
  const subTasksSlice = taskSubTasks(orgId, taskId);
  const commentsSlice = getTaskComments(orgId, taskId);

  const task = useSelector(taskSlice.selector);
  const author = useSelector(selectUser(orgId, task?.author ?? ''));
  const gitDataSlice = getTaskGitData(orgId, taskId);
  const revisionsSlice = getTaskRevision(orgId, taskId);

  const requirementId = task?._relationships?.requirement || '';
  const individualRequirementSlice = getRequirement(orgId, requirementId);
  const requirementFragment = useSelector(
    compose(
      (requirement) =>
        requirement && {
          title: requirement.title,
        },
      individualRequirementSlice.selector,
    ),
  );

  useFirestoreConnect([
    ...taskSlice.query,
    ...revisionsSlice.query,
    ...gitDataSlice.query,
    ...commentsSlice.query,
    ...subTasksSlice.query,
    ...individualRequirementSlice.query,
  ]);

  const subtaskIds = useSelector(compose(getSubtaskIds, subTasksSlice.selector), deepEquals) ?? [];

  const taskComments = useSelector(commentsSlice.selector)?.filter(({ deletedAt }) => deletedAt === null);

  const gitData = useSelector(gitDataSlice.selector);

  const deleteCurrentTask = (): void => {
    task &&
      dispatch(archiveTask([...subtaskIds, { id: task.id }]))
        .then(unwrapResult)
        .catch(() => {
          whenError(strings.task.failedToArchiveTask);
        });
  };

  const copyTaskId = (): void => {
    navigator.clipboard.writeText(`${window.location.origin}/${orgId}/*/tasks/${taskId}`);
    setTaskCopiedText(true);
  };

  if (!isLoaded(task) || !isLoaded(taskComments)) {
    return (
      <StandardSpinner
        className={css`
          animation: ${fadeIn} 2s ease-in-out;
        `}
        fillSpace
        size='medium'
      />
    );
  }

  if (task.archived) {
    return <RemovedTaskView currentOrg={orgId} />;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <LegacyTaskModal
        author={author}
        copyTaskId={copyTaskId}
        currentOrg={orgId}
        deleteCurrentTask={deleteCurrentTask}
        gitData={gitData}
        noModal
        requirementId={requirementId}
        requirementTitle={requirementFragment?.title}
        setTaskCopiedText={setTaskCopiedText}
        task={task}
        taskComments={taskComments}
        taskCopiedText={taskCopiedText}
      />
    </DndProvider>
  );
}

const getSubtaskIds = (data: UI.UITask[] | undefined): Pick<UI.UITask, 'id'>[] | undefined =>
  data?.map(({ id }) => ({
    id,
  }));

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  95% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
