import { createSelector, Selector } from '@reduxjs/toolkit';
import { Data, UI } from '@taraai/types';
import { RootState } from 'reduxStore/store';
import { selectActiveUsers } from 'reduxStore/users/selectors';

const attachEmailToUser =
  (emails: Record<string, string>) =>
  (user: UI.UIUser): UI.UIUser => ({
    ...user,
    email: emails[user?.id],
  });

export const selectOrgUsersWithEmails = createSelector(
  (state: RootState): RootState => state,
  (none: unknown, orgId: Data.Id.OrganizationId): Data.Id.OrganizationId => orgId,
  (state: RootState) => state.userEmails,
);

export const selectActiveUsersWithEmails = (
  orgId: Data.Id.OrganizationId,
): Selector<RootState, UI.UIUser[] | undefined> =>
  createSelector(
    [selectActiveUsers(orgId), (state: RootState) => selectOrgUsersWithEmails(state, orgId)],
    (activeUsers, emails) => {
      if (!emails.orgEmails) {
        return activeUsers;
      }

      return activeUsers.map(attachEmailToUser(emails.orgEmails));
    },
  );
