import { Box, Fluid, HStack, styled, Text } from '@taraai/design-system';
import Icon from 'components/core/controllers/views/Icon';
import { RichEditorContext } from 'components/editor/RichEditorProvider';
import React, { SyntheticEvent, useContext } from 'react';
import { strings } from 'resources';

const LIMIT = 140;

type Props = {
  atLimit: boolean;
  optionsAnimation?: {
    onAnimationEnd: () => void;
    show: boolean;
  };
  text: string;
  tooShort: boolean;
};

export function Options({ atLimit, optionsAnimation, text, tooShort }: Props): JSX.Element {
  const { save, editorState } = useContext(RichEditorContext);

  const handleReturnIconClick = (event: SyntheticEvent): void => {
    event.stopPropagation();
    save(editorState.getCurrentContent());
  };

  return (
    <AnimatedOptions
      alignY='center'
      onAnimationEnd={optionsAnimation?.onAnimationEnd}
      show={optionsAnimation?.show}
      space='$8px'
    >
      {tooShort ? (
        <>
          <Text color='$coreGrey' size='$10px'>
            {strings.workDrawer.assignHelpText}
          </Text>
          <Text color='$coreGrey' size='$10px'>
            {strings.workDrawer.labelHelpText}
          </Text>
          <Text color='$coreGrey' size='$10px'>
            {strings.workDrawer.estimateHelpText}
          </Text>
        </>
      ) : (
        <Box>
          <Text color={atLimit ? '$dark' : '$coreGrey'} size='$10px'>
            {text.length}
          </Text>
          <Text color='$coreGrey' size='$10px'>
            /{LIMIT}
          </Text>
        </Box>
      )}
      <Fluid />
      <ReturnIcon
        color='$coreGrey'
        name={!tooShort ? 'returnActive' : 'returnInactive'}
        noPadding
        onClick={handleReturnIconClick}
      />
    </AnimatedOptions>
  );
}

const AnimatedOptions = styled(
  HStack,
  { overflow: 'hidden', height: '16px', marginTop: '0.5rem', animation: '0.3s linear' },
  {
    show: {
      true: {
        '@keyframes show': {
          'from': { height: 0, marginTop: 0 },
          '33%': { height: 0, marginTop: '0.5rem' },
          'to': { height: '16px', marginTop: '0.5rem' },
        },
        'animationName': 'show',
        'marginTop': '0.5rem',
      },
      false: {
        '@keyframes hide': {
          'from': { height: '16px', marginTop: '0.5rem' },
          '66%': { height: 0, marginTop: '0.5rem' },
          'to': { height: 0, marginTop: 0 },
        },
        'animationName': 'hide',
        'height': 0,
        'marginTop': 0,
      },
    },
  },
);

const ReturnIcon = styled(Icon, { color: 'transparent' });
