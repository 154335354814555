import { Data } from '@taraai/types';
import Radio from 'components/core/controllers/views/Radio';
import Text from 'components/core/controllers/views/Text';
import { css } from 'emotion';
import React from 'react';
import { strings } from 'resources';

export type EffortTogglerProps = {
  description?: string;
  effortUnit: Data.EffortUnit;
  label?: string;
  onChange?: (effortUnit: Data.EffortUnit) => void;
};

const EFFORT_UNITS: { label: string; value: Data.EffortUnit }[] = [
  {
    label: strings.workspace.effortToggler.units.days,
    value: 'days',
  },
  {
    label: strings.workspace.effortToggler.units.hours,
    value: 'hours',
  },
  {
    label: strings.workspace.effortToggler.units.storyPoints,
    value: 'storyPoints',
  },
];

export function EffortToggler({ description, effortUnit, label, onChange }: EffortTogglerProps): JSX.Element {
  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
      `}
    >
      {label && (
        <Text
          className={css`
            font-size: 1rem;
            color: #67728b;
            padding-bottom: 0.5rem;
          `}
        >
          {label}
        </Text>
      )}
      {description && (
        <Text
          className={css`
            font-size: 0.875rem;
            color: #67728b;
            padding-bottom: 1rem;
          `}
        >
          {description}
        </Text>
      )}
      <div
        className={css`
          display: flex;
          & label {
            margin-right: 2.5rem;
          }
        `}
      >
        {EFFORT_UNITS.map((unit) => (
          <Radio
            key={unit.value}
            checked={effortUnit === unit.value}
            label={unit.label}
            onClick={(): void => onChange?.(unit.value)}
          />
        ))}
      </div>
    </div>
  );
}
