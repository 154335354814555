import { styled, Text } from '@taraai/design-system';
import React, { ReactNode } from 'react';

interface HeaderButtonProps {
  children?: ReactNode;
  isActive: boolean;
  onClick: () => void;
}

export function HeaderButton({ children, isActive, onClick }: HeaderButtonProps): JSX.Element {
  return (
    <StyledButton disabled={!isActive} isActive={isActive} onClick={onClick}>
      <Text color={isActive ? '$focus' : '$grey2'} size='$10px' weight='medium'>
        {children}
      </Text>
    </StyledButton>
  );
}

const StyledButton = styled(
  'button',
  {
    background: 'none',
    border: 'none',
    outline: 'none',
    padding: 0,
  },
  {
    isActive: {
      true: {
        cursor: 'pointer',
      },
    },
  },
);
