/* eslint-disable @typescript-eslint/no-explicit-any */
import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { firebaseReducer, firestoreReducer, getFirebase, getFirestore, reduxFirestore } from '@taraai/read-write';
import firebase from 'firebase/compat/app';
import { ThunkDispatch } from 'redux-thunk';
import { firebaseInit } from 'tools/libraries/firebase';

import { appSelectionReducer } from './appSelection';
import { gitInsightsReducer } from './gitInsights';
import { importsReducer } from './imports/actions';
import { getOrgId, getTeamId, getUserId, hackForSearchReducer } from './middlewares';
import { taskModalReducer } from './modals/actions';
import { organizationInvitationReducer } from './onboarding/reducers';
import { workspaceReducer } from './organization';
import { searchReducer } from './search';
import { sprintsReducer } from './sprints/reducers';
import { tasksReducer } from './tasks';
import { teamReducer } from './teams';
import { usersWithAccessLevelsReducer } from './usersWithAccessLevels/reducers';
import { usersWithEmailsReducer } from './usersWithEmails/reducers';
import { Profile } from './utils';
import { workDrawerReducer } from './workDrawer';

// Initialize other services on firebase instance
firebaseInit();

// Add firebase to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  imports: importsReducer,
  taskModal: taskModalReducer,
  organizationInvitation: organizationInvitationReducer,
  search: searchReducer,
  sprints: sprintsReducer,
  tasks: tasksReducer,
  team: teamReducer,
  userEmails: usersWithEmailsReducer,
  usersWithAccessLevels: usersWithAccessLevelsReducer,
  workDrawer: workDrawerReducer,
  workspace: workspaceReducer,
  appSelection: appSelectionReducer,
  gitInsights: gitInsightsReducer,
});

const enhancers = [reduxFirestore(firebase as any)];

// Create store with reducers
export const reduxStore = configureStore({
  reducer: rootReducer,
  enhancers,
  middleware: (getDefaultMiddleware) => [
    hackForSearchReducer,
    ...getDefaultMiddleware({
      serializableCheck: false,
      thunk: {
        extraArgument: {
          getFirestore,
          getFirebase,
          getUserId,
          getOrgId,
          getTeamId,
        },
      },
    }),
  ],
});

export type AppDispatch = typeof reduxStore.dispatch;

type RootStateRaw = ReturnType<typeof reduxStore.getState>;
export type RootState = Omit<RootStateRaw, 'firebase'> & {
  firebase: Omit<RootStateRaw['firebase'], 'profile'> & { profile: Profile };
};
export type RootStateWithProfile = RootState;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;
