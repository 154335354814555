import { Box, getCustomSize, HStack, Spacer, Text } from '@taraai/design-system';
import { isLoaded } from '@taraai/read-write';
import { Data, UI } from '@taraai/types';
import { SectionWithLoader } from 'components/app/TaskDetails/common/Section';
import React, { useState } from 'react';
import { TaskGitData } from 'reduxStore';
import { strings } from 'resources';
import { taskDetailsTestIds } from 'resources/cypress/testAttributesValues';

import { InfoPopup } from './InfoPopup';

type Props = {
  gitData: TaskGitData | undefined;
  taskId: Data.Id.TaskId;
};

const GITHUB_URL = 'https://github.com';
const GITLAB_URL = 'https://gitlab.com';

export const Git = ({ gitData, taskId }: Props): JSX.Element => {
  const [showPopup, setShowPopup] = useState(false);
  const hasConnectedData =
    isLoaded(gitData) && (!!gitData.branches[0] || !!gitData.commits[0] || !!gitData.pullRequests[0]);

  return (
    <Box onMouseLeave={() => setShowPopup(false)}>
      <SectionWithLoader
        content={(loadedGitData) => {
          const { branches, pullRequests, commits } = loadedGitData;
          return (
            <Box key={taskId} spaceTop='$12px'>
              <Text data-cy={taskDetailsTestIds.GIT_DATA} size='$12px'>
                {getTitle(branches, pullRequests, commits, taskId)}
                {getContent(branches, pullRequests, commits)}
              </Text>
            </Box>
          );
        }}
        data={gitData}
        right={
          <InfoPopup
            data-cy={taskDetailsTestIds.INFO_POPUP}
            hasConnectedData={hasConnectedData}
            setShowPopup={setShowPopup}
            showPopup={showPopup}
            taskId={taskId}
          />
        }
        title={strings.taskSidebar.modules.git.title}
      />
    </Box>
  );
};

/**
 * Prepare git note content based on task git data with the following priority:
 * pull request, branch, commit, no data
 */

/* get Title for each git activity*/
const getTitle = (
  branches: UI.UIBranchSummary[],
  pullRequests: UI.UIPullRequestSummary[],
  commits: UI.UICommitSummary[],
  taskId: Data.Id.TaskId,
): string | string[] => {
  if (pullRequests.length) {
    return pullRequests.length > 1
      ? strings.taskSidebar.modules.git.pullRequestsConnected
      : strings.taskSidebar.modules.git.pullRequestConnected;
  }
  if (branches.length) {
    return branches.length > 1
      ? strings.taskSidebar.modules.git.branchesConnected
      : strings.taskSidebar.modules.git.branchConnected;
  }
  if (commits.length) {
    return strings.taskSidebar.modules.git.connectedToPrefix;
  }
  return strings.formatString(strings.taskSidebar.modules.git.addTaskIdNote, {
    taskId: strings.formatString(strings.task.taskId, { taskId }) as string,
  });
};

/* Display only PRs if there are PRs, branches and commits associated to a task; else display branches or commits */
const getContent = (
  branches: UI.UIBranchSummary[],
  pullRequests: UI.UIPullRequestSummary[],
  commits: UI.UICommitSummary[],
): JSX.Element[] => {
  if (pullRequests.length) {
    return pullRequests.map(getPullRequests);
  }
  if (branches.length) {
    return branches.map(getBranches);
  }
  if (commits.length) {
    return commits.map(getCommits);
  }
  return [];
};

const getPullRequests = ({ data: prSummary }: UI.UIPullRequestSummary): JSX.Element => {
  return (
    <Box key={prSummary.pullRequestId} spaceTop='$12px'>
      <HStack alignY='center'>
        <Box
          background={prSummary.state === 'open' ? '$grey3' : '$grey6'}
          borderRadius='$2px'
          center='horz'
          minWidth={getCustomSize(60)}
          space='$4px'
        >
          <Text color={prSummary.state === 'open' ? '$grey6' : '$white'} size='$12px' uppercase weight='medium'>
            {prSummary.state}
          </Text>
        </Box>
        <Spacer space='$8px' />
        <HStack align='right'>
          <Box maxWidth={getCustomSize(210)}>
            <Text color='$dark' size='$12px' weight='bold'>
              <a href={prSummary.url} rel='noreferrer noopener' target='_blank'>
                {prSummary.title}
              </a>
            </Text>
          </Box>
        </HStack>
      </HStack>
    </Box>
  );
};
const getBranches = (branch: UI.UIBranchSummary): JSX.Element => {
  const url =
    branch.externalService.service === 'github'
      ? createBranchUrl(GITHUB_URL, branch.externalService.repository, branch.name)
      : createBranchUrl(GITLAB_URL, branch.externalService.repository, branch.name);

  return (
    <Box key={branch.branchId} spaceTop='$12px'>
      <Text color='$dark' size='$12px' weight='bold'>
        <a href={url} rel='noreferrer noopener' target='_blank'>
          {branch.name}
        </a>
      </Text>
    </Box>
  );
};

const getCommits = (commit: UI.UICommitSummary): JSX.Element => {
  return (
    <Box key={commit.commitId}>
      <Text color='$dark' size='$12px' weight='bold'>
        <a href={commit.url} rel='noreferrer noopener' target='_blank'>
          {commit.message}
        </a>
      </Text>
    </Box>
  );
};

function createBranchUrl(url: string, repo: string, branch: string): string {
  return `${url}/${repo}/tree/${branch}`;
}
