import {
  Box,
  Fluid,
  HStack,
  SegmentControl,
  SelectInput,
  Spacer,
  Text,
  TimeInput,
  VStack,
} from '@taraai/design-system';
import { Data } from '@taraai/types';
import React from 'react';
import { strings } from 'resources';

import { SprintSettingsViewProps } from './types';

const weekDays = [
  Data.WeekDay.Saturday,
  Data.WeekDay.Sunday,
  Data.WeekDay.Monday,
  Data.WeekDay.Tuesday,
  Data.WeekDay.Wednesday,
  Data.WeekDay.Thursday,
  Data.WeekDay.Friday,
];

export const SprintEndTime = ({ onChange, sprintSettings }: SprintSettingsViewProps): JSX.Element => {
  const timezoneInputOptions = Array.from(Data.TIMEZONES_SHORTLIST, ({ label, value }) => ({
    label,
    option: value,
  }));
  return (
    <Box background='$grey1' spaceHorz='$16px' spaceVert='$12px'>
      <VStack>
        <Text color='$dark' size='$14px'>
          {strings.sprintSettings.sprintEndTime}
        </Text>
        <Spacer space='$4px' />
        <Text color='$grey6' size='$12px'>
          {strings.sprintSettings.selectADay}
        </Text>
        <Spacer space='$16px' />
        <SegmentControl onChange={(endDay) => onChange({ ...sprintSettings, endDay })} value={sprintSettings.endDay}>
          {weekDays.map((day) => (
            <Fluid key={day}>
              <SegmentControl.Item value={day}>{strings.sprintSettings.weekDays[day]}</SegmentControl.Item>
            </Fluid>
          ))}
        </SegmentControl>
        <Spacer space='$16px' />
        <HStack space='$16px'>
          <Fluid fractions={2}>
            <TimeInput
              hours={sprintSettings.endHours}
              minutes={sprintSettings.endMinutes}
              onChange={({ minutes, hours }) =>
                onChange({ ...sprintSettings, endHours: hours as Data.Hours, endMinutes: minutes as Data.Minutes })
              }
              size='$14px'
            />
          </Fluid>
          <Fluid fractions={3}>
            <SelectInput
              onChange={(timezone) => onChange({ ...sprintSettings, timezone: timezone ?? null })}
              options={timezoneInputOptions}
              value={sprintSettings.timezone}
            />
          </Fluid>
        </HStack>
      </VStack>
    </Box>
  );
};
