import { createSelector, OutputParametricSelector } from '@reduxjs/toolkit';
import { useFirestoreConnect } from '@taraai/read-write';
import { Data, PathId, UI } from '@taraai/types';
import { notNull, notUndefined } from '@taraai/utility';
import uniqBy from 'lodash.uniqby';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import deepEquals from 'react-fast-compare';
import { DefaultRootState, useSelector } from 'react-redux';
import {
  getLastSprint,
  Query,
  QueryAlias,
  selectActiveTeam,
  selectActiveWorkspace,
  selectSprintList,
  selectTeamDocument,
} from 'reduxStore';
import { formatDMMM, toTimestamp } from 'tools/libraries/helpers/dates';
import { sort } from 'tools/libraries/helpers/sort';

export type PhantomSprintPathId = [string, string, true];
export type PhantomSprint = null;
export type SprintPathId = PathId | PhantomSprintPathId;

type SprintFragment = Pick<UI.UISprint, 'id' | 'path'> & { endSeconds: number };
type UsePaginationHookReturn = {
  items: SprintPathId[] | undefined;
  loadCurrentSprint: () => string | undefined;
  setSprint: (sprintId: string) => string | undefined;
  prependPage: () => void;
  appendPage: () => void;
};
type NewPage = {
  newPivotEndDate?: number;
  newBeforeLimit?: number;
  newAfterLimit?: number;
  newSprintId?: string;
};

const minBufferBeforeSelected = 1;
const upperLimitLoad = 4;
const lowerLimitLoad = 2;
const itemsPerPage = 4;

const getPageSliceKey = (config: {
  pageSize: number;
  beforeLimit: number | null;
  afterLimit: number | null;
  pivotEndDate: number;
}): string => {
  return JSON.stringify({
    ...config,
    pivotEndDate: formatDMMM(toTimestamp({ seconds: config.pivotEndDate })),
  });
};

// Method responsible for searching the selected sprint and increase
// the window so it is within the boundaries
const expandToSelected = (
  pageSize: number,
  selected: string | undefined,
  allSprints: (SprintFragment | null)[],
  offset: number,
): NewPage => {
  const sprints = allSprints.filter(notNull);
  const selectedId = selected ?? sprints[Math.floor(sprints.length / 2)]?.id;
  if (!selectedId) return {};

  const activeIndex = sprints.findIndex((sprint) => sprint?.id === selectedId);
  const newIdx = activeIndex + offset;
  if (newIdx < lowerLimitLoad) {
    return {
      newPivotEndDate: sprints[0]?.endSeconds,
      newBeforeLimit: pageSize,
      newAfterLimit: 1, // to make sure don't load too much at once
      newSprintId: sprints[newIdx]?.id,
    };
  }

  if (newIdx > sprints.length - upperLimitLoad) {
    return {
      newPivotEndDate: sprints[sprints.length - 1]?.endSeconds,
      newBeforeLimit: minBufferBeforeSelected, // to make use don't load too much at once
      newAfterLimit: pageSize,
      newSprintId: sprints[newIdx]?.id,
    };
  }

  return { newSprintId: sprints[newIdx]?.id };
};

interface SprintItem {
  sprints?: (SprintFragment | null)[] | undefined;
  items?: SprintPathId[] | undefined;
}

const itemsSelector = (): OutputParametricSelector<
  DefaultRootState,
  number,
  SprintItem,
  (res1: DefaultRootState, res2: number, res3: string[], res4: string) => SprintItem
> =>
  createSelector(
    (state: DefaultRootState) => state,
    (___: unknown, afterLimit: number) => afterLimit,
    (___: unknown, ____: unknown, aliases: QueryAlias[]) => aliases,
    (___: unknown, ____: unknown, _____: unknown, lastSprint: QueryAlias) => lastSprint,

    (state, afterLimit, aliases, lastSprint): SprintItem => {
      const totalSprintsCount =
        selectTeamDocument(state, selectActiveTeam(state as { team: string }))?.totalSprintsCount ?? 1;

      const lastSprintEndDate = selectSprintList(state, lastSprint, ['endDate'])?.shift()?.endDate;

      const allItems = aliases.flatMap((alias) => selectSprintList(state, alias));
      const loadedItems = allItems.filter(notUndefined);

      const minimumLoad = Math.min(totalSprintsCount, afterLimit);
      const allQueriesLoaded = allItems.length === loadedItems.length;
      const canRenderCurrentSprint = loadedItems.length > minimumLoad || allQueriesLoaded;
      if (!canRenderCurrentSprint) {
        return {};
      }

      const allSprints = uniqBy(sort(loadedItems, 'endDate'), 'id');
      const sprints = allSprints.map(({ id, path, endDate }) => ({ id, path, endSeconds: endDate.seconds })) as (
        | SprintFragment
        | PhantomSprint
      )[];
      const items = allSprints.map(({ id, path }) => [path, id] as PathId) as (SprintPathId | PhantomSprintPathId)[];

      // append a phantom sprint when there's not more to sprints to load
      const lastQueriedRealSprint = items[items.length - 1];
      if (
        lastQueriedRealSprint?.length > 0 &&
        (!lastSprintEndDate || lastSprintEndDate?.seconds === allSprints[allSprints.length - 1].endDate.seconds)
      ) {
        sprints.push(null);
        items.push([lastQueriedRealSprint[0], lastQueriedRealSprint[1], true]);
      }

      return { sprints, items };
    },
  );

/**
 * This hook enables us to paginate data using page slices
 * @param pageSize
 * @param initialPivotEndDate
 * @param getPageSlice
 */
export const usePagination = (
  pageSizeBack: number,
  pageSizeForward: number,
  initialSelectedSprint: Data.Id.SprintId,
  currentSprintId: Data.Id.SprintId,
  initialPivotEndDateSeconds: number,
  getPageSlice: (before: number | null, after: number | null, pivotEndDateSeconds: number) => Query<UI.UISprint>,
): UsePaginationHookReturn => {
  const [beforeLimit, setBeforeLimit] = useState<number | null>(pageSizeBack);
  const [afterLimit, setAfterLimit] = useState<number | null>(pageSizeForward + 1);
  const [pivotEndDate, setPivotEndDate] = useState(initialPivotEndDateSeconds);

  const [selectedSprintId, setSelectedSprintId] = useState<string | undefined>(initialSelectedSprint);
  useEffect(() => setSelectedSprintId(initialSelectedSprint), [initialSelectedSprint]);

  // store all slices to be able to display all of the loaded pages
  const sliceMap = useRef<Map<string, Query<UI.UISprint>>>(new Map());

  const orgId = useSelector(selectActiveWorkspace);
  const teamId = useSelector(selectActiveTeam);

  // Query last sprint
  const lastSprintSlice = getLastSprint(orgId, teamId);
  const pageSlice = getPageSlice(beforeLimit, afterLimit, pivotEndDate);
  const pageKey = getPageSliceKey({
    pageSize: pageSizeBack,
    beforeLimit,
    afterLimit,
    pivotEndDate,
  });
  sliceMap.current.set(pageKey, pageSlice);

  // retrieve all loaded page slices
  const allPageSlices = Array.from(sliceMap.current.values());
  const pageQueries = allPageSlices
    .flatMap((slice) => slice.query)
    .filter((query) => query.where?.find(([field, , value]) => field === 'teamId' && value === teamId));
  const aliases = pageQueries.map(({ storeAs }) => storeAs);

  useFirestoreConnect([...pageQueries, ...lastSprintSlice.query]);

  const memoSprintSelector = useMemo(itemsSelector, [afterLimit, aliases, lastSprintSlice.query[0].storeAs]);
  const { sprints, items } = useSelector(
    (state) => memoSprintSelector(state, afterLimit ?? 0, aliases, lastSprintSlice.query[0].storeAs),
    deepEquals,
  );

  const prependPage = useCallback((): void => setBeforeLimit((beforeLimit || 0) + itemsPerPage), [beforeLimit]);
  const appendPage = useCallback((): void => setAfterLimit((afterLimit || 0) + itemsPerPage), [afterLimit]);

  const setSprint = useCallback(
    (sprintId: string): string | undefined => {
      if (!sprints) return;
      const allSprints = sprints?.filter(notNull);
      const requestedIndex = allSprints?.findIndex((sprint) => sprint?.id === sprintId) ?? 0;
      const selectedIndex = allSprints?.findIndex((sprint) => sprint?.id === selectedSprintId) ?? 0;
      const inc = requestedIndex - selectedIndex;

      const { newSprintId, newPivotEndDate, newBeforeLimit, newAfterLimit } = expandToSelected(
        pageSizeForward,
        selectedSprintId,
        sprints,
        inc,
      );

      if (newPivotEndDate && newBeforeLimit && newAfterLimit) {
        setPivotEndDate(newPivotEndDate);
        setBeforeLimit(newBeforeLimit);
        setAfterLimit(newAfterLimit);
      }

      if (newSprintId) {
        setSelectedSprintId(newSprintId);
      }

      return newSprintId;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sprints, pageSizeForward],
  );

  const loadCurrentSprint = useCallback((): string | undefined => {
    return currentSprintId && setSprint(currentSprintId);
  }, [currentSprintId, setSprint]);

  return {
    items,
    loadCurrentSprint,
    setSprint,
    prependPage,
    appendPage,
  };
};
