import { createAsyncThunk } from '@reduxjs/toolkit';
import { Data, UI } from '@taraai/types';
import { isNonEmptyString } from '@taraai/utility';
import { RootState } from 'reduxStore/store';
import { ExtraAPI } from 'reduxStore/utils/types';
import { strings } from 'resources';

export const deleteSprint = createAsyncThunk(
  'DeleteSprint',
  async ({ id: sprintId }: Pick<UI.UISprint, 'id'>, { getState, extra }) => {
    const state = getState() as RootState;
    const { getOrgId, getUserId, getFirestore } = extra as ExtraAPI;
    const firestore = getFirestore();
    const orgId = getOrgId();
    const userId = getUserId(state);

    if (!isNonEmptyString(sprintId)) throw new Error(strings.sprints.error.missingSprintId);

    const orgRef = firestore.collection('orgs').doc(orgId);
    const sprintRef = orgRef.collection('sprints').doc(sprintId);

    return firestore.runTransaction(async (transaction) => {
      // Get sprint that is going to be deleted
      const sprint = (await transaction.get(sprintRef)).data() as Data.Sprint;
      if (!sprint) throw new Error(strings.sprints.error.sprintNotFound);
      const { teamId, sprintName, remainingTasksCount } = sprint;

      // Get sprint's team
      const teamRef = orgRef.collection('teams').doc(teamId);
      const team = (await transaction.get(teamRef)).data() as Data.Team;
      if (!team) throw new Error(strings.sprints.error.teamNotFound);

      // Do not allow to delete active sprint
      if (sprintId === team.currentSprintId) throw new Error(strings.sprints.error.cannotDeleteActiveSprint);

      // Get tasks for deleted sprint
      const tasksSnapshot = await orgRef.collection('tasks').where('sprint', '==', sprintId).get();

      const tasks = tasksSnapshot.docs;

      // Move all tasks from the deleted sprint to backlog
      tasks.forEach((task) =>
        transaction.update(task.ref, {
          sprint: null,
          updatedAt: firestore.Timestamp.now(),
          updatedBy: userId,
        }),
      );

      transaction.update(sprintRef, {
        deleted: true,
        updatedAt: firestore.Timestamp.now(),
      });

      return { id: sprintId, sprintName, remainingTasksCount };
    });
  },
);
