import { styled } from '@taraai/design-system';
import { NotificationBar } from 'components/core/controllers/views/NotificationBar';
import { linkTo } from 'components/Router/paths';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import {
  selectActiveTeam,
  selectActiveWorkspace,
  selectAuth,
  selectOrganization,
  selectProfile,
  updateUser,
  useAppDispatch,
} from 'reduxStore';
import { strings } from 'resources';

export function SlackConnectAccountNotificationBar(): JSX.Element | null {
  const dispatch = useAppDispatch();
  const orgId = useSelector(selectActiveWorkspace);
  const teamId = useSelector(selectActiveTeam);

  const isSlackInstalledInOrg = !!useSelector(
    compose((org) => org.integrations.find((stanza) => stanza.service === 'slack'), selectOrganization(orgId)),
  );
  const hasUserClosedNotification = !!useSelector(
    compose((profile) => profile.tutorialCards?.slackConnectAccount, selectProfile),
  );
  const isSlackProfileConnected = !!useSelector(
    compose((profile) => profile.externalAccounts?.slack?.length, selectProfile),
  );
  const profileId = useSelector(compose((auth) => auth.uid, selectAuth));

  const shouldDisplayNotification = isSlackInstalledInOrg && !isSlackProfileConnected && !hasUserClosedNotification;

  const handleClose = useCallback(() => {
    dispatch(
      updateUser({
        id: profileId,
        tutorialCards: { slackConnectAccount: true },
      }),
    );
  }, [dispatch, profileId]);

  if (!shouldDisplayNotification) {
    return null;
  }

  return (
    <NotificationBar bgColor='#122098' iconName='slack' onCloseRequest={handleClose} showCloseBtn>
      {strings.integrations.slack.connectAccountNotification.slackAppInstalled}
      &nbsp;
      <LinkStyled to={linkTo('integrations', { orgId, teamId })}>
        {strings.integrations.slack.connectAccountNotification.clickToConnect}
      </LinkStyled>
    </NotificationBar>
  );
}

const LinkStyled = styled(Link, {
  textDecoration: 'underline',
  cursor: 'pointer',
});
