import { forEachEntityRange } from 'components/editor/entities';
import { DraftDecorator } from 'components/editor/types';

import { Label } from './Label';

export const labelDecorator: DraftDecorator = {
  strategy: (block, callback, content) => {
    forEachEntityRange('label', content, block, callback);
  },
  component: Label,
};
