import Button from 'components/core/controllers/views/Button';
import { browserHistory } from 'components/Router/history';
import { css } from 'emotion';
import React, { PureComponent } from 'react';
import { strings } from 'resources';
import { reportError } from 'tools/libraries/stackdriver';

interface ErrorBoundaryProps {
  children?: JSX.Element;
  initial?: boolean;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

/**
 * ErrorBoundary examples:
 *
 * By default it shows it's children, but when children throw an error, it replaces the entire app.
 *
 *
 * ```tsx
 * <ErrorBoundary> ... </ErrorBoundary>
 * ```
 *
 * ```tsx
 * <ErrorBoundary initial style={{ minHeight: '37.5rem' }} />
 * ```
 *
 */
export default class ErrorBoundary extends PureComponent<ErrorBoundaryProps, ErrorBoundaryState> {
  static getDerivedStateFromError(): { hasError: boolean } {
    return {
      hasError: true,
    };
  }

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: props.initial ?? false,
    };
  }

  componentDidCatch(error: Error): void {
    reportError(error);
  }

  render(): null | JSX.Element {
    const {
      state: { hasError },
      props: { children = null },
    } = this;

    return hasError ? (
      <div
        className={css`
          display: flex;
          height: 100%;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        `}
      >
        <span
          className={css`
            margin-bottom: 1.25rem;
            font-size: 3.75rem;
            font-weight: normal;
          `}
        >
          {strings.emoji.robot}
        </span>
        <span
          className={css`
            margin-bottom: 1rem;
            font-weight: normal;
          `}
        >
          {strings.errorBoundry.evenRobotsMakeMistakes}
        </span>
        <span
          className={css`
            margin-bottom: 1.25rem;
            color: #67728b;
            font-size: 1rem;
            font-weight: normal;
          `}
        >
          {strings.errorBoundry.pleaseTryAgain}
        </span>
        <Button
          className={css`
            min-height: 2.375rem;
            padding: 0rem;
            :hover {
              opacity: 0.8;
            }
          `}
          color='ghost'
          onClick={(): void => {
            browserHistory.push('/');
            window.location.reload();
          }}
        >
          {strings.errorBoundry.retry}
        </Button>
      </div>
    ) : (
      children
    );
  }
}
