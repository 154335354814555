import { useMediaQuery } from '@material-ui/core';
import { unwrapResult } from '@reduxjs/toolkit';
import { breakpoints } from '@taraai/design-system/dist/core/breakpoints';
import { Data } from '@taraai/types';
import { actions, Step } from 'components/app/Onboarding/FlowWrapper';
import { linkTo } from 'components/Router/paths';
import dashify from 'dashify';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  checkOrganizationExists,
  connectToOrganization,
  finishOnboarding,
  selectAuth,
  updateUser,
  useAppDispatch,
} from 'reduxStore';
import { organizationIdValidator, organizationNameValidator, useFormInput } from 'tools';
import { guessTimezone } from 'tools/utils/timezones';

import { Form } from './Form';

export const WorkspaceStep: Step = ({ children }) => {
  const isDesktop = useMediaQuery(breakpoints.desktop);
  const history = useHistory();
  const [slug, setSlug] = React.useState('');
  const { uid: profileId } = useSelector(selectAuth);
  const dispatch = useAppDispatch();

  const checkOrgExists = async (orgId: Data.Id.OrganizationId): Promise<boolean> => {
    return dispatch(checkOrganizationExists({ orgId })).then(unwrapResult);
  };

  const [organizationNameInputValue, organizationNameInputProps, isOrganizationNameValid] = useFormInput('', {
    debounceValidation: true,
    initialValid: false,
    validator: organizationNameValidator,
  });

  const [organizationUrlInputValue, organizationUrlInputProps, isOrganizationUrlValid, setUrl] = useFormInput('', {
    debounceValidation: true,
    initialValid: false,
    validator: organizationIdValidator(checkOrgExists),
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    dispatch(actions.setOrgName(organizationNameInputValue));
  }, [dispatch, organizationNameInputValue]);

  useEffect(() => {
    setSlug(organizationUrlInputValue);
  }, [organizationUrlInputValue, slug]);

  const isFormValid = isOrganizationNameValid && isOrganizationUrlValid;

  const onNext = useCallback(async () => {
    setIsSubmitting(true);
    await dispatch(
      connectToOrganization({
        type: 'CreateNewOrganization',
        id: slug,
        name: organizationNameInputValue,
        timezone: guessTimezone(),
        /**
         * NOTE: Temporary attribute to distinguish current onboarding process from 2.0 version.
         * Remove together with 2.0 global release.
         */
        newOnboarding: true,
      }),
    ).then(unwrapResult);

    await dispatch(
      updateUser({
        id: profileId,
      }),
    ).then(unwrapResult);

    await dispatch(finishOnboarding({ orgId: slug }));

    const teamName = `Team ${organizationNameInputValue.split(' ')[0]}`;
    const dashedTeamId = dashify(teamName).replace(/[^\dA-Za-z-]/g, '');

    const FIRST_TASK_PARAMS = '?type=task&active=1';
    const sprintPage = linkTo(
      'sprints',
      {
        orgId: slug,
        teamId: dashedTeamId,
      },
      FIRST_TASK_PARAMS,
    );

    const mobileNotePage = linkTo('onboardingMobileNote', {
      orgId: slug,
    });

    history.push(isDesktop ? sprintPage : mobileNotePage);

    setIsSubmitting(false);
  }, [dispatch, slug, organizationNameInputValue, profileId, history, isDesktop]);

  return children({
    childProps: {
      isFormValid,
      isSubmitting,
      orgName: organizationNameInputValue,
      organizationNameInputProps,
      organizationUrlInputProps,
      slug,
      setUrl,
    },
    Form,
    onNext,
  });
};
