import { Data } from '@taraai/types';
import { ImportBacklogIndicatorController } from 'components/app/controllers/ImportBacklogIndicatorController';
import RequirementsListElement from 'components/app/controllers/views/RequirementsListElement';
import { FastSmallSpinner } from 'components/core/controllers/views/Spinners';
import { css } from 'emotion';
import React from 'react';
import { Trello } from 'resources/assets';
import { sprintBacklogButtons } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';

export function TrelloIcon({ size = '2rem', padding = '0rem' }: { size?: string; padding?: string }): JSX.Element {
  return (
    <img
      alt=''
      className={css`
        width: ${size};
        height: ${size};
        padding: ${padding};
        box-sizing: content-box;
      `}
      data-cy={sprintBacklogButtons.TRELLO}
      src={Trello}
    />
  );
}

function TrelloImportedTasksIndicator({
  taskCount,
  onClick,
}: {
  taskCount: number;
  onClick: () => void;
}): JSX.Element | null {
  if (taskCount === 0) {
    return null;
  }

  return (
    <RequirementsListElement
      description={strings.formatString(strings.importIndicatorInBacklog.taskCount, { taskCount })}
      icon={(): JSX.Element => <TrelloIcon />}
      label={strings.importIndicatorInBacklog.importedFromTrello}
      onClick={onClick}
    />
  );
}

function TrelloImportInProgressIndicator({
  importDoc,
  onClick,
}: {
  importDoc: Data.Import;
  onClick: () => void;
}): JSX.Element {
  const tasksProgress = `${importDoc.processedItemsCount}/${importDoc.totalItemsCount}`;
  const description = strings.formatString(strings.importIndicatorInBacklog.importingTasks, { tasksProgress });
  return (
    <RequirementsListElement
      description={description}
      icon={(): JSX.Element => <TrelloIcon />}
      label={strings.importIndicatorInBacklog.importedFromTrello}
      onClick={onClick}
      rightIcon={<FastSmallSpinner />}
    />
  );
}

export function TrelloImportBacklogIndicatorController({
  showImportedTasks,
}: {
  showImportedTasks: () => void;
}): JSX.Element {
  return (
    <ImportBacklogIndicatorController
      importInProgressIndicator={TrelloImportInProgressIndicator}
      service='trello'
      showImportedTasks={showImportedTasks}
      tasksImportedIndicator={TrelloImportedTasksIndicator}
    />
  );
}
