import styled from '@emotion/styled';
import { UI } from '@taraai/types';
import Button from 'components/core/controllers/views/Button';
import Icon from 'components/core/controllers/views/Icon';
import Logo from 'components/core/controllers/views/Logo';
import React from 'react';

type TeamFragment = Pick<UI.UITeam, 'name'>;
type TeamOptionProps = {
  team: TeamFragment;
  isActive: boolean;
  isSelected: boolean;
};

export function TeamOption({ team, isSelected, isActive }: TeamOptionProps): JSX.Element {
  return (
    <Container isActive={isActive} name={team.name}>
      <Logo name={team.name} shape='circle' size='medium' />
      <Title isActive={isActive}>{team.name}</Title>
      {isSelected && <Icon name='tick' />}
    </Container>
  );
}

type ActiveProps = {
  isActive: boolean;
};

const Container = styled(({ isActive, ...props }) => <Button color='ghost' size='compact' {...props} />)`
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  outline: none;
  border: none;
  background-color: ${({ isActive }: ActiveProps): string => (isActive ? '#fbfbfd' : 'white')};
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #fbfbfd;
    outline: none;
    border: none;
  }

  &:focus {
    outline: none;
    border: none;
  }
`;

const Title = styled.div`
  color: #303f4b;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0.5rem;
  transition: opacity 0.2s ease-in-out;
  flex-wrap: wrap;

  &:hover {
    opacity: ${({ isActive }: ActiveProps): number => (isActive ? 0.7 : 1)};
  }
`;
