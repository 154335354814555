import Input from 'components/core/controllers/views/Input';
import React, { useCallback } from 'react';
import { dropDownTestIds } from 'resources/cypress/testAttributesValues';

export type SearchProps = {
  className?: string;
  placeholder?: string;
  inputRef: React.RefObject<HTMLInputElement>;
  value?: string;
  onChange: (value: string) => void;
  onKeyDown: (event: React.KeyboardEvent) => void;
};

export default function Search({
  inputRef,
  className,
  placeholder,
  onChange,
  onKeyDown,
  value,
}: SearchProps): JSX.Element {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value);
    },
    [onChange],
  );
  return (
    <Input
      Ref={inputRef}
      className={className}
      data-cy={dropDownTestIds.TEXT_INPUT}
      onChange={handleChange}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      type='text'
      value={value}
    />
  );
}
