import { css, cx } from 'emotion';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import { atomic } from 'resources';

/**
 * Variants h1, h2, h3, h4, body, label, placeholder, paragraph
 * Sizes:done
 * Weights:done
 * Letter Spacing:
 * Decoration:
 * Style:
 * href => false, relative, absolute
 * parse href and see if it is relative or absolute
 * relative: /playground - on click - history.push
 * absolute: https://google.com - target blank, wrap it in an a tag
 */

// eslint-disable-next-line sonarjs/cognitive-complexity
export default function Text({
  color: exactColor = atomic.theme.color.inherit,
  /* eslint-disable id-length */
  h1 = false,
  h2 = false,
  h3 = false,
  h4 = false,
  h5 = false,
  a = false,
  p = false,
  /* eslint-enable id-length */
  body = false,
  placeholder = false,
  paragraph = false,
  href = '',
  fontSize: exactSize = atomic.fonts.size.default,
  fontWeight: exactWeight = atomic.fonts.weight.default,
  textDecoration: exactTextDecoration = atomic.fonts.decoration.none,
  children,
  primary = false,
  secondary = false,
  accept = false,
  error = false,
  grey = false,
  black = false,
  white = false,
  overline = false,
  underline = false,
  navigate = '',
  className = '',
  style: { main = {}, link: linkStyle = {} } = {},
  ...props
} = {}) {
  const history = useHistory();

  const navigationMap = {
    push: () => history.push(href),
    pop: () => history.go(-1),
    replace: () => history.replace(href),
    forward: () => history.go(1),
    back: () => history.go(-1),
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const pattern = /^https?:\/\//i;
  const sizes = atomic.fonts.size;
  const weights = atomic.fonts.weight;
  const variants = [h1, h2, h3, h4, h5, placeholder || body || paragraph || p || a];
  const variantMapping = ['h1', 'h2', 'h3', 'h4', 'h5', 'p'][variants.indexOf(true)];
  const size = variants.includes(true)
    ? [sizes.h1, sizes.h2, sizes.h3, sizes.h4, sizes.h5, sizes.placeholder, sizes.p][variants.indexOf(true)]
    : exactSize;
  const weight = variants.includes(true)
    ? [weights.bold, weights.bold, weights.bold, weights.bold, weights.regular, weights.regular, weights.regular][
        variants.indexOf(true)
      ]
    : exactWeight;
  const colorArr = [primary, secondary, accept, error, grey, black, white];
  const color = colorArr.includes(true)
    ? [
        atomic.theme.color.primary,
        atomic.colors.secondary,
        atomic.theme.color.success,
        atomic.theme.color.error,
        atomic.theme.color.grey,
        atomic.theme.color.black,
        atomic.theme.color.white,
      ][colorArr.indexOf(true)]
    : exactColor;
  const textDecor = [overline, underline];
  const decorationArr = {
    Overline: atomic.fonts.decoration.overline,
    Underline: atomic.fonts.decoration.underline,
    key(index) {
      return this[Object.keys(this)[index]];
    },
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const decoration = [];
  textDecor.forEach((val, index) => {
    if (val) {
      decoration.push(decorationArr.key(index));
    }
  });
  if (href.trim().length !== 0) {
    decoration.push(decorationArr.key(1));
  }
  if (decoration === []) {
    decoration.push(exactTextDecoration);
  }
  const navigation = navigationMap[navigate];
  const Component = variantMapping || 'span';
  const TextMemoized = useMemo(
    () => (
      <Component
        {...props}
        className={cx(
          css`
            ${atomic.margin(0)};
            ${atomic.color(color)};
            ${atomic.fontSize(size)};
            ${atomic.fontWeight(weight)};
            ${atomic.textDecoration(...decoration)};
          `,
          className,
          main,
        )}
      >
        {children}
      </Component>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [children, className, color, decoration, main, props, size, weight],
  );
  return useMemo(
    () =>
      href.trim().length !== 0 ? (
        <a
          className={cx(
            css`
              ${atomic.color(color)};
              ${atomic.textDecoration(...decoration)};
              ${atomic.transition()}
              &:hover {
                ${atomic.color(color)};
                ${atomic.textDecoration(...decoration)};
              }
            `,
            linkStyle,
          )}
          href={href}
          onClick={pattern.test(href) ? () => false : () => navigation(href)}
          rel='noreferrer'
          target={pattern.test(href) ? '_blank' : '_self'}
        >
          {TextMemoized}
        </a>
      ) : (
        TextMemoized
      ),
    [href, color, decoration, linkStyle, pattern, TextMemoized, navigation],
  );
}
