import { Data, Timestamp } from '@taraai/types';
import { firestore } from 'tools/libraries/firebaseValues';

type SubscriptionItem = {
  plan: {
    interval: string;
  };
};

export type SubscriptionDocument = {
  quantity: number;
  role: string;
  items: SubscriptionItem[];
  current_period_end: Timestamp;
};

export async function sendToStripCustomerPortal(organizationId: Data.Id.OrganizationId): Promise<void> {
  const functionRef = firestore().app.functions().httpsCallable('stripe-createPortalLink');
  const { data } = await functionRef({ returnUrl: window.location.origin, organizationId });
  window.location.assign(data.url);
}

export async function getSubscriptionDetails(organizationId: Data.Id.OrganizationId): Promise<SubscriptionDocument> {
  const subscriptionsSnapshot = await firestore()
    .collection('customers')
    .doc(organizationId)
    .collection('subscriptions')
    .where('status', 'in', ['trialing', 'active'])
    .get();

  const subscriptionsData = subscriptionsSnapshot.docs[0].data();

  if (subscriptionsSnapshot.docs[0].exists) return subscriptionsData as SubscriptionDocument;

  throw new Error('Failed to fetch subscription data');
}
