import { forEachTextMatch } from 'components/editor/entities';
import { taskNumberRegExp } from 'components/editor/plugins/utils';
import { DraftDecorator } from 'components/editor/types';

import TaskSelector from './TaskSelector';

export const taskNumberDecorator: DraftDecorator = {
  strategy: (block, callback) => {
    forEachTextMatch(taskNumberRegExp, block, callback);
  },
  component: TaskSelector,
};
