import InstallIntegrationForm from 'components/app/controllers/views/InstallIntegrationForm';
import Button from 'components/core/controllers/views/Button';
import Icon from 'components/core/controllers/views/Icon';
import SpinnerButton from 'components/core/controllers/views/SpinnerButton';
import { css, cx } from 'emotion';
import React from 'react';
import { IconName } from 'resources';
import { integrationTestIds } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';
import { atomic } from 'resources/theme';
import { formatI18n } from 'tools/libraries/helpers/formatI18n';
import { ConnectAccountGenericData } from 'types/connect-account';

interface ConnectButtonProps {
  label: string;
  name: string;
  icon?: IconName;
  disabled?: boolean;
}

const ConnectButton = ({ label, icon, disabled, name }: ConnectButtonProps): JSX.Element => (
  <Button
    className={css`
      padding: 0.25rem 1rem;
      margin-right: ${disabled ? '0.5rem' : '0rem'};
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;
    `}
    color='ghost'
    disabled={disabled}
    id='installIntegrationFormChild'
    name={name}
  >
    {icon ? (
      <Icon
        className={css`
          width: 1rem;
          height: 1rem;
          padding: 0;
          margin-right: 0.5rem;
          vertical-align: sub;
        `}
        color='black'
        name={icon}
      />
    ) : null}
    {label}
  </Button>
);

export interface ConnectExternalAccountProps extends React.HTMLProps<HTMLDivElement> {
  connectData: ConnectAccountGenericData;
  label: string;
  icon?: IconName;
  connectedLabel: string;
  service: 'github' | 'slack' | 'gitlab';
  onProfileDisconnect?: (service: 'github' | 'slack' | 'gitlab') => Promise<void>;
}

interface ChooseBtnToRenderProps {
  isComingSoon?: boolean;
  isConnected: boolean;
  comingSoonBtn: JSX.Element;
  connectBtn: JSX.Element;
  connectedStateBtn: JSX.Element;
}
const chooseBtnToRender = ({
  isComingSoon,
  isConnected,
  comingSoonBtn,
  connectBtn,
  connectedStateBtn,
}: ChooseBtnToRenderProps): JSX.Element => {
  if (isComingSoon) {
    return comingSoonBtn;
  }

  if (isConnected) {
    return connectedStateBtn;
  }

  return connectBtn;
};

export default function ConnectExternalAccount({
  className,
  connectData,
  label,
  icon,
  connectedLabel,
  service,
  onProfileDisconnect,
}: ConnectExternalAccountProps): JSX.Element {
  const accounts = connectData.accounts.map((user) => `@${user}`).join(' ');
  const connectBtn = (
    <InstallIntegrationForm installationData={connectData} method='post'>
      <ConnectButton icon={icon} label={label} name={label} />
    </InstallIntegrationForm>
  );

  const comingSoonBtn = <ConnectButton disabled icon={icon} label={strings.integrations.comingSoon} name={label} />;

  const connectedStateBtn = <ConnectButton disabled icon={icon} label={connectedLabel} name={label} />;

  const isConnected = connectData.accounts.length > 0;

  const buttonToDisplay = chooseBtnToRender({
    isComingSoon: !connectData.isFeatureEnabled,
    isConnected,
    connectBtn,
    comingSoonBtn,
    connectedStateBtn,
  });

  return (
    <div
      className={cx(
        css`
          display: flex;
          flex-direction: row;
          align-items: center;
        `,
        className,
      )}
    >
      {isConnected && (
        <>
          <Icon
            className={css`
              width: 0.875rem;
              height: 0.625rem;
              padding: 0 0 0 0.5rem;
              color: ${atomic.get(atomic.colors.success)};
            `}
            name='tick'
          />
          <div
            className={css`
              font-size: 0.875rem;
              color: ${atomic.get(atomic.colors.success)};
              padding: 0 0.5rem;
            `}
          >
            {accounts}
          </div>
        </>
      )}
      {buttonToDisplay}
      {isConnected && onProfileDisconnect && (
        <SpinnerButton
          color='ghost'
          dataCy={formatI18n(integrationTestIds.PROFILE_DISCONNECT)({
            service,
          })}
          onClick={(): Promise<void> => onProfileDisconnect(service)}
        >
          {strings.integrations.disconnect}
        </SpinnerButton>
      )}
    </div>
  );
}
