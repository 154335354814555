import { Box, styled } from '@taraai/design-system';
import SubtaskController, { TaskFragment } from 'components/app/controllers/SubtaskController';
import DraggableList from 'components/core/controllers/views/DraggableList';
import { useTaskTitleConfig } from 'components/editor/config';
import { RichEditor, RichEditorHandle } from 'components/editor/RichEditor';
import { RichEditorProvider } from 'components/editor/RichEditorProvider';
import React from 'react';
import { strings } from 'resources/i18n';

export interface SubtasksProps extends React.HTMLProps<HTMLDivElement> {
  subtasks: TaskFragment[];
  addTaskRef: React.MutableRefObject<RichEditorHandle | null>;
  handleEnter: (content: string) => void;
  onSubtaskListReorder: (newList: TaskFragment[]) => void;
  showSubtaskInput: boolean;
}

/**
 * Subtasks
 * view for list of subtasks with create subtask input
 *
 */
export default function Subtasks({
  subtasks,
  addTaskRef,
  handleEnter,
  showSubtaskInput,
  onSubtaskListReorder,
}: SubtasksProps): JSX.Element {
  const config = useTaskTitleConfig({ singleLine: true });
  return (
    <Box spaceTop='$8px'>
      {subtasks.length > 0 && (
        <Box spaceTop='$8px'>
          <DraggableList
            items={subtasks}
            listElement={({ value, innerRef }): JSX.Element => (
              <SubtaskController key={value.id} dragRef={innerRef} task={value} />
            )}
            onChange={onSubtaskListReorder}
            placeholder={({ show }): JSX.Element => <Placeholder show={show} />}
            type='subtasks'
          />
        </Box>
      )}
      {(subtasks.length > 0 || showSubtaskInput) && (
        <InputWrapperBox borderRadius='$2px' space='$8px'>
          <RichEditorProvider config={config} initialValue='' onSave={handleEnter} saveStrategy='saveOnReturn'>
            <RichEditor ref={addTaskRef} placeholder={strings.task.addASubTask} />
          </RichEditorProvider>
        </InputWrapperBox>
      )}
    </Box>
  );
}

const Placeholder = styled(
  'div',
  {
    borderRadius: '3px',
    marginBottom: '0.5rem',
    transition: 'height 0.2s ease',
    backgroundColor: '$grey3',
  },
  {
    show: {
      true: { height: '42px' },
      false: { height: '0px' },
    },
  },
);

const InputWrapperBox = styled(Box, {
  '.DraftEditor-root': {
    color: '$dark',
    fontSize: '0.875rem',
  },
  ':focus-within': {
    boxShadow: 'inset 0 0 0 1px colors.$grey4',
  },
});
