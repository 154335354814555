/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable id-length */
import { Box, tokens } from '@taraai/design-system';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { Bar, BarGroup } from '@visx/shape';
import { Text as VText } from '@visx/text';
import { BarStackVerticalProps } from 'components/app/GitInsights/_types';
import { backgroundTypes, GraphColors } from 'components/app/GitInsights/Shared/constants';
import { CapitalizeString } from 'components/app/GitInsights/Shared/utils';
import React from 'react';

import { SVGBackgroundPatterns } from './SVGBackgroundPatterns';

const defaultMargin = { top: 0, left: 20, right: 0, bottom: 20 };

export function GroupVerticalStacked({
  data,
  dataKeys,
  width,
  height,
  margin = defaultMargin,
  getAxisBottomInfo,
  barHeight,
  showAxisBottomInfo = true,
  showAxisLeftInfo = true,
  leftAxiosLabel,
  showValues = false,
  showBarLabels = true,
  showGridLines,
}: BarStackVerticalProps): JSX.Element | null {
  // bounds
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  const keys = dataKeys;

  // scales
  const bottomScale = scaleBand<string>({
    domain: data.map(getAxisBottomInfo),
    padding: 0.2,
  });

  const barsScale = scaleBand<string>({
    domain: keys,
    padding: 0.1,
  });

  const leftScale = scaleLinear<number>({
    domain: [0, Math.max(...data.map((d) => Math.max(...keys.map((key) => Number(d[key])))))],
  });

  const colorScale = scaleOrdinal<string, string>({
    domain: keys,
    range: [],
  });

  // update scale output dimensions
  bottomScale.rangeRound([0, xMax]);
  barsScale.rangeRound([0, bottomScale.bandwidth()]);
  leftScale.range([yMax, 0]);

  return width < 10 ? null : (
    <Box>
      <svg height={height + 35} width={width}>
        {SVGBackgroundPatterns({ dataLength: data.length })}
        <rect fill='transparent' height={height} rx={14} width={width} x={0} y={0} />
        <Group left={leftAxiosLabel ? 20 : 0} top={margin.top + 10}>
          {showGridLines && (
            <GridRows
              height={yMax}
              left={margin.left}
              numTicks={3}
              scale={leftScale}
              stroke={tokens.colors.$secondaryText}
              strokeOpacity={0.1}
              top={margin.top}
              width={xMax}
            />
          )}

          <BarGroup
            color={colorScale}
            data={data}
            height={yMax}
            keys={keys}
            x0={getAxisBottomInfo}
            x0Scale={bottomScale}
            x1Scale={barsScale}
            yScale={leftScale}
          >
            {(barGroups) =>
              barGroups.map((barGroup) => (
                <Group key={`bar-group-${barGroup.index}-${barGroup.x0}`} left={barGroup.x0}>
                  {barGroup.bars.map((bar) => (
                    <>
                      <Group key={`bar-stack-${barGroup.index}-${bar.index}`}>
                        <Bar
                          fill={
                            backgroundTypes[bar.index] === 'solid'
                              ? GraphColors[barGroup.index]
                              : `url(#background-${backgroundTypes[bar.index]}-${GraphColors[barGroup.index]})`
                          }
                          height={barHeight || bar.height}
                          width={bar.width}
                          x={bar.x}
                          y={bar.y}
                        />
                        {showBarLabels && (
                          <VText
                            color='#616379'
                            dx={bar.width / 2 - 10}
                            dy={bar.height + 5}
                            fontSize='10px'
                            fontWeight={100}
                            stroke='#616379'
                            textAnchor='start'
                            verticalAnchor='start'
                            x={bar.x}
                            y={bar.y}
                          >
                            {keys[bar.index]}
                          </VText>
                        )}
                        {showValues && (
                          <VText
                            dx={bar.width / 2}
                            dy={bar.height / 2}
                            fill='white'
                            fontSize='12px'
                            stroke='white'
                            textAnchor='middle'
                            verticalAnchor='middle'
                            width={bar.width}
                            x={bar.x}
                            y={bar.y}
                          >
                            {bar.value === 0 ? '' : bar.value}
                          </VText>
                        )}
                      </Group>
                    </>
                  ))}
                </Group>
              ))
            }
          </BarGroup>

          {showAxisLeftInfo && (
            <AxisLeft
              hideAxisLine
              hideTicks
              label={leftAxiosLabel}
              labelProps={{ fill: tokens.colors.$secondaryText, fontSize: '11px', dx: '1.2em' }}
              left={20}
              numTicks={3}
              scale={leftScale}
              tickLabelProps={() => ({
                fill: tokens.colors.$secondaryText,
                fontSize: 11,
                textAnchor: 'middle',
                dy: '0.21em',
              })}
            />
          )}

          {showAxisBottomInfo && (
            <AxisBottom
              hideAxisLine
              hideTicks
              scale={bottomScale}
              tickFormat={CapitalizeString}
              tickLabelProps={() => ({
                fill: tokens.colors.$background,
                fontSize: 12,
                fontWeight: 500,
                textAnchor: 'middle',
              })}
              tickStroke={tokens.colors.$secondaryText}
              top={yMax + margin.top + 20}
            />
          )}
        </Group>
      </svg>
    </Box>
  );
}
