import { css } from 'emotion';
import React from 'react';
import { useDrop } from 'react-dnd';

type DropzoneProps = {
  width: number;
  height: number;
  top?: number;
  left?: number;
  placeholder: (show: boolean) => JSX.Element;
  acceptType: string;
  onElementDropped: (elementId: string) => void;
};

export const Dropzone: React.FC<DropzoneProps> = ({
  height,
  width,
  top = 0,
  left = 0,
  placeholder,
  acceptType,
  onElementDropped,
}) => {
  const [{ isHovered }, dropzoneRef] = useDrop<{ id: string; type: string }, unknown, { isHovered: boolean }>({
    accept: acceptType,
    collect: (monitor) => ({
      isHovered: monitor.isOver(),
    }),
    drop: (element) => {
      onElementDropped(element.id);
    },
  });

  return (
    <>
      <div>{placeholder(isHovered)}</div>
      <div
        ref={dropzoneRef}
        className={css`
          position: absolute;
          width: 100%;
          height: ${height}px;
          width: ${width}px;
          top: ${top}px;
          left: ${left}px;
        `}
        data-testid='dropzone'
      />
    </>
  );
};
