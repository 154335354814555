import SprintDetailsController from 'components/app/controllers/SprintDetailsController';
import React from 'react';
import { markPage, Marks } from 'tools/utils/perfmarks';

/**
 * SprintDetails does…
 * Page component for the Sprint View. Renders all components relevant to specific sprint details.
 */
export default function SprintDetails(): JSX.Element {
  markPage(Marks.PageSprintDetails);
  return <SprintDetailsController />;
}
