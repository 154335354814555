import { createAsyncThunk } from '@reduxjs/toolkit';
import Tara, { Data, StatusHistory, UI } from '@taraai/types';
import { EffortUnit } from '@taraai/types/dist/data';
import { isNonEmptyString, notNull } from '@taraai/utility';
import { RootState } from 'reduxStore/store';
import { CREATION_DEFAULTS, decode } from 'reduxStore/utils/decoders';
import { ExtraAPI, Write } from 'reduxStore/utils/types';
import { insertNear } from 'tools';

type UserOrg = {
  id: string;
  effortUnit: EffortUnit;
  nextTaskSlug: number;
  path: string;
};

interface CreateTaskPayload
  extends Partial<Omit<UI.UITask, '_relationships' | 'updatedAt' | 'createdAt' | 'gitStatus'>> {
  orgId?: Data.Id.OrganizationId;
  _relationships?: Partial<Pick<Data.TaskRelationships, 'requirement' | 'parent'>>;
  insertAtIndex?: number;
  taskIds?: Data.Id.TaskId[];
}

export const createTask = createAsyncThunk('CreateTask', async (payload: CreateTaskPayload, { extra, getState }) => {
  try {
    const { getOrgId, getUserId, getFirestore } = extra as ExtraAPI;
    const state = getState() as RootState;
    const userId = getUserId(state);
    const orgId = 'orgId' in payload ? payload?.orgId : getOrgId();
    const {
      title,
      assignee,
      collaborators,
      labels = [],
      _relationships,
      sprint = null,
      insertAtIndex,
      taskIds,
      effortLevel,
      description,
    } = payload;
    const firestore = getFirestore();
    const cachedTaskId = state.firestore.cache.database.orgs[orgId].nextTaskSlug.toString();
    const requirementDoc = _relationships?.requirement;
    const requirementCreation = requirementDoc && typeof insertAtIndex !== 'undefined';
    const sprintCreation = sprint && insertAtIndex;

    if (!isNonEmptyString(orgId)) throw new Error('Missing orgId');

    const updateOrg = ({ userOrg }: { userOrg: UserOrg }): Write => ({
      id: userOrg.id,
      path: userOrg.path,
      nextTaskSlug: userOrg.nextTaskSlug + 1,
    });

    const updateTask = ({ userOrg }: { userOrg: UserOrg }): Write => {
      const { nextTaskSlug, effortUnit } = userOrg;
      const id = String(nextTaskSlug);
      const decodedRelationships = decode<Tara.Data.TaskRelationships>(
        { parent: _relationships?.parent || null, requirement: _relationships?.requirement || null },
        'Relationships',
        CREATION_DEFAULTS,
      );
      const statusHistory = [
        decode<StatusHistory>(
          {
            lastUpdatedBy: userId,
            updatedAt: firestore.Timestamp.now(),
          },
          'StatusHistory',
          CREATION_DEFAULTS,
        ),
      ];
      const taskShell = decode<UI.UITaskCreateChangeset>(
        {
          id,
          author: userId,
          assignee,
          collaborators,
          updatedAt: ['::serverTimestamp'],
          createdAt: ['::serverTimestamp'],
          lastUpdateVia: 'tara',
          title,
          sprint,
          updatedBy: userId,
          _relationships: decodedRelationships,
          statusHistory,
          labels,
          effortLevel,
          description,
        },
        'UITaskCreateChangeset',
        CREATION_DEFAULTS,
      );
      return {
        ...taskShell,
        effortUnit,
        id,
        path: `orgs/${orgId}/tasks`,
      };
    };

    const updateRequirement =
      requirementCreation && requirementDoc
        ? ({ userOrg }: { userOrg: UserOrg }) => {
            const { nextTaskSlug } = userOrg;
            const result = insertNear(String(nextTaskSlug), insertAtIndex ?? 0, taskIds);
            return {
              id: requirementDoc,
              path: `orgs/${orgId}/requirements`,
              orderedTaskIds: result,
            };
          }
        : null;

    const updateSprint =
      sprintCreation && sprint
        ? ({ userOrg }: { userOrg: UserOrg }) => {
            const { nextTaskSlug } = userOrg;
            const result = insertNear(String(nextTaskSlug), insertAtIndex ?? 0, taskIds);
            return {
              id: sprint,
              path: `orgs/${orgId}/sprints`,
              orderedTaskIds: result,
            };
          }
        : null;

    const reads = {
      userOrg: { path: 'orgs', id: orgId },
    };

    const writes = [updateOrg, updateTask, updateRequirement, updateSprint].filter(notNull);

    firestore.mutate({ reads, writes });
    return cachedTaskId;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
});
