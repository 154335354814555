import { Data } from '@taraai/types';
import { useCallback, useState } from 'react';

import { EffortTogglerProps } from './EffortToggler';

type Props = {
  initialEffortUnit: Data.EffortUnit;
  onChange?: (color: Data.EffortUnit) => void;
};

/*
 * Hook to use with EffortToggler component
 */
export function useEffortToggler({ initialEffortUnit, onChange }: Props): EffortTogglerProps {
  const [effortUnit, setEffortUnit] = useState<Data.EffortUnit>(initialEffortUnit);

  const onChangeHandler = useCallback(
    (newEffortUnit: Data.EffortUnit): void => {
      setEffortUnit(newEffortUnit);
      return onChange?.(newEffortUnit);
    },
    [onChange],
  );
  return { effortUnit, onChange: onChangeHandler };
}
