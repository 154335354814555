import { Data, UI } from '@taraai/types';
import { isNonEmptyString } from '@taraai/utility';
import { compose } from 'redux';
import { createIndividualSelector, inertQuery, Query, ReduxFirestoreQuery } from 'reduxStore/utils';
import { sort } from 'tools/libraries/helpers/sort';

export function getSprintSummary(orgId: Data.Id.OrganizationId, archived = false): Query<UI.SprintSummaryOption> {
  if (!isNonEmptyString(orgId)) {
    return inertQuery();
  }
  const storeAs = `orgs/${orgId}/summaries/sprints`;
  const query: ReduxFirestoreQuery = {
    collection: `orgs/${orgId}/summaries`,
    doc: 'sprints',
    storeAs,
  };

  return {
    query: [query],
    selector: compose(
      (data: UI.SummarySprint) => filterSprintSummaries(data, archived),
      createIndividualSelector(query),
    ),
  };
}

export const filterSprintSummaries = (doc: UI.SummarySprint, archived: boolean): UI.SprintSummaryOption[] => {
  const summaryData = Object.keys(doc?.summaries || {})
    .filter((id: string) => {
      const data: UI.SprintSummary = doc?.summaries[id];
      return data.deleted === false && data.isComplete === false && data.archived === archived;
    })
    .map((sprintId: string) => {
      const data: UI.SprintSummary = doc?.summaries[sprintId];
      return { id: sprintId, sprintName: data.sprintName, teamId: data.teamId };
    });
  return sort(summaryData, 'sprintName');
};
