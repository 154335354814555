import { MaybeReusableLink } from 'components/app/controllers/InviteUsersController';
import InviteUsersPopup, { Props as InviteUsersPopupProps } from 'components/app/controllers/views/InviteUsersPopup';
import TagInput from 'components/core/controllers/views/TagInput';
import React from 'react';
import { strings } from 'resources/i18n';
import { Validator } from 'tools/helpers/validators/inviteUserValidators';

type Props = {
  emailInputValue: string;
  emailList: string[];
  errorsPresent: boolean;
  maybeReusableLink?: MaybeReusableLink;
  validators: Validator[];
  handleTagInputChange: (newTags: string[]) => void;
  setEmailInputValue: (value: string) => void;
} & Omit<InviteUsersPopupProps, 'emailInput' | 'messageInput' | 'teamLabel' | 'teamSelector' | 'windowTitle'>;

export const InviteUsers = ({
  emailInputValue,
  emailList,
  errorsPresent,
  validators,
  handleTagInputChange,
  setEmailInputValue,
  ...props
}: Props): JSX.Element => (
  <InviteUsersPopup
    emailInput={
      <TagInput
        errorsPresent={errorsPresent}
        innerInputValue={emailInputValue}
        onInnerInputChange={setEmailInputValue}
        onTagsChange={handleTagInputChange}
        placeholder={strings.inviteUserPopup.email.placeholder}
        tags={emailList}
        transform={(tag: string): string => tag.trim()}
        validators={validators}
      />
    }
    errorsPresent={errorsPresent}
    windowTitle={strings.inviteUserPopup.title}
    {...props}
  />
);
