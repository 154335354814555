import { forEachEntityRange } from 'components/editor/entities';
import { DraftDecorator } from 'components/editor/types';
import { ContentBlock, ContentState } from 'draft-js';

import { Image } from './Image';

export const imageDecorator: DraftDecorator = {
  strategy: imageStrategy,
  component: Image,
};

export function imageStrategy(
  block: ContentBlock,
  callback: (start: number, end: number) => void,
  content: ContentState,
): void {
  forEachEntityRange('IMAGE', content, block, callback);
}
