import React from 'react';

export const VerticalDots = (
  <svg fill='none' height='10' viewBox='0 0 3 10' width='3' xmlns='http://www.w3.org/2000/svg'>
    <path
      clipRule='evenodd'
      d='M0.737488 1.00034C0.737488 0.448135 1.18522 0.000488281 1.73749 0.000488281C2.28976 0.000488281 2.73749 0.448135 2.73749 1.00034C2.73749 1.55254 2.28976 2.00019 1.73749 2.00019C1.18522 2.00019 0.737488 1.55254 0.737488 1.00034ZM0.737488 4.99972C0.737488 4.44753 1.18521 3.99987 1.73749 3.99987C2.28976 3.99987 2.73749 4.44753 2.73749 4.99972C2.73749 5.55191 2.28976 5.99957 1.73749 5.99957C1.18521 5.99957 0.737488 5.55191 0.737488 4.99972ZM0.737488 8.99912C0.737488 8.44693 1.18521 7.99927 1.73749 7.99927C2.28976 7.99927 2.73749 8.44693 2.73749 8.99912C2.73749 9.55131 2.28976 9.99897 1.73749 9.99897C1.18521 9.99897 0.737488 9.55131 0.737488 8.99912Z'
      fill='#525965'
      fillRule='evenodd'
    />
  </svg>
);
