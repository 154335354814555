import { Identifiable } from '@taraai/types';
import React from 'react';

import { Index, SectionType } from './types';

const KEYS = {
  ESCAPE: 27,
  ARROW_UP: 38,
  ARROW_DOWN: 40,
  ENTER: 13,
};

type SelectorHandlers<E> = {
  closePopup: () => void;
  setActiveOptionIndex: (setFn: (activeIndex: Index | null) => Index | null) => void;
  handleOptionSelection: (element: E) => (event: React.SyntheticEvent) => void;
};

type KeyFnHandler<Section extends SectionType<Identifiable>> = ({
  event,
  sections,
  activeOptionIndex,
}: {
  event: React.KeyboardEvent;
  sections: Section[];
  activeOptionIndex: Index | null;
}) => void;

type KeyFnHandlers<Section extends SectionType<Identifiable>> = {
  [keyCode: number]: KeyFnHandler<Section>;
};

function getPreviousIndex<Section extends SectionType<Identifiable>>(
  activeIndex: Index | null,
  sections: Section[],
): Index {
  if (!activeIndex || (activeIndex.sectionIndex === 0 && activeIndex.optionIndex === 0)) {
    // return last element
    const sectionIndex = sections.length - 1;
    return {
      sectionIndex,
      optionIndex: sections[sectionIndex].options.length - 1,
    };
  }
  // const activeSection = sections[activeIndex.sectionIndex];
  if (activeIndex.optionIndex === 0) {
    // return last element in previous section
    const sectionIndex = activeIndex.sectionIndex - 1;
    return {
      sectionIndex,
      optionIndex: sections[sectionIndex].options.length - 1,
    };
  }
  // return previous element in same section
  return {
    sectionIndex: activeIndex.sectionIndex,
    optionIndex: activeIndex.optionIndex - 1,
  };
}

function getNextIndex<Section extends SectionType<Identifiable>>(
  activeIndex: Index | null,
  sections: Section[],
): Index {
  if (
    !activeIndex ||
    (activeIndex.sectionIndex === sections.length - 1 &&
      activeIndex.optionIndex === sections[activeIndex.sectionIndex].options.length - 1)
  ) {
    // return first element
    return {
      sectionIndex: 0,
      optionIndex: 0,
    };
  }
  if (activeIndex.optionIndex === sections[activeIndex.sectionIndex].options.length - 1) {
    // return first element in next section
    const sectionIndex = activeIndex.sectionIndex + 1;
    return {
      sectionIndex,
      optionIndex: 0,
    };
  }
  // return next element in same section
  return {
    sectionIndex: activeIndex.sectionIndex,
    optionIndex: activeIndex.optionIndex + 1,
  };
}

/**
 * createKeyFnMap - creates a map for Selector component, binding keyboard keys with handlers
 */
export function createKeyFnMap<Section extends SectionType<Option>, Option extends Identifiable>({
  closePopup,
  setActiveOptionIndex,
  handleOptionSelection,
}: SelectorHandlers<Option>): KeyFnHandlers<Section> {
  return {
    [KEYS.ESCAPE]: ({ event }): void => {
      event.preventDefault();
      closePopup();
    },
    [KEYS.ARROW_UP]: ({ event, sections }): void => {
      event.preventDefault();
      setActiveOptionIndex((activeIndex) => getPreviousIndex(activeIndex, sections));
    },
    [KEYS.ARROW_DOWN]: ({ event, sections }): void => {
      event.preventDefault();
      setActiveOptionIndex((activeIndex) => getNextIndex(activeIndex, sections));
    },
    [KEYS.ENTER]: ({ event, sections, activeOptionIndex }): void => {
      event.preventDefault();
      if (!activeOptionIndex) {
        return;
      }
      const { optionIndex, sectionIndex } = activeOptionIndex;
      const activeSection = sections[sectionIndex];
      const activeOption = activeSection.options[optionIndex];
      handleOptionSelection(activeOption)(event);
    },
  };
}
