import { unwrapResult } from '@reduxjs/toolkit';
import ImportModalView from 'components/app/controllers/views/ImportModalView';
import Icon from 'components/core/controllers/views/Icon';
import { linkTo } from 'components/Router/paths';
import { css } from 'emotion';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState, selectActiveTeam, selectActiveWorkspace, selectPreferredTeamId, useAppDispatch } from 'reduxStore';
import {
  closeImportModal,
  showBacklogImportIndicator,
  showImportNotification,
  uploadImportFile,
} from 'reduxStore/imports/actions';
import { TrelloImport } from 'resources';
import { strings } from 'resources/i18n';
import { ASANA_EXPORT_INSTRUCTION_URL, TRELLO_EXPORT_INSTRUCTION_URL } from 'tools/libraries';

function ImportModalController(): JSX.Element | null {
  const dispatch = useAppDispatch();
  const showModalForService = useSelector((state: RootState) => state.imports.showModalForService);

  const [isUploading, setIsUploading] = useState(false);

  const orgId = useSelector(selectActiveWorkspace);
  const teamId = useSelector(selectActiveTeam);

  const history = useHistory();
  const preferredTeamId = useSelector(selectPreferredTeamId(orgId));

  const handleImportRequest = useCallback(
    async (file: File) => {
      history.push(linkTo('sprints', { orgId, teamId: teamId ?? preferredTeamId }));

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const service = showModalForService!;

      setIsUploading(true);

      const result = await dispatch(
        uploadImportFile({
          service,
          file,
        }),
      );

      const importId = unwrapResult(result);

      setIsUploading(false);

      if (importId === null) {
        return;
      }

      dispatch(showImportNotification({ importId, service }));
      dispatch(showBacklogImportIndicator({ importId, service }));
      dispatch(closeImportModal());
    },
    [dispatch, history, orgId, teamId, preferredTeamId, showModalForService],
  );

  const onCloseRequest = useCallback(() => {
    dispatch(closeImportModal());
  }, [dispatch]);

  switch (showModalForService) {
    case 'trello':
      return (
        <ImportModalView
          description={strings.importModal.trello.description}
          exportInstructionUrlHref={TRELLO_EXPORT_INSTRUCTION_URL}
          exportInstructionUrlLabel={strings.importModal.trello.exportInstructionUrlLabel}
          isUploading={isUploading}
          onCloseRequest={onCloseRequest}
          onImportRequest={handleImportRequest}
          serviceIcon={
            <img
              alt=''
              className={css`
                width: 8.376rem;
                height: 4.5rem;
              `}
              src={TrelloImport}
            />
          }
          title={strings.importModal.trello.title}
        />
      );
    case 'asana':
      return (
        <ImportModalView
          description={strings.importModal.asana.description}
          exportInstructionUrlHref={ASANA_EXPORT_INSTRUCTION_URL}
          exportInstructionUrlLabel={strings.importModal.asana.exportInstructionUrlLabel}
          isUploading={isUploading}
          onCloseRequest={onCloseRequest}
          onImportRequest={handleImportRequest}
          serviceIcon={
            <Icon
              className={css`
                width: 8.376rem;
                height: 4.5rem;
              `}
              name='asana'
            />
          }
          title={strings.importModal.asana.title}
        />
      );
    default:
      return null;
  }
}

export default ImportModalController;
