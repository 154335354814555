import HomeController from 'components/app/controllers/HomeController';
import React from 'react';

/**
 * Home is the dashboard page for the application
 *
 */
export default function Home(): JSX.Element {
  return <HomeController />;
}
