import { OrderByOptions, WhereOptions } from '@taraai/read-write';
import { UISprint } from '@taraai/types/dist/ui';
import { createStandardSelector, inertQuery, Query } from 'reduxStore/utils/selectors';
import { ReduxFirestoreQuery } from 'reduxStore/utils/types';

type OrderBy = keyof Pick<UISprint, 'createdAt' | 'sprintNumber' | 'sprintName'> | 'sprintNumberDesc';

/**
 * Returns not completed sprints
 * @param orgId
 * @param teamId - when not provided returns all not completed sprints in organization
 * @param options
 */
export function getUpcomingSprints(
  orgId?: string,
  teamId?: string,
  options?: { orderBy?: OrderBy; limit?: number },
): Query<UISprint> {
  if (orgId === '' || teamId === '') {
    return inertQuery();
  }

  let orderBy: OrderByOptions;
  switch (options?.orderBy ?? 'sprintNumber') {
    case 'sprintNumber':
      orderBy = ['sprintNumber', 'asc'];
      break;
    case 'sprintNumberDesc':
      orderBy = ['sprintNumber', 'desc'];
      break;
    case 'createdAt':
      orderBy = ['createdAt', 'asc'];
      break;
    case 'sprintName':
      orderBy = ['sprintName', 'asc'];
      break;
  }

  const teamCondition: WhereOptions[] = teamId ? [['teamId', '==', teamId]] : [];
  const query: ReduxFirestoreQuery = {
    collection: `orgs/${orgId}/sprints`,
    where: [...teamCondition, ['isComplete', '==', false], ['archived', '==', false], ['deleted', '==', false]],
    orderBy,
    storeAs: `sprints/${orgId}/${teamId}/getUpcoming`,
    ...(options?.limit !== undefined ? { limit: options?.limit } : {}),
  };

  return {
    query: [query],
    selector: createStandardSelector<UISprint>(query),
  };
}
