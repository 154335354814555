import { Box, Fluid, HStack } from '@taraai/design-system';
import { TokenProp } from '@taraai/design-system/dist/core';
import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  color: TokenProp<'colors'>;
};
export const Indicator = ({ children, color }: Props): JSX.Element => (
  <HStack>
    <Box borderLeft={[color, '$2px']} borderLeftColor={color} width='$12px' />
    <Fluid>
      <Box>{children}</Box>
    </Fluid>
  </HStack>
);
