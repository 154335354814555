"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.payloadDecoder = void 0;
var ts_data_json_1 = require("ts.data.json");
exports.payloadDecoder = ts_data_json_1.JsonDecoder.object({
    organizationId: ts_data_json_1.JsonDecoder.string,
    teamId: ts_data_json_1.JsonDecoder.string,
    emails: ts_data_json_1.JsonDecoder.array(ts_data_json_1.JsonDecoder.string, 'string[]'),
    message: ts_data_json_1.JsonDecoder.string,
}, 'InviteUsers.Payload');
