import { styled } from '@taraai/design-system';
import React from 'react';
import { Col, Container, Row } from 'react-grid-system';

import { isValidData } from './utils';

type LoadingControllerProps = {
  dataToWatch: unknown;
  children: React.ReactElement;
};

export const LoadingImage = (): JSX.Element => (
  <svg fill='none' height='392' viewBox='0 0 684 392' width='684' xmlns='http://www.w3.org/2000/svg'>
    <animate attributeName='opacity' begin='0.1' dur='1s' repeatCount='indefinite' values='0;1;0' />
    <rect fill='#1F2134' height='392' rx='4' width='684' />
    <rect fill='#303351' height='29' rx='4' width='228' x='24' y='32' />
    <rect fill='#303351' height='16' rx='4' width='160' x='500' y='38.5' />
    <path d='M169 272V158' stroke='#67728B' strokeDasharray='3 3' />
    <path d='M302 264V138' stroke='#67728B' strokeDasharray='3 3' />
    <path
      d='M106 138H111L111 233.5C111 234.881 109.881 236 108.5 236C107.119 236 106 234.881 106 233.5L106 138Z'
      fill='url(#paint0_linear_794_23430)'
    />
    <path
      d='M238 314H233V227.5C233 226.119 234.119 225 235.5 225C236.881 225 238 226.119 238 227.5V314Z'
      fill='url(#paint1_linear_794_23430)'
    />
    <path d='M445 272V158' stroke='#67728B' strokeDasharray='3 3' />
    <path d='M578 264V138' stroke='#67728B' strokeDasharray='3 3' />
    <path
      d='M382 138H387V233.5C387 234.881 385.881 236 384.5 236C383.119 236 382 234.881 382 233.5V138Z'
      fill='url(#paint2_linear_794_23430)'
    />
    <path
      d='M514 314H509V227.5C509 226.119 510.119 225 511.5 225C512.881 225 514 226.119 514 227.5V314Z'
      fill='url(#paint3_linear_794_23430)'
    />
    <rect height='391' rx='3.5' stroke='white' strokeOpacity='0.15' width='683' x='0.5' y='0.5' />
    <defs>
      <linearGradient
        gradientUnits='userSpaceOnUse'
        id='paint0_linear_794_23430'
        x1='106'
        x2='106'
        y1='239.419'
        y2='133.442'
      >
        <stop stopColor='#4D5BFF' />
        <stop offset='1' stopColor='#4D5BFF' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        gradientUnits='userSpaceOnUse'
        id='paint1_linear_794_23430'
        x1='238'
        x2='238'
        y1='221.895'
        y2='318.14'
      >
        <stop stopColor='#4D5BFF' />
        <stop offset='1' stopColor='#4D5BFF' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        gradientUnits='userSpaceOnUse'
        id='paint2_linear_794_23430'
        x1='382'
        x2='382'
        y1='239.419'
        y2='133.442'
      >
        <stop stopColor='#4D5BFF' />
        <stop offset='1' stopColor='#4D5BFF' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        gradientUnits='userSpaceOnUse'
        id='paint3_linear_794_23430'
        x1='514'
        x2='514'
        y1='221.895'
        y2='318.14'
      >
        <stop stopColor='#4D5BFF' />
        <stop offset='1' stopColor='#4D5BFF' stopOpacity='0' />
      </linearGradient>
    </defs>
  </svg>
);

export const LoadingController = ({ dataToWatch, children }: LoadingControllerProps): JSX.Element => {
  return !isValidData(dataToWatch) ? (
    <Container>
      <GridRow>
        <Col lg={6} xl={6} xxl={6}>
          <LoadingImage />
        </Col>

        <Col lg={6} xl={6} xxl={6}>
          <LoadingImage />
        </Col>
      </GridRow>

      <GridRow>
        <Col lg={6} xl={6} xxl={6}>
          <LoadingImage />
        </Col>
        <Col lg={6} xl={6} xxl={6}>
          <LoadingImage />
        </Col>
      </GridRow>
    </Container>
  ) : (
    children
  );
};

const GridRow = styled(Row, {
  marginBottom: '$24px',
});
