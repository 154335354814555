import { Data, UI } from '@taraai/types';
import { ErrorObject } from 'ajv';
import { decode } from 'reduxStore/utils/decoders';
import { strings } from 'resources/i18n';
import { ValidationResult } from 'tools/utils/hooks/commonTypes';

export const organizationIdValidator =
  (checkOrganizationExists: (organizationId: string) => Promise<boolean>) =>
  async (organizationId: string): Promise<ValidationResult> => {
    try {
      decode<UI.UIOrganizationPartial>({ id: organizationId }, 'UIOrganizationPartial');
    } catch (err) {
      const { errors } = err as { errors: ErrorObject[] };
      const errorType = errors[0]?.keyword;
      const message =
        errorType === 'minLength'
          ? strings.createWorkspace.emptyWorkspaceUrl
          : strings.createWorkspace.invalidWorkspaceUrl;

      return {
        isValid: false,
        message,
      };
    }

    const isOrganizationNameTaken = await checkOrganizationExists(organizationId);
    return isOrganizationNameTaken
      ? {
          isValid: false,
          message: strings.createWorkspace.workspaceUrlTaken,
        }
      : {
          isValid: true,
          message: strings.formatString(strings.createWorkspace.availableWorkspaceUrl, {
            organizationId,
          }) as string,
        };
  };

export const organizationNameValidator = async (name: string): Promise<ValidationResult> => {
  try {
    decode<Data.OrganizationPartial>({ name }, 'OrganizationPartial');
    return {
      isValid: true,
      message: '',
    };
  } catch (error) {
    return {
      isValid: false,
      message: strings.createWorkspace.emptyWorkspaceName,
    };
  }
};
