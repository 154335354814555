import { createSelector } from '@reduxjs/toolkit';
import { Data } from '@taraai/types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { selectHasPreferredTeam, selectIsProfileActiveIn } from 'reduxStore';

/**
 * Returns orgId from the URL only if org has finished loading properly,
 * otherwise it's undefined.
 */
export const useLoadedOrgId = (): Data.Id.OrganizationId | undefined => {
  const { orgId } = useParams<{ orgId?: Data.Id.OrganizationId }>();
  return useSelector(
    createSelector(
      [selectIsProfileActiveIn(orgId), selectHasPreferredTeam(orgId)],
      (isProfileActive, hasPreferredTeamId) => (isProfileActive && hasPreferredTeamId ? orgId : undefined),
    ),
  );
};
