/* eslint-disable sonarjs/no-identical-functions */
import { unwrapResult } from '@reduxjs/toolkit';
import { UI } from '@taraai/types';
import AvatarPicker from 'components/core/controllers/views/AvatarPicker';
import { css } from 'emotion';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectActiveUsers, selectActiveWorkspace, selectAuth, selectUser, useAppDispatch } from 'reduxStore';
import { updateTask } from 'reduxStore/tasks/actions/update';
import { taskModalTestIds } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';

type TaskFragment = Pick<UI.UITask, 'id' | 'assignee'>;

export interface SetTaskAsigneeProps {
  task: TaskFragment;
  Ref?: React.RefObject<HTMLInputElement>;
  onError: () => void;
  dataCy?: string;
}

/** Manages UI for assigning user to a task */
export default function SetTaskAsignee({ task, Ref, onError, dataCy }: SetTaskAsigneeProps): JSX.Element {
  const dispatch = useAppDispatch();
  const orgId = useSelector(selectActiveWorkspace);
  const { uid: profileId } = useSelector(selectAuth);
  const assignee = useSelector(selectUser(orgId, task.assignee ?? ''));

  // show only active users plus assignee, or all users if there's no access level info available

  const users = useSelector(selectActiveUsers(orgId));

  /** Assign user to the task by id */
  const setAssignee = useCallback(
    (user) => {
      dispatch(updateTask({ id: task?.id, assignee: user }))
        .then(unwrapResult)
        .catch(onError);
    },
    [dispatch, task, onError],
  );

  /** Remove the current assignee */
  const removeAssignee = useCallback(() => {
    dispatch(updateTask({ id: task?.id, assignee: null }))
      .then(unwrapResult)
      .catch(onError);
  }, [dispatch, task, onError]);

  return (
    <div
      className={css`
        display: flex;
      `}
      data-cy={taskModalTestIds.TASK_MODAL_ROW.ASSIGN_TO_SELF}
    >
      {!assignee && (
        <div
          className={css`
            color: #1d98ff;
            align-items: center;
            margin: auto 1rem auto 0;
            font-size: 0.875rem;
            font-weight: 500;
          `}
          data-cy={taskModalTestIds.TASK_MODAL_ROW.ASSIGN_TO_SELF}
          onClick={(event: React.SyntheticEvent): void => {
            event.preventDefault();
            event.stopPropagation();
            setAssignee(profileId);
          }}
          onKeyDown={(event: React.SyntheticEvent): void => {
            event.preventDefault();
            event.stopPropagation();
            setAssignee(profileId);
          }}
          role='button'
          tabIndex={-1}
        >
          {strings.tasks.assignSelf}
        </div>
      )}
      <AvatarPicker
        Ref={Ref}
        dataCy={dataCy}
        maxAllowed={1}
        onAddUser={setAssignee}
        onRemoveUser={removeAssignee}
        pickerStyle={css`
          width: 2rem;
          height: 2rem;
          padding: 0rem;
        `}
        size='medium'
        suggestions={users}
        users={assignee ? [assignee] : undefined}
      />
    </div>
  );
}
