import { createAsyncThunk } from '@reduxjs/toolkit';
import { Functions, UI } from '@taraai/types';
import { CREATION_DEFAULTS, decode } from 'reduxStore/utils/decoders';
import { ExtraAPI } from 'reduxStore/utils/types';

export const getOrgFromInvitation = createAsyncThunk(
  'GetOrgFromInvitation',
  async ({ token }: UI.InvitationToken, { extra }): Promise<Functions.GetOrgFromInvitation.Response> => {
    const { getFirebase } = extra as ExtraAPI;

    const payloadDraft: Functions.GetOrgFromInvitation.Payload = {
      invitationToken: token,
    };

    const payload = decode<Functions.GetOrgFromInvitation.Payload>(
      payloadDraft,
      'GetOrgFromInvitationPayload',
      CREATION_DEFAULTS,
    );

    const response = await getFirebase().functions().httpsCallable('getOrgFromInvitation')(payload);

    return decode<Functions.GetOrgFromInvitation.Response>(
      response.data,
      'GetOrgFromInvitationResponse',
      CREATION_DEFAULTS,
    );
  },
);
