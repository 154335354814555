/**
 * Insert as close as possible to the desired index.
 * Also ensures that no duplicates are in the array
 * @param item - The item to insert in the array
 * @param index - The index in the visible array you'd like to insert
 * @param visibleList - The array displayed in React
 * @param entireList - The entire array from the Firestore Document
 * @returns
 */
export const insertNear = (
  item: string,
  index: number,
  visibleList?: string[] | null,
  entireList?: string[] | null,
): string[] | null => {
  if (!visibleList || index === -1) return null;

  // if visibleList != entireList then insert into entireList
  if (entireList && entireList.length !== visibleList.length) {
    return insertAfterPreviousVisibleItem(item, index, visibleList, entireList);
  }

  // is item in displayed array
  const itemIndex = visibleList.findIndex((id) => id === item);
  // if item in the list then remove
  if (itemIndex > -1) {
    visibleList.splice(itemIndex, 1);
  }
  // add item into new index
  visibleList.splice(index, 0, item);

  return visibleList;
};

const insertAfterPreviousVisibleItem = (
  item: string,
  index: number,
  visibleList: string[],
  entireList: string[],
): string[] | null => {
  const useVisible = visibleList.length > entireList.length;
  const idx = visibleList.findIndex((_item) => _item === item);
  if (idx > -1) {
    visibleList.splice(idx, 1);
  }
  visibleList.splice(index, 0, item);
  if (useVisible) return visibleList;

  // find previous item
  const priorIdx = visibleList.findIndex((_item) => _item === item) - 1;
  const priorId = visibleList[priorIdx];

  // if item in entireList then remove
  const indexNow = entireList.findIndex((id) => id === item);
  if (indexNow > -1) {
    entireList.splice(indexNow, 1);
  }

  // find previous item in entireList array
  const insertIdAfter = entireList.findIndex((id) => id === priorId);
  entireList.splice(insertIdAfter + 1, 0, item);

  return entireList;
};

/**
 * Gets the id of the element above where dragged element was dropped
 * if top of list returns null.
 * @param list - List of items
 * @param elementId - The id of the element
 * @param targetIdx - The index of the element
 * @returns - The id of the element before the elementId
 */
export const getInsertAfterId = ({
  list,
  elementId,
  targetIdx,
}: {
  list: (string | null)[] | null | undefined;
  elementId: string;
  targetIdx: number;
}): string | null => {
  if (!list || targetIdx === 0) return null;

  const previousIndex = list.indexOf(elementId);

  const isMovingDownList = previousIndex < targetIdx;
  const wasFromNotDifferentList = previousIndex >= 0;

  if (isMovingDownList && wasFromNotDifferentList) return list[targetIdx];

  return list[targetIdx - 1];
};

export const getIsDropInPlanned = ({
  list,
  targetIdx,
}: {
  list: (string | null)[] | null | undefined;
  targetIdx: number;
}): boolean => {
  // it can't be true if no planned section
  if (!list || list[0] !== null) return false;

  // remove nulls from beginning of list and double nulls
  const listWithoutPrecedingNulls = list.reduce((prev: (string | null)[], current: string | null) => {
    if (current !== null || (prev.length > 0 && prev[prev.length - 1] !== null)) {
      prev.push(current);
    }
    return prev;
  }, []) as (string | null)[];

  // get index of second as it must be the end of carried over section
  const indexOfNull = listWithoutPrecedingNulls.indexOf(null);

  return targetIdx > indexOfNull;
};
