import { css } from 'emotion';
import React from 'react';
import { strings } from 'resources/i18n';

/**
 * PullRequestHeader
 * Header
 *
 */
export default function PullRequestHeader(): JSX.Element {
  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: baseline;
        padding-top: 2rem;
      `}
    >
      <div
        className={css`
          font-size: 1rem;
          font-weight: 600;
          color: #124151;
          padding-bottom: 1rem;
        `}
      >
        {strings.dashboard.pullRequest.title}
      </div>
    </div>
  );
}
