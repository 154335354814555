import { Data, UI } from '@taraai/types';
import GitHubFallback from 'components/app/controllers/views/GitHubFallback';
import PullRequestListItem from 'components/app/controllers/views/PullRequestListItem';
import ListView from 'components/core/layouts/ListView';
import { css, cx } from 'emotion';
import React, { useCallback, useState } from 'react';
import { IconName } from 'resources';
import { strings } from 'resources/i18n';
import { atomic } from 'resources/theme';
import { sort } from 'tools/libraries/helpers/sort';
import { ConnectGitHubAccountData } from 'types/connect-account';
import { InstallGitHubData } from 'types/install-integration';

export interface PullRequestsProps extends React.HTMLProps<HTMLDivElement> {
  /**
   * Array of abstract Tara Pull Request objects (supporting all platforms)
   */
  pullRequests?: UI.UIPullRequest[];
  installGitHubData: InstallGitHubData;
  connectGitHubAccountData?: ConnectGitHubAccountData;
  orgId: Data.Id.OrganizationId;
  icon: IconName;
  title: string;
  details?: string;
  showDetailsButton?: boolean;
  showSorting?: boolean;
  headingStyle?: string;
  pullRequestTableStyle?: string;
}

const arrowUp = css`
  position: relative;
  width: 0;
  height: 0;
  border-left: 0.25rem solid transparent;
  border-right: 0.25rem solid transparent;
  border-bottom: 0.25rem solid #67728b;
  bottom: 0.5rem;
  margin-left: 0.5rem;
`;

const arrowDown = css`
  position: relative;
  width: 0;
  height: 0;
  border-left: 0.25rem solid transparent;
  border-right: 0.25rem solid transparent;
  border-top: 0.25rem solid #67728b;
  top: 0.5rem;
  margin-left: 0.5rem;
`;

/**
 * A view of the open Pull Requests
 *
 *  - [Designs](https://zpl.io/adYY8Xe)
 */
function PullRequests({
  className,
  pullRequests,
  installGitHubData,
  connectGitHubAccountData,
  orgId,
  icon,
  title,
  details,
  showDetailsButton,
  showSorting = true,
  headingStyle,
  pullRequestTableStyle,
  ...props
}: PullRequestsProps): JSX.Element {
  const [sortDirection, setSortDirection] = useState<'newest' | 'oldest'>('newest');

  const sortedPullRequests: UI.UIPullRequest[] = sort(pullRequests || [], 'createdAt', sortDirection === 'newest');

  const shouldShowPullRequests = installGitHubData.id && sortedPullRequests.length > 0;

  const toggleSortDirection = useCallback(() => {
    setSortDirection(sortDirection === 'newest' ? 'oldest' : 'newest');
  }, [sortDirection]);

  return (
    <div
      className={cx(
        css`
          padding: 1.25rem 0 1.25rem 0.625rem;
          flex-grow: 1;
          flex-shrink: 1;
        `,
        className,
      )}
    >
      <div
        className={cx(
          css`
            height: 100%;
            position: relative;
            min-height: 15.625rem;
            max-height: 28.125rem;
            border-radius: 0.1875rem;
            box-shadow: 0 0 1.25rem 0 rgba(0, 0, 0, 0.08);
            background-color: #ffffff;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
          `,
          pullRequestTableStyle,
        )}
        {...props}
      >
        <h2
          className={cx(
            css`
              font-size: 1rem;
              font-weight: 500;
              padding: 1.5rem 1rem 0.75rem;
            `,
            headingStyle,
          )}
        >
          {strings.formatString(strings.pullRequests.title, {
            openPRs: shouldShowPullRequests ? sortedPullRequests.length : 0,
          })}
        </h2>
        {shouldShowPullRequests ? (
          <>
            {showSorting && (
              <div
                className={css`
                  position: absolute;
                  font-size: 0.75rem;
                  right: 1.5rem;
                  top: 1.75rem;
                  color: #67728b;
                  outline: none;
                  cursor: pointer;
                  :hover {
                    opacity: 0.7;
                    transition: 0.3s all 0s ease;
                  }
                  display: ${sortedPullRequests.length ? 'block' : 'none'};
                `}
                onClick={toggleSortDirection}
                onKeyDown={toggleSortDirection}
                role='button'
                tabIndex={0}
              >
                {strings.pullRequests[sortDirection]}
                <span className={sortDirection ? arrowDown : arrowUp} />
              </div>
            )}
            <div
              className={css`
                border-bottom: 0.0625rem solid ${atomic.get(atomic.colors.grey3)};
                width: 100%;
              `}
            />
            <div
              className={css`
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                color: ${atomic.get(atomic.colors.grey6)};
                font-size: 0.75rem;
                font-weight: 500;
                padding: 1rem 1rem 0.25rem;
              `}
            >
              <p>{strings.pullRequests.pullRequest}</p>
              <p>{strings.pullRequests.openedSince}</p>
            </div>
            <ListView
              childrenClassName={css`
                padding: 0.25rem 0;
              `}
              className={css`
                padding: 0 1rem 0.75rem;
              `}
            >
              {sortedPullRequests.map((pullRequest: UI.UIPullRequest) => (
                <PullRequestListItem
                  key={pullRequest.id}
                  pullRequest={pullRequest}
                  showDetailsButton={showDetailsButton}
                />
              ))}
            </ListView>
          </>
        ) : (
          <GitHubFallback
            connectGitHubAccountData={connectGitHubAccountData}
            details={details}
            icon={icon}
            iconClassName={css`
              color: black;
              width: 2rem;
              height: 2.5rem;
            `}
            installGitHubData={installGitHubData}
            organizationId={orgId}
            title={title}
          />
        )}
      </div>
    </div>
  );
}

export default PullRequests;
