import { Box } from '@taraai/design-system';
import { useFirestoreConnect } from '@taraai/read-write';
import { Data } from '@taraai/types';
import { SprintDateModule } from 'components/core/controllers/views/SprintDateModule';
import React from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import { getSprint, selectActiveWorkspace } from 'reduxStore';

type Props = {
  id: Data.Id.SprintId;
};

export const SprintStartEndDates = ({ id }: Props): JSX.Element | null => {
  const orgId = useSelector(selectActiveWorkspace);
  const sprintSlice = getSprint(orgId, id);
  const sprintFragment = useSelector(
    compose((sprint) => sprint && { endDate: sprint.endDate, startDate: sprint.startDate }, sprintSlice.selector),
    deepEquals,
  );

  useFirestoreConnect(sprintSlice.query);

  if (!sprintFragment) {
    return null;
  }
  return (
    <Box space='$24px'>
      <SprintDateModule endDate={sprintFragment.startDate} startDate={sprintFragment.endDate} />
    </Box>
  );
};
