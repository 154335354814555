"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unwrap = exports.decode = exports.getDecoder = exports.StatusDecoder = exports.ErrorResultDecoder = void 0;
var ts_data_json_1 = require("ts.data.json");
exports.ErrorResultDecoder = ts_data_json_1.JsonDecoder.object({
    status: ts_data_json_1.JsonDecoder.isExactly('error'),
    errors: ts_data_json_1.JsonDecoder.array(ts_data_json_1.JsonDecoder.object({
        field: ts_data_json_1.JsonDecoder.string,
        error: ts_data_json_1.JsonDecoder.string,
    }, 'ErrorObject'), 'ErrorArray'),
}, 'ErrorResult');
exports.StatusDecoder = ts_data_json_1.JsonDecoder.oneOf([ts_data_json_1.JsonDecoder.isExactly('ok'), ts_data_json_1.JsonDecoder.isExactly('error')], 'Status');
function getDecoder(decoder) {
    return ts_data_json_1.JsonDecoder.object({ status: exports.StatusDecoder }, 'WithStatus').then(function (_a) {
        var status = _a.status;
        if (status === 'ok') {
            return ts_data_json_1.JsonDecoder.object({
                status: ts_data_json_1.JsonDecoder.isExactly('ok'),
                value: decoder,
            }, 'OkResult');
        }
        if (status === 'error') {
            return exports.ErrorResultDecoder;
        }
        return ts_data_json_1.JsonDecoder.fail("Unknown status ".concat(status));
    });
}
exports.getDecoder = getDecoder;
function decode(data, decoder) {
    var result = getDecoder(decoder).decode(data);
    if (result.isOk()) {
        return result.value;
    }
    throw new Error(result.error);
}
exports.decode = decode;
function unwrap(result) {
    if (result.status === 'error') {
        throw new Error(result.errors.map(function (error) { return error.error; }).join(' '));
    }
    return result.value;
}
exports.unwrap = unwrap;
