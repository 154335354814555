"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTimeSegmentName = exports.getStartDate = exports.getPreviousTimeSegmentIds = exports.getCurrentTimeSegmentId = exports.getPreviousQuarterIDs = exports.getCurrentQuarterID = exports.getPerviousMonthsIDs = exports.getCurrentMonthID = exports.getPreviousWeekID = exports.getCurrentWeekID = void 0;
var luxon_1 = require("luxon");
var DEFAULT_ZONE = 'UTC';
function parseDateTime(date) {
    return luxon_1.DateTime.fromJSDate(date, { zone: DEFAULT_ZONE });
}
/**
 * Get the current week id in the format of YYYY-WW
 * YYYY is the ISO week-numbering year
 * WW is the ISO week number
 * Examples:
 * - January 1st 2023 is in week 52 of 2022
 * - January 2nd 2023 is in week 1 of 2023
 * See: https://www.epochconverter.com/weeks/2023 for more details
 * @param date Date to get the week id for
 * @returns    The week id in the format of YYYY-WW
 */
var getCurrentWeekID = function (date) {
    var targetDate = parseDateTime(date);
    return "".concat(targetDate.weekYear, "-").concat(targetDate.weekNumber);
};
exports.getCurrentWeekID = getCurrentWeekID;
/**
 * Get the previous week id in the format of YYYY-WW
 * YYYY is the ISO week-numbering year
 * WW is the ISO week number
 * Examples:
 * - The previous week of January 1st 2023 is in week 51 of 2022 (because January 1st 2023 is in week 52 of 2022)
 * - The previous week of January 2nd 2023 is in week 52 of 2022 (because January 2nd 2023 is in week 1 of 2023)
 * @param date Date to get the previous week id for
 * @returns    The previous week id in the format of YYYY-WW
 */
var getPreviousWeekID = function (date) {
    var targetDate = parseDateTime(date);
    // Subtract 1 week from the target date
    var previousWeek = targetDate.minus({ weeks: 1 });
    // Get the week id for the new date
    return (0, exports.getCurrentWeekID)(previousWeek.toJSDate());
};
exports.getPreviousWeekID = getPreviousWeekID;
/**
 * Get the current month id in the format of YYYY-MM
 * YYYY is the year
 * MM is the month
 * Examples:
 * - January 1st 2023 is in month 1 of 2023, so it should return 2023-1
 * @param date Date to get the month id for
 * @returns   The month id in the format of YYYY-MM
 */
var getCurrentMonthID = function (date) {
    var targetDate = parseDateTime(date);
    return "".concat(targetDate.year, "-").concat(targetDate.month);
};
exports.getCurrentMonthID = getCurrentMonthID;
/**
 * Get the two months before the current month id in the format of YYYY-MM
 * YYYY is the year
 * MM is the month
 * Examples:
 * - January 1st 2023 is month 1 of 2023, so it should return [2022-11, 2022-12]
 * - March 1st 2023 is month 3 of 2023, so it should return [2023-1, 2023-2]
 * @param date Date to get the previous months id for
 * @returns    The previous months id in the format of YYYY-MM
 */
var getPerviousMonthsIDs = function (date) {
    var targetDate = parseDateTime(date);
    var previousMonth = targetDate.minus({ months: 1 });
    var previousMonth2 = previousMonth.minus({ months: 1 });
    return [previousMonth2, previousMonth].map(function (month) {
        return "".concat(month.year, "-").concat(month.month);
    });
};
exports.getPerviousMonthsIDs = getPerviousMonthsIDs;
/**
 * Returns the current quarter id in the format of YYYY-Q
 * YYYY is the year
 * Q is the quarter
 * Examples:
 * - January 1st 2023 is in quarter 1 of 2023, so it should return 2023-1
 * - April 1st 2023 is in quarter 2 of 2023, so it should return 2023-2
 * @param date Date to get the quarter id for
 * @returns    The quarter id in the format of YYYY-Q
 */
var getCurrentQuarterID = function (date) {
    var targetDate = parseDateTime(date);
    return "".concat(targetDate.year, "-").concat(targetDate.quarter);
};
exports.getCurrentQuarterID = getCurrentQuarterID;
/**
 * Returns the previous three quarter ids in the format of [YYYY-Q, YYYY-Q, YYYY-Q], where the first element is the oldest quarter
 * YYYY is the year
 * Q is the quarter
 * Examples:
 * - December 1st 2022 is in quarter 4 of 2022, so it should return [2022-1, 2022-2, 2022-3]
 * - January 1st 2023 is in quarter 1 of 2023, so it should return [2022-2, 2022-3, 2022-4]
 */
var getPreviousQuarterIDs = function (date) {
    var targetDate = parseDateTime(date);
    var previousQuarter = targetDate.minus({ months: 3 });
    var previousQuarter2 = previousQuarter.minus({ months: 3 });
    var previousQuarter3 = previousQuarter2.minus({ months: 3 });
    return [previousQuarter3, previousQuarter2, previousQuarter].map(function (quarter) {
        return "".concat(quarter.year, "-").concat(quarter.quarter);
    });
};
exports.getPreviousQuarterIDs = getPreviousQuarterIDs;
/* istanbul ignore next */
var getCurrentTimeSegmentId = function (timeSegment) {
    var currentDate = new Date();
    switch (timeSegment) {
        case 'weekly':
            return (0, exports.getCurrentWeekID)(currentDate);
        case 'monthly':
            return (0, exports.getCurrentMonthID)(currentDate);
        case 'quarterly':
            return (0, exports.getCurrentQuarterID)(currentDate);
    }
    return null;
};
exports.getCurrentTimeSegmentId = getCurrentTimeSegmentId;
/* istanbul ignore next */
var getPreviousTimeSegmentIds = function (timeSegment) {
    var currentDate = new Date();
    switch (timeSegment) {
        case 'weekly':
            return [(0, exports.getPreviousWeekID)(currentDate)];
        case 'monthly':
            return (0, exports.getPerviousMonthsIDs)(currentDate);
        case 'quarterly':
            return (0, exports.getPreviousQuarterIDs)(currentDate);
    }
    throw new Error('could not get getPreviousTimeSegmentIds');
};
exports.getPreviousTimeSegmentIds = getPreviousTimeSegmentIds;
/* istanbul ignore next */
// gets the beginning of the current time segment
var getStartDate = function (timeSegment) {
    var currentDate = new Date();
    switch (timeSegment) {
        case 'weekly':
            return parseDateTime(currentDate).startOf('week').toJSDate();
        case 'monthly':
            return parseDateTime(currentDate).startOf('month').toJSDate();
        case 'quarterly':
            return parseDateTime(currentDate).startOf('quarter').toJSDate();
        default: {
            throw new Error('getStartDate: invalid time segment');
        }
    }
};
exports.getStartDate = getStartDate;
/* istanbul ignore next */
// should return 'current' : 'previous' for weekly or the month [aug, set] name or quarter [qrt1, qrt2, qrt3] name of the given timeSegmentId
var getTimeSegmentName = function (timeSegmentId, timeSegment) {
    var currentDate = new Date();
    var currentQuarter = (0, exports.getCurrentQuarterID)(currentDate);
    var currentMonth = (0, exports.getCurrentMonthID)(currentDate);
    var currentWeek = (0, exports.getCurrentWeekID)(currentDate);
    switch (timeSegment) {
        case 'weekly':
            return timeSegmentId === currentWeek ? 'current' : 'previous';
        case 'monthly':
            return timeSegmentId === currentMonth ? 'current' : 'previous';
        case 'quarterly':
            return timeSegmentId === currentQuarter ? 'current' : 'previous';
        default:
            throw new Error('could not make time segment name');
    }
};
exports.getTimeSegmentName = getTimeSegmentName;
