import { createAsyncThunk } from '@reduxjs/toolkit';
import { Functions } from '@taraai/types';
import { ExtraAPI } from 'reduxStore/utils/types';

export type RemoveTeamChangeset = Omit<Functions.RemoveTeam.Payload, 'organizationId'>;

export const remove = createAsyncThunk('RemoveTeam', async (changeset: RemoveTeamChangeset, { extra }) => {
  const { getOrgId, getFirebase } = extra as ExtraAPI;
  const organizationId = getOrgId();

  const payload: Functions.RemoveTeam.Payload = {
    ...changeset,
    organizationId,
  };

  return getFirebase().functions().httpsCallable('removeTeam')(payload);
});
