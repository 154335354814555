import { Data } from '@taraai/types';
import { Section } from 'components/app/TaskDetails/common/Section';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectTaskValue } from 'reduxStore';
import { strings } from 'resources';

import { RequirementSelector } from './RequirementSelector';

type Props = {
  taskId: Data.Id.TaskId;
};

export function Requirement({ taskId }: Props): JSX.Element {
  const requirementsRef = useRef<HTMLInputElement>(null);

  const requirement = useSelector((state) => selectTaskValue(state, taskId, '_relationships')?.requirement ?? '');

  return (
    <Section
      right={<RequirementSelector requirementId={requirement} requirementsRef={requirementsRef} taskId={taskId} />}
      title={strings.taskSidebar.modules.requirement.title}
    />
  );
}
