/* eslint-disable sonarjs/cognitive-complexity */
import { Data } from '@taraai/types';
import { getSelectedTeamId } from 'components/app/GlobalNav/helpers';
import { GetPremiumButton } from 'components/app/monetization/GetPremiumButton';
import { Tab, Tabs } from 'components/core/controllers/views/Tabs';
import { getRouteName, linkTo } from 'components/Router/paths';
import { stringify } from 'query-string';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getOrgTeams, getSubscriptionType, hasFeature, selectActiveWorkspace, selectPreferredTeamId } from 'reduxStore';
import { AppSelectionType } from 'reduxStore/appSelection';
import { strings } from 'resources';
import { globalNavTestIds } from 'resources/cypress/testAttributesValues';
import { useQueryValue } from 'tools';
import { GetRemainingTrialDays } from 'tools/utils/getRemainingTrialDays';

type PreviousParams = { id?: string; type?: 'sprint' | 'requirement' | 'task' };

export const Navigation: React.FC = () => {
  /**
   * Do not use `usePathParams` hook. We are manipulating location also outside the main `history` object.
   * It provides to situations, when `useLocation()` shows different location than `window.location` which cause typecheck issues.
   * https://app.tara.ai/tara-ai/team-tara/tasks/4497
   */
  const { requirementId, taskId } = useParams<{
    requirementId?: Data.Id.OrganizationId;
    taskId?: Data.Id.TeamId;
  }>();

  const history = useHistory();
  const orgId = useSelector(selectActiveWorkspace);
  const preferredTeamId = useSelector(selectPreferredTeamId(orgId));
  const orgTeams = useSelector(getOrgTeams(orgId).selector, deepEquals);
  const remainingTrialDays = GetRemainingTrialDays();

  const isEnabled = useSelector(hasFeature('alphaMonetization', orgId));
  const isGitlabInsightsEnabled = useSelector(hasFeature('gitlabInsights', orgId));
  const subscriptionType = useSelector(getSubscriptionType(orgId));
  const isPremium = subscriptionType !== null;
  const showUpgradeButton = isEnabled && (!isPremium || (remainingTrialDays && remainingTrialDays > 0));

  // Team should be either the selected team (if present) or the preferred one
  const teamId = getSelectedTeamId(orgTeams || []) || preferredTeamId;

  const DEFINEPATH = linkTo('requirements', { orgId, teamId });
  const SPRINTPATH = linkTo('sprints', { orgId, teamId });
  const HOMEPATH = linkTo('home', { orgId, teamId });
  const INTEGRATIONSPATH = linkTo('integrations', { orgId, teamId });
  const PROGRESSPATH = linkTo('sprintDetails', {
    orgId,
    teamId,
    sprintId: 'current',
  });
  const INSIGHTSPATH = linkTo('gitlabInsights', {
    orgId,
    teamId,
  });

  // get query params from sprint page
  const typeFromQuery = useQueryValue('type');
  const idFromQuery = useQueryValue('active');

  const selectionType = (typeFromQuery || (requirementId ? 'requirement' : 'task')) as AppSelectionType;
  const selectionId = idFromQuery || requirementId || taskId;

  const [previousParams, setPreviousParams] = useState<PreviousParams>({});

  useEffect(() => {
    if (selectionId && selectionType && selectionId !== previousParams?.id) {
      setPreviousParams({ id: selectionId, type: selectionType });
    }
  }, [selectionId, previousParams?.id, previousParams?.type, selectionType]);

  const handleGetRoute = useCallback(
    (tab: Tab): string => {
      if (tab.route === SPRINTPATH && previousParams?.id && previousParams?.type) {
        const { type, id } = previousParams;
        const typeMap = {
          sprint: `${SPRINTPATH}?${stringify({ type: 'sprint', active: id })}`,
          requirement: `${SPRINTPATH}?${stringify({ type: 'requirement', active: id })}`,
          task: `${SPRINTPATH}?${stringify({ type: 'task', active: id })}`,
        };
        return typeMap[type] || tab.route;
      }
      if (tab.route === DEFINEPATH && previousParams?.id && previousParams?.type) {
        const { type, id } = previousParams;
        const typeMap = {
          sprint: tab.route,
          requirement: linkTo('requirement', { orgId, teamId, requirementId: id }),
          task: linkTo('task', { orgId, teamId, taskId: id }),
        };
        return typeMap[type];
      }
      return tab.route;
    },
    [SPRINTPATH, previousParams, DEFINEPATH, orgId, teamId],
  );

  const appTabs = useMemo(
    (): Tab[] => [
      {
        label: strings.navigation.home,
        route: HOMEPATH,
        icon: 'homeFull',
        dataCy: globalNavTestIds.HOME,
      },
      {
        label: strings.navigation.define,
        route: DEFINEPATH,
        dataCy: globalNavTestIds.DEFINE,
      },
      {
        label: strings.navigation.sprint,
        route: SPRINTPATH,
        dataCy: globalNavTestIds.SPRINT,
      },
      {
        label: strings.navigation.progress,
        route: PROGRESSPATH,
        dataCy: globalNavTestIds.PROGRESS,
      },
      ...(isGitlabInsightsEnabled
        ? [
            {
              label: strings.navigation.insights,
              route: INSIGHTSPATH,
              dataCy: globalNavTestIds.PROGRESS,
            },
          ]
        : []),
    ],
    [HOMEPATH, DEFINEPATH, SPRINTPATH, PROGRESSPATH, isGitlabInsightsEnabled, INSIGHTSPATH],
  );

  const gitlabTabs = useMemo(
    (): Tab[] => [
      {
        label: strings.navigation.home,
        route: INTEGRATIONSPATH,
        icon: 'homeFull',
        dataCy: globalNavTestIds.GITLAB,
      },
      {
        label: strings.navigation.insights,
        route: INSIGHTSPATH,
        dataCy: globalNavTestIds.PROGRESS,
      },
    ],
    [INTEGRATIONSPATH, INSIGHTSPATH],
  );

  const tabs = isGitlabInsightsEnabled ? gitlabTabs : appTabs;

  const [selectedTab, setSelectedTab] = useState<Tab>();
  useEffect(
    () =>
      setSelectedTab(
        tabs.find(({ route, label }) => {
          const routeName = getRouteName();
          const defineRoute = routeName === 'task' || routeName === 'requirement';
          // select define option when on requirement builder page
          const onRequirements = defineRoute && label === strings.navigation.define;
          // select sprint option when on sprints page with task sidebar enabled
          const onSprint = routeName === 'sprintsTask' && label === strings.navigation.sprint;
          const onInsights = routeName === 'insights' && label === strings.navigation.insights;

          const onPrevious = route === history.location.pathname;

          return onRequirements || onSprint || onPrevious || onInsights;
        }),
      ),
    [history.location.pathname, tabs],
  );

  const handleTabChange = useCallback(
    (tab: Tab) => {
      history.push(handleGetRoute(tab));
    },
    [handleGetRoute, history],
  );

  return (
    <>
      <Tabs onTabChange={handleTabChange} selectedTab={selectedTab} tabs={tabs} variant='globalNav' />
      {showUpgradeButton && !isGitlabInsightsEnabled && (
        <GetPremiumButton
          onClick={() => history.push(linkTo('upgrade', { orgId, teamId }))}
          remainingTrialDays={remainingTrialDays}
        />
      )}
    </>
  );
};
