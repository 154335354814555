import { css, cx, keyframes } from 'emotion';
import React from 'react';
import { atomic } from 'resources/theme';

// @TODO while developing new use cases, add more sizes
type SpinnerSize = 'small' | 'medium';

interface StandardSpinnerProps {
  size: SpinnerSize;
  backgroundColor?: string;
  className?: string;
  /** Fill empty space and center itself */
  fillSpace?: boolean;
}

const spinnerSizeToWidthMap: { [key: string]: string } = {
  small: '1.5rem',
  medium: '2.5rem',
};

const rotateKeyframes = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
`;

const StandardSpinner = ({
  size,
  backgroundColor,
  className,
  fillSpace = false,
}: StandardSpinnerProps): JSX.Element => {
  const width = spinnerSizeToWidthMap[size];

  const wrapperClassName = fillSpace
    ? css`
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      `
    : undefined;

  return (
    <div className={wrapperClassName} data-testid='spinner'>
      <div
        className={cx(
          css`
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: ${width};
            height: ${width};
          `,
          className,
        )}
      >
        <div
          className={css`
            width: ${width};
            height: ${width};
            background: #ddd;
            border-radius: 50%;
          `}
        >
          <div
            className={css`
              height: 0;
              width: 0;
              border-radius: 50%;
              border-right: calc(${width} / 2) solid ${atomic.get(atomic.colors.grey7)};
              border-top: calc(${width} / 2) solid transparent;
              border-left: calc(${width} / 2) solid transparent;
              border-bottom: calc(${width} / 2) solid transparent;

              animation: ${rotateKeyframes} 1.5s infinite linear;
            `}
          />
          <div
            className={css`
              width: 80%;
              height: 80%;
              background: ${backgroundColor ?? 'white'};
              border-radius: 50%;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            `}
          />
        </div>
      </div>
    </div>
  );
};

export default StandardSpinner;
