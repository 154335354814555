import { createBrowserHistory } from 'history';

/**
 *
 *
 * Method that adds a route to the browser URL without
 * navigating to that page.
 * Right now the "react-router-dom history", can't be used for this,
 * so we had to abstract and add the method
 *
 * @param route The _string_ route to be added to the urlHistory
 */
const pushRoute = (route: string): void => {
  window.history.pushState('', '', route);
};

export const browserHistory = Object.assign(createBrowserHistory(), { pushRoute });
