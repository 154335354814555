import { UI } from '@taraai/types';
import { parseLabelsFromPlainText, unique } from '@taraai/utility';
import { extractEffortLevel } from 'components/editor/plugins/extractEffortLevel/extractEffortLevel';
import { extractMentions } from 'components/editor/plugins/extractMentions';

type PartialTask = Partial<Omit<UI.UITask, '_relationships' | 'updatedAt' | 'createdAt' | 'gitStatus'>>;

export function extractTaskData(title: string): PartialTask {
  const partialTask = extractMentions(extractEffortLevel({ title }));
  const labels = unique(parseLabelsFromPlainText(partialTask.title));
  return { ...partialTask, labels };
}
