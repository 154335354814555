import { EditorCommand } from 'components/editor/editorCommand';
import { EditorStateOptionalTransform, EditorStateTransform, transforms } from 'components/editor/transforms';
import { RichEditorPlugin } from 'components/editor/types';
import { RichUtils } from 'draft-js';

export const handleKeyCommand: NonNullable<RichEditorPlugin['handleKeyCommand']> = (
  command,
  editorState,
  eventTimeStamp,
  pluginFunctions,
) => {
  const newState =
    customCommandHandlers[command]?.(editorState, pluginFunctions) ?? RichUtils.handleKeyCommand(editorState, command);
  if (newState) {
    pluginFunctions.setEditorState(newState);
    return 'handled';
  }
  return 'not-handled';
};

const customCommandHandlers: Partial<Record<EditorCommand, EditorStateTransform | EditorStateOptionalTransform>> = {
  'ordered-list-item': transforms.toggleOrderedList,
  'unordered-list-item': transforms.toggleUnorderedList,
  'blockquote': transforms.toggleBlockquote,
  'strikethrough': transforms.toggleStrikethrough,
  'code-block': transforms.toggleCodeBlock,
  'try-append-space': transforms.tryAppendSpace,
};
