import { useEffect } from 'react';

/**
 * Executes handler on click event outside provided element/elements
 */
export function useClickOutside(ref: React.RefObject<HTMLElement>, handler: (event: MouseEvent) => void): void;
export function useClickOutside(refs: React.RefObject<HTMLElement>[], handler: (event: MouseEvent) => void): void;
export function useClickOutside(
  refOrRefs: React.RefObject<HTMLElement>[] | React.RefObject<HTMLElement>,
  handler: (event: MouseEvent) => void,
): void {
  useEffect(() => {
    const refs = Array.isArray(refOrRefs) ? refOrRefs : [refOrRefs];
    const handleClick = (event: MouseEvent): void => {
      if (refs.every((ref) => !ref.current?.contains(event.target as Node))) {
        handler(event);
      }
    };

    document.addEventListener('mousedown', handleClick);
    return (): void => {
      document.removeEventListener('mousedown', handleClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handler]);
}
