import { TaskStatus, UI } from '@taraai/types';
import { Section } from 'components/app/TaskDetails/common/Section';
import { StatusSelector } from 'components/core/controllers/StatusSelector';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectTaskValue } from 'reduxStore';
import { strings } from 'resources';

type Props = {
  taskId: UI.UITask['id'];
};

export function Status({ taskId }: Props): JSX.Element | null {
  const status = useSelector((state) => selectTaskValue(state, taskId, 'status')) as TaskStatus;

  return status?.toString() ? (
    <Section
      right={<StatusSelector showLabel status={status} taskId={taskId} />}
      title={strings.taskSidebar.modules.status.title}
    />
  ) : null;
}
