import { styled } from '@taraai/design-system';
import { useFirestoreConnect } from '@taraai/read-write';
import { TaskSummary } from '@taraai/types/src/ui';
import { DraftDecoratorComponentProps } from 'components/editor/types';
import React from 'react';
import { useSelector } from 'react-redux';
import { getTaskSummary } from 'reduxStore/summaries/tasks';
import { useLoadedOrgId } from 'tools/utils/hooks/useLoadedOrgId';

import { TaskSelectorDropdown } from './ui/TaskSelectorDropdown';

export default function TaskSelector({ children, decoratedText }: DraftDecoratorComponentProps): JSX.Element {
  const query = decoratedText.substring(1, decoratedText.length);

  const orgId = useLoadedOrgId() || '';

  const tasksSlice = getTaskSummary(orgId, false) || [];

  useFirestoreConnect(tasksSlice.query);

  const tasks = useSelector(tasksSlice.selector);

  const filteredTasks = tasks?.filter((task: TaskSummary) => task.id.includes(query));

  const DropdownWrapper = styled('span', { position: 'relative' });

  return (
    <TaskSelectorDropdown tasks={filteredTasks}>
      <DropdownWrapper>{children}</DropdownWrapper>
    </TaskSelectorDropdown>
  );
}
