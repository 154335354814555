import { Box, mapTokens, styled, TokenProp } from '@taraai/design-system';
import Icon from 'components/core/controllers/views/Icon';
import React from 'react';
import { IconName } from 'resources';

export const CheckButton = ({ disabled, onClick }: { disabled?: boolean; onClick?: () => void }): JSX.Element => {
  return (
    <Button
      backgroundColor={disabled ? '$grey3' : '$focus'}
      disabled={disabled}
      fillColor={disabled ? '$grey3' : '$focus'}
      iconName='check2'
      onClick={onClick}
    />
  );
};

export const CrossButton = ({ onClick }: { onClick?: () => void }): JSX.Element => (
  <Button backgroundColor='$grey6' iconName='cross' onClick={onClick} />
);

export const PencilButton = ({ onClick }: { onClick?: () => void }): JSX.Element => (
  <Button hoverFillColor='$indigo' iconName='edit2' onClick={onClick} />
);

interface ButtonProps {
  backgroundColor?: TokenProp<'colors'>;
  disabled?: boolean;
  fillColor?: TokenProp<'colors'>;
  hoverFillColor?: TokenProp<'colors'>;
  iconName: IconName;
  onClick?: () => void;
}

const Button = ({
  backgroundColor,
  disabled,
  fillColor,
  hoverFillColor,
  iconName,
  onClick,
}: ButtonProps): JSX.Element => (
  <Box background={backgroundColor} borderRadius='$2px' height='$12px' width='$12px'>
    <StyledIcon
      disabled={disabled}
      fillColor={fillColor}
      hoverFillColor={hoverFillColor}
      name={iconName}
      onClick={disabled ? undefined : onClick}
    />
  </Box>
);

const StyledIcon = styled(
  Icon,
  {
    height: '100%',
    width: '100%',
    padding: 0,
  },
  {
    disabled: {
      true: { cursor: 'default' },
      false: { cursor: 'pointer' },
    },
    fillColor: mapTokens('colors', (fill) => ({ fill })),
    hoverFillColor: mapTokens('colors', (fill) => ({ ':hover': { fill } })),
  },
);
