import { unwrapResult } from '@reduxjs/toolkit';
import ProfileAvatarView from 'components/app/controllers/views/ProfileAvatarView';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectProfile, useAppDispatch } from 'reduxStore';
import { setProfilePicture } from 'reduxStore/users';
import { strings } from 'resources';
import { useToast } from 'tools';

export default function ProfileAvatarController(): JSX.Element {
  const dispatch = useAppDispatch();
  const profile = useSelector(selectProfile);

  const [uploadStatus, setUploadStatus] = useState('');
  const { addToast } = useToast();

  const handleUploadProfileImage = (file: File): void => {
    setUploadStatus(strings.profileImage.uploading);
    dispatch(setProfilePicture({ file }))
      .then(unwrapResult)
      .then(() => {
        setUploadStatus('');
        return addToast({
          message: strings.profileImage.successfullyUploaded,
          timeoutMs: 3000,
          type: 'success',
        });
      })
      .catch((error: Error) => {
        const message = strings
          .formatString(strings.profileImage.uploadError, {
            errorMessage: error.message,
          })
          .toString();
        setUploadStatus('');
        return addToast({
          message,
          timeoutMs: 5500,
          type: 'error',
        });
      });
  };

  const handleRemoveProfileImage = (): void => {
    setUploadStatus(strings.profileImage.removing);
    dispatch(setProfilePicture({ removeCurrent: true }))
      .then(unwrapResult)
      .then(() => {
        setUploadStatus('');
        return addToast({
          message: strings.profileImage.successfullyRemoved,
          timeoutMs: 3000,
          type: 'success',
        });
      })
      .catch((error: Error) => {
        setUploadStatus('');
        const message = strings
          .formatString(strings.profileImage.uploadError, {
            errorMessage: error.message,
          })
          .toString();
        return addToast({
          message,
          timeoutMs: 5500,
          type: 'error',
        });
      });
  };

  return (
    <ProfileAvatarView
      handleRemoveProfileImage={handleRemoveProfileImage}
      handleUploadProfileImage={handleUploadProfileImage}
      uploadStatus={uploadStatus}
      user={profile}
    />
  );
}
