import { unwrapResult } from '@reduxjs/toolkit';
import { styled } from '@taraai/design-system';
import { useFirestoreConnect } from '@taraai/read-write';
import { Data, UI } from '@taraai/types';
import { notUndefined } from '@taraai/utility';
import TaskStatusDropdown from 'components/app/controllers/TaskStatusDropdown';
import EmptyTableState from 'components/app/controllers/views/EmptyTableState';
import { SmartText } from 'components/app/controllers/views/SmartText';
import TaskList from 'components/app/controllers/views/TaskList';
import TasksAssignedHeader from 'components/app/controllers/views/TasksAssignedHeader';
import { TableDataEntry } from 'components/core/controllers/Table';
import AvatarPicker from 'components/core/controllers/views/AvatarPicker';
import Text from 'components/core/controllers/views/Text';
import { useTaskTitleConfig } from 'components/editor/config';
import { css } from 'emotion';
import React, { useCallback, useEffect } from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import {
  getAssignedTasksFromUserSprints,
  getCollaborativeTasksFromUserSprints,
  selectActiveUsers,
  selectAuth,
  selectUser,
  useAppDispatch,
} from 'reduxStore';
import { openTaskModal } from 'reduxStore/modals/actions';
import { updateTask } from 'reduxStore/tasks/actions/update';
import { selectUserAssignedTasksOrderedByCreatedAt } from 'reduxStore/tasks/selectors';
import { taskListAttributeTestIds } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';
import { useToast } from 'tools';
import { sort } from 'tools/libraries/helpers/sort';

// Sorting on the frontend to fix an issue with duplicate tasks showing up on staging due to TaskStatus not having the updated action for tasks
function useConvertTasksToTableData(
  tasks: UI.UITask[] | undefined,
  onClick: (event: React.SyntheticEvent) => void,
  onAddUser: (taskId: Data.Id.TaskId) => (userId: Data.Id.UserId) => void,
  onRemoveUser: (taskId: Data.Id.TaskId) => (userId: Data.Id.UserId) => void,
  team: UI.UIUser[],
  orgId: Data.Id.OrganizationId,
  currentUser?: UI.UIUser,
): TableDataEntry[] {
  const config = useTaskTitleConfig();
  return sort(tasks || [], 'status').map((task: UI.UITask) => ({
    status: (
      <div
        data-testid='task-status'
        onClick={onClick}
        onKeyDown={(event): void | false => event.keyCode === 13 && event.stopPropagation()}
        role='button'
        tabIndex={0}
      >
        <TaskStatusDropdown key={task.id} dataCy={taskListAttributeTestIds.STATUS_DROPDOWN} task={task} />
      </div>
    ),
    title: (
      <TaskTitleText h5>
        <SmartText config={config} text={task.title} />
      </TaskTitleText>
    ),
    assignee: (
      <div
        className={css`
          display: flex;
          flex-direction: row;
          align-items: center;
        `}
      >
        <AvatarPicker
          key={task.id}
          className={css`
            padding: 0.25rem 0rem;
          `}
          dataCy={taskListAttributeTestIds.ASSIGNEE_DROPDOWN}
          maxAllowed={1}
          onAddUser={onAddUser(task.id)}
          onRemoveUser={onRemoveUser(task.id)}
          size='small'
          // show only active users plus assignee, or all users if there's no access level info available
          suggestions={team.filter((user) => user.accessLevels?.[orgId] !== 'deactivated' || user.id === task.assignee)}
          users={[currentUser].filter(notUndefined)}
        />
      </div>
    ),
    id: task.id,
    task,
  }));
}

export interface DashboardTasksProps {
  // Id of currently viewed organization (aka workspace)
  orgId: Data.Id.OrganizationId;
  activeSprintIds: Data.Id.SprintId[];
}

/**
 * DashboardTasks holds logic to render the correct task list tables
 * for the current user on the home page
 *
 */
export default function DashboardTasks({ orgId, activeSprintIds: sprintIds }: DashboardTasksProps): JSX.Element {
  const dispatch = useAppDispatch();
  const team = useSelector(selectActiveUsers(orgId));
  const { uid: userId } = useSelector(selectAuth);

  const currentUser = useSelector(selectUser(orgId, userId ?? ''));

  const currentSprintCollaboratingTasks = getCollaborativeTasksFromUserSprints(orgId, userId, sprintIds, {
    orderBy: 'title',
  });

  const assignedTaskSlices = getAssignedTasksFromUserSprints(orgId, userId, sprintIds ?? [], {
    orderBy: 'title',
  });
  useFirestoreConnect(assignedTaskSlices.flatMap(({ query }) => query));

  useFirestoreConnect(currentSprintCollaboratingTasks.query);

  const sprintCollabTasks = useSelector(currentSprintCollaboratingTasks.selector) || [];
  const sprintAssignedTasks =
    useSelector(selectUserAssignedTasksOrderedByCreatedAt(assignedTaskSlices), deepEquals) || [];

  const toggleModal = useCallback(
    (taskId: Data.Id.TaskId): { payload: string; type: string } => dispatch(openTaskModal(taskId)),
    [dispatch],
  );

  // TODO: Remove the cast as UI.UITask[] whenever possible
  const assignedTasksData = sprintAssignedTasks as UI.UITask[];
  const collabTasksData = sprintCollabTasks;

  const { addToast } = useToast();

  const addAssignee = useCallback(
    (taskId) =>
      (assignee: string): void => {
        dispatch(updateTask({ id: taskId, assignee }))
          .then(unwrapResult)
          .catch(() => {
            addToast({ type: 'error', message: strings.task.failedToUpdateTask });
          });
      },
    [addToast, dispatch],
  );

  const removeAssignee = useCallback(
    (taskId) => (): void => {
      dispatch(updateTask({ id: taskId, assignee: null }))
        .then(unwrapResult)
        .catch(() => {
          addToast({ type: 'error', message: strings.task.failedToUpdateTask });
        });
    },
    [addToast, dispatch],
  );

  const colWidth: Record<string, string> = {};

  colWidth[strings.dashboard.status] = '7%';
  colWidth[strings.dashboard.title] = '90%';
  colWidth[strings.dashboard.assignee] = '3%';

  const colCount = Object.keys(colWidth).length;

  const handleClick = (event: React.SyntheticEvent): void => {
    event.stopPropagation();
  };

  const assignedTasks = useConvertTasksToTableData(
    assignedTasksData,
    handleClick,
    addAssignee,
    removeAssignee,
    team ?? [],
    orgId,
    currentUser,
  );

  const collabTasks = useConvertTasksToTableData(
    collabTasksData,
    handleClick,
    addAssignee,
    removeAssignee,
    team ?? [],
    orgId,
    currentUser,
  );

  const noAssignedTasks =
    assignedTasks.length === 0 ? <EmptyTableState colCount={colCount} noAssignedTasks orgId={orgId} /> : undefined;

  const noActiveSprint =
    sprintIds.length > 0 ? undefined : <EmptyTableState colCount={colCount} noActiveSprint orgId={orgId} />;

  const columns = {
    status: { name: strings.task.status, width: '15%' },
    title: { name: strings.task.name, width: '80%' },
    assignee: { name: '', width: '5%' },
  };

  useEffect(() => {
    document.title = strings.titles.dashboard;
    return () => {
      // Restore the original page title when the component unmounts
      document.title = strings.titles.default;
    };
  }, []);

  return (
    <div
      className={css`
        flex: 1;
        background-color: #fbfbfd;
      `}
    >
      <TaskList
        bottomRow={noActiveSprint || noAssignedTasks}
        columns={columns}
        colWidth={colWidth}
        dashboardTaskRow
        tasks={assignedTasks}
        toggleModal={toggleModal}
        topRow={
          sprintIds.length > 0 ? (
            <TasksAssignedHeader colCount={colCount} header taskCount={assignedTasks.length} />
          ) : undefined
        }
      />
      {collabTasks?.length > 0 && (
        <TaskList
          columns={columns}
          colWidth={colWidth}
          dashboardTaskRow
          tasks={collabTasks}
          toggleModal={toggleModal}
          topRow={<TasksAssignedHeader colCount={colCount} taskCount={collabTasks.length} />}
        />
      )}
    </div>
  );
}

const TaskTitleText = styled(Text, {
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
  wordBreak: 'break-word',
});
