import { useEffect } from 'react';

/**
 * useKeyPress
 * @param {string} key - the name of the key to respond to, compared against event.key
 * @param {function} action - the action to perform on key press (react setStateAction)
 * @param {function} actionValue - the value passed to action callback
 */

export function useListenToEscapeKey(
  key: string,
  action: React.Dispatch<React.SetStateAction<boolean>>,
  actionValue: boolean,
): void {
  useEffect(() => {
    function onKeyup(event: KeyboardEvent): void {
      if (event.key === key) action(actionValue);
    }
    window.addEventListener('keyup', onKeyup);
    return () => window.removeEventListener('keyup', onKeyup);
  }, [action, key, actionValue]);
}
