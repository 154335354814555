import { Data, UI } from '@taraai/types';
import { isNonEmptyString } from '@taraai/utility';
import { createStandardSelector, inertQuery, Query } from 'reduxStore/utils/selectors';
import { ReduxFirestoreQuery } from 'reduxStore/utils/types';

export const getTaskCommitsSummaries = (
  orgId: Data.Id.OrganizationId,
  taskId: Data.Id.TaskId,
  options?: { limit?: number },
): Query<UI.UICommitSummary> => {
  if (![orgId, taskId].every(isNonEmptyString)) {
    return inertQuery();
  }

  const query: ReduxFirestoreQuery = {
    collection: `orgs/${orgId}/tasks-commits-summaries`,
    where: [['taskId', '==', taskId]],
    storeAs: `summaries/${orgId}/${taskId}/commits`,
    orderBy: ['createdAt', 'desc'],
  };

  if (typeof options?.limit === 'number') {
    query.limit = options.limit;
  }

  return {
    query: [query],
    selector: createStandardSelector<UI.UICommitSummary>(query),
  };
};
