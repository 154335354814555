"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.payloadDecoder = exports.serviceDecoder = void 0;
var ts_data_json_1 = require("ts.data.json");
exports.serviceDecoder = new ts_data_json_1.JsonDecoder.Decoder(function (value) {
    switch (value) {
        case 'asana':
        case 'trello':
            return (0, ts_data_json_1.ok)(value);
        default:
            return (0, ts_data_json_1.err)("Failed to decode ".concat(value, " as CSVImportableService"));
    }
});
exports.payloadDecoder = ts_data_json_1.JsonDecoder.object({
    service: exports.serviceDecoder,
    organizationId: ts_data_json_1.JsonDecoder.string,
    filename: ts_data_json_1.JsonDecoder.string,
}, 'Payload');
