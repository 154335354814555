import { useFirestoreConnect } from '@taraai/read-write';
import { Data } from '@taraai/types';
import { SprintColumnToastView } from 'components/app/controllers/views/SprintColumnToastView';
import React from 'react';
import { useSelector } from 'react-redux';
import getSprintsWindow from 'reduxStore/sprints/queries/getSprintsWindow';
import { fromEpoch } from 'tools';

type Props = {
  sprintStartDateSeconds: number;
  sprintId: Data.Id.SprintId;
  orgId: Data.Id.OrganizationId;
  teamId: Data.Id.TeamId;
  sprintRequiresAction: Data.SprintRequiredActions | null;
};

export function SprintColumnToastController({
  sprintId,
  orgId,
  sprintStartDateSeconds,
  teamId,
  sprintRequiresAction,
}: Props): JSX.Element {
  const previousSprintSlice = getSprintsWindow(orgId, teamId, fromEpoch(sprintStartDateSeconds), { limitBefore: 1 });
  useFirestoreConnect(Array.from(previousSprintSlice.query));
  const previousSprint = useSelector(previousSprintSlice.selector) ?? [];
  const { sprintName, computedOnCompletion } = previousSprint[0]
    ? previousSprint[0]
    : { sprintName: '', computedOnCompletion: { totalTasksCompleted: 0 } };

  return (
    <SprintColumnToastView
      sprintId={sprintId}
      sprintName={sprintName}
      sprintRequiresAction={sprintRequiresAction}
      totalTasksCount={computedOnCompletion.totalTasksCompleted || 0}
    />
  );
}
