import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { RootState } from 'reduxStore/store';

/**
 * Render route only for non-authenticated users,
 * otherwise redirect to `/`.
 */
const NonAuthenticatedRoute: React.FC<RouteProps> = ({ children, ...props }: RouteProps) => {
  const auth = useSelector((state: RootState) => state.firebase.auth);

  const render = (): JSX.Element => {
    if (!auth.isEmpty) {
      return <Redirect to={{ pathname: '/' }} />;
    }
    return <>{children}</>;
  };

  return <Route {...props} render={render} />;
};

export default NonAuthenticatedRoute;
