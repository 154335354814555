import styled from '@emotion/styled';
import { useMediaQuery } from '@material-ui/core';
import Button from 'components/core/controllers/views/Button';
import Text from 'components/core/controllers/views/Text';
import React from 'react';
import { atomic, strings, TaraLogoIconWhite } from 'resources';

type MobileNoteProps = {
  onClick: () => void;
};

export const MobileNote: React.FC<MobileNoteProps> = ({ onClick }) => {
  const { small } = atomic.responsive.breakpoints[0];
  const isDesktop = useMediaQuery(`(min-width: ${small}px)`);

  return (
    <Wrapper>
      <Logo src={TaraLogoIconWhite} />
      <Message h1 white>
        {strings.onboarding.loginFromDesktop.header}
      </Message>
      <ContinueButton color='wireframe' onClick={onClick} size={isDesktop ? 'default' : 'fullWidth'}>
        {strings.onboarding.loginFromDesktop.submit}
      </ContinueButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;
  padding: 1rem;

  background: linear-gradient(138deg, #58a6e7 0%, #775dfa 100%);
`;

const Logo = styled.img`
  width: 2.5rem;
`;

const Message = styled((props) => <Text h1 white {...props} />)`
  margin: 2rem 0;
  text-align: center;
  font-weight: 500;
`;

const ContinueButton = styled((props) => <Button {...props} />)`
  margin-top: 1rem;
`;
