import InstallIntegrationForm from 'components/app/controllers/views/InstallIntegrationForm';
import Button from 'components/core/controllers/views/Button';
import Icon from 'components/core/controllers/views/Icon';
import { css } from 'emotion';
import React from 'react';
import { strings } from 'resources/i18n';
import { useInstallGitHub } from 'tools';

/**
 * GithubErrorPage used to track error messages from Github repos not being set up
 */
export default function GithubErrorPage(): JSX.Element {
  const gitHubInstallation = useInstallGitHub();

  return (
    <div
      className={css`
        align-items: center;
      `}
    >
      <div
        className={css`
          font-size: 14px;
          font-weight: 600;
          text-align: center;
          color: #e53935;
        `}
      >
        <Icon
          className={css`
            width: 3.5rem;
            height: 3.5rem;
            color: black;
            margin-bottom: 1.4375rem;
            margin-top: 12.5rem;
          `}
          name='github'
        />
        <br />
        {strings.integrations.github.errors.noRepositoryTitle}
      </div>
      <div
        className={css`
          padding-top: 1rem;
          font-size: 14px;
          line-height: 1.57;
          text-align: center;
          color: #575f65;
          padding: 0.625rem;
        `}
      >
        {strings.integrations.github.errors.noRepositoryDescription}
        <br />
        <InstallIntegrationForm installationData={gitHubInstallation} method='get'>
          <Button
            className={css`
              width: 10.8125rem;
              height: 2.375rem;
              padding: 0;
              outline: 0;
              margin-top: 2rem;
            `}
            color='green'
            id='installIntegrationFormChild'
            name={strings.onboarding.connectToGitHub}
            size='small'
          >
            {strings.onboarding.connectToGitHub}
          </Button>
        </InstallIntegrationForm>
      </div>
    </div>
  );
}
