import React, { CSSProperties } from 'react';
import {
  DraggingStyle,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
  NotDraggingStyle,
} from 'react-beautiful-dnd';

import { DroppableDescription } from './types';
import { getDroppableId } from './utils';

export const DroppableArea = React.memo(function DroppableArea({
  children,
  description,
  isDropDisabled = false,
  styles,
  draggingOverStyles,
  showPlaceholder = true,
}: {
  children(provided: DroppableProvided, snapshot: DroppableStateSnapshot): React.ReactElement<HTMLElement>;
  description?: DroppableDescription;
  isDropDisabled?: boolean;
  styles?: CSSProperties;
  draggingOverStyles?: CSSProperties;
  showPlaceholder?: boolean;
}): JSX.Element {
  if (description) {
    const droppableId = getDroppableId(
      description.id,
      description.type,
      description.visibleList,
      description.list || undefined,
    );

    return (
      <Droppable key={`DnD-${description.id}`} droppableId={droppableId} isDropDisabled={isDropDisabled}>
        {(provided, snapshot) => {
          return (
            <>
              <div
                ref={provided?.innerRef}
                {...provided?.droppableProps}
                style={getStyles(snapshot, styles, draggingOverStyles)}
              >
                {children(provided, snapshot)}
                {showPlaceholder && provided?.placeholder}
              </div>
            </>
          );
        }}
      </Droppable>
    );
  }
  return <>{children}</>;
});

const getStyles = (
  snapshot: DroppableStateSnapshot,
  styles?: CSSProperties,
  draggingOverStyles?: CSSProperties,
): CSSProperties | DraggingStyle | NotDraggingStyle | undefined => {
  const defaultStyles = { minHeight: '3.016rem' };
  if (snapshot.isDraggingOver) return { ...defaultStyles, ...styles, ...draggingOverStyles };
  return { ...defaultStyles, ...styles };
};
