import { Box } from '@taraai/design-system';
import { useFirestoreConnect } from '@taraai/read-write';
import { Data } from '@taraai/types';
import { RequirementDetailsController } from 'components/app/RequirementDetails/RequirementDetails';
import { TaskDetails } from 'components/app/TaskDetails/TaskDetails';
import { linkTo } from 'components/Router/paths';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { compose } from 'redux';
import { getTask, selectActiveTeam, selectActiveWorkspace, selectNextTaskId } from 'reduxStore';

export function DefineSidePanelController({
  requirementId,
  taskId,
}: {
  requirementId: Data.Id.RequirementId;
  taskId: Data.Id.TaskId;
}): JSX.Element | null {
  const history = useHistory();
  const orgId = useSelector(selectActiveWorkspace);
  const teamId = useSelector(selectActiveTeam);
  const nextTaskId = useSelector(selectNextTaskId);

  const navigateAfterTaskDelete = useCallback((): void => {
    if (nextTaskId) return history.push(linkTo('task', { orgId, teamId, taskId: nextTaskId }));
    history.push(linkTo('requirements', { orgId, teamId }));
  }, [history, orgId, teamId, nextTaskId]);

  const individualTask = getTask(orgId, taskId);
  const taskFragment = useSelector(
    compose(
      (task) =>
        task && {
          assigneeId: task.assignee,
        },
      individualTask.selector,
    ),
  );
  useFirestoreConnect(individualTask.query);
  const assigneeId = useMemo(() => taskFragment?.assigneeId ?? '', [taskFragment?.assigneeId]);

  if (!taskId && !requirementId && !assigneeId) return <Container />;

  return (
    <Container>
      {requirementId && <RequirementDetailsController orgId={orgId} requirementId={requirementId} teamId={teamId} />}
      {taskId && (
        <TaskDetails orgId={orgId} taskDeleteCallback={navigateAfterTaskDelete} taskId={taskId} teamId={teamId} />
      )}
    </Container>
  );
}

function Container({ children }: { children?: ReactNode }): JSX.Element {
  return (
    <Box background='$grey2' height='$full'>
      {children}
    </Box>
  );
}
