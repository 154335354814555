import { Box, styled } from '@taraai/design-system';
import Icon from 'components/core/controllers/views/Icon';
import React from 'react';

/*
    This component is used to create a box to add something,
    it's reusable and was release in designs for new sprint menus (2.48.2),
    see an example here: https://www.figma.com/file/SJMsr1P0xvSCSoZJxPX5Xz/Project-Redux?node-id=99%3A224881
*/

type AddActionBoxProps = {
  text: string;
  onClick: () => void;
  centerText?: boolean;
};

export const AddActionBox = ({ text, onClick, centerText }: AddActionBoxProps): JSX.Element => {
  return (
    <Wrapper centerText={centerText} onClick={onClick}>
      <PlusIcon name='plus' noPadding /> &nbsp;{text}
    </Wrapper>
  );
};

const Wrapper = styled(
  Box,
  {
    color: '$focus',
    cursor: 'pointer',
    backgroundColor: '$white',
    fontSize: '14px',
    padding: '12px',
  },
  { centerText: { true: { display: 'flex', alignItems: 'center', justifyContent: 'center' } } },
);

const PlusIcon = styled(Icon, { alignSelf: 'center', height: '0.75rem', width: '0.75rem', color: '$focus' });
